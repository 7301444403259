import React from 'react';
import { StyledInput } from './SharedInput.style';
import { CustomAutocomplete } from './Autocomplete/CustomAutocomplete';
import { CustomTextarea } from './Textarea/CustomTextarea';
import { type ICurrentThemeColor } from '../../../app/slices/switchThemeSlice';
import { NumberInput } from './NumberInput/NumberInput';

export interface IThemeStyledComponents {
	color: () => string;
	colorAlert: string;
	uniqueNameOfInput: string;
	currentTheme: ICurrentThemeColor;
}
interface IInputProps {
	type: string;
	placeholder: string;
	inputFunction: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
	value?: string;
	theme?: IThemeStyledComponents;
	params?: string[];
	uniqueNameOfInput: string;
	isDisabled?: boolean;
	autocomplete?: boolean;
	minNumber?: number;
	maxNumber?: number;
	stepNumber?: number;
	refTag?: React.RefObject<HTMLInputElement> | null;
}

export const SharedInput = ({
	type,
	placeholder,
	inputFunction,
	value,
	theme,
	params,
	uniqueNameOfInput,
	isDisabled,
	autocomplete,
	minNumber,
	maxNumber,
	stepNumber,
	refTag,
}: IInputProps): JSX.Element => {
	if (type === 'autocomplete') {
		return (
			<CustomAutocomplete
				placeholder={placeholder}
				value={value}
				inputFunction={inputFunction}
				aria-describedby="outlined-input-helper-text"
				theme={theme}
				params={params}
				uniqueNameOfInput={uniqueNameOfInput}
				disabled={isDisabled === true}
			/>
		);
	} else if (type === 'textarea') {
		return (
			<CustomTextarea
				aria-label="Textarea"
				placeholder={placeholder}
				value={value}
				theme={theme}
				inputFunction={inputFunction}
			/>
		);
	} else if (type === 'number') {
		return (
			<NumberInput
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={inputFunction}
				id={uniqueNameOfInput}
				aria-describedby="outlined-input-helper-text"
				theme={theme}
				className="shared-input"
				autoComplete="off"
				disabled={isDisabled === true}
				minNumber={minNumber}
				maxNumber={maxNumber}
				stepNumber={stepNumber}
			/>
			// <StyledInput
			// 	type={type}
			// 	placeholder={placeholder}
			// 	value={value}
			// 	onChange={inputFunction}
			// 	id={uniqueNameOfInput}
			// 	aria-describedby="outlined-input-helper-text"
			// 	theme={theme}
			// 	className="shared-input"
			// 	autoComplete="off"
			// 	disabled={isDisabled === true}
			// 	min={minNumber !== undefined ? minNumber : 'auto'}
			// 	max={maxNumber !== undefined ? maxNumber : 'auto'}
			// 	step={stepNumber !== undefined ? stepNumber : '1'}
			// />
		);
	} else {
		return (
			<StyledInput
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={inputFunction}
				id={uniqueNameOfInput}
				aria-describedby="outlined-input-helper-text"
				theme={theme}
				className="shared-input"
				autoComplete={autocomplete === false ? 'off' : 'on'}
				disabled={isDisabled === true}
				ref={refTag !== undefined ? refTag : null}
			/>
		);
	}
};
