import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const isOpenModalLogoutSlice = createSlice({
	name: 'isOpenModalLogout',
	initialState,
	reducers: {
		setCloseOpenModalLogoutStatus(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setCloseOpenModalLogoutStatus } = isOpenModalLogoutSlice.actions;
export const isOpenModalLogoutReducer = isOpenModalLogoutSlice.reducer;
