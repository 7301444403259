import React from 'react';
import { LayoutAccount } from '../../components/shared/LayoutAccount/LayoutAccount';
import { NichesMenu } from '../../components/AboutMenu/NichesMenu/NichesMenu';

export const NichesPage: React.FC = () => {
	return (
		<LayoutAccount title="Niches">
			<NichesMenu />
		</LayoutAccount>
	);
};
