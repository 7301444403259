import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const openTestBotTrainingBotMenuSlice = createSlice({
	name: 'openTestBotTrainingBotMenu',
	initialState: false,
	reducers: {
		setOpenModalTestBotStatus(state, action: PayloadAction<boolean>) {
			state = action.payload;
			return state;
		},
	},
});

export const { setOpenModalTestBotStatus } =
	openTestBotTrainingBotMenuSlice.actions;
export const openTestBotTrainingBotMenuReducer =
	openTestBotTrainingBotMenuSlice.reducer;
