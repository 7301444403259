import styled from 'styled-components';

export const StyledModalExportSnippets = styled.div`
	width: 100%;

	& .exportSnippetMenuComponent-wrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding: 30px 50px;
		width: 100%;

		& .exportSnippetMenu-title-subtitle {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			font-style: normal;
			width: 100%;

			& .exportSnippetMenu-title {
				font-size: 24px;
				font-weight: 600;
				line-height: 145%;
			}

			& .exportSnippetMenu-subtitle {
				font-size: 18px;
				font-weight: 400;
				line-height: 27px;
			}
		}

		& .exportSnippetMenu-input-url {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 2rem;

			& > div > div {
				width: 100% !important;
			}

			& input {
				width: 100% !important;
			}

			& .exportSnippetMenu-exe-buttons {
				display: flex;
				gap: 1rem;
				align-items: center;
			}

			& .exportSnippetMenu-answer-window {
				border: 1px solid #1592ec;
				border-radius: 8px;
				padding: 1rem;
				overflow: auto;
				height: 300px;
				width: 100% !important;
				color: ${(props) => props.theme.currentTheme.fontColorInput};
				font-size: 16px;
				font-weight: 400;
				line-height: 27px;
				cursor: no-drop;
				background: ${(props) => props.theme.currentTheme.disabledBackground};
			}
		}

		& .exportSnippetMenu-answer-loading-content {
			display: flex;
			justify-content: center;

			& .error-message-from-api {
				color: ${(props) => props.theme.currentTheme.alertFontColor};
			}

			& .message-from-api {
				color: #1592ec;
			}
		}

		& .exportSnippetMenu-button-field {
			display: flex;
			gap: 1rem;

			& > div {
				width: 100% !important;
				& button {
					width: 100% !important;
				}
			}
		}
	}

	@media screen and (max-width: 650px) {
		& .exportSnippetMenuComponent-wrapper {
			padding: 16px;
			& .exportSnippetMenu-title-subtitle {
				text-align: center;
			}
		}
	}
`;
