// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { useLazyGetNotVectorisedSnippetsByChatbotIdQuery } from '../../../app/api/chatbot.api';
import { setNotVectorisedSnippetsCountMessage } from '../../../app/slices/trainingBotMenu/messagesFromApi/notVectorisedSnippetsCountMessageTrainingBotMenuSlice';

export const useGetNotVectorisedSnippetsCount = (): ((
	chatbotId: number
) => Promise<number>) => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const dispatch = useAppDispatch();

	const [lazyGetNotVectorisedSnippetsCountFn] =
		useLazyGetNotVectorisedSnippetsByChatbotIdQuery();

	return async (chatbotId: number): Promise<number> => {
		const notVectorisedSnippetsBody = {
			token,
			chatbotId: chatbotId.toString(),
		};

		let countNotVectorisedSnippets = 0;

		try {
			if (chatbotId > 0) {
				const resultLazyNotVectorisedSnippetsCount =
					await lazyGetNotVectorisedSnippetsCountFn(notVectorisedSnippetsBody);
				const { data, isLoading, isError, isSuccess } =
					resultLazyNotVectorisedSnippetsCount;

				// console.log(resultLazyNotVectorisedSnippetsCount)
				if (isError) {
					dispatch(setNotVectorisedSnippetsCountMessage('error'));
				} else if (isLoading) {
					dispatch(setNotVectorisedSnippetsCountMessage('loading'));
				} else if (isSuccess) {
					dispatch(setNotVectorisedSnippetsCountMessage('success'));
					countNotVectorisedSnippets = data.count;
				}
			}
		} catch (error) {
			dispatch(setNotVectorisedSnippetsCountMessage('error'));
		}

		return countNotVectorisedSnippets;
	};
};
