// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialContactsState {
	nameContacts: string;
	emailContacts: string;
	messageContacts: string;
}

const initialState: IInitialContactsState = {
	nameContacts: '',
	emailContacts: '',
	messageContacts: '',
};

const contactsInputsValueSlice = createSlice({
	name: 'contactsInputsValue',
	initialState,
	reducers: {
		setNameContacts(state, action) {
			state.nameContacts = action.payload;
			return state;
		},
		setEmailContacts(state, action) {
			state.emailContacts = action.payload;
			return state;
		},
		setMessageContacts(state, action) {
			state.messageContacts = action.payload;
			return state;
		},
	},
});

export const { setNameContacts, setEmailContacts, setMessageContacts } =
	contactsInputsValueSlice.actions;
export const contactsInputsValueReducer = contactsInputsValueSlice.reducer;
