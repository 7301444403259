/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSharedWrappedInputWithIcon = styled.div`
	display: flex;
	align-items: flex-start !important;

	& .shared-wrapped-input {
		width: 100%;
	}

	& .icon-wrapper {
		width: 66px !important;
		height: 68px !important;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 10px;
		background: ${(props) => props.theme.currentTheme.wrappedIconInInputBg};
		border-radius: 8px 0px 0px 8px !important;
		border-right: none !important;
		border: 1px solid ${(props) => props.theme.currentTheme.borderInputs};
		outline: none;

		& img {
			width: 21px;
			height: 21px;
		}
	}

	& .disabled-icon-wrapper {
		background: ${(props) => props.theme.currentTheme.disabledBackground};
		border: 1px solid ${(props) => props.theme.currentTheme.disabledBorder};
	}

	&:hover {
		& .icon-wrapper {
			background: linear-gradient(
						${(props) => props.theme.currentTheme.cardsBackground} 0 0
					)
					padding-box,
				linear-gradient(to right, #43f2ff, #691eff) border-box;
			border: 1px solid transparent;
		}
		& .shared-wrapped-input {
			& .shared-input {
				background: linear-gradient(
							${(props) => props.theme.currentTheme.cardsBackground} 0 0
						)
						padding-box,
					linear-gradient(to right, #691eff, #691eff) border-box;
				border: 1px solid transparent;
			}
			& .MuiAutocomplete-root {
				background: linear-gradient(
							${(props) => props.theme.currentTheme.cardsBackground} 0 0
						)
						padding-box,
					linear-gradient(to right, #691eff, #691eff) border-box;
				border: 1px solid transparent;
			}
		}
		& .disabled-wrapped-input {
			& .shared-input {
				color: ${(props) => props.theme.currentTheme.disabledColor};
				background: ${(props) => props.theme.currentTheme.disabledBackground};
				border: 1px solid ${(props) => props.theme.currentTheme.disabledBorder};
			}
			& .MuiAutocomplete-root {
				color: ${(props) => props.theme.currentTheme.disabledColor};
				background: ${(props) => props.theme.currentTheme.disabledBackground};
				border: 1px solid ${(props) => props.theme.currentTheme.disabledBorder};
			}
		}
		& .disabled-icon-wrapper {
			color: ${(props) => props.theme.currentTheme.disabledColor};
			background: ${(props) => props.theme.currentTheme.disabledBackground};
			border: 1px solid ${(props) => props.theme.currentTheme.disabledBorder};
		}
	}

	&:disabled {
		& .icon-wrapper {
			color: ${(props) => props.theme.currentTheme.disabledColor};
			background: ${(props) => props.theme.currentTheme.disabledBackground};
			border: 1px solid ${(props) => props.theme.currentTheme.disabledBorder};
		}
		& .MuiAutocomplete-root {
			color: ${(props) => props.theme.currentTheme.disabledColor};
			background: ${(props) => props.theme.currentTheme.disabledBackground};
			border: 1px solid ${(props) => props.theme.currentTheme.disabledBorder};
		}
		& .Mui-disabled {
			color: ${(props) => props.theme.currentTheme.disabledColor};
		}
	}

	& .shared-wrapped-input {
		& .shared-input {
			border-radius: 0px 8px 8px 0px !important;
		}

		& .MuiAutocomplete-root {
			border-radius: 0px 8px 8px 0px !important;

			& .Mui-focused {
				border-radius: 0px 8px 8px 0px !important;
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .icon-wrapper {
			width: 50px !important;
			height: 50px !important;
		}
	}

	@media screen and (max-width: 1000px) {
		& .customAutocomplete {
			width: 100% !important;
		}
	}
`;
