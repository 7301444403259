// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledReceiveJSCodeComponent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 145%;

	color: ${(props) => props.theme.currentTheme.mainFontColor};

	& .receive-title {
		font-weight: 600;
		font-size: 32px;
		line-height: 48px;
	}

	& .receive-message {
		text-align: center;
		& a {
			margin-top: 3px;
			text-decoration: none;
			color: #1592ec;
			font-weight: 400;
			font-size: 22px;
			line-height: 145%;
		}
	}

	& > img {
		width: 120px;
		height: 120px;
	}

	@media screen and (max-width: 1500px) {
		font-size: 20px;

		& .receive-title {
			font-size: 18px;
		}

		& .receive-subtitle {
			font-size: 16px;
		}

		& .receive-message {
			font-size: 14px;

			& a {
				font-size: 14px;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		flex-direction: column;
		align-items: center;

		& .receive-title,
		.receive-subtitle {
			text-align: center;
		}

		& > div {
			width: 90%;
			padding: 0px;
			height: auto;
		}
	}

	@media screen and (max-width: 610px) {
		padding: 0px;
		flex-direction: column;

		& svg {
			width: 100px;
			height: 100px;
		}

		& > div {
			padding: 0px;
		}
	}

	@media screen and (max-width: 500px) {
		& .receive-title {
			font-size: 20px;
			text-align: center;
		}

		& .receive-subtitle {
			font-size: 16px;
			text-align: center;
		}

		& .receive-message {
			font-size: 16px;
			& a {
				font-size: 16px;
			}
		}
	}
`;
