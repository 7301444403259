// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const aboutOpenCloseDropDownSlice = createSlice({
	name: 'aboutOpenCloseDropDown',
	initialState,
	reducers: {
		setStatusAboutDropDownList(state, action: PayloadAction<boolean>) {
			state = action.payload;
			return state;
		},
	},
});

export const { setStatusAboutDropDownList } =
	aboutOpenCloseDropDownSlice.actions;
export const aboutOpenCloseDropDownReducer =
	aboutOpenCloseDropDownSlice.reducer;
