import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialVectoriseSnippetMessageTrainingBotMenu {
	vectoriseSnippetMessage: string;
}

const initialState: IInitialVectoriseSnippetMessageTrainingBotMenu = {
	vectoriseSnippetMessage: '',
};

const vectoriseSnippetMessageTrainingBotMenuSlice = createSlice({
	name: 'vectoriseSnippetMessageTrainingBotMenu',
	initialState,
	reducers: {
		setVectoriseSnippetMessage(state, action: PayloadAction<string>) {
			state.vectoriseSnippetMessage = action.payload;
			return state;
		},
	},
});

export const { setVectoriseSnippetMessage } =
	vectoriseSnippetMessageTrainingBotMenuSlice.actions;
export const vectoriseSnippetMessageTrainingBotMenuReducer =
	vectoriseSnippetMessageTrainingBotMenuSlice.reducer;
