import styled from 'styled-components';

export const StyledCurrentTemplate = styled.div`
	width: 33%;
	/* height: 100%; */
	max-height: 425px;
	padding: 24px;
	border-radius: 16px;
	border: 1px solid #93bce2;
	background: ${(props) => props.theme.currentTheme.templateBg};
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:hover {
		border: 1px solid #1592ec;
	}

	&:active {
		border: 2px solid #1592ec !important;
	}

	& .currentTemplate {
		width: 300px;
		max-width: 100%;
		max-height: 380px;
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media screen and (max-width: 600px) {
		width: 80%;
	}
`;
