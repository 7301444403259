import React from 'react';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { SharedTransparentButton } from '../../shared/SharedTransparentButton/SharedTransparentButton';

export const LetsGenerateCard: React.FC = () => {
	return (
		<Box
			component="div"
			className="personal-cabinet-generate-wrapper small-box-wrapper"
			sx={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'row !important',
				flexWrap: 'wrap',
				gap: 3,
			}}
		>
			<Box component="div" className="personal-cabinet-generate-subtitle">
				Let&apos;s generate a new Custom ChatbotGPT for your website
			</Box>
			<Box component="div" className="button-field">
				<NavLink to="/chatbot-generator" style={{ textDecoration: 'none' }}>
					<SharedTransparentButton
						buttonFunction={() => {}}
						text="Create bot"
						tooltip="You can create your custom chatbot"
					/>
				</NavLink>
			</Box>
		</Box>
	);
};
