// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const checkEmptyStatusForDetailedBotCardSlice = createSlice({
	name: 'checkEmptyStatusForDetailedBotCard',
	initialState: false,
	reducers: {
		setEmptyStatusForDetailedBotCard(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForDetailedBotCard } =
	checkEmptyStatusForDetailedBotCardSlice.actions;
export const checkEmptyStatusForDetailedBotCardReducer =
	checkEmptyStatusForDetailedBotCardSlice.reducer;
