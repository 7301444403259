import styled from 'styled-components';

export const StyledSharedQuestion = styled.div`
	padding: 24px 40px 24px 24px;
	background: linear-gradient(
				${(props) => props.theme.currentTheme.cardsBackground} 0 0
			)
			padding-box,
		linear-gradient(to right, #bfd5ff, #dee9ff) border-box;
	border: 1px solid transparent;
	border-radius: 20px 20px 0px 20px;

	width: 100%;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	gap: 1rem;

	& .question-is-open-icon {
		& svg path {
			stroke: ${(props) => props.theme.currentTheme.mainFontColor};
		}
	}

	@media screen and (max-width: 800px) {
		height: auto;
		padding: 16px;
		gap: 1rem;
	}
`;
