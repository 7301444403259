// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialOpenAIMenuValidInputsValueState {
	isValidOpenAiConfigOpenAIMenu: boolean;
	isValidTemperatureConfigOpenAIMenu: boolean;
	isValidCompletionConfigOpenAIMenu: boolean;
	isValidVectorModelConfigOpenAIMenu: boolean;
	isValidThresholdConfigOpenAIMenu: boolean;
	isValidMaxTokensContentConfigOpenAIMenu: boolean;
	isValidMaxTokensRequestConfigOpenAIMenu: boolean;
	isValidMaxTokensResponseConfigOpenAIMenu: boolean;
	isValidMaxTokensMessageConfigOpenAIMenu: boolean;
}

const initialState: IInitialOpenAIMenuValidInputsValueState = {
	isValidOpenAiConfigOpenAIMenu: true,
	isValidTemperatureConfigOpenAIMenu: false,
	isValidCompletionConfigOpenAIMenu: false,
	isValidVectorModelConfigOpenAIMenu: false,
	isValidThresholdConfigOpenAIMenu: false,
	isValidMaxTokensContentConfigOpenAIMenu: false,
	isValidMaxTokensRequestConfigOpenAIMenu: false,
	isValidMaxTokensResponseConfigOpenAIMenu: false,
	isValidMaxTokensMessageConfigOpenAIMenu: false,
};

const isValidOpenAIMenuInputsSlice = createSlice({
	name: 'isValidOpenAIMenuInputs',
	initialState,
	reducers: {
		setValidStatusOpenAiConfigOpenAIMenu(state, action) {
			state.isValidOpenAiConfigOpenAIMenu = action.payload;
			return state;
		},
		setValidStatusTemperatureConfigOpenAIMenu(state, action) {
			state.isValidTemperatureConfigOpenAIMenu = action.payload;
			return state;
		},
		setValidStatusCompletionConfigOpenAIMenu(state, action) {
			state.isValidCompletionConfigOpenAIMenu = action.payload;
			return state;
		},
		setValidStatusVectorModelConfigOpenAIMenu(state, action) {
			state.isValidVectorModelConfigOpenAIMenu = action.payload;
			return state;
		},
		setValidStatusThresholdConfigOpenAIMenu(state, action) {
			state.isValidThresholdConfigOpenAIMenu = action.payload;
			return state;
		},
		setValidStatusMaxTokensContentConfigOpenAIMenu(state, action) {
			state.isValidMaxTokensContentConfigOpenAIMenu = action.payload;
			return state;
		},
		setValidStatusMaxTokensRequestConfigOpenAIMenu(state, action) {
			state.isValidMaxTokensRequestConfigOpenAIMenu = action.payload;
			return state;
		},
		setValidStatusMaxTokensResponseConfigOpenAIMenu(state, action) {
			state.isValidMaxTokensResponseConfigOpenAIMenu = action.payload;
			return state;
		},
		setValidStatusMaxTokensMessageConfigOpenAIMenu(state, action) {
			state.isValidMaxTokensMessageConfigOpenAIMenu = action.payload;
			return state;
		},
	},
});

export const {
	setValidStatusOpenAiConfigOpenAIMenu,
	setValidStatusTemperatureConfigOpenAIMenu,
	setValidStatusCompletionConfigOpenAIMenu,
	setValidStatusVectorModelConfigOpenAIMenu,
	setValidStatusThresholdConfigOpenAIMenu,
	setValidStatusMaxTokensContentConfigOpenAIMenu,
	setValidStatusMaxTokensRequestConfigOpenAIMenu,
	setValidStatusMaxTokensResponseConfigOpenAIMenu,
	setValidStatusMaxTokensMessageConfigOpenAIMenu,
} = isValidOpenAIMenuInputsSlice.actions;
export const isValidOpenAIMenuInputsReducer =
	isValidOpenAIMenuInputsSlice.reducer;
