// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialResetInputValueValidStatus {
	isValidPasswordReset: boolean;
}

const initialState: IInitialResetInputValueValidStatus = {
	isValidPasswordReset: false,
};

const isValidResetInputValueSlice = createSlice({
	name: 'isValidResetInputValue',
	initialState,
	reducers: {
		setValidPasswordResetStatus(state, action) {
			state.isValidPasswordReset = action.payload;
			return state;
		},
	},
});

export const { setValidPasswordResetStatus } =
	isValidResetInputValueSlice.actions;
export const isValidResetInputValueReducer =
	isValidResetInputValueSlice.reducer;
