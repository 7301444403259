import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const PureSkeleton: React.FC = () => {
	return (
		<Stack className="stack-skeleton" spacing={0} sx={{ pb: 2 }}>
			<Skeleton
				variant="rectangular"
				animation="pulse"
				height={400}
				sx={{ borderRadius: '10px' }}
			/>
		</Stack>
	);
};
