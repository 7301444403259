import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useGetPersonalInformationQuery } from '../app/api/chatbot.api';
import { useAppDispatch } from '../app/hooks';
import {
	setAnswerApiLoginPersonalInformation,
	setAnswerApiLoginStatus,
	setAnswerApiLoginToken,
} from '../app/slices/answersFromAPI/answerApiLoginSlice';
import { setItem } from '../app/utils/storageUtils';
import { setAuthorizationStatus } from '../app/slices/isAuthorizationSlice';

export const AuthFromGithubAndFacebookPage: React.FC = () => {
	const dataFromUrl = useLocation();
	const tokenFromUrl = dataFromUrl.pathname.replace('/token/', '');

	const { data: getPersonalInformationData } =
		useGetPersonalInformationQuery(tokenFromUrl);

	const dispatch = useAppDispatch();
	const completeLogin = (personalInformationFromApi: any, token: any): any => {
		dispatch(setAnswerApiLoginStatus(true));
		dispatch(setAnswerApiLoginPersonalInformation(personalInformationFromApi));
		setItem('loginDataFromApi', JSON.stringify(personalInformationFromApi));
		dispatch(setAnswerApiLoginToken(token));
		dispatch(setAuthorizationStatus(true));
		setItem('isAuthorization', 'true');
		setItem('token', tokenFromUrl);
	};

	useEffect(() => {
		if (getPersonalInformationData !== undefined) {
			const personalInformationFromApi = {
				id: getPersonalInformationData.id,
				username: getPersonalInformationData.username,
				surname: getPersonalInformationData.surname,
			};
			completeLogin(personalInformationFromApi, tokenFromUrl);
			setTimeout(() => {
				window.location.replace('/');
			}, 0);
		}
	}, [getPersonalInformationData]);

	return <Box component="div" className="AuthFromGithubAndFacebook-page"></Box>;
};
