import React from 'react';
import { Box } from '@mui/material';
import { StyledSharedOneActionComponent } from './SharedOneActionComponent.style';
import { SharedNavLink } from '../SharedNavlink/SharedNavlink';
import { SharedTitleSubtitle } from '../SharedTitleSubtitle/SharedTitleSubtitle';
import { WrapperMenu } from '../WrapperMenu/WrapperMenu';
import { removeSessionItem } from '../../../app/utils/storageUtils';
import { SharedMainButton } from '../SharedMainButton/SharedMainButton';

interface ISharedOneActionComponentProps {
	inputsFieldForgot: JSX.Element;
	handleExecuteFunction: () => void;
	title: string;
	subtitle: string;
	textButton: string;
	loadingErrorContent: JSX.Element | null;
	isErrorMessage?: boolean;
	navLink: '/' | '/registration';
	additionalActionField?: JSX.Element;
	isForgotMenu?: boolean;
}

export const SharedOneActionComponent: React.FC<
	ISharedOneActionComponentProps
> = ({
	inputsFieldForgot,
	handleExecuteFunction,
	title,
	subtitle,
	textButton,
	loadingErrorContent,
	isErrorMessage,
	navLink,
	additionalActionField,
	isForgotMenu,
}) => {
	return (
		<WrapperMenu isForgotMenu={isForgotMenu}>
			<StyledSharedOneActionComponent>
				<Box component="div" className="menu-wrapper">
					<Box sx={{ width: '500px' }}></Box>
					<Box component="div" className="menu-inputs-area">
						<SharedTitleSubtitle
							title={title}
							subtitle={subtitle}
							align="center"
							fontSize="2rem"
						/>
						<Box component="div" className="forgotReset-inputs-field">
							{inputsFieldForgot}
						</Box>
						<Box
							component="div"
							className={`forgotReset-inputs-loadingErrorContent ${
								isErrorMessage === true ? 'error-alert' : ''
							}`}
						>
							{loadingErrorContent}
						</Box>
						{additionalActionField !== undefined && additionalActionField}
						<Box component="div" className="forgotReset-button">
							<SharedMainButton
								isDeleteButton={false}
								text={textButton}
								buttonFunction={handleExecuteFunction}
							/>
						</Box>
						<Box component="div" className="forgotReset-create-under-button">
							<Box
								component="div"
								onClick={() => {
									if (navLink === '/') {
										removeSessionItem('registrationData');
										// clearSessionStorage();
										window.location.replace('/');
									}
								}}
							>
								<SharedNavLink
									link={navLink}
									text={
										navLink === '/'
											? 'Have an account? '
											: "Don't have an account yet? "
									}
									accent={navLink === '/' ? 'Login' : 'Create'}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</StyledSharedOneActionComponent>
		</WrapperMenu>
	);
};
