/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { StyledSharedWrappedInputWithIcon } from './SharedWrappedInputWithIcon.style';
import { type IIsFloatDigit, WrappedInput } from '../WrappedInput/WrappedInput';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { CustomTooltip } from '../../CustomTooltip/CustomTooltip';

interface IInputProps {
	type: string;
	placeholder: string;
	isEmpty: boolean;
	params?: string[];
	isDarkBack?: boolean;
	uniqueNameOfInput: string;
	value?: string;
	isValid: boolean;
	isDisabled?: boolean;
	maxLength?: number;
	autocomplete?: boolean;
	tooltip?: string;

	icon?: string;
	iconSVG?: JSX.Element;

	minNumber?: number;
	maxNumber?: number;
	stepNumber?: number;
	isFloat?: IIsFloatDigit;

	target?: string;
	tip?: string;
}

export const SharedWrappedInputWithIcon: React.FC<IInputProps> = ({
	type,
	placeholder,
	isEmpty,
	uniqueNameOfInput,
	target,
	tip,
	isDarkBack,
	value,
	isValid,
	isDisabled,
	params,
	icon,
	iconSVG,

	tooltip,
	minNumber,
	maxNumber,
	stepNumber,
	maxLength,
	isFloat,
}: IInputProps) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		currentTheme,
	};

	return (
		<StyledSharedWrappedInputWithIcon
			theme={theme}
			className={`styledSharedWrappedInputWithIcon`}
		>
			{tooltip !== undefined && tooltip.length > 0 ? (
				<CustomTooltip title={tooltip}>
					<Box
						component="div"
						className={`icon-wrapper  ${
							isDisabled === true ? 'disabled-icon-wrapper' : ''
						}`}
					>
						{iconSVG !== undefined ? (
							iconSVG
						) : (
							<Box
								component="img"
								src={icon !== undefined ? icon : ''}
								alt="input-icon"
							/>
						)}
					</Box>
				</CustomTooltip>
			) : (
				<Box
					component="div"
					className={`icon-wrapper  ${
						isDisabled === true ? 'disabled-icon-wrapper' : ''
					}`}
				>
					<Box component="img" src={icon} alt="input-icon" />
				</Box>
			)}
			<WrappedInput
				type={type}
				placeholder={placeholder}
				isEmpty={isEmpty}
				isDarkBack={isDarkBack}
				uniqueNameOfInput={uniqueNameOfInput}
				value={value}
				isValid={isValid}
				isDisabled={isDisabled === true}
				params={params}
				minNumber={minNumber}
				maxNumber={maxNumber}
				stepNumber={stepNumber}
				maxLength={maxLength}
				isFloat={isFloat}
			/>
		</StyledSharedWrappedInputWithIcon>
	);
};
