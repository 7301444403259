import React from 'react';
import { Box, FormControlLabel, Radio } from '@mui/material';
import visa from '../../pic/visa.svg';
import amex from '../../pic/amex.svg';
import mastercard from '../../pic/mastercard.svg';
import checked from '../../pic/checked.svg';
import ellipse from '../../pic/ellipse.svg';
import { CustomTooltip } from '../../../shared/CustomTooltip/CustomTooltip';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { ReactComponent as Delete } from '../../pic/deleteBlue.svg';
import {
	setCurrentPaymentMethodDataForDelete,
	setCurrentPaymentMethodIdForDelete,
	setStatusOpenModalDelete,
} from '../../../../app/slices/paymentMethodsMenu/deletePaymentMethodsMenuSlice';

interface ICustomCardCheckboxProps {
	label: string;
	isCard: boolean;
	brand?: string;
	id: string;
	isChecked: boolean;
	last4: string;
}

export const CustomCardCheckbox: React.FC<ICustomCardCheckboxProps> = ({
	label,
	isCard,
	brand,
	id,
	isChecked,
	last4,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const dispatch = useAppDispatch();
	const handleClickOnDeletePaymentMethod = (): void => {
		dispatch(setStatusOpenModalDelete(true));
		dispatch(setCurrentPaymentMethodIdForDelete(id));
		dispatch(setCurrentPaymentMethodDataForDelete(last4));
	};

	return (
		<Box
			component="div"
			className={`customCardCheckbox ${isCard ? 'isCard' : ''} ${
				isChecked ? 'isChecked' : 'isNotChecked'
			}`}
		>
			<FormControlLabel
				label={label}
				value={id}
				control={
					<Radio
						checked={isChecked}
						icon={<Box component="img" alt="check" src={ellipse} />}
						checkedIcon={<Box component="img" alt="check" src={checked} />}
					/>
				}
			/>
			{isCard ? (
				<Box component="div" className="customCardCheckbox-brand-pic">
					<Box
						component="img"
						src={
							brand === 'visa' || brand === 'VISA'
								? visa
								: brand === 'mastercard' || brand === 'MasterCard'
								? mastercard
								: brand === 'amex' || brand === 'AMEX'
								? amex
								: ''
						}
						alt="card"
					/>
				</Box>
			) : (
				<></>
			)}

			{isCard ? (
				<CustomTooltip theme={theme} title="Delete current payment method">
					<Box
						component="div"
						className="customCardCheckbox-delete-pic"
						onClick={handleClickOnDeletePaymentMethod}
					>
						<Delete style={{ width: '22px', height: 'auto' }} />
					</Box>
				</CustomTooltip>
			) : (
				<></>
			)}
		</Box>
	);
};
