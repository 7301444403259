import styled from 'styled-components';

export const StyledMessageExample = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	display: flex;
	gap: 16px;
	padding: 32px 0px 0px 146px;
	/* padding: 32px 0px 0px 166px; */
	/* padding: 32px 0px 0px 190px; */

	& .messageExample-arrow {
		display: block;
	}

	& .messageExample-msg-box {
		width: max-content;
		padding: 32px;
		border-radius: 8px;
		border: 1px solid ${(props) => props.theme.currentTheme.integrateAccent};
		color: ${(props) => props.theme.currentTheme.mainFontColor};
		display: flex;
		flex-direction: column;
		align-items: center;
		box-shadow: ${(props) => props.theme.currentTheme.boxShadow};

		& > span {
			text-align: center;
		}

		& .messageExample-msg-header {
			padding-bottom: 16px;
		}

		& .messageExample-msg-article-1 {
			width: 500px;
			padding-bottom: 8px;

			& .messageExample-msg-fake-link {
				color: ${(props) => props.theme.currentTheme.coderfyAccentPrimaryColor};
			}
		}
	}

	@media screen and (max-width: 1500px) {
		padding: 0px;
		top: 36px;
		left: -16px;

		& .messageExample-arrow {
			display: none;
		}
	}

	@media screen and (max-width: 800px) {
		position: relative;
		top: 8px;
		left: 0px;

		& .messageExample-msg-box {
			width: 100%;

			& .messageExample-msg-article-1 {
				width: 100%;
			}
		}
	}
`;
