import React from 'react';
import { Box } from '@mui/material';
import { StyledMessageExample } from './MessageExample.style';
import arrow from '../../../../pic/arrow.svg';
import { useAppSelector } from '../../../../../../app/hooks';
import { type RootState } from '../../../../../../app/store';

export const MessageExample: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	return (
		<StyledMessageExample theme={theme} className="messageExample-wrapper">
			<Box component="div" className="messageExample-arrow">
				<Box component="img" src={arrow} alt="arrow-pic" />
			</Box>
			<Box component="div" className="messageExample-msg-box">
				<Box component="span" className="messageExample-msg-header">
					Done! Congratulations on your new bot.
				</Box>
				<Box component="span" className="messageExample-msg-article-1">
					You will find it at&nbsp;
					<Box component="span" className="messageExample-msg-fake-link">
						t.me/yourBot
					</Box>
					. You can now add a description, about section and profile picture for
					your bot, see /help for a list of commands.
				</Box>
				<Box component="span" className="messageExample-msg-article-2">
					By the way, when you&apos;ve finished creating your cool bot, ping our
					Bot Support if you want a better username for it. Just make sure the
					bot is fully operational before you do this.
				</Box>
			</Box>
		</StyledMessageExample>
	);
};
