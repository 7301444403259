import { type ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { type IDependenciesFromWrapperInput } from './utils';
import { type AppDispatch } from '../store';

export interface IInputChangeConfigV6 {
	setValueFn: ActionCreatorWithPayload<string, string>;
	validationFn: (value: string, params?: string[] | undefined) => boolean;
	setValidStatusFn: ActionCreatorWithPayload<boolean, string>;
}

export const checkInputV6 = (
	dependencies: IDependenciesFromWrapperInput,
	dispatch: AppDispatch,
	inputChangeConfigV6: IInputChangeConfigV6
): void => {
	const setValueAndDefineValidation = (
		setValueFn: ActionCreatorWithPayload<string, string>,
		validationFn: (value: string, params?: string[] | undefined) => boolean,
		setValidStatusFn: ActionCreatorWithPayload<boolean, string>
	): void => {
		dispatch(setValueFn(dependencies.valueInput));
		validationFn(dependencies.valueInput, dependencies.params)
			? dispatch(setValidStatusFn(true))
			: dispatch(setValidStatusFn(false));
	};
	setValueAndDefineValidation(
		inputChangeConfigV6.setValueFn,
		inputChangeConfigV6.validationFn,
		inputChangeConfigV6.setValidStatusFn
	);
};
