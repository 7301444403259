import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MainPage } from './pages/MainPage';
import { ChatbotGeneratorPage } from './pages/ChatbotGeneratorPage';
import { PersonalCabinetPage } from './pages/PersonalCabinetPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { closeMobileMenu } from './app/slices/openMobileMenuSlice';
import { ContactsPage } from './pages/ContactsPage';
import { ErrorPage } from './pages/ErrorPage';
import { loadAllInformationFromStorage } from './app/utils/storageUtils';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
// import { DetailedBotCard } from './components/DetailedBotCard/DetailedBotCard';
import { ChangeUserInfoPage } from './pages/ChangeUserInfoPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
// import { MutationObserverComponent } from './components/MutationObserver/MutationObserver';
import { type RootState } from './app/store';
import { AboutMainPage } from './pages/AboutPages/AboutMainPage';
import { AboutQuestionsPage } from './pages/AboutPages/AboutQuestionsPage';
import { NichesPage } from './pages/AboutPages/NichesPage';
import { SupportPage } from './pages/AboutPages/SupportPage';
import { PlatformsPage } from './pages/AboutPages/PlatformsPage';
import { PlanPage } from './pages/PlanPage';
import { AuthFromGithubAndFacebookPage } from './pages/AuthFromGithubAndFacebook';
import { TrainingBotPage } from './pages/TrainingBotPage';
import { MutationObserverReCaptchaComponent } from './components/MutationObserver/MutationObserverReCaptcha';
import { TelegramPage } from './pages/SocialPages/TelegramPage';
import { ViberPage } from './pages/SocialPages/ViberPage';
import { MessengerPage } from './pages/SocialPages/MessengerPage';
import { WhatsAppPage } from './pages/SocialPages/WhatsAppPage';

export const App = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const handleCloseMobileMenu = (): void => {
		dispatch(closeMobileMenu());
	};

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	useEffect(() => {
		loadAllInformationFromStorage(dispatch);
	}, []);

	useEffect(() => {
		const body = document.querySelector('body');
		if (body !== null) {
			body.style.background = currentTheme.mainRootBackground;
		}
	}, [currentTheme]);

	return (
		<Box component="div" className="app" onClick={handleCloseMobileMenu}>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				{/* <MutationObserverComponent /> */}
				<MutationObserverReCaptchaComponent />
				<Routes>
					<Route path="/" element={<MainPage />} />
					<Route path="/registration" element={<RegistrationPage />} />
					<Route path="/personal-cabinet" element={<PersonalCabinetPage />} />
					<Route path="/change-info" element={<ChangeUserInfoPage />} />
					<Route path="/chatbot-generator" element={<ChatbotGeneratorPage />} />
					<Route path="/contacts" element={<ContactsPage />} />
					<Route path="/forgot" element={<ForgotPasswordPage />} />
					<Route path="/about" element={<AboutMainPage />} />
					<Route path="/plan" element={<PlanPage />} />
					<Route path="/about/questions" element={<AboutQuestionsPage />} />
					<Route path="/about/niches" element={<NichesPage />} />
					<Route path="/about/platforms" element={<PlatformsPage />} />
					<Route path="/about/support" element={<SupportPage />} />
					<Route
						path="/social-integration/telegram"
						element={<TelegramPage />}
					/>
					<Route path="/social-integration/viber" element={<ViberPage />} />
					<Route
						path="/social-integration/messenger"
						element={<MessengerPage />}
					/>
					<Route
						path="/social-integration/whatsapp"
						element={<WhatsAppPage />}
					/>
					<Route path="/reset-password?" element={<ResetPasswordPage />} />
					<Route path="/chatbot/:id" element={<TrainingBotPage />} />
					{/* <Route path="/chatbot/change-bot/:id" element={<DetailedBotCard />} /> */}
					<Route
						path="/token/:token"
						element={<AuthFromGithubAndFacebookPage />}
					/>
					<Route path="/*" element={<ErrorPage />} />
				</Routes>
			</BrowserRouter>
		</Box>
	);
};
