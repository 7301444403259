import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as SortWhite } from '../../pic/sortWhite.svg';
import { ReactComponent as SortDark } from '../../pic/sortDark.svg';
import { useAppSelector, useIsDarkTheme } from '../../../../../app/hooks';
import { type RootState } from '../../../../../app/store';
// import { sortOrderForRefetching } from '../../../../../app/slices/trainingBotMenu/searchBar/searchBarValueSlice';
import { CustomTooltip } from '../../../CustomTooltip/CustomTooltip';
import { StyledSortComponentChoose } from './SortComponentChoose.style';

interface ISortComponentObject {
	title: string;
	sortQuery: string;
}

interface ISortComponentChooseProps {
	sortComponentsArray: ISortComponentObject[];
	sortOrderForRefetchingFn: (sortQuery: string) => void;
}

export const SortComponentChoose: React.FC<ISortComponentChooseProps> = ({
	sortComponentsArray,
	sortOrderForRefetchingFn,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const [isOpenSortPanel, setIsOpenSortPanel] = useState(false);

	const isDarkTheme = useIsDarkTheme();

	const handleOpenSortPanel = (): void => {
		setIsOpenSortPanel(true);
	};
	const handleCloseSortPanel = (): void => {
		setIsOpenSortPanel(false);
	};

	const handleSortSnippets = (sortQuery: string): void => {
		sortOrderForRefetchingFn(sortQuery);
		handleCloseSortPanel();
	};

	return (
		<StyledSortComponentChoose
			theme={theme}
			className="sortComponentChoose-wrapper"
			onMouseLeave={handleCloseSortPanel}
		>
			<CustomTooltip theme={theme} placement="top" title={`Sort snippets`}>
				<Box
					component="button"
					className="title-snippet-sort-button"
					onClick={handleOpenSortPanel}
				>
					{isDarkTheme ? <SortDark /> : <SortWhite />}
				</Box>
			</CustomTooltip>

			{isOpenSortPanel ? (
				<Box className="sortComponentChoose-list">
					{sortComponentsArray.map((currentSortOption) => (
						<Box
							component="div"
							className="sortOption"
							key={currentSortOption.sortQuery}
							onClick={() => {
								handleSortSnippets(currentSortOption.sortQuery);
							}}
						>
							{currentSortOption.title}
						</Box>
					))}
				</Box>
			) : (
				<></>
			)}
		</StyledSortComponentChoose>
	);
};
