// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IPeriodPriceInitialState {
	currentPeriod: string;
}

const initialState: IPeriodPriceInitialState = {
	currentPeriod: 'Month',
};

const togglePeriodPriceMenuSlice = createSlice({
	name: 'togglePeriodPriceMenu',
	initialState,
	reducers: {
		setCurrentPeriod(state, action: PayloadAction<string>) {
			state.currentPeriod = action.payload;
			return state;
		},
	},
});

export const { setCurrentPeriod } = togglePeriodPriceMenuSlice.actions;
export const togglePeriodPriceMenuReducer = togglePeriodPriceMenuSlice.reducer;
