/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialPaginationValuesHistoryMenu {
	totalResultsSnippetsHistoryMenu: number;
	viewOnPageHistoryMenu: string;
	startFetchPageHistoryMenu: number;
	finishFetchPageHistoryMenu: number;
	isValidViewOnPageHistoryMenu: boolean;
}

const initialState: IInitialPaginationValuesHistoryMenu = {
	totalResultsSnippetsHistoryMenu: 0,
	viewOnPageHistoryMenu: '10',
	startFetchPageHistoryMenu: 1,
	finishFetchPageHistoryMenu: 10,
	isValidViewOnPageHistoryMenu: true,
};

const paginationValuesHistoryMenuSlice = createSlice({
	name: 'paginationValuesHistoryMenuSlice',
	initialState,
	reducers: {
		setHistoryTotalResultsSnippets(state, action: PayloadAction<number>) {
			state.totalResultsSnippetsHistoryMenu = action.payload;
			return state;
		},
		setHistoryViewOnPage(state, action: PayloadAction<string>) {
			state.viewOnPageHistoryMenu = action.payload;
			return state;
		},
		setHistoryStartFetchPage(state, action: PayloadAction<number>) {
			state.startFetchPageHistoryMenu = action.payload;
			return state;
		},
		setHistoryFinishFetchPage(state, action: PayloadAction<number>) {
			state.finishFetchPageHistoryMenu = action.payload;
			return state;
		},

		setHistoryIsValidViewOnPage(state, action: PayloadAction<boolean>) {
			state.isValidViewOnPageHistoryMenu = action.payload;
			return state;
		},
	},
});

export const {
	setHistoryTotalResultsSnippets,
	setHistoryViewOnPage,
	setHistoryStartFetchPage,
	setHistoryFinishFetchPage,
	setHistoryIsValidViewOnPage,
} = paginationValuesHistoryMenuSlice.actions;
export const paginationValuesHistoryMenuReducer =
	paginationValuesHistoryMenuSlice.reducer;
