import React from 'react';
import { useCheckAuth } from '../app/hooks';
import { Navigate } from 'react-router-dom';
import { ChangeUserInfoMenu } from '../components/ChangeUserInfoMenu/ChangeUserInfoMenu';
import { LayoutAccount } from '../components/shared/LayoutAccount/LayoutAccount';

export const ChangeUserInfoPage: React.FC = () => {
	const isAuthorization = useCheckAuth();

	return (
		<LayoutAccount title="Account Settings">
			{isAuthorization ? (
				<ChangeUserInfoMenu />
			) : (
				<Navigate to="/" replace={true} />
			)}
		</LayoutAccount>
	);
};
