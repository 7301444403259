import React from 'react';
import { Box } from '@mui/material';
import { StyledViberMenu } from './ViberMenu.style';
import {
	type IStepElementProps,
	StepElement,
} from '../../shared/StepElement/StepElement';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { useWindowSize } from '../../../../app/hooks/useWindowSize';
import { MessageExampleViber } from './additional/MessageExample/MessageExampleViber';
import { InputSendToken } from '../../shared/InputSendToken/InputSendToken';
import { ChatbotsTable } from '../../shared/ChatbotsTable/ChatbotsTable';
import { HelpIntegrate } from '../../shared/HelpIntegrate/HelpIntegrate';

export const ViberMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const windowSize = useWindowSize();
	const innerWindowWidth = windowSize[0];

	const steps: IStepElementProps[] = [
		{
			index: 1,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Go to&nbsp;
					<Box
						component="a"
						className="step-Element-link"
						href="https://partners.viber.com/account/create-bot-account"
						target="blank"
					>
						https://partners.viber.com/account/create-bot-account
					</Box>
				</Box>
			),
			stepList: [],
		},
		{
			index: 2,
			header: 'Register if needed',
			stepList: [],
		},
		{
			index: 3,
			header: 'Set up your bot information.',
			stepList: [
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{ position: 'relative' }}
						>
							At the end you will be provided with token:
							<MessageExampleViber />
						</Box>
					),
					indentation: innerWindowWidth > 800 ? 400 : 10,
				},
			],
		},
		{
			index: 4,
			header: 'Copy the token and paste it on chatbot-generator website',
			stepList: [
				{
					stepListElement: <InputSendToken uniqueName="Viber" />,
					withoutPointDot: true,
				},
			],
		},
	];

	return (
		<StyledViberMenu theme={theme}>
			<ChatbotsTable keyForDataDisplay="Viber" />

			<Box component="div" className="integrationMenu-header">
				To integrate Viber with Coderfy Chatbot you need:
				{windowSize[0] > 1200 && <HelpIntegrate />}
			</Box>
			{steps.map((step) => (
				<StepElement
					key={step.index}
					index={step.index}
					stepList={step.stepList}
					header={step.header}
				/>
			))}
			{windowSize[0] <= 1200 && <HelpIntegrate />}
		</StyledViberMenu>
	);
};
