import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as Edit } from '../../../pic/editBlue.svg';
import { ReactComponent as Delete } from '../../../pic/deleteBlue.svg';
import { type IIDataSnippetsRows } from '../SnippetsTable';
import { colorCircle } from '../GridCard';
import { SharedCheckboxItem } from '../../../../shared/SharedCheckboxItem/SharedCheckboxItem';
import { useOpenModalEdit } from '../../../hooks/openModal/useOpenModalEdit';
import { useOpenModalDelete } from '../../../hooks/openModal/useOpenModalDelete';
import { useOpenModalCache } from '../../../hooks/openModal/useOpenModalCache';
import CachedIcon from '@mui/icons-material/Cached';
import { useAppSelector } from '../../../../../app/hooks';
import { type RootState } from '../../../../../app/store';
import { useAddRemoveCurrentSnippetAsChecked } from '../../../hooks/useAddRemoveCurrentSnippetAsChecked';

export interface IGridCardProps {
	row: IIDataSnippetsRows;
}

export const MobileGridCard: React.FC<IGridCardProps> = ({ row }) => {
	const openModalEdit = useOpenModalEdit();
	const openModalDelete = useOpenModalDelete();
	const openModalCache = useOpenModalCache();

	const allCheckedArray = useAppSelector(
		(state: RootState) => state.checkedSnippetsMenu.checkedSnippetsArray
	);
	const allFunctionsWithCheckedArray = useAddRemoveCurrentSnippetAsChecked();
	const { addCurrentSnippetAsChecked, removeCurrentSnippetAsChecked } =
		allFunctionsWithCheckedArray;

	const handleClickOnEdit = (): void => {
		openModalEdit(row.id.toString(), row.prompt, row.completion);
	};

	const handleClickOnDelete = (): void => {
		openModalDelete(row.id.toString());
	};

	const handleClickOnCache = (): void => {
		openModalCache(row.id.toString());
	};

	const [isChecked, setIsChecked] = useState(false);

	const handleAddRemoveCurrentSnippetAsChecked = (): void => {
		const isMatch = allCheckedArray.find(
			(currentSnippet) => currentSnippet === row.id
		);
		if (isMatch !== undefined && isChecked) {
			removeCurrentSnippetAsChecked(row.id);
		} else if (isMatch === undefined && !isChecked) {
			addCurrentSnippetAsChecked(row.id);
		}
	};

	useEffect(() => {
		allCheckedArray.length === 0 && setIsChecked(false);
		const isMatch = allCheckedArray.find(
			(currentSnippet) => currentSnippet === row.id
		);
		if (isMatch !== undefined) {
			setIsChecked(true);
		} else if (isMatch === undefined) {
			setIsChecked(false);
		}
	}, [allCheckedArray]);

	return (
		<Box component="div" className="grid-card-wrapper">
			<Box component="div" className="grid-card-accent-line">
				<Box component="div" className="grid-card-item-left">
					Prompt
				</Box>
				<Box
					component="div"
					sx={{ display: 'flex', alignItems: 'center' }}
					className="grid-card-item-right"
				>
					{/* Hide checkbox */}
					<SharedCheckboxItem
						setStateAction={handleAddRemoveCurrentSnippetAsChecked}
						label=""
						className="snippet-checkbox"
						isChecked={isChecked}
					/>
					<Box>{row.prompt}</Box>
				</Box>
			</Box>
			<Box component="div" className="grid-card-line">
				<Box component="div" className="grid-card-item-left">
					URL
				</Box>
				<Box component="div" className="grid-card-item-right">
					{row.url}
				</Box>
			</Box>
			{/* Hide bot name */}
			{/* <Box component="div" className="grid-card-line">
				<Box component="div" className="grid-card-item-left">
					Bot name
				</Box>
				<Box component="div" className="grid-card-item-right">
					{row.botName}
				</Box>
			</Box> */}

			<Box component="div" className="grid-card-line">
				<Box component="div" className="grid-card-item-left">
					Created
				</Box>
				<Box component="div" className="grid-card-item-right">
					{row.created}
				</Box>
			</Box>
			{/* Hide cashed and pushed */}
			<Box component="div" className="grid-card-line">
				<Box component="div" className="grid-card-item-left">
					Cashed
				</Box>
				<Box component="div" className="grid-card-item-right">
					{colorCircle(row.cashed)}
				</Box>
			</Box>
			{/* <Box component="div" className="grid-card-line">
				<Box component="div" className="grid-card-item-left">
					Pushed
				</Box>
				<Box component="div" className="grid-card-item-right">
					{colorCircle(row.pushed)}
				</Box>
			</Box> */}
			<Box component="div" className="grid-card-line">
				<Box component="div" className="grid-card-item-left">
					Vectorized
				</Box>
				<Box component="div" className="grid-card-item-right">
					{colorCircle(row.vectorized)}
				</Box>
			</Box>

			<Box component="div" className="grid-card-line">
				<Box component="div" className="grid-card-item-left">
					Actions
				</Box>
				<Box component="div" className="grid-card-item-right actions-buttons">
					<CachedIcon
						onClick={() => {
							if (!row.cashed) {
								handleClickOnCache();
							}
						}}
						className={row.cashed ? 'disabled-svg' : ''}
					/>
					<Edit onClick={handleClickOnEdit} />
					<Delete onClick={handleClickOnDelete} />
				</Box>
			</Box>
		</Box>
	);
};
