import React from 'react';
import { StyledSharedNavLink } from './SharedNavlink.style';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

interface ISharedNavLinkProps {
	link: string;
	text: string;
	accent?: string;
}

export const SharedNavLink: React.FC<ISharedNavLinkProps> = ({
	link,
	text,
	accent,
}) => {
	return (
		<StyledSharedNavLink>
			<NavLink to={link}>
				<Box component="span" className="white-text">
					<Box component="span" className="white-text">
						{text}
					</Box>
					<Box component="span" className="blue-text">
						{accent}
					</Box>
				</Box>
			</NavLink>
		</StyledSharedNavLink>
	);
};
