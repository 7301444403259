import styled from 'styled-components';

export const StyledHistoryPayment = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 90%;
	padding: 24px 0px 41px 0px;
	border-top: ${(props) => props.theme.currentTheme.botBorder};

	& .grid-row-wrapper {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 25px;

		display: flex;
		gap: 1rem;
		width: 90%;
		/* width: max-content; */
		align-items: center;
		justify-content: space-between;
		animation: appear 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

		& > div {
			width: 196px;
		}

		& .grid-date,
		.grid-plan,
		.grid-price {
			width: 104px;
		}

		& .grid-transaction {
			display: flex;
			align-items: center;
			gap: 1rem;
		}
		/* & .grid-status {
    max-width: 104px;
  } */

		& .isTitle {
			color: ${(props) => props.theme.currentTheme.halfTransparentFontColor};
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 25px;
		}

		& .grid-transaction.isValue {
			/* border-radius: 8px;
			padding: 4px 16px;
			transition: all .3s ease-in;
			&:hover {
				background: ${(props) => props.theme.currentTheme.accentHighlightColorForTable};
				cursor: pointer;
			}
			&:active {
				transform: scale(0.95);
			} */
		}
	}

	& .grid-row-wrapper.isValue {
		border-radius: 8px;
		padding: 4px 0px;
		transition: all 0.3s ease-in;
		&:hover {
			background: ${(props) =>
				props.theme.currentTheme.accentHighlightColorForTable};
			cursor: pointer;
		}
		&:active {
			transform: scale(0.95);
		}
	}

	& .paginationTableSnippets {
		padding-top: 1rem;
		justify-content: center;
		display: flex;
	}

	@keyframes appear {
		0% {
			opacity: 0;
			transform: translateY(-100px);
		}
		30% {
			opacity: 0;
		}
		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	@media screen and (max-width: 1000px) {
		padding: 2rem;
		width: 100%;
		overflow: scroll;
		& .grid-row-wrapper {
			width: 700px;
		}
	}
`;
