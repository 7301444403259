import React from 'react';
import { Box } from '@mui/material';
import facebookPic from '../pic/facebook.svg';

export const LoginWithFacebook: React.FC = () => {
	const baseUrl = process.env.REACT_APP_BASE_LINK;
	const currentHref = window.location.href;

	return (
		<Box
			component="button"
			className="LoginWithFacebook-button"
			onClick={() => {
				window.location.replace(
					`${
						baseUrl !== undefined ? baseUrl : ''
					}/auth/facebook?source=${currentHref}`
				);
			}}
		>
			<Box component="img" src={facebookPic} alt="facebook-pic" />
		</Box>
	);
};
