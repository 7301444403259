import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledConfigModelMenu } from './ConfigModelMenu.style';
import { SaveDeleteBntFieldShared } from '../../shared/SaveDeleteBntFieldShared/SaveDeleteBntFieldShared';
import { ReactComponent as Pencil } from './pic/pencil.svg';
import { ReactComponent as Man } from './pic/man.svg';
import { ReactComponent as Support } from './pic/support.svg';
import { ReactComponent as Protect } from './pic/protect.svg';
import { ReactComponent as World } from './pic/world.svg';
import { ConfigWrappedInput } from './ConfigWrappedInput/ConfigWrappedInput';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { SharedCheckboxItem } from '../../shared/SharedCheckboxItem/SharedCheckboxItem';
import { setEmptyStatusForCheckConfigModelMenu } from '../../../app/slices/configInputs/configModelMenuInputs/checkEmptyStatusForModelMenuInputsSlice';
import {
	useGetConfigQuery,
	useUpdateConfigMutation,
} from '../../../app/api/chatbot.api';
import { useLocation } from 'react-router-dom';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import { DataAlert } from '../../shared/SharedDataAlertFromApi/SharedDataAlertFromApi';
import { type IUpdateConfigRequestBody } from '../../../app/api/interfaces/config/updateConfig.interface';
import { temporaryParams } from '../../ChatbotGeneratorMenu/AddStilizationComponent/AddStylizationInputsField';
import { ProgressSpinner } from '../../shared/ProgressSpinner/ProgressSpinner';

export const ConfigModelMenu: React.FC = () => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const pressedButton = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForConfigModelMenu
	);
	const isValidInputs = useAppSelector(
		(state: RootState) => state.isValidModelMenuInputs
	);
	const valueConfigModelMenuInputs = useAppSelector(
		(state: RootState) => state.configModelMenuInputs
	);

	const {
		// website,
		flavor,
		prefix,
		reCaptchaKey,
		reCaptchaSecretKey,
		reCaptchaTemperature,
		greeting,
	} = valueConfigModelMenuInputs;

	const {
		// isValidWebsiteConfigModelMenu,
		isValidFlavorConfigModelMenu,
		isValidGreetingConfigModelMenu,
		isValidPrefixConfigModelMenu,
		isValidReCaptchaKeyConfigModelMenu,
		isValidReCaptchaSecretKeyConfigModelMenu,
		isValidReCaptchaTemperatureConfigModelMenu,
	} = isValidInputs;

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const currentId = useLocation().pathname.split('/');
	const currentIdComputed = parseFloat(currentId[currentId.length - 1]);
	const {
		data: currentBotConfigData,
		isLoading: isLoadingCurrentBotConfigData,
		isError: isErrorCurrentBotConfigData,
		isSuccess: isSuccessCurrentBotConfigData,
		refetch: refetchCurrentBotConfigData,
	} = useGetConfigQuery({ chatbotId: currentIdComputed.toString(), token });

	const [isCached, setIsCached] = useState(currentBotConfigData?.cached === 1);
	useEffect(() => {
		setIsCached(currentBotConfigData?.cached === 1);
	}, [currentBotConfigData]);

	const [updateChatbotConfig, resultOfUpdateConfig] = useUpdateConfigMutation();

	const dispatch = useAppDispatch();

	const executeUpdateBotSettings = async (): Promise<void> => {
		if (
			isValidGreetingConfigModelMenu &&
			isValidPrefixConfigModelMenu &&
			isValidReCaptchaTemperatureConfigModelMenu &&
			isValidFlavorConfigModelMenu
		) {
			const cachedForCheck = isCached ? 1 : 0;

			const arrayForUpdateConfigRequestBody = [
				{ value: greeting, key: 'greeting', type: 'string' },
				{ value: prefix, key: 'prefix', type: 'string' },
				{ value: reCaptchaTemperature, key: 'recaptcha', type: 'number' },
				{ value: flavor, key: 'system_message', type: 'string' },
				{ value: reCaptchaKey, key: 'recaptcha_key', type: 'string' },
				{ value: reCaptchaSecretKey, key: 'recaptcha_secret', type: 'string' },
				{ value: cachedForCheck, key: 'cached', type: 'number' },
			];

			const updateConfigRequestBody: IUpdateConfigRequestBody = {};

			const descriptionProperty = {
				configurable: true,
				enumerable: true,
				writable: true,
			};

			for (const updateValue of arrayForUpdateConfigRequestBody) {
				if (currentBotConfigData !== undefined) {
					const currentValue =
						currentBotConfigData[
							updateValue.key as keyof typeof currentBotConfigData
						];

					if (updateValue.value !== currentValue) {
						const value =
							updateValue.type === 'string'
								? updateValue.value
								: updateValue.key === 'cached'
								? cachedForCheck
								: parseFloat(updateValue.value.toString());

						Object.defineProperty(updateConfigRequestBody, updateValue.key, {
							...descriptionProperty,
							value,
						});
					}
				}
			}

			if (Object.keys(updateConfigRequestBody).length > 0) {
				try {
					const result = await updateChatbotConfig({
						token,
						chatbotId: currentIdComputed.toString(),
						updateConfigRequestBody,
					});
					if ('data' in result) {
						setLoadingErrorContent(
							<DataAlert text="Your chatbot settings have been updated" />
						);
						void refetchCurrentBotConfigData();
					} else {
						setLoadingErrorContent(
							<DataAlert text="Something went wrong" isError />
						);
					}
				} catch (error) {
					setLoadingErrorContent(
						<DataAlert text="Something went wrong" isError />
					);
				}
			} else {
				setLoadingErrorContent(<DataAlert text="Nothing to change" />);
			}
		} else {
			setLoadingErrorContent(
				<DataAlert
					text="Please fill in all required information fields"
					isError
				/>
			);
		}
	};

	const [
		isOpenModalAfterUpdateBotSettings,
		setIsOpenModalAfterUpdateBotSettings,
	] = useState(false);

	const handleCloseModal = (): void => {
		setIsOpenModalAfterUpdateBotSettings(false);
	};
	const handleOpenModalUpdate = (): void => {
		dispatch(setEmptyStatusForCheckConfigModelMenu(true));
		setIsOpenModalAfterUpdateBotSettings(true);
		void executeUpdateBotSettings();
	};

	useEffect(() => {
		dispatch(setEmptyStatusForCheckConfigModelMenu(false));
	}, []);

	const leftConfigs = [
		// {
		// 	title: 'Website',
		// 	changeableInput: {
		// 		type: 'text',
		// 		placeholder: 'HTTP://',
		// 		uniqueNameOfInput: 'website-model-menu-changeableInput',
		// 		isValid: isValidWebsiteConfigModelMenu,
		// 		value: currentBotData !== undefined ? currentBotData.domain : '',
		// 		icon: world,
		// 		disabled: true,
		// 	},
		// },
		{
			title: 'reCaptcha temperature',
			changeableInput: {
				type: 'number',
				placeholder: 'reCaptcha temperature',
				uniqueNameOfInput: 'reCaptcha-temperature-model-menu-changeableInput',
				isValid: isValidReCaptchaTemperatureConfigModelMenu,
				value:
					currentBotConfigData?.recaptcha != null
						? currentBotConfigData.recaptcha
						: '',
				// icon: world,
				iconSVG: <World />,
				minNumber: 0,
				maxNumber: 1,
				stepNumber: 0.1,
				isFloat: { maxCountInteger: 1, maxCountFloat: 3 },
				tooltip:
					'This parameter affects the degree of protection against automatic requests. Please enter a value between 0 and 1',
			},
		},
		{
			title: 'Greeting',
			changeableInput: {
				type: 'text',
				placeholder: 'Greeting',
				uniqueNameOfInput: 'greeting-model-menu-changeableInput',
				isValid: isValidGreetingConfigModelMenu,
				value:
					currentBotConfigData?.greeting != null
						? currentBotConfigData.greeting
						: '',
				iconSVG: <Pencil />,
				// icon: pencil,
				tooltip: 'Greeting to welcome user with',
			},
		},
		{
			title: 'reCAPTCHA',
			changeableInput: {
				type: 'text',
				placeholder: 'reCAPTCHA',
				uniqueNameOfInput: 'reCAPTCHA-model-menu-changeableInput',
				isValid: isValidReCaptchaKeyConfigModelMenu,
				value:
					currentBotConfigData?.recaptcha_key != null
						? currentBotConfigData.recaptcha_key
						: '',
				iconSVG: <Protect />,
				// icon: protect,
				tooltip: 'Own reCaptcha key',
			},
		},
	];

	const rightConfigs = [
		{
			title: 'Flavor',
			changeableInput: {
				type: 'autocomplete',
				params: temporaryParams,
				placeholder: 'Flavor...',
				uniqueNameOfInput: 'flavor-model-menu-changeableInput',
				isValid: isValidFlavorConfigModelMenu,
				value:
					currentBotConfigData?.system_message != null
						? currentBotConfigData.system_message
						: '',
				iconSVG: <Support />,
				// icon: support,
				tooltip: 'The parameter affects the tone of the chatbot communication',
			},
		},
		{
			title: 'Prefix',
			changeableInput: {
				type: 'text',
				placeholder: 'Prefix',
				uniqueNameOfInput: 'prefix-model-menu-changeableInput',
				isValid: isValidPrefixConfigModelMenu,
				value:
					currentBotConfigData?.prefix != null
						? currentBotConfigData.prefix
						: '',
				iconSVG: <Man />,
				// icon: man,
				tooltip: 'Prefix inserted before all prompts',
			},
		},
		{
			title: 'reCAPTCHA key',
			changeableInput: {
				type: 'text',
				placeholder: 'reCAPTCHA key',
				uniqueNameOfInput: 'reCAPTCHA-key-model-menu-changeableInput',
				isValid: isValidReCaptchaSecretKeyConfigModelMenu,
				value:
					currentBotConfigData?.recaptcha_secret != null
						? currentBotConfigData.recaptcha_secret
						: '',
				iconSVG: <Protect />,
				// icon: protect,
				tooltip: 'Own reCaptcha secret key',
			},
		},
	];

	const theme = { currentTheme };

	return (
		<StyledConfigModelMenu className="styledConfigModelMenu" theme={theme}>
			{isLoadingCurrentBotConfigData ? (
				<ProgressSpinner />
			) : isErrorCurrentBotConfigData ? (
				<Box>Something went wrong</Box>
			) : isSuccessCurrentBotConfigData ? (
				<Box component="div" className="styledConfigModelMenu-wrapper">
					<Box component="div" className="configs-wrapper">
						<Box component="div" className="left-configs">
							{leftConfigs.map((currentConfig) => (
								<ConfigWrappedInput
									key={currentConfig.title}
									title={currentConfig.title}
									changeableInput={currentConfig.changeableInput}
									isEmpty={pressedButton}
								/>
							))}
						</Box>
						<Box component="div" className="right-configs">
							{rightConfigs.map((currentConfig) => (
								<ConfigWrappedInput
									key={currentConfig.title}
									title={currentConfig.title}
									changeableInput={currentConfig.changeableInput}
									isEmpty={pressedButton}
								/>
							))}
						</Box>
					</Box>
					<Box component="div" className="checkbox-field">
						<SharedCheckboxItem
							setStateAction={() => {
								setIsCached(!isCached);
							}}
							label="Cache all snippets"
							className="styledConfigModelMenu-cache-checkbox"
							isChecked={isCached}
						/>
					</Box>
					<Box component="div" className="btn-field">
						<SaveDeleteBntFieldShared
							handleOpenModalUpdate={handleOpenModalUpdate}
							// TEMPORARY STATE
							statusState={true}
							textSaveBtn="Save"
							textTooltipSaveBtn="Update current information"
							isOnlySave
						/>
					</Box>
				</Box>
			) : (
				<></>
			)}
			{isOpenModalAfterUpdateBotSettings && (
				<SharedModal
					executeFn={() => {}}
					isDeleteModal={false}
					closeFn={() => {
						handleCloseModal();
					}}
					message={loadingErrorContent}
					isOnlyAlert={true}
					title={
						resultOfUpdateConfig.isLoading
							? 'Please, wait'
							: resultOfUpdateConfig.isError
							? 'Warning'
							: resultOfUpdateConfig.isSuccess
							? 'Succeed'
							: ''
					}
				/>
			)}
		</StyledConfigModelMenu>
	);
};
