/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialCurrentChatbotIdForActionsTrainingBotMenu {
	currentChatbotIdForTest: string;
	currentChatbotIdForVectorise: string;
	currentChatbotIdForHistory: string;
}

const initialState: IInitialCurrentChatbotIdForActionsTrainingBotMenu = {
	currentChatbotIdForTest: '',
	currentChatbotIdForVectorise: '',
	currentChatbotIdForHistory: '',
};

const currentChatbotIdForActionsTrainingBotMenuSlice = createSlice({
	name: 'currentChatbotIdForActionsTrainingBotMenu',
	initialState,
	reducers: {
		setCurrentChatbotIdForTest(state, action: PayloadAction<string>) {
			state.currentChatbotIdForTest = action.payload;
			return state;
		},
		setCurrentChatbotIdForVectorise(state, action: PayloadAction<string>) {
			state.currentChatbotIdForVectorise = action.payload;
			return state;
		},
		setCurrentChatbotIdForHistory(state, action: PayloadAction<string>) {
			state.currentChatbotIdForHistory = action.payload;
			return state;
		},
	},
});

export const {
	setCurrentChatbotIdForTest,
	setCurrentChatbotIdForVectorise,
	setCurrentChatbotIdForHistory,
} = currentChatbotIdForActionsTrainingBotMenuSlice.actions;
export const currentChatbotIdForActionsTrainingBotMenuReducer =
	currentChatbotIdForActionsTrainingBotMenuSlice.reducer;
