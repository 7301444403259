import React, { useRef, useState } from 'react';
import ReactCrop, { type PixelCrop, type Crop } from 'react-image-crop';
import { useGetCroppedImg } from '../../../app/customHooks/imageHooks';
import 'react-image-crop/dist/ReactCrop.css';

export interface ICompletedCropValues {
	unit: string;
	width: number;
	height: number;
	x: number;
	y: number;
}

interface ICropImageComponentProps {
	src: string;
	completedCrop: ICompletedCropValues | undefined;
	setCompletedCrop: React.Dispatch<
		React.SetStateAction<ICompletedCropValues | undefined>
	>;
	isCreationChatbot: boolean;
}

export const CropImageComponent: React.FC<ICropImageComponentProps> = ({
	src,
	completedCrop,
	setCompletedCrop,
	isCreationChatbot,
}) => {
	const userImageContainer = useRef<HTMLImageElement | null>(null);

	const [crop, setCrop] = useState<Crop>({
		unit: 'px',
		width: 150,
		height: 150,
		x: 50,
		y: 50,
	});
	const [completedInnerCrop, setCompletedInnerCrop] = useState<PixelCrop>();

	const { getCroppedImg } = useGetCroppedImg(
		userImageContainer.current,
		completedInnerCrop !== undefined
			? completedInnerCrop
			: {
					unit: 'px',
					width: 100,
					height: 100,
					x: 100,
					y: 100,
			  },
		isCreationChatbot
	);
	getCroppedImg();

	return (
		<ReactCrop
			crop={crop}
			onChange={(c) => {
				setCrop(c);
			}}
			circularCrop={true}
			aspect={1}
			onComplete={(c) => {
				setCompletedCrop(c);
				setCompletedInnerCrop(c);
			}}
		>
			<img src={src} ref={userImageContainer} />
		</ReactCrop>
	);
};
