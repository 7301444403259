import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { SharedPasswordInput } from '../../shared/Input/SharedPasswordInput/SharedPasswordInput';
import { InputsFieldWrapper } from '../../shared/Input/InputsFieldWrapper/InputsFieldWrapper';
import { WrappedInput } from '../../shared/Input/WrappedInput/WrappedInput';

export const InputsFieldLogin: React.FC = () => {
	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForLogin
	);
	const validInputsStatus = useAppSelector(
		(state: RootState) => state.isValidInputs
	);
	const { isValidEmailLogin, isValidPasswordLogin } = validInputsStatus;

	return (
		<InputsFieldWrapper>
			<WrappedInput
				type="email"
				placeholder="E-mail"
				isEmpty={isButtonPressed}
				uniqueNameOfInput="e-mail-login"
				isValid={isValidEmailLogin}
			/>
			<SharedPasswordInput
				withIcons={false}
				isEmpty={isButtonPressed}
				uniqueNameOfInput="password-login"
				isValid={isValidPasswordLogin}
			/>
		</InputsFieldWrapper>
	);
};
