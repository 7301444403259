// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialTestBotInputValueState {
	question: string;
	completion: string;
}

const initialState: IInitialTestBotInputValueState = {
	question: '',
	completion: '',
};

const testBotInputValueSlice = createSlice({
	name: 'testBotInputValue',
	initialState,
	reducers: {
		setTestBotInputValue(state, action: PayloadAction<string>) {
			state.question = action.payload;
			return state;
		},
		setTestBotCompletionInputValue(state, action: PayloadAction<string>) {
			state.completion = action.payload;
			return state;
		},
	},
});

export const { setTestBotInputValue, setTestBotCompletionInputValue } =
	testBotInputValueSlice.actions;
export const testBotInputValueReducer = testBotInputValueSlice.reducer;
