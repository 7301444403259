import { Box } from '@mui/material';
import React from 'react';

export const DataAlert: React.FC<{ text: string; isError?: boolean }> = ({
	text,
	isError,
}) => (
	<Box
		component="div"
		className={`error-message-from-api ${
			isError !== undefined ? 'error-alert' : ''
		}`}
		sx={{
			color: isError ?? false ? 'red !important' : '#1592EC !important',
			textAlign: 'center',
		}}
	>
		{text}
	</Box>
);
