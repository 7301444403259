import styled from 'styled-components';

export const StyledWebhookToCopy = styled.div`
	color: ${(props) => props.theme.currentTheme.coderfyAccentPrimaryColor};
	border: 1px solid ${(props) => props.theme.currentTheme.coderfySecondaryColor};
	border-radius: 8px;
	padding: 0.5rem 1rem;
	width: 470px;
	hyphens: auto;
	word-break: break-all;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background: ${(props) => props.theme.currentTheme.integratePrimary};
	}
	&:active {
		color: #fff;
		background: ${(props) =>
			props.theme.currentTheme.coderfyAccentPrimaryColor};
	}

	@media screen and (max-width: 600px) {
		width: 100%;
	}
`;
