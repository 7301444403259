import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialCurrentSnippetTrainingBotMenu {
	currentSnippetId: string;
}

const initialState: IInitialCurrentSnippetTrainingBotMenu = {
	currentSnippetId: '',
};

const currentSnippetTrainingBotMenuSlice = createSlice({
	name: 'currentSnippetTrainingBotMenu',
	initialState,
	reducers: {
		setCurrentSnippetNumber(state, action: PayloadAction<string>) {
			state.currentSnippetId = action.payload;
			return state;
		},
	},
});

export const { setCurrentSnippetNumber } =
	currentSnippetTrainingBotMenuSlice.actions;
export const currentSnippetTrainingBotMenuReducer =
	currentSnippetTrainingBotMenuSlice.reducer;
