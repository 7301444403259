import {
	type BaseQueryApi,
	type FetchArgs,
	type FetchBaseQueryError,
	type FetchBaseQueryMeta,
	createApi,
	fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { type IGetStripeProductsResponse } from './interfaces/getStripeProducts.interface';
import { type QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { refreshTokenExecute } from '../chatbot.api';
import { type RootState } from '../../store';
import {
	type ICreateSubscriptionIntentResponse,
	type ICreateSubscriptionIntentRequestBodyWithToken,
} from './interfaces/createSubscriptionIntent.interface';
import { type IGetPaymentMethodsResponse } from './interfaces/getPaymentMethods.interface';
import {
	type IGetSubscriptionStatusRequest,
	type IGetSubscriptionStatusResponse,
} from './interfaces/getSubscriptionStatus.interface';
import {
	type IDeleteSubscriptionByIdResponse,
	type IDeleteSubscriptionByIdRequest,
} from './interfaces/deleteSubscriptionById.interface';
import {
	type IPaymentMethodIdWithTokenResponse,
	type IPaymentMethodIdWithTokenRequest,
} from './interfaces/setPaymentMethodAsDefault.interface';
import {
	type IAddPaymentMethodRequestResponse,
	type IAddPaymentMethodRequest,
} from './interfaces/addPaymentMethod.interface';
import {
	type IDeletePaymentMethodByIdRequest,
	type IDeletePaymentMethodByIdResponse,
} from './interfaces/deletePaymentMethodByID.interface';
import {
	type IGetSubscriptionBySubIdRequest,
	type IGetSubscriptionBySubIdResponse,
} from './interfaces/getSubscriptionBySubId.interface';
import {
	type IPurchaseFondyRecurrentRequest,
	type IPurchaseFondyRecurrentResponse,
} from './interfaces/purchaseFondyRecurrent.interface';
import {
	type IPurchaseFondyCheckoutRequest,
	type IPurchaseFondyCheckoutResponse,
} from './interfaces/purchaseFondyCheckout.interface';

const myHeaders = {
	'Access-Control-Allow-Origin': '*',
	'Content-Type': 'application/json',
	'Access-Control-Allow-Methods': 'POST, PATCH, DELETE, GET',
};

const query = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_BASE_LINK_PAYMENT,
});

const extendedQuery = async (
	args: FetchArgs,
	api: BaseQueryApi,
	extraOptions: object
): Promise<
	QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
> => {
	const result = await query(args, api, extraOptions);
	const tokenForRefresh = (api.getState() as RootState).answerApiLogin.token;

	if (result.meta?.response?.status === 401) {
		console.log('result data 401 status: ', result.meta?.response?.status);
		void refreshTokenExecute(
			tokenForRefresh,
			api,
			extraOptions,
			result,
			args,
			query
		);
	} else {
		console.log('result data other status: ', result.meta?.response?.status);
	}

	return result;
};

export const paymentApi = createApi({
	reducerPath: 'paymentApi',
	baseQuery: extendedQuery,
	endpoints: (builder) => ({
		// Create Subscription Intent
		createSubscriptionIntent: builder.mutation<
			ICreateSubscriptionIntentResponse,
			ICreateSubscriptionIntentRequestBodyWithToken
		>({
			query: (
				subscriptionIntentRequestBodyWithToken: ICreateSubscriptionIntentRequestBodyWithToken
			) => ({
				url: `/stripe/subscription`,
				method: 'POST',
				body: JSON.stringify(
					subscriptionIntentRequestBodyWithToken.subscriptionIntentRequestBody
				),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${subscriptionIntentRequestBodyWithToken.token}`,
				},
			}),
		}),

		// Get Payment Methods
		getPaymentMethods: builder.query<IGetPaymentMethodsResponse, string>({
			query: (token: string) => ({
				url: `/stripe/payment-methods`,
				method: 'GET',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${token}`,
				},
			}),
		}),

		// Set Payment Method as default
		setPaymentMethodAsDefault: builder.query<
			IPaymentMethodIdWithTokenResponse,
			IPaymentMethodIdWithTokenRequest
		>({
			query: (paymentMethodIdWithToken: IPaymentMethodIdWithTokenRequest) => ({
				url: `/stripe/payment-methods/set-as-default/${paymentMethodIdWithToken.paymentMethodId}`,
				method: 'GET',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${paymentMethodIdWithToken.token}`,
				},
			}),
		}),

		// Add Payment Method
		addPaymentMethod: builder.mutation<
			IAddPaymentMethodRequestResponse,
			IAddPaymentMethodRequest
		>({
			query: (addPaymentMethodWithToken: IAddPaymentMethodRequest) => ({
				url: `/stripe/payment-methods`,
				method: 'POST',
				body: JSON.stringify(addPaymentMethodWithToken.addPaymentMethodBody),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${addPaymentMethodWithToken.token}`,
				},
			}),
		}),

		// Delete Payment Method by id
		deletePaymentMethodByID: builder.mutation<
			IDeletePaymentMethodByIdResponse,
			IDeletePaymentMethodByIdRequest
		>({
			query: (
				deletePaymentMethodByIdData: IDeletePaymentMethodByIdRequest
			) => ({
				url: `/stripe/payment-methods/${deletePaymentMethodByIdData.paymentMethodId}`,
				method: 'DELETE',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${deletePaymentMethodByIdData.token}`,
				},
			}),
		}),

		// Get Subscription Status by category "active" | "all" | "canceled" | "ended" | "incomplete" | "incomplete_expired" | "past_due" | "paused" | "trialing" | "unpaid";
		getSubscriptionStatus: builder.query<
			IGetSubscriptionStatusResponse,
			IGetSubscriptionStatusRequest
		>({
			query: (getSubscriptionStatusData: IGetSubscriptionStatusRequest) => ({
				url: `/stripe/subscription/status/${getSubscriptionStatusData.status}?limit=100`,
				method: 'GET',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getSubscriptionStatusData.token}`,
				},
			}),
		}),

		// get subscripttion by sub id
		getSubscriptionBySubId: builder.query<
			IGetSubscriptionBySubIdResponse,
			IGetSubscriptionBySubIdRequest
		>({
			query: (
				getSubscriptionBySubIdResponse: IGetSubscriptionBySubIdRequest
			) => ({
				url: `/stripe/subscription/${getSubscriptionBySubIdResponse.subscriptionId}`,
				method: 'GET',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getSubscriptionBySubIdResponse.token}`,
				},
			}),
		}),

		// Delete Subscription by id
		deleteSubscriptionById: builder.mutation<
			IDeleteSubscriptionByIdResponse,
			IDeleteSubscriptionByIdRequest
		>({
			query: (deleteSubscriptionByIdData: IDeleteSubscriptionByIdRequest) => ({
				url: `/stripe/subscription/${deleteSubscriptionByIdData.subscriptionId}`,
				method: 'DELETE',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${deleteSubscriptionByIdData.token}`,
				},
			}),
		}),

		// Get stripe products
		getStripeProducts: builder.query<IGetStripeProductsResponse[], string>({
			query: () => ({
				url: `/stripe/product`,
				method: 'GET',
				headers: { ...myHeaders },
			}),
		}),

		/* 
			--- Endpoints for FONDY ---
		*/

		// purchase Recurrent with saved card - Fondy
		purchaseFondyRecurrent: builder.mutation<
			IPurchaseFondyRecurrentResponse,
			IPurchaseFondyRecurrentRequest
		>({
			query: (purchaseFondyRecurrent: IPurchaseFondyRecurrentRequest) => ({
				url: `/fondy/recurrent`,
				method: 'POST',
				body: JSON.stringify(purchaseFondyRecurrent.purchaseInfo),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${purchaseFondyRecurrent.token}`,
				},
			}),
		}),

		// purchase Checkout with new card - Fondy
		purchaseFondyCheckout: builder.mutation<
			IPurchaseFondyCheckoutResponse,
			IPurchaseFondyCheckoutRequest
		>({
			query: (purchaseFondyCheckout: IPurchaseFondyCheckoutRequest) => ({
				url: `/fondy/checkout`,
				method: 'POST',
				body: JSON.stringify(purchaseFondyCheckout.purchaseInfo),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${purchaseFondyCheckout.token}`,
				},
			}),
		}),
	}),
});

export const {
	useGetStripeProductsQuery,
	useCreateSubscriptionIntentMutation,
	useGetSubscriptionStatusQuery,
	useLazyGetSubscriptionStatusQuery,
	useGetSubscriptionBySubIdQuery,
	useLazyGetSubscriptionBySubIdQuery,
	useDeleteSubscriptionByIdMutation,
	useGetPaymentMethodsQuery,
	useDeletePaymentMethodByIDMutation,
	useSetPaymentMethodAsDefaultQuery,
	useAddPaymentMethodMutation,
	/* 
		--- Endpoints for FONDY ---
	*/
	usePurchaseFondyRecurrentMutation,
	usePurchaseFondyCheckoutMutation,
} = paymentApi;
