import React from 'react';
import { LayoutAccount } from '../../components/shared/LayoutAccount/LayoutAccount';
import { AboutQuestionsMenu } from '../../components/AboutMenu/AboutQuestionsMenu/AboutQuestionsMenu';

export const AboutQuestionsPage: React.FC = () => {
	return (
		<LayoutAccount title="Frequently Asked Questions">
			<AboutQuestionsMenu />
		</LayoutAccount>
	);
};
