import React, { useEffect, useState } from 'react';
import { StyledSnippetsTable } from './SnippetsTable.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { GridCard } from './GridCard';
import { Box, FormControl } from '@mui/material';
// import { UrlTitle } from '../UrlTitle';
import { SortComponent } from '../../../shared/PaginationAndTableComponents/SortComponent/SortComponent';
import { SharedCheckboxItem } from '../../../shared/SharedCheckboxItem/SharedCheckboxItem';
import { useAddRemoveCurrentSnippetAsChecked } from '../../hooks/useAddRemoveCurrentSnippetAsChecked';

export interface IIDataSnippetsRows {
	id: number;
	prompt: string;
	url: string;
	botName: string;
	created: string;
	cashed: boolean;
	pushed: boolean;
	vectorized: boolean;
	completion: string;
}

interface ISharedMobileTableProps {
	rows: IIDataSnippetsRows[];
	// middlewareFn: (currentSnippetNumber: string, prompt: string, completion: string) => void;
}

export const SnippetsTable: React.FC<ISharedMobileTableProps> = ({ rows }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const allCheckedArray = useAppSelector(
		(state: RootState) => state.checkedSnippetsMenu.checkedSnippetsArray
	);
	const allFunctionsWithCheckedArray = useAddRemoveCurrentSnippetAsChecked();
	const { addCurrentSnippetAsChecked, removeAllArraySnippetAsChecked } =
		allFunctionsWithCheckedArray;

	const theme = {
		currentTheme,
		hoverBackgroundColor:
			currentTheme.accentHighlightColorForTable.concat('80'),
	};

	const [isChecked, setIsChecked] = useState(false);

	const handleAddRemoveAllSnippetsOnPage = (): void => {
		if (isChecked) {
			removeAllArraySnippetAsChecked();
		} else {
			removeAllArraySnippetAsChecked();
			rows.map((currentRow) => {
				addCurrentSnippetAsChecked(currentRow.id);
				return currentRow;
			});
		}

		setIsChecked(!isChecked);
	};

	useEffect(() => {
		allCheckedArray.length === 0 && setIsChecked(false);
	}, [allCheckedArray]);

	return (
		<StyledSnippetsTable theme={theme}>
			<Box className="grid-snippet-container">
				<FormControl>
					<Box component="div" className="title-wrapper">
						{/* Hide checkbox */}
						<SharedCheckboxItem
							setStateAction={handleAddRemoveAllSnippetsOnPage}
							label=""
							className="title-snippet snippet-checkbox"
							isChecked={isChecked}
						/>

						{/* <Box className="title-snippet domain-grid-item">Prompt</Box> */}

						<SortComponent
							title="Prompt"
							sortQuery="prompt"
							className="title-snippet domain-grid-item sort-component"
						/>

						{/* <UrlTitle /> */}
						<SortComponent
							title="URL"
							sortQuery="uri"
							className="title-snippet domain-grid-item sort-component"
						/>

						{/* Hide bot name */}
						{/* <Box className="title-snippet bot-name-item">Bot name</Box> */}
						{/* <Box className="title-snippet created-item">Created</Box> */}
						<SortComponent
							title="Created"
							sortQuery="created"
							className="title-snippet created-item sort-component"
						/>
						{/* Hide cashed and pushed */}
						<Box className="title-snippet cashed-item">Cashed</Box>
						{/* <Box className="title-snippet pushed-item">Pushed</Box> */}
						<Box className="title-snippet vectorized-item">Vectorized</Box>
						<Box className="title-snippet actions-grid-item">Actions</Box>
					</Box>
					{rows.length === 0 && (
						<Box
							component="div"
							className="message-empty"
							sx={{ alignSelf: 'center', p: 4 }}
						>
							You don`t have snippets yet
						</Box>
					)}
					{rows.map((row, index) => (
						<GridCard key={index} row={row} />
					))}
				</FormControl>
			</Box>
		</StyledSnippetsTable>
	);
};
