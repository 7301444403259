import styled from 'styled-components';

export const StyledCurrentStatisticItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 277px;
	font-size: 16px;

	& .currentStatisticItem-stage-field {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;

		& .currentStatisticItem-stage-name {
			width: 70px;
		}

		& .currentStatisticItem-stage {
			width: 100%;
		}
	}

	& .currentStatisticItem-count-field {
		display: flex;
		align-items: center;
		justify-content: space-between;

		& > div {
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}
	}

	@media screen and (max-width: 1500px) {
		font-size: 14px;
	}
`;
