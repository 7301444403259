import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as Checkmark } from '../pic/checkmark.svg';
import { StyledWrappedPricePlanCard } from './WrappedPricePlanCard.style';
import { PriceElement } from '../PriceElement/PriceElement';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';

interface IWrappedPricePlanCardProps {
	title: string;
	priceNumber: string;
	benefitsList: string[];
	isPopularPlan: boolean;
	priceId: string;
	id: string;
	isCurrentPlan: boolean;
	refetchFn: () => Promise<void>;
	refetchFnForHistory: () => Promise<void>;
	openModalToCreateSubscription: (
		conditionModal: boolean,
		currentPlanName: string,
		currentPrice: string,
		priceId: string
	) => void;
}

export const WrappedPricePlanCard: React.FC<IWrappedPricePlanCardProps> = ({
	title,
	priceNumber,
	benefitsList,
	isPopularPlan,
	priceId,
	id,
	isCurrentPlan,
	refetchFn,
	refetchFnForHistory,
	openModalToCreateSubscription,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const currentPeriod = useAppSelector(
		(state: RootState) => state.togglePeriodPriceMenu.currentPeriod
	);

	const theme = {
		currentTheme,
		isPopularPlan,
		background: isPopularPlan
			? `
      linear-gradient(180deg, #35137B 0%, #0F1011 100%) padding-box,
      linear-gradient(180deg, #2973EC 0%, rgba(41, 115, 236, 0) 65.62%) border-box;`
			: '#1A1D21',
		border: isPopularPlan ? '2px solid transparent' : '1px solid #D7E2F240',
		scale: isPopularPlan ? '1.1' : '1',
		heightCard: isPopularPlan ? '580px' : '551px',
		widthCard: isPopularPlan ? '380px' : '360px',
		heightButtonMargin: isPopularPlan ? '-12px' : '-40px',
	};

	return (
		<StyledWrappedPricePlanCard className="price-plan-card" theme={theme}>
			<Box component="div" className="high-wrapper">
				{isPopularPlan ? (
					<Box component="div" className="popular-plan-popup">
						<Box component="span" className="popular-plan-text">
							Popular
						</Box>
					</Box>
				) : (
					<></>
				)}
				<Box component="div" className="price-plan-card-title">
					{title}
				</Box>

				<PriceElement
					priceValue={priceNumber}
					isBig
					isPerMonth={currentPeriod === 'Month'}
				/>

				<Box component="div" className="current-benefits-list-field">
					{benefitsList.map((currentBenefit) => (
						<Box
							key={currentBenefit}
							component="div"
							className="current-wrapped-benefit"
						>
							<Checkmark />
							{/* <Box component="img" src={checkmark} alt={`checkMark-benefit`} /> */}
							<Box component="div" className="price-plan-card-current-benefit">
								{currentBenefit}
							</Box>
						</Box>
					))}
				</Box>
			</Box>
			<Box component="div" className="current-button-field">
				{isCurrentPlan ? (
					<SharedMainButton
						text="Current Plan"
						buttonFunction={() => {}}
						isDeleteButton={false}
						isDisabled
					/>
				) : (
					<SharedMainButton
						text="Upgrade plan"
						buttonFunction={() => {
							openModalToCreateSubscription(true, title, priceNumber, priceId);
						}}
						isDeleteButton={false}
						tooltip="You can purchase a new plan that suits your needs"
					/>
				)}
			</Box>
		</StyledWrappedPricePlanCard>
	);
};

export const MemoizingWrappedPricePlanCard = React.memo(WrappedPricePlanCard);
