// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialSearchBarHistoryMenuValueState {
	searchBarValueHistoryMenu: string;
	searchBarValueForRefetchingHistoryMenu: string;
	sortOrderHistoryMenu: string;
	startDate: string | undefined;
	lastDate: string | undefined;
	currentOrder: string;
}

const initialState: IInitialSearchBarHistoryMenuValueState = {
	searchBarValueHistoryMenu: '',
	searchBarValueForRefetchingHistoryMenu: '',
	sortOrderHistoryMenu: 'prompt,DESC',
	startDate: undefined,
	lastDate: undefined,
	currentOrder: 'DESC',
};

const searchBarHistoryMenuValueSlice = createSlice({
	name: 'searchBarHistoryMenuValue',
	initialState,
	reducers: {
		setSearchBarValueHistoryMenu(state, action: PayloadAction<string>) {
			state.searchBarValueHistoryMenu = action.payload;
			return state;
		},
		removeSearchBarValueHistoryMenu(state) {
			state.searchBarValueHistoryMenu = '';
			return state;
		},
		activateSearchBarForRefetchingHistoryMenu(
			state,
			action: PayloadAction<string>
		) {
			state.searchBarValueForRefetchingHistoryMenu = action.payload;
			return state;
		},
		sortOrderForRefetchingHistoryMenu(state, action: PayloadAction<string>) {
			if (state.currentOrder === 'DESC') {
				state.currentOrder = 'ASC';
				state.sortOrderHistoryMenu = `${action.payload},ASC`;
			} else {
				state.currentOrder = 'DESC';
				state.sortOrderHistoryMenu = `${action.payload},DESC`;
			}
			return state;
		},
		changeDateRangeHistoryMenu(state, action: PayloadAction<string[]>) {
			const startDate = action.payload[0];
			const startDateFromDayBegin = startDate.replace(
				/T\d\d:\d\d:\d\d\.\d\d\dZ/gim,
				`T00:00:00.579Z`
			);
			state.startDate = startDateFromDayBegin;
			state.lastDate = action.payload[1];
			return state;
		},
		removeDateRangeHistoryMenu(state) {
			state.startDate = undefined;
			state.lastDate = undefined;
			return state;
		},
	},
});

export const {
	setSearchBarValueHistoryMenu,
	removeSearchBarValueHistoryMenu,
	activateSearchBarForRefetchingHistoryMenu,
	sortOrderForRefetchingHistoryMenu,
	changeDateRangeHistoryMenu,
	removeDateRangeHistoryMenu,
} = searchBarHistoryMenuValueSlice.actions;
export const searchBarHistoryMenuValueReducer =
	searchBarHistoryMenuValueSlice.reducer;
