import React from 'react';
import { StyledWrapperMenu } from './WrapperMenu.style';
import { Box } from '@mui/material';
import background from './pic/BG.svg';
import LetterBg from './pic/LetterBg.svg';
import { ReactComponent as Brain } from './pic/brainBig.svg';
import { ReactComponent as Stripes } from './pic/stripes.svg';

interface IWrapperMenuProps {
	children: JSX.Element;
	isForgotMenu?: boolean;
}

export const WrapperMenu: React.FC<IWrapperMenuProps> = ({
	children,
	isForgotMenu,
}) => {
	const theme = {
		left: isForgotMenu === true ? '200px' : '-300px',
		bottom: isForgotMenu === true ? '0px' : '-200px',
		displayMainBg: isForgotMenu === true ? 'flex' : 'block',
		displayBottomBg: isForgotMenu === true ? 'none' : 'flex',
		height: isForgotMenu === true ? '70vh' : '100vh',
		width: isForgotMenu === true ? '500px' : '70%',
	};

	return (
		<StyledWrapperMenu theme={theme}>
			{children}
			<Box component="div" className="for-image">
				{isForgotMenu === true ? (
					<Box component="img" src={LetterBg} />
				) : (
					<Box component="img" src={background} />
				)}
			</Box>
			<Box component="div" className="for-image-mobile-brain">
				<Brain />
			</Box>
			<Box component="div" className="for-image-mobile-bottom">
				<Stripes />
			</Box>
		</StyledWrapperMenu>
	);
};
