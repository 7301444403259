// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const isCheckboxActiveSlice = createSlice({
	name: 'isCheckboxActive',
	initialState: false,
	reducers: {
		setActiveStatusOfCheckbox(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setActiveStatusOfCheckbox } = isCheckboxActiveSlice.actions;
export const isCheckboxActiveReducer = isCheckboxActiveSlice.reducer;
