// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledLogout = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	cursor: pointer;
	border-radius: 20px;

	& .logout-text {
		font-style: normal;
		font-weight: 300;
		font-size: 24px;
		line-height: 33px;
		color: '#D7E2F2';
		transition: all 0.4s;
	}

	&:hover {
		background: #24282e;
	}

	@media screen and (max-width: 1500px) {
		gap: 0.5rem;

		& .logout-text {
			font-size: 16px;
		}
	}
`;
