// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledInputsFieldWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	& .inputs-field-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		width: 100%;
		gap: 1rem;
	}
`;
