import React from 'react';
import { LayoutAccount } from '../../components/shared/LayoutAccount/LayoutAccount';
import { AboutMainMenu } from '../../components/AboutMenu/AboutMainMenu/AboutMainMenu';

export const AboutMainPage: React.FC = () => {
	return (
		<LayoutAccount title="Create Custom Chatbots for Your Website">
			<AboutMainMenu />
		</LayoutAccount>
	);
};
