/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialRegistrationInputsValueState {
	nameRegistration: string;
	surnameRegistration: string;
	domainRegistration: string;
	emailRegistration: string;
	passwordRegistration: string;
}

const initialState: IInitialRegistrationInputsValueState = {
	nameRegistration: '',
	surnameRegistration: '',
	domainRegistration: '',
	emailRegistration: '',
	passwordRegistration: '',
};

const registrationInputsValueSlice = createSlice({
	name: 'registrationInputsValue',
	initialState,
	reducers: {
		setNameRegistration(state, action) {
			state.nameRegistration = action.payload;
			return state;
		},
		setSurnameRegistration(state, action) {
			state.surnameRegistration = action.payload;
			return state;
		},
		setDomainRegistration(state, action) {
			state.domainRegistration = action.payload;
			return state;
		},
		setEmailRegistration(state, action) {
			state.emailRegistration = action.payload;
			return state;
		},
		setPasswordRegistration(state, action) {
			state.passwordRegistration = action.payload;
			return state;
		},
	},
});

export const {
	setNameRegistration,
	setSurnameRegistration,
	setDomainRegistration,
	setEmailRegistration,
	setPasswordRegistration,
} = registrationInputsValueSlice.actions;
export const registrationInputsValueReducer =
	registrationInputsValueSlice.reducer;
