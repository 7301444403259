import React from 'react';
import { Box } from '@mui/material';
import { StyledSharedModal } from './SharedModal.style';
import { SharedMainButton } from '../SharedMainButton/SharedMainButton';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import deleteIcon from './pic/delete.svg';

interface IAreYouSureProps {
	closeFn: () => void;
	executeFn: (id: number) => void;
	message: JSX.Element | null;
	title: string;
	subtitle?: string;
	isOnlyAlert: boolean;
	isDeleteModal?: boolean;
	icon?: React.ReactElement;
	standaloneFC?: JSX.Element;
}

export const SharedModal: React.FC<IAreYouSureProps> = ({
	closeFn,
	executeFn,
	message,
	title,
	subtitle,
	isOnlyAlert,
	isDeleteModal,
	icon,
	standaloneFC,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = {
		currentTheme,
		maxWidth: standaloneFC !== undefined ? '609px' : '500px',
		paddingSmall: standaloneFC !== undefined ? '24px' : '48px',
	};

	return (
		<StyledSharedModal theme={theme} className="modal-window">
			<Box
				component="div"
				className="modal-wrapper-for-close"
				onClick={closeFn}
			></Box>

			{standaloneFC !== undefined ? (
				<Box component="div" className="are-you-sure-wrapper">
					{standaloneFC}
				</Box>
			) : (
				<Box component="div" className="are-you-sure-wrapper">
					{isDeleteModal === true ? (
						<Box component="img" className="current-icon" src={deleteIcon} />
					) : (
						<></>
					)}
					{icon !== undefined ? icon : <></>}
					<Box component="div" className="are-you-sure-title">
						{title}
					</Box>
					{subtitle !== undefined ? (
						<Box component="div" className="are-you-sure-subtitle">
							{subtitle}
						</Box>
					) : (
						<></>
					)}
					<Box component="div" className="are-you-sure-message">
						{message}
					</Box>
					<Box component="div" className="are-you-sure-buttons-area">
						{!isOnlyAlert ? (
							<SharedMainButton
								buttonFunction={closeFn}
								text="Cancel"
								isDeleteButton={false}
							/>
						) : (
							<SharedMainButton
								buttonFunction={closeFn}
								text="OK"
								isDeleteButton={false}
							/>
						)}
						{!isOnlyAlert ? (
							<SharedMainButton
								isDeleteButton
								buttonFunction={() => {
									executeFn(1);
								}}
								text="Delete"
								small
							/>
						) : (
							<></>
						)}
					</Box>
				</Box>
			)}
		</StyledSharedModal>
	);
};
