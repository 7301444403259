import styled from 'styled-components';

export const StyledAboutQuestionsMenu = styled.div`
	display: flex;
	gap: 24px;
	width: 100%;

	& > div {
		display: flex;
		gap: 16px;
		width: 100%;
	}

	& .articles-column-wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 50%;

		& .aqWrapper {
			display: flex;
			flex-direction: column;
			gap: 24px;

			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 140%;

			color: ${(props) => props.theme.currentTheme.mainFontColor};
		}
	}

	@media screen and (max-width: 800px) {
		padding-top: 3rem !important;
		& .articles-column-wrapper {
			& .aqWrapper {
				font-size: 16px;
			}
		}
	}

	@media screen and (max-width: 600px) {
		& > div {
			flex-direction: column;
		}
		flex-direction: column;
		gap: 1rem;
		padding-top: 3rem !important;
		& .articles-column-wrapper {
			padding: 0px !important;
			width: 100%;
			gap: 1rem;
		}
	}

	@media screen and (max-width: 350px) {
		padding-top: 5rem !important;
	}
`;
