import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { StyledSharedMainButton } from './SharedMainButton.style';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { ProgressSpinner } from '../ProgressSpinner/ProgressSpinner';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { type Instance } from '@popperjs/core';

interface ISharedNavLinkProps {
	buttonFunction: () => void;
	text: string;
	small?: boolean;
	isDeleteButton: boolean;
	isAccentButton?: boolean;
	isDisabled?: boolean;
	inProgress?: boolean;
	tooltip?: string;
}

export const SharedMainButton: React.FC<ISharedNavLinkProps> = ({
	buttonFunction,
	text,
	small,
	isDeleteButton,
	isAccentButton,
	isDisabled,
	inProgress,
	tooltip,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const tooltipRef = useRef<Instance>(null);
	// const popperRef = useRef<HTMLDivElement>(null);
	const mainButtonRef = useRef<HTMLButtonElement>(null);

	const theme = {
		width: small !== undefined ? '200px' : '100%',
		mainColor: isDeleteButton ? currentTheme.alertFontColor : '#5E17EB',
		background: isDeleteButton
			? currentTheme.cardsBackground
			: isAccentButton === true
			? `
				linear-gradient(90deg, #198DED 0%, #691EFF 100%) padding-box,
				linear-gradient(to right, #43f2ff, #691eff) border-box;
			`
			: '#5E17EB',
		hoverBackground: isDeleteButton
			? currentTheme.alertFontColor
			: isAccentButton === true
			? '#fff'
			: currentTheme.cardsBackground,
		colorFont: isDeleteButton ? currentTheme.alertFontColor : '#fff',
		hoverColor: isDeleteButton
			? '#fff'
			: isAccentButton === true
			? '#000'
			: currentTheme.mainFontColor,
		currentTheme,

		borderRadius: isAccentButton === true ? '100px' : '10px',
		border:
			isAccentButton === true
				? '1px solid transparent'
				: `1px solid ${
						isDeleteButton ? currentTheme.alertFontColor : '#5E17EB'
				  }`,
	};

	const [disabledStatus, setDisabledStatus] = useState(false);

	const disabledStatusFromProgress = (): void => {
		if (inProgress === true) {
			setDisabledStatus(true);
		} else if (isDisabled !== undefined) {
			setDisabledStatus(isDisabled);
		} else {
			setDisabledStatus(false);
		}
	};

	useEffect(() => {
		disabledStatusFromProgress();
	}, [inProgress, isDisabled]);

	const [openTooltipStatus, setOpenTooltipStatus] = useState(false);

	const MainFCStyledSharedMainButton = React.forwardRef(
		function MainFCStyledSharedMainButton(props, ref) {
			return (
				<StyledSharedMainButton
					onClick={() => {
						setOpenTooltipStatus(false);
						buttonFunction();
					}}
					onMouseEnter={() => {
						setOpenTooltipStatus(true);
					}}
					onMouseLeave={() => {
						setOpenTooltipStatus(false);
					}}
					theme={theme}
					disabled={disabledStatus}
					ref={mainButtonRef}
				>
					<Box component="div">
						{inProgress === true ? <ProgressSpinner /> : text}
					</Box>
				</StyledSharedMainButton>
			);
		}
	);

	return tooltip !== undefined ? (
		<CustomTooltip
			title={tooltip}
			theme={theme}
			PopperProps={{
				popperRef: tooltipRef,
			}}
			open={openTooltipStatus}
		>
			<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<MainFCStyledSharedMainButton />
			</Box>
		</CustomTooltip>
	) : (
		// ) : disabledStatus ? (
		// 	<Tooltip title="You don't have permission to do this" arrow enterDelay={300} enterNextDelay={300}>
		// 		<Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
		// 			<MainFCStyledSharedMainButton />
		// 		</Box>
		// 	</Tooltip>
		<MainFCStyledSharedMainButton />
	);
};
