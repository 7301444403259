/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledNavbar = styled.div`
	display: flex;
	position: relative;
	max-width: 398px;
	width: 18vw;
	min-width: 200px;
	height: 100%;
	background: #1a1d21;

	& .layout-temporary-background {
		position: fixed;
		left: 0px;
		top: 0px;
		width: 0px;
		height: 0px;
		background: ${(props) => props.theme.palette.mainFontColor};
		z-index: 850;
		opacity: 0.8;
	}

	& .navbar-wrapper {
		position: fixed;
		top: 0px;
		left: 0px;
		max-width: 398px;
		width: 18vw;
		min-width: 200px;

		display: flex;
		flex-direction: column;
		height: 100%;
		background: #1a1d21;
		overflow: auto;

		justify-content: space-between;
		padding: 1rem;
		padding-bottom: 34px;
		align-items: center;
		gap: 3rem;
		z-index: 900;

		& .navbar-navigation {
			display: flex;
			flex-direction: column;
			gap: 3rem;
			align-items: center;
			justify-content: center;
			width: 100%;

			& .navbar-logo {
				display: flex;
				justify-content: center;

				& a {
					width: 100%;
					display: flex;
					justify-content: center;
				}

				& a:hover {
					background: none !important;
				}

				& svg {
					width: 90%;
					height: auto;
				}
			}

			& .navbar-generate-button {
				display: flex;
				justify-content: center;
				width: 90%;

				& a {
					display: flex;
					justify-content: center;
					width: 90%;
					margin: 0px !important;
					text-decoration: none;
				}
			}

			& .navbar-menu-icon-navigation {
				cursor: pointer;
				border-radius: 50%;
				transition: all 0.3s;
				padding: 1rem;

				&:active {
					background: #e5e5e534;
					transform: scale(0.9);
				}
			}

			& .navbar-menu-list-links {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 90%;
			}
		}

		& .additional {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			align-items: center;
			justify-content: space-between;
			color: #d7e2f2;
			width: 100%;

			& .plan-button {
				width: 80%;
				text-decoration: none;
			}

			& .logout-hover-class {
				padding: 16px;
				border-radius: 20px;

				&:hover {
					background: #24282e;
				}
			}
		}
	}

	/* MOBILE */

	& .mobile-navbar-wrapper {
		position: fixed;
		top: 4rem;
		left: 0px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		padding: 1rem;
		align-items: center;
		width: 100%;
		gap: 1rem;
		background: #0e172d;
		z-index: 950;

		& a {
			font-size: 1.3rem;
			text-decoration: none;
			color: #fff;
			transition: all 0.4s;
			padding: 0.5rem 3rem;
			border-radius: 20px;
			font-weight: 200;

			&:hover {
				background: #e2e2e2;
				color: #5e17eb;
			}
		}
	}

	@keyframes appear {
		0% {
			transform: translateX(-300px);
		}
		100% {
			transform: translateX(0px);
		}
	}
	@keyframes disappear {
		0% {
			transform: translateX(0px);
		}
		100% {
			transform: translateX(-300px);
		}
	}

	@media screen and (max-width: 1500px) {
		& .navbar-wrapper {
			padding: 1rem 0.5rem;

			& .navbar-navigation {
				display: flex;
				flex-direction: column;
				gap: 1rem;
			}
		}

		& .additional {
			flex-direction: column;
			gap: 0.5rem;
			align-items: center;
			justify-content: center;

			& .logout-hover-class {
				padding: 8px 20px !important;
				border-radius: 20px;

				&:hover {
				}
			}
		}
	}

	/* After hide navbar */
	@media screen and (max-width: 1000px) {
		display: ${(props) => (props.theme.isShowMenu ? 'flex' : 'none')};
		width: 0px;
		min-width: 0px;

		& .layout-temporary-background {
			width: ${(props) => (props.theme.isShowMenu ? '100vw' : '0px')};
			height: ${(props) => (props.theme.isShowMenu ? '100vh' : '0px')};
		}
	}

	@media screen and (max-width: 500px) {
		& .navbar-wrapper {
			position: fixed;
			top: 0px;
			left: 0px;
			max-width: 500px;
			width: 100%;
			padding-top: 3rem;

			& .additional {
				padding-bottom: 55px;
			}
		}
	}
`;
