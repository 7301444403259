import React from 'react';
import { Box } from '@mui/material';
import { StyledSharedTransparentButton } from './SharedTransparentButton.style';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { ProgressSpinner } from '../ProgressSpinner/ProgressSpinner';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

interface ISharedNavLinkProps {
	buttonFunction: () => void;
	text: string;
	small?: boolean;
	isDeleteButton?: boolean;
	inProgress?: boolean;
	tooltip?: string;
}

export const SharedTransparentButton: React.FC<ISharedNavLinkProps> = ({
	buttonFunction,
	text,
	small,
	isDeleteButton,
	inProgress,
	tooltip,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		width: small !== undefined ? '200px' : '272px',
		bgColor: isDeleteButton,
		currentTheme,
	};

	return tooltip !== undefined ? (
		<CustomTooltip title={tooltip} theme={theme}>
			<StyledSharedTransparentButton onClick={buttonFunction} theme={theme}>
				<Box component="div">
					{inProgress === true ? <ProgressSpinner /> : text}
				</Box>
			</StyledSharedTransparentButton>
		</CustomTooltip>
	) : (
		<StyledSharedTransparentButton onClick={buttonFunction} theme={theme}>
			<Box component="div">
				{inProgress === true ? <ProgressSpinner /> : text}
			</Box>
		</StyledSharedTransparentButton>
	);
};
