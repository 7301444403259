/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialPaginationValuesHistoryFondyPagination {
	totalResultsHistoryFondyPagination: number;
	viewOnPageHistoryFondyPagination: string;
	startFetchPageHistoryFondyPagination: number;
	finishFetchPageHistoryFondyPagination: number;
	isValidViewOnPageHistoryFondyPagination: boolean;
}

const initialState: IInitialPaginationValuesHistoryFondyPagination = {
	totalResultsHistoryFondyPagination: 0,
	viewOnPageHistoryFondyPagination: '10',
	startFetchPageHistoryFondyPagination: 1,
	finishFetchPageHistoryFondyPagination: 10,
	isValidViewOnPageHistoryFondyPagination: true,
};

const paymentsHistoryFondyPaginationSlice = createSlice({
	name: 'paymentsHistoryFondyPagination',
	initialState,
	reducers: {
		setHistoryTotalResultsFondyPagination(
			state,
			action: PayloadAction<number>
		) {
			state.totalResultsHistoryFondyPagination = action.payload;
			return state;
		},
		setHistoryViewOnPageFondyPagination(state, action: PayloadAction<string>) {
			state.viewOnPageHistoryFondyPagination = action.payload;
			return state;
		},
		setHistoryStartFetchPageFondyPagination(
			state,
			action: PayloadAction<number>
		) {
			state.startFetchPageHistoryFondyPagination = action.payload;
			return state;
		},
		setHistoryFinishFetchPageFondyPagination(
			state,
			action: PayloadAction<number>
		) {
			state.finishFetchPageHistoryFondyPagination = action.payload;
			return state;
		},

		setHistoryIsValidViewOnPageFondyPagination(
			state,
			action: PayloadAction<boolean>
		) {
			state.isValidViewOnPageHistoryFondyPagination = action.payload;
			return state;
		},
	},
});

export const {
	setHistoryTotalResultsFondyPagination,
	setHistoryViewOnPageFondyPagination,
	setHistoryStartFetchPageFondyPagination,
	setHistoryFinishFetchPageFondyPagination,
	setHistoryIsValidViewOnPageFondyPagination,
} = paymentsHistoryFondyPaginationSlice.actions;
export const paymentsHistoryFondyPaginationReducer =
	paymentsHistoryFondyPaginationSlice.reducer;
