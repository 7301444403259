// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const showHideMenuSlice = createSlice({
	name: 'showHideMenu',
	initialState,
	reducers: {
		showMenu(state) {
			state = true;
			return state;
		},
		hideMenu(state) {
			state = false;
			return state;
		},
	},
});

export const { showMenu, hideMenu } = showHideMenuSlice.actions;
export const showHideMenuReducer = showHideMenuSlice.reducer;
