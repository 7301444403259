/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledChangeUserInfoMenu = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 170px 0px !important;
	background: #0e172d;
	align-items: center;

	& .change-use-info-wrapper-border {
		width: 90%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background: ${(props) =>
			`linear-gradient(${
				props.theme.currentTheme.mainRootBackground === '#1A1D21'
					? '90deg'
					: '180deg'
			}, ${props.theme.currentTheme.cardsGradient.one}, ${
				props.theme.currentTheme.cardsGradient.two
			})`};
		padding: 1px !important;

		& .change-use-info-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			border-radius: 30px;
			background: ${(props) => props.theme.currentTheme.cardsBackground};

			justify-content: space-between;
			gap: 24px;
			padding: 56px;

			& .change-user-info {
				display: flex;
				flex-direction: column;
				gap: 36px;
				width: 100%;

				& .change-info-area {
					display: flex;
					flex-direction: row;
					gap: 16px;

					& .change-name-surname-field,
					.change-password-field {
						min-height: 302px;
						border: 1px solid
							${(props) => props.theme.currentTheme.borderInMenu};
						border-radius: 20px;
						padding: 40px;
					}

					& .change-name-surname-field {
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						gap: 24px;
						width: 50%;

						& .icon-area {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							gap: 24px;

							& .icon-avatar {
								display: flex;
								flex-direction: column;
								align-items: center;
								padding: 16px 32px;
								gap: 8px;
								width: 99px;
								height: 97px;
								background: #5e17eb;
								border-radius: 50%;

								font-style: normal;
								font-weight: 400;
								font-size: 48px;
								line-height: 65px;
								text-align: center;
								color: #ffffff;
							}
							& .edit-icon {
								font-style: normal;
								font-weight: 400;
								font-size: 22px;
								line-height: 145%;
								color: #1592ec;
							}
						}

						& .name-surname-area {
							display: flex;
							flex-direction: column;
							gap: 1rem;
							width: 100%;
							max-width: 480px;

							& .currentInformation-new {
								display: flex;
								flex-direction: column;
								gap: 8px;

								& .new-title {
									font-style: normal;
									font-weight: 400;
									font-size: 18px;
									line-height: 145%;
									color: ${(props) => props.theme.currentTheme.mainFontColor};
								}

								& .new-value {
									& input {
										width: 100% !important;
									}
								}
							}
						}
					}

					& .change-password-field {
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						gap: 24px;
						width: 50%;

						& .currentInformation-wrapper-password {
							display: flex;
							flex-direction: column;
							gap: 1rem;
							width: 100%;
							max-width: 480px;

							& .password-field {
								display: flex;
								flex-direction: column;
								gap: 8px;

								& .password-title {
									font-style: normal;
									font-weight: 400;
									font-size: 18px;
									line-height: 145%;
									color: ${(props) => props.theme.currentTheme.mainFontColor};
								}
							}

							& input {
								width: 100% !important;
							}
						}
					}
				}

				& .change-buttons-area {
					display: flex;
					flex-direction: row;
					justify-content: center;

					& > div {
						display: flex;
						gap: 1rem;
						width: 70%;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1700px) {
		& .change-use-info-wrapper-border {
			& .change-use-info-wrapper {
				padding: 32px;
			}
		}
	}

	@media screen and (max-width: 1350px) {
		padding: 120px 0px !important;

		& .change-use-info-wrapper-border {
			& .change-use-info-wrapper {
				& .change-user-info {
					& .change-info-area {
						& .change-name-surname-field,
						.change-password-field {
							width: 100%;
							& .name-surname-area {
								max-width: 470px;

								& .currentInformation-new {
									& .new-value {
										& input {
											max-width: 470px;
										}
									}
								}
							}

							& .currentInformation-wrapper-password {
								max-width: 470px;
								& input {
									max-width: 470px;
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1000px) {
		& .change-use-info-wrapper-border {
			& .change-use-info-wrapper {
				& .change-user-info {
					& .change-info-area {
						flex-direction: column;

						& .change-name-surname-field,
						.change-password-field {
							flex-direction: column;
							padding: 1rem;
							min-height: 250px;

							& .name-surname-area {
							}

							& .currentInformation-wrapper-password {
								position: relative;
								max-width: 470px;
								& input {
									max-width: 470px;
								}

								& .styledSharedPasswordInput {
									width: 100%;
								}
							}
						}
					}
					& .change-buttons-area {
						& > div {
							flex-direction: column;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 550px) {
		& .change-use-info-wrapper-border {
			& .change-use-info-wrapper {
				padding: 0.5rem;
			}
		}
	}
`;
