import React from 'react';
import { Box } from '@mui/material';
import { StyledMessengerSetUp } from './MessengerSetUp.style';
import arrow from '../../../../pic/arrowVertical.svg';
import { useAppSelector } from '../../../../../../app/hooks';
import { type RootState } from '../../../../../../app/store';
import messengerSetUp from '../../../../pic/messengerSetUp.png';

export const MessengerSetUp: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	return (
		<StyledMessengerSetUp theme={theme} className="messageExample-wrapper">
			<Box component="div" className="messageExample-msg-box">
				<Box
					component="img"
					className="step-Element-link"
					src={messengerSetUp}
				/>
			</Box>
			<Box component="div" className="messageExample-arrow">
				<Box component="img" src={arrow} alt="arrow-pic" />
			</Box>
		</StyledMessengerSetUp>
	);
};
