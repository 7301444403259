import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialTestBotMessageTrainingBotMenu {
	testBotMessage: string;
}

const initialState: IInitialTestBotMessageTrainingBotMenu = {
	testBotMessage: '',
};

const testBotMessageTrainingBotMenuSlice = createSlice({
	name: 'testBotMessageTrainingBotMenu',
	initialState,
	reducers: {
		setTestBotMessage(state, action: PayloadAction<string>) {
			state.testBotMessage = action.payload;
			return state;
		},
	},
});

export const { setTestBotMessage } = testBotMessageTrainingBotMenuSlice.actions;
export const testBotMessageTrainingBotMenuReducer =
	testBotMessageTrainingBotMenuSlice.reducer;
