// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialModelMenuValidInputsValueState {
	isValidWebsiteConfigModelMenu: boolean;
	isValidFlavorConfigModelMenu: boolean;
	isValidReCaptchaTemperatureConfigModelMenu: boolean;
	isValidReCaptchaKeyConfigModelMenu: boolean;
	isValidReCaptchaSecretKeyConfigModelMenu: boolean;
	isValidGreetingConfigModelMenu: boolean;
	isValidPrefixConfigModelMenu: boolean;
}

const initialState: IInitialModelMenuValidInputsValueState = {
	isValidWebsiteConfigModelMenu: false,
	isValidFlavorConfigModelMenu: false,
	isValidReCaptchaTemperatureConfigModelMenu: false,
	isValidReCaptchaKeyConfigModelMenu: true,
	isValidReCaptchaSecretKeyConfigModelMenu: true,
	isValidGreetingConfigModelMenu: true,
	isValidPrefixConfigModelMenu: true,
};

const isValidModelMenuInputsSlice = createSlice({
	name: 'isValidModelMenuInputs',
	initialState,
	reducers: {
		setValidStatusWebsiteConfigModelMenu(state, action) {
			state.isValidWebsiteConfigModelMenu = action.payload;
			return state;
		},
		setValidStatusFlavorConfigModelMenu(state, action) {
			state.isValidFlavorConfigModelMenu = action.payload;
			return state;
		},
		setValidStatusReCaptchaTemperatureConfigModelMenu(state, action) {
			state.isValidReCaptchaTemperatureConfigModelMenu = action.payload;
			return state;
		},
		setValidStatusReCaptchaKeyConfigModelMenu(state, action) {
			state.isValidReCaptchaKeyConfigModelMenu = action.payload;
			return state;
		},
		setValidStatusReCaptchaSecretKeyConfigModelMenu(state, action) {
			state.isValidReCaptchaSecretKeyConfigModelMenu = action.payload;
			return state;
		},
		setValidStatusGreetingConfigModelMenu(state, action) {
			state.isValidGreetingConfigModelMenu = action.payload;
			return state;
		},
		setValidStatusPrefixConfigModelMenu(state, action) {
			state.isValidPrefixConfigModelMenu = action.payload;
			return state;
		},
	},
});

export const {
	setValidStatusWebsiteConfigModelMenu,
	setValidStatusFlavorConfigModelMenu,
	setValidStatusReCaptchaTemperatureConfigModelMenu,
	setValidStatusReCaptchaKeyConfigModelMenu,
	setValidStatusReCaptchaSecretKeyConfigModelMenu,
	setValidStatusGreetingConfigModelMenu,
	setValidStatusPrefixConfigModelMenu,
} = isValidModelMenuInputsSlice.actions;
export const isValidModelMenuInputsReducer =
	isValidModelMenuInputsSlice.reducer;
