import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { LoginMenu } from '../components/LoginMenu/LoginMenu';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { type RootState } from '../app/store';
import { Navigate } from 'react-router-dom';
import { ConfirmEmailMenu } from '../components/ConfirmEmailMenu/ConfirmEmailMenu';
import { setConfirmEmailIssueStatus } from '../app/slices/isOpenConfirmEmailIssueSlice';
import { getSessionItem } from '../app/utils/storageUtils';

export const MainPage: React.FC = () => {
	const isAuthorization: boolean = useAppSelector(
		(state: RootState) => state.isAuthorization
	);

	const isOpenConfirmEmailIssue: boolean = useAppSelector(
		(state: RootState) => state.isOpenConfirmEmailIssue
	);

	const registrationData = getSessionItem('registrationData');

	const dispatch = useAppDispatch();
	useEffect(() => {
		registrationData !== null
			? dispatch(setConfirmEmailIssueStatus(true))
			: dispatch(setConfirmEmailIssueStatus(false));
	}, [registrationData]);

	const CurrentStepOfLogin: React.FC = () => {
		if (!isAuthorization && !isOpenConfirmEmailIssue) {
			return <LoginMenu />;
		} else if (!isAuthorization && isOpenConfirmEmailIssue) {
			return <ConfirmEmailMenu />;
		} else {
			return <Navigate to="/personal-cabinet" replace={true} />;
		}
	};

	return (
		<Box component="div" className="main-page">
			<CurrentStepOfLogin />
		</Box>
	);
};
