import styled from 'styled-components';

export const StyledTestBotComponent = styled.div`
	width: 100%;

	& .testBotMenuComponent-wrapper {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 8px;
		width: 100%;

		& .testBotMenu-title-subtitle {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			font-style: normal;
			width: 100%;

			& .testBotMenu-title {
				font-size: 20px;
				font-weight: 600;
				line-height: 145%;
			}

			& .testBotMenu-subtitle {
				font-size: 16px;
				font-weight: 400;
				line-height: 27px;
			}
		}

		& .testBotMenu-input-url {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1rem;

			& .snippetsListForSaving-message {
				width: 100%;
				text-align: center;
				color: #1592ec;
			}

			& .prompt-test-wrapper {
				width: 100%;
				display: flex;
				gap: 1rem;
				justify-content: space-between;
				position: relative;

				& .clear-all-btn {
					position: absolute;
					top: -45px;
					left: 38%;
					color: ${(props) => props.theme.currentTheme.mainFontColor};
					background: ${(props) => props.theme.currentTheme.cardsBackground};
					border-radius: 8px;
					opacity: 0.3;

					&:hover {
						opacity: 1;
						background: ${(props) =>
							props.theme.currentTheme.accentHighlightColorForTable};
					}
				}

				& .shared-wrapped-input {
					width: 100%;
				}

				& button {
					width: 25%;
				}
			}

			& > div {
				/* width: 100% !important; */
			}

			& > div > div {
				/* width: 100% !important; */
			}

			& textarea {
				width: 100%;
			}

			& input {
				width: 100% !important;
			}

			& .testBot-answer-window {
				border: 1px solid #1592ec;
				border-radius: 8px;
				padding: 1rem;
				overflow: auto;
				height: 300px;
				width: 100% !important;
				color: ${(props) => props.theme.currentTheme.fontColorInput};
				font-size: 16px;
				font-weight: 400;
				line-height: 27px;
				/* cursor: no-drop; */
				background: ${(props) => props.theme.currentTheme.disabledBackground};
			}
		}

		& .testBot-answer-loading-content {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			align-items: center;

			& .error-message-from-api {
				color: ${(props) => props.theme.currentTheme.alertFontColor};
			}

			& .message-from-api {
				color: #1592ec;
			}
		}

		& .testBotMenu-button-field {
			display: flex;
			flex-direction: column;
			gap: 1rem;

			& > div {
				display: flex;
				gap: 1rem;
				width: 100% !important;

				& button {
					width: 100% !important;
				}
			}
		}

		& .closePic-wrapper {
			position: absolute;
			top: 35px;
			right: 25px;

			& .closePic {
				width: 24px;
				height: 24px;
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 650px) {
		& .testBotMenuComponent-wrapper {
			padding: 0px;
			& .testBotMenu-title-subtitle {
				text-align: center;
			}
		}
	}
`;
