// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialValidChangeChatbotIntegrationInputState {
	isValidChangeChatbotIntegrationInput: boolean;
}

const initialState: IInitialValidChangeChatbotIntegrationInputState = {
	isValidChangeChatbotIntegrationInput: false,
};

const isValidChangeChatbotIntegrationInputSlice = createSlice({
	name: 'isValidChangeChatbotIntegrationInput',
	initialState,
	reducers: {
		setValidStatusChangeChatbotIntegrationInput(
			state,
			action: PayloadAction<boolean>
		) {
			state.isValidChangeChatbotIntegrationInput = action.payload;
			return state;
		},
	},
});

export const { setValidStatusChangeChatbotIntegrationInput } =
	isValidChangeChatbotIntegrationInputSlice.actions;
export const isValidChangeChatbotIntegrationInputReducer =
	isValidChangeChatbotIntegrationInputSlice.reducer;
