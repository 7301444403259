import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export const ProgressSpinner: React.FC = () => {
	return (
		<Stack
			sx={{
				color: 'grey.500',
				display: 'flex',
				justifyContent: 'center !important',
				width: '100%',
			}}
			spacing={2}
			direction="row"
			className="progressSpinner-wrapper"
		>
			<CircularProgress color="secondary" className="progressSpinner" />
		</Stack>
	);
};
