import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { StyledRegistrationMenu } from './RegistrationMenu.style';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { SharedNavLink } from '../shared/SharedNavlink/SharedNavlink';
import { SharedTitleSubtitle } from '../shared/SharedTitleSubtitle/SharedTitleSubtitle';
import { InputsFieldRegistration } from './InputsFieldRegistration/InputsFieldRegistration';
import { setEmptyStatusForRegistration } from '../../app/slices/inputsRegistration/checkEmptyStatusForRegistrationSlice';
import { setRegistrationStatus } from '../../app/slices/isRegistrationSlice';
import { useCreateUserMutation } from '../../app/api/chatbot.api';
import {
	setAnswerApiRegistrationStatus,
	setAnswerApiRegistrationErrorMessage,
} from '../../app/slices/answersFromAPI/answerApiRegistrationSlice';
import { WrapperMenu } from '../shared/WrapperMenu/WrapperMenu';
import {
	type IResponseCreateUserSuccess,
	type IResponseDataWithError,
} from '../../app/api/interfaces/interfaces';
import { ProgressSpinner } from '../shared/ProgressSpinner/ProgressSpinner';
import { getSessionItem, setSessionItem } from '../../app/utils/storageUtils';
import { ifErrorResponseFromApi } from '../../app/utils/utils';
import { SharedMainButton } from '../shared/SharedMainButton/SharedMainButton';

interface IDataResponseRegistration {
	data: IResponseCreateUserSuccess;
}

export const RegistrationMenu: React.FC = () => {
	const answerAprRegistration = useAppSelector(
		(state: RootState) => state.answerApiRegistration
	);
	const registrationInputsValue = useAppSelector(
		(state: RootState) => state.registrationInputsValue
	);
	const {
		nameRegistration,
		surnameRegistration,
		domainRegistration,
		emailRegistration,
		passwordRegistration,
	} = registrationInputsValue;
	const validInputsStatusRegistration = useAppSelector(
		(state: RootState) => state.isValidRegistrationInputs
	);
	const {
		isValidNameRegistration,
		isValidSurnameRegistration,
		isValidEmailRegistration,
		isValidPasswordRegistration,
	} = validInputsStatusRegistration;

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const [createUser, resultOfCreateUser] = useCreateUserMutation();

	const creationUserData = {
		username: nameRegistration,
		surname: surnameRegistration,
		domain: domainRegistration,
		email: emailRegistration,
		password: passwordRegistration,
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setEmptyStatusForRegistration(false));
		dispatch(setAnswerApiRegistrationErrorMessage(''));

		const creationUserDataFromStorage: string | null =
			getSessionItem('registrationData');
		if (creationUserDataFromStorage !== null) {
			dispatch(setAnswerApiRegistrationStatus(true));
			dispatch(setRegistrationStatus(true));
			dispatch(setAnswerApiRegistrationErrorMessage(''));
		}
	}, []);

	const completeCreationAccount = (): void => {
		dispatch(setEmptyStatusForRegistration(true));

		if (
			nameRegistration.length > 0 &&
			surnameRegistration.length > 0 &&
			emailRegistration.length > 0 &&
			passwordRegistration.length > 0 &&
			isValidNameRegistration &&
			isValidSurnameRegistration &&
			isValidEmailRegistration &&
			isValidPasswordRegistration
		) {
			// console.log(creationUserData);
			createUser(creationUserData)
				.then(
					(response: IDataResponseRegistration | IResponseDataWithError) => {
						// console.log('response:', response);
						if ('data' in response) {
							// console.log(response.data);
							dispatch(setAnswerApiRegistrationStatus(true));
							dispatch(setRegistrationStatus(true));
							dispatch(setAnswerApiRegistrationErrorMessage(''));
							setSessionItem(
								'registrationData',
								JSON.stringify({
									email: emailRegistration,
								})
							);
						} else if ('error' in response) {
							// console.log(response.error);
							dispatch(setAnswerApiRegistrationStatus(false));
							response.error.data.message === undefined
								? ifErrorResponseFromApi(
										response,
										dispatch,
										setAnswerApiRegistrationErrorMessage
								  )
								: ifErrorResponseFromApi(
										response,
										dispatch,
										setAnswerApiRegistrationErrorMessage,
										response.error.data.message
								  );
						} else {
							console.log('');
							// console.log('Empty response');
						}
					}
				)
				.catch((error) => {
					dispatch(
						setAnswerApiRegistrationErrorMessage(
							'Server error: Failed to fetch'
						)
					);
					console.log('error: ', error);
				});
		}
	};

	useEffect(() => {
		resultOfCreateUser.isLoading && setLoadingErrorContent(<ProgressSpinner />);
		resultOfCreateUser.isError &&
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{answerAprRegistration.errorMessage}
				</Box>
			);
	}, [resultOfCreateUser, answerAprRegistration]);

	return (
		<WrapperMenu>
			<StyledRegistrationMenu>
				<Box component="div" className="menu-wrapper">
					<Box sx={{ width: '500px' }}></Box>
					<Box component="div" className="menu-inputs-area">
						<Box>
							<SharedTitleSubtitle
								title="Custom ChatGPT Chatbot generator"
								subtitle="Hi, we`ve been looking forward to see you"
								align="center"
							/>
						</Box>
						<Box component="div" className="registration-inputs-field">
							<InputsFieldRegistration />
						</Box>
						{loadingErrorContent}
						<Box component="div" className="registration-button">
							<SharedMainButton
								isDeleteButton={false}
								text="Sign up"
								buttonFunction={completeCreationAccount}
							/>
						</Box>
						<Box component="div" className="registration-login-under-button">
							<SharedNavLink link="/" text="Have an account? " accent="Login" />
						</Box>
					</Box>
				</Box>
			</StyledRegistrationMenu>
		</WrapperMenu>
	);
};
