// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialValidCreateChangePromptState {
	isValidPrompt: boolean;
	isValidCompletion: boolean;
}

const initialState: IInitialValidCreateChangePromptState = {
	isValidPrompt: false,
	isValidCompletion: false,
};

const isValidCreateChangePromptInputsSlice = createSlice({
	name: 'isValidCreateChangePromptInputs',
	initialState,
	reducers: {
		setValidStatusPrompt(state, action: PayloadAction<boolean>) {
			state.isValidPrompt = action.payload;
			return state;
		},
		setValidStatusCompletion(state, action: PayloadAction<boolean>) {
			state.isValidCompletion = action.payload;
			return state;
		},
	},
});

export const { setValidStatusPrompt, setValidStatusCompletion } =
	isValidCreateChangePromptInputsSlice.actions;
export const isValidCreateChangePromptInputsReducer =
	isValidCreateChangePromptInputsSlice.reducer;
