import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import { StyledTestBotComponent } from './TestBotComponent.style';
import { WrappedInput } from '../../shared/Input/WrappedInput/WrappedInput';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import {
	useAppDispatch,
	useAppSelector,
	useIsDarkTheme,
} from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import close from './pic/close.svg';
import closeWhite from './pic/closeWhite.svg';
import {
	setTestBotCompletionInputValue,
	setTestBotInputValue,
} from '../../../app/slices/trainingBotMenu/testBotInput/testBotInputValueSlice';
import { useTestBot } from '../../PersonalCabinetMenu/hooks/useTestBot';
import {
	setEmptyStatusCompletionForTestBotInput,
	setEmptyStatusQuestionForTestBotInput,
} from '../../../app/slices/trainingBotMenu/testBotInput/checkEmptyStatusForTestBotInputSlice';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { checkInput, isEmptyColor, validation } from '../../../app/utils/utils';
import { useCreateSnippetMutation } from '../../../app/api/chatbot.api';
import { loadingErrorContent } from '../../../app/utils/createLoadingErrorContent';
import { setValidStatusTestBotCompletionInput } from '../../../app/slices/trainingBotMenu/testBotInput/isValidTestBotInputSlice';
import { ProgressSpinner } from '../../shared/ProgressSpinner/ProgressSpinner';
import { DialogWnd } from './DialogWnd/DialogWnd';
import { removeItem, setItem } from '../../../app/utils/storageUtils';
import {
	addSnippetToListForSavingInStore,
	removeListSavedSnippetsFromListInDialog,
	removeSnippetFromListByIdAndListInStore,
	setSnippetsListForSaving,
	setSnippetsListInDialog,
	updateSnippetFromListAndInSaving,
} from '../../../app/slices/testChatbotModal/snippetsList.slice';

interface IModalTestBotProps {
	closeFn: () => void;
	chatbotId: string;
}

interface ITestBotComponentProps {
	closeFn: () => void;
	chatbotId: string;
}

const TestBotComponent: React.FC<ITestBotComponentProps> = ({
	closeFn,
	chatbotId,
}) => {
	const isDarkTheme = useIsDarkTheme();

	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const currentChatbotId = useAppSelector(
		(state: RootState) =>
			state.currentChatbotIdForActionsTrainingBotMenu.currentChatbotIdForTest
	);

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const valueInput = useAppSelector(
		(state: RootState) => state.testBotInputValue
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { question, completion } = valueInput;

	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForTestBotInput
	);

	const {
		checkEmptyCompletion: isEmptyCompletion,
		checkEmptyQuestion: isEmptyQuestion,
	} = isButtonPressed;

	const validInputStatus = useAppSelector(
		(state: RootState) => state.isValidTestBotInput
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { isValidTestBotCompletionInput, isValidTestBotInput } =
		validInputStatus;

	const snippetsListForTestingChatbot = useAppSelector(
		(state: RootState) => state.snippetsListForTestChatbot
	);
	const { snippetsListInDialog: snippetsList, snippetsListForSaving } =
		snippetsListForTestingChatbot;

	const [messageAfterRequest, setMessageAfterRequest] = useState<
		JSX.Element | string
	>('');

	const msgContainerRef = useRef<HTMLDivElement | null>(null);
	const questionInputRef = useRef<HTMLInputElement | null>(null);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [createSnippet, resultOfCreateSnippet] = useCreateSnippetMutation();

	const testBotFn = useTestBot();

	const dispatch = useAppDispatch();

	const updateSnippetFromList = (
		id: number,
		isPrompt: boolean,
		updateValue: string
	): void => {
		dispatch(updateSnippetFromListAndInSaving({ id, isPrompt, updateValue }));
	};

	const addSnippetToListForSaving = (id: number): void => {
		dispatch(addSnippetToListForSavingInStore({ id }));
	};

	const removeSnippetFromListByIdAndList = (
		id: number,
		targetSnippetsList: string
	): void => {
		dispatch(
			removeSnippetFromListByIdAndListInStore({ id, targetSnippetsList })
		);
	};

	const handleRemoveAllSnippets = (): void => {
		dispatch(setSnippetsListForSaving([]));
		dispatch(setSnippetsListInDialog([]));
		removeItem(`snippetsList-${currentChatbotId}`);
		removeItem(`snippetsListForSaving-${currentChatbotId}`);
	};

	useEffect(() => {
		setItem(`snippetsList-${currentChatbotId}`, JSON.stringify(snippetsList));
	}, [snippetsList]);

	useEffect(() => {
		setItem(
			`snippetsListForSaving-${currentChatbotId}`,
			JSON.stringify(snippetsListForSaving)
		);
	}, [snippetsListForSaving]);

	const handleScrollDialogToBottom = (): void => {
		const currentMsgContainer = msgContainerRef.current;
		if (currentMsgContainer !== null) {
			const msgContainerScrollHeight = currentMsgContainer.scrollHeight;
			currentMsgContainer.scrollTo({
				left: 0,
				top: msgContainerScrollHeight,
				behavior: 'smooth',
			});
		}
	};

	const handleTestBotExecute = async (): Promise<void> => {
		dispatch(setEmptyStatusQuestionForTestBotInput(true));
		if (isValidTestBotInput && valueInput.question.length > 0) {
			const result = await testBotFn(currentChatbotId, valueInput.question);
			dispatch(setTestBotCompletionInputValue(result));
			const uniqId = parseInt(Date.now().toString());
			dispatch(
				setSnippetsListInDialog([
					...snippetsList,
					{
						id: uniqId,
						prompt: valueInput.question,
						completion: result,
					},
				])
			);
			dispatch(setTestBotInputValue(''));
			setTimeout(() => {
				handleScrollDialogToBottom();
			}, 100);
		}
	};

	const messageTestBotProgress = useAppSelector(
		(state: RootState) => state.testBotMessageTrainingBotMenu.testBotMessage
	);

	useEffect(() => {
		dispatch(setEmptyStatusQuestionForTestBotInput(false));
		dispatch(setEmptyStatusCompletionForTestBotInput(false));
		dispatch(setTestBotCompletionInputValue(''));
	}, []);

	const handleCloseModal = (): void => {
		closeFn();
		dispatch(setTestBotInputValue(''));
	};

	const handleSavePrompt = async (
		promptForSaving: string,
		completionForSaving: string
	): Promise<void> => {
		dispatch(setEmptyStatusCompletionForTestBotInput(true));
		if (promptForSaving.length > 0 && completionForSaving.length > 0) {
			const requestBody = {
				token,
				id: currentChatbotId,
				createSnippetBody: {
					prompt: promptForSaving,
					completion: completionForSaving,
				},
			};

			try {
				const result = await createSnippet(requestBody);
				if ('data' in result) {
					setMessageAfterRequest(
						<Box sx={{ color: '#1592EC' }}>Snippets created successfully</Box>
					);
				}
			} catch (error) {
				setMessageAfterRequest(
					<Box sx={{ color: '#1592EC' }}>Something went wrong</Box>
				);
			}
		}
	};

	const currentColor = isEmptyColor(
		isValidTestBotCompletionInput,
		isEmptyCompletion
	);
	const currentColorForBorder =
		currentColor === 'none'
			? `1px solid ${currentTheme.borderInputs}`
			: currentColor;

	const theme = {
		color: () => currentColorForBorder,
		colorAlert: 'red',
		uniqueNameOfInput: 'testBot-textarea-answer-loading-content-modal',
		currentTheme,
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleChangeValueTextArea = (
		newValue: React.ChangeEvent<HTMLInputElement> | string
	): void => {
		if (typeof newValue === 'string') {
			dispatch(setTestBotCompletionInputValue(newValue));

			validation.usePromptCompletionValidation(newValue)
				? dispatch(setValidStatusTestBotCompletionInput(true))
				: dispatch(setValidStatusTestBotCompletionInput(false));
		}
	};

	useEffect(() => {
		if (resultOfCreateSnippet.isError) {
			setMessageAfterRequest(
				<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
			);
		} else if (resultOfCreateSnippet.isLoading) {
			setMessageAfterRequest(<ProgressSpinner />);
		}
	}, [resultOfCreateSnippet]);

	const handlePressTestBtn = (): void => {
		setMessageAfterRequest('');
		void handleTestBotExecute();
	};

	const handleKeyUpEnter = (keyboardEvent: React.KeyboardEvent): void => {
		switch (keyboardEvent.key) {
			case 'Enter':
				if (messageTestBotProgress !== 'loading' && question.length > 1) {
					handlePressTestBtn();
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const currentQuestionInputRef = questionInputRef.current;
		if (question === '' && currentQuestionInputRef !== null) {
			currentQuestionInputRef.value = question;
		}
	}, [question]);

	return (
		<StyledTestBotComponent theme={theme}>
			<Box
				component="div"
				className="testBotMenuComponent-wrapper"
				onKeyUp={handleKeyUpEnter}
			>
				<Box component="div" className="testBotMenu-title-subtitle">
					<Box component="div" className="testBotMenu-title">
						Test your snippet
					</Box>
					<Box component="div" className="testBotMenu-subtitle">
						You can change all questions and answers.
					</Box>
				</Box>
				<Box component="div" className="testBotMenu-input-url">
					{snippetsListForSaving.length > 0 ? (
						<Box component="div" className="snippetsListForSaving-message">
							{snippetsListForSaving.length} snippets prepared for saving
						</Box>
					) : (
						<></>
					)}
					<DialogWnd
						refTag={msgContainerRef}
						snippetsList={snippetsList}
						snippetsListForSaving={snippetsListForSaving}
						changeSnippetInList={updateSnippetFromList}
						addSnippetForSave={addSnippetToListForSaving}
						removeSnippet={removeSnippetFromListByIdAndList}
						setStateActionFn={{
							setSnippetsList: setSnippetsListInDialog,
							setSnippetsListForSaving,
						}}
					/>
					<Box component="div" className="prompt-test-wrapper">
						{snippetsList.length > 0 ? (
							<Box
								component="button"
								className="clear-all-btn"
								onClick={handleRemoveAllSnippets}
							>
								Clear all
							</Box>
						) : (
							<></>
						)}
						<WrappedInput
							type="text"
							placeholder="Ask bot"
							isEmpty={isEmptyQuestion}
							isValid={isValidTestBotInput}
							uniqueNameOfInput={'testBot-input-modal'}
							value={question}
							refTag={questionInputRef}
						/>
						<SharedMainButton
							isDeleteButton={false}
							text="Test"
							buttonFunction={handlePressTestBtn}
							isDisabled={
								messageTestBotProgress === 'loading' || question.length < 1
							}
						/>
					</Box>
					{/* <Box component="div" className="testBot-answer-window">
						{answerChatbotTest}
					</Box> */}
				</Box>
				<Box component="div" className="testBot-answer-loading-content">
					{messageAfterRequest}
					{loadingErrorContent(messageTestBotProgress, '')}
					{/* {loadingErrorContent(messageTestBotProgress, 'Your chatbot has successfully answered')} */}
				</Box>
				<Box component="div" className="testBotMenu-button-field">
					<Box>
						<SharedMainButton
							isDeleteButton={false}
							text="Save"
							buttonFunction={() => {
								setMessageAfterRequest('');
								for (const snippetObject of snippetsListForSaving) {
									void handleSavePrompt(
										snippetObject.prompt,
										snippetObject.completion
									);
								}
								// removeItem('snippetsList');
								dispatch(removeListSavedSnippetsFromListInDialog());
								removeItem(`snippetsListForSaving-${currentChatbotId}`);
								dispatch(setSnippetsListForSaving([]));
								// void handleTestBotExecute();
							}}
							isDisabled={
								// messageTestBotProgress !== 'success' &&
								messageTestBotProgress === 'loading' ||
								snippetsListForSaving.length === 0
							}
						/>
					</Box>
				</Box>
				<Box component="div" className="closePic-wrapper">
					<Box
						component="img"
						className="closePic"
						alt="closePic"
						src={isDarkTheme ? closeWhite : close}
						onClick={handleCloseModal}
					/>
				</Box>
			</Box>
		</StyledTestBotComponent>
	);
};

export const ModalTestBot: React.FC<IModalTestBotProps> = ({
	closeFn,
	chatbotId,
}) => {
	return (
		<SharedModal
			closeFn={closeFn}
			executeFn={() => {}}
			message={null}
			title=""
			isOnlyAlert={false}
			standaloneFC={
				<TestBotComponent chatbotId={chatbotId} closeFn={closeFn} />
			}
		/>
	);
};
