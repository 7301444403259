// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSharedJSCode = styled.div`
	width: 100%;

	& .receive-js-code-area {
		display: flex;
		justify-content: center;
		gap: 16px;
		transition: all 0.4s;
		width: 100%;
		color: ${(props) => props.theme.currentTheme.mainFontColor};
		font-style: normal;
		font-weight: 400;
		font-size: 22px;
		line-height: 145%;

		& .js-code-value {
			width: 100%;
			display: flex;
			transition: all 0.4s;
			gap: 0.5rem;

			padding: 20px 24px 22px 24px;
			border: 1px solid ${(props) => props.theme.currentTheme.borderInputs};
			max-width: 444px;
			border-radius: 8px;
			height: ${(props) => props.theme.heightCode};
			overflow: hidden;

			& .js-code-text {
				width: 95%;
				hyphens: auto;
				/* word-wrap: anywhere; */
				width: 100%;
				overflow: hidden;

				& .script-text {
					hyphens: auto;
					/* word-wrap: anywhere; */
					overflow: hidden;
				}
			}

			& .js-code-arrow-expand {
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .receive-js-code-area {
			font-size: 16px;

			& .js-code-value {
				padding: 16px 20px 16px 20px;

				height: ${(props) => props.theme.heightCodeSmallScreen};

				& .js-code-arrow-expand {
					padding-top: 5px;
				}
			}
		}
	}

	@media screen and (max-width: 800px) {
		& .receive-js-code-area {
			flex-direction: column;
			justify-content: center;
			align-items: center;

			& .js-code-value {
				max-width: 100%;
			}

			& .button-copy {
				width: 100%;
				& button {
					width: 100%;
				}
			}
		}
	}
`;
