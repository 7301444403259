import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { StyledContactsMenu } from './ContactsMenu.style';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { InputsFieldContacts } from './InputsFieldContacts/InputsFieldContacts';
import { setEmptyStatusForContacts } from '../../app/slices/inputsContacts/checkEmptyStatusForContactsSlice';
import { useCreateFeedbackMutation } from '../../app/api/chatbot.api';
import {
	type ICreateFeedbackResponse,
	type IResponseDataWithError,
} from '../../app/api/interfaces/interfaces';
import { ProgressSpinner } from '../shared/ProgressSpinner/ProgressSpinner';
import { SharedMainButton } from '../shared/SharedMainButton/SharedMainButton';
import { SharedModal } from '../shared/SharedModal/SharedModal';
import letter from './pic/letter.svg';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

interface IDataResponseFeedback {
	data: ICreateFeedbackResponse;
}

export const ContactsMenu: React.FC = () => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const contactsInputsValue = useAppSelector(
		(state: RootState) => state.contactsInputsValue
	);
	const validInputsStatusContacts = useAppSelector(
		(state: RootState) => state.isValidContactsInputs
	);

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const { isValidNameContacts, isValidMessageContacts } =
		validInputsStatusContacts;

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);
	const [messageFeedback, setMessageFeedback] = useState('');
	const [reCaptchaToken, setTokenReCaptcha] = useState('');

	const [createFeedback, resultOfCreateFeedback] = useCreateFeedbackMutation();

	const [isOpenModalAlertSendMessage, setIsOpenModalAlertSendMessage] =
		useState(false);
	const handleCloseModalAlertSendMessage = (): void => {
		setIsOpenModalAlertSendMessage(false);
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setEmptyStatusForContacts(false));
		setIsOpenModalAlertSendMessage(false);
	}, []);

	const sendContactInformation = (): void => {
		dispatch(setEmptyStatusForContacts(true));
		if (
			contactsInputsValue.nameContacts.length > 0 &&
			contactsInputsValue.messageContacts.length > 0 &&
			isValidNameContacts &&
			isValidMessageContacts
		) {
			setIsOpenModalAlertSendMessage(true);
			createFeedback({
				message: {
					message: contactsInputsValue.messageContacts,
					subject: contactsInputsValue.nameContacts,
				},
				token,
				reCaptchaToken,
			})
				.then((response: IDataResponseFeedback | IResponseDataWithError) => {
					// console.log(response);
					if ('data' in response) {
						// console.log(response.data);
					} else if ('error' in response) {
						// console.log(response.error.data.message);
						setMessageFeedback('Error sending feedback');
					}
				})
				.catch((error) => {
					console.log(error);
					setMessageFeedback('Server error: Failed to fetch');
				});
		}
	};

	useEffect(() => {
		resultOfCreateFeedback.isLoading &&
			setLoadingErrorContent(<ProgressSpinner />);
		resultOfCreateFeedback.isError &&
			setLoadingErrorContent(
				<Box
					component="div"
					sx={{ color: '#fff' }}
					className="error-message-from-api"
				>
					{messageFeedback}
				</Box>
			);
		resultOfCreateFeedback.isSuccess &&
			setLoadingErrorContent(
				<Box
					component="div"
					sx={{ color: '#fff' }}
					className="error-message-from-api"
				>
					Your message has been sent successfully
				</Box>
			);
	}, [resultOfCreateFeedback, messageFeedback]);

	const theme = { currentTheme };

	const onVerifyReCaptcha = useCallback((token: string): void => {
		setTokenReCaptcha(token);
	}, []);

	return (
		<StyledContactsMenu theme={theme}>
			<Box component="div" className="contacts-wrapper-border">
				<Box component="div" className="contacts-wrapper">
					<Box component="div" className="contacts-title-area">
						Contact us
					</Box>

					<Box component="div" className="contacts-inputs-area">
						<Box component="form" className="contacts-inputs-field">
							<InputsFieldContacts />
							<GoogleReCaptcha onVerify={onVerifyReCaptcha} />
						</Box>
						<SharedMainButton
							isDeleteButton={false}
							text="Send"
							buttonFunction={sendContactInformation}
							tooltip="Submit your feedback"
						/>
						{isOpenModalAlertSendMessage ? (
							<SharedModal
								executeFn={() => {}}
								closeFn={handleCloseModalAlertSendMessage}
								message={loadingErrorContent}
								title=""
								isOnlyAlert
								icon={
									<Box component="img" className="current-icon" src={letter} />
								}
							/>
						) : (
							<></>
						)}
						<Box component="span" className="info-recaptcha-wrapper">
							This site is protected by reCAPTCHA and the Google
							<Box
								component="a"
								target="_blank"
								href="https://policies.google.com/privacy"
							>
								Privacy Policy
							</Box>{' '}
							and
							<Box
								component="a"
								target="_blank"
								href="https://policies.google.com/terms"
							>
								Terms of Service
							</Box>{' '}
							apply.
						</Box>
					</Box>
				</Box>
			</Box>
		</StyledContactsMenu>
	);
};
