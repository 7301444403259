// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialSearchBarValueState {
	searchBarValue: string;
	searchBarValueForRefetching: string;
	sortOrder: string;
	currentOrder: string;
}

const initialState: IInitialSearchBarValueState = {
	searchBarValue: '',
	searchBarValueForRefetching: '',
	sortOrder: 'uri,DESC',
	currentOrder: 'DESC',
};

const searchBarValueSlice = createSlice({
	name: 'searchBarValue',
	initialState,
	reducers: {
		setSearchBarValue(state, action: PayloadAction<string>) {
			state.searchBarValue = action.payload;
			return state;
		},
		removeSearchBarValue(state) {
			state.searchBarValue = '';
			return state;
		},
		activateSearchBarForRefetching(state, action: PayloadAction<string>) {
			state.searchBarValueForRefetching = action.payload;
			return state;
		},
		sortOrderForRefetching(state, action: PayloadAction<string>) {
			if (state.currentOrder === 'DESC') {
				state.currentOrder = 'ASC';
				state.sortOrder = `${action.payload},ASC`;
			} else {
				state.currentOrder = 'DESC';
				state.sortOrder = `${action.payload},DESC`;
			}
			return state;
		},
		// sortOrderForRefetching(state) {
		// 	if (state.sortOrder === 'uri,DESC') {
		// 		state.sortOrder = 'uri,ASC';
		// 	} else {
		// 		state.sortOrder = 'uri,DESC';
		// 	}
		// 	return state;
		// },
	},
});

export const {
	setSearchBarValue,
	removeSearchBarValue,
	activateSearchBarForRefetching,
	sortOrderForRefetching,
} = searchBarValueSlice.actions;
export const searchBarValueReducer = searchBarValueSlice.reducer;
