// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import {
	type BaseQueryApi,
	type FetchArgs,
	type FetchBaseQueryError,
	type FetchBaseQueryMeta,
	createApi,
	fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import {
	type IConfirmEmail,
	type ICreateUserData,
	type IResponseBotDelete,
	type IResponseCreateBotSuccess,
	type IResponseCreateUserSuccess,
	type IResponseDataBot,
	type IResponseForgotPasswordSuccess,
	type IResponseLoginSuccess,
	type IResponsePatchUserSuccess,
	type IResponseUserDelete,
	type IUserLoginData,
	type ICreateFeedbackResponse,
	type IResendEmailResponse,
	// type IGetCurrentBotResponse,
	type IPersonalInformationResponse,
	type IPatchCurrentBotDataRequestResponse,
	type IIPatchCurrentBotDataRequestWithIdAndToken,
	type IIPatchCurrentBotUploadImageResponse,
	type IIPatchCurrentBotUploadImageWithIdAndToken,
	type ILoginWithGoogle,
	type ILoginWithGoogleAccessTokenResponse,
	type ILoginWithGoogleAccessTokenRequest,
} from './interfaces/interfaces';
import { type RootState } from '../store';
import { type QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
	type IGetCurrentBotWithToken,
	type IConfirmEmailCode,
	type ICreateChatBotDataWithToken,
	type ICreateFeedbackWithToken,
	type IDeleteBotDataWithToken,
	type IDeleteUserDataWithToken,
	type IForgotPassword,
	type IGetAllBotsWithToken,
	type IResendEmail,
	type IResetPasswordWithToken,
	type IUpdateUserDataWithToken,
	type IStatisticAboutAccountResponse,
	type IStatisticAboutAccountRequest,
	type IImportSnippetsRequest,
	type IImportSnippetsResponse,
} from './interfaces/interfacesRequest';
import {
	clearStorageAndLogout,
	removeItem,
	setItem,
} from '../utils/storageUtils';
import { setAnswerApiLoginToken } from '../slices/answersFromAPI/answerApiLoginSlice';
import { type MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import {
	type IGetSnippetsByChatbotIdResponse,
	type IGetSnippetsByChatbotIdRequest,
	type IImportUrlToCrawlRequest,
	type IImportUrlToCrawlResponse,
	type ICreateSnippetResponse,
	type ICreateSnippetRequest,
	type IUpdateSnippetRequest,
	type IUpdateSnippetResponse,
	type IDeleteSnippetRequest,
	type IDeleteSnippetResponse,
	type IVectoriseSnippetsByChatbotIdResponse,
	type IVectoriseSnippetsByChatbotIdRequest,
	type IDeleteAllSnippetsResponse,
	type IDeleteAllSnippetsRequest,
	type ITestBotRequest,
	type ITestBotResponse,
	type IGetHistoryRequest,
	type IGetHistoryResponse,
	type IExportSnippetsRequest,
	type IExportSnippetsResponse,
	type IGetNotVectorisedSnippetsByChatbotIdResponse,
	type IGetNotVectorisedSnippetsByChatbotIdRequest,
	type IDeleteSelectedSnippetsResponse,
	type IDeleteSelectedSnippetsRequest,
	type IExportSelectedSnippetsRequest,
	type IExportSelectedSnippetsResponse,
} from './interfaces/snippets.interface';
import {
	type IGetHistoryFondyRequest,
	type IGetHistoryFondyResponse,
} from './interfaces/fondy/getHistoryFondy.interface';
import {
	type IGetRectokensCardsFondyRequest,
	type IGetRectokensCardsFondyResponse,
} from './interfaces/fondy/getRectokensCardsFondy.interface';
import { type ICancelSubscriptionFondyRequest } from './interfaces/fondy/cancelSubscriptionFondy.interface';
import {
	type IGetProductsChatbotResponse,
	type IGetProductsChatbotRequest,
} from './interfaces/fondy/getProductsChatbot.interface';
import { type IDeletePaymentMethodRequest } from './interfaces/fondy/deletePaymentMethod.interface';
import {
	type IGetChatbotTemplateRequest,
	type IGetChatbotTemplateResponse,
} from './interfaces/styleChatbot/getChatbotTemplate';
import {
	type IGetChatbotStyleByTemplateRequest,
	type IGetChatbotStyleByTemplateResponse,
} from './interfaces/styleChatbot/getChatbotStyleByTemplate';
import { type IGetOneChatbotDataResponse } from './interfaces/getOneChatbot';
import {
	type IUpdateConfigRequest,
	type IUpdateConfigResponse,
} from './interfaces/config/updateConfig.interface';
import {
	type IGetConfigRequest,
	type IGetConfigResponse,
} from './interfaces/config/getConfig.interface';
import {
	type IGetOpenAiModelsRequest,
	type IGetOpenAiModelsResponse,
} from './interfaces/config/getOpenAiModels.interface';
import {
	type IGetMessengerBotsRequest,
	type IGetMessengerBotsResponse,
} from './interfaces/messenger/getMessengerBots.interface';
import { type ICreateMessengerBotRequest } from './interfaces/messenger/createMessengerBot.interface';
import {
	type IUpdateMessengerBotRequest,
	type IUpdateMessengerBotResponse,
} from './interfaces/messenger/updateMessengerBot.interface';
import {
	type IDeleteMessengerBotsRequest,
	type IDeleteMessengerBotsResponse,
} from './interfaces/messenger/deleteMessengerBots.interface';

const myHeaders = {
	'Access-Control-Allow-Origin': '*',
	'Content-Type': 'application/json',
	'Access-Control-Allow-Methods': 'POST, PATCH, DELETE, GET',
};

const query = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_BASE_LINK,
});

export const refreshTokenExecute = async (
	tokenForRefresh: string,
	api: BaseQueryApi,
	extraOptions: object,
	result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>,
	args: FetchArgs,
	queryFromApi: (
		args: string | FetchArgs,
		api: BaseQueryApi,
		extraOptions: object
	) => MaybePromise<
		QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
	>
): Promise<void> => {
	try {
		const refreshResult: QueryReturnValue<
			any,
			FetchBaseQueryError,
			FetchBaseQueryMeta
		> = await query(
			{
				url: `/auth/refresh-token`,
				method: 'POST',
				body: JSON.stringify({ token: tokenForRefresh }),
				headers: myHeaders,
			},
			api,
			extraOptions
		);

		if ('data' in refreshResult && 'token' in refreshResult.data) {
			console.log(refreshResult, api.endpoint);
			setItem('token', refreshResult.data.token);
			api.dispatch(setAnswerApiLoginToken(refreshResult.data.token));
			result = await queryFromApi(args, api, extraOptions);
		} else {
			console.log(refreshResult, api.endpoint);

			removeItem('token');
			api.dispatch(setAnswerApiLoginToken(''));
			clearStorageAndLogout(api.dispatch);
		}
	} catch (error) {
		console.log(error);
	}
};

const extendedQuery = async (
	args: FetchArgs,
	api: BaseQueryApi,
	extraOptions: object
): Promise<
	QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
> => {
	// eslint-disable-next-line prefer-const
	let result = await query(args, api, extraOptions);
	const tokenForRefresh = (api.getState() as RootState).answerApiLogin.token;

	if (result.meta?.response?.status === 401) {
		void refreshTokenExecute(
			tokenForRefresh,
			api,
			extraOptions,
			result,
			args,
			query
		);
	} else {
		// console.log('');
		// console.log(result.meta?.response?.status);
	}
	return result;
};

export const chatbotApi = createApi({
	reducerPath: 'chatbotApi',
	baseQuery: extendedQuery,
	tagTypes: ['Post', 'Patch', 'Delete'],
	endpoints: (builder) => ({
		// Запити для юзерів: оновити/видалити
		updateUser: builder.mutation<
			IResponsePatchUserSuccess,
			IUpdateUserDataWithToken
		>({
			query: (updateUserDataWithToken: IUpdateUserDataWithToken) => ({
				url: `/users`,
				method: 'PATCH',
				body: JSON.stringify(updateUserDataWithToken.updateUserData),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${updateUserDataWithToken.token}`,
				},
			}),
		}),
		deleteUser: builder.mutation<IResponseUserDelete, IDeleteUserDataWithToken>(
			{
				query: (deleteUserDataWithToken: IDeleteUserDataWithToken) => ({
					url: `/users`,
					method: 'DELETE',
					headers: {
						...myHeaders,
						Authorization: `Bearer ${deleteUserDataWithToken.token}`,
					},
				}),
			}
		),
		// Запити для юзерів: авторизація, логін
		createUser: builder.mutation<IResponseCreateUserSuccess, ICreateUserData>({
			query: (createUserData: ICreateUserData) => ({
				url: `/auth/register`,
				method: 'POST',
				body: JSON.stringify(createUserData),
				headers: myHeaders,
			}),
		}),
		loginUser: builder.mutation<IResponseLoginSuccess, IUserLoginData>({
			query: (loginUserData: IUserLoginData) => ({
				url: `/auth/login`,
				method: 'POST',
				body: JSON.stringify(loginUserData),
				headers: myHeaders,
			}),
		}),
		loginWithGoogle: builder.mutation<IResponseLoginSuccess, ILoginWithGoogle>({
			query: (loginWithGoogleData: ILoginWithGoogle) => ({
				url: `/auth/login/google`,
				method: 'POST',
				body: JSON.stringify(loginWithGoogleData),
				headers: myHeaders,
			}),
		}),
		loginWithGoogleByAccessToken: builder.mutation<
			ILoginWithGoogleAccessTokenResponse,
			ILoginWithGoogleAccessTokenRequest
		>({
			query: (
				loginWithGoogleByAccessTokenBody: ILoginWithGoogleAccessTokenRequest
			) => ({
				url: `/auth/login/google/access-token`,
				method: 'POST',
				body: JSON.stringify(loginWithGoogleByAccessTokenBody),
				headers: myHeaders,
			}),
		}),

		forgotPassword: builder.mutation<
			IResponseForgotPasswordSuccess,
			IForgotPassword
		>({
			query: (forgotPasswordData: IForgotPassword) => ({
				url: `/auth/password/forgot`,
				method: 'POST',
				body: JSON.stringify(forgotPasswordData),
				headers: myHeaders,
			}),
		}),

		resetPassword: builder.mutation<string, IResetPasswordWithToken>({
			query: (resetPasswordDataWithToken: IResetPasswordWithToken) => ({
				url: `/auth/password/reset`,
				method: 'POST',
				body: JSON.stringify(resetPasswordDataWithToken),
				headers: myHeaders,
			}),
		}),

		// Запити для ботів: створити, видалити, отримати всі
		createBot: builder.mutation<
			IResponseCreateBotSuccess,
			ICreateChatBotDataWithToken
		>({
			query: (createBotDataWithToken: ICreateChatBotDataWithToken) => ({
				url: `/chatbot/create`,
				method: 'POST',
				body: JSON.stringify(createBotDataWithToken.createBotData),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${createBotDataWithToken.token}`,
				},
			}),
		}),
		deleteBot: builder.mutation<IResponseBotDelete, IDeleteBotDataWithToken>({
			query: (deleteBotDataWithToken: IDeleteBotDataWithToken) => ({
				url: `/chatbot?assetId=${deleteBotDataWithToken.id.toString()}`,
				method: 'DELETE',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${deleteBotDataWithToken.token}`,
				},
			}),
		}),

		// Отримати інформацію про всі боти
		getAllBots: builder.query<IResponseDataBot[], IGetAllBotsWithToken>({
			query: (getAllBotsProps: IGetAllBotsWithToken) => ({
				url: `/chatbot/get-all`,
				headers: {
					Authorization: `Bearer ${getAllBotsProps.token}`,
				},
			}),
		}),

		// Отримати інформацію про бота
		getCurrentBot: builder.query<
			IGetOneChatbotDataResponse,
			IGetCurrentBotWithToken
		>({
			query: (idWithToken: IGetCurrentBotWithToken) => ({
				url: `/chatbot/get-one/${idWithToken.id.toString()}`,
				headers: {
					Authorization: `Bearer ${idWithToken.token}`,
				},
			}),
		}),

		// Оновити інформацію про бота
		patchCurrentBot: builder.mutation<
			IPatchCurrentBotDataRequestResponse,
			IIPatchCurrentBotDataRequestWithIdAndToken
		>({
			query: (
				patchDataWithIdAndToken: IIPatchCurrentBotDataRequestWithIdAndToken
			) => ({
				url: `/chatbot/${patchDataWithIdAndToken.id.toString()}`,
				method: 'PATCH',
				body: JSON.stringify(patchDataWithIdAndToken.patchData),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${patchDataWithIdAndToken.token}`,
				},
			}),
		}),

		// Update icon of the Chatbot
		// updateIconCurrentBot: builder.mutation<
		// 	IIPatchCurrentBotUploadImageResponse,
		// 	IIPatchCurrentBotUploadImageWithIdAndToken
		// >({
		// 	query: (
		// 		patchDataWithIdAndToken: IIPatchCurrentBotUploadImageWithIdAndToken
		// 	) => ({
		// 		url: `/chatbot/upload-image/${patchDataWithIdAndToken.id.toString()}`,
		// 		method: 'PATCH',
		// 		body: patchDataWithIdAndToken.patchData,
		// 		headers: {
		// 			'Access-Control-Allow-Origin': '*',
		// 			'Access-Control-Allow-Methods': 'PATCH',
		// 			Authorization: `Bearer ${patchDataWithIdAndToken.token}`,
		// 		},
		// 	}),
		// }),

		updateIconCurrentBot: builder.mutation<
			IIPatchCurrentBotUploadImageResponse,
			IIPatchCurrentBotUploadImageWithIdAndToken
		>({
			query: (
				patchDataWithIdAndToken: IIPatchCurrentBotUploadImageWithIdAndToken
			) => ({
				url: `/chatbot/upload-image/base64/${patchDataWithIdAndToken.id.toString()}`,
				method: 'PATCH',
				body: JSON.stringify(patchDataWithIdAndToken.patchData),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${patchDataWithIdAndToken.token}`,
				},
			}),
		}),

		// resend email
		resendEmail: builder.mutation<IResendEmailResponse, IResendEmail>({
			query: (emailResend: IResendEmail) => ({
				url: `/email/resend`,
				method: 'POST',
				body: JSON.stringify(emailResend),
				headers: myHeaders,
			}),
		}),

		// confirm email
		confirmEmail: builder.mutation<IConfirmEmail, IConfirmEmailCode>({
			query: (codeWithEmail: IConfirmEmailCode) => ({
				url: `/email/confirm`,
				method: 'POST',
				body: JSON.stringify(codeWithEmail),
				headers: myHeaders,
			}),
		}),

		// create feedback
		createFeedback: builder.mutation<
			ICreateFeedbackResponse,
			ICreateFeedbackWithToken
		>({
			query: (createFeedbackWithToken: ICreateFeedbackWithToken) => ({
				url: `/feedback/create`,
				method: 'POST',
				body: JSON.stringify(createFeedbackWithToken.message),
				headers: {
					...myHeaders,
					'recapcha-token': createFeedbackWithToken.reCaptchaToken,
					Authorization: `Bearer ${createFeedbackWithToken.token}`,
				},
			}),
		}),

		// Get info about user
		getPersonalInformation: builder.query<IPersonalInformationResponse, string>(
			{
				query: (token: string) => ({
					url: `/users`,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}),
			}
		),

		// Snippets menu

		// Get snippets by Chatbot id
		getSnippetsByChatbotId: builder.query<
			IGetSnippetsByChatbotIdResponse,
			IGetSnippetsByChatbotIdRequest
		>({
			query: (
				getSnippetsByChatbotIdRequest: IGetSnippetsByChatbotIdRequest
			) => ({
				url: `
						/chatbot/training-snippets
						?chatBotIDs=${getSnippetsByChatbotIdRequest.chatbotId}
						&page=${getSnippetsByChatbotIdRequest.page}
						&perPage=${getSnippetsByChatbotIdRequest.perPage}
						&order=${getSnippetsByChatbotIdRequest.order}
						&search=${getSnippetsByChatbotIdRequest.prompt}
					`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getSnippetsByChatbotIdRequest.token}`,
				},
			}),
		}),

		// import URL to crawl
		importUrlToCrawl: builder.mutation<
			IImportUrlToCrawlResponse,
			IImportUrlToCrawlRequest
		>({
			query: (importUrlToCrawlRequest: IImportUrlToCrawlRequest) => ({
				url: `/chatbot/import-page`,
				method: 'POST',
				body: JSON.stringify(importUrlToCrawlRequest.importUrlBody),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${importUrlToCrawlRequest.token}`,
				},
			}),
		}),

		// create Snippet
		createSnippet: builder.mutation<
			ICreateSnippetResponse,
			ICreateSnippetRequest
		>({
			query: (createSnippetRequest: ICreateSnippetRequest) => ({
				url: `/chatbot/training-snippets/${createSnippetRequest.id}`,
				method: 'POST',
				body: JSON.stringify(createSnippetRequest.createSnippetBody),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${createSnippetRequest.token}`,
				},
			}),
		}),

		// update Snippet
		updateSnippet: builder.mutation<
			IUpdateSnippetResponse,
			IUpdateSnippetRequest
		>({
			query: (updateSnippetRequest: IUpdateSnippetRequest) => ({
				url: `/chatbot/training-snippets/${updateSnippetRequest.id}`,
				method: 'PATCH',
				body: JSON.stringify(updateSnippetRequest.updateSnippetBody),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${updateSnippetRequest.token}`,
				},
			}),
		}),

		// delete Snippet
		deleteSnippet: builder.mutation<
			IDeleteSnippetResponse,
			IDeleteSnippetRequest
		>({
			query: (deleteSnippetRequest: IDeleteSnippetRequest) => ({
				url: `/chatbot/training-snippets/${deleteSnippetRequest.id}`,
				method: 'DELETE',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${deleteSnippetRequest.token}`,
				},
			}),
		}),

		// delete All Snippets
		deleteAllSnippets: builder.mutation<
			IDeleteAllSnippetsResponse,
			IDeleteAllSnippetsRequest
		>({
			query: (deleteAllSnippetsRequest: IDeleteAllSnippetsRequest) => ({
				url: `/chatbot/training-snippets/all/${deleteAllSnippetsRequest.id}`,
				method: 'DELETE',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${deleteAllSnippetsRequest.token}`,
				},
			}),
		}),

		// delete Selected Snippets
		deleteSelectedSnippets: builder.mutation<
			IDeleteSelectedSnippetsResponse,
			IDeleteSelectedSnippetsRequest
		>({
			query: (
				deleteSelectedSnippetsRequest: IDeleteSelectedSnippetsRequest
			) => ({
				url: `/chatbot/training-snippets`,
				method: 'DELETE',
				body: JSON.stringify({ ids: deleteSelectedSnippetsRequest.ids }),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${deleteSelectedSnippetsRequest.token}`,
				},
			}),
		}),

		// vectorise snippets by chatbotID
		vectoriseSnippetsByChatbotId: builder.query<
			IVectoriseSnippetsByChatbotIdResponse,
			IVectoriseSnippetsByChatbotIdRequest
		>({
			query: (
				vectoriseSnippetsByChatbotIdRequest: IVectoriseSnippetsByChatbotIdRequest
			) => ({
				url: `/chatbot/training-snippets/vectorise/${vectoriseSnippetsByChatbotIdRequest.chatbotId}`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${vectoriseSnippetsByChatbotIdRequest.token}`,
				},
			}),
		}),

		// test bot
		testBot: builder.mutation<ITestBotResponse, ITestBotRequest>({
			query: (testBotRequest: ITestBotRequest) => ({
				url: `/chatbot/chat/${testBotRequest.chatbotId}`,
				method: 'POST',
				body: JSON.stringify(testBotRequest.testBotBody),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${testBotRequest.token}`,
				},
			}),
		}),

		// Get history requests
		getHistoryRequests: builder.query<IGetHistoryResponse, IGetHistoryRequest>({
			query: (getHistoryRequest: IGetHistoryRequest) => ({
				url: `
						/chatbot/history
						?chatBotIDs=${getHistoryRequest.chatbotId}
						&page=${getHistoryRequest.page}
						&perPage=${getHistoryRequest.perPage}
						&order=${getHistoryRequest.order}
						&search=${getHistoryRequest.search}
						${
							getHistoryRequest.from !== undefined &&
							getHistoryRequest.to !== undefined
								? `
									&from=${getHistoryRequest.from}
									&to=${getHistoryRequest.to}
								`
								: ''
						}
					`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getHistoryRequest.token}`,
				},
			}),
		}),

		// Export snippets
		exportSnippets: builder.query<
			IExportSnippetsResponse,
			IExportSnippetsRequest
		>({
			query: (exportSnippetsRequest: IExportSnippetsRequest) => ({
				url: `/chatbot/snippets/export
					/${exportSnippetsRequest.chatbotId}
					/${exportSnippetsRequest.type}`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${exportSnippetsRequest.token}`,
				},
			}),
		}),

		// Export selected snippets
		exportSelectedSnippets: builder.mutation<
			IExportSelectedSnippetsResponse,
			IExportSelectedSnippetsRequest
		>({
			query: (
				exportSelectedSnippetsRequest: IExportSelectedSnippetsRequest
			) => ({
				url: `/chatbot/snippets/export
					/${exportSelectedSnippetsRequest.type}`,
				method: 'POST',
				body: JSON.stringify({
					ids: exportSelectedSnippetsRequest.snippetsArray,
				}),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${exportSelectedSnippetsRequest.token}`,
				},
			}),
		}),

		// get Statistic About Account
		getStatisticAboutAccount: builder.query<
			IStatisticAboutAccountResponse,
			IStatisticAboutAccountRequest
		>({
			query: (
				getStatisticAboutAccountRequest: IStatisticAboutAccountRequest
			) => ({
				url: `/chatbot/usage-statistics`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getStatisticAboutAccountRequest.token}`,
				},
			}),
		}),

		// get not vectorised Snippets By ChatbotId
		getNotVectorisedSnippetsByChatbotId: builder.query<
			IGetNotVectorisedSnippetsByChatbotIdResponse,
			IGetNotVectorisedSnippetsByChatbotIdRequest
		>({
			query: (
				getNotVectorisedSnippetsByChatbotIdRequest: IGetNotVectorisedSnippetsByChatbotIdRequest
			) => ({
				url: `/chatbot/snippets/not-vectorized/${getNotVectorisedSnippetsByChatbotIdRequest.chatbotId}`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getNotVectorisedSnippetsByChatbotIdRequest.token}`,
				},
			}),
		}),

		// import Snippets
		importSnippets: builder.mutation<
			IImportSnippetsResponse,
			IImportSnippetsRequest
		>({
			query: (importSnippetsRequest: IImportSnippetsRequest) => ({
				url: `/chatbot/snippets/import/${importSnippetsRequest.chatbotId}`,
				method: 'POST',
				body: importSnippetsRequest.importFileBody,
				formData: true,
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${importSnippetsRequest.token}`,
				},
			}),
		}),

		/* 
			--- Endpoints for Style Chatbot ---
		*/

		// get chatbot template structure
		getChatbotTemplate: builder.query<
			IGetChatbotTemplateResponse[],
			IGetChatbotTemplateRequest
		>({
			query: (getChatbotTemplate: IGetChatbotTemplateRequest) => ({
				url: `/chatbot/structure`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getChatbotTemplate.token}`,
				},
			}),
		}),

		// get chatbot Style by template structure
		getChatbotStyleByTemplate: builder.query<
			IGetChatbotStyleByTemplateResponse,
			IGetChatbotStyleByTemplateRequest
		>({
			query: (
				getChatbotStyleByTemplateRequest: IGetChatbotStyleByTemplateRequest
			) => ({
				url: `/chatbot/structure/${getChatbotStyleByTemplateRequest.id}`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getChatbotStyleByTemplateRequest.token}`,
				},
			}),
		}),

		/* 
			--- Endpoints for FONDY ---
		*/

		// get products by category chatbot - Fondy
		getProductsChatbot: builder.query<
			IGetProductsChatbotResponse,
			IGetProductsChatbotRequest
		>({
			query: (getProductsChatbot: IGetProductsChatbotRequest) => ({
				url: `/product/category/chatbot`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getProductsChatbot.token}`,
				},
			}),
		}),

		// get History Payments - Fondy
		getHistoryFondy: builder.query<
			IGetHistoryFondyResponse,
			IGetHistoryFondyRequest
		>({
			query: (getHistoryFondyRequest: IGetHistoryFondyRequest) => ({
				url: `/payment/history
					?page=${getHistoryFondyRequest.params.page}
					&perPage=${getHistoryFondyRequest.params.perPage}
					&order=id,DESC
				`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getHistoryFondyRequest.token}`,
				},
			}),
		}),

		// Get all saved cards - Fondy
		getRectokensCardsFondy: builder.query<
			IGetRectokensCardsFondyResponse,
			IGetRectokensCardsFondyRequest
		>({
			query: (getRectokensCardsFondy: IGetRectokensCardsFondyRequest) => ({
				url: `/payment/rectokens`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getRectokensCardsFondy.token}`,
				},
			}),
		}),

		// cancel subscription - Fondy
		cancelSubscriptionFondy: builder.query<
			any,
			ICancelSubscriptionFondyRequest
		>({
			query: (cancelSubscriptionFondy: ICancelSubscriptionFondyRequest) => ({
				url: `/payment/cancel-subscription`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${cancelSubscriptionFondy.token}`,
				},
			}),
		}),

		// delete current payment method /payment/rectoken/{id}
		deletePaymentMethod: builder.mutation<any, IDeletePaymentMethodRequest>({
			query: (deletePaymentMethodRequestBody: IDeletePaymentMethodRequest) => ({
				url: `/payment/rectoken/${deletePaymentMethodRequestBody.id}`,
				method: 'DELETE',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${deletePaymentMethodRequestBody.token}`,
				},
			}),
		}),

		/* 
			CONFIG
		*/
		// get Config by chatbot id
		getConfig: builder.query<IGetConfigResponse, IGetConfigRequest>({
			query: (getConfig: IGetConfigRequest) => ({
				url: `/chatbot/ml-type/${getConfig.chatbotId}`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getConfig.token}`,
				},
			}),
		}),
		// update Config
		updateConfig: builder.mutation<IUpdateConfigResponse, IUpdateConfigRequest>(
			{
				query: (updateSnippetRequest: IUpdateConfigRequest) => ({
					url: `/chatbot/ml-type/${updateSnippetRequest.chatbotId}`,
					method: 'PATCH',
					body: JSON.stringify(updateSnippetRequest.updateConfigRequestBody),
					headers: {
						...myHeaders,
						Authorization: `Bearer ${updateSnippetRequest.token}`,
					},
				}),
			}
		),
		// get openAI models
		getOpenAiModels: builder.query<
			IGetOpenAiModelsResponse[],
			IGetOpenAiModelsRequest
		>({
			query: (getOpenAiModelsRequest: IGetOpenAiModelsRequest) => ({
				url: `/chatbot/openai/models`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getOpenAiModelsRequest.token}`,
				},
			}),
		}),

		/*
		 * Messenger API
		 */

		// get Messenger Bots
		getMessengerBots: builder.query<
			IGetMessengerBotsResponse,
			IGetMessengerBotsRequest
		>({
			query: (getMessengerBotsRequest: IGetMessengerBotsRequest) => ({
				url: `/messenger/bots`,
				headers: {
					...myHeaders,
					Authorization: `Bearer ${getMessengerBotsRequest.token}`,
				},
			}),
		}),

		// create Messenger Bot
		createMessengerBot: builder.mutation<any, ICreateMessengerBotRequest>({
			query: (createMessengerBotRequest: ICreateMessengerBotRequest) => ({
				url: `/messenger/create-bot`,
				method: 'POST',
				body: JSON.stringify(createMessengerBotRequest.createMessengerBotBody),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${createMessengerBotRequest.token}`,
				},
			}),
		}),

		// update Messenger Bot
		updateMessengerBot: builder.mutation<
			IUpdateMessengerBotResponse,
			IUpdateMessengerBotRequest
		>({
			query: (updateMessengerBotRequest: IUpdateMessengerBotRequest) => ({
				url: `/messenger/bot/${updateMessengerBotRequest.chatbotIdIntegration}`,
				method: 'PATCH',
				body: JSON.stringify(updateMessengerBotRequest.updateMessengerBotBody),
				headers: {
					...myHeaders,
					Authorization: `Bearer ${updateMessengerBotRequest.token}`,
				},
			}),
		}),

		// delete Messenger Bot
		deleteMessengerBot: builder.mutation<
			IDeleteMessengerBotsResponse,
			IDeleteMessengerBotsRequest
		>({
			query: (deleteMessengerBotRequest: IDeleteMessengerBotsRequest) => ({
				url: `/messenger/bot/${deleteMessengerBotRequest.chatbotId}`,
				method: 'DELETE',
				headers: {
					...myHeaders,
					Authorization: `Bearer ${deleteMessengerBotRequest.token}`,
				},
			}),
		}),
	}),
});

export const {
	useUpdateUserMutation,
	useCreateUserMutation,
	useLoginUserMutation,
	useDeleteUserMutation,
	useCreateBotMutation,
	useDeleteBotMutation,
	useForgotPasswordMutation,
	useGetAllBotsQuery,
	usePatchCurrentBotMutation,
	useUpdateIconCurrentBotMutation,
	useResetPasswordMutation,
	useConfirmEmailMutation,
	useResendEmailMutation,
	useCreateFeedbackMutation,
	useGetCurrentBotQuery,
	useLazyGetCurrentBotQuery,
	useGetPersonalInformationQuery,
	useLoginWithGoogleMutation,
	useLoginWithGoogleByAccessTokenMutation,
	useGetSnippetsByChatbotIdQuery,
	useImportUrlToCrawlMutation,
	useCreateSnippetMutation,
	useUpdateSnippetMutation,
	useDeleteSnippetMutation,
	useVectoriseSnippetsByChatbotIdQuery,
	useLazyVectoriseSnippetsByChatbotIdQuery,
	useDeleteAllSnippetsMutation,
	useDeleteSelectedSnippetsMutation,
	useTestBotMutation,
	useGetHistoryRequestsQuery,
	useLazyGetHistoryRequestsQuery,
	useLazyExportSnippetsQuery,
	useExportSelectedSnippetsMutation,
	useGetStatisticAboutAccountQuery,
	useLazyGetStatisticAboutAccountQuery,
	useLazyGetNotVectorisedSnippetsByChatbotIdQuery,
	useImportSnippetsMutation,
	/* 
		--- Endpoints for Style Chatbot ---
	*/
	useGetChatbotTemplateQuery,
	useLazyGetChatbotStyleByTemplateQuery,
	/* 
		--- Endpoints for FONDY ---
	*/
	useGetProductsChatbotQuery,
	useLazyGetHistoryFondyQuery,
	useGetRectokensCardsFondyQuery,
	useLazyGetRectokensCardsFondyQuery,
	useLazyCancelSubscriptionFondyQuery,
	useDeletePaymentMethodMutation,
	/* 
		CONFIG
	*/
	useUpdateConfigMutation,
	useGetConfigQuery,
	useGetOpenAiModelsQuery,
	/*
	 * Messenger API
	 */
	useGetMessengerBotsQuery,
	useLazyGetMessengerBotsQuery,
	useCreateMessengerBotMutation,
	useUpdateMessengerBotMutation,
	useDeleteMessengerBotMutation,
} = chatbotApi;
