import React from 'react';
import { Box } from '@mui/material';
import { AddCaptchaKeyInputsField } from './AddCaptchaKeyInputsField';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setEmptyStatusForCheckChatGeneratorFirstStep } from '../../../app/slices/inputsGenerator/checkEmptyStatusForChatGeneratorSlice';
import { setCurrentStepGenerator } from '../../../app/slices/currentStepGeneratorSlice';
import { SharedChatbotGeneratorStepWrapper } from '../SharedChatbotGeneratorStepWrapper/SharedChatbotGeneratorStepWrapper';
import { setItem } from '../../../app/utils/storageUtils';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';

export const AddCaptchaKeyComponent: React.FC = () => {
	const validInputsStatus = useAppSelector(
		(state: RootState) => state.isValidChatGeneratorInputs
	);
	const {
		isValidDomainGeneratorMenu,
		// isValidOpenAIKeyGeneratorMenu,
		// isValidReCaptchaKeyGeneratorMenu,
		// isValidReCaptchaSecretKeyGeneratorMenu,
	} = validInputsStatus;

	const inputsValues = useAppSelector(
		(state: RootState) => state.chatGeneratorInputsValue
	);

	// const isCheckboxActive = useAppSelector(
	// 	(state: RootState) => state.isCheckboxActive
	// );
	const {
		// openAIKey,
		// reCaptchaKey,
		domain,
		// reCaptchaSecretKey
	} = inputsValues;

	const dispatch = useAppDispatch();

	const handlePressStartButton = (): void => {
		dispatch(setEmptyStatusForCheckChatGeneratorFirstStep(true));

		// if (isCheckboxActive) {
		if (domain.length > 0 && isValidDomainGeneratorMenu) {
			dispatch(setCurrentStepGenerator(1));
			setItem(
				'chatbotData',
				JSON.stringify({
					domain,
					// openAIKey: 'none',
					// reCaptchaKey: 'none',
					// reCaptchaSecretKey: 'none'
				})
			);
			setItem('currentStepGenerator', '1');
		}
		// } else if (
		// 	openAIKey.length > 0 &&
		// 	reCaptchaKey.length > 0 &&
		// 	domain.length > 0 &&
		// 	reCaptchaSecretKey.length > 0 &&
		// 	isValidDomainGeneratorMenu &&
		// 	isValidOpenAIKeyGeneratorMenu &&
		// 	isValidReCaptchaKeyGeneratorMenu &&
		// 	isValidReCaptchaSecretKeyGeneratorMenu
		// ) {
		// 	dispatch(setCurrentStepGenerator(1));
		// 	setItem(
		// 		'chatbotData',
		// 		JSON.stringify({
		// 			domain,
		// 			openAIKey,
		// 			reCaptchaKey,
		// 			reCaptchaSecretKey,
		// 		})
		// 	);
		// 	setItem('currentStepGenerator', '1');
		// }
	};

	return (
		<SharedChatbotGeneratorStepWrapper>
			<Box component="div" className="add-inputs-field">
				<AddCaptchaKeyInputsField />
			</Box>
			<Box component="div" className="add-button">
				<SharedMainButton
					isDeleteButton={false}
					text="Next step"
					buttonFunction={handlePressStartButton}
				/>
			</Box>
		</SharedChatbotGeneratorStepWrapper>
	);
};
