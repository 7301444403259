import { Box } from '@mui/material';
import React from 'react';
import { CardInfoItem } from './CardInfoItem';
import { type IBotCustomizationInformation } from './DetailedBotCard';

interface IDetailedBotCardInputsFieldProps {
	botCustomizationInformation: IBotCustomizationInformation[];
}

export const DetailedBotCardInputsField: React.FC<
	IDetailedBotCardInputsFieldProps
> = ({ botCustomizationInformation }) => {
	return (
		<Box component="div" className="information-about-bot">
			{botCustomizationInformation?.map((cardInfoItem) => {
				return (
					<CardInfoItem
						key={cardInfoItem.title}
						title={cardInfoItem.title}
						value={cardInfoItem.value}
						changeableInput={cardInfoItem.changeableInput}
					/>
				);
			})}
		</Box>
	);
};
