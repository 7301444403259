import styled from 'styled-components';

export const StyledConfigOpenAiMenu = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	& .styledConfigOpenAiMenu-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;

		& .top-configs {
			display: flex;
			width: 100%;

			& .configWrappedInput {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 8px;

				& .shared-input {
					width: 100% !important;
				}
			}
		}

		& .aside-configs-wrapper {
			display: flex;
			gap: 1rem;
			justify-content: center;
			width: 100%;

			& > div {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				width: 50%;
			}

			& .configWrappedInput {
				display: flex;
				flex-direction: column;
				gap: 8px;
				width: 100%;

				& .shared-input {
					width: 100% !important;
				}

				& .configWrappedInput-title {
					color: ${(props) => props.theme.currentTheme.mainFontColor};
				}
			}
		}

		& .bottom-configs {
			display: flex;
			width: 100%;
			gap: 16px;

			& .configWrappedInput {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 8px;

				& .shared-input {
					width: 100% !important;
				}
			}
		}

		& .btn-field {
			width: 50%;
		}
	}

	@media screen and (max-width: 1430px) {
		& .styledConfigOpenAiMenu-wrapper {
			& .aside-configs-wrapper {
				/* flex-direction: column; */
				/* padding: 0.5rem; */
				& > div {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 1000px) {
		& .styledConfigOpenAiMenu-wrapper {
			& .aside-configs-wrapper {
				align-items: center;
			}
			& .top-configs {
				display: flex;
				width: 100%;

				& .configWrappedInput {
					width: 100%;

					& .shared-input {
						width: 100% !important;
					}
				}
			}
		}
	}

	@media screen and (max-width: 700px) {
		& .styledConfigOpenAiMenu-wrapper {
			& .aside-configs-wrapper {
				flex-direction: column;
			}
			& .bottom-configs {
				flex-direction: column;

				& .configWrappedInput {
					width: 100%;
					max-width: 100%;

					& .shared-input {
						width: 100% !important;
					}
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		& .styledConfigOpenAiMenu-wrapper {
			& .aside-configs-wrapper {
				width: 100%;

				& .shared-input {
					width: 100% !important;
				}
			}
		}
	}
`;
