// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialConfirmEmailInputValueValidStatus {
	isValidConfirmEmail: boolean;
}

const initialState: IInitialConfirmEmailInputValueValidStatus = {
	isValidConfirmEmail: false,
};

const isValidConfirmEmailInputValueSlice = createSlice({
	name: 'isValidConfirmEmailInputValue',
	initialState,
	reducers: {
		setValidEmailConfirmEmailStatus(state, action: PayloadAction<boolean>) {
			state.isValidConfirmEmail = action.payload;
			return state;
		},
	},
});

export const { setValidEmailConfirmEmailStatus } =
	isValidConfirmEmailInputValueSlice.actions;
export const isValidConfirmEmailInputValueReducer =
	isValidConfirmEmailInputValueSlice.reducer;
