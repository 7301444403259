// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledCustomStepper = styled.div`
	width: 100%;
	margin-bottom: 2rem;

	& .mobile-step {
		display: none;
		padding-top: 2rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: ${(props) => props.theme.mainFontColor};

		& .mobile-step-current {
			font-weight: 500;
			font-size: 16px;
			line-height: 135%;
		}

		& .mobile-step-text {
			font-weight: 400;
			font-size: 20px;
			line-height: 145%;
		}
	}

	& .MuiStepConnector-root {
		border: none;
		background: ${(props) => props.theme.stepperConnector};
		height: 4px;
		z-index: 100;
	}

	& .MuiStepConnector-root {
		& .MuiStepConnector-line {
			border: none;
		}
	}

	& .MuiStepConnector-root.MuiStepConnector-horizontal.Mui-completed {
		background: linear-gradient(131.11deg, #43f2ff, #691eff);
		height: 4px;
		z-index: 100;
	}

	& .MuiStepConnector-root.MuiStepConnector-horizontal.Mui-active {
		background: linear-gradient(131.11deg, #43f2ff, #691eff);
		height: 4px;
		z-index: 100;
	}

	& .MuiStepLabel-alternativeLabel {
		& .MuiSvgIcon-root.MuiStepIcon-root {
			background: linear-gradient(${(props) => props.theme.menuBackground} 0 0)
					padding-box,
				linear-gradient(131.11deg, #43f2ff, #691eff) border-box;
			border: 1px solid transparent;

			color: transparent;
			width: 32px;
			height: 32px;
			border-radius: 50%;
		}

		& .MuiSvgIcon-root.MuiStepIcon-root.Mui-active,
		.MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: linear-gradient(131.11deg, #43f2ff, #691eff) padding-box,
				linear-gradient(131.11deg, #43f2ff, #691eff) border-box;
			border: 1px solid transparent;
		}

		& .MuiStepLabel-label {
			margin-top: 24px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			color: ${(props) => props.theme.mainFontColor};
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 140%;
		}

		& .MuiStepLabel-label.Mui-active {
			& .text-in-step {
				color: ${(props) => props.theme.stepperTextColor};
			}
		}

		& .MuiStepIcon-text {
			fill: transparent;
		}
	}

	@media screen and (max-width: 1500px) {
		margin-bottom: 0.5rem;

		& .MuiStepLabel-alternativeLabel {
			& .MuiSvgIcon-root.MuiStepIcon-root {
				width: 25px;
				height: 25px;
			}

			& .MuiSvgIcon-root.MuiStepIcon-root.Mui-active,
			.MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
				width: 25px;
				height: 25px;
			}

			& .MuiStepLabel-label {
				margin-top: 16px;
				gap: 8px;
				font-size: 14px;
			}
		}
	}

	@media screen and (max-width: 500px) {
		& .mobile-step {
			display: flex;
		}

		& .MuiStepConnector-root {
			padding-bottom: 4px;
		}

		& .MuiStepLabel-alternativeLabel {
			& .MuiStepLabel-label {
				display: none;
				margin-top: 8px;
				gap: 8px;
				font-size: 12px;
			}
		}
	}
`;
