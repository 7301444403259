import React, { useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import { type RootState } from '../app/store';
import { Navigate } from 'react-router-dom';
import { PersonalCabinetMenu } from '../components/PersonalCabinetMenu/PersonalCabinetMenu';
import { LayoutAccount } from '../components/shared/LayoutAccount/LayoutAccount';
import {
	getItem,
	getSessionItem,
	removeSessionItem,
} from '../app/utils/storageUtils';

export const PersonalCabinetPage: React.FC = () => {
	const isAuthorization: boolean = useAppSelector(
		(state: RootState) => state.isAuthorization
	);

	useEffect(() => {
		const tokenFromStorage = getItem('token');
		if (tokenFromStorage !== null) {
			// clearSessionStorage();
			removeSessionItem('registrationData');
		}
	}, []);

	const CurrentComponentBySavePlanId: React.FC = () => {
		if (!isAuthorization) {
			return <Navigate to="/" replace={true} />;
		} else {
			const planIdFromSessionStorage = getSessionItem('planId');
			if (planIdFromSessionStorage !== null) {
				return <Navigate to="/plan" replace={true} />;
			} else {
				return <PersonalCabinetMenu />;
			}
		}
	};

	return (
		<LayoutAccount title="Dashboard">
			<CurrentComponentBySavePlanId />
		</LayoutAccount>
	);
};
