// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialCellsConfirmationCodeState {
	firstNumber: string;
	secondNumber: string;
	thirdNumber: string;
	fourthNumber: string;
	fifthNumber: string;
	sixthNumber: string;
	fullCode: string;
}

const initialState: IInitialCellsConfirmationCodeState = {
	firstNumber: '',
	secondNumber: '',
	thirdNumber: '',
	fourthNumber: '',
	fifthNumber: '',
	sixthNumber: '',
	fullCode: '',
};

const cellsConfirmationCodeSlice = createSlice({
	name: 'cellsConfirmationCode',
	initialState,
	reducers: {
		setNumber(state, action: PayloadAction<string[]>) {
			const sumNumbers = (): string =>
				state.firstNumber +
				state.secondNumber +
				state.thirdNumber +
				state.fourthNumber +
				state.fifthNumber +
				state.sixthNumber;

			const currentNumber = action.payload[0].toString();
			switch (action.payload[1]) {
				case 'first':
					state.firstNumber = currentNumber;
					state.fullCode = sumNumbers();
					return state;
				case 'second':
					state.secondNumber = currentNumber;
					state.fullCode = sumNumbers();
					return state;
				case 'third':
					state.thirdNumber = currentNumber;
					state.fullCode = sumNumbers();
					return state;
				case 'fourth':
					state.fourthNumber = currentNumber;
					state.fullCode = sumNumbers();
					return state;
				case 'fifth':
					state.fifthNumber = currentNumber;
					state.fullCode = sumNumbers();
					return state;
				case 'sixth':
					state.sixthNumber = currentNumber;
					state.fullCode = sumNumbers();
					return state;
				default:
					return state;
			}
		},
	},
});

export const { setNumber } = cellsConfirmationCodeSlice.actions;
export const cellsConfirmationCodeReducer = cellsConfirmationCodeSlice.reducer;
