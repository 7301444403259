import React from 'react';
import { Box } from '@mui/material';
import { StyledSaveDeleteBntFieldShared } from './SaveDeleteBntFieldShared.style';
import { SharedMainButton } from '../SharedMainButton/SharedMainButton';
import { NavLink } from 'react-router-dom';

interface ISaveDeleteBntFieldSharedProps {
	handleOpenModalDelete?: () => void;
	handleOpenModalUpdate: () => void;
	statusState: boolean;
	textDeleteBtn?: string;
	textSaveBtn: string;
	textTooltipSaveBtn: string;
	isOnlySave?: boolean;
}

export const SaveDeleteBntFieldShared: React.FC<
	ISaveDeleteBntFieldSharedProps
> = ({
	handleOpenModalDelete,
	handleOpenModalUpdate,
	statusState,
	textDeleteBtn,
	textSaveBtn,
	textTooltipSaveBtn,
	isOnlySave,
}) => {
	return (
		<StyledSaveDeleteBntFieldShared className="styledSaveDeleteBntFieldShared">
			<Box>
				{statusState && isOnlySave !== true && (
					<SharedMainButton
						isDeleteButton
						buttonFunction={
							handleOpenModalDelete !== undefined
								? handleOpenModalDelete
								: () => {}
						}
						text={textDeleteBtn !== undefined ? textDeleteBtn : ''}
					/>
				)}
				{statusState ? (
					<SharedMainButton
						isDeleteButton={false}
						buttonFunction={handleOpenModalUpdate}
						text={textSaveBtn}
						tooltip={textTooltipSaveBtn}
					/>
				) : (
					<NavLink to="/">
						<SharedMainButton
							isDeleteButton={false}
							buttonFunction={() => {}}
							text="Back"
						/>
					</NavLink>
				)}
			</Box>
		</StyledSaveDeleteBntFieldShared>
	);
};
