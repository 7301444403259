import styled from 'styled-components';

export const StyledSupportMenu = styled.div`
	display: flex;
	gap: 24px;
	width: 100%;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		width: 100%;
	}

	& .supportMenu-wrapper {
		& > div img {
			width: 120px;
			height: 120px;
		}

		& .supportMenu-subtitle {
			text-align: center;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 145%;
		}

		& .supportMenu-first-second-block,
		.supportMenu-third-block {
			display: flex;
			gap: 24px;

			& .container-about-wrapper {
				.supportMenu-block {
					padding: 1rem;
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 1rem;
					color: ${(props) => props.theme.currentTheme.mainFontColor};

					& .supportMenu-block-icon {
						display: flex;
						align-items: center;
						justify-content: center;
						align-self: flex-start;
						gap: 1rem;
						& > svg {
							width: 100px;
							height: 100px;
						}

						& .supportMenu-block-title {
							font-style: normal;
							font-weight: 400;
							font-size: 24px;
							line-height: 145%;
							max-width: 300px;
						}
					}

					& .supportMenu-block-content {
						display: flex;
						flex-direction: column;
						gap: 1rem;
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 140%;
					}
				}
			}
		}

		& .supportMenu-third-block {
			width: 100%;
			display: flex;
			justify-content: center;

			& .container-about-wrapper {
				.supportMenu-block {
					padding: 1rem;
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 2rem;
					color: ${(props) => props.theme.currentTheme.mainFontColor};

					& .supportMenu-block-icon {
						display: flex;
						align-self: center;
					}

					& .supportMenu-block-title-content {
						display: flex;
						flex-direction: column;
						gap: 1rem;

						& .supportMenu-block-title {
							font-style: normal;
							font-weight: 400;
							font-size: 24px;
							line-height: 145%;
							max-width: 300px;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .supportMenu-wrapper {
			& .supportMenu-subtitle {
				font-size: 16px;
			}

			& .supportMenu-first-second-block,
			.supportMenu-third-block {
				& .container-about-wrapper {
					padding: 1rem;
					.supportMenu-block {
						padding: 0.5rem;
						& .supportMenu-block-icon {
							& > svg {
								width: 100px;
								height: 100px;
							}

							& .supportMenu-block-title {
								font-size: 18px;
							}
						}

						& .supportMenu-block-content {
							font-size: 16px;
						}
					}
				}
			}

			& .supportMenu-third-block {
				& .container-about-wrapper {
					.supportMenu-block {
						& .supportMenu-block-title-content {
							& .supportMenu-block-title {
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 800px) {
		padding-top: 3rem !important;
	}

	@media screen and (max-width: 600px) {
		& > div {
			flex-direction: column;
		}
		flex-direction: column;
		gap: 1rem;
		padding-top: 3rem !important;
		& .supportMenu-wrapper {
			width: 100%;
			gap: 1rem;

			& .supportMenu-first-second-block {
				flex-direction: column;
			}

			& .supportMenu-third-block {
				& .container-about-wrapper {
					.supportMenu-block {
						flex-direction: column;
					}
				}
			}
		}
	}

	@media screen and (max-width: 350px) {
		padding-top: 5rem !important;

		& .supportMenu-wrapper {
			gap: 2rem;

			& > div img {
				width: 65px;
				height: 65px;
			}

			& .supportMenu-first-second-block,
			.supportMenu-third-block {
				& .container-about-wrapper {
					.supportMenu-block {
						& .supportMenu-block-icon {
							& > svg {
								width: 64px;
								height: 64px;
							}

							& .supportMenu-block-title {
								max-width: 150px;
							}
						}
					}
				}
			}
		}
	}
`;
