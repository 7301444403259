// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledToggleTheme = styled.div`
	position: relative;
	display: flex;
	width: 150px;
	height: 44px;
	justify-content: center;
	align-items: flex-start;
	padding: 2px;
	background: #0f1011;
	border-radius: 50px;
	z-index: 800;
	cursor: pointer;

	& .checkbox-dark {
		z-index: 801;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px 16px;
		gap: 8px;
		width: 72px;
		height: 40px;
		border-radius: 50px;
	}

	& .checkbox-light {
		z-index: 801;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px 16px;
		gap: 8px;
		width: 72px;
		height: 40px;
		border-radius: 50px;
	}

	& .checkbox-thumb {
		transition: 0.1s ease-in;
		position: absolute;
		left: ${(props) => props.theme.thumbPositionLeft};

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px 16px;
		gap: 8px;

		width: 72px;
		height: 40px;

		background: #1a1d21;
		border-radius: 50px;
		z-index: 800;
	}

	@media screen and (max-width: 1500px) {
		width: 110px;
		height: 36px;

		padding: 2px;
		border-radius: 50px;

		& .checkbox-dark {
			padding: 8px 16px;
			gap: 8px;
			width: 50px;
			height: 32px;
			border-radius: 50px;
		}

		& .checkbox-light {
			padding: 8px 16px;
			gap: 8px;
			width: 50px;
			height: 32px;
			border-radius: 50px;
		}

		& .checkbox-thumb {
			left: ${(props) => props.theme.thumbPositionLeftLittleScreen};
			padding: 8px;
			gap: 8px;
			width: 55px;
			height: 32px;
			border-radius: 50px;
			z-index: 800;
		}
	}
`;
