// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const isRegistrationSlice = createSlice({
	name: 'isRegistration',
	initialState,
	reducers: {
		setRegistrationStatus(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setRegistrationStatus } = isRegistrationSlice.actions;
export const isRegistrationReducer = isRegistrationSlice.reducer;
