import styled from 'styled-components';

export const StyledPlatformsMenu = styled.div`
	display: flex;
	gap: 24px;
	width: 100%;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		width: 100%;
	}

	& .platformsMenu-wrapper {
		display: flex;
		flex-direction: column;
		gap: 3rem;

		& .platformsMenu-high-block {
			display: flex;
			gap: 1rem;
			width: 100%;

			& .platformsMenu-title-subtitle {
				width: 50%;
				display: flex;
				flex-wrap: wrap;
				gap: 10;
				color: ${(props) => props.theme.currentTheme.mainFontColor};

				& .platformsMenu-title {
					font-style: normal;
					font-weight: 600;
					font-size: 36px;
					line-height: 48px;
				}

				& .platformsMenu-subtitle {
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 140%;
				}
			}

			& .platformsMenu-short-logo {
				width: 50%;
				flex-direction: column;
				gap: 10px;

				& > div {
					display: flex;
					gap: 10px;

					& .wrappedShortLogoItem {
						background: ${(props) =>
							props.theme.backgroundWrappedShortLogoItem};

						/* background: linear-gradient(142.23deg, #eaf6ff 1.04%, #bddffa 100%)
								padding-box,
							linear-gradient(to right, #bfd5ff, #dee9ff) border-box;
						border: 1px solid transparent; */
						border-radius: 10px;
						padding: 0px 32px;
						width: 33%;
						/* height: 100%; */
						height: 88px;
						max-height: 88px;

						& a {
							text-decoration: none;

							& > svg {
								cursor: pointer;
								height: 100%;
								width: 100%;
							}
						}
					}

					& .wrappedShortLogoItem.isSmall {
						display: flex;
						gap: 10px;
						background: none;
						padding: 0px;

						& a {
							text-decoration: none;
							display: flex;
							gap: 10px;

							& > div {
								background: ${(props) =>
									props.theme.backgroundWrappedShortLogoItem};

								/* background: linear-gradient(
											142.23deg,
											#eaf6ff 1.04%,
											#bddffa 100%
										)
										padding-box,
									linear-gradient(to right, #bfd5ff, #dee9ff) border-box;
								border: 1px solid transparent; */
								border-radius: 10px;
								width: 50%;
								padding: 16px;

								& > svg {
									cursor: pointer;
									height: 100%;
									width: 100%;
								}
							}
						}
					}
				}
			}

			& .platformsMenu-short-logo-mobile {
				width: 100%;
				flex-direction: row;
				gap: 10px;

				& > div {
					display: flex;
					flex-direction: column;
					width: 50%;
					gap: 10px;

					& .wrappedShortLogoItem {
						background: ${(props) =>
							props.theme.backgroundWrappedShortLogoItem};

						/* background: linear-gradient(142.23deg, #eaf6ff 1.04%, #bddffa 100%)
								padding-box,
							linear-gradient(to right, #bfd5ff, #dee9ff) border-box;
						border: 1px solid transparent;
						background: linear-gradient(142.23deg, #eaf6ff 1.04%, #bddffa 100%); */
						border-radius: 10px;
						padding: 8px 24px;
						height: 88px;
						max-height: 88px;
						width: 100%;

						& > svg {
							height: 100%;
							width: 100%;
						}
					}
				}
			}
		}

		& .platformsMenu-detailed-block {
			display: flex;
			flex-direction: row;
			gap: 1rem;
			& > div {
				display: flex;
				flex-direction: column;
				width: 50%;
				gap: 24px;

				& .wrappedDetailedLogoItem {
					display: flex;
					flex-direction: column;
					align-items: center;
					border-radius: 20px;
					background: linear-gradient(
								${(props) => props.theme.currentTheme.cardsBackground} 0 0
							)
							padding-box,
						linear-gradient(to right, #bfd5ff, #dee9ff) border-box;
					border: 1px solid transparent;

					& .wrappedDetailedLogoItem-high-block {
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 0px;
						gap: 8px;
						background: linear-gradient(142.23deg, #eaf6ff 1.04%, #bddffa 100%);
						border-radius: 19px 19px 0px 0px;
						width: 100%;
						overflow: hidden;

						& .wrappedDetailedLogoItem-high-block-logo {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							width: 50%;
							padding: 16px 24px;
							& > svg {
								height: 50px;
								width: 100%;
							}
						}

						& .wrappedDetailedLogoItem-high-block-name {
							display: flex;
							justify-content: center;
							align-items: center;
							background: ${(props) =>
								props.theme.currentTheme.cardsBackground};
							color: ${(props) => props.theme.currentTheme.mainFontColor};
							font-style: normal;
							font-weight: 400;
							font-size: 24px;
							line-height: 145%;
							height: 100%;
							width: 60%;
							padding: 16px 24px;
							border-radius: 30px 0px 0px 0px;
						}
					}

					& .wrappedDetailedLogoItem-text-block {
						height: 370px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 32px;
						color: ${(props) => props.theme.currentTheme.mainFontColor};

						& .wrappedDetailedLogoItem-text-title {
							font-style: normal;
							font-weight: 400;
							font-size: 24px;
							line-height: 145%;
						}

						& .wrappedDetailedLogoItem-text-content {
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 140%;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .platformsMenu-wrapper {
			& .platformsMenu-high-block {
				& .platformsMenu-title-subtitle {
					& .platformsMenu-title {
						font-size: 24px;
					}

					& .platformsMenu-subtitle {
						font-size: 16px;
					}
				}
			}

			& .platformsMenu-detailed-block {
				& > div {
					& .wrappedDetailedLogoItem {
						& .wrappedDetailedLogoItem-text-block {
							& .wrappedDetailedLogoItem-text-title {
								font-size: 20px;
							}

							& .wrappedDetailedLogoItem-text-content {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1300px) {
		& .platformsMenu-wrapper {
			& .platformsMenu-high-block {
				width: 100%;
				flex-direction: column;
				align-items: center;
				gap: 2rem;
				& .platformsMenu-title-subtitle {
					width: 80%;
				}
				& .platformsMenu-short-logo {
					width: 80%;
				}
			}
		}
	}

	@media screen and (max-width: 800px) {
		padding-top: 3rem !important;
		& .platformsMenu-wrapper {
			& .platformsMenu-high-block {
				& .platformsMenu-title-subtitle {
					width: 100%;
				}
				& .platformsMenu-short-logo {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 700px) {
		& > div {
			flex-direction: column;
		}
		flex-direction: column;
		gap: 1rem;
		padding-top: 3rem !important;
		& .platformsMenu-wrapper {
			& .platformsMenu-high-block {
				& .platformsMenu-short-logo {
					& > div {
						& .wrappedShortLogoItem {
							/* height: auto; */
						}
					}
				}
			}
			& .platformsMenu-detailed-block {
				flex-direction: column;
				& > div {
					width: 100%;

					& .wrappedDetailedLogoItem {
						& .wrappedDetailedLogoItem-high-block {
							& .wrappedDetailedLogoItem-high-block-name {
								font-size: 18px;
							}
						}

						& .wrappedDetailedLogoItem-text-block {
							padding: 16px;
							gap: 1rem;
							height: max-content;

							& .wrappedDetailedLogoItem-text-title {
								font-size: 18px;
							}

							& .wrappedDetailedLogoItem-text-content {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 600px) {
		& .wrappedShortLogoItem {
			display: flex;
			align-items: center;
			justify-content: center;

			& svg {
				width: 100%;
				height: 100%;
				max-height: 64px;
			}
			/* transform: scale(0.9); */
		}
	}

	@media screen and (max-width: 500px) {
		& .platformsMenu-detailed-block {
			/* transform: scale(0.9); */
		}
	}

	@media screen and (max-width: 400px) {
		& .platformsMenu-detailed-block {
			/* transform: scale(0.8); */
		}
	}

	@media screen and (max-width: 350px) {
		padding-top: 5rem !important;

		& .platformsMenu-wrapper {
			& .platformsMenu-high-block {
				& .platformsMenu-short-logo-mobile {
					flex-direction: column;
					align-items: center;
					& > div {
						width: 80%;
						& .wrappedShortLogoItem {
							height: 88px;
						}
					}
				}
			}
			& .platformsMenu-detailed-block {
				& .wrappedDetailedLogoItem {
					& .wrappedDetailedLogoItem-high-block {
						& .wrappedDetailedLogoItem-high-block-name {
							font-size: 15px;
						}
					}
				}
			}
		}
	}
`;
