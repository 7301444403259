import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import { StyledModalImportSnippets } from './ModalImportSnippets.style';
import { SharedTransparentButton } from '../../shared/SharedTransparentButton/SharedTransparentButton';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { ProgressDots } from '../../shared/ProgressDots/ProgressDots';
import { useImportSnippetsMutation } from '../../../app/api/chatbot.api';
import { ReactComponent as Close } from '../pic/close.svg';
import FormData from 'form-data';

interface IModalImportSnippetsProps {
	closeFn: () => void;
	chatbotId: string;
}

interface IImportSnippetsComponentProps {
	closeFn: () => void;
	chatbotId: string;
}

const ImportSnippetsComponent: React.FC<IImportSnippetsComponentProps> = ({
	closeFn,
	chatbotId,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const theme = { currentTheme };

	const userFileSnippetsUpload = useRef<HTMLInputElement | null>(null);
	const [currentFile, setCurrentFile] = useState<File | null>(null);
	const [currentFileFormData, setCurrentFileFormData] =
		useState<FormData | null>(null);

	const refetchFn = useAppSelector(
		(state: RootState) => state.refetchFnTrainingBotMenu.refetchFn
	);

	const saveUserFileSnippets = (): void => {
		if (userFileSnippetsUpload.current?.files != null) {
			const currentUserFileSnippets = userFileSnippetsUpload.current.files[0];

			if (currentUserFileSnippets !== undefined) {
				setCurrentFile(currentUserFileSnippets);
				const formSnippetsFormData = new FormData();

				formSnippetsFormData.append('file', currentUserFileSnippets);

				setCurrentFileFormData(formSnippetsFormData);
			}
		}
	};

	const handleRemoveFile = (): void => {
		setCurrentFile(null);
		setCurrentFileFormData(null);
	};

	const [importSnippets, resultOfImportSnippets] = useImportSnippetsMutation();

	const importBody = {
		token,
		chatbotId,
	};

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const handleImportSnippetsExecute = async (): Promise<void> => {
		if (currentFileFormData !== null && currentFile !== null) {
			try {
				const resultUploadingSnippets = await importSnippets({
					...importBody,
					importFileBody: currentFileFormData,
				});

				if ('data' in resultUploadingSnippets) {
					setLoadingErrorContent(
						<Box component="div" className="message-from-api">
							{resultUploadingSnippets.data.data}
						</Box>
					);
					await refetchFn();
				}
			} catch (error) {
				setLoadingErrorContent(
					<Box component="div" className="error-message-from-api">
						Something went wrong
					</Box>
				);
			}
		}
	};

	useEffect(() => {
		setLoadingErrorContent(null);
	}, []);

	useEffect(() => {
		if (resultOfImportSnippets.isLoading) {
			setLoadingErrorContent(<ProgressDots />);
		} else if (resultOfImportSnippets.isError) {
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{' '}
					Something went wrong{' '}
				</Box>
			);
		} else if (resultOfImportSnippets.isSuccess) {
			setLoadingErrorContent(null);
		}
	}, [resultOfImportSnippets]);

	useEffect(() => {
		if (resultOfImportSnippets.status === 'rejected') {
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{' '}
					Something went wrong{' '}
				</Box>
			);
		}
	}, [resultOfImportSnippets]);

	const handleCloseModal = (): void => {
		closeFn();
		setLoadingErrorContent(null);
	};

	return (
		<StyledModalImportSnippets theme={theme}>
			<Box component="div" className="importSnippetMenuComponent-wrapper">
				<Box component="div" className="importSnippetMenu-title-subtitle">
					<Box component="div" className="importSnippetMenu-title">
						Import your snippets
					</Box>
					<Box component="div" className="importSnippetMenu-subtitle">
						Choose file in format csv or xlsx
					</Box>
				</Box>
				<Box
					component="div"
					className="importSnippetMenu-type-name-uploading-content"
				>
					{currentFile !== null ? (
						<Box component="div" className="name-type-upload-file-wrapper">
							<Box component="div" className="name-type-upload-file">
								<Box>Name: &quot;{currentFile.name}&quot;</Box>
								<Box>Size: {(currentFile.size / 1024).toFixed(2)} KB</Box>
							</Box>
							<Box
								component="div"
								className="remove-upload-file"
								onClick={handleRemoveFile}
							>
								<Close className="close-icon" />
							</Box>
						</Box>
					) : (
						<></>
					)}
				</Box>
				<Box
					component="div"
					className="importSnippetMenu-answer-loading-content"
				>
					{loadingErrorContent}
				</Box>
				<Box component="div" className="importSnippetMenu-input-url">
					<Box component="div" className="importSnippetMenu-exe-buttons">
						<form id="userFileSnippetsForm">
							<label className="user-file-snippets-input-form-control">
								Choose file
								<input
									type="file"
									id="user-file-snippets-input"
									name="user-file-snippets-input"
									ref={userFileSnippetsUpload}
									onChange={saveUserFileSnippets}
									accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								/>
							</label>
						</form>
						<SharedMainButton
							isDeleteButton={false}
							text="Import snippets"
							buttonFunction={() => {
								void handleImportSnippetsExecute();
							}}
							isDisabled={currentFileFormData === null}
						/>
					</Box>
				</Box>
				<Box component="div" className="importSnippetMenu-button-field">
					<Box>
						<SharedTransparentButton
							text="Cancel"
							buttonFunction={handleCloseModal}
						/>
					</Box>
				</Box>
			</Box>
		</StyledModalImportSnippets>
	);
};

export const ModalImportSnippets: React.FC<IModalImportSnippetsProps> = ({
	closeFn,
	chatbotId,
}) => {
	return (
		<SharedModal
			closeFn={closeFn}
			executeFn={() => {}}
			message={null}
			title=""
			isOnlyAlert={false}
			standaloneFC={
				<ImportSnippetsComponent chatbotId={chatbotId} closeFn={closeFn} />
			}
		/>
	);
};
