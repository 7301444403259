import React from 'react';
import { Box } from '@mui/material';
import { type IGenericRow } from './SharedDesktopTable';

export interface IGridCardProps {
	row: IGenericRow;
	hasActionButton: boolean;
}

export const colorCircle = (condition: boolean): JSX.Element => {
	return (
		<Box
			component="div"
			className="colorCircle"
			sx={{
				borderRadius: '50%',
				width: '16px',
				height: '16px',
				background: condition ? '#63E597' : '#F3DC69',
			}}
		></Box>
	);
};

export const SharedGridCard: React.FC<IGridCardProps> = ({
	row,
	hasActionButton,
}) => {
	return (
		<Box component="div" className="grid-card-wrapper">
			{row.gridElementsRow.map((currentGridElement) => {
				return (
					<Box
						component="div"
						className={`grid-card-item grid-item ${
							currentGridElement.isOnlyBool === true ? 'bool-element' : ''
						}`}
						key={currentGridElement.valueGridElement.concat(
							Math.random().toString()
						)}
						sx={{ width: `${currentGridElement.widthElement} !important` }}
					>
						{currentGridElement.isOnlyBool === true ? (
							colorCircle(currentGridElement.boolValue === true)
						) : (
							<>
								<Box component="span">
									{currentGridElement.valueGridElement}
								</Box>
								<Box component="div">{currentGridElement.valueJSX}</Box>
							</>
						)}
					</Box>
				);
			})}
			{hasActionButton ? (
				<Box
					component="div"
					className="grid-card-item actions-grid-item actions-buttons"
				>
					{row.actionButtons.map((currentActionButton) => (
						<Box key={Math.random().toString()}>{currentActionButton}</Box>
					))}
				</Box>
			) : (
				<></>
			)}
		</Box>
	);
};
