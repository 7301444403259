import styled from 'styled-components';

export const StyledDialogWnd = styled.div`
	height: 400px;
	width: 100%;
	border-radius: 8px !important;
	background: ${(props) => props.theme.currentTheme.cardsBackground};
	border: 1px solid ${(props) => props.theme.currentTheme.borderInputs};
	color: ${(props) => props.theme.currentTheme.fontColorInput};
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	overflow-x: none;
	overflow-y: auto;

	&:hover {
		background: linear-gradient(
					${(props) => props.theme.currentTheme.cardsBackground} 0 0
				)
				padding-box,
			linear-gradient(to right, #43f2ff, #691eff) border-box;
		border: 1px solid transparent;
	}

	& .dialog-AQ-wrapper {
		padding: 0.5rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: 100% !important;
		position: relative;
		padding-left: 50px;
		padding-right: 0px;
		background: none;
		height: max-content;
		/* transition: all .3s ease-in-out; */
		/* animation: dialog-AQ-appear .3s ease-in-out; */

		& .add-remove-list-for-save {
			position: absolute;
			left: 8px;
			top: 10px;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			align-items: center;

			& > div {
				display: flex;
				gap: 0.5rem;
			}

			& .clear-btn {
				color: ${(props) => props.theme.currentTheme.mainFontColor};
				background: ${(props) => props.theme.currentTheme.cardsBackground};
				border-radius: 8px;
				opacity: 0.3;
				width: 100%;
				border: none;

				&:hover {
					opacity: 1;
					background: ${(props) =>
						props.theme.currentTheme.accentHighlightColor};
				}
			}

			& button {
				background: ${(props) => props.theme.currentTheme.vidgetCardBlue};
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: 24px;
				height: 24px;
				border: 2px solid
					${(props) => props.theme.currentTheme.accentHighlightColorForTable};

				& svg {
					width: 16px;
					height: 16px;
				}

				&:hover {
					background: ${(props) =>
						props.theme.currentTheme.accentHighlightColorForTable};
				}
			}

			/* & .remove-for-save {
				background: none;

				&:hover {
					background: none;
				}
			} */
		}

		& .dialog-aq {
			display: flex;
			height: max-content;
			/* position: relative; */

			& .update-input-edit {
				/* position: absolute; */
				top: 8px;
				right: 8px;
				width: max-content;
				padding-left: 0.5rem;
				color: ${(props) =>
					props.theme.currentTheme.vidgetCardBlueDisabledColor};
				display: flex;
				flex-direction: column;

				& svg {
					cursor: pointer;
					width: 21px;
					height: 21px;
				}
			}
		}

		& .prompt-element {
			justify-content: flex-end;
			height: max-content;
		}

		& .completion-element {
			justify-content: space-between;
			/* justify-content: flex-start; */
			height: max-content;
		}

		& textarea,
		.input-dialog-div-question,
		.input-dialog-div-answer {
			border-radius: 8px;
			border: none;
			outline: none;
			padding: 0.5rem;
			height: max-content !important;
			resize: none;
			height: max-content;
			min-height: 56px;
			border: 1px solid transparent;
		}

		& .prompt-element textarea,
		.input-dialog-div-question {
			max-width: 60%;
			background: ${(props) => props.theme.currentTheme.accentHighlightColor};
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			border-radius: 8px 8px 0px 8px;
			padding: 8px 16px;
		}

		& .prompt-element textarea {
			animation: background-change 4s ease-in-out infinite;
			/* &:hover,
			&:focus,
			&:active { */
			background: linear-gradient(
						${(props) => props.theme.currentTheme.accentHighlightColor} 0 0
					)
					padding-box,
				linear-gradient(to right, #43f2ff, #691eff) border-box;
			/* } */
		}

		& .completion-element textarea,
		.input-dialog-div-answer {
			max-width: 80%;
			background: #5e17eb;
			color: #fff;
			border-radius: 8px 8px 8px 0px;
		}

		& .completion-element textarea {
			animation: background-change 4s ease-in-out infinite;
			&:hover,
			&:focus,
			&:active {
				background: linear-gradient(#5e17eb 0 0) padding-box,
					linear-gradient(to right, #43f2ff, #691eff) border-box;
			}
		}
	}

	& .dialog-AQ-wrapper-disappear-state {
		animation: dialog-AQ-disappear 0.3s ease-in-out;
	}

	& .present-in-saving-list {
		background: ${(props) =>
			props.theme.currentTheme.accentHighlightColorForTable};

		& .present-in-saving-list-btn {
			& button {
				background: ${(props) => props.theme.currentTheme.cardsModalBackground};

				&:hover {
					background: ${(props) => props.theme.currentTheme.vidgetCardBlue};
				}
			}
		}

		& .update-input-edit {
			padding-right: 0.5rem;
		}
	}

	@keyframes background-change {
		0% {
			opacity: 1;
			background: ${(props) => props.theme.currentTheme.accentHighlightColor};
			outline: 1px solid #43f2ff;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
		}
		50% {
			opacity: 0.95;
			background: #5e17eb50;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
		}
		100% {
			opacity: 1;
			background: ${(props) => props.theme.currentTheme.accentHighlightColor};
			outline: 1px solid #43f2ff;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
		}
	}

	@keyframes dialog-AQ-disappear {
		0% {
			opacity: 1;
			transform: translateX(0px);
		}
		100% {
			opacity: 0;
			transform: translateX(-100px);
		}
	}

	@media screen and (max-width: 600px) {
		& .dialog-AQ-wrapper {
			& .prompt-element textarea {
				max-width: 75%;
			}

			& .completion-element textarea {
				max-width: 100%;
			}
		}
	}
`;
