// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const isAuthorizationSlice = createSlice({
	name: 'isAuthorization',
	initialState,
	reducers: {
		setAuthorizationStatus(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setAuthorizationStatus } = isAuthorizationSlice.actions;
export const isAuthorizationReducer = isAuthorizationSlice.reducer;
