import React from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { ProgressSpinner } from '../../shared/ProgressSpinner/ProgressSpinner';
import { type IGetProductsChatbotResponse } from '../../../app/api/interfaces/fondy/getProductsChatbot.interface';
import { MemoizingWrappedPricePlanCard } from '../WrappedPricePlanCard/WrappedPricePlanCard';
import { type IGetStripeProductsResponse } from '../../../app/api/payment.api/interfaces/getStripeProducts.interface';

interface IFondyRequestObject {
	isErrorGetProductsFondy: boolean;
	isLoadingGetProductsFondy: boolean;
	isSuccessGetProductsFondy: boolean;
	getFondyProductsData: IGetProductsChatbotResponse | undefined;
}

interface IStripeRequestObject {
	getStripeProductsData: IGetStripeProductsResponse[] | undefined;
	isErrorGetProducts: boolean;
	isLoadingGetProducts: boolean;
	isSuccessGetProducts: boolean;
}

interface ICurrentPlanInformation {
	isExistCurrentPlan: boolean;
	currentPlanName: string;
	currentPlanId: string;
}

interface ICardsPricePlanProps {
	fondyGetProductsFunctionObject: IFondyRequestObject;
	stripeGetProductsFunctionObject: IStripeRequestObject;
	currentPlanInformationObject: ICurrentPlanInformation;
	refetchFunctionAfterChoosePlan: () => Promise<void>;
	getFunctionForHistory: () => Promise<void>;
	openModalToCreateSubscription: (
		conditionModal: boolean,
		currentPlanName: string,
		currentPrice: string,
		priceId: string
	) => void;
}

export const CardsPricePlan: React.FC<ICardsPricePlanProps> = ({
	fondyGetProductsFunctionObject,
	stripeGetProductsFunctionObject,
	currentPlanInformationObject,
	refetchFunctionAfterChoosePlan,
	getFunctionForHistory,
	openModalToCreateSubscription,
}) => {
	const isCurrentFondy = useAppSelector(
		(state: RootState) => state.isCurrentFondy
	);

	const currentPeriod = useAppSelector(
		(state: RootState) => state.togglePeriodPriceMenu.currentPeriod
	);

	const {
		isErrorGetProductsFondy,
		isLoadingGetProductsFondy,
		isSuccessGetProductsFondy,
		getFondyProductsData,
	} = fondyGetProductsFunctionObject;

	const {
		getStripeProductsData,
		isErrorGetProducts,
		isLoadingGetProducts,
		isSuccessGetProducts,
	} = stripeGetProductsFunctionObject;

	const { isExistCurrentPlan, currentPlanId, currentPlanName } =
		currentPlanInformationObject;

	// IF FONDY
	if (isCurrentFondy) {
		if (isErrorGetProductsFondy) {
			return (
				<Box component="div" className="plan-menu-upgrade-available-plans">
					Something went wrong
				</Box>
			);
		} else if (isLoadingGetProductsFondy) {
			return <ProgressSpinner />;
		} else if (
			isSuccessGetProductsFondy &&
			getFondyProductsData !== undefined &&
			'data' in getFondyProductsData
		) {
			return (
				<Box component="div" className="plan-menu-upgrade-available-plans">
					{[...getFondyProductsData.data]
						.sort(
							(priceA, priceB) =>
								priceA.priceInCentsUSD - priceB.priceInCentsUSD
						)
						.filter((currentPricePlan) => {
							if (currentPeriod === 'Month') {
								return currentPricePlan.productValue === 2592000;
							} else {
								return currentPricePlan.productValue !== 2592000;
							}
						})
						.map((currentPricePlan, index) => {
							const benefitsArray = currentPricePlan.bulletPoints;

							const currentPriceByPeriod =
								currentPeriod === 'Month'
									? (currentPricePlan.priceInCentsUSD / 100).toString()
									: (currentPricePlan.priceInCentsUSD / 100).toString();

							const currentPriceIdByPeriod =
								currentPeriod === 'Month'
									? currentPricePlan.id.toString()
									: currentPricePlan.id.toString();

							return (
								<MemoizingWrappedPricePlanCard
									key={currentPricePlan.name}
									title={currentPricePlan.name}
									priceNumber={currentPriceByPeriod}
									benefitsList={benefitsArray}
									isPopularPlan={currentPricePlan.isBest}
									priceId={currentPriceIdByPeriod}
									id={currentPricePlan.id.toString()}
									isCurrentPlan={
										isExistCurrentPlan
											? currentPlanId === currentPricePlan.id.toString()
											: false
									}
									refetchFn={refetchFunctionAfterChoosePlan}
									refetchFnForHistory={getFunctionForHistory}
									openModalToCreateSubscription={openModalToCreateSubscription}
								/>
							);
						})}
				</Box>
			);
		} else {
			return null;
		}
		// ELSE IF STRIPE
	} else {
		if (isErrorGetProducts) {
			return (
				<Box component="div" className="plan-menu-upgrade-available-plans">
					Something went wrong
				</Box>
			);
		} else if (isLoadingGetProducts) {
			return <ProgressSpinner />;
		} else if (isSuccessGetProducts) {
			return (
				<Box component="div" className="plan-menu-upgrade-available-plans">
					{getStripeProductsData !== undefined &&
						[...getStripeProductsData]
							.sort(
								(priceA, priceB) =>
									priceA.default_price.unit_amount -
									priceB.default_price.unit_amount
							)
							.filter((currentPricePlan) => currentPricePlan.name !== 'Custom')
							.map((currentPricePlan, index) => {
								const benefitsArray =
									currentPricePlan.description !== null
										? currentPricePlan.description.split(',')
										: [];

								const yearPeriodArray = currentPricePlan.prices.filter(
									(pricePlan) => pricePlan.recurring.interval === 'year'
								)[0];
								const monthPeriodArray = currentPricePlan.prices.filter(
									(pricePlan) => pricePlan.recurring.interval === 'month'
								)[0];

								const currentPriceByPeriod =
									currentPeriod === 'Month'
										? Math.floor(monthPeriodArray.unit_amount / 100).toString()
										: Math.floor(yearPeriodArray.unit_amount / 100).toString();

								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								const currentPriceIdByPeriod =
									currentPeriod === 'Month'
										? monthPeriodArray.id
										: yearPeriodArray.id;

								return (
									<MemoizingWrappedPricePlanCard
										key={currentPricePlan.name}
										title={currentPricePlan.name}
										priceNumber={currentPriceByPeriod}
										benefitsList={benefitsArray}
										isPopularPlan={currentPricePlan.metadata.isBest === 'true'}
										priceId={currentPriceIdByPeriod}
										id={currentPricePlan.id}
										isCurrentPlan={
											isExistCurrentPlan
												? currentPlanName === currentPricePlan.name
												: false
										}
										refetchFn={refetchFunctionAfterChoosePlan}
										refetchFnForHistory={getFunctionForHistory}
										openModalToCreateSubscription={
											openModalToCreateSubscription
										}
									/>
								);
							})}
				</Box>
			);
		} else {
			return null;
		}
	}
};
