// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSharedTitleSubtitle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	word-break: break-word;
	hyphens: manual;

	& .shared-title,
	.shared-subtitle {
		width: 100%;
		text-align: center;
		color: #fff;
		margin-bottom: 0.7rem;
		width: ${(props) =>
			props.theme.width !== undefined ? props.theme.width : 'auto'};
	}

	& .shared-title {
		font-size: ${(props) =>
			props.theme.fontSize !== undefined ? props.theme.fontSize : '37px'};
		line-height: 48px;
	}

	& .shared-subtitle {
		font-size: 21px;
		line-height: 27px;
	}

	@media screen and (max-width: 550px) {
		& .shared-title {
			font-size: 2rem;
		}
	}

	@media screen and (max-width: 350px) {
		& .shared-title {
			font-size: 1.5rem;
			text-align: center;
		}
		& .shared-subtitle {
			font-size: 1rem;
		}
	}
`;
