// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialCurrentChatbotIdForHTMLCodeSlice {
	chatbotId: string;
}

export const initialState: IInitialCurrentChatbotIdForHTMLCodeSlice = {
	chatbotId: '',
};

const currentChatbotIdForHTMLCodeSlice = createSlice({
	name: 'currentChatbotIdForHTMLCode',
	initialState,
	reducers: {
		setChatbotIdForHTMLCode(state, action: PayloadAction<string>) {
			state.chatbotId = action.payload;
			return state;
		},
	},
});

export const { setChatbotIdForHTMLCode } =
	currentChatbotIdForHTMLCodeSlice.actions;
export const currentChatbotIdForHTMLCodeReducer =
	currentChatbotIdForHTMLCodeSlice.reducer;
