import styled from 'styled-components';

export const StyledChooseTemplateComponent = styled.div`
	/* width: 1000px; */
	display: flex;
	flex-direction: column;
	gap: 2rem;

	& .choose-template-title {
		color: ${(props) => props.theme.currentTheme.mainFontColor};
		text-align: center;
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		line-height: 48px;
	}

	& .choose-template-wrapper {
		display: flex;
		justify-content: space-between;
		gap: 34px;

		& .active-template {
			border: 2px solid #1592ec !important;
		}
	}

	& .add-button {
		display: flex;
		gap: 1rem;
		width: 100%;
	}

	@media screen and (max-width: 1450px) {
		& .choose-template-title {
			font-size: 24px;
		}

		& .choose-template-wrapper {
			gap: 24px;
		}
	}

	@media screen and (max-width: 600px) {
		& .choose-template-wrapper {
			flex-direction: column;
			align-items: center;
		}
	}
`;
