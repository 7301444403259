import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setOpenModalTestBotStatus } from '../../../app/slices/trainingBotMenu/openModal/openTestBotTrainingBotMenuSlice';
import { setCurrentChatbotIdForTest } from '../../../app/slices/trainingBotMenu/openModal/currentValues/currentChatbotIdForActionsTrainingBotMenuSlice';
import { CustomTooltip } from '../../shared/CustomTooltip/CustomTooltip';
import { setStartState } from '../../../app/slices/testChatbotModal/snippetsList.slice';

interface ITestBotActionBtnProps {
	chatbotId: number;
}

export const TestBotActionBtn: React.FC<ITestBotActionBtnProps> = ({
	chatbotId,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const dispatch = useAppDispatch();

	const testButtonFRef = useRef<HTMLDivElement>();

	const openModalVectorise = (): void => {
		dispatch(setOpenModalTestBotStatus(true));
		dispatch(setCurrentChatbotIdForTest(chatbotId.toString()));
		dispatch(setStartState(chatbotId.toString()));
	};

	const theme = { currentTheme };

	return (
		<>
			<CustomTooltip
				theme={theme}
				className={chatbotId.toString()}
				title="You can test the work of the chatbot you created, change and save the snippet you like."
			>
				<Box>
					<Box
						component="button"
						className="test-bot-action inline-action-button"
						onClick={openModalVectorise}
						ref={testButtonFRef}
					>
						Test
					</Box>
				</Box>
			</CustomTooltip>
		</>
	);
};
