import React from 'react';
import { LayoutAccount } from '../../components/shared/LayoutAccount/LayoutAccount';
import { SupportMenu } from '../../components/AboutMenu/SupportMenu/SupportMenu';

export const SupportPage: React.FC = () => {
	return (
		<LayoutAccount title="Coderfy Chatbot Generator">
			<SupportMenu />
		</LayoutAccount>
	);
};
