/* eslint-disable @typescript-eslint/prefer-includes */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledSharedPreviewWrapperImg } from './SharedPreviewWrapperImg.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';

interface ISharedPreviewWrapperImgProps {
	link: string;
}

export const SharedPreviewWrapperImg: React.FC<
	ISharedPreviewWrapperImgProps
> = ({ link }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const [appearStyle, setAppearStyle] = useState('');
	useEffect(() => {
		setAppearStyle('appear-PreviewWrapperImg');
		setTimeout(() => {
			setAppearStyle('');
		}, 800);
	}, [link]);

	const theme = {
		currentTheme,
		paddingLeft: link.indexOf('modern') !== -1 ? '38px' : '24px',
	};

	return (
		<StyledSharedPreviewWrapperImg
			className={`currentStyle-wrapper`}
			theme={theme}
		>
			<Box
				component="img"
				className={`currentStyle ${appearStyle}`}
				src={link}
				alt="chatbot-style"
			></Box>
		</StyledSharedPreviewWrapperImg>
	);
};
