import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const openDeleteCurrentSnippetTrainingBotMenuSlice = createSlice({
	name: 'openDeleteCurrentSnippetTrainingBotMenu',
	initialState: false,
	reducers: {
		setOpenModalDeleteCurrentSnippetStatus(
			state,
			action: PayloadAction<boolean>
		) {
			state = action.payload;
			return state;
		},
	},
});

export const { setOpenModalDeleteCurrentSnippetStatus } =
	openDeleteCurrentSnippetTrainingBotMenuSlice.actions;
export const openDeleteCurrentSnippetTrainingBotMenuReducer =
	openDeleteCurrentSnippetTrainingBotMenuSlice.reducer;
