/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialFocusTextareaId {
	focusTextareaId: number;
	isPrompt: boolean;
}

const initialState: IInitialFocusTextareaId = {
	focusTextareaId: 0,
	isPrompt: false,
};

const focusTextareaSlice = createSlice({
	name: 'focusTextarea',
	initialState,
	reducers: {
		setFocusTextareaId(state, action: PayloadAction<number>) {
			state.focusTextareaId = action.payload;
			return state;
		},
		setFocusTextareaType(state, action: PayloadAction<boolean>) {
			state.isPrompt = action.payload;
			return state;
		},
	},
});

export const { setFocusTextareaId, setFocusTextareaType } =
	focusTextareaSlice.actions;
export const focusTextareaReducer = focusTextareaSlice.reducer;
