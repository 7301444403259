// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialValidSearchBarHistoryMenuState {
	isValidSearchBarInputHistoryMenu: boolean;
}

const initialState: IInitialValidSearchBarHistoryMenuState = {
	isValidSearchBarInputHistoryMenu: false,
};

const isValidSearchBarHistoryMenuInputsSlice = createSlice({
	name: 'isValidSearchBarHistoryMenuInputsSlice',
	initialState,
	reducers: {
		setValidStatusSearchBarInputHistoryMenu(
			state,
			action: PayloadAction<boolean>
		) {
			state.isValidSearchBarInputHistoryMenu = action.payload;
			return state;
		},
	},
});

export const { setValidStatusSearchBarInputHistoryMenu } =
	isValidSearchBarHistoryMenuInputsSlice.actions;
export const isValidSearchBarHistoryMenuInputsReducer =
	isValidSearchBarHistoryMenuInputsSlice.reducer;
