import styled from 'styled-components';

export const StyledWhatsAppGetToken = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	display: flex;
	gap: 16px;
	padding: 48px 0px 0px 0px;

	& .messageExample-arrow {
		display: block;

		& img {
			width: 150px;
		}
	}

	& .messageExample-msg-box {
		display: flex;
		flex-direction: column;
		align-items: center;

		& img {
			border-radius: 8px;
			box-shadow: ${(props) => props.theme.currentTheme.boxShadow};
			width: 500px;
			height: auto;
		}

		& .step-Element-img-first {
			margin-top: 32px;
			width: 250px;
		}
	}

	@media screen and (max-width: 1500px) {
		padding: 0px;
		top: 48px;
		left: -16px;

		& .messageExample-arrow {
			display: none;
		}
	}

	@media screen and (max-width: 1250px) {
		flex-direction: column;
		position: relative;
		top: 10px;

		& .messageExample-arrow {
			display: none;
		}

		& .messageExample-msg-box {
			& .step-Element-img-first {
				margin-top: 0px;
			}
		}
	}

	@media screen and (max-width: 800px) {
		position: relative;
		top: 8px;
		left: 0px;

		& .messageExample-msg-box {
			& img {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 500px) {
		left: -10px;

		& .messageExample-msg-box {
			& img {
				width: 110%;
			}
		}
	}
`;
