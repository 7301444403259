// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledCellInputRow = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: center;
	width: 100%;
	& input {
		display: flex;
		justify-content: center;
		text-align: center;
		align-items: center;
		font-weight: 800;
		font-size: 20px;
		caret-color: transparent;
		background: #1a1d21;
		width: 50px;
		height: 70px;
		border: 1px solid #5e17eb;
		border-radius: 16px;
		padding: 10px;
		color: #d7e2f2;
		font-style: normal;
		font-weight: 300;
		font-size: 40px;
		line-height: 100%;

		&:focus {
			outline: 4px solid #5e17eb;
		}
	}

	@media screen and (max-width: 600px) {
		gap: 0.8rem;

		& input {
			border-radius: 10px;
			font-size: 32px;
			height: 60px;
			width: 40px;
		}
	}

	@media screen and (max-width: 350px) {
		gap: 0.5rem;

		& input {
			font-size: 24px;
			height: 45px;
			width: 35px;
		}
	}
`;
