import React from 'react';
import { Box } from '@mui/material';
import { StyledStepElement } from './StepElement.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import accentPoint from '../../pic/accentPoint.svg';

export interface IStepListElement {
	stepListElement: string | JSX.Element;
	indentation?: number;
	withoutPointDot?: boolean;
}

export interface IStepElementProps {
	index: number;
	header: string | JSX.Element;
	stepList: IStepListElement[];
}

export const StepElement: React.FC<IStepElementProps> = ({
	header,
	stepList,
	index,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	return (
		<StyledStepElement className="stepElement-wrapper" theme={theme}>
			<Box component="div" className="stepElement-header-field">
				<Box component="div" className="stepElement-header-number">
					{index}
				</Box>
				<Box component="div" className="stepElement-header">
					{header}
				</Box>
			</Box>
			{stepList.length > 0 && (
				<Box component="div" className="stepElement-list-field">
					{stepList.map((stepListElement, index) => {
						return (
							<Box
								component="div"
								key={index}
								className="stepListElement-wrapper"
								sx={{
									pb:
										stepListElement.indentation !== undefined
											? stepListElement.indentation / 8
											: 0,
								}}
							>
								{stepListElement.withoutPointDot === true ? (
									<></>
								) : (
									<Box
										component="img"
										src={accentPoint}
										alt="point-pic"
										className="stepElement-point-element"
									/>
								)}
								{/* <Box component='div' className='stepElement-point-element'>&nbsp;&nbsp;&nbsp;</Box> */}
								{typeof stepListElement.stepListElement === 'string' ? (
									stepListElement.stepListElement
								) : (
									<Box component="div" className="stepElement-string-element">
										{stepListElement.stepListElement}
									</Box>
								)}
							</Box>
						);
					})}
				</Box>
			)}
		</StyledStepElement>
	);
};
