import React, { useEffect, useState } from 'react';
import { getItem } from '../../../../app/utils/storageUtils';
import { WebhookToCopy } from '../WebhookToCopy/WebhookToCopy';
import { getWebhookLink } from '../../utils/getWebhookLink';

interface IWebhookInnerComponentProps {
	localStorageKey: string;
	creationBotStatus: boolean;
	creationBotId: number;
	webhookLinkKey: string;
}

export const WebhookInnerComponent: React.FC<IWebhookInnerComponentProps> = ({
	localStorageKey,
	creationBotStatus,
	creationBotId,
	webhookLinkKey,
}) => {
	const webhookLinkFromStorage = getItem(localStorageKey);
	const [webhookLink, setWebhookLink] = useState('');

	useEffect(() => {
		if (webhookLinkFromStorage !== null) {
			setWebhookLink(webhookLinkFromStorage);
		}
	}, []);

	if (webhookLink.length > 0) {
		return <WebhookToCopy link={webhookLink} />;
	} else if (!creationBotStatus) {
		return (
			<WebhookToCopy link="After creating the messenger bot, there will be a link to the webhook here" />
		);
	} else {
		return (
			<WebhookToCopy
				link={getWebhookLink(webhookLinkKey, creationBotId.toString())}
			/>
		);
	}
};
