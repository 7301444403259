import React from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../../../../app/hooks';
import { type RootState } from '../../../../../../app/store';
import { StyledTokenAlertMessage } from './TokenAlertMessage.style';
import attention from '../../../../pic/attention.svg';

export const TokenAlertMessage: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	return (
		<StyledTokenAlertMessage theme={theme} className="tokenAlert-wrapper">
			<Box component="div" className="tokenAlert-msg-box">
				<Box
					component="img"
					className="tokenAlert-pic"
					src={attention}
					alt="attention-pic"
				/>
				<Box component="span" className="tokenAlert-msg-article">
					Keep your token secure and store it safely, it can be used by anyone
					to control your bot.
				</Box>
			</Box>
		</StyledTokenAlertMessage>
	);
};
