export const firstWelcomeArticle = [
	`Welcome to Coderfy Chatbot generator - the easiest and most efficient way to create a custom chatbot for your website. With Coderfy Chatbot generator, you can create training data for specific businesses or themes with their domains and train custom chatbots on this data. You can get a simple HTML code to add to your website and start using your chatbot in minutes!`,
	`Our platform offers a 7-day free trial to test it before committing to a monthly payment plan, starting from just 49 Euros. If you're looking for an advanced trained model of a chatbot, we offer this option for a small one-time fee of 198 Euros.`,
	`Our team is always working hard to bring you the best features, and we're excited to announce our upcoming options for training chatbots in the app dashboard, the history of chats, and the ability to design and customize the style of your bot.`,
	`At Coderfy Chatbot Generator, we understand that creating and integrating a chatbot can be confusing and overwhelming. That's why we've made it our mission to provide an intuitive and user-friendly platform that anyone can use, regardless of technical expertise.`,
	`Our dedicated customer support team can always assist you if you have questions or concerns about using our chatbot generator. Whether a small business owner or a large corporation, Coderfy Chatbot generator can help you increase engagement and streamline customer communication on your website. Try it out today and see the difference it can make!`,
];

// FAQ section for Coderfy Chatbot generator:
export const secondFAQArticle = {
	title: 'Frequently Asked Questions:',
	content: [
		'- We understand you may have questions about Coderfy Chatbot Generator and its work.',
		'- Here are some frequently asked questions and detailed answers to help address any concerns or queries.',
	],
};

export const articlesQA = [
	{
		q: 'Q: What is Coderfy Chatbot generator?',
		a: 'A: Coderfy Chatbot generator is a platform that enables you to create a custom chatbot for your website. It allows you to create training data for specific businesses or themes with their domains and train custom chatbots on this data.',
		content: [],
	},
	{
		q: 'Q: How does Coderfy Chatbot generator work?',
		a: 'A: With Coderfy Chatbot generator, you can easily create a custom chatbot by following these simple steps:',
		content: [
			'1. Enter the domain of your website.',
			'2. Add the necessary training data for your chatbot.',
			'3. Train your custom chatbot on this data.',
			'4. Get a simple HTML code to add to your website and start using your chatbot.',
		],
	},
	{
		q: 'Q: What are the current features of Coderfy Chatbot generator?',
		a: 'A: The current features of the Coderfy Chatbot generator include:',
		content: [
			'- Creating training data about some specific business or theme with its domain',
			'- Training custom chatbot for this website on this data',
			'- Getting a simple HTML code to add this chatbot to the website',
			'- Trying and testing it for 7 days for free',
			'- Possibility to get an advanced trained model of chatbot for a small one-time fee 198 Euros',
			'- The monthly payment for having a chatbot on the website from 49 Euros',
		],
	},
	{
		q: 'Q: What are the following features of the Coderfy Chatbot generator?',
		a: 'A: The upcoming features of the Coderfy Chatbot generator include:',
		content: [
			'- Options for training chatbot in the dashboard of the app',
			'- History of chats',
			'- Design and customization of bots style',
		],
	},
	{
		q: 'Q: Can I try the Coderfy Chatbot generator for free?',
		a: 'A: Yes! We offer a 7-day free trial to test it out before committing to a monthly payment plan.',
		content: [],
	},
	{
		q: 'Q: Is Coderfy Chatbot generator easy to use?',
		a: 'A: Yes! Our mission is to provide an intuitive and user-friendly platform that anyone can use, regardless of technical expertise.',
		content: [],
	},
	{
		q: 'Q: How much does Coderfy Chatbot generator cost?',
		a: "A: Our monthly payment plan starts from just 49 Euros. If you're looking for an advanced trained model of a chatbot, we offer this option for a small one-time fee of 198 Euros.",
		content: [],
	},
	{
		q: 'Q: Can I customize the design of my chatbot?',
		a: "A: Yes! With our upcoming feature, you'll be able to design and customize the style of your bot.",
		content: [],
	},
	{
		q: 'Q: Can I get support if I have any questions?',
		a: 'A: Yes! Our dedicated customer support team can always assist you with any questions or concerns about using our chatbot generator.',
		content: [],
	},
];

export const registrationArticleTitle =
	'About registration to the Coderfy Chatbot generator';

export const thirdRegistrationArticle = [
	'To use Coderfy Chatbot generator, you must register for an account. The registration process is quick and easy. You only need a valid email address, website domain, and password.',
	'Once registered, you can access our platform and start creating your custom chatbot immediately. Our intuitive interface and easy-to-use tools make it simple to create a custom chatbot in just a few steps.',
	'Be sure to start engaging your website visitors with a custom chatbot! Sign up for the Coderfy Chatbot generator today and see the difference it can make for your business.',
];

export const nichesArticleTitle =
	'10 Niches Where Coderfy Chatbot Generator Can Be Useful:';

export const fourthNichesArticle = [
	{
		accent: 'Fintech',
		content:
			' improve customer experience with instant answers to financial questions and support for online transactions.',
	},
	{
		accent: 'Real Estate',
		content:
			' assist visitors with property searches, provide property information, and schedule showings.',
	},
	{
		accent: 'Healthcare',
		content:
			' provide immediate medical information and assistance, schedule appointments, and answer general healthcare questions.',
	},
	{
		accent: 'E-commerce',
		content:
			'provide customer service and support for online purchases, recommend products, and update order status.',
	},
	{
		accent: 'Travel',
		content:
			' provide information about destinations, flights, and accommodations, and assist with bookings and itineraries.',
	},
	{
		accent: 'Education',
		content:
			'provide instant access to course information, answer program questions, and assist with enrollment.',
	},
	{
		accent: 'Legal',
		content:
			'assist with legal inquiries, provide access to legal documents and forms, and schedule consultations.',
	},
	{
		accent: 'Entertainment',
		content:
			' recommend movies, TV shows, and music, and answer general entertainment questions.',
	},
	{
		accent: 'Non-profit',
		content:
			' provide information about the organization and its mission, and assist with donations and volunteer opportunities.',
	},
	{
		accent: 'Small Business',
		content:
			'provide customer support, schedule appointments, and provide information about products and services.',
	},
];

export const platformsArticleTitle =
	'Website CMS and Platforms Supported by Coderfy Chatbot Generator:';

export const fifthPlatformsArticle = [
	'- WordPress',
	'- Shopify',
	'- Squarespace',
	'- Wix',
	'- Magento',
	'- Drupal',
	'- Joomla',
	'- Weebly',
	'- BigCommerce',
	'- PrestaShop',
];

export const sixthPlatformsArticle = [
	'Our chatbot generator is designed to integrate seamlessly with your website, regardless of the CMS or platform you use. With Coderfy Chatbot generator, you can improve customer engagement and streamline communication on your website, regardless of your industry or website platform.',
	'Certainly! Adding the HTML code generated by the Coderfy Chatbot generator to your website is a straightforward process. Here are the steps you can follow based on the CMS or platform you are using:',
];

export const implementationToPlatforms = [
	{
		title: 'WordPress',
		content: [
			'1. Log in to your WordPress dashboard.',
			'2. Go to Appearance > Widgets.',
			'3. Find the widget area where you want to add the chatbot and click on "Add Widget"',
			'4. Select the "Custom HTML" widget.',
			'5. Paste the HTML code generated by the Coderfy Chatbot generator into the text area of the widget.',
			'6. Save the widget and preview your website to see the chatbot in action.',
		],
	},
	{
		title: 'Shopify',
		content: [
			'1. Log in to your Shopify account.',
			'2. Go to Online Store > Themes.',
			'3. Click on "Actions" and select "Edit code".',
			'4. Find the theme.liquid file and click on it to open it.',
			'5. Locate the </head> tag and paste the HTML code generated by Coderfy Chatbot generator just before it.',
			'6. Save the file and preview your website to see the chatbot in action.',
		],
	},
	{
		title: 'Wix',
		content: [
			'1. Log in to your Wix account.',
			'2. Go to the page where you want to add the chatbot.',
			'3. Click on "Add" and select "More".',
			'4. Click on "HTML iframe".',
			'5. Paste the HTML code generated by Coderfy Chatbot generator into the "Code" section.',
			'6. Save the changes and preview your website to see the chatbot in action.',
		],
	},
	{
		title: 'Squarespace',
		content: [
			'1. Log in to your Squarespace account.',
			'2. Go to Pages > Edit your page.',
			'3. Click on the "Add Block" button and select "Code".',
			'4. Paste the HTML code generated by Coderfy Chatbot generator into the text area of the block.',
			'5. Save the block and preview your website to see the chatbot in action.',
		],
	},
	{
		title: 'Magento',
		content: [
			'1. Log in to your Magento account.',
			'2. Go to the Content > Elements > Pages section.',
			'3. Click on the page where you want to add the chatbot.',
			'4. Click on "Show/Hide Editor" to access the HTML code.',
			'5. Paste the HTML code generated by the Coderfy Chatbot generator into the page`s text area.',
			'6. Save the changes and preview your website to see the chatbot in action.',
		],
	},
	{
		title: 'Drupal',
		content: [
			'1. Log in to your Drupal account.',
			'2. Go to Structure > Block layout.',
			'3. Select the region where you want to add the chatbot.',
			'4. Click on "Add block" and select "Custom block".',
			'5. Paste the HTML code generated by Coderfy Chatbot generator into the "Block body" section.',
			'6. Save the block and preview your website to see the chatbot in action.',
		],
	},
	{
		title: 'Joomla',
		content: [
			'1. Log in to your Joomla account.',
			'2. Go to Extensions > Modules.',
			'3. Click on "New" and select "Custom HTML".',
			'4. Paste the HTML code generated by Coderfy Chatbot generator into the "Custom Output" section.',
			'5. Save the module and preview your website to see the chatbot in action.',
		],
	},
	{
		title: 'Weebly',
		content: [
			'1. Log in to your Weebly account.',
			'2. Go to the page where you want to add the chatbot.',
			'3. Click on "Embed Code" and select "Custom HTML".',
			'4. Paste the HTML code generated by Coderfy Chatbot generator into the text area.',
			'5. Save the changes and preview your website to see the chatbot in action.',
		],
	},
	{
		title: 'BigCommerce',
		content: [
			'1. Log in to your BigCommerce account.',
			'2. Go to Storefront > Script Manager.',
			'3. Click on "Create a Script".',
			'4. Enter a name for the script and select "Footer".',
			'5. Paste the HTML',
		],
	},
	{
		title: 'PrestaShop',
		content: [
			'1. Log in to your PrestaShop Admin Panel using your credentials.',
			'2. Navigate to the Theme Configuration: Go to "Design" in the left-hand menu and select "Theme & Logo." > "Advanced Settings" tab.',
			'3. Look for the "Header" section where you want to add the chatbot (This could be in the header.tpl file or a different template file, depending on your theme).',
			'4. Click the "Edit" or "Modify" button to access the template editor for the selected header file.',
			'5. Insert the HTML code for the ChatGPT Chatbot just before the closing </head> tag. Save and Apply Changes',
		],
	},
];

export const assistanceQuestions = [
	'If you need assistance or have questions about our platform, we offer multiple support options, including email, phone, and live chat. Our friendly and knowledgeable support team can assist you with any issues or concerns.',
];

export const seventhLastArticle = [
	{
		title: 'Security and Privacy',
		content: [
			'We understand that your data and privacy are of utmost importance.',
			'Coderfy Chatbot Generator utilizes the latest security protocols and measures to ensure your data is safe and secure.',
			'We are committed to maintaining the privacy of our users and comply with all relevant regulations and laws.',
		],
	},
	{
		title: 'Success Stories of using the Chatbot created by our Generator:',
		content: [
			'Many businesses have already experienced the benefits of using Coderfy Chatbot Generator to improve their customer engagement and support.',
		],
	},
	{
		title:
			'Here are a few examples of how chatbots created with our platform have helped businesses in various industries:',
		content: [
			'A fintech startup saw a 25% increase in customer engagement and a 20% increase in conversions after implementing a chatbot on their website.',
			'A real estate agent could schedule 30% more property showings and reduce response times by 50% with a chatbot.',
			'An e-commerce store saw a 15% increase in sales and a 10% decrease in support requests after implementing a chatbot to assist with customer service.',
		],
	},
];

export const allTextContent = {
	firstWelcomeArticle,
	secondFAQArticle,
	articlesQA,
	registrationArticleTitle,
	thirdRegistrationArticle,
	nichesArticleTitle,
	fourthNichesArticle,
	platformsArticleTitle,
	fifthPlatformsArticle,
	sixthPlatformsArticle,
	implementationToPlatforms,
	assistanceQuestions,
	seventhLastArticle,
};
