import styled from 'styled-components';

export const StyledInputSendToken = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;

	& .MuiFormControl-root.MuiTextField-root {
		height: 100%;
	}
	& .MuiAutocomplete-root.MuiOutlinedInput-root {
		height: 100%;
	}

	& .MuiAutocomplete-root {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
		height: 68px;
		width: 470px;
		background: ${(props) => props.theme.currentTheme.cardsBackground};
		border-radius: 8px !important;
		border: 1px solid ${(props) => props.theme.currentTheme.borderInputs};
		outline: none;
		font-size: 18px;

		&:hover {
			background: linear-gradient(
						${(props) => props.theme.currentTheme.cardsBackground} 0 0
					)
					padding-box,
				linear-gradient(to right, #43f2ff, #691eff) border-box;
			border: 1px solid transparent;
		}

		& .MuiOutlinedInput-root {
			color: ${(props) => props.theme.currentTheme.fontColorInput};
			font-family: 'Nunito';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 100%;
			border: none;
			outline: none;
			border-radius: 20px;
			height: 100%;
			width: 100%;
			padding: 0px;

			& .MuiAutocomplete-input {
				padding: 0px;
				padding-left: 15px;
			}

			& .MuiAutocomplete-endAdornment {
				right: 5%;
			}
		}

		& .MuiOutlinedInput-input {
			font-family: 'Nunito';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 100%;

			letter-spacing: -0.05em;
			opacity: 1;
			padding: 0px;
		}

		& fieldset {
			border: none;
		}

		& .Mui-focused {
			border-radius: 8px;
			border: 1px solid #1592ec;
			transform: scale(1.008);
		}

		& input::placeholder {
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 100%;
			letter-spacing: -0.01em;
			opacity: 1;
			padding: 0px;
		}

		& .MuiSvgIcon-root {
			fill: rgb(94, 23, 235);
		}

		& .MuiPopper-root .MuiAutocomplete-popper {
			background: ${(props) =>
				props.theme.currentTheme.cardsBackground} !important;
		}
	}

	& .MuiPopper-root .MuiAutocomplete-popper {
		background: ${(props) =>
			props.theme.currentTheme.cardsBackground} !important;
	}

	& .MuiAutocomplete-popper {
		background: ${(props) =>
			props.theme.currentTheme.cardsBackground} !important;

		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 100%;
		letter-spacing: -0.01em;
	}

	@media screen and (max-width: 1500px) {
		& .MuiAutocomplete-root {
			height: 50px;

			& .MuiOutlinedInput-input {
				font-size: 14px;
			}

			& input::placeholder {
				font-size: 14px;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		width: 70vmin;
		max-width: 550px;

		& .MuiAutocomplete-root {
			width: 100%;
		}
	}

	@media screen and (max-width: 500px) {
		& .shared-input {
			width: 100% !important;
		}
		& input::placeholder {
			font-size: 14px !important;
		}
	}
`;
