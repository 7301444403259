import React, { useState } from 'react';
import { Box } from '@mui/material';
import { WrappedInput } from '../../shared/Input/WrappedInput/WrappedInput';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { WrapperForArticleInput } from './WrapperForArticleInput';
import { SharedChangeIconComponent } from '../../shared/SharedChangeIconComponent/SharedChangeIconComponent';
import { StyledAddStylizationInputsField } from './StyledAddStylizationInputsField.style';

export const temporaryParams = [
	'Sales Executive',
	'The CEO',
	'Empathy',
	'Funny',
	'An Expert',
	'One liner',
	'Two liner',
	'Multilingual',
	'Wall of text',
	'Poet',
	'Donald Trump',
	'Joe Biden',
	'Barack Obama',
	'Thomas Jefferson',
	'Snoop Dog',
	'Bob Marley',
	'Buddha',
	'Jesus',
	'Pirate',
	'Alien from Zorg',
	'Reddit Troll',
];

export const temporaryParamsStyle = [
	// 'Coderfy',
	// 'Custom',
	'Chess',
	'Default',
	'Frank',
	'Morphed-bubbles',
	'Nuomorphism',
	'Office',
];

interface IAddStylizationInputsFieldProps {
	isIconTemplate: boolean;
	stylesCssParams: string[];
}

export const AddStylizationInputsField: React.FC<
	IAddStylizationInputsFieldProps
> = ({ isIconTemplate, stylesCssParams }) => {
	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForChatGenerator
	);
	const { secondStep } = isButtonPressed;
	const validInputsStatus = useAppSelector(
		(state: RootState) => state.isValidChatGeneratorInputs
	);
	const {
		isValidToneGeneratorMenu,
		isValidStyleGeneratorMenu,
		isValidButtonTextGeneratorMenu,
		isValidHeaderGeneratorMenu,
	} = validInputsStatus;

	const valueInputs = useAppSelector(
		(state: RootState) => state.chatGeneratorInputsValue
	);
	const { header, buttonText, tone, style } = valueInputs;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [currentFile, setCurrentFile] = useState<File>();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const iconChatbotChoosingForCreationChatbot = useAppSelector(
		(state: RootState) =>
			state.iconChatbot.iconChatbotChoosingForCreationChatbot
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const inputsList = [
		{
			article: 'Enter a title for your bot',
			node: (
				<Box component="div" className="wrapped-header-with-upload-icon">
					<WrappedInput
						type="text"
						placeholder="Header of chat window"
						isEmpty={secondStep}
						uniqueNameOfInput="header-generator-menu"
						isValid={isValidHeaderGeneratorMenu}
						value={header}
					/>
					{isIconTemplate ? (
						<SharedChangeIconComponent
							isChatbotIcon
							setCurrentFile={setCurrentFile}
							iconUrl=""
							isCreationChatbot
						/>
					) : (
						<></>
					)}
				</Box>
			),
		},
		{
			article: 'Enter button text',
			node: (
				<WrappedInput
					type="text"
					placeholder="Button text"
					isEmpty={secondStep}
					uniqueNameOfInput="button-text-generator-menu"
					isValid={isValidButtonTextGeneratorMenu}
					value={buttonText}
				/>
			),
		},
		{
			article: 'Choose a style',
			node: (
				<WrappedInput
					type="autocomplete"
					placeholder="Theme"
					isEmpty={secondStep}
					params={stylesCssParams}
					uniqueNameOfInput="style-autocomplete-generator-menu"
					isValid={isValidStyleGeneratorMenu}
					value={style}
				/>
			),
		},
		{
			article: 'Choose a bot tone',
			node: (
				<WrappedInput
					type="autocomplete"
					placeholder="Tone"
					isEmpty={secondStep}
					params={temporaryParams}
					uniqueNameOfInput="tone-autocomplete-generator-menu"
					isValid={isValidToneGeneratorMenu}
					value={tone}
				/>
			),
		},
	];

	return (
		<StyledAddStylizationInputsField className="add-stylization-inputs-field">
			<Box component="div" className="add-url-tone">
				{inputsList.map((currentInput) => (
					<WrapperForArticleInput
						key={currentInput.article}
						article={currentInput.article}
					>
						{currentInput.node}
					</WrapperForArticleInput>
				))}
			</Box>
		</StyledAddStylizationInputsField>
	);
};
