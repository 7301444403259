import React from 'react';
import { StyledToggleTheme } from './ToggleTheme.style';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
	switchToLightTheme,
	switchToDarkTheme,
} from '../../../app/slices/switchThemeSlice';
import { type RootState } from '../../../app/store';
import { Box } from '@mui/material';
import { ReactComponent as MoonLight } from '../pic/moonLight.svg';
import { ReactComponent as MoonDark } from '../pic/moonDark.svg';
import { ReactComponent as SunDark } from '../pic/sunDark.svg';
import { ReactComponent as SunLight } from '../pic/sunLight.svg';
import { setItem } from '../../../app/utils/storageUtils';

export const ToggleTheme: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const dispatch = useAppDispatch();
	const handleSwitchTheme = (): void => {
		if (currentTheme.mainRootBackground === '#1A1D21') {
			setItem('theme', 'light');
			dispatch(switchToLightTheme());
		} else {
			setItem('theme', 'dark');
			dispatch(switchToDarkTheme());
		}
	};

	const theme = {
		thumbPositionLeft:
			currentTheme.mainRootBackground === '#1A1D21' ? '2px' : '76px',
		thumbPositionLeftLittleScreen:
			currentTheme.mainRootBackground === '#1A1D21' ? '2px' : '53px',
	};

	return (
		<StyledToggleTheme onClick={handleSwitchTheme} theme={theme}>
			<Box component="div" className="checkbox-dark">
				{currentTheme.mainRootBackground === '#1A1D21' ? (
					<MoonLight />
				) : (
					<MoonDark />
				)}
			</Box>
			<Box component="div" className="checkbox-light">
				{currentTheme.mainRootBackground === '#1A1D21' ? (
					<SunDark />
				) : (
					<SunLight />
				)}
			</Box>
			<Box component="div" className={`checkbox-thumb`}></Box>
		</StyledToggleTheme>
	);
};
