import type React from 'react';
import { useEffect } from 'react';
import { useDeleteSelectedSnippetsMutation } from '../../../app/api/chatbot.api';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setDeleteAllSnippetsMessage } from '../../../app/slices/trainingBotMenu/deleteAllSnippetsMessageTrainingBotMenuSlice';

export const useDeleteSelectedSnippets = (): ((
	ids: number[],
	setStateFn: (value: React.SetStateAction<boolean>) => void
) => Promise<void>) => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const [deleteSelectedSnippets, resultOfDeletingSelectedSnippets] =
		useDeleteSelectedSnippetsMutation();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (resultOfDeletingSelectedSnippets.isError) {
			dispatch(setDeleteAllSnippetsMessage('error'));
		} else if (resultOfDeletingSelectedSnippets.isLoading) {
			dispatch(setDeleteAllSnippetsMessage('loading'));
		} else if (resultOfDeletingSelectedSnippets.isSuccess) {
			dispatch(setDeleteAllSnippetsMessage('success'));
		}
	}, [resultOfDeletingSelectedSnippets]);

	useEffect(() => {
		dispatch(setDeleteAllSnippetsMessage(''));
	}, []);

	return async (
		ids: number[],
		setStateFn: (value: React.SetStateAction<boolean>) => void
	): Promise<void> => {
		const deleteSnippetBody = {
			token,
			ids,
		};
		try {
			const result = await deleteSelectedSnippets(deleteSnippetBody);
			if ('data' in result) {
				dispatch(setDeleteAllSnippetsMessage('success'));
				setStateFn(false);
			} else {
				dispatch(setDeleteAllSnippetsMessage('error'));
			}
		} catch (error) {
			dispatch(setDeleteAllSnippetsMessage('error'));
		}
	};
};
