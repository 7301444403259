// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type IResponseDataBot } from '../../api/interfaces/interfaces';

export interface IInitialDetailedBotCardInputsValueState {
	tone: string;
	style: string;
	styleCss: string;
	structure: string;
	header: string;
	buttonText: string;
	chatBotName: string;
}

export const initialState: IInitialDetailedBotCardInputsValueState = {
	tone: '',
	style: '',
	header: '',
	buttonText: '',
	chatBotName: '',
	styleCss: '',
	structure: '',
};

const detailedBotCardInputsValueSlice = createSlice({
	name: 'detailedBotCardInputsValue',
	initialState,
	reducers: {
		setToneDetailedBotCard(state, action: PayloadAction<string>) {
			state.tone = action.payload;
			return state;
		},
		setStyleDetailedBotCard(state, action: PayloadAction<string>) {
			state.style = action.payload;
			return state;
		},
		setHeaderDetailedBotCard(state, action: PayloadAction<string>) {
			state.header = action.payload;
			return state;
		},
		setButtonTextDetailedBotCard(state, action: PayloadAction<string>) {
			state.buttonText = action.payload;
			return state;
		},
		setChatBotNameDetailedBotCard(state, action: PayloadAction<string>) {
			state.chatBotName = action.payload;
			return state;
		},
		setChatBotStructureDetailedBotCard(state, action: PayloadAction<string>) {
			state.structure = action.payload;
			return state;
		},
		setChatBotStyleCSSDetailedBotCard(state, action: PayloadAction<string>) {
			state.styleCss = action.payload;
			return state;
		},
		setStateDetailedBotCardFromApi(
			state,
			action: PayloadAction<IResponseDataBot>
		) {
			const allBotInformation: IResponseDataBot = action.payload;
			if (allBotInformation.tone !== undefined) {
				state.tone = allBotInformation.tone;
			}
			if (allBotInformation.button !== undefined) {
				state.buttonText = allBotInformation.button;
			}
			if (allBotInformation.askMe !== undefined) {
				state.header = allBotInformation.askMe;
			}
			if (allBotInformation.style !== undefined) {
				state.style = allBotInformation.style;
			}
			// if (allBotInformation.styleCss !== undefined) {
			// 	state.styleCss = allBotInformation.styleCss;
			// }
			// if (allBotInformation.structure !== undefined) {
			// 	state.structure = allBotInformation.structure;
			// }
			if (allBotInformation.chatBotName !== undefined) {
				state.chatBotName = allBotInformation.chatBotName;
			}
			return state;
		},
	},
});

export const {
	setToneDetailedBotCard,
	setStyleDetailedBotCard,
	setHeaderDetailedBotCard,
	setButtonTextDetailedBotCard,
	setChatBotNameDetailedBotCard,
	setStateDetailedBotCardFromApi,
	setChatBotStructureDetailedBotCard,
	setChatBotStyleCSSDetailedBotCard,
} = detailedBotCardInputsValueSlice.actions;
export const detailedBotCardInputsValueReducer =
	detailedBotCardInputsValueSlice.reducer;
