import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import { StyledCreateChangePromptComponent } from './CreateChangePromptComponent.style';
import { WrappedInput } from '../../shared/Input/WrappedInput/WrappedInput';
import { SharedTransparentButton } from '../../shared/SharedTransparentButton/SharedTransparentButton';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import {
	useCreateSnippetMutation,
	useUpdateSnippetMutation,
} from '../../../app/api/chatbot.api';
import { ProgressSpinner } from '../../shared/ProgressSpinner/ProgressSpinner';
import { setEmptyStatusForCreateChangePrompt } from '../../../app/slices/trainingBotMenu/inputImportUrl/inputsCreateChangePropmt/checkEmptyStatusForCreateChangePromptSlice';
import {
	setCompletion,
	setPrompt,
} from '../../../app/slices/trainingBotMenu/inputImportUrl/inputsCreateChangePropmt/createChangePromptValuesSlice';

interface IModalCreateChangePromptProps {
	closeFn: () => void;
	chatbotId: string;
	typeOfModal: string;
}

interface ICreateChangePromptComponentProps {
	closeFn: () => void;
	chatbotId: string;
	typeOfModal: string;
}

const CreateChangePromptComponent: React.FC<
	ICreateChangePromptComponentProps
> = ({ closeFn, chatbotId, typeOfModal }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);
	const theme = { currentTheme };

	const [messageAfterRequest, setMessageAfterRequest] = useState<
		JSX.Element | string
	>('');

	const valuesInputs = useAppSelector(
		(state: RootState) => state.createChangePromptValues
	);
	const { prompt, completion } = valuesInputs;

	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForCreateChangePrompt
	);
	const validInputStatus = useAppSelector(
		(state: RootState) => state.isValidCreateChangePromptInputs
	);
	const { isValidCompletion, isValidPrompt } = validInputStatus;

	const currentSnippetId = useAppSelector(
		(state: RootState) => state.currentSnippetTrainingBotMenu.currentSnippetId
	);

	const refetchFn = useAppSelector(
		(state: RootState) => state.refetchFnTrainingBotMenu.refetchFn
	);

	const [createSnippet, resultOfCreateSnippet] = useCreateSnippetMutation();
	const [updateSnippet, resultOfUpdateSnippet] = useUpdateSnippetMutation();

	const dispatch = useAppDispatch();
	const handleSendFn = async (): Promise<void> => {
		dispatch(setEmptyStatusForCreateChangePrompt(true));

		if (
			isValidCompletion &&
			isValidPrompt &&
			completion.length > 0 &&
			prompt.length > 0
		) {
			if (typeOfModal === 'createSnippet') {
				const requestBody = {
					token,
					id: chatbotId,
					createSnippetBody: {
						prompt,
						completion,
					},
				};
				try {
					const result = await createSnippet(requestBody);
					if ('data' in result) {
						setMessageAfterRequest(
							<Box sx={{ color: '#1592EC' }}>{result.data.status}</Box>
						);
						await refetchFn();
					} else if ('error' in result) {
						setMessageAfterRequest(
							<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
						);
					}
				} catch (error) {
					setMessageAfterRequest(
						<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
					);
				}
			} else if (typeOfModal === 'changeSnippet') {
				const requestBody = {
					token,
					id: currentSnippetId,
					updateSnippetBody: {
						prompt,
						completion,
					},
				};
				try {
					const result = await updateSnippet(requestBody);
					if ('data' in result) {
						setMessageAfterRequest(
							<Box sx={{ color: '#1592EC' }}>
								Your snippet has been successfully updated
							</Box>
						);
						await refetchFn();
					} else if ('error' in result) {
						setMessageAfterRequest(
							<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
						);
					}
				} catch (error) {
					setMessageAfterRequest(
						<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
					);
				}
			}
		}
	};

	useEffect(() => {
		dispatch(setEmptyStatusForCreateChangePrompt(false));
	}, []);

	useEffect(() => {
		if (resultOfCreateSnippet.isError) {
			setMessageAfterRequest(
				<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
			);
		} else if (resultOfCreateSnippet.isLoading) {
			setMessageAfterRequest(<ProgressSpinner />);
		}
	}, [resultOfCreateSnippet]);

	useEffect(() => {
		if (resultOfUpdateSnippet.isError) {
			setMessageAfterRequest(
				<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
			);
		} else if (resultOfUpdateSnippet.isLoading) {
			setMessageAfterRequest(<ProgressSpinner />);
		}
	}, [resultOfUpdateSnippet]);

	const handleCloseModal = (): void => {
		closeFn();
		dispatch(setPrompt(''));
		dispatch(setCompletion(''));
	};

	return (
		<StyledCreateChangePromptComponent theme={theme}>
			<Box component="div" className="createChangePromptComponent-wrapper">
				<Box component="div" className="createChangePrompt-title-subtitle">
					<Box component="div" className="createChangePrompt-title">
						{typeOfModal === 'createSnippet'
							? 'Add your snippet'
							: 'Edit your snippet'}
					</Box>
					<Box component="div" className="createChangePrompt-subtitle">
						{typeOfModal === 'createSnippet'
							? 'Add your custom question and write how you would like the chatbot to answer'
							: 'Edit your question or chatbot answer'}
					</Box>
				</Box>
				<Box component="div" className="createChangePrompt-input-url">
					<WrappedInput
						type="text"
						placeholder="Prompt"
						isEmpty={isButtonPressed}
						isValid={isValidPrompt}
						uniqueNameOfInput={'createChangePrompt-input-modal'}
						value={prompt}
					/>
					<WrappedInput
						type="textarea"
						placeholder="Completion"
						isEmpty={isButtonPressed}
						isValid={isValidCompletion}
						uniqueNameOfInput={'createChangeCompletion-input-modal'}
						value={completion}
					/>
				</Box>
				{messageAfterRequest}
				<Box component="div" className="createChangePrompt-button-field">
					<Box>
						<SharedTransparentButton
							text="Close"
							buttonFunction={handleCloseModal}
						/>
					</Box>
					<Box>
						<SharedMainButton
							isDeleteButton={false}
							text={typeOfModal === 'createSnippet' ? 'Send' : 'Edit'}
							buttonFunction={() => {
								void handleSendFn();
							}}
						/>
					</Box>
				</Box>
			</Box>
		</StyledCreateChangePromptComponent>
	);
};

export const ModalCreateChangePrompt: React.FC<
	IModalCreateChangePromptProps
> = ({ closeFn, chatbotId, typeOfModal }) => {
	return (
		<SharedModal
			closeFn={closeFn}
			executeFn={() => {}}
			message={null}
			title=""
			isOnlyAlert={false}
			standaloneFC={
				<CreateChangePromptComponent
					typeOfModal={typeOfModal}
					chatbotId={chatbotId}
					closeFn={closeFn}
				/>
			}
		/>
	);
};
