import React from 'react';
import { Box } from '@mui/material';
import { type IIDataBotRows } from '../../../PersonalCabinetMenu/Cards/ProfilesBotsCard';
// import { ReactComponent as Edit } from '../pic/edit.svg';
// import { ReactComponent as EditDark } from '../pic/editDark.svg';
// import { useAppSelector } from '../../../../app/hooks';
// import { type RootState } from '../../../../app/store';

export interface IGridCardProps {
	row: IIDataBotRows;
}

export const GridCard: React.FC<IGridCardProps> = ({ row }) => {
	// const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	// const handleClickOnRow = (): void => {
	// 	document.location.replace(row.link);
	// };

	return (
		<Box component="div" className="grid-card-wrapper">
			<Box component="div" className="grid-card-accent-line">
				<Box component="div" className="grid-card-item-left">
					Domain
				</Box>
				<Box component="div" className="grid-card-item-right">
					{row.domain}
				</Box>
			</Box>
			<Box component="div" className="grid-card-line">
				<Box component="div" className="grid-card-item-left">
					Tone
				</Box>
				<Box component="div" className="grid-card-item-right">
					{row.tone}
				</Box>
			</Box>
			<Box component="div" className="grid-card-line">
				<Box component="div" className="grid-card-item-left">
					Style
				</Box>
				<Box component="div" className="grid-card-item-right">
					{row.style}
				</Box>
			</Box>
			<Box component="div" className="grid-card-line">
				<Box component="div" className="grid-card-item-left">
					Actions
				</Box>
				<Box
					component="div"
					className="grid-card-item-right actions-grid-item"
					// onClick={handleClickOnRow}
					// sx={{
					// 	cursor: 'pointer',
					// }}
				>
					{row.actionButtons.map((element) => element)}

					{/* {currentTheme.mainRootBackground === '#1A1D21' ? (
						<EditDark />
					) : (
						<Edit />
					)} */}
				</Box>
			</Box>
		</Box>
	);
};
