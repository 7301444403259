import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledChooseTemplateComponent } from './ChooseTemplateComponent.style';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setCurrentStepGenerator } from '../../../app/slices/currentStepGeneratorSlice';
import {
	setEmptyStatusForCheckChatGeneratorFirstStep,
	setEmptyStatusForCheckChatGeneratorSecondStep,
} from '../../../app/slices/inputsGenerator/checkEmptyStatusForChatGeneratorSlice';
import { getItem, setItem } from '../../../app/utils/storageUtils';
import { CurrentTemplate } from './CurrentTemplate/CurrentTemplate';
import { useGetChatbotTemplateQuery } from '../../../app/api/chatbot.api';
import { type IGetChatbotTemplateResponse } from '../../../app/api/interfaces/styleChatbot/getChatbotTemplate';
import { ProgressDots } from '../../shared/ProgressDots/ProgressDots';
import { DataAlert } from '../../shared/SharedDataAlertFromApi/SharedDataAlertFromApi';

export const ChooseTemplateComponent: React.FC = () => {
	const currentStep = useAppSelector(
		(state: RootState) => state.currentStepGenerator
	);
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	const currentChosenTemplate = useAppSelector(
		(state: RootState) => state.chooseTemplate
	);
	const { chosenTemplateId, chosenTemplateName } = currentChosenTemplate;

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const {
		data: dataGetChatbotTemplate,
		isLoading: isLoadingGetChatbotTemplate,
		isError: isErrorGetChatbotTemplate,
		isSuccess: isSuccessGetChatbotTemplate,
	} = useGetChatbotTemplateQuery({ token });

	const [dataArrayTemplates, setDataArrayTemplates] =
		useState<IGetChatbotTemplateResponse[]>();

	useEffect(() => {
		dataGetChatbotTemplate !== undefined &&
			setDataArrayTemplates(dataGetChatbotTemplate);
	}, [dataGetChatbotTemplate]);

	useEffect(() => {
		if (isLoadingGetChatbotTemplate) {
			// console.log('isLoadingGetChatbotTemplate');
			setLoadingErrorContent(<ProgressDots />);
		} else if (isErrorGetChatbotTemplate) {
			// console.log('isErrorGetChatbotTemplate');
			setLoadingErrorContent(
				<DataAlert
					text="Something went wrong. Please, reload the page"
					isError
				/>
			);
		} else if (isSuccessGetChatbotTemplate) {
			// console.log('isSuccessGetChatbotTemplate');
			setLoadingErrorContent(null);
		}
	}, [
		isLoadingGetChatbotTemplate,
		isErrorGetChatbotTemplate,
		isSuccessGetChatbotTemplate,
	]);

	const dispatch = useAppDispatch();

	const handlePreviousStep = (): void => {
		dispatch(setCurrentStepGenerator(currentStep - 1));
		dispatch(setEmptyStatusForCheckChatGeneratorFirstStep(false));
		dispatch(setEmptyStatusForCheckChatGeneratorSecondStep(false));
		setItem('currentStepGenerator', JSON.stringify(currentStep - 1));
	};

	const handleNextStep = (): void => {
		dispatch(setEmptyStatusForCheckChatGeneratorFirstStep(true));

		if (chosenTemplateId.length > 0 && chosenTemplateName.length > 0) {
			dispatch(setCurrentStepGenerator(2));
			const chatbotData = getItem('chatbotData');
			chatbotData !== null &&
				setItem(
					'chatbotData',
					JSON.stringify({
						...JSON.parse(chatbotData),
						chosenTemplateId,
						chosenTemplateName,
					})
				);
			setItem('currentStepGenerator', '2');
		} else {
			setLoadingErrorContent(
				<DataAlert text="Please, choose a template" isError />
			);
		}
	};

	useEffect(() => {
		setLoadingErrorContent(null);
	}, []);

	return (
		<StyledChooseTemplateComponent
			className="chooseTemplateComponent-wrapper"
			theme={theme}
		>
			<Box component="div" className="choose-template-title">
				Choose a template
			</Box>
			<Box component="div" className="choose-template-wrapper">
				{dataArrayTemplates?.map((currentTemplate) => {
					return (
						<CurrentTemplate
							key={currentTemplate.name}
							templateName={currentTemplate.name}
							templateId={currentTemplate.id.toString()}
							link={currentTemplate.imageUrl}
						/>
					);
				})}
			</Box>
			{loadingErrorContent !== null ? loadingErrorContent : <></>}
			<Box component="div" className="add-button">
				<SharedMainButton
					isDeleteButton={false}
					text="Back"
					buttonFunction={handlePreviousStep}
				/>
				<SharedMainButton
					isDeleteButton={false}
					text="Next"
					buttonFunction={handleNextStep}
					tooltip="Tune your chatbot style and other settings"
				/>
			</Box>
		</StyledChooseTemplateComponent>
	);
};
