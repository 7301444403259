import {
	setAnswerApiLoginPersonalInformation,
	setAnswerApiLoginToken,
} from '../slices/answersFromAPI/answerApiLoginSlice';
import { setStateGeneratorFromStorage } from '../slices/inputsGenerator/chatGeneratorInputsValueSlice';
import { setCurrentStepGenerator } from '../slices/currentStepGeneratorSlice';
import { setCodeJSGeneratedStatus } from '../slices/isCodeJSGeneratedSlice';
import { setStateLoginFromStorage } from '../slices/inputsLogin/loginInputsValueSlice';
import { type AppDispatch } from '../store';
import { clearLoginAndLogout } from './utils';
import { setAuthorizationStatus } from '../slices/isAuthorizationSlice';

export const myStorage = window.localStorage;
export const mySessionStorage = window.sessionStorage;

export const setItem = (key: string, value: string): void => {
	myStorage.setItem(key, value);
};
export const setSessionItem = (key: string, value: string): void => {
	mySessionStorage.setItem(key, value);
};

export const getItem = (key: string): string | null => myStorage.getItem(key);
export const removeItem = (key: string): void => {
	myStorage.removeItem(key);
};

export const getSessionItem = (key: string): string | null =>
	mySessionStorage.getItem(key);
export const removeSessionItem = (key: string): void => {
	mySessionStorage.removeItem(key);
};

export const clearStorage = (): void => {
	myStorage.clear();
};
export const clearSessionStorage = (): void => {
	mySessionStorage.clear();
};

export const showAllItems = (): string[] | null => {
	const allItems: string[] = [];
	if (myStorage.length > 0) {
		for (let i = 0; i < myStorage.length; i++) {
			const currentKey: string | null = myStorage.key(i);
			const currentItem = (): string | null =>
				currentKey !== null ? getItem(currentKey) : null;
			const result = currentItem();
			result !== null && allItems.push(result);
		}
		return allItems;
	} else {
		return null;
	}
};

export const clearStorageAndLogout = (dispatch: AppDispatch): void => {
	clearStorage();
	clearLoginAndLogout(dispatch);
};

export const loadAllInformationFromStorage = (dispatch: AppDispatch): void => {
	const isAuthorization = getItem('isAuthorization');
	isAuthorization === 'true' && dispatch(setAuthorizationStatus(true));

	const currentStepGenerator = getItem('currentStepGenerator');
	const codeJSGeneratedStatus = getItem('codeJSGeneratedStatus');
	currentStepGenerator !== null &&
		dispatch(setCurrentStepGenerator(JSON.parse(currentStepGenerator)));
	codeJSGeneratedStatus !== null &&
		dispatch(setCodeJSGeneratedStatus(JSON.parse(codeJSGeneratedStatus)));

	const loginData = getItem('loginData');
	loginData !== null &&
		dispatch(setStateLoginFromStorage(JSON.parse(loginData)));

	const chatbotData = getItem('chatbotData');
	if (chatbotData !== null) {
		dispatch(setStateGeneratorFromStorage(JSON.parse(chatbotData)));
	}

	const personalInformationFromApi = getItem('loginDataFromApi');
	personalInformationFromApi !== null &&
		dispatch(
			setAnswerApiLoginPersonalInformation(
				JSON.parse(personalInformationFromApi)
			)
		);

	const tokenFromApi = getItem('token');
	tokenFromApi !== null && dispatch(setAnswerApiLoginToken(tokenFromApi));
};
