import React, { useState } from 'react';
import { Box } from '@mui/material';
import {
	type IStepElementProps,
	StepElement,
} from '../../shared/StepElement/StepElement';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { useWindowSize } from '../../../../app/hooks/useWindowSize';
import { InputSendToken } from '../../shared/InputSendToken/InputSendToken';
import { WhatsAppGetToken } from './additional/WhatsAppGetToken/WhatsAppGetToken';
import { WhatsAppConfiguration } from './additional/WhatsAppConfiguration/WhatsAppConfiguration';
import { StyledWhatsAppMenu } from './WhatsAppMenu.style';
import { setItem } from '../../../../app/utils/storageUtils';
import { getWebhookLink } from '../../utils/getWebhookLink';
import { WebhookInnerComponent } from '../../shared/WebhookInnerComponent/WebhookInnerComponent';
import { ChatbotsTable } from '../../shared/ChatbotsTable/ChatbotsTable';
import { HelpIntegrate } from '../../shared/HelpIntegrate/HelpIntegrate';

export const WhatsAppMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const windowSize = useWindowSize();
	const innerWindowWidth = windowSize[0];

	const [creationBotStatus, setCreationBotStatus] = useState(false);
	const [creationBotId, setCreationBotId] = useState(0);

	const handleChangeStatusForWebhookLink = (
		status: boolean,
		chatbotId: number
	): void => {
		if (status && chatbotId > 0) {
			setCreationBotStatus(status);
			setCreationBotId(chatbotId);
		} else {
			setCreationBotStatus(false);
			setCreationBotId(0);
		}
	};

	const updateLocalStorage = (chatbotId: number): void => {
		setTimeout(() => {
			setItem(
				'WhatsApp-webhook',
				getWebhookLink('whatsapp', chatbotId.toString())
			);
		}, 0);
	};

	const steps: IStepElementProps[] = [
		{
			index: 1,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Go to&nbsp;
					<Box
						component="a"
						className="step-Element-link"
						href="https://developers.facebook.com/apps"
						target="blank"
					>
						https://developers.facebook.com/apps
					</Box>
					&nbsp;register if needed
				</Box>
			),
			stepList: [],
		},
		{
			index: 2,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Click on the green{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Create App
					</Box>{' '}
					button
				</Box>
			),
			stepList: [],
		},
		{
			index: 3,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					In the opened window choose{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Other
					</Box>{' '}
					and click{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Next
					</Box>
				</Box>
			),
			stepList: [],
		},
		{
			index: 4,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Then choose{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Business
					</Box>{' '}
					and click{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Next
					</Box>
				</Box>
			),
			stepList: [],
		},
		{
			index: 5,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Provide you app name and click{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Create App
					</Box>
				</Box>
			),
			stepList: [],
		},
		{
			index: 6,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Now you should be on{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Add products to your app page.
					</Box>
				</Box>
			),
			stepList: [
				{
					stepListElement: (
						<Box component="span" className="step-Element-span-wrapper">
							Find Whatsapp on this page and click{' '}
							<Box component="span" sx={{ fontWeight: 600 }}>
								Setup
							</Box>
						</Box>
					),
				},
				{
					stepListElement: (
						<Box component="span" className="step-Element-span-wrapper">
							Continue the registration until you wont get to the Quickstart
							page
						</Box>
					),
				},
			],
		},
		{
			index: 7,
			header:
				'You need to set up a system user as the admin of your app. You can then generate never expiring access tokens for this system user. Step by step guide to set it up:',
			stepList: [
				{
					stepListElement: (
						<Box component="span" className="step-Element-span-wrapper">
							<Box
								component="a"
								className="step-Element-link"
								href="https://developers.facebook.com/apps"
								target="blank"
							>
								Add a system user to your facebook app
							</Box>
							&nbsp;if it doesn&apos;t exist yet. Make sure it has the admin
							role.
						</Box>
					),
				},
				{
					stepListElement: (
						<Box component="span" className="step-Element-span-wrapper">
							On the same page, in the&nbsp;
							<Box component="span" sx={{ fontWeight: 600 }}>
								&ldquo;Assigned Assets&ldquo;
							</Box>
							&nbsp;section, check whether your app is listed here. If not, add
							your app via the&nbsp;
							<Box component="span" sx={{ fontWeight: 600 }}>
								&ldquo;Add asset&ldquo;
							</Box>
							&nbsp;button, granting&nbsp;
							<Box component="span" sx={{ fontWeight: 600 }}>
								&ldquo;Full control&ldquo;
							</Box>
							&nbsp;over your app
						</Box>
					),
				},
				{
					stepListElement: (
						<Box component="span" className="step-Element-span-wrapper">
							<Box
								component="a"
								className="step-Element-link"
								href="https://developers.facebook.com/apps"
								target="blank"
							>
								Add the system user to your Whatsapp account
							</Box>
							&nbsp;in the&nbsp;
							<Box component="span" sx={{ fontWeight: 600 }}>
								&ldquo;People&ldquo;
							</Box>
							&nbsp;section, granting&nbsp;
							<Box component="span" sx={{ fontWeight: 600 }}>
								&ldquo;Full control&ldquo;
							</Box>
							&nbsp;over your Whatsapp account
						</Box>
					),
				},
				{
					stepListElement: (
						<Box component="span" className="step-Element-span-wrapper">
							Now click the
							<Box
								component="a"
								className="step-Element-link"
								href="https://developers.facebook.com/apps"
								target="blank"
							>
								&ldquo;Generate new token&ldquo; button for above system user
							</Box>
							&nbsp;which reveals a&nbsp;
							<Box component="span" sx={{ fontWeight: 600 }}>
								&ldquo;Generate token&ldquo;
							</Box>
							&nbsp;popup.
							<br />
							Select token expiration -&gt; never.
							<br />
							Select the 2 permissions whatsapp_business_management and
							whatsapp_business_messaging and confirm
						</Box>
					),
				},
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{ position: 'relative' }}
						>
							A new access token is presented to you as a link. Click it and
							then store the generated token safely as it will not be stored for
							you by facebook. This token will not expire.
							<WhatsAppGetToken />
						</Box>
					),
					indentation: innerWindowWidth > 1250 ? 300 : 10,
				},
			],
		},
		{
			index: 8,
			header:
				'Now you can give us the permanent token you’ve generated and we will give you a webhook url',
			stepList: [
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{
								position: 'relative',
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							}}
						>
							Please, paste token here and choose chatbot for integration:
							<InputSendToken
								uniqueName="WhatsApp"
								changeStatusForWebhookLink={handleChangeStatusForWebhookLink}
								updateLocalStorageFn={updateLocalStorage}
							/>
						</Box>
					),
				},
			],
		},
		{
			index: 9,
			header: 'Copy this webhook url and go to the configuration page',
			stepList: [
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{
								position: 'relative',
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							}}
						>
							<Box component="span" className="step-Element-span-wrapper">
								Please, copy the next link:
							</Box>
							<WebhookInnerComponent
								localStorageKey="WhatsApp-webhook"
								creationBotStatus={creationBotStatus}
								creationBotId={creationBotId}
								webhookLinkKey="whatsapp"
							/>
							{/* {!creationBotStatus ? (
								<WebhookToCopy link="After creating the WhatsApp bot, there will be a link to the webhook here" />
							) : (
								<WebhookToCopy
									link={getWebhookLink('whatsapp', creationBotId.toString())}
								/>
							)} */}
						</Box>
					),
				},
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{ position: 'relative' }}
						>
							<WhatsAppConfiguration />
						</Box>
					),
					indentation: innerWindowWidth > 1250 ? 200 : 10,
					withoutPointDot: true,
				},
				{
					stepListElement: (
						<Box component="span" className="step-Element-span-wrapper">
							Click on the edit button
						</Box>
					),
				},
				{
					stepListElement: (
						<Box component="span" className="step-Element-span-wrapper">
							In the opened window provide the webhook url you’ve copied and the
							token you’ve generated as verify token
						</Box>
					),
				},
			],
		},
		{
			index: 10,
			header:
				'At this point you should be ready to go. Try to talk to your bot.',
			stepList: [],
		},
	];

	return (
		<StyledWhatsAppMenu theme={theme}>
			<ChatbotsTable keyForDataDisplay="WhatsApp" />

			<Box component="div" className="integrationMenu-header">
				To integrate WhatsApp with Coderfy Chatbot you need:
				{windowSize[0] > 1200 && <HelpIntegrate />}
			</Box>
			{steps.map((step) => (
				<StepElement
					key={step.index}
					index={step.index}
					stepList={step.stepList}
					header={step.header}
				/>
			))}
			{windowSize[0] <= 1200 && <HelpIntegrate />}
		</StyledWhatsAppMenu>
	);
};
