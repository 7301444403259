import styled from 'styled-components';

export const StyledTrainingBotMenu = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 6rem 50px !important;

	& .trainingBotMenu-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 0px !important;

		& .trainingBotMenu-main-card-wrapper {
			display: flex;
			flex-direction: column;
			position: relative;
			border-radius: 30px;
			padding: 48px;
			width: 100%;
			height: max-content;
			gap: 2.96vh;
			background: linear-gradient(
						180deg,
						${(props) => props.theme.currentTheme.cardsBackground} 0%,
						${(props) => props.theme.currentTheme.cardsBackground} 100%
					)
					padding-box,
				linear-gradient(
						180deg,
						${(props) => props.theme.currentTheme.cardsGradient.one},
						${(props) => props.theme.currentTheme.cardsGradient.two} 65.62%
					)
					border-box;
			border: 1px solid transparent;

			& .trainingBotMenu-search-bar-field {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 32px;

				& .search-bar-title-subtitle-area {
					text-align: center;
					width: clamp(300px, 40%, 500px);
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 16px;
					color: ${(props) => props.theme.currentTheme.mainFontColor};

					& .search-bar-title {
						font-size: 24px;
						font-style: normal;
						font-weight: 600;
						line-height: 145%;
					}

					& .search-bar-subtitle {
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		padding: 6rem 24px !important;
	}

	@media screen and (max-width: 1500px) {
		& .trainingBotMenu-wrapper {
			& .trainingBotMenu-main-card-wrapper {
				padding: 24px;

				& .trainingBotMenu-search-bar-field {
					& .search-bar-title-subtitle-area {
						& .search-bar-title {
							font-size: 18px;
							font-weight: 600;
							line-height: 145%;
						}

						& .search-bar-subtitle {
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 550px) {
		padding: 10rem 0.5rem !important;
		& .trainingBotMenu-wrapper {
			& .trainingBotMenu-main-card-wrapper {
				padding: 24px 16px;
			}
		}
	}

	@media screen and (max-width: 350px) {
		& .trainingBotMenu-wrapper {
			& .trainingBotMenu-main-card-wrapper {
				& .trainingBotMenu-search-bar-field {
					& .search-bar-title-subtitle-area {
						width: 100%;
					}
				}
			}
		}
	}
`;
