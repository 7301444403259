import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { ProgressSpinner } from '../../shared/ProgressSpinner/ProgressSpinner';
import { type IClientProfiles } from '../../shared/SharedTable/SharedTable';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { useGetAllBotsQuery } from '../../../app/api/chatbot.api';
import { type IResponseDataBot } from '../../../app/api/interfaces/interfaces';
import { SharedMobileTable } from '../../shared/SharedTable/SharedMobileTable/SharedMobileTable';
import { SharedCustomTable } from '../../shared/SharedTable/SharedCustomTable/SharedCustomTable';
import { VectoriseActionBtn } from '../ActionButtonsForProfilesBotCard/VectoriseActionBtn';
import { useNavigate } from 'react-router-dom';
import { TestBotActionBtn } from '../ActionButtonsForProfilesBotCard/TestBotActionBtn';
import { EditBlueActionBtn } from '../ActionButtonsForProfilesBotCard/EditBlueActionBtn';

export interface IIDataBotRows {
	id: number;
	domain: string;
	tone: string;
	style: string;
	link: string;
	actionButtons: JSX.Element[];
}

export const ProfilesBotsCard: React.FC = React.memo(
	function ProfilesBotsCard() {
		const token = useAppSelector(
			(state: RootState) => state.answerApiLogin.token
		);

		const navigate = useNavigate();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [clientProfiles, setClientProfiles] = useState<IClientProfiles>({
			rows: [],
			columns: [],
		});
		const [successGetData, setSuccessGetData] = useState(false);
		const [dataRows, setDataRows] = useState<IIDataBotRows[]>([]);

		const {
			data: allBotData,
			isLoading,
			isError,
			refetch,
		} = useGetAllBotsQuery({ token });

		const getAllBots = (): void => {
			const rowsFromApi: IIDataBotRows[] = [];
			if (Array.isArray(allBotData) && allBotData.length > 0) {
				// console.log(allBotData);

				allBotData.map((bot: IResponseDataBot, index: number) => {
					const currentRow = {
						id: bot.id,
						domain: bot.domain,
						tone: bot.tone,
						style: bot.style,
						link: `/chatbot/${bot.id}`,
						actionButtons: [
							<VectoriseActionBtn
								chatbotId={bot.id}
								key={bot.id.toString().concat('-vectorise')}
							/>,
							<TestBotActionBtn
								key={bot.id.toString().concat('-test-bot')}
								chatbotId={bot.id}
							/>,
							<EditBlueActionBtn
								key={bot.id.toString().concat('-edit')}
								chatbotId={bot.id}
								onClick={() => {
									navigate(`/chatbot/${bot.id}`);
								}}
							/>,
						],
					};
					return rowsFromApi.push(currentRow);
				});
				setDataRows(rowsFromApi);
				// console.log(rowsFromApi)
				setClientProfiles({
					rows: [...rowsFromApi],
					columns: [
						{ field: 'domain', headerName: 'Domain', width: 250 },
						{ field: 'tone', headerName: 'Tone', width: 150 },
						{ field: 'style', headerName: 'Style', width: 150 },
					],
				});
				setSuccessGetData(true);
			} else {
				setSuccessGetData(false);
			}
		};

		const [windowWidth, setWindowWidth] = useState(0);

		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth);
		});

		useEffect(() => {
			setWindowWidth(window.innerWidth);
			allBotData !== null && getAllBots();
			void refetch();
		}, []);

		useEffect(() => {
			allBotData !== null && getAllBots();
		}, [allBotData]);

		const AdditionalMessageComponent: React.FC = () => {
			if (isError) {
				return <Box>Something went wrong. Please, reload the page</Box>;
			} else if (isLoading) {
				return <ProgressSpinner />;
			} else if (!successGetData) {
				return <Box>You don&apos;t have bots yet</Box>;
			} else {
				return windowWidth <= 850 ? (
					<SharedMobileTable rows={dataRows} />
				) : (
					<SharedCustomTable rows={dataRows} />
				);
			}
		};

		return (
			<Box component="div" className="personal-cabinet-profiles-wrapper">
				<Box
					component="div"
					className="personal-cabinet-profiles in-wrapper-title"
				>
					Profiles
				</Box>
				<Box
					component="div"
					className="personal-cabinet-profiles in-wrapper-table"
				>
					<AdditionalMessageComponent />
				</Box>
			</Box>
		);
	}
);
