// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusForContactsSlice = createSlice({
	name: 'checkEmptyStatusForContacts',
	initialState,
	reducers: {
		setEmptyStatusForContacts(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForContacts } =
	checkEmptyStatusForContactsSlice.actions;
export const checkEmptyStatusForContactsReducer =
	checkEmptyStatusForContactsSlice.reducer;
