import React, { useState, useEffect } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { SharedWrappedInputWithExtraContent } from '../SharedWrappedInputWithExtraContent/SharedWrappedInputWithExtraContent';
import { WrappedInput } from '../WrappedInput/WrappedInput';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { StyledSharedPasswordInput } from './SharedPasswordInput.style';

interface ISharedPasswordInput {
	isEmpty: boolean;
	withIcons: boolean;
	isDarkBack?: boolean;
	uniqueNameOfInput: string;
	value?: string;
	autocomplete?: boolean;
	isValid: boolean;
}

export const SharedPasswordInput: React.FC<ISharedPasswordInput> = ({
	isEmpty,
	withIcons,
	isDarkBack,
	uniqueNameOfInput,
	value,
	isValid,
	autocomplete,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const [windowSizeValue, setWindowSizeValue] = useState<null | number>(null);
	const [isVisiblePassword, setIsVisiblePassword] = useState('password');

	const handleClickShowHidePassword = (): void => {
		setIsVisiblePassword(
			isVisiblePassword === 'password' ? 'text' : 'password'
		);
	};

	useEffect(() => {
		setWindowSizeValue(window.innerWidth);
	}, []);

	window.addEventListener('resize', (e) => {
		setWindowSizeValue(window.innerWidth);
	});

	return (
		<StyledSharedPasswordInput
			theme={theme}
			className="styledSharedPasswordInput"
		>
			<Box className="for-eye" sx={{}}>
				<InputAdornment
					position="end"
					className="eye-icon"
					sx={{
						position: 'absolute',
						zIndex: 800,
						top:
							windowSizeValue !== null && windowSizeValue < 1500
								? '27px'
								: '35px',
						right: '20px',
					}}
				>
					<IconButton
						aria-label="toggle password visibility"
						onMouseUp={handleClickShowHidePassword}
						edge="end"
						tabIndex={-1}
						className="iconButton"
						sx={{ color: currentTheme.mainFontColor }}
					>
						{isVisiblePassword === 'password' ? (
							<Visibility />
						) : (
							<VisibilityOff />
						)}
					</IconButton>
				</InputAdornment>
			</Box>
			{withIcons ? (
				<SharedWrappedInputWithExtraContent
					type={isVisiblePassword}
					placeholder="Password"
					isEmpty={isEmpty}
					uniqueNameOfInput={uniqueNameOfInput}
					isDarkBack={isDarkBack !== undefined ? isDarkBack : false}
					value={value}
					isValid={isValid}
				/>
			) : (
				<WrappedInput
					type={isVisiblePassword}
					placeholder="Password"
					isEmpty={isEmpty}
					isDarkBack
					uniqueNameOfInput={uniqueNameOfInput}
					value={value}
					isValid={isValid}
					autocomplete={autocomplete}
				/>
			)}
		</StyledSharedPasswordInput>
	);
};
