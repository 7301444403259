import React from 'react';
import { StyledTogglePeriod } from './TogglePeriod.style';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { Box } from '@mui/material';
import { setCurrentPeriod } from '../../../app/slices/paymentMethodsMenu/togglePeriodPriceMenuSlice';

export const TogglePeriod: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const currentPeriod = useAppSelector(
		(state: RootState) => state.togglePeriodPriceMenu.currentPeriod
	);

	const dispatch = useAppDispatch();

	const handleSwitchTheme = (): void => {
		if (currentPeriod === 'Year') {
			dispatch(setCurrentPeriod('Month'));
		} else {
			dispatch(setCurrentPeriod('Year'));
		}
	};

	const theme = {
		currentTheme,
		thumbPositionLeft: currentPeriod === 'Year' ? '2px' : '73px',
		thumbPositionLeftLittleScreen: currentPeriod === 'Year' ? '2px' : '53px',
	};

	return (
		<StyledTogglePeriod onClick={handleSwitchTheme} theme={theme}>
			<Box component="div" className="checkbox-dark">
				{currentPeriod === 'Year' ? (
					<Box
						component="span"
						sx={{
							color:
								currentTheme.mainRootBackground === '#1A1D21'
									? '#232323'
									: '#D7E2F2',
						}}
					>
						Yearly
					</Box>
				) : (
					<Box component="span" sx={{ color: currentTheme.mainFontColor }}>
						Yearly
					</Box>
				)}
			</Box>
			<Box component="div" className="checkbox-light">
				{currentPeriod === 'Year' ? (
					<Box component="span" sx={{ color: currentTheme.mainFontColor }}>
						Monthly
					</Box>
				) : (
					<Box
						component="span"
						sx={{
							color:
								currentTheme.mainRootBackground === '#1A1D21'
									? '#232323'
									: '#D7E2F2',
						}}
					>
						Monthly
					</Box>
				)}
			</Box>
			<Box component="div" className={`checkbox-thumb`}></Box>
		</StyledTogglePeriod>
	);
};
