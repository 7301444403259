// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialForgotInputValueState {
	emailForgot: string;
}

const initialState: IInitialForgotInputValueState = {
	emailForgot: '',
};

const forgotInputValueSlice = createSlice({
	name: 'forgotInputValue',
	initialState,
	reducers: {
		setEmailForgot(state, action) {
			state.emailForgot = action.payload;
			return state;
		},
	},
});

export const { setEmailForgot } = forgotInputValueSlice.actions;
export const forgotInputValueReducer = forgotInputValueSlice.reducer;
