import React from 'react';
import { StyledSharedMobileSnippetsTable } from './SharedMobileSnippetsTable.style';
import { MobileGridCard } from './SharedMobileGridCard';
import { useAppSelector } from '../../../../../app/hooks';
import { type RootState } from '../../../../../app/store';
// import { type IIDataSnippetsRows } from '../SharedDesktopTable';
import { Box } from '@mui/material';
import { type IGenericTable } from '../SharedDesktopTable';

interface ISharedMobileTableProps {
	// rows: IIDataSnippetsRows[];
	table: IGenericTable;
	hasActionButton: boolean;
	// middlewareFn: (currentSnippetNumber: string, prompt: string, completion: string) => void;
}

export const SharedMobileSnippetsTable: React.FC<ISharedMobileTableProps> = ({
	hasActionButton,
	table,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = { currentTheme };

	return (
		<StyledSharedMobileSnippetsTable theme={theme}>
			{table.genericRows.length === 0 ? (
				<Box sx={{ alignSelf: 'center', p: 4 }}>You don`t have data yet</Box>
			) : (
				table.genericRows.map((currentRow) => (
					<MobileGridCard
						key={Math.random().toString()}
						row={currentRow}
						titles={table.titles}
						hasActionButton={hasActionButton}
					/>
				))
			)}
		</StyledSharedMobileSnippetsTable>
	);
};
