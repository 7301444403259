import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledTableSnippetsTrainingBotMenu } from './TableSnippetsTrainingBotMenu.style';
import { ActionsLeftAreaTableSnippets } from './ActionsLeftAreaTableSnippets/ActionsLeftAreaTableSnippets';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import {
	type IIDataSnippetsRows,
	SnippetsTable,
} from './SnippetsTable/SnippetsTable';
import { MobileSnippetsTable } from './SnippetsTable/MobileSnippetsTable/MobileSnippetsTable';
import { SharedActionsViewOnPage } from '../../shared/PaginationAndTableComponents/SharedActionsViewOnPage/SharedActionsViewOnPage';
import { SharedPagination } from '../../shared/PaginationAndTableComponents/SharedPagination/SharedPagination';
import { setStartFetchPage } from '../../../app/slices/trainingBotMenu/paginationValuesTrainingBotMenuSlice';
import { SortComponentChoose } from '../../shared/PaginationAndTableComponents/SortComponent/SortComponentChoose/SortComponentChoose';
import { sortOrderForRefetching } from '../../../app/slices/trainingBotMenu/searchBar/searchBarValueSlice';
import { SharedCheckboxItem } from '../../shared/SharedCheckboxItem/SharedCheckboxItem';
import { useAddRemoveCurrentSnippetAsChecked } from '../hooks/useAddRemoveCurrentSnippetAsChecked';

interface ITableSnippetsTrainingBotMenuProps {
	snippets: IIDataSnippetsRows[];
	chatbotId: string;
}

export const TableSnippetsTrainingBotMenu: React.FC<
	ITableSnippetsTrainingBotMenuProps
> = ({ snippets, chatbotId }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		currentTheme,
	};

	const [windowWidth, setWindowWidth] = useState(0);
	useEffect(() => {
		setWindowWidth(window.innerWidth);
	}, []);
	window.addEventListener('resize', () => {
		setWindowWidth(window.innerWidth);
	});

	const paginationValues = useAppSelector(
		(state: RootState) => state.paginationValuesTrainingBotMenu
	);
	const { viewOnPage, totalResultsSnippets, startFetchPage } = paginationValues;

	const dispatch = useAppDispatch();
	const handleSortOrderForRefetchingExecute = (sortOrder: string): void => {
		dispatch(sortOrderForRefetching(sortOrder));
	};

	const allCheckedArray = useAppSelector(
		(state: RootState) => state.checkedSnippetsMenu.checkedSnippetsArray
	);
	const allFunctionsWithCheckedArray = useAddRemoveCurrentSnippetAsChecked();
	const { addCurrentSnippetAsChecked, removeAllArraySnippetAsChecked } =
		allFunctionsWithCheckedArray;

	const [isChecked, setIsChecked] = useState(false);

	const handleAddRemoveAllSnippetsOnPage = (): void => {
		if (isChecked) {
			removeAllArraySnippetAsChecked();
		} else {
			removeAllArraySnippetAsChecked();
			snippets.map((currentRow) => {
				addCurrentSnippetAsChecked(currentRow.id);
				return currentRow;
			});
		}

		setIsChecked(!isChecked);
	};

	useEffect(() => {
		allCheckedArray.length === 0 && setIsChecked(false);
	}, [allCheckedArray]);

	return (
		<StyledTableSnippetsTrainingBotMenu
			className="tableSnippetsTrainingBotMenu"
			theme={theme}
		>
			<Box component="div" className="actions-table-field">
				<Box component="div" className="actions-table-push-export-delete-area">
					<ActionsLeftAreaTableSnippets
						chatbotId={chatbotId}
						isEmptyArray={snippets.length === 0}
					/>
				</Box>
				<Box component="div" className="actions-table-view-on-page-area">
					<SharedActionsViewOnPage
						currentCountViewOnPage={viewOnPage}
						uniqueNameOfInput="viewOnPage-autocomplete"
						extraComponent={
							windowWidth >= 1200 ? (
								<></>
							) : (
								<Box component="div" className="right-part-wrapper">
									<SharedCheckboxItem
										setStateAction={handleAddRemoveAllSnippetsOnPage}
										label="All Prompt"
										className="snippet-checkbox"
										isChecked={isChecked}
									/>
									<SortComponentChoose
										sortOrderForRefetchingFn={
											handleSortOrderForRefetchingExecute
										}
										sortComponentsArray={[
											{ sortQuery: 'prompt', title: 'Prompt' },
											{ sortQuery: 'uri', title: 'URL' },
											{ sortQuery: 'created', title: 'Created' },
										]}
									/>
								</Box>
							)
						}
					/>
				</Box>
			</Box>
			<Box component="div" className="snippets-main-table-field">
				{windowWidth > 1200 ? (
					<SnippetsTable rows={snippets} />
				) : (
					<MobileSnippetsTable rows={snippets} />
				)}
			</Box>
			<Box component="div" className="pagination-table-field">
				<SharedPagination
					viewOnPage={viewOnPage}
					totalResults={totalResultsSnippets}
					setValueFn={setStartFetchPage}
					currentPage={startFetchPage}
				/>
			</Box>
		</StyledTableSnippetsTrainingBotMenu>
	);
};
