import React from 'react';
import { StyledSharedChatbotGeneratorStepWrapper } from './SharedChatbotGeneratorStepWrapper.style';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';

interface IStyledSharedChatbotGeneratorStepWrapperProps {
	children: JSX.Element[];
}

export const SharedChatbotGeneratorStepWrapper: React.FC<
	IStyledSharedChatbotGeneratorStepWrapperProps
> = ({ children }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const currentStep = useAppSelector(
		(state: RootState) => state.currentStepGenerator
	);

	const theme = {
		currentTheme,
		width: currentStep === 1 ? 'auto' : '100%',
	};

	return (
		<StyledSharedChatbotGeneratorStepWrapper theme={theme}>
			{children}
		</StyledSharedChatbotGeneratorStepWrapper>
	);
};
