import React, { useState } from 'react';
import { Box } from '@mui/material';
import { StyledMessengerMenu } from './MessengerMenu.style';
import {
	type IStepElementProps,
	StepElement,
} from '../../shared/StepElement/StepElement';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { useWindowSize } from '../../../../app/hooks/useWindowSize';
import { MessengerSetUp } from './additional/MessagerSetUp/MessengerSetUp';
import { MessengerGetToken } from './additional/MessengerGetToken/MessengerGetToken';
import { MessengerWebhook } from './additional/MessengerWebhook/MessengerWebhook';
import { InputSendToken } from '../../shared/InputSendToken/InputSendToken';
import { getWebhookLink } from '../../utils/getWebhookLink';
import { setItem } from '../../../../app/utils/storageUtils';
import { WebhookInnerComponent } from '../../shared/WebhookInnerComponent/WebhookInnerComponent';
import { ChatbotsTable } from '../../shared/ChatbotsTable/ChatbotsTable';
import { HelpIntegrate } from '../../shared/HelpIntegrate/HelpIntegrate';

export const MessengerMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const windowSize = useWindowSize();
	const innerWindowWidth = windowSize[0];

	const [creationBotStatus, setCreationBotStatus] = useState(false);
	const [creationBotId, setCreationBotId] = useState(0);

	const handleChangeStatusForWebhookLink = (
		status: boolean,
		chatbotId: number
	): void => {
		if (status && chatbotId > 0) {
			setCreationBotStatus(status);
			setCreationBotId(chatbotId);
		} else {
			setCreationBotStatus(false);
			setCreationBotId(0);
		}
	};

	const updateLocalStorage = (chatbotId: number): void => {
		setItem(
			'Messenger-webhook',
			getWebhookLink('messenger', chatbotId.toString())
		);
	};

	const steps: IStepElementProps[] = [
		{
			index: 1,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Go to&nbsp;
					<Box
						component="a"
						className="step-Element-link"
						href="https://developers.facebook.com/apps"
						target="blank"
					>
						https://developers.facebook.com/apps
					</Box>
					&nbsp;register if needed
				</Box>
			),
			stepList: [],
		},
		{
			index: 2,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Click on the green{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Create App
					</Box>{' '}
					button
				</Box>
			),
			stepList: [],
		},
		{
			index: 3,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					In the opened window choose{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Other
					</Box>{' '}
					and click{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Next
					</Box>
				</Box>
			),
			stepList: [],
		},
		{
			index: 4,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Then choose{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Business
					</Box>{' '}
					and click{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Next
					</Box>
				</Box>
			),
			stepList: [],
		},
		{
			index: 5,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Provide you app name and click{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Create App
					</Box>
				</Box>
			),
			stepList: [],
		},
		{
			index: 6,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Now you should be on{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Add products to your app page.
					</Box>{' '}
					Find messenger on this page and click{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Setup:
					</Box>
				</Box>
			),
			stepList: [
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{ position: 'relative' }}
						>
							<MessengerSetUp />
						</Box>
					),
					indentation: innerWindowWidth > 800 ? 330 : 10,
					withoutPointDot: true,
				},
			],
		},
		{
			index: 7,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Create new page for you bot or choose{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Existing
					</Box>{' '}
					and click{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Generate Token
					</Box>
				</Box>
			),
			stepList: [],
		},
		{
			index: 8,
			header:
				'Copy the generated token and provide it to chatbot-generator website',
			stepList: [
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{ position: 'relative' }}
						>
							<MessengerGetToken />
						</Box>
					),
					indentation: innerWindowWidth > 800 ? 300 : 10,
					withoutPointDot: true,
				},
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{
								position: 'relative',
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							}}
						>
							Please, paste token here and choose chatbot for integration:
							<InputSendToken
								uniqueName="Messenger"
								changeStatusForWebhookLink={handleChangeStatusForWebhookLink}
								updateLocalStorageFn={updateLocalStorage}
							/>
						</Box>
					),
				},
			],
		},
		{
			index: 9,
			header:
				'You will be provided with webhook url copy it and paste it to facebook messenger app',
			stepList: [
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{
								position: 'relative',
								display: 'flex',
								flexDirection: 'column',
								gap: 2,
							}}
						>
							<Box component="span" className="step-Element-span-wrapper">
								Please, copy the next link:
							</Box>
							<WebhookInnerComponent
								localStorageKey="Messenger-webhook"
								creationBotStatus={creationBotStatus}
								creationBotId={creationBotId}
								webhookLinkKey="messenger"
							/>
							{/* {!creationBotStatus ? (
								<WebhookToCopy link="After creating the messenger bot, there will be a link to the webhook here" />
							) : (
								<WebhookToCopy
									link={getWebhookLink('messenger', creationBotId.toString())}
								/>
							)} */}
						</Box>
					),
				},
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{ position: 'relative' }}
						>
							Please, paste this link to facebook messenger app:
							<MessengerWebhook />
						</Box>
					),
					indentation: innerWindowWidth > 800 ? 220 : 10,
				},
			],
		},
		{
			index: 10,
			header: 'Use the token you’ve generated as a Verify token',
			stepList: [],
		},
		{
			index: 11,
			header: (
				<Box component="span" className="step-Element-span-wrapper">
					Click{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Verify
					</Box>{' '}
					and{' '}
					<Box component="span" sx={{ fontWeight: 600 }}>
						Save
					</Box>
				</Box>
			),
			stepList: [],
		},
		{
			index: 12,
			header:
				'At that point you should be ready to go. Try to talk to your bot. It might take some time to response.',
			stepList: [],
		},
	];

	return (
		<StyledMessengerMenu theme={theme}>
			<ChatbotsTable keyForDataDisplay="Messenger" />

			<Box component="div" className="integrationMenu-header">
				To integrate Messenger with Coderfy Chatbot you need:
				{windowSize[0] > 1200 && <HelpIntegrate />}
			</Box>
			{steps.map((step) => (
				<StepElement
					key={step.index}
					index={step.index}
					stepList={step.stepList}
					header={step.header}
				/>
			))}
			{windowSize[0] <= 1200 && <HelpIntegrate />}
		</StyledMessengerMenu>
	);
};
