// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IPaymentMethodsMenuSliceInitialState {
	isCheckedAddNewCardOption: boolean;
	currentPaymentMethodId: string;
}

const initialState: IPaymentMethodsMenuSliceInitialState = {
	isCheckedAddNewCardOption: true,
	currentPaymentMethodId: '',
};

const paymentMethodsMenuSlice = createSlice({
	name: 'paymentMethodsMenu',
	initialState,
	reducers: {
		setStatusCheckedAddNewCardOption(state, action: PayloadAction<boolean>) {
			state.isCheckedAddNewCardOption = action.payload;
			return state;
		},
		setCurrentPaymentMethodId(state, action: PayloadAction<string>) {
			state.currentPaymentMethodId = action.payload;
			return state;
		},
	},
});

export const { setStatusCheckedAddNewCardOption, setCurrentPaymentMethodId } =
	paymentMethodsMenuSlice.actions;
export const paymentMethodsMenuReducer = paymentMethodsMenuSlice.reducer;
