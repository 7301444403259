// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialCreateChangePromptValueState {
	prompt: string;
	completion: string;
}

const initialState: IInitialCreateChangePromptValueState = {
	prompt: '',
	completion: '',
};

const createChangePromptValuesSlice = createSlice({
	name: 'createChangePromptValues',
	initialState,
	reducers: {
		setPrompt(state, action: PayloadAction<string>) {
			state.prompt = action.payload;
			return state;
		},
		setCompletion(state, action: PayloadAction<string>) {
			state.completion = action.payload;
			return state;
		},
	},
});

export const { setPrompt, setCompletion } =
	createChangePromptValuesSlice.actions;
export const createChangePromptValuesReducer =
	createChangePromptValuesSlice.reducer;
