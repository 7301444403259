import React from 'react';
import {
	Box,
	LinearProgress,
	styled,
	linearProgressClasses,
} from '@mui/material';
import { StyledCurrentStatisticItem } from './CurrentStatisticItem.style';
import { useAppSelector } from '../../../../../app/hooks';
import { type RootState } from '../../../../../app/store';

interface ICurrentStatisticItemProps {
	usedCount: number | undefined;
	maxCount: number | undefined;
	nameCurrentStatisticItem: string;
}

export const CurrentStatisticItem: React.FC<ICurrentStatisticItemProps> = ({
	usedCount,
	maxCount,
	nameCurrentStatisticItem,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const remainsCount =
		maxCount !== undefined && usedCount !== undefined
			? maxCount - usedCount
			: 0;
	const percent =
		maxCount !== undefined && usedCount !== undefined
			? Math.ceil((usedCount / maxCount) * 100)
			: 0;

	const CircleIcon: React.FC<{ color: string }> = ({ color }) => {
		return (
			<Box
				sx={{
					width: '12px',
					height: '12px',
					borderRadius: '50%',
					background:
						color === 'disabled'
							? currentTheme.vidgetCardBlueDisabledColor
							: '#1592EC',
				}}
			></Box>
		);
	};

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 4,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: currentTheme.vidgetCardBlueDisabledColor,
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: '#1592EC',
		},
	}));

	return (
		<StyledCurrentStatisticItem className="currentStatisticItem">
			<Box component="div" className="currentStatisticItem-stage-field">
				<Box component="div" className="currentStatisticItem-stage-name">
					{nameCurrentStatisticItem}
				</Box>
				<Box component="div" className="currentStatisticItem-stage">
					<BorderLinearProgress variant="determinate" value={percent} />
				</Box>
			</Box>
			<Box component="div" className="currentStatisticItem-count-field">
				<Box component="div" className="currentStatisticItem-count-used">
					<CircleIcon color="active" />
					<Box component="span">Used</Box>
					<Box component="span">{usedCount}</Box>
				</Box>
				<Box component="div" className="currentStatisticItem-count-remains">
					<CircleIcon color="disabled" />
					<Box component="span">Remains</Box>
					<Box component="span">{remainsCount}</Box>
				</Box>
			</Box>
		</StyledCurrentStatisticItem>
	);
};
