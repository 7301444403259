// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusForCreateChangePromptSlice = createSlice({
	name: 'checkEmptyStatusForCreateChangePrompt',
	initialState,
	reducers: {
		setEmptyStatusForCreateChangePrompt(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForCreateChangePrompt } =
	checkEmptyStatusForCreateChangePromptSlice.actions;
export const checkEmptyStatusForCreateChangePromptReducer =
	checkEmptyStatusForCreateChangePromptSlice.reducer;
