import styled from 'styled-components';

export const StyledWrappedPricePlanCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	/* align-items: center; */
	/* background: ${(props) => props.theme.background};
border: ${(props) => props.theme.border}; */
	border-radius: 30px;
	padding: 29px 20px;
	width: 380px;
	height: 587px;
	/* gap: 2rem; */
	/* transform: scale(${(props) => props.theme.scale}); */

	background: linear-gradient(
				180deg,
				${(props) => props.theme.currentTheme.cardsBackground} 0%,
				${(props) => props.theme.currentTheme.cardsBackground} 100%
			)
			padding-box,
		linear-gradient(
				180deg,
				${(props) => props.theme.currentTheme.cardsGradient.one},
				${(props) => props.theme.currentTheme.cardsGradient.two} 65.62%
			)
			border-box;
	border: 1px solid transparent;

	/* & .high-wrapper { */
	& .popular-plan-popup {
		position: absolute;
		top: -12px;
		right: 0px;
		border-radius: 100px;
		background: #1592ec;
		/* width: 114px;
			height: 34px; */
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.2rem 0.4rem;

		& .popular-plan-text {
			color: #fff;
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 18px;
		}
	}

	& .price-plan-card-title {
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 48px;
	}

	& .current-price-field {
		display: flex;
		gap: 0.5rem;
		padding-top: 0.5rem;
		padding-bottom: 49px;

		& .current-price {
			font-size: 60px;
			font-weight: 700;
			line-height: 46px;
		}

		& h3 {
			color: #a2a4c2;
		}
	}

	& .current-benefits-list-field {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		padding-top: 33px;

		& .current-wrapped-benefit {
			display: flex;
			align-items: center;
			gap: 1rem;

			& svg {
				width: 23px;
				height: 23px;
			}

			& .price-plan-card-current-benefit {
				width: 100%;
			}

			& h3 {
				color: #a2a4c2;
			}
		}
	}
	/* } */

	& .current-button-field {
		display: flex;
		justify-content: center;
		/* position: relative; */

		/* & a { */

		& button {
			/* Past logic */
			/* position: absolute;
			z-index: 900;
			bottom: 44px; */
			width: 80%;
			text-decoration: none;
			/* bottom: ${(props) => props.theme.heightButtonMargin}; */

			& .ContentInButtonPopularPlan {
				display: flex;
				gap: 0.5rem;
				justify-content: center;
				align-self: center;

				& svg path {
					stroke: #0f1011;
				}

				& .ContentInButtonPopularPlan-text {
					display: flex;
					align-items: center;
				}
			}
		}

		& button:hover {
			.ContentInButtonPopularPlan {
				& svg path {
					stroke: #fff;
				}
			}
		}
	}
	/* } */

	@media screen and (max-width: 1500px) {
		/* transform: scale(0.9); */
		width: 320px;

		& .price-plan-card-title {
			font-size: 24px;
		}

		& .current-benefits-list-field {
			font-size: 14px;
		}
	}

	@media screen and (max-width: 1300px) {
		/* transform: scale(0.9); */
		width: 300px;
	}

	@media screen and (max-width: 1100px) {
		height: 650px;
	}

	@media screen and (max-width: 1100px) {
		height: 587px;
	}

	@media screen and (max-width: 750px) {
		/* height: max-content; */
		/* padding-bottom: 6rem; */
	}

	@media screen and (max-width: 350px) {
		transform: scale(0.7);
	}
`;
