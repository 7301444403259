// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

interface IEmptyStatusForSteps {
	firstStep: boolean;
	secondStep: boolean;
}

const initialState: IEmptyStatusForSteps = {
	firstStep: false,
	secondStep: false,
};

const checkEmptyStatusForChatGeneratorSlice = createSlice({
	name: 'checkEmptyStatusForChatGenerator',
	initialState,
	reducers: {
		setEmptyStatusForCheckChatGeneratorFirstStep(state, action) {
			state.firstStep = action.payload;
			return state;
		},
		setEmptyStatusForCheckChatGeneratorSecondStep(state, action) {
			state.secondStep = action.payload;
			return state;
		},
	},
});

export const {
	setEmptyStatusForCheckChatGeneratorFirstStep,
	setEmptyStatusForCheckChatGeneratorSecondStep,
} = checkEmptyStatusForChatGeneratorSlice.actions;
export const checkEmptyStatusForChatGeneratorReducer =
	checkEmptyStatusForChatGeneratorSlice.reducer;
