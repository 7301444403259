import React from 'react';
import { Box } from '@mui/material';
import { ErrorMenu } from '../components/ErrorMenu/ErrorMenu';

export const ErrorPage: React.FC = () => {
	return (
		<Box component="div" className="error-page">
			<ErrorMenu />
		</Box>
	);
};
