import React from 'react';
import { StyledHistoryPayment } from './HistoryPayment.style';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import visa from '../pic/visa.svg';
import amex from '../pic/amex.svg';
import mastercard from '../pic/mastercard.svg';
import { ProgressDots } from '../../shared/ProgressDots/ProgressDots';
import { SharedPagination } from '../../shared/PaginationAndTableComponents/SharedPagination/SharedPagination';
import { setHistoryStartFetchPageFondyPagination } from '../../../app/slices/paymentsHistory/paymentsHistoryFondyPaginationSlice';

export interface IPayment {
	date: string;
	plan: string;
	price: string;
	transaction: string;
	status: string;
	cardType?: string;
	invoiceLink?: string;
}

export interface IStatusLoadPaymentsHistory {
	isLoading: boolean;
	isError: boolean;
	isSuccess: boolean;
}

interface IHistoryPaymentProps {
	payments: IPayment[] | undefined;
	statusPaymentsHistory: IStatusLoadPaymentsHistory;
	isFondy?: boolean;
	totalResults?: number;
}

interface IGridRowProps {
	paymentData: IPayment;
	isTitleRow?: boolean;
	isFondy?: boolean;
}

const GridPaymentRow: React.FC<IGridRowProps> = ({
	paymentData,
	isTitleRow,
	isFondy,
}) => {
	const classNameGridRow = isTitleRow === true ? 'isTitle' : 'isValue';

	return (
		<Box
			component="div"
			className={`grid-row-wrapper ${classNameGridRow}`}
			onClick={
				isFondy === true
					? () => {}
					: () => {
							Boolean(paymentData.invoiceLink) &&
								window.open(paymentData.invoiceLink);
					  }
			}
		>
			<Box component="div" className={`grid-date ${classNameGridRow}`}>
				{paymentData.date}
			</Box>
			<Box component="div" className={`grid-plan ${classNameGridRow}`}>
				{paymentData.plan}
			</Box>
			<Box component="div" className={`grid-price ${classNameGridRow}`}>
				{paymentData.price}
			</Box>
			<Box component="div" className={`grid-transaction ${classNameGridRow}`}>
				<Box component="span">{paymentData.transaction}</Box>
				{isTitleRow === undefined &&
				paymentData.cardType !== undefined &&
				paymentData.cardType?.length > 0 ? (
					<Box
						component="img"
						src={
							paymentData.cardType === 'visa' || paymentData.cardType === 'VISA'
								? visa
								: paymentData.cardType === 'mastercard' ||
								  paymentData.cardType === 'MasterCard'
								? mastercard
								: paymentData.cardType === 'amex'
								? amex
								: ''
						}
						alt="card"
					/>
				) : (
					<></>
				)}
			</Box>
			<Box
				component="div"
				className={`grid-status ${classNameGridRow}`}
				sx={{
					color:
						paymentData.status === 'Succeeded' ||
						paymentData.status === 'Success'
							? '#1EAF2C'
							: '#E2483E',
				}}
			>
				{paymentData.status}
			</Box>
		</Box>
	);
};

export const HistoryPayment: React.FC<IHistoryPaymentProps> = ({
	payments,
	statusPaymentsHistory,
	isFondy,
	totalResults,
}) => {
	const titleGridHistoryRow = {
		date: 'Date',
		plan: 'Plan',
		price: 'Price',
		transaction: 'Transaction',
		status: 'Status',
	};

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const currentPage = useAppSelector(
		(state: RootState) =>
			state.paymentsHistoryFondyPagination.startFetchPageHistoryFondyPagination
	);

	const theme = {
		currentTheme,
	};

	return (
		<StyledHistoryPayment theme={theme}>
			{statusPaymentsHistory.isSuccess && payments?.length === 0 ? (
				<></>
			) : (
				<GridPaymentRow paymentData={titleGridHistoryRow} isTitleRow />
			)}
			{statusPaymentsHistory.isLoading ? (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						pt: 3,
					}}
				>
					<ProgressDots />
				</Box>
			) : statusPaymentsHistory.isError ? (
				<Box>Something went wrong. Please, reload the page</Box>
			) : statusPaymentsHistory.isSuccess && payments?.length === 0 ? (
				<Box>You don&apos;t have a payment history</Box>
			) : (
				payments?.map((paymentData, index) => (
					<GridPaymentRow
						key={index}
						paymentData={paymentData}
						isFondy={isFondy}
					/>
				))
			)}
			{isFondy === true ? (
				<SharedPagination
					viewOnPage="10"
					totalResults={totalResults !== undefined ? totalResults : 10}
					setValueFn={setHistoryStartFetchPageFondyPagination}
					currentPage={currentPage}
				/>
			) : (
				<></>
			)}
		</StyledHistoryPayment>
	);
};
