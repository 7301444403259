import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { StyledCurrentTemplate } from './CurrentTemplate.style';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import {
	setChosenTemplateId,
	setChosenTemplateName,
} from '../../../../app/slices/chooseTemplate/chooseTemplateSlice';

interface ICurrentTemplateProps {
	link: string;
	templateName: string;
	templateId: string;
}

export const CurrentTemplate: React.FC<ICurrentTemplateProps> = ({
	templateName,
	templateId,
	link,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const currentChosenTemplate = useAppSelector(
		(state: RootState) => state.chooseTemplate
	);
	const { chosenTemplateName } = currentChosenTemplate;
	const theme = { currentTheme };
	const dispatch = useAppDispatch();

	const handleChooseTemplate = (): void => {
		dispatch(setChosenTemplateName(templateName));
		dispatch(setChosenTemplateId(templateId));
	};

	useEffect(() => {
		dispatch(setChosenTemplateName(''));
		dispatch(setChosenTemplateId(''));
	}, []);

	return (
		<StyledCurrentTemplate
			className={`currentTemplate-wrapper ${
				chosenTemplateName === templateName ? 'active-template' : ''
			}`}
			theme={theme}
			onClick={handleChooseTemplate}
		>
			<Box
				component="img"
				className="currentTemplate"
				src={link}
				alt="chatbot-template"
			></Box>
		</StyledCurrentTemplate>
	);
};
