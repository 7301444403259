import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialCurrentChatbotIdTrainingBotMenu {
	currentChatbotId: string;
}

const initialState: IInitialCurrentChatbotIdTrainingBotMenu = {
	currentChatbotId: '',
};

const currentChatbotIdTrainingBotMenuSlice = createSlice({
	name: 'currentChatbotIdTrainingBotMenu',
	initialState,
	reducers: {
		setCurrentChatbotId(state, action: PayloadAction<string>) {
			state.currentChatbotId = action.payload;
			return state;
		},
	},
});

export const { setCurrentChatbotId } =
	currentChatbotIdTrainingBotMenuSlice.actions;
export const currentChatbotIdTrainingBotMenuReducer =
	currentChatbotIdTrainingBotMenuSlice.reducer;
