import React from 'react';
import { ContactsMenu } from '../components/ContactsMenu/ContactsMenu';
import { useCheckAuth } from '../app/hooks';
import { Navigate } from 'react-router-dom';
import { LayoutAccount } from '../components/shared/LayoutAccount/LayoutAccount';

export const ContactsPage: React.FC = () => {
	const isAuthorization = useCheckAuth();

	return (
		<LayoutAccount title="Contacts">
			{isAuthorization ? <ContactsMenu /> : <Navigate to="/" replace={true} />}
		</LayoutAccount>
	);
};
