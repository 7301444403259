// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledInput = styled.input`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 10px 10px 17px;
	gap: 10px;
	width: 470px;
	height: ${(props) =>
		props.theme.uniqueNameOfInput.match(/text-area/gim) !== null
			? '300px'
			: '68px'};
	background: ${(props) => props.theme.currentTheme.cardsBackground};
	border-radius: 8px !important;
	border: ${(props) => props.theme.color};
	outline: none;
	color: ${(props) => props.theme.currentTheme.fontColorInput};
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 100%;

	/* The most usable way to create gradient border with border-radius */
	&:hover {
		background: linear-gradient(
					${(props) => props.theme.currentTheme.cardsBackground} 0 0
				)
				padding-box,
			linear-gradient(to right, #43f2ff, #691eff) border-box;
		border: 1px solid transparent;
	}

	&:disabled {
		color: ${(props) => props.theme.currentTheme.disabledColor};
		background: ${(props) => props.theme.currentTheme.disabledBackground};
		border: 1px solid ${(props) => props.theme.currentTheme.disabledBorder};
	}

	&:disabled::placeholder {
		color: ${(props) => props.theme.currentTheme.disabledColor};
	}

	&:focus {
		border: 1px solid #1592ec;
	}
	&::placeholder {
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 100%;
		letter-spacing: -0.01em;
		color: ${(props) => props.theme.currentTheme.fontColorInput};
	}

	@media screen and (max-width: 1500px) {
		height: ${(props) =>
			props.theme.uniqueNameOfInput.match(/text-area/gim) !== null
				? '300px'
				: '50px'};

		font-size: 14px;
		&::placeholder {
			font-size: 14px;
		}
	}

	@media screen and (max-width: 1000px) {
		width: 70vmin;
		max-width: 550px;
	}

	@media screen and (max-width: 500px) {
		width: 85vmin;
		font-size: 16px;
		&::placeholder {
			font-size: 16px;
		}
	}
`;
