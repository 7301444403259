import React from 'react';
import { fourthNichesArticle } from '../shared/textContent';
import { StyledNichesMenu } from './NichesMenu.style';
import { Box } from '@mui/material';
import { NicheWrapper } from './NicheWrapper';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';

export const NichesMenu: React.FC = () => {
	const firstPartOfArticles = fourthNichesArticle.filter(
		(value, index) => index <= 4
	);
	const secondPartOfArticles = fourthNichesArticle.filter(
		(value, index) => index > 4
	);

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	return (
		<StyledNichesMenu theme={theme}>
			<Box>
				<Box component="div" className="niches-column-wrapper">
					{firstPartOfArticles.map((item, index) => (
						<NicheWrapper
							key={index}
							title={item.accent}
							value={item.content}
							index={index + 1}
						/>
					))}
				</Box>
				<Box component="div" className="niches-column-wrapper">
					{secondPartOfArticles.map((item, index) => (
						<NicheWrapper
							key={index}
							title={item.accent}
							value={item.content}
							index={index + 6}
						/>
					))}
				</Box>
			</Box>
		</StyledNichesMenu>
	);
};
