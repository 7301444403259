// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSharedNavLink = styled.div`
	& > a {
		display: flex;
		gap: 0.3rem;
		text-decoration: none;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 145%;
		flex-wrap: wrap;
		justify-content: center;
		& .white-text {
			color: #fff;
		}
		& .blue-text {
			color: #1592ec;
		}
	}
`;
