// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledWrappedInput = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	position: relative;

	& .left-input-image {
		position: relative;
		width: 100px;
		display: flex;
		justify-content: flex-end;
	}

	& .right-part-input {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 1rem;
	}

	& input[type='number']::-webkit-outer-spin-button,
	& input[type='number']::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	& input[type='number'] {
		-moz-appearance: textfield;
	}
	& input[type='number']:hover,
	& input[type='number']:focus {
		-moz-appearance: number-input;
	}
	& input[type='number']::-webkit-inner-spin-button,
	& input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	& .wrapper-btn-inc-dec {
		position: absolute;
		top: 15px;
		right: 15px;
		background: none;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		& button {
			background: none;
			display: flex;
			border-radius: 8px;
			align-items: center;
			justify-content: center;

			& svg {
				width: 10px;
				height: 10px;

				& path {
					stroke: #5e17eb;
				}
			}

			&:hover {
				background: #5e17eb30;
			}
		}
	}

	@media screen and (max-width: 500px) {
		& .alertMessage {
			width: 50vmin;
		}
	}
`;
