// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialChatGeneratorValidInputsValueState {
	isValidDomainGeneratorMenu: boolean;
	isValidToneGeneratorMenu: boolean;
	isValidOpenAIKeyGeneratorMenu: boolean;
	isValidReCaptchaKeyGeneratorMenu: boolean;
	isValidReCaptchaSecretKeyGeneratorMenu: boolean;
	isValidStyleGeneratorMenu: boolean;
	isValidHeaderGeneratorMenu: boolean;
	isValidButtonTextGeneratorMenu: boolean;
}

const initialState: IInitialChatGeneratorValidInputsValueState = {
	isValidDomainGeneratorMenu: false,
	isValidToneGeneratorMenu: false,
	isValidOpenAIKeyGeneratorMenu: false,
	isValidReCaptchaKeyGeneratorMenu: false,
	isValidReCaptchaSecretKeyGeneratorMenu: false,
	isValidStyleGeneratorMenu: false,
	isValidHeaderGeneratorMenu: false,
	isValidButtonTextGeneratorMenu: false,
};

const isValidChatGeneratorInputsSlice = createSlice({
	name: 'isValidChatGeneratorInputs',
	initialState,
	reducers: {
		setValidStatusDomainGeneratorMenu(state, action) {
			state.isValidDomainGeneratorMenu = action.payload;
			return state;
		},
		setValidStatusToneGeneratorMenu(state, action) {
			state.isValidToneGeneratorMenu = action.payload;
			return state;
		},
		setValidStatusOpenAIKeyGeneratorMenu(state, action) {
			state.isValidOpenAIKeyGeneratorMenu = action.payload;
			return state;
		},
		setValidStatusReCaptchaKeyGeneratorMenu(state, action) {
			state.isValidReCaptchaKeyGeneratorMenu = action.payload;
			return state;
		},
		setValidStatusReCaptchaSecretKeyGeneratorMenu(state, action) {
			state.isValidReCaptchaSecretKeyGeneratorMenu = action.payload;
			return state;
		},
		setValidStatusStyleGeneratorMenu(state, action) {
			state.isValidStyleGeneratorMenu = action.payload;
			return state;
		},
		setValidStatusHeaderGeneratorMenu(state, action) {
			state.isValidHeaderGeneratorMenu = action.payload;
			return state;
		},
		setValidStatusButtonTextGeneratorMenu(state, action) {
			state.isValidButtonTextGeneratorMenu = action.payload;
			return state;
		},
	},
});

export const {
	setValidStatusDomainGeneratorMenu,
	setValidStatusToneGeneratorMenu,
	setValidStatusOpenAIKeyGeneratorMenu,
	setValidStatusReCaptchaKeyGeneratorMenu,
	setValidStatusReCaptchaSecretKeyGeneratorMenu,
	setValidStatusStyleGeneratorMenu,
	setValidStatusButtonTextGeneratorMenu,
	setValidStatusHeaderGeneratorMenu,
} = isValidChatGeneratorInputsSlice.actions;
export const isValidChatGeneratorInputsReducer =
	isValidChatGeneratorInputsSlice.reducer;
