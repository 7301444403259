import React from 'react';
import { useCheckAuth } from '../app/hooks';
import { Navigate } from 'react-router-dom';
import { LayoutAccount } from '../components/shared/LayoutAccount/LayoutAccount';
import { PlanMenu } from '../components/PlanMenu/PlanMenu';
import { setSessionItem } from '../app/utils/storageUtils';

export const PlanPage: React.FC = () => {
	const isAuthorization = useCheckAuth();

	const setPlanIdForPurchase = (): void => {
		const searchParams = window.location.search;
		const planIdParameter = searchParams.match(/planId=\w*/gim);
		if (planIdParameter !== null) {
			const planId = decodeURIComponent(planIdParameter[0]).split('planId=')[1];
			setSessionItem('planId', planId);
		}
	};

	const CurrentComponentBySavePlanId: React.FC = () => {
		setPlanIdForPurchase();
		return isAuthorization ? <PlanMenu /> : <Navigate to="/" replace={true} />;
	};

	return (
		<LayoutAccount title="Your Plan">
			<CurrentComponentBySavePlanId />
		</LayoutAccount>
	);
};
