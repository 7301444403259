import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SaveDeleteBntFieldShared } from '../../shared/SaveDeleteBntFieldShared/SaveDeleteBntFieldShared';
import key from './pic/key.svg';
import temperature from './pic/temperature.svg';
import question from './pic/question.svg';
import filter from './pic/filter.svg';
import uniqueness from './pic/uniqueness.svg';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { StyledConfigOpenAiMenu } from './ConfigOpenAiMenu.style';
import { ConfigWrappedInput } from '../ConfigModelMenu/ConfigWrappedInput/ConfigWrappedInput';
import { setEmptyStatusForCheckConfigOpenAIMenu } from '../../../app/slices/configInputs/configOpenAIMenuInputs/checkEmptyStatusForOpenAIMenuInputsSlice';
import {
	useGetConfigQuery,
	useGetOpenAiModelsQuery,
	useUpdateConfigMutation,
} from '../../../app/api/chatbot.api';
import { useLocation } from 'react-router-dom';
import { DataAlert } from '../../shared/SharedDataAlertFromApi/SharedDataAlertFromApi';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import { type IUpdateConfigRequestBody } from '../../../app/api/interfaces/config/updateConfig.interface';
import { checkInput } from '../../../app/utils/utils';
import { ProgressSpinner } from '../../shared/ProgressSpinner/ProgressSpinner';

export const ConfigOpenAiMenu: React.FC = () => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const pressedButton = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForOpenAIMenuInputs
	);
	const isValidInputs = useAppSelector(
		(state: RootState) => state.isValidOpenAIMenuInputs
	);
	const valueConfigOpenAiMenuInputs = useAppSelector(
		(state: RootState) => state.configOpenAIMenuInputs
	);

	const {
		completionModel,
		maxTokensContent,
		// maxTokensMessage,
		maxTokensRequest,
		maxTokensResponse,
		openAiKey,
		temperature: temperatureConfigOpenAiMenuInput,
		threshold,
		// vectorModel,
	} = valueConfigOpenAiMenuInputs;

	const {
		isValidCompletionConfigOpenAIMenu,
		isValidMaxTokensContentConfigOpenAIMenu,
		// isValidMaxTokensMessageConfigOpenAIMenu,
		isValidMaxTokensRequestConfigOpenAIMenu,
		isValidMaxTokensResponseConfigOpenAIMenu,
		isValidOpenAiConfigOpenAIMenu,
		isValidTemperatureConfigOpenAIMenu,
		isValidThresholdConfigOpenAIMenu,
		// isValidVectorModelConfigOpenAIMenu,
	} = isValidInputs;
	const dispatch = useAppDispatch();

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const { data: dataGetOpenAiModels, refetch: refetchGetOpenAiModels } =
		useGetOpenAiModelsQuery({ token });

	const [openAiModelsList, setOpenAiModelsList] = useState<string[]>([]);
	useEffect(() => {
		if (dataGetOpenAiModels !== undefined) {
			const openAiModelsListFromApi = dataGetOpenAiModels.map(
				(currentModel) => currentModel.id
			);
			setOpenAiModelsList(openAiModelsListFromApi);
			checkInput(
				'completion-chat-openAI-menu-changeableInput',
				{ valueInput: completionModel, params: openAiModelsListFromApi },
				dispatch
			);
		}
	}, [dataGetOpenAiModels]);

	const currentId = useLocation().pathname.split('/');
	const currentIdComputed = parseFloat(currentId[currentId.length - 1]);
	const {
		data: currentBotConfigData,
		isLoading: isLoadingCurrentBotConfigData,
		isError: isErrorCurrentBotConfigData,
		isSuccess: isSuccessCurrentBotConfigData,
		refetch: refetchCurrentBotConfigData,
	} = useGetConfigQuery({ chatbotId: currentIdComputed.toString(), token });

	const [updateChatbotConfig, resultOfUpdateConfig] = useUpdateConfigMutation();

	const executeUpdateBotSettings = async (): Promise<void> => {
		if (
			isValidCompletionConfigOpenAIMenu &&
			isValidMaxTokensContentConfigOpenAIMenu &&
			isValidMaxTokensRequestConfigOpenAIMenu &&
			isValidMaxTokensResponseConfigOpenAIMenu &&
			isValidTemperatureConfigOpenAIMenu &&
			isValidThresholdConfigOpenAIMenu
		) {
			const arrayForUpdateConfigRequestBody = [
				{ value: completionModel, key: 'model', type: 'string' },
				{ value: maxTokensContent, key: 'max_context_tokens', type: 'number' },
				{ value: maxTokensRequest, key: 'max_request_tokens', type: 'number' },
				{ value: maxTokensResponse, key: 'max_tokens', type: 'number' },
				{ value: openAiKey, key: 'api_key', type: 'string' },
				{
					value: temperatureConfigOpenAiMenuInput,
					key: 'temperature',
					type: 'number',
				},
				{ value: threshold, key: 'threshold', type: 'number' },
			];

			const updateConfigRequestBody: IUpdateConfigRequestBody = {};

			const descriptionProperty = {
				configurable: true,
				enumerable: true,
				writable: true,
			};

			for (const updateValue of arrayForUpdateConfigRequestBody) {
				if (updateValue.type === 'string') {
					if (
						currentBotConfigData !== undefined &&
						updateValue.value !==
							currentBotConfigData[
								updateValue.key as keyof typeof currentBotConfigData
							]
					) {
						Object.defineProperty(updateConfigRequestBody, updateValue.key, {
							...descriptionProperty,
							value: updateValue.value,
						});
					}
				} else {
					if (updateValue.key === 'temperature') {
						if (
							currentBotConfigData !== undefined &&
							updateValue.value !==
								currentBotConfigData[
									updateValue.key as keyof typeof currentBotConfigData
								]
						) {
							Object.defineProperty(updateConfigRequestBody, updateValue.key, {
								...descriptionProperty,
								value: parseFloat(updateValue.value),
							});
						}
					} else {
						if (
							currentBotConfigData !== undefined &&
							parseFloat(updateValue.value) !==
								currentBotConfigData[
									updateValue.key as keyof typeof currentBotConfigData
								]
						) {
							Object.defineProperty(updateConfigRequestBody, updateValue.key, {
								...descriptionProperty,
								value: parseFloat(updateValue.value),
							});
						}
					}
				}
			}

			if (Object.keys(updateConfigRequestBody).length > 0) {
				try {
					const result = await updateChatbotConfig({
						token,
						chatbotId: currentIdComputed.toString(),
						updateConfigRequestBody,
					});
					if ('data' in result) {
						setLoadingErrorContent(
							<DataAlert text="Your chatbot settings have been updated" />
						);
						void refetchCurrentBotConfigData();
						void refetchGetOpenAiModels();
					} else {
						setLoadingErrorContent(
							<DataAlert text="Something went wrong" isError />
						);
					}
				} catch (error) {
					setLoadingErrorContent(
						<DataAlert text="Something went wrong" isError />
					);
				}
			} else {
				setLoadingErrorContent(<DataAlert text="Nothing to change" />);
			}
		} else {
			setLoadingErrorContent(
				<DataAlert
					text="Please fill in all required information fields"
					isError
				/>
			);
		}
	};

	const [
		isOpenModalAfterUpdateBotSettings,
		setIsOpenModalAfterUpdateBotSettings,
	] = useState(false);

	const handleCloseModal = (): void => {
		setIsOpenModalAfterUpdateBotSettings(false);
	};
	const handleOpenModalUpdate = (): void => {
		dispatch(setEmptyStatusForCheckConfigOpenAIMenu(true));
		setIsOpenModalAfterUpdateBotSettings(true);
		void executeUpdateBotSettings();
	};

	useEffect(() => {
		dispatch(setEmptyStatusForCheckConfigOpenAIMenu(false));
	}, []);

	const topConfigs = [
		{
			title: 'OpenAI API key',
			changeableInput: {
				type: 'text',
				placeholder: 'API key',
				uniqueNameOfInput: 'openAI-API-key-openAI-menu-changeableInput',
				isValid: isValidOpenAiConfigOpenAIMenu,
				value:
					currentBotConfigData?.api_key != null
						? currentBotConfigData.api_key
						: '',
				icon: key,
				tooltip: 'OpenAI API key to use for model when invoking OpenAI',
			},
		},
	];

	const leftConfigs = [
		{
			title: 'Temperature',
			changeableInput: {
				type: 'number',
				placeholder: 'Temperature',
				uniqueNameOfInput: 'temperature-openAI-menu-changeableInput',
				isValid: isValidTemperatureConfigOpenAIMenu,
				value:
					currentBotConfigData?.temperature != null
						? currentBotConfigData.temperature.toString()
						: '',
				icon: temperature,
				minNumber: 0,
				maxNumber: 1,
				stepNumber: 0.1,
				isFloat: { maxCountInteger: 1, maxCountFloat: 3 },
				tooltip:
					'Temperature, implying risk model will take when generating response. Please enter a value between 0 and 1',
			},
		},
		{
			title: 'Completion/chat model',
			changeableInput: {
				type: 'autocomplete',
				placeholder: 'Completion/chat model',
				uniqueNameOfInput: 'completion-chat-openAI-menu-changeableInput',
				params: openAiModelsList,
				isValid: isValidCompletionConfigOpenAIMenu,
				value:
					currentBotConfigData?.model != null ? currentBotConfigData.model : '',
				icon: question,
				tooltip: 'Completion model to use to generate response',
			},
		},
	];

	const rightConfigs = [
		{
			title: 'Threshold',
			changeableInput: {
				type: 'number',
				placeholder: 'Threshold',
				uniqueNameOfInput: 'threshold-openAI-menu-changeableInput',
				isValid: isValidThresholdConfigOpenAIMenu,
				value:
					currentBotConfigData?.threshold != null
						? currentBotConfigData.threshold.toString()
						: '',
				icon: filter,
				minNumber: 0,
				maxNumber: 1,
				stepNumber: 0.1,
				isFloat: { maxCountInteger: 1, maxCountFloat: 3 },
				tooltip:
					'Threshold for vectors when doing lookup into training data. Please enter a value between 0 and 1',
			},
		},
		{
			title: 'Vector model',
			changeableInput: {
				type: 'text',
				// type: 'autocomplete',
				placeholder: 'Vector model',
				uniqueNameOfInput: 'vector-model-openAI-menu-changeableInput',
				// params: openAiModelsList,
				isValid: true,
				value:
					currentBotConfigData?.vector_model != null
						? currentBotConfigData.vector_model
						: '',
				icon: question,
				tooltip: 'Vector model to vectorise training snippets',
				disabled: true,
			},
		},
	];

	const bottomConfigs = [
		{
			title: 'Max context tokens',
			changeableInput: {
				type: 'number',
				placeholder: 'Max context tokens',
				uniqueNameOfInput: 'max-context-tokens-openAI-menu-changeableInput',
				isValid: isValidMaxTokensContentConfigOpenAIMenu,
				value:
					currentBotConfigData?.max_context_tokens != null
						? currentBotConfigData.max_context_tokens.toString()
						: '',
				icon: uniqueness,
				minNumber: 0,
				maxNumber: 8000,
				tooltip:
					'Max context tokens, implying maximum size of context to send to OpenAI',
			},
		},
		{
			title: 'Max request tokens',
			changeableInput: {
				type: 'number',
				placeholder: 'Max request tokens',
				uniqueNameOfInput: 'max-request-tokens-openAI-menu-changeableInput',
				isValid: isValidMaxTokensRequestConfigOpenAIMenu,
				value:
					currentBotConfigData?.max_request_tokens != null
						? currentBotConfigData.max_request_tokens.toString()
						: '',
				icon: uniqueness,
				minNumber: 0,
				maxNumber: 8000,
				tooltip:
					'Max request tokens, implying maximum prompt size provided by users',
			},
		},
		{
			title: 'Max response tokens',
			changeableInput: {
				type: 'number',
				placeholder: 'Max response tokens',
				uniqueNameOfInput: 'max-response-tokens-openAI-menu-changeableInput',
				isValid: isValidMaxTokensResponseConfigOpenAIMenu,
				value:
					currentBotConfigData?.max_tokens != null
						? currentBotConfigData.max_tokens.toString()
						: '',
				icon: uniqueness,
				minNumber: 0,
				maxNumber: 8000,
				tooltip:
					'Max response tokens, implying maximum size OpenAI is allowed to return for prompts',
			},
		},
		// {
		// 	title: 'Max message tokens',
		// 	changeableInput: {
		// 		type: 'number',
		// 		placeholder: 'Max message tokens',
		// 		uniqueNameOfInput: 'max-message-tokens-openAI-menu-changeableInput',
		// 		isValid: isValidMaxTokensMessageConfigOpenAIMenu,
		// 		value: maxTokensMessage,
		// 		icon: uniqueness,
		// 		tooltip: 'Max message tokens, implying maximum context size to use for conversations',
		// 	},
		// },
	];

	const theme = { currentTheme };

	return (
		<StyledConfigOpenAiMenu className="styledConfigOpenAiMenu" theme={theme}>
			{isLoadingCurrentBotConfigData ? (
				<ProgressSpinner />
			) : isErrorCurrentBotConfigData ? (
				<Box>Something went wrong</Box>
			) : isSuccessCurrentBotConfigData ? (
				<Box component="div" className="styledConfigOpenAiMenu-wrapper">
					<Box component="div" className="top-configs">
						{topConfigs.map((currentConfig) => (
							<ConfigWrappedInput
								key={currentConfig.title}
								title={currentConfig.title}
								changeableInput={currentConfig.changeableInput}
								isEmpty={pressedButton}
							/>
						))}
					</Box>
					<Box component="div" className="aside-configs-wrapper">
						<Box component="div" className="left-configs">
							{leftConfigs.map((currentConfig) => (
								<ConfigWrappedInput
									key={currentConfig.title}
									title={currentConfig.title}
									changeableInput={currentConfig.changeableInput}
									isEmpty={pressedButton}
								/>
							))}
						</Box>
						<Box component="div" className="right-configs">
							{rightConfigs.map((currentConfig) => (
								<ConfigWrappedInput
									key={currentConfig.title}
									title={currentConfig.title}
									changeableInput={currentConfig.changeableInput}
									isEmpty={pressedButton}
								/>
							))}
						</Box>
					</Box>
					<Box component="div" className="bottom-configs">
						{bottomConfigs.map((currentConfig) => (
							<ConfigWrappedInput
								key={currentConfig.title}
								title={currentConfig.title}
								isEmpty={pressedButton}
								changeableInput={currentConfig.changeableInput}
							/>
						))}
					</Box>
					<Box component="div" className="btn-field">
						<SaveDeleteBntFieldShared
							handleOpenModalUpdate={handleOpenModalUpdate}
							// TEMPORARY STATE
							statusState={true}
							textSaveBtn="Save"
							textTooltipSaveBtn="Update current information"
							isOnlySave
						/>
					</Box>
				</Box>
			) : (
				<></>
			)}
			{isOpenModalAfterUpdateBotSettings && (
				<SharedModal
					executeFn={() => {}}
					isDeleteModal={false}
					closeFn={() => {
						handleCloseModal();
					}}
					message={loadingErrorContent}
					isOnlyAlert={true}
					title={
						resultOfUpdateConfig.isLoading
							? 'Please, wait'
							: resultOfUpdateConfig.isError
							? 'Warning'
							: resultOfUpdateConfig.isSuccess
							? 'Succeed'
							: ''
					}
				/>
			)}
		</StyledConfigOpenAiMenu>
	);
};
