// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import { useTestBotMutation } from '../../../app/api/chatbot.api';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setTestBotMessage } from '../../../app/slices/trainingBotMenu/testBotInput/testBotMessageTrainingBotMenuSlice';

export const useTestBot = (): ((
	chatbotId: string,
	textPrompt: string
) => Promise<string>) => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const [testBot, resultOfTestingBot] = useTestBotMutation();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (resultOfTestingBot.isError) {
			dispatch(setTestBotMessage('error'));
		} else if (resultOfTestingBot.isLoading) {
			dispatch(setTestBotMessage('loading'));
		} else if (resultOfTestingBot.isSuccess) {
			dispatch(setTestBotMessage('success'));
		}
	}, [resultOfTestingBot]);

	useEffect(() => {
		dispatch(setTestBotMessage(''));
	}, []);

	return async (chatbotId: string, textPrompt: string): Promise<string> => {
		const deleteSnippetBody = {
			token,
			chatbotId,
			testBotBody: {
				prompt: textPrompt,
			},
		};
		try {
			const result = await testBot(deleteSnippetBody);
			if ('data' in result) {
				dispatch(setTestBotMessage('success'));
				return result.data.result;
			} else {
				dispatch(setTestBotMessage('error'));
				return 'Something went wrong';
			}
		} catch (error) {
			dispatch(setTestBotMessage('error'));
			return 'Something went wrong';
		}
	};
};
