import React, { useState } from 'react';
import { Box } from '@mui/material';
import { StyledDialogWnd } from './DialogWnd.style';
import { DialogAQElement } from '../DialogAQ/DialogAQElement';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
	type setSnippetsListInDialog,
	type setSnippetsListForSaving,
} from '../../../../app/slices/testChatbotModal/snippetsList.slice';

export interface ISnippetObjectForSaveUpdate {
	id: number;
	prompt: string;
	completion: string;
}

export interface ISetStateFn {
	setSnippetsList: typeof setSnippetsListInDialog;
	setSnippetsListForSaving: typeof setSnippetsListForSaving;
}

interface IDialogWndProps {
	snippetsList: ISnippetObjectForSaveUpdate[];
	snippetsListForSaving: ISnippetObjectForSaveUpdate[];
	changeSnippetInList: (
		id: number,
		isPrompt: boolean,
		updateValue: string
	) => void;
	addSnippetForSave: (id: number) => void;
	removeSnippet: (id: number, targetSnippetsList: string) => void;
	setStateActionFn: ISetStateFn;
	refTag: React.MutableRefObject<HTMLDivElement | null>;
}

export const DialogWnd: React.FC<IDialogWndProps> = ({
	snippetsList,
	snippetsListForSaving,
	changeSnippetInList,
	addSnippetForSave,
	removeSnippet,
	setStateActionFn,
	refTag,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const isPresentInSavingList = (id: number): boolean => {
		const snippetIndex = snippetsListForSaving.findIndex(
			(currentSnippet) => currentSnippet.id === id
		);
		return snippetIndex !== -1;
	};

	const DialogAqWrapper: React.FC<{
		currentSnippetObject: ISnippetObjectForSaveUpdate;
		key: number;
	}> = ({ currentSnippetObject }) => {
		const [disappearClassName, setDisappearClassName] = useState('');
		return (
			<Box
				component="div"
				className={`dialog-AQ-wrapper ${
					isPresentInSavingList(currentSnippetObject.id)
						? 'present-in-saving-list'
						: ''
				} ${disappearClassName}`}
			>
				<DialogAQElement
					changeSnippetInList={changeSnippetInList}
					snippet={currentSnippetObject}
					isPrompt
				/>
				<DialogAQElement
					changeSnippetInList={changeSnippetInList}
					snippet={currentSnippetObject}
					isPrompt={false}
				/>
				<Box
					component="div"
					className={`add-remove-list-for-save ${
						isPresentInSavingList(currentSnippetObject.id)
							? 'present-in-saving-list-btn'
							: ''
					}`}
				>
					<Box component="div" className="add-remove-wrapper">
						<Box
							component="button"
							className={`add-for-save`}
							onClick={() => {
								addSnippetForSave(currentSnippetObject.id);
							}}
							disabled={isPresentInSavingList(currentSnippetObject.id)}
						>
							<AddIcon />
						</Box>
						<Box
							component="button"
							className={`remove-for-save`}
							onClick={() => {
								removeSnippet(currentSnippetObject.id, 'inSavingList');
							}}
							disabled={!isPresentInSavingList(currentSnippetObject.id)}
						>
							<RemoveIcon />
						</Box>
					</Box>
					<Box component="div" className="clear-wrapper">
						<Box
							component="button"
							className="clear-btn"
							onClick={() => {
								setDisappearClassName('dialog-AQ-wrapper-disappear-state');
								setTimeout(() => {
									removeSnippet(currentSnippetObject.id, 'inDialog');
									setDisappearClassName('');
								}, 300);
							}}
						>
							Remove
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	return (
		<StyledDialogWnd theme={theme} className="dialog-wnd" ref={refTag}>
			{snippetsList.map((currentSnippetObject) => (
				<DialogAqWrapper
					// eslint-disable-next-line react/prop-types
					key={currentSnippetObject.id}
					currentSnippetObject={currentSnippetObject}
				/>
			))}
		</StyledDialogWnd>
	);
};
