import React from 'react';
import { StyledCardWrapper } from './CardWrapper.style';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';

interface ICardWrapperProps {
	children: React.ReactNode;
	isFullSize: boolean;
	lazyAppear?: boolean;
	isFullHeight?: boolean;
}

export const CardWrapper: React.FC<ICardWrapperProps> = ({
	children,
	isFullSize,
	lazyAppear,
	isFullHeight,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		isFullSize: isFullSize ? '100%' : '48%',
		maxWidth: isFullSize ? '1250px' : '600px',
		height: isFullHeight === true ? '100%' : 'max-content',
		currentTheme,
	};

	return (
		<StyledCardWrapper
			theme={theme}
			className={`styledCardWrapper ${lazyAppear === true ? 'lazyAppear' : ''}`}
		>
			{children}
		</StyledCardWrapper>
	);
};
