// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialValidImportUrlState {
	isValidDomainImportUrl: boolean;
}

const initialState: IInitialValidImportUrlState = {
	isValidDomainImportUrl: false,
};

const isValidImportUrlInputsSlice = createSlice({
	name: 'isValidImportUrlInputs',
	initialState,
	reducers: {
		setValidStatusDomainImportUrl(state, action: PayloadAction<boolean>) {
			state.isValidDomainImportUrl = action.payload;
			return state;
		},
	},
});

export const { setValidStatusDomainImportUrl } =
	isValidImportUrlInputsSlice.actions;
export const isValidImportUrlInputsReducer =
	isValidImportUrlInputsSlice.reducer;
