// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSelect = styled.div`
	& .MuiFormControl-root {
		& .MuiInputBase-root {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;
			width: 68px;
			height: 40px;
			background: ${(props) => props.theme.currentTheme.cardsBackground};
			border-radius: 8px !important;
			border: ${(props) => props.theme.border};
			outline: none;

			color: ${(props) => props.theme.currentTheme.fontColorInput};
			font-family: 'Nunito';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 100%;
			padding-top: 8px;

			&:hover {
				background: linear-gradient(
							${(props) => props.theme.currentTheme.cardsBackground} 0 0
						)
						padding-box,
					linear-gradient(to right, #43f2ff, #691eff) border-box;
				border: 1px solid transparent;
			}
		}

		& .MuiSvgIcon-root.MuiSelect-icon {
			color: ${(props) => props.theme.currentTheme.fontColorInput};
		}

		& .MuiOutlinedInput-notchedOutline {
			display: none;
		}
	}
`;
