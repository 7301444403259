import React, { useEffect, useState } from 'react';
import {
	Box,
	FormControl,
	MenuItem,
	Select,
	type SelectChangeEvent,
} from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { WrappedInput } from '../../../shared/Input/WrappedInput/WrappedInput';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { StyledSharedActionsViewOnPage } from './SharedActionsViewOnPage.style';
import {
	setStartFetchPage,
	setViewOnPage,
} from '../../../../app/slices/trainingBotMenu/paginationValuesTrainingBotMenuSlice';
import {
	setHistoryStartFetchPage,
	setHistoryViewOnPage,
} from '../../../../app/slices/trainingBotMenu/historyMenu/paginationValuesHistoryMenuSlice';
import { StyledSelect } from './CustomSelect.stlye';

interface ISharedActionsViewOnPageProps {
	currentCountViewOnPage: string;
	uniqueNameOfInput: string;
	extraComponent: JSX.Element;
}

export const SharedActionsViewOnPage: React.FC<
	ISharedActionsViewOnPageProps
> = ({ currentCountViewOnPage, uniqueNameOfInput, extraComponent }) => {
	const temporaryParamsViewOnPage = ['10', '25', '50'];

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const dispatch = useAppDispatch();

	const [windowWidth, setWindowWidth] = useState(0);

	useEffect(() => {
		setWindowWidth(window.innerWidth);
	}, []);
	window.addEventListener('resize', () => {
		setWindowWidth(window.innerWidth);
	});

	const handleChangeViewOnPage = (event: SelectChangeEvent): void => {
		switch (uniqueNameOfInput) {
			case 'viewOnPage-historyRequestMenu':
				dispatch(setHistoryViewOnPage(event.target.value));
				dispatch(setHistoryStartFetchPage(1));
				break;

			case 'viewOnPage-autocomplete':
				dispatch(setViewOnPage(event.target.value));
				dispatch(setStartFetchPage(1));
				break;

			default:
				break;
		}
	};

	const theme = {
		currentTheme,
		border: `1px solid ${currentTheme.borderInputs}`,
	};

	return (
		<StyledSharedActionsViewOnPage
			className="styledSharedActionsViewOnPage"
			theme={theme}
		>
			{extraComponent}
			<Box
				component="span"
				className="viewOnPage-title"
				sx={{ display: windowWidth >= 850 ? 'flex' : 'none' }}
			>
				View on page
			</Box>

			<StyledSelect theme={theme}>
				<FormControl>
					<Select
						labelId={uniqueNameOfInput.concat('-label')}
						id={uniqueNameOfInput}
						value={currentCountViewOnPage}
						onChange={handleChangeViewOnPage}
						autoWidth
						label="Age"
					>
						{temporaryParamsViewOnPage.map((currentCount) => (
							<MenuItem key={currentCount} value={currentCount}>
								{currentCount}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</StyledSelect>
		</StyledSharedActionsViewOnPage>
	);
};
