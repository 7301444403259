import React from 'react';
import { Box } from '@mui/material';
import { StyledSharedAnswer } from './Answer.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';

interface ISharedQuestionProps {
	text: string;
	content: string[];
}

export const SharedAnswer: React.FC<ISharedQuestionProps> = ({
	text,
	content,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	return (
		<StyledSharedAnswer theme={theme} className="sharedAnswer">
			<Box component="div" className="answer-text">
				<Box component="div" className="answer-text-main">
					{text}
				</Box>
				<Box component="div" className="answer-text-content">
					{content.map((item, index) => (
						<Box key={index}>{item}</Box>
					))}
				</Box>
			</Box>
		</StyledSharedAnswer>
	);
};
