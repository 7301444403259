import React from 'react';
import { Box } from '@mui/material';
import { SharedWrappedInputWithExtraContent } from '../../shared/Input/SharedWrappedInputWithExtraContent/SharedWrappedInputWithExtraContent';
import {
	// useAppDispatch,
	useAppSelector,
} from '../../../app/hooks';
import { type RootState } from '../../../app/store';
// import dashedBorder from './pic/dashedBorder.png';
// import dashedBorderMobile from './pic/dashedBorderMobile.png';
// import { CustomCheckbox } from '../../shared/Input/Checkbox/Checkbox';
// import { setActiveStatusOfCheckbox } from '../../../app/slices/isCheckboxActiveSlice';

export const AddCaptchaKeyInputsField: React.FC = () => {
	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForChatGenerator
	);
	const { firstStep } = isButtonPressed;
	const validInputsStatus = useAppSelector(
		(state: RootState) => state.isValidChatGeneratorInputs
	);
	const {
		isValidDomainGeneratorMenu,
		// isValidOpenAIKeyGeneratorMenu,
		// isValidReCaptchaKeyGeneratorMenu,
		// isValidReCaptchaSecretKeyGeneratorMenu,
	} = validInputsStatus;

	const valueInputs = useAppSelector(
		(state: RootState) => state.chatGeneratorInputsValue
	);
	const { domain } = valueInputs;

	// const isCheckboxActive = useAppSelector(
	// 	(state: RootState) => state.isCheckboxActive
	// );

	// const dispatch = useAppDispatch();
	// const handleActiveCheckbox = (): void => {
	// 	dispatch(setActiveStatusOfCheckbox(!isCheckboxActive));
	// };

	return (
		<Box>
			<SharedWrappedInputWithExtraContent
				type="text"
				placeholder="Enter domain/website url/link"
				isEmpty={firstStep}
				uniqueNameOfInput="domain-generator-menu"
				target="empty"
				tip="Enter the website where you want to host the chatbot"
				isDarkBack={false}
				isValid={isValidDomainGeneratorMenu}
				value={domain}
			/>

			{/*  
			<Box
				component="div"
				className="check-coderfy-key-field"
				onClick={handleActiveCheckbox}
			>
				<Box
					component="div"
					className="check-wrapper"
					sx={{
						backgroundImage: {
							xs: `url(${dashedBorderMobile})`,
							sm: `url(${dashedBorder})`,
						},
					}}
				>
					<Box component="div" className="check">
						<CustomCheckbox />
					</Box>
					<Box component="div" className="check-text-field">
						<Box component="div" className="check-text-field-description">
							You can use our keys to test chatbot or input your OpenAI keys and
							reCaptcha key
						</Box>
						<Box component="div" className="check-text-field-title">
							use Coderfy keys
						</Box>
					</Box>
				</Box>
			</Box>

			<SharedWrappedInputWithExtraContent
				type="text"
				placeholder="Enter OpenAI key"
				isEmpty={firstStep}
				uniqueNameOfInput="openAI-key-generator-menu"
				target="https://platform.openai.com/account/api-keys"
				isDarkBack={false}
				isValid={isValidOpenAIKeyGeneratorMenu}
				isDisabled={isCheckboxActive}
			/>
			<SharedWrappedInputWithExtraContent
				type="text"
				placeholder="Enter ReCaptcha key"
				isEmpty={firstStep}
				uniqueNameOfInput="reCaptcha-key-generator-menu"
				target="https://www.google.com/recaptcha/about/"
				isDarkBack={false}
				isValid={isValidReCaptchaKeyGeneratorMenu}
				isDisabled={isCheckboxActive}
			/>
			<SharedWrappedInputWithExtraContent
				type="text"
				placeholder="Enter ReCaptcha secret key"
				isEmpty={firstStep}
				uniqueNameOfInput="reCaptcha-secret-key-generator-menu"
				target="https://www.google.com/recaptcha/about/"
				isDarkBack={false}
				isValid={isValidReCaptchaSecretKeyGeneratorMenu}
				isDisabled={isCheckboxActive}
			/>
		*/}
		</Box>
	);
};
