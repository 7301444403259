import styled from 'styled-components';

export const StyledConfigModelMenu = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	& .styledConfigModelMenu-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;

		& .configs-wrapper {
			display: flex;
			gap: 1rem;
			justify-content: center;

			width: 100%;
			max-width: 1300px;

			& > div {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				width: 50%;
			}

			& .configWrappedInput {
				display: flex;
				flex-direction: column;
				gap: 8px;

				width: 100%;

				& .shared-input {
					width: 100% !important;
				}

				& .configWrappedInput-title {
					color: ${(props) => props.theme.currentTheme.mainFontColor};
				}
			}
		}

		& .checkbox-field {
			display: flex;
			flex-direction: column;
			/* gap: 0.5rem; */
			justify-content: flex-end;
			width: 100%;
			max-width: 1300px;

			& .styledConfigModelMenu-cache-checkbox label {
				display: flex;
				flex-direction: row-reverse;

				& .MuiTypography-root {
					font-family: 'Nunito';
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 27px;
				}
			}
		}

		& .btn-field {
			width: 50%;
			max-width: 1300px;
		}
	}

	@media screen and (max-width: 700px) {
		& .styledConfigModelMenu-wrapper {
			& .configs-wrapper {
				flex-direction: column;
				padding: 0.5rem;
				& > div {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		& .styledConfigModelMenu-wrapper {
			& .configs-wrapper {
				width: 100%;

				& .shared-input {
					width: 100% !important;
				}
			}
		}
	}
`;
