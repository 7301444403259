import React, { useEffect, useState } from 'react';
import { StyledPaymentMethodsMenu } from './PaymentMethodsMenu.style';
import { Box } from '@mui/material';
import {
	useAppDispatch,
	useAppSelector,
	useIsDarkTheme,
} from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import close from '../pic/close.svg';
import closeWhite from '../pic/closeWhite.svg';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import { AddNewPaymentMethodInputsField } from './AddNewPaymentMethodInputsField/AddNewPaymentMethodInputsField';
import { setEmptyStatusForPaymentMethodCardValues } from '../../../app/slices/inputsPaymentMethodCard/checkEmptyStatusForPaymentMethodCardValuesSlice';
import {
	useCreateSubscriptionIntentMutation,
	useGetPaymentMethodsQuery,
	usePurchaseFondyCheckoutMutation,
	usePurchaseFondyRecurrentMutation,
} from '../../../app/api/payment.api/payment.api';
import {
	type ICardsArrayData,
	PaymentMethodsChoosingField,
} from './PaymentMethodsChoosingField/PaymentMethodsChoosingField';
// import { type IAllPaymentMethodsData } from '../../../app/api/payment.api/interfaces/getPaymentMethods.interface';
import { type ICreateSubscriptionIntentRequestBodyWithToken } from '../../../app/api/payment.api/interfaces/createSubscriptionIntent.interface';
import { ProgressDots } from '../../shared/ProgressDots/ProgressDots';
import {
	useDeletePaymentMethodMutation,
	useGetRectokensCardsFondyQuery,
} from '../../../app/api/chatbot.api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FondyPaymentMethodsMenu } from '../FondyPaymentMethodsMenu/FondyPaymentMethodsMenu';
import { ModalConfirmRecurrentPaymentComponent } from './ModalConfirmRecurrentPaymentComponent/ModalConfirmRecurrentPaymentComponent';
import {
	setCurrentPaymentMethodDataForDelete,
	setCurrentPaymentMethodIdForDelete,
	setStatusOpenModalDelete,
} from '../../../app/slices/paymentMethodsMenu/deletePaymentMethodsMenuSlice';
import { type IGetRectokensCards } from '../../../app/api/interfaces/fondy/getRectokensCardsFondy.interface';
// import { type IGetRectokensCards } from '../../../app/api/interfaces/fondy/getRectokensCardsFondy.interface';
// import { esAR } from 'rsuite/esm/locales';
// import { FondyPaymentMethodsMenu } from '../FondyPaymentMethodsMenu/FondyPaymentMethodsMenu';

interface IPaymentMethodsMenuProps {
	closeFn: () => void;
	refetchFn: () => Promise<void>;
	currentPlanName: string;
	currentPrice: string;
	priceId: string;
	isFondy: boolean;
}

export const PaymentMethodsMenu: React.FC<IPaymentMethodsMenuProps> = ({
	closeFn,
	refetchFn,
	currentPlanName,
	currentPrice,
	priceId,
	isFondy,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		currentTheme,
	};

	const validCardInputsStatus = useAppSelector(
		(state: RootState) => state.isValidPaymentMethodCardValues
	);
	const { isValidCardNumber, isValidMonth, isValidYear, isValidCVC } =
		validCardInputsStatus;

	const valueInputs = useAppSelector(
		(state: RootState) => state.paymentMethodCardValue
	);
	const { cardNumber, month, year, cvc } = valueInputs;

	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const isCheckedAddNewCard = useAppSelector(
		(state: RootState) => state.paymentMethodsMenu.isCheckedAddNewCardOption
	);
	const currentPaymentMethodId = useAppSelector(
		(state: RootState) => state.paymentMethodsMenu.currentPaymentMethodId
	);

	const isDarkTheme = useIsDarkTheme();

	const dispatch = useAppDispatch();

	// --- STRIPE LOGIC ---
	const [allPaymentMethodsData, setAllPaymentMethodsData] =
		useState<ICardsArrayData[]>();
	const {
		data: dataGetPaymentMethods,
		isLoading: isLoadingGetPaymentMethods,
		isError: isErrorGetPaymentMethods,
		isSuccess: isSuccessGetPaymentMethods,
		// refetch: refetchPaymentMethods,
	} = useGetPaymentMethodsQuery(token);

	const [openModalConfirmRecurrentFondy, setOpenModalConfirmRecurrentFondy] =
		useState(false);

	const modalDeletePaymentMethodData = useAppSelector(
		(state: RootState) => state.deletePaymentMethodsMenu
	);
	const {
		isOpenModalDeletePaymentMethod,
		currentPaymentMethodData,
		currentPaymentMethodId: currentPaymentMethodIdForDelete,
	} = modalDeletePaymentMethodData;

	// --- FONDY LOGIC ---
	const [allPaymentMethodsDataFondy, setAllPaymentMethodsDataFondy] =
		useState<ICardsArrayData[]>();
	const {
		data: dataGetRectokensCardsFondy,
		isLoading: isLoadingGetRectokensCardsFondy,
		isError: isErrorGetRectokensCardsFondy,
		isSuccess: isSuccessGetRectokensCardsFondy,
		refetch: refetchPaymentMethodsFondy,
	} = useGetRectokensCardsFondyQuery({ token });

	// --- FONDY LOGIC ---
	const [
		getTokenForPurchaseFondyRecurrent,
		resultOfGetTokenForPurchaseFondyRecurrent,
	] = usePurchaseFondyRecurrentMutation();
	const [
		getTokenForPurchaseFondyCheckout,
		resultOfGetTokenForPurchaseFondyCheckout,
	] = usePurchaseFondyCheckoutMutation();
	const [tokenForPurchaseFondy, setTokenForPurchaseFondy] =
		useState<string>('');

	// FONDY DELETE PAYMENT METHOD
	const [executeDeletionPaymentMethod, resultDeletionPaymentMethod] =
		useDeletePaymentMethodMutation();
	const [
		loadingErrorContentDeletionPaymentMethod,
		setLoadingErrorContentDeletionPaymentMethod,
	] = useState<JSX.Element | null>(null);

	// Function to set payment methods list
	const updatePaymentMethodList = (
		allPaymentMethodsDataFondy: IGetRectokensCards[]
	): void => {
		const newMutatedArray: ICardsArrayData[] = allPaymentMethodsDataFondy.map(
			(currentCardData) => {
				return {
					last4: currentCardData.masked_card.substring(
						currentCardData.masked_card.length - 4
					),
					brand: currentCardData.card_type,
					id: currentCardData.id.toString(),
				};
			}
		);
		setAllPaymentMethodsDataFondy(newMutatedArray);
	};

	useEffect(() => {
		if (!isFondy && isSuccessGetPaymentMethods) {
			const allPaymentMethodsDataStripe = dataGetPaymentMethods.data;
			const newMutatedArray: ICardsArrayData[] =
				allPaymentMethodsDataStripe.map((currentCardData) => {
					return {
						last4: currentCardData.card.last4,
						brand: currentCardData.card.brand,
						id: currentCardData.id.toString(),
					};
				});
			setAllPaymentMethodsData(newMutatedArray);
		} else if (isFondy && isSuccessGetRectokensCardsFondy) {
			const allPaymentMethodsDataFondy = dataGetRectokensCardsFondy.result;
			updatePaymentMethodList(allPaymentMethodsDataFondy);
		}
	}, [dataGetPaymentMethods, isSuccessGetPaymentMethods]);

	useEffect(() => {
		dispatch(setEmptyStatusForPaymentMethodCardValues(false));
		setLoadingErrorContentDeletionPaymentMethod(null);
	}, []);

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);
	const [
		loadingErrorContentForModalAfterRecurrentPayment,
		setLoadingErrorContentForModalAfterRecurrentPayment,
	] = useState<JSX.Element | null>(null);
	const [isOpenModalAfterRecurrentPayment, setOpenModalAfterRecurrentPayment] =
		useState(false);

	const [createSubscription, createSubscriptionResult] =
		useCreateSubscriptionIntentMutation();

	const createSubscriptionExecute = async (
		subscriptionIntentRequestBodyWithToken: ICreateSubscriptionIntentRequestBodyWithToken
	): Promise<void> => {
		try {
			const responseSubscription = await createSubscription(
				subscriptionIntentRequestBodyWithToken
			);
			if ('data' in responseSubscription) {
				window.open(
					responseSubscription.data.latest_invoice.hosted_invoice_url,
					'_blank'
				);
				closeFn();

				// Handle refetch functions after all
				await refetchFn();
			} else if ('error' in responseSubscription) {
				console.log(responseSubscription.error);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// --- STRIPE LOGIC ---
	const handlePressPayButton = async (): Promise<void> => {
		dispatch(setEmptyStatusForPaymentMethodCardValues(true));

		const paymentSubscriptionBody = isCheckedAddNewCard
			? {
					priceId,
					number: cardNumber.toString(),
					expMonth: Number.parseInt(month),
					expYear: Number.parseInt('20'.concat(year)),
					cvc,
			  }
			: {
					priceId,
					paymentMethodId: currentPaymentMethodId,
			  };

		const subscriptionIntentRequestBodyWithToken = {
			token,
			subscriptionIntentRequestBody: paymentSubscriptionBody,
		};

		if (!isCheckedAddNewCard) {
			void createSubscriptionExecute(subscriptionIntentRequestBodyWithToken);
		} else {
			if (
				cardNumber.length === 16 &&
				month.length > 0 &&
				year.length > 0 &&
				cvc.length === 3 &&
				isValidCardNumber &&
				isValidMonth &&
				isValidYear &&
				isValidCVC
			) {
				void createSubscriptionExecute(subscriptionIntentRequestBodyWithToken);
			} else {
				console.log('not valid');
			}
		}
	};

	useEffect(() => {
		if (createSubscriptionResult.isError) {
			setLoadingErrorContent(
				<Box
					component="div"
					className="error-message-from-api"
					sx={{ color: 'red !important' }}
				>
					Something went wrong
				</Box>
			);
		} else if (createSubscriptionResult.isSuccess) {
			setLoadingErrorContent(null);
		}
	}, [createSubscriptionResult]);

	useEffect(() => {
		setTokenForPurchaseFondy('');
		setLoadingErrorContentForModalAfterRecurrentPayment(null);
		setOpenModalAfterRecurrentPayment(false);
	}, []);

	const executePaymentRecurrentFondy = async (): Promise<void> => {
		const purchaseBodyRecurrent = {
			order_desc: `Purchasing plan "${currentPlanName}" for $${currentPrice}`,
			productId: parseInt(priceId),
			rectokenId: parseInt(currentPaymentMethodId),
		};
		try {
			const result = await getTokenForPurchaseFondyRecurrent({
				purchaseInfo: purchaseBodyRecurrent,
				token,
			});
			if ('data' in result) {
				await refetchFn();
				setLoadingErrorContentForModalAfterRecurrentPayment(
					<Box
						component="div"
						className="message-from-api"
						sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
					>
						<Box>Status: {result.data.response.response_status}</Box>
						<Box>Order id: {result.data.response.order_id}</Box>
					</Box>
				);
			} else {
				setLoadingErrorContentForModalAfterRecurrentPayment(
					<Box
						component="div"
						className="error-message-from-api"
						sx={{ color: 'red !important' }}
					>
						Something went wrong
					</Box>
				);
			}
			setOpenModalAfterRecurrentPayment(true);
		} catch (error) {
			setLoadingErrorContent(
				<Box
					component="div"
					className="error-message-from-api"
					sx={{ color: 'red !important' }}
				>
					Something went wrong
				</Box>
			);
		}
	};

	// --- FONDY LOGIC ---
	const handlePressPayButtonForFondy = async (): Promise<void> => {
		if (isCheckedAddNewCard) {
			// LOGIC NEW CARD
			const purchaseBodyCheckout = {
				order_desc: `Purchasing plan "${currentPlanName}" for $${currentPrice}`,
				productId: parseInt(priceId),
			};
			try {
				const result = await getTokenForPurchaseFondyCheckout({
					purchaseInfo: purchaseBodyCheckout,
					token,
				});
				if ('data' in result) {
					setTokenForPurchaseFondy(result.data.response.token);
				} else {
					setLoadingErrorContent(
						<Box
							component="div"
							className="error-message-from-api"
							sx={{ color: 'red !important' }}
						>
							Something went wrong
						</Box>
					);
				}
			} catch (error) {
				setLoadingErrorContent(
					<Box
						component="div"
						className="error-message-from-api"
						sx={{ color: 'red !important' }}
					>
						Something went wrong
					</Box>
				);
			}
		} else if (!isCheckedAddNewCard && currentPaymentMethodId !== '') {
			// LOGIC CURRENT CARD
			setOpenModalConfirmRecurrentFondy(true);
			// const
			// getTokenForPurchaseFondyRecurrent
		} else {
			setLoadingErrorContent(
				<Box
					component="div"
					className="error-message-from-api"
					sx={{ color: 'red !important' }}
				>
					Please, chose a payment method
				</Box>
			);
		}
	};

	useEffect(() => {
		const resRec = resultOfGetTokenForPurchaseFondyRecurrent;
		const resCheck = resultOfGetTokenForPurchaseFondyCheckout;
		if (resRec.isLoading || resCheck.isLoading) {
			setLoadingErrorContent(<ProgressDots />);
		} else if (resRec.isError || resCheck.isError) {
			setLoadingErrorContent(
				<Box
					component="div"
					className="error-message-from-api"
					sx={{ color: 'red !important' }}
				>
					Something went wrong
				</Box>
			);
		} else if (resRec.isSuccess || resCheck.isSuccess) {
			setLoadingErrorContent(null);
		}
	}, [
		resultOfGetTokenForPurchaseFondyRecurrent,
		resultOfGetTokenForPurchaseFondyCheckout,
	]);

	const PaymentMethodsContent: React.FC = () => {
		if (isFondy) {
			if (isLoadingGetRectokensCardsFondy) {
				return <ProgressDots />;
			} else if (isErrorGetRectokensCardsFondy) {
				return <Box>Something went wrong.</Box>;
			} else {
				return (
					<PaymentMethodsChoosingField
						isFondy={isFondy}
						cardsArray={
							allPaymentMethodsDataFondy !== undefined
								? allPaymentMethodsDataFondy
								: []
						}
					/>
				);
			}
		} else {
			if (isLoadingGetPaymentMethods) {
				return <ProgressDots />;
			} else if (isErrorGetPaymentMethods) {
				return <Box>Something went wrong.</Box>;
			} else {
				return (
					<PaymentMethodsChoosingField
						isFondy={isFondy}
						cardsArray={
							allPaymentMethodsData !== undefined ? allPaymentMethodsData : []
						}
					/>
				);
			}
		}
	};

	const handleCloseAndRefetch = async (): Promise<void> => {
		await refetchFn();
		closeFn();
	};

	const handleDeletePaymentMethod = async (): Promise<void> => {
		const result = await executeDeletionPaymentMethod({
			token,
			id: currentPaymentMethodIdForDelete,
		});
		if ('data' in result) {
			dispatch(setStatusOpenModalDelete(false));
			dispatch(setCurrentPaymentMethodIdForDelete(''));
			dispatch(setCurrentPaymentMethodDataForDelete(''));
			const resultRefetchingPaymentMethodsFondy =
				await refetchPaymentMethodsFondy();
			if (resultRefetchingPaymentMethodsFondy.data !== undefined) {
				updatePaymentMethodList(
					resultRefetchingPaymentMethodsFondy.data.result
				);
			}
		} else if ('error' in result) {
			setLoadingErrorContentDeletionPaymentMethod(
				<Box
					component="div"
					className="error-message-from-api"
					sx={{ color: 'red !important' }}
				>
					Something went wrong
				</Box>
			);
		}
	};

	useEffect(() => {
		if (resultDeletionPaymentMethod.isError) {
			setLoadingErrorContentDeletionPaymentMethod(
				<Box
					component="div"
					className="error-message-from-api"
					sx={{ color: 'red !important' }}
				>
					Something went wrong
				</Box>
			);
		} else if (resultDeletionPaymentMethod.isLoading) {
			setLoadingErrorContentDeletionPaymentMethod(<ProgressDots />);
		} else {
			setLoadingErrorContentDeletionPaymentMethod(null);
		}
	}, [resultDeletionPaymentMethod]);

	const refetchAfterPurchasePresentCard = async (): Promise<void> => {
		await refetchFn();
		const resultRefetchingPaymentMethodsFondy =
			await refetchPaymentMethodsFondy();
		if (resultRefetchingPaymentMethodsFondy.data !== undefined) {
			updatePaymentMethodList(resultRefetchingPaymentMethodsFondy.data.result);
		}
	};

	useEffect(() => {
		void refetchAfterPurchasePresentCard();
	}, [currentPlanName]);

	return (
		<StyledPaymentMethodsMenu
			theme={theme}
			className="styledPaymentMethodsMenu"
		>
			<Box component="div" className="closePic-wrapper">
				<Box
					component="img"
					className="closePic"
					alt="closePic"
					src={isDarkTheme ? closeWhite : close}
					onClick={() => {
						void handleCloseAndRefetch();
					}}
				/>
			</Box>
			<Box component="div" className="paymentMethodsMenu-title">
				Upgrade Plan
			</Box>
			<Box component="div" className="paymentMethodsMenu-current-plan-area">
				<Box component="div" className="current-plan">
					Plan {currentPlanName}
				</Box>
				<Box component="div" className="current-price">
					$ {currentPrice}
				</Box>
			</Box>
			<Box component="div" className="paymentMethodsMenu-payment-methods-area">
				<Box
					component="div"
					className="paymentMethodsMenu-payment-methods-choosing-field"
				>
					<Box
						component="div"
						className="paymentMethodsMenu-payment-methods-choosing-title"
					>
						Payment Method
					</Box>
					<PaymentMethodsContent />
				</Box>
				<Box
					component="div"
					className="paymentMethodsMenu-payment-methods-add-new-field"
				>
					{isCheckedAddNewCard && !isFondy ? (
						<AddNewPaymentMethodInputsField />
					) : tokenForPurchaseFondy !== '' && isFondy ? (
						<FondyPaymentMethodsMenu
							currentPlanName={currentPlanName}
							currentPrice={currentPrice}
							priceId={priceId}
							closeFn={() => {
								void handleCloseAndRefetch();
							}}
							refetchFn={refetchAfterPurchasePresentCard}
							tokenPaymentFondy={tokenForPurchaseFondy}
						/>
					) : (
						<></>
					)}
				</Box>
			</Box>
			<Box component="div" className="paymentMethodsMenu-buttons-area">
				<Box
					component="div"
					className="paymentMethodsMenu-buttons-area-loadingContent"
				>
					{loadingErrorContent}
				</Box>
				<Box component="div" className="button-small">
					<SharedMainButton
						text="Pay"
						buttonFunction={() => {
							if (isFondy) {
								void handlePressPayButtonForFondy();
							} else {
								void handlePressPayButton();
							}
						}}
						isDeleteButton={false}
						inProgress={
							isFondy
								? resultOfGetTokenForPurchaseFondyRecurrent.isLoading ||
								  resultOfGetTokenForPurchaseFondyCheckout.isLoading
								: createSubscriptionResult.isLoading
						}
					/>
				</Box>
			</Box>
			{openModalConfirmRecurrentFondy ? (
				<SharedModal
					closeFn={() => {
						setOpenModalConfirmRecurrentFondy(false);
					}}
					executeFn={() => {}}
					message={null}
					title=""
					isOnlyAlert={false}
					standaloneFC={
						<ModalConfirmRecurrentPaymentComponent
							executePaymentRecurrentFondy={executePaymentRecurrentFondy}
							handleCloseModal={() => {
								setOpenModalConfirmRecurrentFondy(false);
							}}
							handleOpenModalAfterRecurrent={() => {
								setOpenModalAfterRecurrentPayment(true);
							}}
						/>
					}
				/>
			) : (
				<></>
			)}
			{isOpenModalAfterRecurrentPayment ? (
				<SharedModal
					closeFn={() => {
						setOpenModalAfterRecurrentPayment(false);
					}}
					executeFn={() => {}}
					message={loadingErrorContentForModalAfterRecurrentPayment}
					title=""
					isOnlyAlert
				/>
			) : (
				<></>
			)}
			{isOpenModalDeletePaymentMethod ? (
				<SharedModal
					closeFn={() => {
						dispatch(setStatusOpenModalDelete(false));
					}}
					executeFn={() => {
						void handleDeletePaymentMethod();
					}}
					message={loadingErrorContentDeletionPaymentMethod}
					title={`Are you sure you want to delete payment method: ${currentPaymentMethodData}?`}
					isOnlyAlert={false}
					isDeleteModal
				/>
			) : (
				<></>
			)}
		</StyledPaymentMethodsMenu>
	);
};

interface IPaymentMethodsMenuInModalProps {
	closeFn: () => void;
	refetchFn: () => Promise<void>;
	currentPlanName: string;
	currentPrice: string;
	priceId: string;
	isFondy?: boolean;
}

export const PaymentMethodsMenuInModal: React.FC<
	IPaymentMethodsMenuInModalProps
> = ({
	closeFn,
	refetchFn,
	currentPlanName,
	currentPrice,
	priceId,
	isFondy,
}) => {
	// if (isFondy === true) {
	// 	return (
	// 		<FondyPaymentMethodsMenu
	// 			currentPlanName={currentPlanName}
	// 			currentPrice={currentPrice}
	// 			priceId={priceId}
	// 			closeFn={closeFn}
	// 			refetchFn={refetchFn}
	// 		/>
	// 	);
	// } else {
	return (
		<SharedModal
			closeFn={closeFn}
			executeFn={() => {}}
			message={null}
			title=""
			isOnlyAlert={false}
			standaloneFC={
				<PaymentMethodsMenu
					currentPlanName={currentPlanName}
					currentPrice={currentPrice}
					priceId={priceId}
					closeFn={closeFn}
					refetchFn={refetchFn}
					// FONDY LOGIC
					isFondy={isFondy === true}
				/>
			}
		/>
	);
	// }
};
