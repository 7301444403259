/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledPersonalCabinetMenu = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 6rem 4rem;
	background: #0e172d;
	color: ${(props) => props.theme.mainFontColor};

	& .personal-cabinet-wrapper {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: 1.5rem;

		& .personal-cabinet-title-subtitle {
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
			gap: 0.5rem;

			& .personal-cabinet-title,
			.personal-cabinet-subtitle {
				color: #fff;
				font-family: 'Play';
				text-align: center;
			}

			& .personal-cabinet-title {
				font-size: 50px;
				hyphens: manual;
				line-height: 48px;
			}
		}

		& .personal-cabinet-menu {
			display: flex;
			width: 100%;
			gap: 2rem;
			/* flex-wrap: wrap; */
			/* justify-content: center; */

			align-items: center;
			flex-direction: column;

			& > div {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				gap: 2rem;
				width: 100%;
			}

			& .personal-cabinet-middle-vidgets {
				& .unitedComponent {
					display: flex;
					justify-content: center;
					gap: 1rem;
					width: 100%;
				}
			}

			& .personal-cabinet-profiles-wrapper {
				& .in-wrapper-table {
					display: flex;
					flex-direction: column;
					gap: 1rem;

					& > div {
						display: flex;
						justify-content: center;
						align-items: center;
						text-align: center;
					}
				}
			}

			& .personal-cabinet-info-wrapper {
				& .personal-cabinet-info-button {
					margin-top: 3rem;
				}

				& .personal-cabinet-info-value {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	@media screen and (min-width: 2000px) {
		padding: 6rem 2rem;

		& .personal-cabinet-wrapper {
			& .personal-cabinet-menu {
				& .personal-cabinet-profiles-wrapper,
				.personal-cabinet-info-wrapper {
					/* min-height: 450px; */
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		padding: 6rem 2rem;

		& .personal-cabinet-wrapper {
			& .personal-cabinet-menu {
				gap: 1rem;

				& > div {
					gap: 1rem;
				}

				& .in-wrapper-title {
					font-size: 18px;
					line-height: 22px;
				}
			}
		}
	}

	@media screen and (max-width: 800px) {
		& .personal-cabinet-wrapper {
			& .personal-cabinet-menu {
				& .personal-cabinet-middle-vidgets {
					& .unitedComponent {
						flex-direction: column;
						align-items: center;

						& > div {
							width: 100% !important;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		padding: 6rem 0.5rem;
	}
`;
