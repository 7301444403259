// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSharedOneActionComponent = styled.div`
	width: 100%;

	& .menu-wrapper {
		& .menu-inputs-area {
			max-width: 470px;
			gap: 1rem;

			& .forgotReset-inputs-field {
				width: 80%;
				align-self: end;
				align-self: center;

				& .inputs-field-wrapper {
					align-items: center !important;
				}
			}

			& .forgotReset-create-under-button {
				display: flex;
				text-align: center;
			}

			& .error-message-from-api {
				font-weight: 400;
				font-size: 18px;
				line-height: 145%;
				color: #1592ec;
				text-align: center;
			}

			& .error-message-from-api.error-alert {
				color: #b14d56;
			}

			& .forgotReset-button {
				display: flex;
				justify-content: center;
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		& .menu-wrapper {
			flex-direction: column;

			& .menu-inputs-area {
				max-width: 550px;
				width: 70vmin;
				& .forgotReset-inputs-field {
					align-self: center;
					width: auto;

					& .forgotReset-titles-field {
						margin-bottom: 2rem;
					}
				}
			}
		}
	}

	@media screen and (max-width: 600px) {
		& .shared-title {
			font-size: 1.5rem !important;
		}
		& .shared-subtitle {
		}
	}

	@media screen and (max-width: 500px) {
		& .menu-wrapper {
			& .menu-inputs-area {
				width: 90vmin;
			}

			& .forgotReset-button {
				width: 85vw !important;
			}
		}
	}

	@media screen and (max-width: 350px) {
		& .shared-title {
			font-size: 1rem !important;
			line-height: 16px;
		}
		& .shared-subtitle {
			font-size: 0.8rem !important;
			line-height: 16px;
		}
	}
`;
