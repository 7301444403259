import React from 'react';
import { type IListLinks } from '../Navbar';
import { NavLink, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { StyledNavListItem } from './NavlistItem.style';
import { ReactComponent as DownWhite } from '../pic/DownWhite.svg';
import { useAppDispatch } from '../../../app/hooks';
import { hideMenu } from '../../../app/slices/showHideMenuSlice';

interface INavListItemProps {
	navListItem: IListLinks;
	executeCloseMobileFunction: () => void;
}

export const NavListItem: React.FC<INavListItemProps> = ({
	navListItem,
	executeCloseMobileFunction,
}) => {
	const {
		dropList,
		isDropDownListOpen,
		toggleDropDownStatusFunction,
		isDropDownList,
		value,
		link,
		logo,
	} = navListItem;

	const { pathname } = useLocation();

	const theme = {
		colorLinks: '#D7E2F2',
		weight:
			pathname === link &&
			value !== 'FAQ' &&
			value !== 'Social Media Integration'
				? '600'
				: '300',
	};

	const dispatch = useAppDispatch();
	const executeFunctionWithHideNavbar = (): void => {
		if (!isDropDownList) {
			dispatch(hideMenu());
			executeCloseMobileFunction();
		}
	};

	const NavListLogoAndValue: React.FC = () => (
		<Box component="div" onClick={executeFunctionWithHideNavbar}>
			<Box component="div" className="nav-list-icon">
				{logo}
			</Box>
			<Box component="div" className="nav-list-value">
				{value}
			</Box>
		</Box>
	);

	const NavListDropDownList: React.FC = () => (
		<Box
			component="div"
			className="nav-list-drop"
			onMouseEnter={(e) => {
				e.preventDefault();
			}}
			onClick={(e) => {
				e.preventDefault();
			}}
		>
			{isDropDownList && (
				<Box component="button">
					{isDropDownListOpen === false ? (
						<DownWhite />
					) : (
						<DownWhite style={{ transform: 'rotate(90deg)' }} />
					)}
				</Box>
			)}
		</Box>
	);

	const executeFunctionWithCloseMobileFunctionIfDroppedList = (): void => {
		if (isDropDownList) {
			toggleDropDownStatusFunction?.();
		} else {
			executeCloseMobileFunction();
		}
	};

	const LinkWrapper: React.FC = () => (
		<NavLink
			to={link}
			onClick={executeFunctionWithCloseMobileFunctionIfDroppedList}
		>
			<NavListLogoAndValue />
			<NavListDropDownList />
		</NavLink>
	);

	return (
		<StyledNavListItem className="nav-list-item-wrapper" theme={theme}>
			<LinkWrapper />

			<Box component="div" className="nav-list-drop-list">
				{isDropDownListOpen === true &&
					dropList?.map((item) => {
						return (
							<NavLink
								to={item.link}
								key={item.value}
								onClick={() => {
									dispatch(hideMenu());
									executeCloseMobileFunction();
								}}
								style={{ fontWeight: item.link === pathname ? '600' : '300' }}
							>
								{item.value}
							</NavLink>
						);
					})}
			</Box>
		</StyledNavListItem>
	);
};
