/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledDetailedBotCard = styled.div`
	/* comment after replace in card */
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	/* min-height: 100vh;
	background: #0e172d;
	padding: 170px 0px !important; */

	& .bot-card-wrapper-border {
		/* comment after replace in card */
		width: 100%;
		/* width: 90%;
		max-width: 1300px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background: ${(props) =>
			`linear-gradient(${
				props.theme.currentTheme.mainRootBackground === '#1A1D21'
					? '90deg'
					: '180deg'
			}, ${props.theme.currentTheme.cardsGradient.one}, ${
				props.theme.currentTheme.cardsGradient.two
			})`};
		padding: 1px !important; */

		& .bot-card-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			border-radius: 30px;
			background: ${(props) => props.theme.currentTheme.cardsBackground};

			justify-content: space-between;
			gap: 24px;
			padding: 40px;

			& .bot-card-info-field {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				/* width: 100%; */
			}

			& .bot-card-info-wrapper {
				display: flex;
				width: 100%;
				gap: 1.5rem;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				& .wrapper-for-all-bot-info {
					display: flex;
					width: 100%;
					justify-content: center;
					flex-direction: row;
					gap: 1rem;
					justify-content: center;
					align-items: center;

					& .bot-info-domain-icon-name {
						padding: 24px;
						border-radius: 30px;
						border: 1px solid
							${(props) => props.theme.currentTheme.borderInMenu};
						height: 522px;
						/* min-height: 450px; */
						/* max-height: 520px; */
						/* min-height: 400px; */
						/* max-height: max-content; */

						min-width: 400px;
						width: 50%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						/* align-items: flex-start; */

						& .information-about-bot {
							width: 100%;
							justify-content: space-between;
							display: flex;
							flex-direction: column;
							/* align-items: flex-start; */
							gap: 1rem;
							max-width: 550px;

							& .cardInfoItem {
								width: 100%;
							}

							& .in-wrapper-title {
								color: ${(props) => props.theme.currentTheme.mainFontColor};
								padding-bottom: 0.5rem;
								font-style: normal;
								font-weight: 400;
								font-size: 18px;
								line-height: 145%;
							}

							& .bot-card-info-value {
								display: flex;
								/* border: 1px solid
									${(props) => props.theme.currentTheme.borderInputs}; */
								/* color: ${(props) =>
									props.theme.currentTheme.mainFontColor}; */
								font-style: normal;
								font-weight: 400;
								font-size: 20px;
								line-height: 100%;
								border-radius: 8px;
								width: 100%;
								align-items: center;
								padding: 24px;
								gap: 4px;
								hyphens: auto;
								word-wrap: break-word;

								color: ${(props) => props.theme.currentTheme.disabledColor};
								background: ${(props) =>
									props.theme.currentTheme.disabledBackground};
								border: 1px solid
									${(props) => props.theme.currentTheme.disabledBorder};
							}
							& .bot-card-info-value-changeableInput > div input {
								width: 100%;
							}

							&
								.bot-card-info-value-changeableInput
								> div
								.MuiAutocomplete-root {
								width: 100%;
							}
						}

						& .checkbox-wrapper {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 2rem 1rem 0px 1rem;

							& label {
								margin: 0px;
								text-align: center;

								& span {
									font-family: 'Nunito' !important;
									color: ${(props) => props.theme.currentTheme.mainFontColor};
								}
							}
						}
					}

					& .bot-card-info {
						display: flex;
						width: 100%;
						justify-content: center;
						flex-direction: row;
						gap: 1rem;
						justify-content: center;
						align-items: center;

						padding: 24px;
						border-radius: 30px;
						border: 1px solid
							${(props) => props.theme.currentTheme.borderInMenu};

						min-height: 450px;
						max-height: 540px;
						/* min-height: 400px;
						height: 100%;
						max-height: max-content; */

						& .cardInfoItem {
							width: 100%;
							display: flex;
							flex-direction: column;
							align-self: center;
						}

						& > div {
							padding: 0.5rem;
							display: flex;
							gap: 1rem;
							flex-direction: column;
						}

						& .information-about-bot {
							width: 50%;
							justify-content: space-between;
							max-width: 550px;

							display: flex;

							& .in-wrapper-title {
								color: ${(props) => props.theme.currentTheme.mainFontColor};
								padding-bottom: 0.5rem;
								font-style: normal;
								font-weight: 400;
								font-size: 18px;
								line-height: 145%;
							}

							& .bot-card-info-value {
								display: flex;
								border: 1px solid
									${(props) => props.theme.currentTheme.borderInputs};
								color: ${(props) => props.theme.currentTheme.mainFontColor};
								font-style: normal;
								font-weight: 400;
								font-size: 20px;
								line-height: 100%;
								border-radius: 8px;
								width: 100%;
								align-items: center;
								padding: 24px;
								gap: 4px;
								hyphens: auto;
								word-wrap: break-word;
							}

							& .bot-card-info-value-changeableInput > div input {
								width: 100%;
							}

							& .bot-card-info-value-changeableInput {
								display: flex;
								justify-content: center;

								& > div {
									width: 100%;
									align-items: center;
								}
							}

							&
								.bot-card-info-value-changeableInput
								> div
								.MuiAutocomplete-root {
								width: 100%;
							}

							&
								.bot-card-info-value-changeableInput
								> div
								.MuiAutocomplete-root {
								width: 100%;
							}

							& .bot-card-info-value-changeableInput > div > div {
								width: 100%;
							}
						}

						& > .visualization-bot {
							/* width: 40%;
							height: 416px; */
							min-width: 300px;
							width: 40%;
							display: flex;
							justify-content: center;
							align-items: center;
							/* PREVIEW */
							/* height: 350px; */
							height: 450px;

							& > img {
								height: 100%;
								width: auto;
								max-width: 400px;
								background: grey;
								border-radius: 20px;
								border: 1px solid
									${(props) => props.theme.currentTheme.mainFontColor};
							}

							& > div {
								width: 100%;
								height: 100%;
								background: grey;
								border-radius: 20px;
								display: flex;
								justify-content: center;
								align-items: center;
								font-style: normal;
								font-weight: 500;
								font-size: 26px;
								line-height: 140%;
								color: #fff;
								position: relative;
								z-index: 700;
								overflow: hidden;

								&::after {
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									transform: translateX(-100%);
									background-image: linear-gradient(
										90deg,
										rgba(#fff, 0) 0,
										rgba(#fff, 0.2) 20%,
										rgba(#fff, 0.5) 60%,
										rgba(#fff, 0)
									);
									animation: shimmer 2s infinite;
									content: '';
									z-index: 800;
									background: #ffffff10;
									filter: blur(50px);
								}
							}
						}
					}
				}
			}

			& .title-code {
				color: ${(props) => props.theme.currentTheme.mainFontColor};
				font-weight: 600;
				font-size: 22px;
				line-height: 100%;
				font-style: normal;
			}
		}

		& .bot-card-buttons {
			display: flex;
			flex-direction: row;
			justify-content: center;
			width: 70%;
		}
	}

	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}

	@media screen and (max-width: 1800px) {
		& .bot-card-wrapper {
			& .bot-card-info-field {
				& .bot-card-info-wrapper {
					& .wrapper-for-all-bot-info {
						& .bot-info-domain-icon-name {
							width: 300px;
							min-width: 300px;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		/* comment after replace in card */
		/* padding: 120px 0px !important; */

		& .bot-card-wrapper-border {
			& .bot-card-wrapper {
				padding: 16px;

				& .bot-card-info-field {
					& .bot-card-info-wrapper {
						& .wrapper-for-all-bot-info {
							& .bot-info-domain-icon-name {
								/* min-height: 466px; */
								height: 450px;

								& .information-about-bot {
									& .in-wrapper-title {
										font-size: 16px;
									}

									& .bot-card-info-value {
										font-size: 16px;
										padding: 16px;
									}
								}
							}
						}

						& .bot-card-info {
							& .information-about-bot {
								& .in-wrapper-title {
									font-size: 16px;
								}

								& .bot-card-info-value {
									font-size: 16px;
									padding: 16px;
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1300px) {
	}

	@media screen and (max-width: 1250px) {
		& .bot-card-wrapper-border {
			& .bot-card-wrapper {
				& .bot-card-info-field {
					& .bot-card-info-wrapper {
						& .wrapper-for-all-bot-info {
							flex-direction: column;

							& .bot-info-domain-icon-name {
								/* min-height: 466px; */

								height: max-content;
								min-height: max-content;
								width: 100%;
							}
						}

						& .bot-card-info {
							flex-direction: column;
							height: max-content;
							max-height: max-content;

							& .information-about-bot {
								width: 100%;

								& .bot-card-info-value {
								}
							}
							& > .visualization-bot {
								width: 100%;
								height: auto;

								& > div {
									min-height: 400px;
								}
							}
						}
					}
					& .bot-card-buttons {
						width: 100%;
					}
				}
			}
		}
	}

	@media screen and (max-width: 550px) {
		/* comment after replace in card */
		/* padding: 4rem 0.5rem; */
		& .bot-card-wrapper {
			flex-direction: column;
			/* comment after replace in card */
			padding: 0px !important;

			& .bot-card-info-field {
				width: 100%;
				& .bot-card-info-wrapper {
					& .bot-card-info {
						& .visualization-bot {
							width: 100%;
							height: auto;
							min-width: 0px;
						}
					}
					& .bot-card-script {
						padding: 2rem 0.8rem;
					}
				}
			}

			& .bot-card-info {
				& .information-about-bot {
					& .cardInfoItem {
						width: 100%;
					}
				}
			}
		}
	}
`;
