import styled from 'styled-components';

export const StyledPersonalUserCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 300px;
	height: 100%;

	& .in-wrapper-table {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: max-content;
		align-self: center;
		margin: auto;

		& .personal-cabinet-info-value {
		}

		& .personal-cabinet-info-value {
			/* text-align: right; */
			hyphens: auto;
			word-wrap: anywhere;
			font-size: 16px;
			width: 70%;
		}

		& > div {
			display: flex;
			/* justify-content: space-between; */
			width: 100%;
			gap: 1rem;
		}

		& .personal-cabinet-info-article {
			width: 90px;
			/* font-weight: 700; */
		}
	}

	@media screen and (max-width: 1500px) {
		& .in-wrapper-table {
			& .personal-cabinet-info-value,
			.personal-cabinet-info-article {
				font-size: 14px !important;
			}
		}
	}
`;
