import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { StyledFondyPaymentMethodsMenu } from './FondyPaymentMethodsMenu.style';
// import { type IFondyOptions } from '../../../app/api/interfaces/fondy/fondy.options.interface';
import { useAppSelector, useIsDarkTheme } from '../../../app/hooks';
import close from '../pic/close.svg';
import closeWhite from '../pic/closeWhite.svg';
import { type RootState } from '../../../app/store';

interface IFondyPaymentParams {
	period: string;
}

interface IFondyPaymentMethodsMenuProps {
	closeFn: () => void;
	refetchFn: () => Promise<void>;
	currentPlanName: string;
	currentPrice: string;
	priceId: string;
	fondyPaymentParams?: IFondyPaymentParams;
	tokenPaymentFondy: string;
}

declare function fondy(tagId: string, options: any): any;

export const FondyPaymentMethodsMenu: React.FC<
	IFondyPaymentMethodsMenuProps
> = ({
	closeFn,
	refetchFn,
	currentPlanName,
	currentPrice,
	priceId,
	fondyPaymentParams,
	tokenPaymentFondy,
}) => {
	const isDarkTheme = useIsDarkTheme();
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		currentTheme,
	};

	// const merchantId = (): number => {
	// 	const id = process.env.REACT_APP_FONDY_MERCHANT_ID;
	// 	if (id !== undefined) {
	// 		return parseInt(id);
	// 	} else {
	// 		return 0;
	// 	}
	// };

	const Options = {
		options: {
			methods: ['card', 'banklinks_eu', 'wallets', 'local_methods'],
			methods_disabled: [],
			card_icons: ['mastercard', 'visa', 'maestro', 'american_express'],
			active_tab: 'card',
			fields: false,
			full_screen: false,
			button: true,
			fee: true,
			locales: [
				'cs',
				'de',
				'en',
				'es',
				'fr',
				'hu',
				'it',
				'ko',
				'lv',
				'pl',
				'ro',
				'ru',
				'sk',
				'uk',
			],
			email: true,
		},
		params: {
			token: tokenPaymentFondy,
		},
	};

	useEffect(() => {
		fondy('#fondy-window', Options)
			.$on('success', async function (model: any) {
				// console.log('success event handled');
				// console.log(model);
				const orderStatus = model.data.order.order_data.order_status;

				if (orderStatus === 'approved') {
					await refetchFn();
					console.log('Order is approved. Do somethng on approve...');
				}
			})
			.$on('error', function (model: any) {
				// console.log('error event handled');
				// console.log(model);
				const orderStatus = model.data.order.order_data.order_status;
				const responseCode: string | number = model.data.error.code;
				const responseDescription: string = model.data.error.message;
				if (orderStatus === 'declined') {
					console.log(
						`Order is declined. Do somethng on decline... Last response code is: ${responseCode}, description: ${responseDescription}`
					);
				} else if (orderStatus === 'processing') {
					console.log(
						`Order is in processing. Last response code is: ${responseCode}, description: ${responseDescription}`
					);
				}
			});
	}, []);

	return (
		<StyledFondyPaymentMethodsMenu theme={theme}>
			<Box component="div" className="fondyPaymentMethodsMenu-wrapper">
				<Box component="div" className="closePic-wrapper">
					<Box
						component="img"
						className="closePic"
						alt="closePic"
						src={isDarkTheme ? closeWhite : close}
						onClick={closeFn}
					/>
				</Box>
				<Box component="div" id="fondy-window"></Box>
			</Box>
		</StyledFondyPaymentMethodsMenu>
	);
};
