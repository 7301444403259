/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type ISnippetObjectForSaveUpdate } from '../../../components/TrainingBotMenu/ModalTestBot/DialogWnd/DialogWnd';
import { getItem } from '../../utils/storageUtils';

export interface IInitialSnippetsListSlice {
	snippetsListInDialog: ISnippetObjectForSaveUpdate[];
	snippetsListForSaving: ISnippetObjectForSaveUpdate[];
}

const initialState: IInitialSnippetsListSlice = {
	snippetsListInDialog: [],
	snippetsListForSaving: [],
};

const snippetsListSlice = createSlice({
	name: 'snippetsListSlice',
	initialState,
	reducers: {
		setStartState(state, action: PayloadAction<string>) {
			const chatbotId = action.payload;
			const snippetsListFromStorage = getItem(`snippetsList-${chatbotId}`);
			const snippetsListForSavingFromStorage = getItem(
				`snippetsListForSaving-${chatbotId}`
			);
			state.snippetsListInDialog =
				snippetsListFromStorage !== null
					? JSON.parse(snippetsListFromStorage)
					: [];
			state.snippetsListForSaving =
				snippetsListForSavingFromStorage !== null
					? JSON.parse(snippetsListForSavingFromStorage)
					: [];
			return state;
		},
		setSnippetsListInDialog(
			state,
			action: PayloadAction<ISnippetObjectForSaveUpdate[]>
		) {
			state.snippetsListInDialog = action.payload;
			return state;
		},
		setSnippetsListForSaving(
			state,
			action: PayloadAction<ISnippetObjectForSaveUpdate[]>
		) {
			state.snippetsListForSaving = action.payload;
			return state;
		},

		updateSnippetFromListAndInSaving(
			state,
			action: PayloadAction<{
				id: number;
				isPrompt: boolean;
				updateValue: string;
			}>
		) {
			const indexUpdatedSnippet = state.snippetsListInDialog.findIndex(
				(currentSnippet) => currentSnippet.id === action.payload.id
			);

			if (action.payload.isPrompt) {
				state.snippetsListInDialog[indexUpdatedSnippet].prompt =
					action.payload.updateValue;
			} else {
				state.snippetsListInDialog[indexUpdatedSnippet].completion =
					action.payload.updateValue;
			}

			const indexUpdatedSnippetForSaving =
				state.snippetsListForSaving.findIndex(
					(currentSnippet) => currentSnippet.id === action.payload.id
				);
			if (indexUpdatedSnippetForSaving !== -1) {
				if (action.payload.isPrompt) {
					state.snippetsListForSaving[indexUpdatedSnippetForSaving].prompt =
						action.payload.updateValue;
				} else {
					state.snippetsListForSaving[indexUpdatedSnippetForSaving].completion =
						action.payload.updateValue;
				}
			}
			return state;
		},

		addSnippetToListForSavingInStore(
			state,
			action: PayloadAction<{
				id: number;
			}>
		) {
			const chosenSnippetForAdding = state.snippetsListInDialog.find(
				(currentSnippet) => currentSnippet.id === action.payload.id
			);
			const checkPresentSnippet = state.snippetsListForSaving.filter(
				(currentSnippet) => currentSnippet.id === action.payload.id
			);
			if (
				chosenSnippetForAdding !== undefined &&
				checkPresentSnippet.length === 0
			) {
				state.snippetsListForSaving = [
					...state.snippetsListForSaving,
					chosenSnippetForAdding,
				];
			}
			return state;
		},

		removeSnippetFromListByIdAndListInStore(
			state,
			action: PayloadAction<{
				id: number;
				targetSnippetsList: string;
			}>
		) {
			switch (action.payload.targetSnippetsList) {
				case 'inDialog': {
					const indexChosenSnippetForRemove =
						state.snippetsListInDialog.findIndex(
							(currentSnippet) => currentSnippet.id === action.payload.id
						);
					if (indexChosenSnippetForRemove !== -1) {
						state.snippetsListInDialog.splice(indexChosenSnippetForRemove, 1);
					}
					return state;
				}
				case 'inSavingList': {
					const indexChosenSnippetForRemove =
						state.snippetsListForSaving.findIndex(
							(currentSnippet) => currentSnippet.id === action.payload.id
						);
					if (indexChosenSnippetForRemove !== -1) {
						state.snippetsListForSaving.splice(indexChosenSnippetForRemove, 1);
					}
					return state;
				}
				default:
					return state;
			}
		},

		removeListSavedSnippetsFromListInDialog(state) {
			for (const currentSnippet of state.snippetsListInDialog) {
				const indexInSavedList = state.snippetsListForSaving.findIndex(
					(snip) => snip.id === currentSnippet.id
				);
				if (indexInSavedList !== -1) {
					const indexInDialogList = state.snippetsListInDialog.findIndex(
						(snip) => snip.id === currentSnippet.id
					);
					state.snippetsListInDialog.splice(indexInDialogList, 1);
				}
			}
			return state;
		},
	},
});

export const {
	setStartState,
	setSnippetsListInDialog,
	setSnippetsListForSaving,
	updateSnippetFromListAndInSaving,
	addSnippetToListForSavingInStore,
	removeSnippetFromListByIdAndListInStore,
	removeListSavedSnippetsFromListInDialog,
} = snippetsListSlice.actions;
export const snippetsListReducer = snippetsListSlice.reducer;
