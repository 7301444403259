import styled from 'styled-components';

export const StyledCurrentPlanComponent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8px;

		& .statisticCard-inner-title {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 145%;
		}

		& .statisticCard-inner-value {
			padding: 8px 24px;
			width: 135px;
			background: ${(props) =>
				props.theme.currentTheme.vidgetCardBlueDisabledColor};
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 27px;
			border-radius: 4px;
			text-align: center;

			display: flex;
			justify-content: center;
			align-items: center;

			& .progressSpinner-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				width: auto;
				height: 80%;

				& .progressSpinner {
					width: 30px;
					height: 30px;
				}
			}
		}
	}
	@media screen and (min-width: 1500px) {
		& > div {
			& .statisticCard-inner-value {
				width: 150px;
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& > div {
			& .statisticCard-inner-value {
				font-size: 14px;
			}
		}
	}

	@media screen and (max-width: 550px) {
		width: max-content;
		flex-direction: row;
		padding: 16px 0px;
		flex-wrap: wrap;
	}
`;
