import React from 'react';
import { Box } from '@mui/material';
import { StyledMessageExample } from './MessageExampleViber.style';
import arrow from '../../../../pic/arrow.svg';
import { useAppSelector } from '../../../../../../app/hooks';
import { type RootState } from '../../../../../../app/store';
import viberExample from '../../../../pic/viberExample.png';

export const MessageExampleViber: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	return (
		<StyledMessageExample theme={theme} className="messageExample-wrapper">
			<Box component="div" className="messageExample-arrow">
				<Box component="img" src={arrow} alt="arrow-pic" />
			</Box>
			<Box component="div" className="messageExample-msg-box">
				<Box component="img" className="step-Element-link" src={viberExample} />
			</Box>
		</StyledMessageExample>
	);
};
