// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSharedTransparentButton = styled.button`
	/* linear-gradient(90deg, #198DED 0%, #691EFF 100%) padding-box,
linear-gradient(to right, #43f2ff, #691eff) border-box; */
	/* 1px solid transparent */

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 202px;
	height: 70px;
	border-radius: 8px;
	border: 2px solid transparent;
	background: linear-gradient(
				to right,
				${(props) => props.theme.currentTheme.cardsBackground},
				${(props) => props.theme.currentTheme.cardsBackground}
			)
			padding-box,
		linear-gradient(45deg, #3daeff 0%, #5410db 100%) border-box;

	/* border: none;
	background: linear-gradient(45deg, #3daeff, #5410db); */
	cursor: pointer;
	padding: 2px;
	transition: all 0.2s;
	overflow: hidden;

	& > div {
		color: ${(props) => props.theme.currentTheme.mainFontColor};
		font-style: normal;
		font-size: 18px;
		line-height: 100%;
		width: 100%;
		padding: 0px 16px;

		/* background: ${(props) => props.theme.currentTheme.cardsBackground}; */
		/* width: 100%; */
		height: 100%;
		/* border-radius: 6px; */
		display: flex;
		justify-content: center;
		align-items: center;

		/* & > div {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 90%;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			font-family: 'Nunito';
			font-style: normal !important;
			text-decoration: none !important;
			font-weight: 600;
			font-size: 18px;
			line-height: 100%;
			z-index: 100;
			transition: all 0.1s;
		} */
	}

	&:active {
		transform: scale(0.98);
	}

	&:hover {
		background: #5e17eb;

		& > div {
			background: inherit;
			color: #fff;

			& > div {
				/* color: #fff; */
			}
		}
	}

	@media screen and (max-width: 1500px) {
		/* width: 170px; */
		min-width: 170px;
		height: 50px;

		& > div {
			& > div {
				font-size: 16px;
			}
		}
	}

	@media screen and (max-width: 350px) {
		width: 100%;
	}
`;
