import React from 'react';
import { ReactComponent as EditBlue } from '../pic/editBlue.svg';
import { CustomTooltip } from '../../shared/CustomTooltip/CustomTooltip';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setCurrentChatbotIdForHistory } from '../../../app/slices/trainingBotMenu/openModal/currentValues/currentChatbotIdForActionsTrainingBotMenuSlice';

interface IEditBlueActionBtnProps {
	onClick: () => void;
	chatbotId: number;
}

export const EditBlueActionBtn: React.FC<IEditBlueActionBtnProps> = ({
	onClick,
	chatbotId,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	const dispatch = useAppDispatch();

	const handleClickEdit = (): void => {
		dispatch(setCurrentChatbotIdForHistory(chatbotId.toString()));
		onClick();
	};

	return (
		<CustomTooltip
			theme={theme}
			title="Edit snippets or chatbot settings. Get a history of requests to your bot and more."
		>
			<EditBlue onClick={handleClickEdit} />
		</CustomTooltip>
	);
};
