// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledErrorMenu = styled.div`
	min-height: 100vh;
	display: flex;
	width: 100%;
	padding: 4rem;
	background: #1a1d21;

	& .error-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-around;

		& .error-area {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			justify-content: flex-start;
			align-items: center;
			width: max-content;

			& .error-logo-image {
				width: 400px;
				height: auto;
			}

			& .shared-subtitle {
				width: max-content;
			}

			& .error-button {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 1100px) {
		& .error-wrapper {
			flex-direction: column;
		}
	}
`;
