import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { type RootState } from '../../store';
import { type IGetSubscriptionBySubIdResponse } from '../../api/payment.api/interfaces/getSubscriptionBySubId.interface';
import { useLazyGetSubscriptionBySubIdQuery } from '../../api/payment.api/payment.api';
import { ProgressDots } from '../../../components/shared/ProgressDots/ProgressDots';
import { getDateDDMMYY } from '../../utils/payment.util';

interface IReturnObjectUseGetSubscriptionBySubId {
	currentPlanName: string | JSX.Element;
	expireDate: string | JSX.Element;
	isSuccessGetSubscriptionById: boolean;
	isLoadingGetSubscriptionById: boolean;
	isErrorGetSubscriptionById: boolean;
}

interface IUseGetSubscriptionBySubId {
	returnObject: IReturnObjectUseGetSubscriptionBySubId;
	updateSubscriptionId: (subscriptionId: string) => void;
}

export const useGetSubscriptionBySubId = (): IUseGetSubscriptionBySubId => {
	// (): (subscriptionId: string) => Promise<IReturnObjectUseGetSubscriptionBySubId> => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const [getSubscriptionBySubId, statusOfGettingSubscriptionBySubId] =
		useLazyGetSubscriptionBySubIdQuery();

	// const executeGetSubscriptionBySubId = async (subscriptionId: string): Promise<IReturnObjectUseGetSubscriptionBySubId> => {

	const [currentPlan, setCurrentPlan] =
		useState<IGetSubscriptionBySubIdResponse>();

	const [subscriptionId, setSubscriptionId] = useState('');

	const updateSubscriptionId = (subscriptionId: string): void => {
		setSubscriptionId(subscriptionId);
	};

	useEffect(() => {
		try {
			if (
				subscriptionId != null &&
				subscriptionId !== '' &&
				subscriptionId !== 'null' &&
				subscriptionId?.includes('sub_')
			) {
				getSubscriptionBySubId({ token, subscriptionId })
					.then((response) => {
						setCurrentPlan(response.data);
					})
					.catch((error) => {
						console.log(error);
					});
			}
		} catch (error) {
			console.log(error);
		}
	}, [subscriptionId]);

	const loadingStatus = (): boolean => {
		return statusOfGettingSubscriptionBySubId.isFetching;
	};
	const errorStatus = (): boolean => {
		return statusOfGettingSubscriptionBySubId.isError;
	};
	const successStatus = (): boolean => {
		return statusOfGettingSubscriptionBySubId.isSuccess;
	};

	const getPlanSubscriptionValueByKey = (key: string): string | JSX.Element => {
		if (currentPlan !== undefined) {
			if (loadingStatus()) {
				return <ProgressDots />;
			} else if (errorStatus()) {
				return 'Error';
			} else if (successStatus()) {
				switch (key) {
					case 'productName':
						return currentPlan.plan.product.name;
					case 'expiryDate':
						return getDateDDMMYY(currentPlan.subscription.current_period_end);
					default:
						return '';
				}
			} else {
				return '';
			}
		} else {
			return '';
		}
	};
	// };

	return {
		updateSubscriptionId,
		// executeGetSubscriptionBySubId
		returnObject: {
			currentPlanName: getPlanSubscriptionValueByKey('productName'),
			expireDate: getPlanSubscriptionValueByKey('expiryDate'),
			isSuccessGetSubscriptionById: successStatus(),
			isLoadingGetSubscriptionById: loadingStatus(),
			isErrorGetSubscriptionById: errorStatus(),
		},
	};
};
