import styled from 'styled-components';

export const StyledNavbarTrainingBotMenu = styled.div`
	display: flex;
	gap: 53px;
	border-bottom: 1px solid ${(props) => props.theme.currentTheme.disabledBorder};
	overflow-x: auto;
	overflow-x: none;

	& .currentNavElement {
		padding: 17px 0px;
		color: ${(props) => props.theme.currentTheme.mainFontColor};
		cursor: pointer;
		transition: all 0.1s ease-in;
		font-size: 22px;
		font-style: normal;
		font-weight: 400;
		line-height: 145%;

		&:hover {
			color: #8044f8;
		}
	}

	& .currentNavElement.currentStyle {
		color: #8044f8;
		border-bottom: 1px solid #8044f8;
	}

	@media screen and (max-width: 1500px) {
		& .currentNavElement {
			font-size: 16px;
		}
	}
`;
