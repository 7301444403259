import styled from 'styled-components';

export const StyledTableSnippetsTrainingBotMenu = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	padding-top: 24px;

	& .actions-table-field {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		& .actions-table-push-export-delete-area {
			& .actionsLeftAreaTableSnippets-wrapper {
				display: flex;
				align-items: center;
				gap: 32px;

				& .currentActionButton {
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 145%;
					border: none;
					background: none;
					cursor: pointer;
					color: ${(props) => props.theme.currentTheme.mainFontColor};

					&:hover {
						color: #8044f8;
					}
					&:active {
						color: #8044f8;
					}
				}

				& .currentActionButton.isDeleteButton {
					color: #e34c4c;

					&:hover {
						color: #8044f8;
					}
					&:active {
						color: #8044f8;
					}
				}
			}
		}

		& .actions-table-view-on-page-area {
			& .right-part-wrapper {
				display: flex;
				/* gap: .5rem; */
			}
			& .actionsViewOnPageAreaTableSnippets {
				/* display: flex;
				align-items: center;
				gap: 16px;

				& .viewOnPage-title {
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px;
					color: ${(props) => props.theme.currentTheme.mainFontColor};
				}

				& .MuiAutocomplete-root {
					width: 68px;
					height: 40px;

					& .MuiIconButton-root.MuiAutocomplete-clearIndicator {
						display: none;
					}

					& .MuiOutlinedInput-input {
						font-size: 16px;
					}
				} */
			}
		}
	}

	& .snippets-main-table-field {
		width: 100%;
	}

	@media screen and (max-width: 1000px) {
		& .actions-table-field {
			& .actions-table-push-export-delete-area {
				& .actionsLeftAreaTableSnippets-wrapper {
					flex-wrap: wrap;
				}
			}

			& .actions-table-view-on-page-area {
				& .actionsViewOnPageAreaTableSnippets {
					/* & .customAutocomplete {
						width: 100% !important;
					} */
					/* & .MuiAutocomplete-root {
						width: 68px !important;
						height: 40px !important;
					} */
				}
			}
		}
	}

	@media screen and (max-width: 850px) {
		& .actions-table-field {
			flex-direction: column;
			gap: 1.5rem;

			& .actions-table-push-export-delete-area {
				& .actionsLeftAreaTableSnippets-wrapper {
					justify-content: center;
					gap: 1rem;
				}
			}

			& .actions-table-view-on-page-area {
				display: flex;
				width: 100%;
				/* justify-content: space-between; */

				& .actionsViewOnPageAreaTableSnippets {
					/* display: flex;
					width: 100%;
					justify-content: space-between; */
				}
			}
		}
	}
`;
