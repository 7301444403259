import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const openEditCurrentSnippetTrainingBotMenuSlice = createSlice({
	name: 'openEditCurrentSnippetTrainingBotMenuSlice',
	initialState: false,
	reducers: {
		setOpenModalEditCurrentSnippetStatus(
			state,
			action: PayloadAction<boolean>
		) {
			state = action.payload;
			return state;
		},
	},
});

export const { setOpenModalEditCurrentSnippetStatus } =
	openEditCurrentSnippetTrainingBotMenuSlice.actions;
export const openEditCurrentSnippetTrainingBotMenuReducer =
	openEditCurrentSnippetTrainingBotMenuSlice.reducer;
