// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialChatGeneratorValidInputsValueState {
	isValidToneDetailedBotCard: boolean;
	isValidStyleDetailedBotCard: boolean;
	isValidHeaderDetailedBotCard: boolean;
	isValidButtonTextDetailedBotCard: boolean;
	isValidChatBotNameDetailedBotCard: boolean;
	isValidChatBotStructureDetailedBotCard: boolean;
	isValidChatBotStyleCssDetailedBotCard: boolean;
}

const initialState: IInitialChatGeneratorValidInputsValueState = {
	isValidToneDetailedBotCard: false,
	isValidStyleDetailedBotCard: false,
	isValidHeaderDetailedBotCard: false,
	isValidButtonTextDetailedBotCard: false,
	isValidChatBotNameDetailedBotCard: false,
	isValidChatBotStructureDetailedBotCard: false,
	isValidChatBotStyleCssDetailedBotCard: false,
};

const isValidDetailedBotCardInputsSlice = createSlice({
	name: 'isValidDetailedBotCardInputs',
	initialState,
	reducers: {
		setValidStatusToneDetailedBotCard(state, action) {
			state.isValidToneDetailedBotCard = action.payload;
			return state;
		},
		setValidStatusStyleDetailedBotCard(state, action) {
			state.isValidStyleDetailedBotCard = action.payload;
			return state;
		},
		setValidStatusHeaderDetailedBotCard(state, action) {
			state.isValidHeaderDetailedBotCard = action.payload;
			return state;
		},
		setValidStatusButtonTextDetailedBotCard(state, action) {
			state.isValidButtonTextDetailedBotCard = action.payload;
			return state;
		},
		setValidStatusChatBotNameDetailedBotCard(state, action) {
			state.isValidChatBotNameDetailedBotCard = action.payload;
			return state;
		},
		setValidStatusChatBotStructureDetailedBotCard(state, action) {
			state.isValidChatBotStructureDetailedBotCard = action.payload;
			return state;
		},
		setValidStatusChatBotStyleCssDetailedBotCard(state, action) {
			state.isValidChatBotStyleCssDetailedBotCard = action.payload;
			return state;
		},
	},
});

export const {
	setValidStatusToneDetailedBotCard,
	setValidStatusStyleDetailedBotCard,
	setValidStatusButtonTextDetailedBotCard,
	setValidStatusHeaderDetailedBotCard,
	setValidStatusChatBotNameDetailedBotCard,
	setValidStatusChatBotStructureDetailedBotCard,
	setValidStatusChatBotStyleCssDetailedBotCard,
} = isValidDetailedBotCardInputsSlice.actions;
export const isValidDetailedBotCardInputsReducer =
	isValidDetailedBotCardInputsSlice.reducer;
