import { useAppDispatch } from '../hooks';
import { setCurrentStepGenerator } from '../slices/currentStepGeneratorSlice';
import {
	setStateGeneratorFromStorage,
	initialState,
} from '../slices/inputsGenerator/chatGeneratorInputsValueSlice';
import {
	setEmptyStatusForCheckChatGeneratorFirstStep,
	setEmptyStatusForCheckChatGeneratorSecondStep,
} from '../slices/inputsGenerator/checkEmptyStatusForChatGeneratorSlice';
import { setCodeJSGeneratedStatus } from '../slices/isCodeJSGeneratedSlice';
import { removeItem, setItem } from '../utils/storageUtils';

export const useClearSettingsJSGenerated = (): (() => void) => {
	const dispatch = useAppDispatch();

	const clearSettingsJSGenerated = (): void => {
		dispatch(setEmptyStatusForCheckChatGeneratorFirstStep(false));
		dispatch(setEmptyStatusForCheckChatGeneratorSecondStep(false));
		dispatch(setCodeJSGeneratedStatus(false));
		dispatch(setCurrentStepGenerator(0));
		dispatch(setStateGeneratorFromStorage(initialState));

		setItem('currentStepGenerator', JSON.stringify(0));
		removeItem('chatbotData');
		setItem('codeJSGeneratedStatus', 'false');
	};

	return clearSettingsJSGenerated;
};
