// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialChatGeneratorInputsValueState {
	domain: string;
	tone: string;
	openAIKey: string;
	reCaptchaKey: string;
	reCaptchaSecretKey: string;
	style: string;
	header: string;
	buttonText: string;
}

export const initialState: IInitialChatGeneratorInputsValueState = {
	domain: '',
	tone: '',
	openAIKey: '',
	reCaptchaKey: '',
	reCaptchaSecretKey: '',
	style: '',
	header: '',
	buttonText: '',
};

const chatGeneratorInputsValueSlice = createSlice({
	name: 'chatGeneratorInputsValue',
	initialState,
	reducers: {
		setDomainGeneratorMenu(state, action: PayloadAction<string>) {
			state.domain = action.payload;
			return state;
		},
		setToneGeneratorMenu(state, action: PayloadAction<string>) {
			state.tone = action.payload;
			return state;
		},
		setOpenAIKeyGeneratorMenu(state, action: PayloadAction<string>) {
			state.openAIKey = action.payload;
			return state;
		},
		setReCaptchaKeyGeneratorMenu(state, action: PayloadAction<string>) {
			state.reCaptchaKey = action.payload;
			return state;
		},
		setReCaptchaSecretKeyGeneratorMenu(state, action: PayloadAction<string>) {
			state.reCaptchaSecretKey = action.payload;
			return state;
		},
		setStyleGeneratorMenu(state, action: PayloadAction<string>) {
			state.style = action.payload;
			return state;
		},
		setHeaderGeneratorMenu(state, action: PayloadAction<string>) {
			state.header = action.payload;
			return state;
		},
		setButtonTextGeneratorMenu(state, action: PayloadAction<string>) {
			state.buttonText = action.payload;
			return state;
		},
		setStateGeneratorFromStorage(
			state,
			action: PayloadAction<IInitialChatGeneratorInputsValueState>
		) {
			const allItems: IInitialChatGeneratorInputsValueState = action.payload;
			if (allItems.domain !== undefined) {
				state.domain = allItems.domain;
			}
			if (allItems.tone !== undefined) {
				state.tone = allItems.tone;
			}
			if (allItems.openAIKey !== undefined) {
				state.openAIKey = allItems.openAIKey;
			}
			if (allItems.reCaptchaKey !== undefined) {
				state.reCaptchaKey = allItems.reCaptchaKey;
			}
			if (allItems.reCaptchaSecretKey !== undefined) {
				state.reCaptchaSecretKey = allItems.reCaptchaSecretKey;
			}
			if (allItems.buttonText !== undefined) {
				state.buttonText = allItems.buttonText;
			}
			if (allItems.header !== undefined) {
				state.header = allItems.header;
			}
			if (allItems.style !== undefined) {
				state.style = allItems.style;
			}
			return state;
		},
	},
});

export const {
	setDomainGeneratorMenu,
	setToneGeneratorMenu,
	setOpenAIKeyGeneratorMenu,
	setReCaptchaKeyGeneratorMenu,
	setReCaptchaSecretKeyGeneratorMenu,
	setStyleGeneratorMenu,
	setHeaderGeneratorMenu,
	setButtonTextGeneratorMenu,
	setStateGeneratorFromStorage,
} = chatGeneratorInputsValueSlice.actions;
export const chatGeneratorInputsValueReducer =
	chatGeneratorInputsValueSlice.reducer;
