import React from 'react';
import { Box } from '@mui/material';
import { StyledCurrentPlanComponent } from './CurrentPlanComponent.style';
import { useAppSelector } from '../../../../../app/hooks';
import { type RootState } from '../../../../../app/store';
import { ProgressSpinner } from '../../../../shared/ProgressSpinner/ProgressSpinner';

interface ICurrentPlanComponentProps {
	currentPlan: string | JSX.Element;
	expireDate: string | JSX.Element;
	isLoading: boolean;
	isError: boolean;
	isSuccess: boolean;
}

export const CurrentPlanComponent: React.FC<ICurrentPlanComponentProps> = ({
	currentPlan,
	expireDate,
	isError,
	isLoading,
	isSuccess,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const LoadingComponent: React.FC = () => (
		<Box
			component="div"
			className="loadingComponent"
			sx={{ display: 'flex', justifyContent: 'center', pt: 1, width: '150px' }}
		>
			<ProgressSpinner />
		</Box>
	);

	const AbsentCurrentPlan: React.FC = () => (
		<Box
			sx={{
				fontSize: '14px',
				lineHeight: '14px',
				fontWeight: '500',
				textAlign: 'center',
				p: 1,
				width: '150px',
				background: currentTheme.vidgetCardBlueDisabledColor,
				borderRadius: '4px',
			}}
		>
			You don`t have a current plan yet
		</Box>
	);

	return (
		<StyledCurrentPlanComponent
			className="statisticCard-plan-info"
			theme={theme}
		>
			{isError ? (
				<>Something went wrong</>
			) : isLoading ? (
				<LoadingComponent />
			) : currentPlan === '' || expireDate === '' ? (
				<AbsentCurrentPlan />
			) : isSuccess ? (
				<>
					<Box className="statisticCard-plan-info-current-plan">
						<Box className="statisticCard-inner-title">Current plan</Box>
						<Box className="statisticCard-inner-value">{currentPlan}</Box>
					</Box>
					<Box className="statisticCard-plan-info-plan-expires">
						<Box className="statisticCard-inner-title">Plan Expires</Box>
						<Box className="statisticCard-inner-value">{expireDate}</Box>
					</Box>
				</>
			) : (
				<AbsentCurrentPlan />
			)}
		</StyledCurrentPlanComponent>
	);
};
