import React from 'react';
import { Box } from '@mui/material';
import { RegistrationMenu } from '../components/RegistrationMenu/RegistrationMenu';
import { useAppSelector } from '../app/hooks';
import { type RootState } from '../app/store';
import { SuccessfullyRegistered } from '../components/RegistrationMenu/SuccessfullyRegistered/SuccessfullyRegistered';
import { ConfirmEmailMenu } from '../components/ConfirmEmailMenu/ConfirmEmailMenu';
import { Navigate } from 'react-router-dom';

export const RegistrationPage: React.FC = () => {
	const isAuthorization: boolean = useAppSelector(
		(state: RootState) => state.isAuthorization
	);

	const isRegistration: boolean = useAppSelector(
		(state: RootState) => state.isRegistration
	);

	const isConfirmEmail: boolean = useAppSelector(
		(state: RootState) => state.isConfirmEmail
	);

	const CurrentStepOfRegistration: React.FC = () => {
		if (!isRegistration && !isConfirmEmail) {
			return <RegistrationMenu />;
		} else if (isRegistration && !isConfirmEmail) {
			return <ConfirmEmailMenu />;
		} else if (isRegistration && isConfirmEmail) {
			return <SuccessfullyRegistered />;
		} else {
			return <RegistrationMenu />;
		}
	};

	return (
		<Box component="div" className="registration-page">
			{!isAuthorization ? <CurrentStepOfRegistration /> : <Navigate to="/" />}
		</Box>
	);
};
