import React from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { StyledAboutMainMenu } from './AboutMainMenu.style';

import easy from './pic/easyAboutMain.svg';
import multifunction from './pic/multifunctionAboutMain.svg';
import support from './pic/supportAboutMain.svg';
import time from './pic/timeAboutMain.svg';

import { ContainerAbout } from '../shared/ContainerAbout/ContainerAbout';
import { WrapperListAdvantages } from './WrapperListAdvantages';

export const AboutMainMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = { currentTheme };

	const listAdvantagesVertical = [
		{
			value:
				'With Coderfy Chatbot generator, you can create training data for specific businesses or themes with their domains and train custom chatbots on this data. You can get a simple HTML code to add to your website and start using your chatbot in minutes!',
			title: 'Fast',
			icon: <Box component="img" className="current-icon" src={time} />,
		},
		{
			value:
				'At Coderfy Chatbot Generator, we understand that creating and integrating a chatbot can be confusing and overwhelming. That`s why we`ve made it our mission to provide an intuitive and user-friendly platform that anyone can use, regardless of technical expertise.',
			title: 'Easy',
			icon: <Box component="img" className="current-icon" src={easy} />,
		},
	];

	const listAdvantagesHorizontal = [
		{
			value:
				'Our team is always working hard to bring you the best features, and we`re excited to announce our upcoming options for training chatbots in the app dashboard, the history of chats, and the ability to design and customize the style of your bot.',
			title: 'Multifunction',
			icon: (
				<Box component="img" className="current-icon" src={multifunction} />
			),
		},
		{
			value:
				'Our dedicated customer support team can always assist you if you have questions or concerns about using our chatbot generator. Whether a small business owner or a large corporation, Coderfy Chatbot generator can help you increase engagement and streamline customer communication on your website. Try it out today and see the difference it can make!',
			title: 'Support',
			icon: <Box component="img" className="current-icon" src={support} />,
		},
	];

	const offerCards = [
		{ title: 'Trial to test', accent: 'Free', additional: 'for 7-days' },
		{ title: 'Basic', accent: '49 €', additional: 'for month' },
		{ title: 'Advanced', accent: '198 €', additional: 'one-time fee' },
	];

	return (
		<StyledAboutMainMenu theme={theme}>
			<Box component="div" className="aboutMainMenu-wrapper">
				<Box component="div" className="aboutMainMenu-subtitle">
					Welcome to Coderfy Chatbot generator - the easiest and most efficient
					way to create a custom chatbot for your website.
				</Box>
				<Box component="div" className="about-advantages">
					<Box
						component="div"
						className="about-advantages-left"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{listAdvantagesVertical.map((item) => (
							<ContainerAbout key={item.title}>
								<WrapperListAdvantages
									icon={item.icon}
									title={item.title}
									positionVertical={false}
									value={item.value}
								/>
							</ContainerAbout>
						))}
					</Box>
					<Box
						component="div"
						className="about-advantages-right"
						sx={{
							display: 'flex',
							flexDirection: { xs: 'column', sm: 'row' },
							justifyContent: 'center',
							gap: '1rem',
						}}
					>
						{listAdvantagesHorizontal.map((item) => (
							<ContainerAbout key={item.title}>
								<WrapperListAdvantages
									icon={item.icon}
									title={item.title}
									positionVertical={true}
									value={item.value}
								/>
							</ContainerAbout>
						))}
					</Box>
				</Box>
				<Box component="div" className="about-offer">
					<Box component="div" className="about-offer-text">
						<Box component="div" className="about-offer-text-title">
							We offer
						</Box>
						<Box component="div" className="about-offer-text-text">
							Our platform offers a 7-day free trial to test it before
							committing to a monthly payment plan, starting from just 49 Euros.
							If you&apos;re looking for an advanced trained model of a chatbot,
							we offer this option for a small one-time fee of 198 Euros
						</Box>
					</Box>
					<Box component="div" className="about-offer-cards">
						{offerCards.map((card) => (
							<Box key={card.title} component="div" className="card-wrapper">
								<Box component="div" className="card-title">
									{' '}
									{card.title}{' '}
								</Box>
								<Box component="div" className="card-accent">
									{' '}
									{card.accent}{' '}
								</Box>
								<Box component="div" className="card-additional">
									{' '}
									{card.additional}{' '}
								</Box>
							</Box>
						))}
					</Box>
				</Box>
			</Box>
		</StyledAboutMainMenu>
	);
};
