import React from 'react';
import { StyledNumberInput } from './NumberInput.style';
import { type IThemeStyledComponents } from '../SharedInput';
import { StyledInput } from '../SharedInput.style';

interface INumberInputProps {
	type: string;
	placeholder: string;
	value?: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
	id: string;
	theme?: IThemeStyledComponents;
	className: string;
	autoComplete: string;
	disabled: boolean;
	minNumber?: number;
	maxNumber?: number;
	stepNumber?: number;
}

export const NumberInput: React.FC<INumberInputProps> = ({
	type,
	placeholder,
	value,
	onChange,
	id,
	theme,
	className,
	autoComplete,
	disabled,
	maxNumber,
	minNumber,
	stepNumber,
}) => {
	return (
		<StyledNumberInput>
			<StyledInput
				// type="text"
				type="number"
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				id={id}
				aria-describedby="outlined-input-helper-text"
				theme={theme}
				className="shared-input"
				autoComplete="off"
				disabled={disabled}
				min={minNumber !== undefined ? minNumber : 'auto'}
				max={maxNumber !== undefined ? maxNumber : 'auto'}
				step={stepNumber !== undefined ? stepNumber : '1'}
			/>
		</StyledNumberInput>
	);
};
