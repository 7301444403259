// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledWrapperMenu = styled.div`
	background: #1a1d21;
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 4rem;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	z-index: 400;

	& .menu-wrapper {
		border-radius: 30%;
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		position: relative;
		z-index: 400;

		& .menu-inputs-area {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			justify-content: flex-start;
			align-items: center;
		}
	}

	& .for-image {
		position: absolute;
		bottom: 0px;
		left: 0px;
		height: 100vh;
		width: 70%;
		z-index: 0;

		& img {
			height: 100%;
		}

		& svg {
			height: 100%;
		}
	}

	& .for-image-mobile-brain {
		display: none;
		position: absolute;
		top: -30px;
		left: 0px;
		width: 100%;
		justify-content: center;
		align-items: center;
		& > svg {
			width: 210px;
			height: 210px;
		}
	}

	& .for-image-mobile-bottom {
		display: none;
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		justify-content: center;
		align-items: center;

		& svg {
			width: 100%;
		}
	}

	@media screen and (max-width: 1100px) {
		align-items: flex-start;

		& .menu-wrapper {
			flex-direction: column;
		}

		& .for-image {
			display: ${(props) => props.theme.displayMainBg};
			left: ${(props) => props.theme.left};
			bottom: ${(props) => props.theme.bottom};
			z-index: 1;
			justify-content: center;
			align-items: center;
			height: ${(props) => props.theme.height};
			width: ${(props) => props.theme.width};
		}
	}

	@media screen and (max-width: 650px) {
		& .menu-wrapper {
			& .menu-inputs-area {
				padding-top: 90px;
			}
		}

		& .for-image {
			display: none;
		}

		& .for-image-mobile-brain {
			display: flex;
		}

		& .for-image-mobile-bottom {
			display: flex;
		}
	}
`;
