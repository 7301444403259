import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { setNumber } from '../../../../app/slices/cellsConfirmationCode/cellsConfirmationCodeSlice';

interface ICellInputProps {
	index: number;
	numberOfInput: string;
	inputsList: string[];
}

export const CellInput: React.FC<ICellInputProps> = ({
	index,
	numberOfInput,
	inputsList,
}: ICellInputProps) => {
	const allValue = useAppSelector(
		(state: RootState) => state.cellsConfirmationCode
	);
	const currentValue = (): string => {
		switch (numberOfInput) {
			case 'first':
				return allValue.firstNumber;
			case 'second':
				return allValue.secondNumber;
			case 'third':
				return allValue.thirdNumber;
			case 'fourth':
				return allValue.fourthNumber;
			case 'fifth':
				return allValue.fifthNumber;
			case 'sixth':
				return allValue.sixthNumber;
			default:
				return '';
		}
	};

	const nextElement = document.querySelector(`#cell-input-${index}`)
		?.nextSibling as HTMLInputElement;
	const previousElement = document.querySelector(`#cell-input-${index}`)
		?.previousSibling as HTMLInputElement;
	const lastElement = document.querySelector(
		`#cell-input-5`
	) as HTMLInputElement;

	const inputRef: React.MutableRefObject<null | HTMLInputElement> =
		useRef(null);

	const dispatch = useAppDispatch();

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
		e.preventDefault();
		const clipboardData = e.clipboardData?.getData('text/plain') ?? '';
		if (clipboardData.length === 6 && !/[^0-9]+/g.test(clipboardData)) {
			inputsList.map((value, index) =>
				dispatch(setNumber([clipboardData[index], value]))
			);
			lastElement.focus();
		}
	};

	const handleValueInput = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.KeyboardEvent<HTMLInputElement>,
		valueKey: null | string = null
	): void => {
		const currentValue = valueKey === null ? e.currentTarget.value : valueKey;
		if (currentValue === '') {
			dispatch(setNumber(['', numberOfInput]));
		} else if (currentValue.match(/\d/gim) !== null) {
			if (valueKey !== null) {
				dispatch(setNumber([currentValue, numberOfInput]));
				numberOfInput !== 'sixth' && nextElement.focus();
			}
		}
	};

	const handleChangeFocusAndReplaceValue = (
		e: React.KeyboardEvent<HTMLInputElement>
	): void => {
		e.preventDefault();
		try {
			if (e.key === 'Backspace' && numberOfInput !== 'first') {
				previousElement.focus();
				dispatch(setNumber(['', numberOfInput]));
			} else if (e.key === 'Backspace' && numberOfInput === 'first') {
				dispatch(setNumber(['', numberOfInput]));
			} else if (e.key === 'ArrowLeft' && numberOfInput !== 'first') {
				previousElement.focus();
			} else if (e.key === 'ArrowRight' && numberOfInput !== 'sixth') {
				nextElement.focus();
			} else if (/^\d$/.test(e.key)) {
				handleValueInput(e, e.key);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<input
			className={`cellInput`}
			id={`cell-input-${index}`}
			type="text"
			value={currentValue()}
			maxLength={1}
			ref={inputRef}
			onChange={handleValueInput}
			onKeyUp={(e) => {
				handleChangeFocusAndReplaceValue(e);
			}}
			onPaste={handlePaste}
			autoComplete="off"
		></input>
	);
};
