// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusChangeChatbotIntegrationSlice = createSlice({
	name: 'checkEmptyStatusChangeChatbotIntegration',
	initialState,
	reducers: {
		setEmptyStatusChangeChatbotIntegration(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusChangeChatbotIntegration } =
	checkEmptyStatusChangeChatbotIntegrationSlice.actions;
export const checkEmptyStatusChangeChatbotIntegrationReducer =
	checkEmptyStatusChangeChatbotIntegrationSlice.reducer;
