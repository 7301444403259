import React from 'react';
import { Box } from '@mui/material';
import chessStyle from './pic/chess.png';
import defaultStyle from './pic/default.png';
import frankStyle from './pic/frank.png';
import officeStyle from './pic/office.png';
import nuomorphismStyle from './pic/nuomorphism.png';
import morphedBubblesStyle from './pic/morphed-bubbles.png';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { SharedPreviewWrapperImg } from './SharedPreviewWrapperImg/SharedPreviewWrapperImg';

interface ISharedPreviewChatbotProps {
	style: string;
	isCoderfy?: boolean;
}

export const SharedPreviewChatbot: React.FC<ISharedPreviewChatbotProps> = ({
	style,
	isCoderfy,
}) => {
	const currentLinkChatbotImg = useAppSelector(
		(state: RootState) => state.linkCurrentChatbotImg.link
	);
	if (isCoderfy === true) {
		switch (style) {
			case '':
				return (
					<Box component="div" className="spoiler-choose-style">
						Choose a style
					</Box>
				);
			default:
				// SharedPreviewWrapperImg
				return <SharedPreviewWrapperImg link={currentLinkChatbotImg} />;
			// return <Box component="img" src={currentLinkChatbotImg} />;
		}
	} else {
		switch (style) {
			case 'Chess':
				return <Box component="img" src={chessStyle} />;

			case 'Default':
				return <Box component="img" src={defaultStyle} />;

			case 'Frank':
				return <Box component="img" src={frankStyle} />;

			case 'Morphed-bubbles':
				return <Box component="img" src={morphedBubblesStyle} />;

			case 'Nuomorphism':
				return <Box component="img" src={nuomorphismStyle} />;

			case 'Office':
				return <Box component="img" src={officeStyle} />;

			default:
				return <Box>Choose a style</Box>;
		}
	}
};
