import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledHistoryRequest } from './HistoryRequest.style';
import { SharedPagination } from '../../shared/PaginationAndTableComponents/SharedPagination/SharedPagination';
import { SharedSearchBar } from '../../shared/PaginationAndTableComponents/SearchBarTrainingBotMenu/SharedSearchBar';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import {
	SharedDesktopTable,
	type IGenericRow,
} from '../../shared/PaginationAndTableComponents/SharedTable/SharedDesktopTable';
import { SharedActionsViewOnPage } from '../../shared/PaginationAndTableComponents/SharedActionsViewOnPage/SharedActionsViewOnPage';
import {
	useGetHistoryRequestsQuery,
	useLazyGetHistoryRequestsQuery,
} from '../../../app/api/chatbot.api';
import {
	type IGetHistoryResponse,
	type IGetHistoryRequest,
} from '../../../app/api/interfaces/snippets.interface';
import { redoDateToDaysAgo } from '../TrainingBotMenu';
import {
	activateSearchBarForRefetchingHistoryMenu,
	sortOrderForRefetchingHistoryMenu,
} from '../../../app/slices/trainingBotMenu/historyMenu/searchBarHistoryMenu/searchBarHistoryMenuValueSlice';
import {
	setHistoryStartFetchPage,
	setHistoryTotalResultsSnippets,
} from '../../../app/slices/trainingBotMenu/historyMenu/paginationValuesHistoryMenuSlice';
import { SharedMobileSnippetsTable } from '../../shared/PaginationAndTableComponents/SharedTable/MobileSnippetsTable/SharedMobileSnippetsTable';
import ShowMoreText from 'react-show-more-text';
import { HistoryDateRangePicker } from './HistoryDateRangePicker/HistoryDateRangePicker';
import { SortComponentChoose } from '../../shared/PaginationAndTableComponents/SortComponent/SortComponentChoose/SortComponentChoose';

interface IHistoryRequestProps {
	chatbotId: string;
}

export const HistoryRequest: React.FC<IHistoryRequestProps> = ({
	chatbotId,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const paginationValues = useAppSelector(
		(state: RootState) => state.paginationValuesHistoryMenu
	);

	const searchBarHistoryMenuValue = useAppSelector(
		(state: RootState) => state.searchBarHistoryMenuValue
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const currentChatbotId = useAppSelector(
		(state: RootState) =>
			state.currentChatbotIdForActionsTrainingBotMenu.currentChatbotIdForHistory
	);

	const {
		totalResultsSnippetsHistoryMenu,
		viewOnPageHistoryMenu,
		startFetchPageHistoryMenu,
		finishFetchPageHistoryMenu,
	} = paginationValues;

	const {
		searchBarValueHistoryMenu,
		searchBarValueForRefetchingHistoryMenu,
		sortOrderHistoryMenu,
		startDate,
		lastDate,
	} = searchBarHistoryMenuValue;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const dispatch = useAppDispatch();

	const getHistoryBody: IGetHistoryRequest = {
		chatbotId,
		token,
		page: startFetchPageHistoryMenu.toString(),
		perPage: viewOnPageHistoryMenu,
		order: sortOrderHistoryMenu,
		search: searchBarValueHistoryMenu,
		from: startDate,
		to: lastDate,
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [objectTitlesForTable, setObjectTitlesForTable] = useState({
		titles: [
			{ valueGridElement: 'Prompt', valueJSX: <></>, widthElement: '150px' },
			{
				valueGridElement: 'Completion',
				valueJSX: <></>,
				widthElement: '100%',
			},
			{ valueGridElement: 'Created', valueJSX: <></>, widthElement: '150px' },
		],
	});
	const [rowArrayForTable, setRowArrayForTable] = useState<IGenericRow[]>();

	const getStringMonthFirstDate = (): string => {
		const todayMonth = (new Date().getMonth() + 1).toString();
		const stringMonthFirstDate =
			todayMonth.length > 1 ? todayMonth : '0'.concat(todayMonth);
		return stringMonthFirstDate;
	};

	const [countPerMonth, setCountPerMonth] = useState(0);

	const [getHistoryLazy] = useLazyGetHistoryRequestsQuery();

	const executeGetHistory = async (): Promise<void> => {
		const queryForHistoryCountPerMonth = {
			chatbotId,
			token,
			page: '1',
			perPage: '10000',
			search: '',
			order: 'prompt,DESC',
			from: `2023-${getStringMonthFirstDate()}-01T00:00:00.216Z`,
			to: new Date().toISOString(),
		};

		try {
			const result = await getHistoryLazy(queryForHistoryCountPerMonth);
			if ('data' in result && result.data !== undefined) {
				setCountPerMonth(result.data.totalResults);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		void executeGetHistory();
	}, []);

	const { data: dataHistory, refetch: refetchHistory } =
		useGetHistoryRequestsQuery(getHistoryBody);

	const handleRefreshHistory = (
		dataHistoryUpd: IGetHistoryResponse | undefined
	): void => {
		if (dataHistoryUpd !== undefined) {
			const mutatedHistoryForTable: IGenericRow[] = dataHistoryUpd.data.map(
				(currentHistoryItem) => {
					return {
						gridElementsRow: [
							{
								valueGridElement: currentHistoryItem.prompt.toString(),
								valueJSX: <></>,
								widthElement: '150px',
							},
							{
								valueGridElement: '',
								// valueGridElement: currentHistoryItem.completion,
								// valueJSX: <></>,
								valueJSX: (
									<ShowMoreText lines={4} className="showMoreText-component">
										{String(currentHistoryItem.completion)}
									</ShowMoreText>
								),
								widthElement: '100%',
								// widthElement: '350px',
							},
							{
								valueGridElement: redoDateToDaysAgo(currentHistoryItem.created),
								valueJSX: <></>,
								widthElement: '150px',
							},
						],
						actionButtons: [<></>],
					};
				}
			);
			setRowArrayForTable(mutatedHistoryForTable);

			if (totalResultsSnippetsHistoryMenu !== dataHistoryUpd.totalResults) {
				dispatch(setHistoryTotalResultsSnippets(dataHistoryUpd.totalResults));
			}
		}
	};

	// useEffect(() => {
	// 		const todayMonth = new Date().getMonth().toString();
	// 		const arrayCurrentMonthHistory = dataHistoryForMonthCount.data.filter(currentHistoryRequest => {
	// 			const month = currentHistoryRequest.created.toString().match(/-\d\d-/gmi);
	// 			if (month === null) { return false; } else {
	// 				const stringMonth = month[0].match(/\d\d/gmi)?.[0];
	// 				if (stringMonth !== undefined) {
	// 					return parseInt(stringMonth) === (parseInt(todayMonth) + 1);
	// 				} else { return false; };
	// 			};
	// 		});
	// 		setCountPerMonth(arrayCurrentMonthHistory.length);
	// }, [dataHistoryForMonthCount]);

	useEffect(() => {
		handleRefreshHistory(dataHistory);
	}, [dataHistory]);

	useEffect(() => {
		dispatch(setHistoryStartFetchPage(1));
		refetchHistory()
			.then((response) => {
				handleRefreshHistory(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [searchBarValueForRefetchingHistoryMenu]);

	useEffect(() => {
		refetchHistory()
			.then((response) => {
				handleRefreshHistory(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [
		searchBarValueForRefetchingHistoryMenu,
		viewOnPageHistoryMenu,
		startFetchPageHistoryMenu,
		finishFetchPageHistoryMenu,
		// searchBarValueForRefetchingHistoryMenu,
		startDate,
		lastDate,
	]);

	const handleActivateRefetchingHistory = (): void => {
		dispatch(
			activateSearchBarForRefetchingHistoryMenu(
				searchBarValueForRefetchingHistoryMenu
			)
		);
	};

	const [windowWidth, setWindowWidth] = useState(0);
	useEffect(() => {
		setWindowWidth(window.innerWidth);
	}, []);
	window.addEventListener('resize', () => {
		setWindowWidth(window.innerWidth);
	});

	const theme = {
		currentTheme,
	};

	const HistoryTable: React.FC = () => {
		if (rowArrayForTable !== undefined) {
			if (windowWidth >= 700) {
				return (
					<SharedDesktopTable
						table={{
							titles: objectTitlesForTable.titles,
							genericRows: rowArrayForTable,
						}}
						hasActionButton={false}
					/>
				);
			} else {
				return (
					<SharedMobileSnippetsTable
						table={{
							titles: objectTitlesForTable.titles,
							genericRows: rowArrayForTable,
						}}
						hasActionButton={false}
					/>
				);
			}
		} else {
			return null;
		}
	};

	const handleSortOrderForRefetchingExecute = (sortOrder: string): void => {
		dispatch(sortOrderForRefetchingHistoryMenu(sortOrder));
	};

	return (
		<StyledHistoryRequest theme={theme} className="historyRequestMenu">
			<Box component="div" className="historyRequestMenu-search-bar-field">
				<Box component="div" className="search-bar-title-subtitle-area">
					<Box component="div" className="search-bar-title">
						History of requests to your chatbot
					</Box>
					<Box component="div" className="search-bar-subtitle">
						Total requests this month: {countPerMonth}
					</Box>
				</Box>
				<SharedSearchBar
					uniqueNameOfInput="search-bar-input-historyRequestMenu"
					searchBarValue={searchBarValueHistoryMenu}
					handleActivateRefetchingFn={handleActivateRefetchingHistory}
					extraComponent={<></>}
				/>
			</Box>
			<Box
				component="div"
				className="viewOnPage-HistoryDateRangePicker-wrapper"
			>
				<HistoryDateRangePicker currentTheme={currentTheme} />
				<Box component="div" className="viewOnPage-wrapper">
					<SortComponentChoose
						sortOrderForRefetchingFn={handleSortOrderForRefetchingExecute}
						sortComponentsArray={[
							{ sortQuery: 'prompt', title: 'Prompt' },
							{ sortQuery: 'completion', title: 'Completion' },
							{ sortQuery: 'created', title: 'Created' },
						]}
					/>
					<SharedActionsViewOnPage
						uniqueNameOfInput="viewOnPage-historyRequestMenu"
						extraComponent={<></>}
						currentCountViewOnPage={viewOnPageHistoryMenu}
					/>
				</Box>
			</Box>
			<Box component="div" className="historyRequestMenu-table-field">
				<HistoryTable />
				{/* <TableSnippetshistoryRequestMenu
          snippets={chatbotSnippets}
          chatbotId={currentIdComputed.toString()}
        /> */}
			</Box>
			<Box component="div" className="pagination-table-field">
				{/* <PaginationTableSnippets /> */}
				<SharedPagination
					viewOnPage={viewOnPageHistoryMenu}
					totalResults={totalResultsSnippetsHistoryMenu}
					setValueFn={setHistoryStartFetchPage}
					currentPage={startFetchPageHistoryMenu}
				/>
			</Box>
		</StyledHistoryRequest>
	);
};
