import styled from 'styled-components';

export const StyledSharedPasswordInput = styled.div`
	position: relative;
	max-width: 470px;

	@media screen and (max-width: 1000px) {
		max-width: 550px;
		width: 70vmin;
	}

	@media screen and (max-width: 500px) {
		max-width: 550px;
		width: 85vmin;
	}
`;
