import React from 'react';
import { Box } from '@mui/material';
import { StyledSharedSearchBar } from './SharedSearchBar.style';
import { ReactComponent as SearchBlack } from '../pic/searchBlack.svg';
import { ReactComponent as SearchWhite } from '../pic/searchWhite.svg';
import { WrappedInput } from '../../Input/WrappedInput/WrappedInput';
import { useIsDarkTheme } from '../../../../app/hooks';

interface ISharedSearchBarProps {
	searchBarValue: string;
	handleActivateRefetchingFn: () => void;
	uniqueNameOfInput: string;
	extraComponent: JSX.Element;
}

export const SharedSearchBar: React.FC<ISharedSearchBarProps> = ({
	searchBarValue,
	handleActivateRefetchingFn,
	uniqueNameOfInput,
	extraComponent,
}) => {
	const isDarkTheme = useIsDarkTheme();

	return (
		<StyledSharedSearchBar>
			<Box component="form" className="search-input">
				<WrappedInput
					type="text"
					placeholder="Search"
					isEmpty={false}
					isValid={true}
					uniqueNameOfInput={uniqueNameOfInput}
					value={searchBarValue}
					autocomplete={false}
				/>
				<Box component="div" className="search-input-action-button">
					<Box component="button" disabled>
						{/* <Box component="button" onClick={handleActivateRefetchingFn}> */}
						{isDarkTheme ? <SearchWhite /> : <SearchBlack />}
					</Box>
				</Box>
			</Box>
			{extraComponent}
		</StyledSharedSearchBar>
	);
};
