import React from 'react';
import { Box } from '@mui/material';

interface IWrapperListAdvantagesProps {
	value: string;
	title: string;
	icon: React.ReactElement;
	positionVertical: boolean;
}

export const WrapperListAdvantages: React.FC<IWrapperListAdvantagesProps> = ({
	value,
	title,
	icon,
	positionVertical,
}) => {
	return (
		<Box
			component="div"
			className="wrapperListAdvantages"
			sx={{
				display: 'flex',
				flexDirection: {
					xs: 'column',
					sm: positionVertical ? 'column' : 'row',
				},
				gap: '2rem',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Box
				component="div"
				className="wrapperListAdvantages-icon-field"
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '.5rem',
				}}
			>
				<Box component="div" className="wrapperListAdvantages-icon">
					{icon}
				</Box>
				<Box component="div" className="wrapperListAdvantages-title">
					{title}
				</Box>
			</Box>
			<Box component="div" className="wrapperListAdvantages-text-field">
				{value}
			</Box>
		</Box>
	);
};
