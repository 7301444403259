import React, { useEffect } from 'react';
import { StyledLayoutAccount } from './LayoutAccount.style';
import { MemoizingNavbar } from '../../Navbar/Navbar';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { hideMenu, showMenu } from '../../../app/slices/showHideMenuSlice';
import { ReactComponent as Menu } from './pic/menu.svg';
import { ReactComponent as Close } from './pic/close.svg';
import { useLocation } from 'react-router-dom';
import { useClearSettingsJSGenerated } from '../../../app/hooks/useClearSettingsJSGenerated';
import { ModalAskLogout } from '../../Navbar/Logout/ModalAskLogout/ModalAskLogout';
import { logout } from '../../../app/utils/loginUtil';
import { setCloseOpenModalLogoutStatus } from '../../../app/slices/isOpenModalLogout/isOpenModalLogoutSlice';

interface ILayoutAccountProps {
	children: React.ReactNode;
	title: string;
	subtitle?: string;
}

export const LayoutAccount: React.FC<ILayoutAccountProps> = ({
	children,
	title,
	subtitle,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const isShowMenu = useAppSelector((state: RootState) => state.showHideMenu);
	const currentStep = useAppSelector(
		(state: RootState) => state.currentStepGenerator
	);
	const isOpenModal = useAppSelector(
		(state: RootState) => state.isOpenModalLogout
	);

	const dispatch = useAppDispatch();
	const handleToggleShowHideMenu = (): void => {
		isShowMenu ? dispatch(hideMenu()) : dispatch(showMenu());
	};

	const location = useLocation();
	const clearSettingsJSGenerated = useClearSettingsJSGenerated();

	const handleCloseModal = (): void => {
		dispatch(setCloseOpenModalLogoutStatus(false));
	};

	useEffect(() => {
		if (location.pathname !== '/chatbot-generator' && currentStep === 3) {
			clearSettingsJSGenerated();
		}
	}, [location]);

	return (
		<StyledLayoutAccount theme={currentTheme}>
			<Box component="div" className="layout-navbar-wrapper">
				<MemoizingNavbar />
			</Box>
			<Box component="div" className="layout-menu-wrapper">
				<Box component="div" className="layout-menu-title">
					{title}
					{subtitle !== undefined && Boolean(subtitle) ? (
						<Box component="div" className="layout-menu-subtitle">
							{subtitle}
						</Box>
					) : (
						<></>
					)}
				</Box>
				{children}
			</Box>
			<Box
				component="div"
				className="navbar-menu-icon-navigation"
				onClick={handleToggleShowHideMenu}
			>
				{isShowMenu ? (
					<Close className="close-icon" />
				) : (
					<Menu className="menu-icon" />
				)}
			</Box>
			{isOpenModal ? (
				<ModalAskLogout
					executeFn={() => {
						logout(dispatch);
					}}
					closeFn={handleCloseModal}
				/>
			) : (
				<></>
			)}
		</StyledLayoutAccount>
	);
};
