// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSharedChatbotGeneratorStepWrapper = styled.div`
	width: ${(props) => props.theme.width};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;

	& .addStylizationInputsField {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		width: max-content;
		justify-content: center;
		align-items: center;

		& .add-stylization-inputs-field {
			width: max-content;
		}

		& > .spoiler {
			width: 50%;
			height: 416px;
			min-width: 450px;

			& img {
				height: 100%;
				width: auto;
				/* height: 100%;
				background: grey;
				border-radius: 20px;
				border: 1px solid ${(props) => props.theme.currentTheme.mainFontColor}; */
			}

			& > .spoiler-choose-style {
				width: 100%;
				height: 100%;
				background: grey;
				border-radius: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-style: normal;
				font-weight: 500;
				font-size: 26px;
				line-height: 140%;
				color: #fff;
				position: relative;
				z-index: 700;
				overflow: hidden;

				&::after {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					transform: translateX(-100%);
					background-image: linear-gradient(
						90deg,
						rgba(#fff, 0) 0,
						rgba(#fff, 0.2) 20%,
						rgba(#fff, 0.5) 60%,
						rgba(#fff, 0)
					);
					animation: shimmer 2s infinite;
					content: '';
					z-index: 800;
					background: #ffffff10;
					filter: blur(50px);
				}
			}
		}
	}

	& .add-inputs-field {
		margin-bottom: 1.5rem;

		& .add-stylization-inputs-field {
			& > .add-url-tone {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 1rem;
				flex-direction: column !important;
			}
		}

		& > div {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;

			& .check-coderfy-key-field {
				display: flex;
				justify-content: left;
				gap: 1rem;
				position: relative;
				width: 100%;
				margin-top: 1.5rem;
				cursor: pointer;

				& .check-wrapper {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 24px;
					padding: 8px 24px;
					background-size: 100% 100%;
					max-width: 470px;
					height: 108px;
					align-self: flex-start;
					border-radius: 8px;

					& .check-text-field {
						display: flex;
						flex-direction: column;
						gap: 4px;
						color: ${(props) => props.theme.currentTheme.mainFontColor};

						& .check-text-field-description {
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 140%;
						}

						& .check-text-field-title {
							font-style: normal;
							font-weight: 400;
							font-size: 24px;
							line-height: 38px;
						}
					}
				}

				& .check-dashed-border {
					position: absolute;
					left: 0px;
					top: 0px;
				}
			}

			& .wrapperForArticleInput {
				display: flex;
				flex-direction: column;
				gap: 8px;

				& .wrapperForArticleInput-article {
					display: flex;
					color: ${(props) => props.theme.currentTheme.mainFontColor};
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 140%;
					align-self: flex-start;
				}
			}
		}
	}

	& .add-button {
		display: flex;
		gap: 1rem;
		width: 100%;
	}

	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}

	@media screen and (max-width: 1500px) {
		& .add-inputs-field {
			& > div {
				& .check-coderfy-key-field {
					& .check-wrapper {
						& .check-text-field {
							& .check-text-field-description {
								font-size: 16px;
							}

							& .check-text-field-title {
								font-size: 18px;
							}
						}
					}

					& .check-dashed-border {
						position: absolute;
						left: 0px;
						top: 0px;
					}
				}

				& .wrapperForArticleInput {
					& .wrapperForArticleInput-article {
						font-size: 16px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1300px) {
		& .addStylizationInputsField {
			flex-direction: column;
		}
	}

	@media screen and (max-width: 1000px) {
		& .add-inputs-field > div {
			& > div {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
			}
		}

		& .add-inputs-field {
			& > div {
				& .check-coderfy-key-field {
					& .check-wrapper {
						width: 70vmin;
						max-width: 70vmin;
					}
				}
			}
		}
	}

	@media screen and (max-width: 650px) {
		& .add-inputs-field > div {
			& .wrapperForArticleInput {
				& .wrapperForArticleInput-article {
					font-size: 16px;
				}
			}
		}

		& .addStylizationInputsField {
			& > .spoiler {
				width: 100%;
				height: 416px;
				min-width: 0px;
			}
		}
	}

	@media screen and (max-width: 600px) {
		& .add-inputs-field {
			& > div {
				& .check-coderfy-key-field {
					& .check-wrapper {
						flex-direction: column;
						height: max-content;

						& .check-text-field {
							& .check-text-field-description {
								text-align: center;
							}

							& .check-text-field-title {
								text-align: center;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 450px) {
		& .add-button {
			flex-direction: column-reverse;
		}

		& .addStylizationInputsField {
			& > .spoiler {
				width: 100%;
				height: 416px;
				min-width: 0px;

				& > img {
					height: auto;
					width: 85vw;
					background: grey;
					border-radius: 20px;
				}
			}
		}
	}
`;
