import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import { StyledModalExportSnippets } from './ModalAskVectorise.style';
import { SharedTransparentButton } from '../../shared/SharedTransparentButton/SharedTransparentButton';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { useVectoriseSnippet } from '../../PersonalCabinetMenu/hooks/useVectoriseSnippet';
import {
	setOpenModalAfterVectoriseOnlyAlertStatus,
	setOpenModalAskVectoriseStatus,
} from '../../../app/slices/trainingBotMenu/openModal/openVectoriseSnippetsTrainingBotMenuSlice';
import { useGetNotVectorisedSnippetsCount } from '../../PersonalCabinetMenu/hooks/useGetNotVectorisedSnippetsCount';
import { ProgressDots } from '../../shared/ProgressDots/ProgressDots';

interface IModalAskVectoriseProps {
	closeFn: () => void;
	chatbotId: string;
}

interface IAskVectoriseComponentProps {
	closeFn: () => void;
	chatbotId: string;
}

const AskVectoriseComponent: React.FC<IAskVectoriseComponentProps> = ({
	closeFn,
	chatbotId,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = { currentTheme };

	const dispatch = useAppDispatch();

	const getCountNotVectorisedSnippets = useGetNotVectorisedSnippetsCount();
	const vectoriseSnippets = useVectoriseSnippet(parseInt(chatbotId));

	const [isPresentNotVectorisedSnippets, setIsPresentNotVectorisedSnippets] =
		useState(false);

	const [messageInModalToVectorise, setMessageInModalToVectorise] =
		useState<JSX.Element | null>(null);

	const getCount = async (): Promise<void> => {
		setMessageInModalToVectorise(<ProgressDots />);
		const countNotVectorisedSnippetsResponse =
			await getCountNotVectorisedSnippets(parseInt(chatbotId));
		if (countNotVectorisedSnippetsResponse > 0) {
			setIsPresentNotVectorisedSnippets(true);
			setMessageInModalToVectorise(
				<>
					Are you sure you want to vectorise{' '}
					{countNotVectorisedSnippetsResponse} snippets?
				</>
			);
		} else {
			setIsPresentNotVectorisedSnippets(false);
			setMessageInModalToVectorise(<>All snippets are already vectorized</>);
		}
	};

	useEffect(() => {
		void getCount();
	}, [chatbotId]);

	const executeVectoriseFn = (): void => {
		openModalVectoriseAfterExecute();
		vectoriseSnippets().catch((error) => {
			console.log(error);
		});
	};

	const openModalVectoriseAfterExecute = (): void => {
		dispatch(setOpenModalAskVectoriseStatus(false));
		dispatch(setOpenModalAfterVectoriseOnlyAlertStatus(true));
	};

	const handleCloseModal = (): void => {
		closeFn();
	};

	return (
		<StyledModalExportSnippets theme={theme}>
			<Box component="div" className="askVectoriseMenuComponent-wrapper">
				<Box component="div" className="askVectoriseMenu-title-subtitle">
					<Box component="div" className="askVectoriseMenu-subtitle">
						{messageInModalToVectorise}
					</Box>
				</Box>
				{/* <Box
					component="div"
					className="askVectoriseMenu-answer-loading-content"
				>
					
				</Box> */}
				<Box component="div" className="askVectoriseMenu-input-url">
					<Box component="div" className="askVectoriseMenu-exe-buttons">
						{isPresentNotVectorisedSnippets ? (
							<Box component="div" className="askVectoriseMenu-wrapper-button">
								<SharedMainButton
									isDeleteButton={false}
									text="Yes"
									buttonFunction={executeVectoriseFn}
								/>
							</Box>
						) : (
							<></>
						)}
						<Box component="div" className="askVectoriseMenu-wrapper-button">
							<SharedTransparentButton
								text={isPresentNotVectorisedSnippets ? 'No' : 'Cancel'}
								buttonFunction={handleCloseModal}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</StyledModalExportSnippets>
	);
};

export const ModalAskVectorise: React.FC<IModalAskVectoriseProps> = ({
	closeFn,
	chatbotId,
}) => {
	return (
		<SharedModal
			closeFn={closeFn}
			executeFn={() => {}}
			message={null}
			title=""
			isOnlyAlert={false}
			standaloneFC={
				<AskVectoriseComponent chatbotId={chatbotId} closeFn={closeFn} />
			}
		/>
	);
};
