import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import {
	setEmptyStatusForCheckChatGeneratorFirstStep,
	setEmptyStatusForCheckChatGeneratorSecondStep,
} from '../../../app/slices/inputsGenerator/checkEmptyStatusForChatGeneratorSlice';
import { setCurrentStepGenerator } from '../../../app/slices/currentStepGeneratorSlice';
import { SharedChatbotGeneratorStepWrapper } from '../SharedChatbotGeneratorStepWrapper/SharedChatbotGeneratorStepWrapper';
import { AddStylizationInputsField } from './AddStylizationInputsField';
import { setCodeJSGeneratedStatus } from '../../../app/slices/isCodeJSGeneratedSlice';
import { getItem, setItem } from '../../../app/utils/storageUtils';
import {
	useCreateBotMutation,
	useLazyGetChatbotStyleByTemplateQuery,
	useUpdateIconCurrentBotMutation,
} from '../../../app/api/chatbot.api';
import {
	setAnswerApiCreateBotErrorMessage,
	setAnswerApiCreateBotStatus,
} from '../../../app/slices/answersFromAPI/answerApiCreateBotSlice';
import {
	type IChatBotData,
	type IResponseCreateBotSuccess,
	type IResponseDataWithError,
} from '../../../app/api/interfaces/interfaces';
import { ProgressSpinner } from '../../shared/ProgressSpinner/ProgressSpinner';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import { SharedPreviewChatbot } from '../../shared/SharedPreviewChatbot/SharedPreviewChatbot';
import { domainWithoutHttp } from '../../../app/utils/utils';
import { type IGetChatbotStyleByTemplateStylesList } from '../../../app/api/interfaces/styleChatbot/getChatbotStyleByTemplate';
import { DataAlert } from '../../shared/SharedDataAlertFromApi/SharedDataAlertFromApi';
import { ProgressDots } from '../../shared/ProgressDots/ProgressDots';
import { setLinkCurrentChatbotImg } from '../../../app/slices/inputsGenerator/linkCurrentChatbotImgSlice';
import {
	setButtonTextGeneratorMenu,
	setHeaderGeneratorMenu,
	setStyleGeneratorMenu,
	setToneGeneratorMenu,
} from '../../../app/slices/inputsGenerator/chatGeneratorInputsValueSlice';
import { setIconChatbotChoosingForCreationChatbot } from '../../../app/slices/iconChatbot/iconChatbotSlice';
import { setChatbotIdForHTMLCode } from '../../../app/slices/inputsGenerator/currentChatbotIdForHTMLCodeSlice';

interface IDataResponseCreateBot {
	data: IResponseCreateBotSuccess;
}

export const AddStylizationComponent: React.FC = () => {
	const validInputsStatus = useAppSelector(
		(state: RootState) => state.isValidChatGeneratorInputs
	);
	const {
		isValidToneGeneratorMenu,
		isValidStyleGeneratorMenu,
		isValidButtonTextGeneratorMenu,
		isValidHeaderGeneratorMenu,
	} = validInputsStatus;

	const inputsValues = useAppSelector(
		(state: RootState) => state.chatGeneratorInputsValue
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { header, buttonText, tone, domain, style, openAIKey, reCaptchaKey } =
		inputsValues;

	const currentStep = useAppSelector(
		(state: RootState) => state.currentStepGenerator
	);
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);
	const errorMessage = useAppSelector(
		(state: RootState) => state.answerApiCreateBot.errorMessage
	);
	const iconChatbotChoosing = useAppSelector(
		(state: RootState) =>
			state.iconChatbot.iconChatbotChoosingForCreationChatbot
	);

	const dispatch = useAppDispatch();

	const [stylesListByTemplateId, setStylesListByTemplateId] =
		useState<IGetChatbotStyleByTemplateStylesList[]>();
	const [isIconTemplate, setIsIconTemplate] = useState(false);
	const [templateId, setTemplateId] = useState(0);
	const [styleId, setStyleId] = useState(0);

	const [getChatbotStyleByTemplate, statusGettingChatbotStyleByTemplate] =
		useLazyGetChatbotStyleByTemplateQuery();
	const [dataUpdateIcon] = useUpdateIconCurrentBotMutation();

	const executeGetChatbotStyleByTemplate = async (
		token: string,
		chosenTemplateId: string
	): Promise<void> => {
		try {
			const resultGettingChatbotStyleByTemplate =
				await getChatbotStyleByTemplate({
					token,
					id: chosenTemplateId,
				});
			if ('data' in resultGettingChatbotStyleByTemplate) {
				setStylesListByTemplateId(
					resultGettingChatbotStyleByTemplate.data?.styleCss
				);
			} else {
				setLoadingErrorContent(
					<DataAlert text="Something went wrong" isError />
				);
			}
		} catch (error) {
			setLoadingErrorContent(<DataAlert text="Something went wrong" isError />);
		}
	};

	useEffect(() => {
		if (statusGettingChatbotStyleByTemplate.isLoading) {
			setLoadingErrorContent(<ProgressDots />);
		} else if (statusGettingChatbotStyleByTemplate.isError) {
			setLoadingErrorContent(
				<DataAlert
					text="Something went wrong. Please, reload the page"
					isError
				/>
			);
		} else if (statusGettingChatbotStyleByTemplate.isSuccess) {
			setLoadingErrorContent(null);
		}
	}, [statusGettingChatbotStyleByTemplate]);

	useEffect(() => {
		const chatbotData = getItem('chatbotData');
		if (chatbotData !== null && 'chosenTemplateId' in JSON.parse(chatbotData)) {
			const templateId = JSON.parse(chatbotData).chosenTemplateId;
			setTemplateId(parseInt(templateId));
			const chosenTemplateId: string = templateId;
			chosenTemplateId === '5' && setIsIconTemplate(true);
			void executeGetChatbotStyleByTemplate(token, chosenTemplateId);
		}
	}, []);

	useEffect(() => {
		const currentLinkChatbotImg = stylesListByTemplateId?.find(
			(currentStyle) => currentStyle.name === style
		)?.imageUrl;
		const currentStyleId = stylesListByTemplateId?.find(
			(currentStyle) => currentStyle.name === style
		)?.id;
		currentLinkChatbotImg !== undefined &&
			dispatch(setLinkCurrentChatbotImg(currentLinkChatbotImg));
		currentStyleId !== undefined && setStyleId(currentStyleId);
	}, [style]);

	// const chatbotDataFromStorage = getItem('chatbotData');

	// const valueOfOpenAiKey =
	// 	chatbotDataFromStorage !== null &&
	// 	JSON.parse(chatbotDataFromStorage).openAIKey !== 'none'
	// 		? JSON.parse(chatbotDataFromStorage).openAIKey
	// 		: 'none';
	// const valueOfReCaptchaKey =
	// 	chatbotDataFromStorage !== null &&
	// 	JSON.parse(chatbotDataFromStorage).reCaptchaKey !== 'none'
	// 		? JSON.parse(chatbotDataFromStorage).reCaptchaKey
	// 		: 'none';
	// const valueOfReCaptchaSecretKey =
	// 	chatbotDataFromStorage !== null &&
	// 	JSON.parse(chatbotDataFromStorage).reCaptchaSecretKey !== 'none'
	// 		? JSON.parse(chatbotDataFromStorage).reCaptchaSecretKey
	// 		: 'none';

	const [createBot, resultOfCreationBot] = useCreateBotMutation();

	const createBotData: IChatBotData = {
		tone,
		// style,
		styleCss: styleId,
		structure: templateId,
		// domain: `https://${domain}/`,
		domain: domainWithoutHttp(domain),
		// openAiKey: valueOfOpenAiKey,
		// reCaptcha: valueOfReCaptchaKey,
		// reCaptchaSecret: valueOfReCaptchaSecretKey,
		askMe: header,
		button: buttonText,
	};

	const handlePreviousStep = (): void => {
		dispatch(setStyleGeneratorMenu(''));
		dispatch(setHeaderGeneratorMenu(''));
		dispatch(setButtonTextGeneratorMenu(''));
		dispatch(setToneGeneratorMenu(''));
		dispatch(setCurrentStepGenerator(currentStep - 1));
		dispatch(setEmptyStatusForCheckChatGeneratorFirstStep(false));
		dispatch(setEmptyStatusForCheckChatGeneratorSecondStep(false));
		setItem('currentStepGenerator', JSON.stringify(currentStep - 1));
		dispatch(setAnswerApiCreateBotErrorMessage([]));
		dispatch(setAnswerApiCreateBotStatus(false));
	};

	const finishCreationNextStep = (): void => {
		dispatch(setCurrentStepGenerator(3));
		dispatch(setCodeJSGeneratedStatus(true));
		const chatbotData = getItem('chatbotData');
		chatbotData !== null &&
			setItem(
				'chatbotData',
				JSON.stringify({
					...JSON.parse(chatbotData),
					header,
					buttonText,
					tone,
					style,
				})
			);
		setItem('currentStepGenerator', '3');
		setItem('codeJSGeneratedStatus', 'true');
		dispatch(setAnswerApiCreateBotStatus(true));
		dispatch(setAnswerApiCreateBotErrorMessage([]));

		dispatch(setIconChatbotChoosingForCreationChatbot(''));
	};

	const handlePressStartButton = (): void => {
		// console.log(domain);
		dispatch(setAnswerApiCreateBotErrorMessage([]));

		dispatch(setEmptyStatusForCheckChatGeneratorSecondStep(true));
		if (
			header.length > 0 &&
			buttonText.length > 0 &&
			tone.length > 0 &&
			style.length > 0 &&
			isValidToneGeneratorMenu &&
			isValidStyleGeneratorMenu &&
			isValidButtonTextGeneratorMenu &&
			isValidHeaderGeneratorMenu
		) {
			createBot({
				createBotData,
				token,
			})
				.then((response: IDataResponseCreateBot | IResponseDataWithError) => {
					// console.log(createBotData);
					// console.log(response);
					if (
						'data' in response ||
						('error' in response &&
							response.error.data.error === 'Error sending email')
					) {
						if ('data' in response) {
							dispatch(setChatbotIdForHTMLCode(response.data.id.toString()));
						}
						if (
							isIconTemplate &&
							'data' in response &&
							iconChatbotChoosing !== ''
						) {
							const createdChatbotData = response.data;
							const patchDataForUpdateChatbotIcon = {
								id: createdChatbotData.id,
								token,
								patchData: { file: iconChatbotChoosing },
							};
							// console.log(patchDataForUpdateChatbotIcon);
							dataUpdateIcon(patchDataForUpdateChatbotIcon)
								.then((updateIconChatbotResponse) => {
									// console.log(
									// 	'updateIconChatbotResponse',
									// 	updateIconChatbotResponse
									// );
									return updateIconChatbotResponse;
								})
								.catch(
									(updateIconChatbotResponseError) =>
										updateIconChatbotResponseError
								)
								.finally(() => {
									finishCreationNextStep();
								});
						} else {
							finishCreationNextStep();
						}
					} else if ('error' in response) {
						// console.log(response.error.data.error);
						dispatch(setAnswerApiCreateBotStatus(false));
						dispatch(
							setAnswerApiCreateBotErrorMessage(response.error.data.error)
						);
					} else {
						console.log('');
						// console.log('Empty response');
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch(
						setAnswerApiCreateBotErrorMessage('Server error: Failed to fetch')
					);
				});
		}
	};

	useEffect(() => {
		resultOfCreationBot.isLoading &&
			setLoadingErrorContent(<ProgressSpinner />);
		resultOfCreationBot.isError &&
			setLoadingErrorContent(
				<Box
					component="div"
					className="error-message-from-api"
					sx={{ color: 'red' }}
				>
					Something went wrong. Please try again or contact support
				</Box>
			);
		// resultOfCreationBot.isError && console.log(resultOfCreationBot);
	}, [resultOfCreationBot, errorMessage]);

	return (
		<SharedChatbotGeneratorStepWrapper>
			<Box
				component="div"
				className="add-inputs-field addStylizationInputsField"
			>
				<AddStylizationInputsField
					isIconTemplate={isIconTemplate}
					stylesCssParams={
						stylesListByTemplateId !== undefined
							? stylesListByTemplateId.map((currentStyle) => currentStyle.name)
							: []
					}
				/>
				<Box component="div" className="add-url-tone spoiler">
					<SharedPreviewChatbot style={style} isCoderfy />
				</Box>
			</Box>
			{loadingErrorContent !== null ? loadingErrorContent : <></>}
			<Box component="div" className="add-button">
				<SharedMainButton
					isDeleteButton={false}
					text="Back"
					buttonFunction={handlePreviousStep}
				/>
				<SharedMainButton
					isDeleteButton={false}
					text="Next"
					buttonFunction={handlePressStartButton}
					tooltip="Complete the settings and create a chatbot"
				/>
			</Box>
		</SharedChatbotGeneratorStepWrapper>
	);
};
