import React from 'react';
import { Box } from '@mui/material';
import arrow from '../pic/arrow.svg';
import {
	type IStatusLoadPaymentsHistory,
	type IPayment,
} from './HistoryPayment';

interface IOpenCloseHistoryButtonProps {
	openCloseHistoryStatus: boolean;
	setOpenCloseHistoryFn: (
		openCloseHistoryStatus: React.SetStateAction<boolean>
	) => void;
	executeFnGetHistory: () => Promise<IPayment[] | null>;
	refetchFn: () => Promise<void>;
	statusPaymentsHistory: IStatusLoadPaymentsHistory;
}

export const OpenCloseHistoryButton: React.FC<IOpenCloseHistoryButtonProps> = ({
	openCloseHistoryStatus,
	setOpenCloseHistoryFn,
	executeFnGetHistory,
	refetchFn,
	statusPaymentsHistory,
}) => {
	const handleGetHideRefetchHistoryByClick = (): void => {
		void executeFnGetHistory();

		setOpenCloseHistoryFn(!openCloseHistoryStatus);
	};

	return (
		<Box
			component="div"
			className="plan-menu-payment-field-history-btn"
			onClick={handleGetHideRefetchHistoryByClick}
			sx={{
				display: 'flex',
				alignItems: 'center',
				cursor: 'pointer',
				color: '#1592ec',
				fontSize: '14px',
				fontStyle: 'normal',
				fontWeight: '400',
				lineHeight: '25px',
				padding: '1rem',
			}}
		>
			<Box component="span">Payment History</Box>
			<Box
				component="img"
				src={arrow}
				sx={{
					transform: `rotate(${openCloseHistoryStatus ? '180deg' : '0deg'})`,
				}}
			/>
		</Box>
	);
};
