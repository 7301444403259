import React from 'react';
import { Box } from '@mui/material';
import { ResetPasswordMenu } from '../components/ResetPasswordMenu/ResetPasswordMenu';
import { Navigate, useLocation } from 'react-router-dom';

export const ResetPasswordPage: React.FC = () => {
	const dataFromUrl = useLocation();

	return (
		<Box component="div" className="resetPassword-page">
			{dataFromUrl.search !== '' ? (
				<ResetPasswordMenu dataFromUrl={dataFromUrl.search} />
			) : (
				<Navigate to="/personal-cabinet" replace={true} />
			)}
		</Box>
	);
};
