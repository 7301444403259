import type React from 'react';
import { useEffect } from 'react';
import { useDeleteAllSnippetsMutation } from '../../../app/api/chatbot.api';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setDeleteAllSnippetsMessage } from '../../../app/slices/trainingBotMenu/deleteAllSnippetsMessageTrainingBotMenuSlice';

export const useDeleteAllSnippets = (): ((
	chatbotId: string,
	setStateFn: (value: React.SetStateAction<boolean>) => void
) => Promise<void>) => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const [deleteAllSnippets, resultOfDeletingAllSnippets] =
		useDeleteAllSnippetsMutation();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (resultOfDeletingAllSnippets.isError) {
			dispatch(setDeleteAllSnippetsMessage('error'));
		} else if (resultOfDeletingAllSnippets.isLoading) {
			dispatch(setDeleteAllSnippetsMessage('loading'));
		} else if (resultOfDeletingAllSnippets.isSuccess) {
			dispatch(setDeleteAllSnippetsMessage('success'));
		}
	}, [resultOfDeletingAllSnippets]);

	useEffect(() => {
		dispatch(setDeleteAllSnippetsMessage(''));
	}, []);

	return async (
		chatbotId: string,
		setStateFn: (value: React.SetStateAction<boolean>) => void
	): Promise<void> => {
		const deleteSnippetBody = {
			token,
			id: chatbotId,
		};
		try {
			const result = await deleteAllSnippets(deleteSnippetBody);
			if ('data' in result) {
				dispatch(setDeleteAllSnippetsMessage('success'));
				setStateFn(false);
			} else {
				dispatch(setDeleteAllSnippetsMessage('error'));
			}
		} catch (error) {
			dispatch(setDeleteAllSnippetsMessage('error'));
		}
	};
};
