import React from 'react';
import { Box } from '@mui/material';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';

interface ISearchBarButtonsFieldProps {
	openModalImportUrlFn: () => void;
	openModalCreateSnippetFn: () => void;
}

export const SearchBarButtonsField: React.FC<ISearchBarButtonsFieldProps> = ({
	openModalImportUrlFn,
	openModalCreateSnippetFn,
}) => {
	return (
		<Box component="div" className="buttons-field">
			<Box component="div" className="button-add-wrapper">
				<SharedMainButton
					text="Add"
					buttonFunction={openModalCreateSnippetFn}
					isDeleteButton={false}
					tooltip="Add settings how you would like the chatbot to answer questions"
				/>
			</Box>
			<Box component="div" className="button-import-wrapper">
				<SharedMainButton
					text="Import"
					buttonFunction={openModalImportUrlFn}
					isDeleteButton={false}
					tooltip="Import ready-made snippets from the website"
				/>
			</Box>
		</Box>
	);
};
