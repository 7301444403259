/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledContactsMenu = styled.div`
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 6rem 4rem !important;
	background: #0e172d;

	& .contacts-wrapper-border {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background: ${(props) =>
			`linear-gradient(${
				props.theme.currentTheme.mainRootBackground === '#1A1D21'
					? '90deg'
					: '180deg'
			}, ${props.theme.currentTheme.cardsGradient.one}, ${
				props.theme.currentTheme.cardsGradient.two
			})`};
		padding: 1px !important;

		& .contacts-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;

			border-radius: 30px;
			background: ${(props) => props.theme.currentTheme.cardsBackground};

			justify-content: space-between;
			gap: 24px;
			padding: 56px;

			& .contacts-title-area {
				color: ${(props) => props.theme.currentTheme.mainFontColor};
				font-style: normal;
				font-weight: 600;
				font-size: 32px;
				line-height: 48px;
			}

			& .contacts-inputs-area {
				display: flex;
				flex-direction: column;
				gap: 3rem;
				justify-content: flex-start;
				align-items: center;
			}

			& .info-recaptcha-wrapper {
				max-width: 470px;
				text-align: center;

				& a {
					padding: 0px 5px;
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .contacts-wrapper-border {
			& .contacts-wrapper {
				padding: 24px;

				& .contacts-title-area {
					font-size: 24px;
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		padding: 0.5rem !important;

		& .contacts-wrapper-border {
			& .contacts-wrapper {
				padding: 16px;
			}
		}
	}
`;
