import React from 'react';
import { Box } from '@mui/material';
import { StyledNavbarTrainingBotMenu } from './NavbarTrainingBotMenu.style';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setCurrentPage } from '../../../app/slices/trainingBotMenu/currentPageTrainingBotMenuSlice';

export const NavbarTrainingBotMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const currentPage = useAppSelector(
		(state: RootState) => state.currentPageTrainingBotMenu.currentPage
	);

	const dispatch = useAppDispatch();

	const navList = [
		{
			title: 'Training data',
			isChecked: currentPage === 'Training data',
		},
		{ title: 'History', isChecked: currentPage === 'History' },
		{ title: 'Settings', isChecked: currentPage === 'Settings' },
		{ title: 'Model', isChecked: currentPage === 'Model' },
		{ title: 'OpenAI', isChecked: currentPage === 'OpenAI' },
	];

	const theme = {
		currentTheme,
	};

	return (
		<StyledNavbarTrainingBotMenu
			className="navbarTrainingBotMenu"
			theme={theme}
		>
			{navList.map((currentNavElement) => (
				<Box
					component="div"
					className={`
              currentNavElement 
              ${currentNavElement.isChecked ? 'currentStyle' : ''}
            `}
					key={currentNavElement.title}
					onClick={() => {
						dispatch(setCurrentPage(currentNavElement.title));
					}}
				>
					{currentNavElement.title}
				</Box>
			))}
		</StyledNavbarTrainingBotMenu>
	);
};
