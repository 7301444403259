import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { InputsFieldWrapper } from '../../shared/Input/InputsFieldWrapper/InputsFieldWrapper';
import { WrappedInput } from '../../shared/Input/WrappedInput/WrappedInput';

export const InputsFieldForgot: React.FC = () => {
	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForForgot
	);
	const validInputsStatusForgot = useAppSelector(
		(state: RootState) => state.isValidForgotInputValue
	);
	const { isValidEmailForgot } = validInputsStatusForgot;

	return (
		<InputsFieldWrapper>
			<WrappedInput
				type="email"
				placeholder="E-mail"
				isEmpty={isButtonPressed}
				uniqueNameOfInput="e-mail-forgot"
				isDarkBack={true}
				isValid={isValidEmailForgot}
			/>
		</InputsFieldWrapper>
	);
};
