// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialValidLoginState {
	isValidDomainLogin: boolean;
	isValidEmailLogin: boolean;
	isValidPasswordLogin: boolean;
}

const initialState: IInitialValidLoginState = {
	isValidDomainLogin: false,
	isValidEmailLogin: false,
	isValidPasswordLogin: false,
};

const isValidInputsSlice = createSlice({
	name: 'isValidInputs',
	initialState,
	reducers: {
		setValidStatusDomainLogin(state, action: PayloadAction<boolean>) {
			state.isValidDomainLogin = action.payload;
			return state;
		},
		setValidStatusEmailLogin(state, action: PayloadAction<boolean>) {
			state.isValidEmailLogin = action.payload;
			return state;
		},
		setValidStatusPasswordLogin(state, action: PayloadAction<boolean>) {
			state.isValidPasswordLogin = action.payload;
			return state;
		},
	},
});

export const {
	setValidStatusDomainLogin,
	setValidStatusEmailLogin,
	setValidStatusPasswordLogin,
} = isValidInputsSlice.actions;
export const isValidInputsReducer = isValidInputsSlice.reducer;
