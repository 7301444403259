import React from 'react';
import { Box } from '@mui/material';
import { ProgressDots } from '../../components/shared/ProgressDots/ProgressDots';

export const loadingErrorContent = (
	message: string,
	successfulMessage: string
): JSX.Element | null => {
	switch (message) {
		case 'error':
			return (
				<Box component="div" className="error-message-from-api">
					{' '}
					Something went wrong{' '}
				</Box>
			);

		case 'loading':
			return <ProgressDots />;

		case 'success':
			return (
				<Box component="div" className="message-from-api">
					{successfulMessage}
				</Box>
			);

		default:
			return null;
	}
};
