import React from 'react';
import { Box } from '@mui/material';
import { ForgotMenu } from '../components/ForgotMenu/ForgotMenu';

export const ForgotPasswordPage: React.FC = () => {
	return (
		<Box component="div" className="forgot-password-page">
			<ForgotMenu />
		</Box>
	);
};
