import styled from 'styled-components';

export const StyledPaymentMethodsChoosingField = styled.div`
	border-radius: 8px;
	width: 100%;
	border: 1px solid ${(props) => props.theme.currentTheme.borderInputs};
	overflow: hidden;

	& .customCardCheckbox {
		position: relative;
		padding: 16px;

		& label {
			width: 100%;
			display: flex;
			gap: 20px;

			& span {
				font-family: 'Nunito';
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 28px;
			}
		}

		& .customCardCheckbox-brand-pic {
			position: absolute;
			top: 14px;
			right: 45px;
		}

		& .customCardCheckbox-delete-pic {
			width: 40px;
			height: 100%;
			/* background: #E34C4C; */
			/* border-left: 1px solid #E34C4C; */
			color: red;
			position: absolute;
			top: 0px;
			right: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			&:hover {
				background: #e34c4c40;
			}

			& svg path {
				fill: #e34c4c;
			}
		}
	}

	& .customCardCheckbox.isCard {
		border-bottom: 1px solid ${(props) => props.theme.currentTheme.borderInputs};
	}

	& .customCardCheckbox.isNotChecked {
		& label {
			& span {
				color: ${(props) => props.theme.currentTheme.disabledColor};
			}
		}
	}

	& .customCardCheckbox.isChecked {
		& label {
			& span {
				color: ${(props) => props.theme.currentTheme.mainFontColor};
			}
		}
	}

	@media screen and (min-width: 1500px) {
		& .customCardCheckbox {
			& .customCardCheckbox-brand-pic {
				top: 27px;
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .customCardCheckbox {
			padding: 0px;
			padding-left: 16px;
			height: 50px;
			display: flex;
			align-items: center;
		}
	}

	@media screen and (max-width: 410px) {
		& .customCardCheckbox {
			position: relative;
			padding: 16px;

			& label {
				gap: 5px;

				& span {
					font-size: 14px;
				}
			}
		}
	}
`;
