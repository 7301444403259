import React from 'react';
import { Box } from '@mui/material';
import { StyledWhatsAppGetToken } from './WhatsAppGetToken.style';
import arrow from '../../../../pic/arrow.svg';
import { useAppSelector } from '../../../../../../app/hooks';
import { type RootState } from '../../../../../../app/store';
import whatsAppGetToken from '../../../../pic/whatsAppGenerateToken.png';
import whatsAppAccessToken from '../../../../pic/whatsAppAccessToken.png';

export const WhatsAppGetToken: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	return (
		<StyledWhatsAppGetToken theme={theme} className="messageExample-wrapper">
			<Box component="div" className="messageExample-arrow">
				<Box component="img" src={arrow} alt="arrow-pic" />
			</Box>
			<Box component="div" className="messageExample-msg-box">
				<Box
					component="img"
					className="step-Element-img-first"
					src={whatsAppGetToken}
				/>
			</Box>
			<Box component="div" className="messageExample-msg-box">
				<Box
					component="img"
					className="step-Element-img-second"
					src={whatsAppAccessToken}
				/>
			</Box>
		</StyledWhatsAppGetToken>
	);
};
