import { Box } from '@mui/material';
import React from 'react';
import { SharedPasswordInput } from '../shared/Input/SharedPasswordInput/SharedPasswordInput';
import { useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';

export interface IUserInformation {
	title: string;
	value: string;
	id: string;
	type: string;
}

interface IChangePasswordField {
	fields: IUserInformation[];
}

export const ChangePasswordField: React.FC<IChangePasswordField> = ({
	fields,
}) => {
	const isSendChangePasswordInformationButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForChangePassword
	);
	const validInputsStatusChangeInformation = useAppSelector(
		(state: RootState) => state.isValidChangeInformationValues
	);
	const { isValidPasswordChangeInfo, isValidPasswordRepeatChangeInfo } =
		validInputsStatusChangeInformation;

	return (
		<Box component="div" className="currentInformation-wrapper-password">
			{fields.map((currentInformation) => (
				<Box
					component="div"
					key={currentInformation.id}
					className="password-field"
				>
					<Box component="div" className="password-title">
						{currentInformation.title}
					</Box>
					<Box component="div" className="password-value">
						<SharedPasswordInput
							key={currentInformation.id}
							withIcons={false}
							isEmpty={isSendChangePasswordInformationButtonPressed}
							uniqueNameOfInput={currentInformation.id}
							isDarkBack={false}
							value={currentInformation.value}
							autocomplete={false}
							isValid={
								currentInformation.id === 'password-change-info'
									? isValidPasswordChangeInfo
									: currentInformation.id === 'password-change-info-repeat' &&
									  isValidPasswordRepeatChangeInfo
							}
						/>
					</Box>
				</Box>
			))}
		</Box>
	);
};
