import styled from 'styled-components';

export const StyledSharedChangeIconComponent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: max-content;
	margin-bottom: 1rem;

	& .change-icon-modal-Menu {
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 950;
		width: 100vw;
		height: 100vh;
		background: #00000080;
		display: flex;
		justify-content: center;
		align-items: center;

		& .wrapper-change-icon-modal-menu-window {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 56px 100px;
			width: 520px;
			height: max-content;
			border-radius: 20px;
			font-weight: 600;
			font-size: 32px;
			line-height: 38px;
			gap: 48px;
			background: ${(props) => props.theme.currentTheme.cardsModalBackground};
			border: 1px solid ${(props) => props.theme.currentTheme.borderInMenu};
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			padding-top: 90px;

			& .modal-menu-high-field {
				display: flex;
				/* justify-content: space-between; */
				gap: 23%;
				align-items: center;
				width: 100%;
				position: absolute;
				top: 3%;
				right: 0px;
				padding-left: 5%;
				font-size: 22px;
				font-style: normal;
				font-weight: 400;
				line-height: 145%;

				& .action-button-back-next {
					display: flex;
					align-items: center;
					gap: 8px;
					cursor: ${(props) => props.theme.cursorSelect};
					opacity: ${(props) => props.theme.isDisable};

					& .action-button-back-next-arrow {
						display: flex;
						justify-content: center;
						align-items: center;
						transform: ${(props) => props.theme.rotate};
					}
				}
			}

			& .selectImageStage {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				gap: 48px;

				& .modal-ChooseImage-pic {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 300px;
					height: 300px;

					& img {
						width: 100%;
						height: auto;
					}
				}

				& .user-image-input-form-control {
					position: relative;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					border: 1px solid #5e17eb;
					width: 100%;
					height: 70px;
					border-radius: 10px;
					background: #5e17eb;
					cursor: pointer;
					padding: 1rem;
					transition: all 0.5s;
					position: relative;
					overflow: hidden;

					color: #fff;
					font-style: normal;
					font-size: 22px;
					line-height: 100%;

					&:hover {
						background: ${(props) =>
							props.theme.currentTheme.cardsModalBackground};
						color: ${(props) => props.theme.currentTheme.mainFontColor};
					}

					&:active {
						background: ${(props) =>
							props.theme.currentTheme.cardsModalBackground};
						color: ${(props) => props.theme.currentTheme.mainFontColor};
						transform: scale(0.95);
					}

					& #user-image-input {
						width: 0.1px;
						height: 0.1px;
						opacity: 0;
						overflow: hidden;
						position: absolute;
						z-index: -1;
					}
				}

				& .modal-ChooseImage-text {
					font-size: 22px;
					font-style: normal;
					font-weight: 400;
					line-height: 145%;
				}
			}

			& .modal-menu-close-btn {
				position: absolute;
				top: 5%;
				right: 5%;
				cursor: pointer;
				transition: all 0.1s linear;

				&:hover {
					transform: scale(1.1);
				}

				& svg {
					width: 30px;
					height: 30px;

					& path {
						stroke: ${(props) => props.theme.currentTheme.mainFontColor};
					}
				}
			}

			& .cropImageStage {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 3rem;
			}
		}
	}
`;
