/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledPlanMenu = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 6rem 4rem;
	background: #0e172d;
	color: ${(props) => props.theme.currentTheme.mainFontColor};

	& .plan-menu-wrapper {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: 1.5rem;

		& .plan-menu {
			display: flex;
			width: 100%;
			gap: 3rem;
			flex-wrap: wrap;
			justify-content: center;

			& .plan-menu-current-plan-area {
				position: relative;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 1px;
				height: max-content;
				border-radius: 30px;
				width: 100%;
				max-width: 1250px;
				min-height: 100px;
				background: linear-gradient(
							180deg,
							${(props) => props.theme.currentTheme.cardsBackground} 0%,
							${(props) => props.theme.currentTheme.cardsBackground} 100%
						)
						padding-box,
					linear-gradient(
							180deg,
							${(props) => props.theme.currentTheme.cardsGradient.one},
							${(props) => props.theme.currentTheme.cardsGradient.two} 65.62%
						)
						border-box;
				border: 1px solid transparent;

				/* & > div {
					flex-direction: row; */

				& .in-CardWrapper {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					/* justify-content: space-between; */
					padding: 33px 60px;
					width: 100%;

					& .plan-menu-current-information-plan-part {
						padding-top: 0.8rem;
						display: flex;
						gap: 55px;

						& .plan-menu-active-plan-popup {
							position: absolute;
							top: -10px;
							left: 0px;
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 100px;
							background: #1eaf2c;
							width: 62px;
							height: 22px;
							font-size: 14px;
							font-style: normal;
							font-weight: 700;
							line-height: 18px;
							color: #fff;
						}

						& > div {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							gap: 8px;
							height: 82px;
						}

						& .plan-menu-payment-field {
							height: max-content;
							/* & .plan-menu-payment-field-history-btn {
								display: flex;
								align-items: center;
								cursor: pointer;
								color: #1592ec;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 25px;
							} */
						}

						& .field-title {
							color: ${(props) =>
								props.theme.currentTheme.halfTransparentFontColor};
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 25px;
						}

						& .plan-menu-current-field-value {
							font-size: 32px;
							font-style: normal;
							font-weight: 700;
							/* line-height: 48px; */
							padding-top: 8px;
						}

						& .plan-menu-expires-field-value {
							padding-bottom: 6px;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: 25px;
						}
					}

					& .plan-menu-current-extend-and-cancel-plan-part {
						display: flex;
						flex-direction: column;
						align-items: center;
						align-self: center;
						gap: 8px;
						width: 240px;

						& button {
							width: 100%;
						}

						& .plan-menu-extend-plan-button {
							background: none;
							border: none;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: 25px;
							cursor: pointer;
							border-radius: 8px;
							transition: all 0.3s ease-in-out;
							color: ${(props) => props.theme.currentTheme.mainFontColor};

							&:hover {
								background: ${(props) =>
									props.theme.currentTheme.alertFontColor};
								color: #fff;
							}

							&:active {
								transform: scale(0.95);
							}
						}
					}
					/* } */
				}
				& > .plan-menu-payment-field-history-btn {
					display: none;
				}
			}

			& .plan-menu-upgrade-plan-area {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				/* max-width: 1250px; */
				/* overflow: auto; */

				& .plan-menu-upgrade-title-subtitle-toggle-field {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding-bottom: 53px;
					width: 100%;
					max-width: 1250px;

					& .upgrade-title-subtitle {
						display: flex;
						flex-direction: column;
						gap: 0.5rem;

						& .plan-menu-upgrade-title {
							font-size: 36px;
							font-style: normal;
							font-weight: 700;
							line-height: 36px;
						}

						& .plan-menu-upgrade-subtitle {
							font-size: 24px;
							font-style: normal;
							font-weight: 400;
							line-height: 48px;
						}
					}
				}

				& .plan-menu-upgrade-available-plans {
					width: 100%;
					display: flex;
					justify-content: center;
					gap: 28px;
				}
			}
		}
	}

	@media screen and (max-width: 1650px) {
		padding: 1rem 2rem !important;
		/* transform: scale(0.9); */

		& .plan-menu-wrapper {
			& .plan-menu {
				/* gap: 1rem; */

				& .in-wrapper-title {
					font-size: 24px;
				}

				& .plan-menu-upgrade-plan-area {
					& .plan-menu-upgrade-available-plans {
						transform: scale(0.9);
					}
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .plan-menu-wrapper {
			& .plan-menu {
				& .plan-menu-upgrade-plan-area {
					& .plan-menu-upgrade-title-subtitle-toggle-field {
						& .upgrade-title-subtitle {
							& .plan-menu-upgrade-title {
								font-size: 24px;
							}
							& .plan-menu-upgrade-subtitle {
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1350px) {
		& .plan-menu-wrapper {
			& .plan-menu {
				& .plan-menu-upgrade-plan-area {
					/* max-width: 900px; */
					& .plan-menu-upgrade-available-plans {
						/* transform: scale(0.8); */
					}
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		& .plan-menu-wrapper {
			& .plan-menu {
				& .plan-menu-current-plan-area {
					& .in-CardWrapper {
						gap: 1rem;
						& .plan-menu-current-information-plan-part {
							gap: 20px;
						}
					}
				}
				& .plan-menu-upgrade-plan-area {
					& .plan-menu-upgrade-available-plans {
						transform: scale(1);
						flex-wrap: wrap;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1100px) {
		& .plan-menu-wrapper {
			padding: 100px 0px !important;
			& .plan-menu {
				& .plan-menu-current-plan-area {
					/* width: max-content; */
					width: ${(props) => props.theme.currentPlanCardWidth};
					/* overflow: auto; */

					& .in-CardWrapper {
						flex-direction: column;
						align-items: center;
						width: 361px;
						padding: 48px 30px;

						& .plan-menu-current-information-plan-part {
							flex-direction: column;
							align-items: center;
							width: 90%;
							gap: 20px;

							& .plan-menu-current-field,
							.plan-menu-payment-field,
							.plan-menu-expires-field {
								flex-direction: row;
								align-items: center;
								justify-content: space-between;
								width: 100%;

								& .plan-menu-payment-field-history-btn {
									display: none;
								}
							}
						}
					}

					& > .plan-menu-payment-field-history-btn {
						display: flex;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1000px) {
		padding: 0px;

		& .plan-menu-wrapper {
			padding: 100px 0px !important;
			& .plan-menu {
				& .plan-menu-upgrade-plan-area {
					& .plan-menu-upgrade-title-subtitle-toggle-field {
						flex-direction: column;
						align-items: center;
						text-align: center;
						gap: 3rem;
					}
					& .plan-menu-upgrade-available-plans {
						flex-direction: column;
						gap: 40px;
						align-items: center;
						transform: scale(1);
					}
				}
			}
		}
	}

	@media screen and (max-width: 750px) {
		& .plan-menu-wrapper {
			& .plan-menu {
				& .plan-menu-current-plan-area {
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		padding: 6rem 0.5rem;
	}

	@media screen and (max-width: 350px) {
		& .plan-menu-wrapper {
			& .plan-menu {
				& .plan-menu-current-plan-area {
					transform: scale(0.7);
				}
			}
		}
	}
`;
