// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusForPaymentMethodCardValuesSlice = createSlice({
	name: 'checkEmptyStatusForPaymentMethodCardValues',
	initialState,
	reducers: {
		setEmptyStatusForPaymentMethodCardValues(
			state,
			action: PayloadAction<boolean>
		) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForPaymentMethodCardValues } =
	checkEmptyStatusForPaymentMethodCardValuesSlice.actions;
export const checkEmptyStatusForPaymentMethodCardValuesReducer =
	checkEmptyStatusForPaymentMethodCardValuesSlice.reducer;
