import styled from 'styled-components';

export const StyledPaymentMethodsMenu = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	width: 90%;
	padding: 0px;
	/* border-top: ${(props) => props.theme.currentTheme.botBorder}; */

	& .closePic-wrapper {
		position: absolute;
		top: -15px;
		right: -25px;

		& .closePic {
			width: 24px;
			height: 24px;
			cursor: pointer;
		}
	}

	& .paymentMethodsMenu-title {
		width: 100%;
		font-size: clamp(24px, 28px, 32px);
		font-weight: 700;
		line-height: 48px;
		color: ${(props) => props.theme.currentTheme.mainFontColor};
		text-align: center;
	}

	& .paymentMethodsMenu-current-plan-area {
		width: 100%;
		border-radius: 8px;
		height: 54px;
		display: flex;
		justify-content: space-between;
		padding: 13px 20px;
		background: ${(props) =>
			props.theme.currentTheme.accentHighlightColorForTable};

		color: ${(props) => props.theme.currentTheme.mainFontColor};
		font-size: 18px;
		font-weight: 700;
		line-height: 28px;

		& .current-price {
			color: #5e17eb;
		}
	}

	& .paymentMethodsMenu-payment-methods-area {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
		overflow: auto;
		max-height: 600px;
		padding-bottom: 1rem;

		& .paymentMethodsMenu-payment-methods-choosing-field {
			display: flex;
			flex-direction: column;
			gap: 20px;

			& .MuiFormControl-root {
				width: 100%;
			}

			& .paymentMethodsMenu-payment-methods-choosing-title {
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 28px;
				color: ${(props) => props.theme.currentTheme.mainFontColor};
			}
		}
	}

	& .paymentMethodsMenu-buttons-area {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;

		& .paymentMethodsMenu-buttons-area-loadingContent {
			display: flex;
			justify-content: center;
			width: 100%;
		}

		& .button-small {
			width: 220px;
			display: flex;
			justify-content: center;
			align-self: center;
		}

		& .checkbox-payment-method {
			& .MuiFormControlLabel-root {
				& .MuiButtonBase-root.MuiCheckbox-root {
					color: ${(props) => props.theme.currentTheme.borderInputs};
				}

				& .MuiTypography-root {
					font-family: 'Nunito';
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 25px;
				}
			}
			/* #1EAF2C */
		}
	}

	@media screen and (max-width: 1500px) {
		& .paymentMethodsMenu-payment-methods-area {
			max-height: 500px;
		}

		& .closePic-wrapper {
			top: 10px;
			right: 0px;
		}
	}

	@media screen and (max-width: 1300px) {
		& .paymentMethodsMenu-payment-methods-area {
			max-height: 400px;
		}
	}

	@media screen and (max-width: 500px) {
		/* padding: 2rem; */
		width: 100%;

		& .closePic-wrapper {
			top: 0px;
			right: 0px;
		}
	}
`;
