// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialImportUrlValueState {
	domain: string;
}

const initialState: IInitialImportUrlValueState = {
	domain: '',
};

const importUrlValueSlice = createSlice({
	name: 'importUrlValue',
	initialState,
	reducers: {
		setDomainImportUrl(state, action: PayloadAction<string>) {
			state.domain = action.payload;
			return state;
		},
	},
});

export const { setDomainImportUrl } = importUrlValueSlice.actions;
export const importUrlValueReducer = importUrlValueSlice.reducer;
