// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialStateChangeUserInfoMenuSlice {
	messageErrorForModal: string;
	messageFromApi: string;
	isOpenModal: boolean;
	isOpenModalAlertChange: boolean;
	isOpenModalAfterSuccessDeleteAccount: boolean;
	loadingErrorContentForChangeUser: JSX.Element | null;
	loadingErrorContentForDeleteUser: JSX.Element | null;
}

const initialState: IInitialStateChangeUserInfoMenuSlice = {
	messageErrorForModal: '',
	messageFromApi: '',
	isOpenModal: false,
	isOpenModalAlertChange: false,
	isOpenModalAfterSuccessDeleteAccount: false,
	loadingErrorContentForChangeUser: null,
	loadingErrorContentForDeleteUser: null,
};

const stateChangeUserInfoMenuSlice = createSlice({
	name: 'stateChangeUserInfoMenu',
	initialState,
	reducers: {
		setIsOpenModal(state, action: PayloadAction<boolean>) {
			state.isOpenModal = action.payload;
			return state;
		},
		setIsOpenModalAlertChange(state, action: PayloadAction<boolean>) {
			state.isOpenModalAlertChange = action.payload;
			return state;
		},
		setIsOpenModalAfterSuccessDeleteAccount(
			state,
			action: PayloadAction<boolean>
		) {
			state.isOpenModalAfterSuccessDeleteAccount = action.payload;
			return state;
		},
		setMessageErrorForModal(state, action: PayloadAction<string>) {
			state.messageErrorForModal = action.payload;
			return state;
		},
		setMessageFromApi(state, action: PayloadAction<string>) {
			state.messageFromApi = action.payload;
			return state;
		},
		setLoadingErrorContentForChangeUser(
			state,
			action: PayloadAction<JSX.Element | null>
		) {
			state.loadingErrorContentForChangeUser = action.payload;
			return state;
		},
		setLoadingErrorContentForDeleteUser(
			state,
			action: PayloadAction<JSX.Element | null>
		) {
			state.loadingErrorContentForDeleteUser = action.payload;
			return state;
		},
	},
});

export const {
	setIsOpenModal,
	setIsOpenModalAlertChange,
	setIsOpenModalAfterSuccessDeleteAccount,
	setMessageErrorForModal,
	setMessageFromApi,
	setLoadingErrorContentForChangeUser,
	setLoadingErrorContentForDeleteUser,
} = stateChangeUserInfoMenuSlice.actions;
export const stateChangeUserInfoMenuReducer =
	stateChangeUserInfoMenuSlice.reducer;
