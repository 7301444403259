// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
// import { type ICurrentThemeColor, themes } from '../context/themeContext/themeContext';

export interface ICardsGradient {
	one: string;
	two: string;
}

export interface ICardsGradientIntegrate {
	one: string;
	two: string;
}

export interface ITogglePeriod {
	bg: string;
	btn: string;
}

export interface ICurrentThemeColor {
	mainRootBackground: string;
	menuBackground: string;
	mainFontColor: string;
	additionalFontColor: string;
	accentHighlightColor: string;
	accentMainColor: string;
	additionalColor: string;
	cardsBackground: string;
	cardsGradient: ICardsGradient;
	accentHighlightColorForTable: string;
	borderInputs: string;
	fontColorInput: string;
	stepperConnector: string;
	stepperTextColor: string;
	alertFontColor: string;
	borderInMenu: string;
	disabledBackground: string;
	disabledColor: string;
	disabledBorder: string;
	botMenuBackground: string;
	botMessageAnswerBackground: string;
	botShadowColor: string;
	botBorder: string;
	platformsCardColor: string;
	cardsModalBackground: string;
	halfTransparentFontColor: string;
	togglePeriod: ITogglePeriod;
	disabledButtonColor: string;
	vidgetCardBlue: string;
	vidgetCardBlueDisabledColor: string;
	accentPrimaryColor: string;
	templateBg: string;
	wrappedIconInInputBg: string;
	integrateGradient: ICardsGradientIntegrate;
	integrateAccent: string;
	integratePrimary: string;
	boxShadow: string;
	coderfyAccentPrimaryColor: string;
	coderfySecondaryColor: string;
	coderfyAlertPrimaryColor: string;
	coderfyAlertSecondaryColor: string;
}

export interface IThemesColors {
	dark: ICurrentThemeColor;
	light: ICurrentThemeColor;
}

export const themes: IThemesColors = {
	dark: {
		mainRootBackground: '#1A1D21',
		menuBackground: '#12121C',
		mainFontColor: '#D7E2F2',
		additionalFontColor: '#CACBCE',
		accentHighlightColor: '#515178',
		accentMainColor: '#5E17EB',
		additionalColor: '#6F6AF8',
		cardsBackground: '#1A1D21',
		cardsGradient: { one: '#2E4B83', two: '#222B3C' },
		accentHighlightColorForTable: '#24282E',
		borderInputs: '#5E17EB',
		fontColorInput: '#92A6C6',
		stepperConnector: '#4F4E8A',
		stepperTextColor: '#43F2FF',
		alertFontColor: '#B14D56',
		borderInMenu: '#4F4E8A',
		disabledBackground: '#1E2125',
		disabledColor: '#4F4E8A',
		disabledBorder: '#4F4E8A',
		botShadowColor: 'drop-shadow(8px 16px 32px rgba(7, 10, 15, 0.75))',
		botMenuBackground: '#151F2D',
		botBorder: '1px solid #21334A',
		botMessageAnswerBackground:
			'linear-gradient(148.48deg, #2A3C56 14.45%, #152943 84.23%)',
		platformsCardColor: '#24282E',
		cardsModalBackground: '#24282E',
		halfTransparentFontColor: '#A2A4C2',
		togglePeriod: { bg: '#1A1D21', btn: '#D7E2F2' },
		disabledButtonColor: '#0F1011',
		vidgetCardBlue: '#004B81',
		vidgetCardBlueDisabledColor: '#045996',
		accentPrimaryColor: '#1592EC',
		templateBg: '#24282E',
		wrappedIconInInputBg: '#181B1F',
		integrateGradient: { one: '#0D2C49', two: '#211A38' },
		integrateAccent: '#1592EC',
		integratePrimary: '#1C2532',
		boxShadow: '24px 24px 32px 0px rgba(24, 27, 31, 0.79)',
		coderfyAccentPrimaryColor: '#1592EC',
		coderfySecondaryColor: '#343A42',
		coderfyAlertPrimaryColor: '#6D4507',
		coderfyAlertSecondaryColor: '#262421',
	},
	light: {
		mainRootBackground: '#EFEFEF',
		menuBackground: '#F8F9FB',
		// menuBackground: '#EFEFEF',
		mainFontColor: '#232323',
		additionalFontColor: '#595959',
		accentHighlightColor: '#E6DAFB',
		accentMainColor: '#5E17EB',
		additionalColor: '#6F6AF8',
		cardsBackground: '#FFF',
		cardsGradient: { one: '#EBF2F9', two: '#C7D0DB' },
		accentHighlightColorForTable: '#EAF6FF',
		borderInputs: '#8BAFD0',
		fontColorInput: '#808A8D',
		stepperConnector: '#D7E2F2',
		stepperTextColor: '#5E17EB',
		alertFontColor: '#EE7883',
		borderInMenu: '#549ADB',
		disabledBackground: '#FBFCFF',
		disabledColor: '#B1C3CA',
		disabledBorder: '#D8E1EE',
		botShadowColor: 'drop-shadow(8px 16px 32px rgba(189, 215, 235, 0.55))',
		botMenuBackground: '#F4FBFF',
		botBorder: '1px solid #C2E1F8',
		botMessageAnswerBackground:
			'linear-gradient(142.23deg, #E7FEFF 1.04%, #BEE3FF 100%)',
		platformsCardColor: '#EAF6FF',
		cardsModalBackground: '#fff',
		halfTransparentFontColor: '#696969',
		togglePeriod: { bg: '#D9D9D9', btn: '#1A1D21' },
		disabledButtonColor: '#EFEFEF',
		vidgetCardBlue: '#DCF0FF',
		vidgetCardBlueDisabledColor: '#B4DDFB',
		accentPrimaryColor: '#1592EC',
		templateBg: '#EAF4FA',
		wrappedIconInInputBg: '#F4FAFF',
		integrateGradient: { one: '#D6F5FF', two: '#F1E5FF' },
		integrateAccent: '#68B9F3',
		integratePrimary: '#EBF7FF',
		boxShadow: '8px 16px 32px 0px rgba(201, 212, 221, 0.55)',
		coderfyAccentPrimaryColor: '#1592EC',
		coderfySecondaryColor: '#93BCE2',
		coderfyAlertPrimaryColor: '#E19523',
		coderfyAlertSecondaryColor: '#FFFCF7',
	},
};

const themeFromLocalStorage = window.localStorage.getItem('theme');

const initialState: ICurrentThemeColor =
	themeFromLocalStorage !== null && themeFromLocalStorage === 'light'
		? themes.light
		: themes.dark;

const switchThemeSlice = createSlice({
	name: 'switchTheme',
	initialState,
	reducers: {
		switchToLightTheme(state) {
			state = themes.light;
			return state;
		},
		switchToDarkTheme(state) {
			state = themes.dark;
			return state;
		},
	},
});

export const { switchToLightTheme, switchToDarkTheme } =
	switchThemeSlice.actions;
export const switchThemeReducer = switchThemeSlice.reducer;
