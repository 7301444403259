import React from 'react';
import {
	platformsArticleTitle,
	sixthPlatformsArticle,
	implementationToPlatforms,
} from '../shared/textContent';
import { StyledPlatformsMenu } from './PlatformsMenu.style';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';

import { ReactComponent as BigcommerceDark } from '../pic/BigcommerceDark.svg';
import { ReactComponent as JoomlaDark } from '../pic/JoomlaDark.svg';
import { ReactComponent as MagentoDark } from '../pic/MagentoDark.svg';
import { ReactComponent as ShopifyDark } from '../pic/ShopifyDark.svg';
import { ReactComponent as SquarespaceDark } from '../pic/SquarespaceDark.svg';
import { ReactComponent as WordpressDark } from '../pic/WordpressDark.svg';
import { ReactComponent as PrestashopDark } from '../pic/PrestashopDark.svg';

import { ReactComponent as Bigcommerce } from '../pic/Bigcommerce.svg';
import { ReactComponent as Drupal } from '../pic/Drupal.svg';
import { ReactComponent as Joomla } from '../pic/Joomla.svg';
import { ReactComponent as Magento } from '../pic/Magento.svg';
import { ReactComponent as Prestashop } from '../pic/Prestashop.svg';
import { ReactComponent as Shopify } from '../pic/Shopify.svg';
import { ReactComponent as Squarespace } from '../pic/Squarespace.svg';
import { ReactComponent as Weebly } from '../pic/Weebly.svg';
import { ReactComponent as Wix } from '../pic/Wix.svg';
import { ReactComponent as Wordpress } from '../pic/Wordpress.svg';

import { Link } from 'react-scroll';
// import { WrappedDetailedLogoItem } from './WrappedDetailedLogoItem';
import { WrappedCardDetailedPlatform } from './WrappedCardDetailedPlatform/WrappedCardDetailedPlatform';

interface IWrappedShortLogoItemProps {
	icon: React.ReactElement;
	isSmall: boolean;
	link: string;
}

interface IAllLogoList {
	id: number;
	name: string;
	value: React.ReactElement;
	isSmall: boolean;
	link: string;
}

export const PlatformsMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const isDarkTheme = (): boolean =>
		currentTheme.mainRootBackground === '#1A1D21';
	const theme = {
		currentTheme,
		backgroundWrappedShortLogoItem: isDarkTheme() ? '#24282E' : '#fff',
	};

	const allLogosList: IAllLogoList[] = [
		{
			link: 'wordpress',
			id: 1,
			name: 'WordPress',
			value: isDarkTheme() ? <WordpressDark /> : <Wordpress />,
			isSmall: false,
		},
		{
			link: 'squarespace',
			id: 2,
			name: 'Squarespace',
			value: isDarkTheme() ? <SquarespaceDark /> : <Squarespace />,
			isSmall: false,
		},
		{
			link: 'drupal',
			id: 3,
			name: 'Drupal',
			value: <Drupal id="drupal-logo" />,
			isSmall: false,
		},
		{
			link: 'joomla',
			id: 4,
			name: 'Joomla',
			value: isDarkTheme() ? <JoomlaDark /> : <Joomla />,
			isSmall: false,
		},
		{
			link: 'magento',
			id: 5,
			name: 'Magento',
			value: isDarkTheme() ? <MagentoDark /> : <Magento />,
			isSmall: false,
		},
		{
			link: 'shopify',
			id: 6,
			name: 'Shopify',
			value: isDarkTheme() ? <ShopifyDark /> : <Shopify />,
			isSmall: false,
		},
		{
			link: 'bigcommerce',
			id: 7,
			name: 'BigCommerce',
			value: isDarkTheme() ? <BigcommerceDark /> : <Bigcommerce />,
			isSmall: false,
		},
		{
			link: 'prestashop',
			id: 8,
			name: 'PrestaShop',
			value: isDarkTheme() ? <PrestashopDark /> : <Prestashop />,
			isSmall: false,
		},
		{
			link: 'wix',
			id: 9,
			name: 'Wix-Weebly',
			value: (
				<>
					<Box>
						<Wix />
					</Box>
					<Box>
						<Weebly />
					</Box>
				</>
			),
			isSmall: true,
		},
		{ link: 'wix', id: 10, name: 'Wix', value: <Wix />, isSmall: true },
		{
			link: 'weebly',
			id: 11,
			name: 'Weebly',
			value: <Weebly />,
			isSmall: true,
		},
	];

	const WrappedShortLogoItem: React.FC<IWrappedShortLogoItemProps> = ({
		icon,
		isSmall,
		link,
	}) => (
		<Box
			component="div"
			className={`wrappedShortLogoItem ${isSmall ? 'isSmall' : ''}`}
		>
			<Link to={link} spy smooth duration={800}>
				{icon}
			</Link>
		</Box>
	);

	return (
		<StyledPlatformsMenu theme={theme}>
			<Box component="div" className="platformsMenu-wrapper">
				<Box component="div" className="platformsMenu-high-block">
					<Box component="div" className="platformsMenu-title-subtitle">
						<Box component="div" className="platformsMenu-title">
							{platformsArticleTitle}
						</Box>
						<Box component="div" className="platformsMenu-subtitle">
							{sixthPlatformsArticle.map((item) => (
								<Box key={item}>{item}</Box>
							))}
						</Box>
					</Box>
					<Box
						component="div"
						className="platformsMenu-short-logo"
						sx={{ display: { xs: 'none', sm: 'flex' } }}
					>
						<Box>
							{allLogosList
								.filter((item) => item.id < 4)
								.map((item) => (
									<WrappedShortLogoItem
										key={item.id}
										icon={item.value}
										isSmall={item.isSmall}
										link={item.link}
									/>
								))}
						</Box>
						<Box>
							{allLogosList
								.filter((item) => item.id >= 4 && item.id < 7)
								.map((item) => (
									<WrappedShortLogoItem
										key={item.id}
										icon={item.value}
										isSmall={item.isSmall}
										link={item.link}
									/>
								))}
						</Box>
						<Box>
							{allLogosList
								.filter((item) => item.id >= 7 && item.id < 10)
								.map((item) => (
									<WrappedShortLogoItem
										key={item.id}
										icon={item.value}
										isSmall={item.isSmall}
										link={item.link}
									/>
								))}
						</Box>
					</Box>
					<Box
						component="div"
						className="platformsMenu-short-logo-mobile"
						sx={{ display: { xs: 'flex', sm: 'none' } }}
					>
						<Box>
							{allLogosList
								.filter((item) => item.id < 6)
								.map((item) => (
									<WrappedShortLogoItem
										key={item.id}
										icon={item.value}
										isSmall={item.isSmall}
										link={item.link}
									/>
								))}
						</Box>
						<Box>
							{allLogosList
								.filter(
									(item) => item.id >= 6 && item.id <= 11 && item.id !== 9
								)
								.map((item) => (
									<WrappedShortLogoItem
										key={item.id}
										icon={item.value}
										isSmall={item.isSmall}
										link={item.link}
									/>
								))}
						</Box>
					</Box>
				</Box>
				<Box component="div" className="platformsMenu-detailed-block">
					<Box component="div" className="platformsMenu-detailed-block-left">
						{implementationToPlatforms
							.filter((item, index) => index <= 4)
							.map((currentPlatformContent) => (
								<WrappedCardDetailedPlatform
									key={currentPlatformContent.title}
									title={currentPlatformContent.title}
									content={currentPlatformContent.content}
									allLogosList={allLogosList}
								/>
								// <WrappedDetailedLogoItem
								// 	key={item.title}
								// 	title={item.title}
								// 	content={item.content}
								// 	allLogosList={allLogosList}
								// />
							))}
					</Box>
					<Box component="div" className="platformsMenu-detailed-block-right">
						{implementationToPlatforms
							.filter((item, index) => index > 4)
							.map((currentPlatformContent) => (
								<WrappedCardDetailedPlatform
									key={currentPlatformContent.title}
									title={currentPlatformContent.title}
									content={currentPlatformContent.content}
									allLogosList={allLogosList}
								/>
								// <WrappedDetailedLogoItem
								// 	key={item.title}
								// 	title={item.title}
								// 	content={item.content}
								// 	allLogosList={allLogosList}
								// />
							))}
					</Box>
				</Box>
			</Box>
		</StyledPlatformsMenu>
	);
};
