import React, { useState, useEffect } from 'react';
import { SharedOneActionComponent } from '../shared/SharedOneActionComponent/SharedOneActionComponent';
import { InputsFieldReset } from './InputsFieldReset/InputsFieldReset';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { setEmptyStatusForReset } from '../../app/slices/inputResetPassword/checkEmptyStatusForResetSlice';
import { useResetPasswordMutation } from '../../app/api/chatbot.api';
import { type IResponseDataWithError } from '../../app/api/interfaces/interfaces';
import { ProgressSpinner } from '../shared/ProgressSpinner/ProgressSpinner';
import { Box } from '@mui/material';

interface IResetPasswordMenuProps {
	dataFromUrl: string;
}

interface IDataResponseReset {
	data: string;
}

export const ResetPasswordMenu: React.FC<IResetPasswordMenuProps> = ({
	dataFromUrl,
}) => {
	const arrayDataFromUrl = dataFromUrl.split('=');
	const emailFromUrl = arrayDataFromUrl[1].replace(/&token/gim, '');
	const tokenFromUrl = arrayDataFromUrl[2];

	const resetInputValue = useAppSelector(
		(state: RootState) => state.resetInputValue
	);
	const validInputStatus = useAppSelector(
		(state: RootState) => state.isValidResetInputValue
	);

	const [errorStatus, setErrorStatus] = useState('');
	const [resetPassword, resultOfSendReset] = useResetPasswordMutation();
	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const resetData = {
		password: resetInputValue.passwordReset,
		email: emailFromUrl,
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		setErrorStatus('');
		dispatch(setEmptyStatusForReset(false));
	}, []);

	const handleResetPassword = (): void => {
		dispatch(setEmptyStatusForReset(true));
		if (
			resetInputValue.passwordReset.length > 0 &&
			validInputStatus.isValidPasswordReset
		) {
			// console.log('reset');
			// console.log(tokenFromUrl);
			setErrorStatus('');
			resetPassword({
				...resetData,
				resetToken: tokenFromUrl,
			})
				.then((response: IDataResponseReset | IResponseDataWithError) => {
					// console.log(response);
					if ('data' in response) {
						// console.log(response.data);
						setErrorStatus(
							'Password reset successfully. After couple of seconds you will be automatically redirected to login page'
						);
						setTimeout(() => {
							window.location.replace('/');
						}, 3000);
					} else if ('error' in response) {
						// console.log(response.error);
						setErrorStatus(response.error.message);
					}
				})
				.catch((error) => {
					console.log(error);
					setErrorStatus('Server error: Failed to fetch');
				});
		} else {
			setErrorStatus('Incorrect input');
		}
	};

	useEffect(() => {
		resultOfSendReset.isLoading && setLoadingErrorContent(<ProgressSpinner />);
		resultOfSendReset.isError &&
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{errorStatus}
				</Box>
			);
		resultOfSendReset.isSuccess &&
			setLoadingErrorContent(
				<Box
					component="div"
					className="error-message-from-api"
					sx={{ color: '#ffffff !important' }}
				>
					{errorStatus}
				</Box>
			);
	}, [resultOfSendReset, errorStatus]);

	return (
		<SharedOneActionComponent
			handleExecuteFunction={handleResetPassword}
			inputsFieldForgot={<InputsFieldReset />}
			title="Hello!"
			subtitle="Enter new password"
			textButton="Send new password"
			loadingErrorContent={loadingErrorContent}
			navLink="/registration"
			isForgotMenu={true}
		/>
	);
};
