import React from 'react';
import { Box } from '@mui/material';
import { StyledMessengerGetToken } from './MessengerGetToken.style';
import arrow from '../../../../pic/arrowBig.svg';
import { useAppSelector } from '../../../../../../app/hooks';
import { type RootState } from '../../../../../../app/store';
import messengerGetToken from '../../../../pic/messengerGetToken.png';

export const MessengerGetToken: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	return (
		<StyledMessengerGetToken theme={theme} className="messageExample-wrapper">
			<Box component="div" className="messageExample-arrow">
				<Box component="img" src={arrow} alt="arrow-pic" />
			</Box>
			<Box component="div" className="messageExample-msg-box">
				<Box
					component="img"
					className="step-Element-link"
					src={messengerGetToken}
				/>
			</Box>
		</StyledMessengerGetToken>
	);
};
