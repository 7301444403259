/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled from 'styled-components';

export const StyledCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
	background: linear-gradient(
				to right,
				${(props) => props.theme.currentTheme.cardsBackground},
				${(props) => props.theme.currentTheme.cardsBackground}
			)
			padding-box,
		linear-gradient(
				${(props) =>
					props.theme.currentTheme.mainRootBackground === '#1A1D21'
						? '90deg'
						: '180deg'},
				${(props) => props.theme.currentTheme.cardsGradient.one},
				${(props) => props.theme.currentTheme.cardsGradient.two}
			)
			border-box;
	padding: 1rem;
	height: ${(props) => props.theme.height};
	border-radius: 30px;
	width: 100%;
	max-width: ${(props) => props.theme.maxWidth};
	min-height: 100px;

	& > div {
		width: 100%;

		& .in-wrapper-title {
			font-family: 'Nunito';
			font-style: normal;
			font-weight: 600;
			font-size: 24px;
			line-height: 48px;
			text-align: center;
			padding-bottom: 1rem;

			background: linear-gradient(90deg, #5e17eb, rgb(142, 45, 226), #5e17eb);
			background-clip: text;
			-webkit-background-clip: text;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
		}

		& .in-wrapper-table {
			display: flex;
			width: 100%;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;
			gap: 1rem;
		}
	}

	& .personal-cabinet-learning-stage-wrapper {
		display: flex;
		gap: 24px;

		& .personal-cabinet-learning-stage-subtitle {
			font-family: 'Nunito';
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 38px;
			text-align: center;
		}

		& .personal-cabinet-learning-stage-value {
			& > div {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 68px;
				height: 68px;
				border: 4px solid #1592ec;
				border-radius: 50%;
			}
		}
	}

	@keyframes appear {
		0% {
			-webkit-transform: translateY(-1000px);
			transform: translateY(-1000px);
			opacity: 0;
		}
		100% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
			opacity: 1;
		}
	}

	@media screen and (max-width: 1500px) {
		& > div {
			padding: 1rem;
		}

		.personal-cabinet-generate-wrapper {
			padding: 0px !important;
		}

		& .personal-cabinet-learning-stage-wrapper {
			& .personal-cabinet-learning-stage-subtitle {
				font-size: 18px;
			}

			& .personal-cabinet-learning-stage-value {
				& > div {
				}
			}
		}
	}

	@media screen and (max-width: 1150px) {
		& .personal-cabinet-profiles-wrapper {
			width: 100%;
		}
	}

	& .personal-cabinet-learning-stage-wrapper,
	.personal-cabinet-generate-wrapper {
		/* width: 45%; */
	}

	& .personal-cabinet-generate-wrapper {
		& .in-wrapper-table {
			gap: 3rem;
		}

		& .personal-cabinet-generate-subtitle {
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: 'Nunito';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 140%;
			/* max-width: 259px; */
			width: max-content;
			max-width: 100%;
			text-align: center;
			hyphens: auto;
			word-wrap: break-word;
		}
	}

	@media screen and (max-width: 650px) {
		width: 100%;
	}

	@media screen and (max-width: 500px) {
		& > div {
			padding: 2rem 1rem;
		}
	}
`;
