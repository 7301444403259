import React from 'react';
import { StyledSharedCustomTable } from './SharedCustomTable.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { GridCard } from './GridCard';
import { type IIDataBotRows } from '../../../PersonalCabinetMenu/Cards/ProfilesBotsCard';
import { Box } from '@mui/material';

interface ISharedMobileTableProps {
	rows: IIDataBotRows[];
}

export const SharedCustomTable: React.FC<ISharedMobileTableProps> = ({
	rows,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		currentTheme,
		hoverBackgroundColor:
			currentTheme.accentHighlightColorForTable.concat('80'),
	};

	return (
		<StyledSharedCustomTable theme={theme}>
			<Box className="grid-bots-container">
				<Box component="div" className="title-wrapper">
					<Box className="title-bots domain-grid-item">Domain</Box>
					<Box className="title-bots tone-grid-item">Tone</Box>
					<Box className="title-bots style-grid-item">Style</Box>
					<Box className="title-bots actions-grid-item">Actions</Box>
				</Box>

				{rows.map((row, index) => (
					<GridCard key={index} row={row} />
				))}
			</Box>
		</StyledSharedCustomTable>
	);
};
