import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { useConfirmEmailMutation } from '../../app/api/chatbot.api';
import { setEmptyStatusForConfirmEmail } from '../../app/slices/inputsConfirmEmail/checkEmptyStatusForConfirmEmailSlice';
import {
	type IResponseDataWithError,
	type IConfirmEmail,
} from '../../app/api/interfaces/interfaces';
import { ProgressSpinner } from '../shared/ProgressSpinner/ProgressSpinner';
import { SharedOneActionComponent } from '../shared/SharedOneActionComponent/SharedOneActionComponent';
import { InputsFieldConfirmEmail } from './InputsFieldConfirmEmail';
import {
	// clearSessionStorage,
	getSessionItem,
	removeSessionItem,
	setItem,
} from '../../app/utils/storageUtils';
import { setRegistrationStatus } from '../../app/slices/isRegistrationSlice';
import { AdditionalConfirmationField } from './AdditionalConfirmationField/AdditionalConfirmationField';
import {
	setAnswerApiLoginPersonalInformation,
	setAnswerApiLoginStatus,
	setAnswerApiLoginToken,
} from '../../app/slices/answersFromAPI/answerApiLoginSlice';
import { setAuthorizationStatus } from '../../app/slices/isAuthorizationSlice';
import { setConfirmEmailIssueStatus } from '../../app/slices/isOpenConfirmEmailIssueSlice';
import { useLocation } from 'react-router-dom';

interface IDataConfirmEmailMenu {
	data: IConfirmEmail;
}

export const ConfirmEmailMenu: React.FC = () => {
	const code = useAppSelector(
		(state: RootState) => state.cellsConfirmationCode.fullCode
	);

	const currentPath = useLocation().pathname;

	const [currentConfirmEmail, setCurrentConfirmEmail] = useState('');

	const [confirmEmail, resultOfConfirmEmail] = useConfirmEmailMutation();

	const [errorStatus, setErrorStatus] = useState('');
	const [successStatus, setSuccessStatus] = useState('');
	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const registrationData = getSessionItem('registrationData');

	useEffect(() => {
		registrationData !== null &&
			setCurrentConfirmEmail(JSON.parse(registrationData).email);
	}, []);

	const confirmData = {
		code,
		email: registrationData !== null && JSON.parse(registrationData).email,
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		setErrorStatus('');
		dispatch(setEmptyStatusForConfirmEmail(false));
		if (registrationData === null) {
			dispatch(setRegistrationStatus(false));
		}
	}, [registrationData]);

	const handleConfirmEmail = (): void => {
		// console.log(code.length);

		setLoadingErrorContent(
			<Box component="div" className="error-message-from-api">
				{errorStatus}
			</Box>
		);

		if (code.length === 6) {
			// console.log(confirmData);

			confirmEmail(confirmData)
				.then((response: IDataConfirmEmailMenu | IResponseDataWithError) => {
					// console.log(confirmEmailCodeInputValue.confirmEmailValue);
					// console.log('response:', response);
					if ('data' in response) {
						// console.log('success');
						setSuccessStatus('You`ve successfully confirmed email');
						dispatch(setConfirmEmailIssueStatus(false));

						const personalInformationFromApi = {
							id: response.data.user.id,
							username: response.data.user.username,
							surname: response.data.user.surname,
						};

						dispatch(setAnswerApiLoginStatus(true));
						dispatch(
							setAnswerApiLoginPersonalInformation(personalInformationFromApi)
						);
						setItem(
							'loginDataFromApi',
							JSON.stringify(personalInformationFromApi)
						);
						dispatch(setAnswerApiLoginToken(response.data.token));
						dispatch(setAuthorizationStatus(true));
						setItem('isAuthorization', 'true');
						setItem(
							'loginData',
							JSON.stringify({
								// domain: "",
								email: response.data.user.email,
							})
						);
						setItem('token', response.data.token);
						setTimeout(() => {
							currentPath === '/registration' && window.location.replace('/');
						}, 100);
						setTimeout(() => {
							removeSessionItem('registrationData');
							// clearSessionStorage();
						}, 200);
					} else if ('error' in response) {
						// console.log('error');
						// console.log(response.error.data.message);
						setErrorStatus(response.error.data.message);
					} else {
						console.log('');
						// console.log('empty response');
					}
				})
				.catch((error) => {
					console.log(error);
					setErrorStatus('Server error: Failed to fetch');
				});
		} else {
			// console.log(code);
			setErrorStatus('Enter full code');
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{errorStatus}
				</Box>
			);
		}
	};

	useEffect(() => {
		resultOfConfirmEmail.isLoading &&
			setLoadingErrorContent(<ProgressSpinner />);
		resultOfConfirmEmail.isError &&
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{errorStatus}
				</Box>
			);
	}, [resultOfConfirmEmail, errorStatus]);

	useEffect(() => {
		resultOfConfirmEmail.isSuccess &&
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{successStatus}
				</Box>
			);
	}, [resultOfConfirmEmail, successStatus]);

	useEffect(() => {
		code.length === 6 ? setErrorStatus('') : setErrorStatus('Enter full code');
	}, [code]);

	return (
		<SharedOneActionComponent
			handleExecuteFunction={handleConfirmEmail}
			inputsFieldForgot={<InputsFieldConfirmEmail />}
			title={`Please enter the six digit code from your email: ${currentConfirmEmail}`}
			subtitle="If the code is not received by email, click 'Send code again'."
			textButton="Send code"
			loadingErrorContent={loadingErrorContent}
			navLink="/"
			additionalActionField={
				<AdditionalConfirmationField email={currentConfirmEmail} />
			}
		/>
	);
};
