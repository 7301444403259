// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialChangeInformationInputsValidState {
	isValidUsernameChangeInfo: boolean;
	isValidSurnameChangeInfo: boolean;
	isValidEmailChangeInfo: boolean;
	isValidPasswordChangeInfo: boolean;
	isValidPasswordRepeatChangeInfo: boolean;
}

const initialState: IInitialChangeInformationInputsValidState = {
	isValidUsernameChangeInfo: false,
	isValidEmailChangeInfo: false,
	isValidPasswordChangeInfo: false,
	isValidSurnameChangeInfo: false,
	isValidPasswordRepeatChangeInfo: false,
};

const isValidChangeInformationValuesSlice = createSlice({
	name: 'isValidChangeInformationValues',
	initialState,
	reducers: {
		setValidStatusUsernameChangeInfo(state, action) {
			state.isValidUsernameChangeInfo = action.payload;
			return state;
		},
		setValidStatusEmailChangeInfo(state, action) {
			state.isValidEmailChangeInfo = action.payload;
			return state;
		},
		setValidStatusPasswordChangeInfo(state, action) {
			state.isValidPasswordChangeInfo = action.payload;
			return state;
		},
		setValidStatusPasswordRepeatChangeInfo(state, action) {
			state.isValidPasswordRepeatChangeInfo = action.payload;
			return state;
		},
		setValidStatusSurnameChangeInfo(state, action) {
			state.isValidSurnameChangeInfo = action.payload;
			return state;
		},
	},
});

export const {
	setValidStatusPasswordChangeInfo,
	setValidStatusEmailChangeInfo,
	setValidStatusUsernameChangeInfo,
	setValidStatusPasswordRepeatChangeInfo,
	setValidStatusSurnameChangeInfo,
} = isValidChangeInformationValuesSlice.actions;
export const isValidChangeInformationValuesReducer =
	isValidChangeInformationValuesSlice.reducer;
