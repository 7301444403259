import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as LogoutWhite } from '../pic/logoutWhite.svg';
import { StyledLogout } from './Logout.style';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setCloseOpenModalLogoutStatus } from '../../../app/slices/isOpenModalLogout/isOpenModalLogoutSlice';

export const Logout: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const dispatch = useAppDispatch();

	const handleOpenModal = (): void => {
		dispatch(setCloseOpenModalLogoutStatus(true));
	};

	return (
		<StyledLogout
			theme={currentTheme.additionalFontColor}
			onClick={handleOpenModal}
		>
			<Box component="div" className="logout-item">
				<LogoutWhite />
			</Box>
			<Box component="div" className="logout-text">
				Log out
			</Box>
		</StyledLogout>
	);
};
