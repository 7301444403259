import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialDeleteAllSnippetsMessageTrainingBotMenu {
	deleteAllSnippetsMessage: string;
}

const initialState: IInitialDeleteAllSnippetsMessageTrainingBotMenu = {
	deleteAllSnippetsMessage: '',
};

const deleteAllSnippetsMessageTrainingBotMenuSlice = createSlice({
	name: 'deleteAllSnippetsMessageTrainingBotMenuSlice',
	initialState,
	reducers: {
		setDeleteAllSnippetsMessage(state, action: PayloadAction<string>) {
			state.deleteAllSnippetsMessage = action.payload;
			return state;
		},
	},
});

export const { setDeleteAllSnippetsMessage } =
	deleteAllSnippetsMessageTrainingBotMenuSlice.actions;
export const deleteAllSnippetsMessageTrainingBotMenuReducer =
	deleteAllSnippetsMessageTrainingBotMenuSlice.reducer;
