import React from 'react';
import { Box } from '@mui/material';
import { StyledSharedQuestion } from './Question.style';
import { ReactComponent as Plus } from '../../pic/plus.svg';
import { ReactComponent as Minus } from '../../pic/minus.svg';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';

interface ISharedQuestionProps {
	text: string;
	isOpen: boolean;
	closeOpenAnswer: () => void;
}

export const SharedQuestion: React.FC<ISharedQuestionProps> = ({
	text,
	isOpen,
	closeOpenAnswer,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	return (
		<StyledSharedQuestion
			theme={theme}
			className="sharedQuestion"
			onClick={() => {
				closeOpenAnswer();
			}}
		>
			<Box component="div" className="question-text">
				{text}
			</Box>
			<Box component="div" className="question-is-open-icon">
				{isOpen ? <Minus /> : <Plus />}
			</Box>
		</StyledSharedQuestion>
	);
};
