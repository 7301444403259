// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialResetInputValueState {
	passwordReset: string;
}

const initialState: IInitialResetInputValueState = {
	passwordReset: '',
};

const resetInputValueSlice = createSlice({
	name: 'resetInputValue',
	initialState,
	reducers: {
		setPasswordReset(state, action) {
			state.passwordReset = action.payload;
			return state;
		},
	},
});

export const { setPasswordReset } = resetInputValueSlice.actions;
export const resetInputValueReducer = resetInputValueSlice.reducer;
