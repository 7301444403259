import styled from 'styled-components';

export const StyledSharedActionsViewOnPage = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;

	& .viewOnPage-title {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		color: ${(props) => props.theme.currentTheme.mainFontColor};
	}

	& .MuiAutocomplete-root {
		width: 68px;
		height: 40px;

		& .MuiIconButton-root.MuiAutocomplete-clearIndicator {
			display: none;
		}

		& .MuiOutlinedInput-input {
			font-size: 16px;
		}
	}

	@media screen and (max-width: 1000px) {
		& .customAutocomplete {
			width: 100% !important;
		}
	}

	@media screen and (max-width: 850px) {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
`;
