import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialOpenModalCacheCurrentSnippetsState {
	isOpenModalAskCache: boolean;
	isOpenModalAfterCacheOnlyAlert: boolean;
}

const initialState: IInitialOpenModalCacheCurrentSnippetsState = {
	isOpenModalAfterCacheOnlyAlert: false,
	isOpenModalAskCache: false,
};

const openCacheSnippetsTrainingBotMenuSlice = createSlice({
	name: 'openCacheSnippetsTrainingBotMenu',
	initialState,
	reducers: {
		setOpenModalAfterCacheOnlyAlertStatus(
			state,
			action: PayloadAction<boolean>
		) {
			state.isOpenModalAfterCacheOnlyAlert = action.payload;
			return state;
		},
		setOpenModalAskCacheStatus(state, action: PayloadAction<boolean>) {
			state.isOpenModalAskCache = action.payload;
			return state;
		},
	},
});

export const {
	setOpenModalAfterCacheOnlyAlertStatus,
	setOpenModalAskCacheStatus,
} = openCacheSnippetsTrainingBotMenuSlice.actions;
export const openCacheSnippetsTrainingBotMenuReducer =
	openCacheSnippetsTrainingBotMenuSlice.reducer;
