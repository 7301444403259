// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialIconChatbotSlice {
	iconChatbotChoosing: string;
	iconChatbotChoosingForCreationChatbot: string;
	iconCreateObjectURL: string;
}

const initialState: IInitialIconChatbotSlice = {
	iconChatbotChoosing: '',
	iconChatbotChoosingForCreationChatbot: '',
	iconCreateObjectURL: '',
};

const iconChatbotSlice = createSlice({
	name: 'iconChatbot',
	initialState,
	reducers: {
		setIconChatbotChoosing(state, action) {
			state.iconChatbotChoosing = action.payload;
			return state;
		},
		setIconChatbotChoosingForCreationChatbot(state, action) {
			state.iconChatbotChoosingForCreationChatbot = action.payload;
			return state;
		},
		setIconCreateObjectURL(state, action) {
			state.iconCreateObjectURL = action.payload;
			return state;
		},
	},
});

export const {
	setIconChatbotChoosing,
	setIconCreateObjectURL,
	setIconChatbotChoosingForCreationChatbot,
} = iconChatbotSlice.actions;
export const iconChatbotReducer = iconChatbotSlice.reducer;
