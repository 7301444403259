import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as SortWhite } from '../pic/sortWhite.svg';
import { ReactComponent as SortDark } from '../pic/sortDark.svg';
import {
	useAppDispatch,
	useAppSelector,
	useIsDarkTheme,
} from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { sortOrderForRefetching } from '../../../../app/slices/trainingBotMenu/searchBar/searchBarValueSlice';
import { CustomTooltip } from '../../CustomTooltip/CustomTooltip';

interface IUrlTitleProps {
	isReverse?: boolean;
	title: string;
	sortQuery: string;
	className: string;
}

export const SortComponent: React.FC<IUrlTitleProps> = ({
	isReverse,
	title,
	sortQuery,
	className,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const isDarkTheme = useIsDarkTheme();
	const dispatch = useAppDispatch();

	const handleSortSnippets = (): void => {
		dispatch(sortOrderForRefetching(sortQuery));
	};

	return (
		<Box
			className={className}
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '0.3rem',
				flexDirection: isReverse === true ? 'row-reverse' : 'row',
			}}
		>
			<Box component="span" sx={{ color: currentTheme.mainFontColor }}>
				{title}
			</Box>

			<CustomTooltip theme={theme} title={`Sort snippets by ${title}`}>
				<Box
					component="button"
					className="title-snippet-sort-button"
					onClick={handleSortSnippets}
					sx={{
						background: 'none',
						cursor: 'pointer',
						border: 'none',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{isDarkTheme ? <SortDark /> : <SortWhite />}
				</Box>
			</CustomTooltip>
		</Box>
	);
};
