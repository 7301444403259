import styled from 'styled-components';

export const StyledSharedMobileTable = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;

	& .grid-card-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding-bottom: 1rem;
		border-bottom: 2px solid
			${(props) => props.theme.currentTheme.accentHighlightColorForTable};

		& .grid-card-accent-line {
			background: ${(props) =>
				props.theme.currentTheme.accentHighlightColorForTable};
		}

		& .grid-card-accent-line,
		.grid-card-line {
			border-radius: 4px;
			padding: 8px 16px;
			display: flex;
			align-items: center;
			width: 100%;
			text-align: start;

			& .grid-card-item-left,
			.grid-card-item-right {
				display: flex;
				justify-content: flex-start;
				width: 100%;
				color: ${(props) => props.theme.currentTheme.mainFontColor};
			}

			& .grid-card-item-left {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 145%;
			}

			& .grid-card-item-right {
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 145%;
			}

			& .actions-grid-item {
				/* flex: 3; */
				width: 100%;
				min-width: max-content;
				/* justify-content: center; */

				display: flex;
				gap: 0.5rem;

				& .inline-action-button {
					padding: 0px !important;
					border: none;
					background: none;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 27px;
					color: #1592ec;
					cursor: pointer;
				}

				& svg {
					width: 24px;
					height: 24px;
					cursor: pointer;
				}
			}
		}
	}
`;
