import React from 'react';
import { Box } from '@mui/material';
import { type ICurrentPlanCardData } from '../PlanMenu';
import { PriceElement } from '../PriceElement/PriceElement';
import { SharedTransparentButton } from '../../shared/SharedTransparentButton/SharedTransparentButton';
import { SharedModal } from '../../shared/SharedModal/SharedModal';

interface ICurrentPlanAllDataProps {
	currentPlanCardInformation: (cardValue: string) => JSX.Element | string;
	currentPlanCardData: ICurrentPlanCardData;
	currentPlanPeriod: string;
	openCloseHistoryButton: JSX.Element;
	setOpenCloseModalConfirmCancelSubscribe: React.Dispatch<
		React.SetStateAction<boolean>
	>;
	handleDeletePlan: () => Promise<void>;
	openCloseModalConfirmCancelSubscribe: boolean;
	loadingErrorContentDeleteSubscribe: JSX.Element | null;
}

export const CurrentPlanAllData: React.FC<ICurrentPlanAllDataProps> = ({
	currentPlanCardInformation,
	currentPlanCardData,
	currentPlanPeriod,
	openCloseHistoryButton,
	setOpenCloseModalConfirmCancelSubscribe,
	handleDeletePlan,
	openCloseModalConfirmCancelSubscribe,
	loadingErrorContentDeleteSubscribe,
}) => {
	return (
		<>
			<Box component="div" className="plan-menu-current-information-plan-part">
				<Box component="div" className="plan-menu-active-plan-popup">
					Active
				</Box>
				<Box component="div" className="plan-menu-current-field">
					<Box
						component="div"
						className="plan-menu-current-field-title field-title"
					>
						Current Plan
					</Box>
					<Box component="div" className="plan-menu-current-field-value">
						{currentPlanCardInformation(currentPlanCardData.planName)}
					</Box>
				</Box>
				<Box component="div" className="plan-menu-payment-field">
					<Box
						component="div"
						className="plan-menu-payment-field-title field-title"
					>
						Payment
					</Box>
					<Box component="div" className="plan-menu-payment-field-value">
						<PriceElement
							priceValue={currentPlanCardInformation(
								currentPlanCardData.planPrice
							)}
							isBig={false}
							isPerMonth={currentPlanPeriod === 'month'}
						/>
					</Box>
					{openCloseHistoryButton}
				</Box>
				<Box component="div" className="plan-menu-expires-field">
					<Box
						component="div"
						className="plan-menu-expires-field-title field-title"
					>
						Plan expires
					</Box>
					<Box component="div" className="plan-menu-expires-field-value">
						{currentPlanCardInformation(currentPlanCardData.expireDate)}
					</Box>
				</Box>
			</Box>
			<Box
				component="div"
				className="plan-menu-current-extend-and-cancel-plan-part"
			>
				<SharedTransparentButton
					text="Cancel Subscription"
					buttonFunction={() => {
						setOpenCloseModalConfirmCancelSubscribe(true);
					}}
					tooltip="Cancel a subscription to the current plan. The current plan will be terminated upon expiration."
				/>
			</Box>
			{openCloseModalConfirmCancelSubscribe ? (
				<SharedModal
					closeFn={() => {
						setOpenCloseModalConfirmCancelSubscribe(false);
					}}
					executeFn={() => {
						void handleDeletePlan();
					}}
					isOnlyAlert={false}
					isDeleteModal
					message={loadingErrorContentDeleteSubscribe}
					title="Are you sure you want to cancel subscription?"
				/>
			) : (
				<></>
			)}
		</>
	);
};
