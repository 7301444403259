import React, { useState, useEffect } from 'react';
import { StyledWrappedInput } from './WrappedInput.style';
import { SharedInputAlert } from '../../SharedInputAlert/SharedInputAlert';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { checkInput, isEmptyColor } from '../../../../app/utils/utils';
import { SharedInput } from '../SharedInput';
import { getItem } from '../../../../app/utils/storageUtils';
import { type RootState } from '../../../../app/store';
import Box from '@mui/material/Box';
import { ReactComponent as ArrowUp } from './pic/arrowUp.svg';
import { ReactComponent as ArrowDown } from './pic/arrowDown.svg';
import {
	checkInputV6,
	type IInputChangeConfigV6,
} from '../../../../app/utils/inputUtil.v6';

export interface IIsFloatDigit {
	maxCountInteger: number;
	maxCountFloat: number;
}

interface IConfigChangeInputWithAlertV6 extends IInputChangeConfigV6 {
	alertMessage: string;
}

interface IInputProps {
	type: string;
	placeholder: string;
	isEmpty: boolean;
	params?: string[];
	isDarkBack?: boolean;
	uniqueNameOfInput: string;
	value?: string;
	isValid: boolean;
	isDisabled?: boolean;
	maxLength?: number;
	autocomplete?: boolean;

	minNumber?: number;
	maxNumber?: number;
	stepNumber?: number;
	isFloat?: IIsFloatDigit;
	refTag?: React.RefObject<HTMLInputElement> | null;
	configChangeInputV6?: IConfigChangeInputWithAlertV6 | undefined;
}

export const WrappedInput: React.FC<IInputProps> = ({
	type,
	placeholder,
	isEmpty,
	params,
	isDarkBack,
	uniqueNameOfInput,
	value,
	isValid,
	isDisabled,
	maxLength,
	autocomplete,

	minNumber,
	maxNumber,
	stepNumber,
	isFloat,
	refTag,
	configChangeInputV6,
}: IInputProps) => {
	const [valueInput, setValueInput] = useState('');

	useEffect(() => {
		value !== undefined && setValueInput(value);

		const currentValueFromLocalStorage = getItem('loginData');
		switch (uniqueNameOfInput) {
			case 'domain-login':
				currentValueFromLocalStorage !== null &&
					setValueInput(JSON.parse(currentValueFromLocalStorage).domain);
				break;
			case 'e-mail-login':
				currentValueFromLocalStorage !== null &&
					setValueInput(JSON.parse(currentValueFromLocalStorage).email);
				break;
			case 'password-login':
				currentValueFromLocalStorage !== null &&
					setValueInput(JSON.parse(currentValueFromLocalStorage).password);
				break;
			default:
				break;
		}
	}, []);

	const handleChange = (
		eventOrValue: React.ChangeEvent<HTMLInputElement> | string
	): void => {
		if (typeof eventOrValue !== 'string') {
			if (type === 'number' && maxLength !== undefined) {
				const inputValue = eventOrValue.target.value;
				if (
					('data' in eventOrValue.nativeEvent &&
						(eventOrValue.nativeEvent.data === 'e' ||
							eventOrValue.nativeEvent.data === '-' ||
							eventOrValue.nativeEvent.data === '+')) ||
					eventOrValue.target.type !== 'number'
				) {
					return;
				}

				let cropInputValue = inputValue.replace(
					/[a-zA-Zа-яА-ЯЄієґҐ()_=\-+\\/!@#$%^&*'"`~±|₴§]/gim,
					''
				);
				// let cropInputValue = inputValue.replace(/[e\-+.]/gim, '');
				const isValuePresentInString = cropInputValue.indexOf('e');
				if (isValuePresentInString !== -1) {
					cropInputValue = cropInputValue.slice(0, cropInputValue.length - 2);
				}
				if (cropInputValue.length > maxLength) {
					cropInputValue = cropInputValue.slice(0, maxLength);
				}
				setValueInput(cropInputValue);

				if (cropInputValue.length === maxLength) {
					switch (uniqueNameOfInput) {
						case 'payments-card-number':
							// eslint-disable-next-line no-case-declarations
							const expiryMonthElement = document.querySelector(
								`#payments-card-expiry-month`
							) as HTMLInputElement;
							expiryMonthElement.focus();
							break;
						case 'payments-card-expiry-month':
							// eslint-disable-next-line no-case-declarations
							const expiryYearElement = document.querySelector(
								`#payments-card-expiry-year`
							) as HTMLInputElement;
							expiryYearElement.focus();
							break;
						case 'payments-card-expiry-year':
							// eslint-disable-next-line no-case-declarations
							const cvcElement = document.querySelector(
								`#payments-card-cvc`
							) as HTMLInputElement;
							cvcElement.focus();
							break;
						default:
							break;
					}
				}
			} else if (type === 'number' && isFloat !== undefined) {
				const inputValue = eventOrValue.target.value;
				if (
					'data' in eventOrValue.nativeEvent &&
					(eventOrValue.nativeEvent.data === 'e' ||
						eventOrValue.nativeEvent.data === '-' ||
						eventOrValue.nativeEvent.data === '+')
					// 		||
					// eventOrValue.target.type !== 'number'
				) {
					return;
				}
				// let cropInputValue = inputValue.replace(/[e\-+.]/gim, '');
				let cropInputValue = inputValue.replace(
					/[a-zA-Zа-яА-ЯЄієґҐ()_=\-+\\/!@#$%^&*'"`~±|₴§]/gim,
					''
				);
				const isValuePresentInString = cropInputValue.indexOf('e');
				if (isValuePresentInString !== -1) {
					cropInputValue = cropInputValue.slice(0, cropInputValue.length - 2);
				}
				if (cropInputValue[0] === ',' || cropInputValue[0] === '.') {
					cropInputValue = cropInputValue.slice(1, isFloat.maxCountInteger);
				}
				const checkCorrectFloat = (): boolean =>
					cropInputValue[1] === '.' || cropInputValue[1] === ',';
				if (
					checkCorrectFloat() &&
					cropInputValue.length >= 3 &&
					Number.isNaN(parseInt(cropInputValue[2]))
				) {
					if (cropInputValue.length > isFloat.maxCountInteger) {
						cropInputValue = cropInputValue.slice(0, isFloat.maxCountInteger);
					}
				}
				if (cropInputValue.length > isFloat.maxCountFloat) {
					cropInputValue = cropInputValue.slice(0, isFloat.maxCountFloat);
				}
				if (maxNumber !== undefined && parseFloat(cropInputValue) > maxNumber) {
					cropInputValue = maxNumber.toString();
				}
				setValueInput(cropInputValue);
			} else {
				setValueInput(eventOrValue.target.value);
			}
		} else {
			setValueInput(eventOrValue);
		}
	};

	const handleIncrementValueInput = (): void => {
		let newValue = 0;
		if (stepNumber !== undefined) {
			newValue = parseFloat(
				(parseFloat(valueInput !== '' ? valueInput : '0') + stepNumber).toFixed(
					1
				)
			);
		} else {
			newValue = parseFloat(valueInput !== '' ? valueInput : '0') + 1;
		}
		if (maxNumber !== undefined && newValue <= maxNumber) {
			setValueInput(newValue.toString());
		}
	};

	const handleDecrementValueInput = (): void => {
		let newValue = 0;
		if (stepNumber !== undefined) {
			newValue = parseFloat(
				(parseFloat(valueInput !== '' ? valueInput : '0') - stepNumber).toFixed(
					1
				)
			);
		} else {
			newValue = parseFloat(valueInput !== '' ? valueInput : '0') - 1;
		}
		if (minNumber !== undefined && newValue >= minNumber) {
			setValueInput(newValue.toString());
		}
	};

	const dispatch = useAppDispatch();
	useEffect(() => {
		if (configChangeInputV6 !== undefined) {
			checkInputV6({ valueInput, params }, dispatch, {
				setValueFn: configChangeInputV6.setValueFn,
				validationFn: configChangeInputV6.validationFn,
				setValidStatusFn: configChangeInputV6.setValidStatusFn,
			});
		} else {
			checkInput(uniqueNameOfInput, { valueInput, params }, dispatch);
		}
	}, [valueInput]);

	const currentColor = isEmptyColor(isValid, isEmpty);
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const currentColorForBorder =
		currentColor === 'none'
			? `1px solid ${currentTheme.borderInputs}`
			: currentColor;

	const theme = {
		color: () => currentColorForBorder,
		colorAlert: isDarkBack === true ? '#edc2c2' : 'red',
		uniqueNameOfInput,
		currentTheme,
	};

	return (
		<StyledWrappedInput
			theme={theme}
			className={`shared-wrapped-input  ${
				isDisabled === true ? 'disabled-wrapped-input' : ''
			}`}
		>
			<SharedInput
				type={type}
				placeholder={placeholder}
				value={valueInput}
				inputFunction={handleChange}
				theme={theme}
				params={params}
				uniqueNameOfInput={uniqueNameOfInput}
				isDisabled={isDisabled === true}
				autocomplete={autocomplete}
				minNumber={minNumber}
				maxNumber={maxNumber}
				stepNumber={stepNumber}
				refTag={refTag}
			/>
			{type === 'number' ? (
				<Box component="div" className="wrapper-btn-inc-dec">
					<Box
						component="button"
						className="inc-btn"
						onClick={handleIncrementValueInput}
					>
						<ArrowUp />
					</Box>
					<Box
						component="button"
						className="dec-btn"
						onClick={handleDecrementValueInput}
					>
						<ArrowDown />
					</Box>
				</Box>
			) : (
				<></>
			)}
			{isDisabled === true ? (
				<></>
			) : (
				<SharedInputAlert
					uniqueNameOfInput={uniqueNameOfInput}
					isValid={isValid}
					isEmpty={isEmpty}
					alertMessageV6={
						configChangeInputV6 !== undefined
							? configChangeInputV6.alertMessage
							: undefined
					}
				/>
			)}
		</StyledWrappedInput>
	);
};
