import styled from 'styled-components';

export const StyledMessageExample = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	display: flex;
	gap: 16px;
	padding: 32px 0px 0px 146px;
	/* padding: 32px 0px 0px 166px; */
	/* padding: 32px 0px 0px 190px; */

	& .messageExample-arrow {
		display: block;
	}

	& .messageExample-msg-box {
		/* width: 400px;
		height: auto; */
		/* height: auto;
		width: 25vw; */
		display: flex;
		flex-direction: column;
		align-items: center;

		& img {
			border-radius: 8px;
			box-shadow: ${(props) => props.theme.currentTheme.boxShadow};
			width: 500px;
			height: auto;
		}
	}

	@media screen and (max-width: 1500px) {
		padding: 0px;
		top: 36px;
		left: -16px;

		& .messageExample-arrow {
			display: none;
		}
	}

	@media screen and (max-width: 800px) {
		position: relative;
		top: 8px;
		left: 0px;

		& .messageExample-msg-box {
			& img {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 500px) {
		left: -20px;

		& .messageExample-msg-box {
			& img {
				width: 110%;
			}
		}
	}
`;
