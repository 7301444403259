import React from 'react';
import { InputsFieldWrapper } from '../shared/Input/InputsFieldWrapper/InputsFieldWrapper';
import { CellInputRow } from '../shared/Input/ConfirmCode/CellInputRow';

export const InputsFieldConfirmEmail: React.FC = () => {
	return (
		<InputsFieldWrapper>
			<CellInputRow />
		</InputsFieldWrapper>
	);
};
