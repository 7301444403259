// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusForChangePasswordSlice = createSlice({
	name: 'checkEmptyStatusForChangePassword',
	initialState,
	reducers: {
		setEmptyStatusForChangePasswordInfo(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForChangePasswordInfo } =
	checkEmptyStatusForChangePasswordSlice.actions;
export const checkEmptyStatusForChangePasswordReducer =
	checkEmptyStatusForChangePasswordSlice.reducer;
