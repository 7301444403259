import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import { StyledModalImportUrl } from './ModalImportUrl.style';
import { WrappedInput } from '../../shared/Input/WrappedInput/WrappedInput';
import { SharedTransparentButton } from '../../shared/SharedTransparentButton/SharedTransparentButton';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setEmptyStatusForImportUrl } from '../../../app/slices/trainingBotMenu/inputImportUrl/inputImportUrl/checkEmptyStatusForImportUrlSlice';
import { useImportUrlToCrawlMutation } from '../../../app/api/chatbot.api';
import { ProgressSpinner } from '../../shared/ProgressSpinner/ProgressSpinner';

interface IModalImportUrlProps {
	closeFn: () => void;
	chatbotId: string;
}

interface IImportUrlComponentProps {
	closeFn: () => void;
	chatbotId: string;
}

const ImportUrlComponent: React.FC<IImportUrlComponentProps> = ({
	closeFn,
	chatbotId,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);
	const theme = { currentTheme };

	const [messageAfterRequest, setMessageAfterRequest] = useState<
		JSX.Element | string
	>('');

	const valueInputImportUrl = useAppSelector(
		(state: RootState) => state.importUrlValue.domain
	);

	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForImportUrl
	);
	const validInputStatus = useAppSelector(
		(state: RootState) => state.isValidImportUrlInputs.isValidDomainImportUrl
	);

	const refetchFn = useAppSelector(
		(state: RootState) => state.refetchFnTrainingBotMenu.refetchFn
	);

	const [importUrlFn, resultOfImportUrlFn] = useImportUrlToCrawlMutation();

	const dispatch = useAppDispatch();
	const handleScrapFn = async (): Promise<void> => {
		dispatch(setEmptyStatusForImportUrl(true));
		if (validInputStatus && valueInputImportUrl.length > 0) {
			const requestBody = {
				token,
				importUrlBody: {
					url: valueInputImportUrl,
					threshold: 50,
					delay: 2000,
					max: 100,
					summarize: true,
					id: parseInt(chatbotId),
				},
			};
			try {
				const result = await importUrlFn(requestBody);
				if ('data' in result) {
					setMessageAfterRequest(
						<Box sx={{ color: '#1592EC' }}>
							You have successfully imported the web page. Please wait for the
							snippets to load
						</Box>
					);
					await refetchFn();
				} else if ('error' in result) {
					setMessageAfterRequest(
						<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
					);
				}
			} catch (error) {
				setMessageAfterRequest(
					<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
				);
			}
		}
	};

	useEffect(() => {
		if (resultOfImportUrlFn.isError) {
			setMessageAfterRequest(
				<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
			);
		} else if (resultOfImportUrlFn.isLoading) {
			setMessageAfterRequest(<ProgressSpinner />);
		}
	}, [resultOfImportUrlFn]);

	return (
		<StyledModalImportUrl theme={theme}>
			<Box component="div" className="importUrlComponent-wrapper">
				<Box component="div" className="importUrl-title-subtitle">
					<Box component="div" className="importUrl-title">
						Import model
					</Box>
					<Box component="div" className="importUrl-subtitle">
						Import training model by importing a single web page, such as a
						single WikiPedia page, etc.
					</Box>
				</Box>
				<Box component="div" className="importUrl-input-url">
					<WrappedInput
						type="text"
						placeholder="Website URL"
						isEmpty={isButtonPressed}
						isValid={validInputStatus}
						uniqueNameOfInput="import-url-for-scrape"
					/>
				</Box>
				{messageAfterRequest}
				<Box component="div" className="importUrl-button-field">
					<Box>
						<SharedTransparentButton text="Close" buttonFunction={closeFn} />
					</Box>
					<Box>
						<SharedMainButton
							isDeleteButton={false}
							text="Scrape"
							buttonFunction={() => {
								void handleScrapFn();
							}}
						/>
					</Box>
				</Box>
			</Box>
		</StyledModalImportUrl>
	);
};

export const ModalImportUrl: React.FC<IModalImportUrlProps> = ({
	closeFn,
	chatbotId,
}) => {
	return (
		<SharedModal
			closeFn={closeFn}
			executeFn={() => {}}
			message={null}
			title=""
			isOnlyAlert={false}
			standaloneFC={
				<ImportUrlComponent chatbotId={chatbotId} closeFn={closeFn} />
			}
		/>
	);
};
