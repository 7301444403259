// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialConfirmEmailInputValueState {
	cardNumber: string;
	month: string;
	year: string;
	cvc: string;
}

const initialState: IInitialConfirmEmailInputValueState = {
	cardNumber: '',
	month: '',
	year: '',
	cvc: '',
};

const paymentMethodCardValueSlice = createSlice({
	name: 'paymentMethodCardValue',
	initialState,
	reducers: {
		setCardNumber(state, action: PayloadAction<string>) {
			state.cardNumber = action.payload.toString();
			return state;
		},
		setMonth(state, action: PayloadAction<string>) {
			state.month = action.payload;
			return state;
		},
		setYear(state, action: PayloadAction<string>) {
			state.year = action.payload;
			return state;
		},
		setCVC(state, action: PayloadAction<string>) {
			state.cvc = action.payload.toString();
			return state;
		},
	},
});

export const { setCardNumber, setMonth, setYear, setCVC } =
	paymentMethodCardValueSlice.actions;
export const paymentMethodCardValueReducer =
	paymentMethodCardValueSlice.reducer;
