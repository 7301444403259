// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialValidSearchBarState {
	isValidSearchBarInput: boolean;
}

const initialState: IInitialValidSearchBarState = {
	isValidSearchBarInput: false,
};

const isValidSearchBarInputsSlice = createSlice({
	name: 'isValidSearchBarInputs',
	initialState,
	reducers: {
		setValidStatusSearchBarInput(state, action: PayloadAction<boolean>) {
			state.isValidSearchBarInput = action.payload;
			return state;
		},
	},
});

export const { setValidStatusSearchBarInput } =
	isValidSearchBarInputsSlice.actions;
export const isValidSearchBarInputsReducer =
	isValidSearchBarInputsSlice.reducer;
