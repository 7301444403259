// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusForForgotSlice = createSlice({
	name: 'checkEmptyStatusForForgot',
	initialState,
	reducers: {
		setEmptyStatusForForgot(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForForgot } =
	checkEmptyStatusForForgotSlice.actions;
export const checkEmptyStatusForForgotReducer =
	checkEmptyStatusForForgotSlice.reducer;
