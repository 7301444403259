import {
	configureStore,
	type ThunkAction,
	type Action,
} from '@reduxjs/toolkit';
import { loginInputsValueReducer } from './slices/inputsLogin/loginInputsValueSlice';
import { checkEmptyStatusForChatGeneratorReducer } from './slices/inputsGenerator/checkEmptyStatusForChatGeneratorSlice';
import { isAuthorizationReducer } from './slices/isAuthorizationSlice';
import { isValidInputsReducer } from './slices/inputsLogin/isValidInputsSlice';
import { checkEmptyStatusForLoginReducer } from './slices/inputsLogin/checkEmptyStatusForLoginSlice';
import { chatGeneratorInputsValueReducer } from './slices/inputsGenerator/chatGeneratorInputsValueSlice';
import { isValidChatGeneratorInputsReducer } from './slices/inputsGenerator/isValidChatGeneratorInputsSlice';
import { isCodeJSGeneratedReducer } from './slices/isCodeJSGeneratedSlice';
import { isRegistrationReducer } from './slices/isRegistrationSlice';
import { checkEmptyStatusForRegistrationReducer } from './slices/inputsRegistration/checkEmptyStatusForRegistrationSlice';
import { registrationInputsValueReducer } from './slices/inputsRegistration/registrationInputsValueSlice';
import { isValidRegistrationInputsReducer } from './slices/inputsRegistration/isValidRegistrationInputsSlice';
import { openMobileMenuReducer } from './slices/openMobileMenuSlice';
import { isValidContactsInputsReducer } from './slices/inputsContacts/isValidContactsInputsSlice';
import { contactsInputsValueReducer } from './slices/inputsContacts/contactsInputsValueSlice';
import { checkEmptyStatusForContactsReducer } from './slices/inputsContacts/checkEmptyStatusForContactsSlice';
import { currentStepGeneratorReducer } from './slices/currentStepGeneratorSlice';
import { chatbotApi } from './api/chatbot.api';
import { answerApiCreateBotReducer } from './slices/answersFromAPI/answerApiCreateBotSlice';
import { answerApiLoginReducer } from './slices/answersFromAPI/answerApiLoginSlice';
import { answerApiRegistrationReducer } from './slices/answersFromAPI/answerApiRegistrationSlice';
import { forgotInputValueReducer } from './slices/inputsForgot/forgotInputValueSlice';
import { isValidForgotInputValueReducer } from './slices/inputsForgot/isValidForgotInputValueSlice';
import { checkEmptyStatusForForgotReducer } from './slices/inputsForgot/checkEmptyStatusForForgotSlice';
import { changeInformationInputsValueReducer } from './slices/inputsChangeInformation/changeInformationInputsValueSlice';
import { isValidChangeInformationValuesReducer } from './slices/inputsChangeInformation/isValidChangeInformationValuesSlice';
import { checkEmptyStatusForChangeInfoReducer } from './slices/inputsChangeInformation/checkEmptyStatusForChangeInfoSlice';
import { isValidResetInputValueReducer } from './slices/inputResetPassword/isValidResetInputValueSlice';
import { resetInputValueReducer } from './slices/inputResetPassword/resetInputValueSlice';
import { checkEmptyStatusForResetReducer } from './slices/inputResetPassword/checkEmptyStatusForResetSlice';
import { isScrollAppearReducer } from './slices/isScrollAppearSlice';
import { isValidConfirmEmailInputValueReducer } from './slices/inputsConfirmEmail/isValidConfirmEmailInputValueSlice';
import { confirmEmailInputValueReducer } from './slices/inputsConfirmEmail/confirmEmailInputValueSlice';
import { checkEmptyStatusForConfirmEmailReducer } from './slices/inputsConfirmEmail/checkEmptyStatusForConfirmEmailSlice';
import { isConfirmEmailReducer } from './slices/isConfirmEmailSlice';
import { isOpenConfirmEmailIssueReducer } from './slices/isOpenConfirmEmailIssueSlice';
import { cellsConfirmationCodeReducer } from './slices/cellsConfirmationCode/cellsConfirmationCodeSlice';
import { checkEmptyStatusForChangePasswordReducer } from './slices/inputsChangeInformation/checkEmptyStatusForChangePasswordSlice';
import { aboutOpenCloseDropDownReducer } from './slices/navbarSlices/aboutOpenCloseDropDownSlice';
import { switchThemeReducer } from './slices/switchThemeSlice';
import { isCheckboxActiveReducer } from './slices/isCheckboxActiveSlice';
import { showHideMenuReducer } from './slices/showHideMenuSlice';
import { answerApiPersonalInformationReducer } from './slices/answersFromAPI/answerApiPersonalInformationSlice';
import { stateChangeUserInfoMenuReducer } from './slices/stateChangeUserInfoMenuSlice/stateChangeUserInfoMenuSlice';
import { isValidDetailedBotCardInputsReducer } from './slices/inputsDetailedBotCard/isValidDetailedBotCardInputsSlice';
import { detailedBotCardInputsValueReducer } from './slices/inputsDetailedBotCard/detailedBotCardInputsValueSlice';
import { checkEmptyStatusForDetailedBotCardReducer } from './slices/inputsDetailedBotCard/checkEmptyStatusForDetailedBotCardSlice';
import { iconChatbotReducer } from './slices/iconChatbot/iconChatbotSlice';
import { paymentApi } from './api/payment.api/payment.api';
import { paymentMethodCardValueReducer } from './slices/inputsPaymentMethodCard/paymentMethodCardValue';
import { checkEmptyStatusForPaymentMethodCardValuesReducer } from './slices/inputsPaymentMethodCard/checkEmptyStatusForPaymentMethodCardValuesSlice';
import { isValidPaymentMethodCardValuesReducer } from './slices/inputsPaymentMethodCard/isValidPaymentMethodCardValuesSlice';
import { paymentMethodsMenuReducer } from './slices/paymentMethodsMenu/paymentMethodsMenuSlice';
import { currentPageTrainingBotMenuReducer } from './slices/trainingBotMenu/currentPageTrainingBotMenuSlice';
import { paginationValuesTrainingBotMenuReducer } from './slices/trainingBotMenu/paginationValuesTrainingBotMenuSlice';
import { checkEmptyStatusForImportUrlReducer } from './slices/trainingBotMenu/inputImportUrl/inputImportUrl/checkEmptyStatusForImportUrlSlice';
import { importUrlValueReducer } from './slices/trainingBotMenu/inputImportUrl/inputImportUrl/importUrlValueSlice';
import { isValidImportUrlInputsReducer } from './slices/trainingBotMenu/inputImportUrl/inputImportUrl/isValidImportUrlInputsSlice';
import { createChangePromptValuesReducer } from './slices/trainingBotMenu/inputImportUrl/inputsCreateChangePropmt/createChangePromptValuesSlice';
import { isValidCreateChangePromptInputsReducer } from './slices/trainingBotMenu/inputImportUrl/inputsCreateChangePropmt/isValidCreateChangePromptInputsSlice';
import { checkEmptyStatusForCreateChangePromptReducer } from './slices/trainingBotMenu/inputImportUrl/inputsCreateChangePropmt/checkEmptyStatusForCreateChangePromptSlice';
import { currentSnippetTrainingBotMenuReducer } from './slices/trainingBotMenu/currentSnippetTrainingBotMenuSlice';
import { openEditCurrentSnippetTrainingBotMenuReducer } from './slices/trainingBotMenu/openModal/openEditCurrentSnippetTrainingBotMenuSlice';
import { openDeleteCurrentSnippetTrainingBotMenuReducer } from './slices/trainingBotMenu/openModal/openDeleteCurrentSnippetTrainingBotMenuSlice';
import { deleteSnippetMessageTrainingBotMenuReducer } from './slices/trainingBotMenu/deleteSnippetMessageTrainingBotMenuSlice';
import { openVectoriseSnippetsTrainingBotMenuReducer } from './slices/trainingBotMenu/openModal/openVectoriseSnippetsTrainingBotMenuSlice';
import { vectoriseSnippetMessageTrainingBotMenuReducer } from './slices/trainingBotMenu/vectoriseSnippetMessageTrainingBotMenuSlice';
import { searchBarValueReducer } from './slices/trainingBotMenu/searchBar/searchBarValueSlice';
import { isValidSearchBarInputsReducer } from './slices/trainingBotMenu/searchBar/isValidSearchBarInputsSlice';
import { deleteAllSnippetsMessageTrainingBotMenuReducer } from './slices/trainingBotMenu/deleteAllSnippetsMessageTrainingBotMenuSlice';
import { currentChatbotIdTrainingBotMenuReducer } from './slices/trainingBotMenu/currentChatbotIdTrainingBotMenuSlice';
import { openTestBotTrainingBotMenuReducer } from './slices/trainingBotMenu/openModal/openTestBotTrainingBotMenuSlice';
import { testBotInputValueReducer } from './slices/trainingBotMenu/testBotInput/testBotInputValueSlice';
import { isValidTestBotInputReducer } from './slices/trainingBotMenu/testBotInput/isValidTestBotInputSlice';
import { checkEmptyStatusForTestBotInputReducer } from './slices/trainingBotMenu/testBotInput/checkEmptyStatusForTestBotInputSlice';
import { testBotMessageTrainingBotMenuReducer } from './slices/trainingBotMenu/testBotInput/testBotMessageTrainingBotMenuSlice';
import { refetchFnTrainingBotMenuReducer } from './slices/trainingBotMenu/refetchFnTrainingBotMenuSlice';
import { togglePeriodPriceMenuReducer } from './slices/paymentMethodsMenu/togglePeriodPriceMenuSlice';
import { paginationValuesHistoryMenuReducer } from './slices/trainingBotMenu/historyMenu/paginationValuesHistoryMenuSlice';
import { isValidSearchBarHistoryMenuInputsReducer } from './slices/trainingBotMenu/historyMenu/searchBarHistoryMenu/isValidSearchBarHistoryMenuInputsSlice';
import { searchBarHistoryMenuValueReducer } from './slices/trainingBotMenu/historyMenu/searchBarHistoryMenu/searchBarHistoryMenuValueSlice';
import { openExportSnippetsTrainingBotMenuReducer } from './slices/trainingBotMenu/openModal/openExportSnippetsTrainingBotMenuSlice';
import { currentChatbotIdForActionsTrainingBotMenuReducer } from './slices/trainingBotMenu/openModal/currentValues/currentChatbotIdForActionsTrainingBotMenuSlice';
import { notVectorisedSnippetsCountMessageTrainingBotMenuReducer } from './slices/trainingBotMenu/messagesFromApi/notVectorisedSnippetsCountMessageTrainingBotMenuSlice';
import { openImportSnippetsTrainingBotMenuReducer } from './slices/trainingBotMenu/openModal/importSnippets/openImportSnippetsTrainingBotMenuSlice';
import { openCacheSnippetsTrainingBotMenuReducer } from './slices/trainingBotMenu/openModal/openCacheSnippetsTrainingBotMenuSlice';
import { checkedSnippetsMenuReducer } from './slices/trainingBotMenu/checkedSnippets/checkedSnippetsSlice';
import { paymentsHistoryFondyPaginationReducer } from './slices/paymentsHistory/paymentsHistoryFondyPaginationSlice';
import { isCurrentFondyReducer } from './slices/isCurrentFondySlice';
import { deletePaymentMethodsMenuReducer } from './slices/paymentMethodsMenu/deletePaymentMethodsMenuSlice';
import { chooseTemplateReducer } from './slices/chooseTemplate/chooseTemplateSlice';
import { linkCurrentChatbotImgReducer } from './slices/inputsGenerator/linkCurrentChatbotImgSlice';
import { currentChatbotIdForHTMLCodeReducer } from './slices/inputsGenerator/currentChatbotIdForHTMLCodeSlice';
import { configModelMenuInputsReducer } from './slices/configInputs/configModelMenuInputs/configModelMenuInputsSlice';
import { isValidModelMenuInputsReducer } from './slices/configInputs/configModelMenuInputs/isValidModelMenuInputsSlice';
import { checkEmptyStatusForConfigModelMenuReducer } from './slices/configInputs/configModelMenuInputs/checkEmptyStatusForModelMenuInputsSlice';
import { configOpenAIMenuInputsReducer } from './slices/configInputs/configOpenAIMenuInputs/configOpenAIMenuInputsSlice';
import { isValidOpenAIMenuInputsReducer } from './slices/configInputs/configOpenAIMenuInputs/isValidOpenAIMenunputsSlice';
import { checkEmptyStatusForOpenAIMenuInputsReducer } from './slices/configInputs/configOpenAIMenuInputs/checkEmptyStatusForOpenAIMenuInputsSlice';
import { isOpenModalLogoutReducer } from './slices/isOpenModalLogout/isOpenModalLogoutSlice';
import { focusTextareaReducer } from './slices/testChatbotModal/focusTextareaSlice';
import { snippetsListReducer } from './slices/testChatbotModal/snippetsList.slice';
import { currentPageSocialMenuReducer } from './slices/socialMenu/currentPageSocialMenuSlice';
import { socialOpenCloseDropDownReducer } from './slices/navbarSlices/socialOpenCloseDropDownSlice';
import { inputsValueSocialIntegrateReducer } from './slices/inputsSocialIntegrate/inputsValueSocialIntegrate.slice';
import { isValidSocialIntegrateInputsReducer } from './slices/inputsSocialIntegrate/isValidSocialIntegrateInputs.slice';
import { checkEmptyStatusSocialIntegrateReducer } from './slices/inputsSocialIntegrate/checkEmptyStatusSocialIntegrate.slice';
import { changeChatbotIntegrationInputReducer } from './slices/socialMenu/changeChatbotIntegrationInput/changeChatbotIntegrationInput.slice';
import { checkEmptyStatusChangeChatbotIntegrationReducer } from './slices/socialMenu/changeChatbotIntegrationInput/checkEmptyStatusChangeChatbotIntegration.slice';
import { isValidChangeChatbotIntegrationInputReducer } from './slices/socialMenu/changeChatbotIntegrationInput/isValidChangeChatbotIntegrationInput.slice';
import { refetchFnSocialMenuReducer } from './slices/socialMenu/refetchFnSocialMenu.slice';

export const store = configureStore({
	reducer: {
		loginInputsValue: loginInputsValueReducer,
		checkEmptyStatusForChatGenerator: checkEmptyStatusForChatGeneratorReducer,
		isAuthorization: isAuthorizationReducer,
		isValidInputs: isValidInputsReducer,
		checkEmptyStatusForLogin: checkEmptyStatusForLoginReducer,
		chatGeneratorInputsValue: chatGeneratorInputsValueReducer,
		isValidChatGeneratorInputs: isValidChatGeneratorInputsReducer,
		isCodeJSGenerated: isCodeJSGeneratedReducer,
		isRegistration: isRegistrationReducer,
		checkEmptyStatusForRegistration: checkEmptyStatusForRegistrationReducer,
		registrationInputsValue: registrationInputsValueReducer,
		isValidRegistrationInputs: isValidRegistrationInputsReducer,
		openMobileMenu: openMobileMenuReducer,
		isValidContactsInputs: isValidContactsInputsReducer,
		contactsInputsValue: contactsInputsValueReducer,
		checkEmptyStatusForContacts: checkEmptyStatusForContactsReducer,
		currentStepGenerator: currentStepGeneratorReducer,

		forgotInputValue: forgotInputValueReducer,
		isValidForgotInputValue: isValidForgotInputValueReducer,
		checkEmptyStatusForForgot: checkEmptyStatusForForgotReducer,

		answerApiCreateBot: answerApiCreateBotReducer,
		answerApiLogin: answerApiLoginReducer,
		answerApiRegistration: answerApiRegistrationReducer,
		answerApiPersonalInformation: answerApiPersonalInformationReducer,

		changeInformationInputsValue: changeInformationInputsValueReducer,
		isValidChangeInformationValues: isValidChangeInformationValuesReducer,
		checkEmptyStatusForChangeInfo: checkEmptyStatusForChangeInfoReducer,
		checkEmptyStatusForChangePassword: checkEmptyStatusForChangePasswordReducer,

		isValidResetInputValue: isValidResetInputValueReducer,
		resetInputValue: resetInputValueReducer,
		checkEmptyStatusForReset: checkEmptyStatusForResetReducer,

		isScrollAppear: isScrollAppearReducer,

		isValidConfirmEmailInputValue: isValidConfirmEmailInputValueReducer,
		confirmEmailInputValue: confirmEmailInputValueReducer,
		checkEmptyStatusForConfirmEmail: checkEmptyStatusForConfirmEmailReducer,

		isConfirmEmail: isConfirmEmailReducer,
		isOpenConfirmEmailIssue: isOpenConfirmEmailIssueReducer,

		cellsConfirmationCode: cellsConfirmationCodeReducer,

		aboutOpenCloseDropDown: aboutOpenCloseDropDownReducer,
		socialOpenCloseDropDown: socialOpenCloseDropDownReducer,

		switchTheme: switchThemeReducer,

		isCheckboxActive: isCheckboxActiveReducer,

		showHideMenu: showHideMenuReducer,

		stateChangeUserInfoMenu: stateChangeUserInfoMenuReducer,

		isValidDetailedBotCardInputs: isValidDetailedBotCardInputsReducer,
		detailedBotCardInputsValue: detailedBotCardInputsValueReducer,
		checkEmptyStatusForDetailedBotCard:
			checkEmptyStatusForDetailedBotCardReducer,

		iconChatbot: iconChatbotReducer,

		paymentMethodCardValue: paymentMethodCardValueReducer,
		checkEmptyStatusForPaymentMethodCardValues:
			checkEmptyStatusForPaymentMethodCardValuesReducer,
		isValidPaymentMethodCardValues: isValidPaymentMethodCardValuesReducer,

		togglePeriodPriceMenu: togglePeriodPriceMenuReducer,

		paymentMethodsMenu: paymentMethodsMenuReducer,

		currentPageTrainingBotMenu: currentPageTrainingBotMenuReducer,
		paginationValuesTrainingBotMenu: paginationValuesTrainingBotMenuReducer,

		checkEmptyStatusForImportUrl: checkEmptyStatusForImportUrlReducer,
		importUrlValue: importUrlValueReducer,
		isValidImportUrlInputs: isValidImportUrlInputsReducer,

		createChangePromptValues: createChangePromptValuesReducer,
		isValidCreateChangePromptInputs: isValidCreateChangePromptInputsReducer,
		checkEmptyStatusForCreateChangePrompt:
			checkEmptyStatusForCreateChangePromptReducer,

		currentSnippetTrainingBotMenu: currentSnippetTrainingBotMenuReducer,

		openEditCurrentSnippetTrainingBotMenu:
			openEditCurrentSnippetTrainingBotMenuReducer,
		openDeleteCurrentSnippetTrainingBotMenu:
			openDeleteCurrentSnippetTrainingBotMenuReducer,
		openVectoriseSnippetsTrainingBotMenu:
			openVectoriseSnippetsTrainingBotMenuReducer,
		openTestBotTrainingBotMenu: openTestBotTrainingBotMenuReducer,
		openExportSnippetsTrainingBotMenu: openExportSnippetsTrainingBotMenuReducer,

		deleteSnippetMessageTrainingBotMenu:
			deleteSnippetMessageTrainingBotMenuReducer,
		vectoriseSnippetMessageTrainingBotMenu:
			vectoriseSnippetMessageTrainingBotMenuReducer,

		searchBarValue: searchBarValueReducer,
		isValidSearchBarInputs: isValidSearchBarInputsReducer,

		deleteAllSnippetsMessageTrainingBotMenu:
			deleteAllSnippetsMessageTrainingBotMenuReducer,

		currentChatbotIdTrainingBotMenu: currentChatbotIdTrainingBotMenuReducer,

		testBotInputValue: testBotInputValueReducer,
		isValidTestBotInput: isValidTestBotInputReducer,
		checkEmptyStatusForTestBotInput: checkEmptyStatusForTestBotInputReducer,
		testBotMessageTrainingBotMenu: testBotMessageTrainingBotMenuReducer,

		refetchFnTrainingBotMenu: refetchFnTrainingBotMenuReducer,

		paginationValuesHistoryMenu: paginationValuesHistoryMenuReducer,
		isValidSearchBarHistoryMenuInputs: isValidSearchBarHistoryMenuInputsReducer,
		searchBarHistoryMenuValue: searchBarHistoryMenuValueReducer,

		currentChatbotIdForActionsTrainingBotMenu:
			currentChatbotIdForActionsTrainingBotMenuReducer,

		notVectorisedSnippetsCountMessageTrainingBotMenu:
			notVectorisedSnippetsCountMessageTrainingBotMenuReducer,

		openImportSnippetsTrainingBotMenu: openImportSnippetsTrainingBotMenuReducer,

		openCacheSnippetsTrainingBotMenu: openCacheSnippetsTrainingBotMenuReducer,

		checkedSnippetsMenu: checkedSnippetsMenuReducer,

		paymentsHistoryFondyPagination: paymentsHistoryFondyPaginationReducer,

		isCurrentFondy: isCurrentFondyReducer,

		deletePaymentMethodsMenu: deletePaymentMethodsMenuReducer,

		chooseTemplate: chooseTemplateReducer,

		linkCurrentChatbotImg: linkCurrentChatbotImgReducer,
		currentChatbotIdForHTMLCode: currentChatbotIdForHTMLCodeReducer,

		configModelMenuInputs: configModelMenuInputsReducer,
		isValidModelMenuInputs: isValidModelMenuInputsReducer,
		checkEmptyStatusForConfigModelMenu:
			checkEmptyStatusForConfigModelMenuReducer,

		configOpenAIMenuInputs: configOpenAIMenuInputsReducer,
		isValidOpenAIMenuInputs: isValidOpenAIMenuInputsReducer,
		checkEmptyStatusForOpenAIMenuInputs:
			checkEmptyStatusForOpenAIMenuInputsReducer,

		isOpenModalLogout: isOpenModalLogoutReducer,

		focusTextarea: focusTextareaReducer,
		snippetsListForTestChatbot: snippetsListReducer,

		currentPageSocialMenu: currentPageSocialMenuReducer,

		inputsValueSocialIntegrate: inputsValueSocialIntegrateReducer,
		isValidSocialIntegrateInputs: isValidSocialIntegrateInputsReducer,
		checkEmptyStatusSocialIntegrate: checkEmptyStatusSocialIntegrateReducer,

		changeChatbotIntegrationInput: changeChatbotIntegrationInputReducer,
		checkEmptyStatusChangeChatbotIntegration:
			checkEmptyStatusChangeChatbotIntegrationReducer,
		isValidChangeChatbotIntegrationInput:
			isValidChangeChatbotIntegrationInputReducer,
		refetchFnSocialMenu: refetchFnSocialMenuReducer,

		[chatbotApi.reducerPath]: chatbotApi.reducer,
		[paymentApi.reducerPath]: paymentApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		})
			.concat(chatbotApi.middleware)
			.concat(paymentApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
