import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as Edit } from '../../pic/editBlue.svg';
import { ReactComponent as Delete } from '../../pic/deleteBlue.svg';
import { type IIDataSnippetsRows } from './SnippetsTable';
import { SharedCheckboxItem } from '../../../shared/SharedCheckboxItem/SharedCheckboxItem';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { CustomTooltip } from '../../../shared/CustomTooltip/CustomTooltip';
import { useOpenModalEdit } from '../../hooks/openModal/useOpenModalEdit';
import { useOpenModalDelete } from '../../hooks/openModal/useOpenModalDelete';
import CachedIcon from '@mui/icons-material/Cached';
import { useOpenModalCache } from '../../hooks/openModal/useOpenModalCache';
import { useAddRemoveCurrentSnippetAsChecked } from '../../hooks/useAddRemoveCurrentSnippetAsChecked';

export interface IGridCardProps {
	row: IIDataSnippetsRows;
}

export const colorCircle = (condition: boolean): JSX.Element => {
	return (
		<Box
			component="div"
			className="colorCircle"
			sx={{
				borderRadius: '50%',
				width: '16px',
				height: '16px',
				background: condition ? '#63E597' : '#F3DC69',
			}}
		></Box>
	);
};

export const GridCard: React.FC<IGridCardProps> = ({ row }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const openModalEdit = useOpenModalEdit();
	const openModalDelete = useOpenModalDelete();
	const openModalCache = useOpenModalCache();

	const allCheckedArray = useAppSelector(
		(state: RootState) => state.checkedSnippetsMenu.checkedSnippetsArray
	);
	const allFunctionsWithCheckedArray = useAddRemoveCurrentSnippetAsChecked();
	const { addCurrentSnippetAsChecked, removeCurrentSnippetAsChecked } =
		allFunctionsWithCheckedArray;

	const handleClickOnEdit = (): void => {
		openModalEdit(row.id.toString(), row.prompt, row.completion);
	};

	const handleClickOnDelete = (): void => {
		openModalDelete(row.id.toString());
	};

	const handleClickOnCache = (): void => {
		openModalCache(row.id.toString());
	};

	const [isChecked, setIsChecked] = useState(false);

	const handleAddRemoveCurrentSnippetAsChecked = (): void => {
		const isMatch = allCheckedArray.find(
			(currentSnippet) => currentSnippet === row.id
		);
		if (isMatch !== undefined && isChecked) {
			removeCurrentSnippetAsChecked(row.id);
		} else if (isMatch === undefined && !isChecked) {
			addCurrentSnippetAsChecked(row.id);
		}
	};

	useEffect(() => {
		allCheckedArray.length === 0 && setIsChecked(false);
		const isMatch = allCheckedArray.find(
			(currentSnippet) => currentSnippet === row.id
		);
		if (isMatch !== undefined) {
			setIsChecked(true);
		} else if (isMatch === undefined) {
			setIsChecked(false);
		}
	}, [allCheckedArray]);

	return (
		<Box component="div" className="grid-card-wrapper">
			{/* Hide checkbox */}
			<SharedCheckboxItem
				setStateAction={handleAddRemoveCurrentSnippetAsChecked}
				label=""
				className="snippet-checkbox"
				isChecked={isChecked}
			/>

			<Box component="div" className="grid-card-item domain-grid-item">
				{row.prompt}
			</Box>
			<Box component="div" className="grid-card-item domain-grid-item">
				{row.url}
			</Box>
			{/* Hide bot name */}
			{/* <Box component="div" className="grid-card-item bot-name-item">
				{row.botName}
			</Box> */}
			<Box component="div" className="grid-card-item created-item">
				{row.created}
			</Box>
			{/* Hide cashed and pushed */}

			<Box component="div" className="grid-card-item cashed-item">
				{colorCircle(row.cashed)}
			</Box>
			{/* <Box component="div" className="grid-card-item pushed-item">
				{colorCircle(row.pushed)}
			</Box> */}
			<Box component="div" className="grid-card-item vectorized-item">
				{colorCircle(row.vectorized)}
			</Box>
			<Box
				component="div"
				className="grid-card-item actions-grid-item actions-buttons"
			>
				<CustomTooltip
					theme={theme}
					title={
						row.cashed
							? 'Your snippet has been cached'
							: 'Cache current snippet'
					}
				>
					<CachedIcon
						onClick={() => {
							if (!row.cashed) {
								handleClickOnCache();
							}
						}}
						className={row.cashed ? 'disabled-svg' : ''}
					/>
				</CustomTooltip>
				<CustomTooltip
					theme={theme}
					title="Edit current snippet: prompt and/or completion"
				>
					<Edit
						onClick={handleClickOnEdit}
						style={{ width: '25px', height: 'auto' }}
					/>
				</CustomTooltip>
				<CustomTooltip theme={theme} title="Delete current snippet">
					<Delete
						onClick={handleClickOnDelete}
						style={{ width: '22px', height: 'auto' }}
					/>
				</CustomTooltip>
			</Box>
		</Box>
	);
};
