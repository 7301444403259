import React from 'react';
import { StyledSharedDesktopTable } from './SharedDesktopTable.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { Box, FormControl } from '@mui/material';
import { SharedGridCard } from './SharedGridCard';
// import { SharedCheckboxItem } from '../../../shared/SharedCheckboxItem/SharedCheckboxItem';

export interface IIDataSnippetsRows {
	id: number;
	prompt: string;
	url: string;
	botName: string;
	created: string;
	cashed: boolean;
	pushed: boolean;
	vectorized: boolean;
	completion: string;
}

export interface IGenericGridElement {
	valueGridElement: string;
	valueJSX: JSX.Element;
	widthElement: string;
	isOnlyBool?: boolean;
	boolValue?: boolean;
}

export interface IGenericActionButton {
	valueJSX: JSX.Element;
}

export interface IGenericRow {
	gridElementsRow: IGenericGridElement[];
	actionButtons: JSX.Element[];
}

export interface IGenericTable {
	titles: IGenericGridElement[];
	genericRows: IGenericRow[];
}

interface ISharedDesktopTableProps {
	table: IGenericTable;
	hasActionButton: boolean;
}

export const SharedDesktopTable: React.FC<ISharedDesktopTableProps> = ({
	table,
	hasActionButton,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		currentTheme,
		hoverBackgroundColor:
			currentTheme.accentHighlightColorForTable.concat('80'),
	};

	const { titles, genericRows } = table;

	return (
		<StyledSharedDesktopTable theme={theme}>
			<Box className="grid-table-desktop-container">
				<FormControl>
					<Box component="div" className="title-wrapper">
						{titles.map((currentTitleElement) => {
							return (
								<Box
									key={currentTitleElement.valueGridElement.concat(
										Math.random().toString()
									)}
									className="title-grid-element grid-item"
									sx={{
										width: `${currentTitleElement.widthElement} !important`,
									}}
								>
									<Box component="span">
										{currentTitleElement.valueGridElement}
									</Box>
									<Box component="div">{currentTitleElement.valueJSX}</Box>
								</Box>
							);
						})}
						{hasActionButton ? (
							<Box className="title-grid-element grid-item actions-grid-item actions-title-grid-item">
								Actions
							</Box>
						) : (
							<></>
						)}
					</Box>
					{titles.length === 0 && (
						<Box sx={{ alignSelf: 'center', p: 4 }}>
							You don`t have data yet
						</Box>
					)}
					{genericRows.map((genericRow, index) => (
						<SharedGridCard
							key={index}
							row={genericRow}
							hasActionButton={hasActionButton}
						/>
					))}
				</FormControl>
			</Box>
		</StyledSharedDesktopTable>
	);
};
