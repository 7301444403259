// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledCustomTextarea = styled.div`
	width: 470px;
	height: 300px;

	& textarea {
		resize: none;
		width: 100%;
		max-width: 100%;
		max-height: 100%;
		min-height: 100%;
		min-width: 100%;

		background: ${(props) => props.theme.currentTheme.cardsBackground};
		border-radius: 8px !important;
		border: ${(props) => props.theme.color};

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
		outline: none;
		padding: 17px;

		font-family: 'Nunito';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.05em;
		color: ${(props) => props.theme.currentTheme.fontColorInput};

		/* The most usable way to create gradient border with border-radius */
		&:hover {
			background: linear-gradient(
						${(props) => props.theme.currentTheme.cardsBackground} 0 0
					)
					padding-box,
				linear-gradient(to right, #43f2ff, #691eff) border-box;
			border: 1px solid transparent;
		}

		&:focus {
			border: 2px solid #1592ec;
			transform: scale(1.008);
		}

		&::placeholder {
			font-family: 'Nunito';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 100%;
			letter-spacing: -0.01em;
			color: ${(props) => props.theme.currentTheme.fontColorInput};
			opacity: 1;
			padding: 0px;
		}
	}

	@media screen and (max-width: 1500px) {
		& textarea {
			font-size: 14px;
			&::placeholder {
				font-size: 14px;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		width: 70vmin;
		max-width: 550px;
	}

	@media screen and (max-width: 500px) {
		width: 85vmin;
		max-width: 550px;
		& textarea {
			font-size: 16px;
			&::placeholder {
				font-size: 16px;
			}
		}
	}
`;
