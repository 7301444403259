import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { App } from './App';
import './index.css';
import { ParallaxProvider } from 'react-scroll-parallax';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const container = document.getElementById('root') as Element;
const root = createRoot(container);
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<GoogleOAuthProvider clientId={clientId ?? ''}>
				<GoogleReCaptchaProvider
					container={{
						parameters: {
							badge: 'inline',
						},
					}}
					reCaptchaKey={reCaptchaKey ?? ''}
				>
					<ParallaxProvider>
						<App />
					</ParallaxProvider>
				</GoogleReCaptchaProvider>
			</GoogleOAuthProvider>
		</Provider>
	</React.StrictMode>
);
