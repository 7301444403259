import React, { useState, useEffect } from 'react';
import { StyledSharedDateRangePicker } from './SharedDateRangePicker.style';
import DateRangePicker, { type DateRange } from 'rsuite/DateRangePicker';
import { type ICurrentThemeColor } from '../../../../app/slices/switchThemeSlice';
import 'rsuite/dist/rsuite.min.css';

interface ISharedDateRangePickerProps {
	setStateDateRangeFn: (startDate: string, lastDate: string) => void;
	placeholder: string;
	currentTheme: ICurrentThemeColor;
	removeStateDateRangeFn: () => void;
}

export const SharedDateRangePicker: React.FC<ISharedDateRangePickerProps> = ({
	setStateDateRangeFn,
	placeholder,
	currentTheme,
	removeStateDateRangeFn,
}) => {
	const [startDate, setStartDate] = useState<Date>();
	const [lastDate, setLastDate] = useState<Date>();

	const handleChangeDate = (datesRange: DateRange | null): void => {
		if (datesRange !== null) {
			setStartDate(datesRange[0]);
			setLastDate(datesRange[1]);
		}
	};

	useEffect(() => {
		if (lastDate !== undefined && startDate !== undefined) {
			setStateDateRangeFn(startDate.toISOString(), lastDate.toISOString());
		} else {
			removeStateDateRangeFn();
		}
	}, [lastDate, startDate]);

	const theme = { currentTheme };

	return (
		<StyledSharedDateRangePicker theme={theme}>
			<DateRangePicker
				onChange={handleChangeDate}
				placeholder={placeholder}
				character=" - "
				onClean={removeStateDateRangeFn}
			/>
		</StyledSharedDateRangePicker>
	);
};
