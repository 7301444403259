// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const isOpenConfirmEmailIssueSlice = createSlice({
	name: 'isOpenConfirmEmailIssue',
	initialState,
	reducers: {
		setConfirmEmailIssueStatus(state, action: PayloadAction<boolean>) {
			state = action.payload;
			return state;
		},
	},
});

export const { setConfirmEmailIssueStatus } =
	isOpenConfirmEmailIssueSlice.actions;
export const isOpenConfirmEmailIssueReducer =
	isOpenConfirmEmailIssueSlice.reducer;
