// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { useLazyVectoriseSnippetsByChatbotIdQuery } from '../../../app/api/chatbot.api';
import { setVectoriseSnippetMessage } from '../../../app/slices/trainingBotMenu/vectoriseSnippetMessageTrainingBotMenuSlice';

export const useVectoriseSnippet = (
	chatbotId: number
): (() => Promise<void>) => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const vectoiriseSnippetBody = {
		token,
		chatbotId: chatbotId.toString(),
	};

	const lazyVectoriseSnippetsFn = useLazyVectoriseSnippetsByChatbotIdQuery()[0];

	const dispatch = useAppDispatch();

	return async () => {
		try {
			const resultLazyVectorise = await lazyVectoriseSnippetsFn(
				vectoiriseSnippetBody
			);
			const { isLoading, isError, isSuccess } = resultLazyVectorise;

			if (isError) {
				dispatch(setVectoriseSnippetMessage('error'));
			} else if (isLoading) {
				dispatch(setVectoriseSnippetMessage('loading'));
			} else if (isSuccess) {
				dispatch(setVectoriseSnippetMessage('success'));
			}
		} catch (error) {
			dispatch(setVectoriseSnippetMessage('error'));
		}
	};
};
