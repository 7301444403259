// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface ICreateBotDataResponse {
	isSuccess: boolean;
	errorMessage: string[];
}

const initialState: ICreateBotDataResponse = {
	isSuccess: false,
	errorMessage: [],
};

const answerApiCreateBotSlice = createSlice({
	name: 'answerApiCreateBot',
	initialState,
	reducers: {
		setAnswerApiCreateBotStatus(state, action) {
			state.isSuccess = action.payload;
			return state;
		},
		setAnswerApiCreateBotErrorMessage(state, action) {
			state.errorMessage = action.payload;
			return state;
		},
	},
});

export const {
	setAnswerApiCreateBotStatus,
	setAnswerApiCreateBotErrorMessage,
} = answerApiCreateBotSlice.actions;
export const answerApiCreateBotReducer = answerApiCreateBotSlice.reducer;
