import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledPlanMenu } from './PlanMenu.style';
import { useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { TogglePeriod } from './TogglePeriod/TogglePeriod';
import { HistoryPayment, type IPayment } from './HistoryPayment/HistoryPayment';
import {
	useDeleteSubscriptionByIdMutation,
	useGetStripeProductsQuery,
	useGetSubscriptionStatusQuery,
	useLazyGetSubscriptionStatusQuery,
} from '../../app/api/payment.api/payment.api';
import { ProgressSpinner } from '../shared/ProgressSpinner/ProgressSpinner';
import { ProgressDots } from '../shared/ProgressDots/ProgressDots';
import { type ISubscriptionStatusCurrentPlanObject } from '../../app/api/payment.api/interfaces/getSubscriptionStatus.interface';
import {
	getDateDDMMYY,
	getDateDDMMYYFromTimeCode,
} from '../../app/utils/payment.util';
import { OpenCloseHistoryButton } from './HistoryPayment/OpenCloseHistoryButton';
import { PaymentMethodsMenuInModal } from './PaymentMethodsMenu/PaymentMethodsMenu';
import {
	getSessionItem,
	removeSessionItem,
} from '../../app/utils/storageUtils';
import {
	useGetProductsChatbotQuery,
	useGetStatisticAboutAccountQuery,
	useLazyCancelSubscriptionFondyQuery,
	useLazyGetHistoryFondyQuery,
} from '../../app/api/chatbot.api';
import { type IGetHistoryFondyResponseResult } from '../../app/api/interfaces/fondy/getHistoryFondy.interface';
import { type IStatisticAboutAccountResponse } from '../../app/api/interfaces/interfacesRequest';
import { CardsPricePlan } from './CardsPricePlan/CardsPricePlan';
import { type QueryStatus } from '@reduxjs/toolkit/dist/query';
import { CurrentPlanAllData } from './CurrentPlanAllData/CurrentPlanAllData';

export interface ICurrentPlanCardData {
	planName: string;
	planPrice: string;
	expireDate: string;
}

export const PlanMenu: React.FC = () => {
	const [openCloseHistory, setOpenCloseHistory] = useState(false);
	const [changeablePlanOptions, setChangeablePlanOptions] = useState<{
		currentPlanName: string;
		currentPrice: string;
		priceId: string;
	}>({ currentPlanName: '', currentPrice: '', priceId: '' });

	const [openCloseModalPaymentMethods, setOpenCloseModalPaymentMethods] =
		useState(false);
	const [
		openCloseModalConfirmCancelSubscribe,
		setOpenCloseModalConfirmCancelSubscribe,
	] = useState(false);

	const isCurrentFondy = useAppSelector(
		(state: RootState) => state.isCurrentFondy
	);

	const currentPeriod = useAppSelector(
		(state: RootState) => state.togglePeriodPriceMenu.currentPeriod
	);
	const currentPage = useAppSelector(
		(state: RootState) =>
			state.paymentsHistoryFondyPagination.startFetchPageHistoryFondyPagination
	);

	const openModalToCreateSubscription = (
		conditionModal: boolean,
		currentPlanName: string,
		currentPrice: string,
		priceId: string
	): void => {
		setOpenCloseModalPaymentMethods(conditionModal);
		setChangeablePlanOptions({
			currentPlanName,
			currentPrice,
			priceId,
		});
	};

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const [
		loadingErrorContentDeleteSubscribe,
		setLoadingErrorContentDeleteSubscribe,
	] = useState<JSX.Element | null>(null);

	// GET PRODUCTS STRIPE
	const {
		data: getStripeProductsData,
		isError: isErrorGetProducts,
		isLoading: isLoadingGetProducts,
		isSuccess: isSuccessGetProducts,
		refetch: refetchGetStripeProductsQuery,
	} = useGetStripeProductsQuery('');

	// GET PRODUCTS FONDY
	const {
		data: getFondyProductsData,
		isError: isErrorGetProductsFondy,
		isLoading: isLoadingGetProductsFondy,
		isSuccess: isSuccessGetProductsFondy,
		refetch: refetchGetFondyProductsQuery,
	} = useGetProductsChatbotQuery({ token });

	// GET SUBSCRIPTION DATA FROM STRIPE
	const {
		data: dataSubscriptionStatus,
		isLoading: isLoadingDataSubscriptionStatus,
		isError: isErrorDataSubscriptionStatus,
		isSuccess: isSuccessDataSubscriptionStatus,
		refetch: refetchSubscriptionStatus,
	} = useGetSubscriptionStatusQuery({
		token,
		status: 'all',
	});

	// GET SUBSCRIPTION DATA FROM FONDY
	const {
		data: statisticDataFirstRender,
		isLoading: loadingStatusFondySubscription,
		isError: errorStatusFondySubscription,
		refetch: refetchStatisticData,
	} = useGetStatisticAboutAccountQuery({ token });

	const [getAllSubscriptionStatusForHistory, statusObjectOfGetAllSubscription] =
		useLazyGetSubscriptionStatusQuery();

	const [getHistoryFondy, resultOfGetHistoryFondy] =
		useLazyGetHistoryFondyQuery();

	const [
		isLoadingDataSubscriptionStatusAllHistory,
		setIsLoadingDataSubscriptionStatusAllHistory,
	] = useState(false);
	const [
		isErrorDataSubscriptionStatusAllHistory,
		setIsErrorDataSubscriptionStatusAllHistory,
	] = useState(false);
	const [
		isSuccessDataSubscriptionStatusAllHistory,
		setIsSuccessDataSubscriptionStatusAllHistory,
	] = useState(false);

	useEffect(() => {
		const planIdFromSessionStorage = getSessionItem('planId');
		try {
			if (
				!isCurrentFondy &&
				planIdFromSessionStorage !== null &&
				getStripeProductsData !== undefined
			) {
				const necessaryPlanFromLending = [...getStripeProductsData].filter(
					(currentPricePlan) => currentPricePlan.id === planIdFromSessionStorage
				)[0];
				const yearPeriodArray = necessaryPlanFromLending.prices.filter(
					(pricePlan) => pricePlan.recurring.interval === 'year'
				)[0];
				const monthPeriodArray = necessaryPlanFromLending.prices.filter(
					(pricePlan) => pricePlan.recurring.interval === 'month'
				)[0];

				const currentPriceByPeriod =
					currentPeriod === 'Month'
						? (monthPeriodArray.unit_amount / 100).toString()
						: (yearPeriodArray.unit_amount / 100).toString();

				const currentPriceIdByPeriod =
					currentPeriod === 'Month' ? monthPeriodArray.id : yearPeriodArray.id;

				openModalToCreateSubscription(
					true,
					necessaryPlanFromLending.name,
					currentPriceByPeriod,
					currentPriceIdByPeriod
				);
				removeSessionItem('planId');
			} else if (
				isCurrentFondy &&
				planIdFromSessionStorage !== null &&
				getFondyProductsData !== undefined
			) {
				const necessaryPlanFromLending = [...getFondyProductsData.data].filter(
					(currentPricePlan) =>
						currentPricePlan.id.toString() === planIdFromSessionStorage
				)[0];

				if (necessaryPlanFromLending !== undefined) {
					openModalToCreateSubscription(
						true,
						necessaryPlanFromLending.name,
						(necessaryPlanFromLending.priceInCentsUSD / 100).toString(),
						necessaryPlanFromLending.id.toString()
					);
					removeSessionItem('planId');
				} else {
					removeSessionItem('planId');
				}
			}
		} catch (error) {
			console.log(error);
		}
	}, [getStripeProductsData, getFondyProductsData]);

	useEffect(() => {
		const checkStatus = (status: QueryStatus): void => {
			setIsLoadingDataSubscriptionStatusAllHistory(status === 'pending');
			setIsSuccessDataSubscriptionStatusAllHistory(status === 'fulfilled');
			setIsErrorDataSubscriptionStatusAllHistory(
				status !== 'pending' && status !== 'fulfilled'
			);
		};

		if (isCurrentFondy) {
			checkStatus(resultOfGetHistoryFondy.status);
		} else {
			checkStatus(statusObjectOfGetAllSubscription.status);
		}
	}, [statusObjectOfGetAllSubscription, resultOfGetHistoryFondy]);

	const getFunctionForHistory = async (): Promise<void> => {
		await getAllSubscriptionStatusForHistory({
			token,
			status: 'all',
		});
	};

	const [deletePlan, resultOfDeletionPlan] =
		useDeleteSubscriptionByIdMutation();

	const [deletePlanFondy, resultOfDeletionPlanFondy] =
		useLazyCancelSubscriptionFondyQuery();

	const [currentPlanCardData, setCurrentPlanCardData] =
		useState<ICurrentPlanCardData>({
			planName: '',
			planPrice: '',
			expireDate: '',
		});
	const [currentPlanName, setCurrentPlanName] = useState('');
	const [currentPlanId, setCurrentPlanId] = useState('');
	const [currentPlanPeriod, setCurrentPlanPeriod] = useState('month');
	const [isExistCurrentPlan, setIsExistCurrentPlan] = useState(false);
	const [historyArray, setHistoryArray] = useState<IPayment[]>();
	const [totalResults, setTotalResults] = useState<number>(10);

	const handleUpdateCurrentPlan = (
		statisticData: IStatisticAboutAccountResponse | undefined
	): void => {
		if (isCurrentFondy && statisticData !== undefined) {
			if (getFondyProductsData !== undefined) {
				const currentPlan = getFondyProductsData.data.find(
					(plan) => plan.id.toString() === statisticData.subscriptionId
				);
				if (currentPlan !== undefined) {
					setIsExistCurrentPlan(true);
					setCurrentPlanName(currentPlan.name);
					setCurrentPlanId(currentPlan.id.toString());
					setCurrentPlanPeriod(
						currentPlan.productValue === 2592000 ? 'month' : 'year'
					);
					setCurrentPlanCardData({
						expireDate: statisticData.subscriptionDueDate.toString(),
						planName: currentPlan.name,
						planPrice: (currentPlan.priceInCentsUSD / 100).toString(),
					});
				}
			} else {
				setCurrentPlanCardData({ expireDate: '', planName: '', planPrice: '' });
				setCurrentPlanName('');
				setCurrentPlanPeriod('month');
				setCurrentPlanId('');
				setIsExistCurrentPlan(false);
			}
		}
	};

	const refetchFunctionAfterChoosePlan = async (): Promise<void> => {
		if (isCurrentFondy) {
			const updateStatisticData = (await refetchStatisticData()).data;
			handleUpdateCurrentPlan(updateStatisticData);
			await refetchGetFondyProductsQuery();
		} else {
			await refetchSubscriptionStatus();
		}
	};

	const handleDeletePlan = async (): Promise<void> => {
		try {
			let subscriptionId = '';

			if (
				dataSubscriptionStatus !== undefined &&
				dataSubscriptionStatus?.data.filter(
					(allSubscription) => allSubscription.status === 'active'
				).length > 0
			) {
				subscriptionId = dataSubscriptionStatus?.data.filter(
					(allSubscription) => allSubscription.status === 'active'
				)[0].id;
			} else if (
				dataSubscriptionStatus !== undefined &&
				dataSubscriptionStatus?.data.filter(
					(allSubscription) => allSubscription.status === 'trialing'
				).length > 0
			) {
				subscriptionId = dataSubscriptionStatus?.data.filter(
					(allSubscription) => allSubscription.status === 'trialing'
				)[0].id;
			}

			const deleteResult = !isCurrentFondy
				? await deletePlan({
						token,
						subscriptionId,
				  })
				: await deletePlanFondy({ token });

			if (isCurrentFondy) {
				await refetchGetFondyProductsQuery();
				const updateStatisticData = (await refetchStatisticData()).data;
				handleUpdateCurrentPlan(updateStatisticData);
			} else {
				await refetchSubscriptionStatus();
				await refetchGetStripeProductsQuery();
			}

			if ('data' in deleteResult) {
				setOpenCloseModalConfirmCancelSubscribe(false);
				setIsExistCurrentPlan(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (resultOfDeletionPlan.isError) {
			setLoadingErrorContentDeleteSubscribe(
				<Box
					component="div"
					className="error-message-from-api"
					sx={{ color: 'red !important' }}
				>
					Something went wrong
				</Box>
			);
		} else if (resultOfDeletionPlan.isLoading) {
			setLoadingErrorContentDeleteSubscribe(<ProgressSpinner />);
		} else if (resultOfDeletionPlan.isSuccess) {
			setLoadingErrorContentDeleteSubscribe(null);
		}
	}, [resultOfDeletionPlan]);

	useEffect(() => {
		if (resultOfDeletionPlanFondy.isError) {
			setLoadingErrorContentDeleteSubscribe(
				<Box
					component="div"
					className="error-message-from-api"
					sx={{ color: 'red !important' }}
				>
					Something went wrong
				</Box>
			);
		} else if (resultOfDeletionPlanFondy.isLoading) {
			setLoadingErrorContentDeleteSubscribe(<ProgressSpinner />);
		} else if (resultOfDeletionPlanFondy.isSuccess) {
			setLoadingErrorContentDeleteSubscribe(null);
		}
	}, [resultOfDeletionPlanFondy]);

	// ---- LOGIC FOR STRIPE ---
	const handleGetHistory = async (): Promise<IPayment[] | null> => {
		console.log('STRIPE');
		try {
			const refetchSubscription = await getAllSubscriptionStatusForHistory({
				token,
				status: 'all',
			});
			const allPlansInHistory =
				refetchSubscription.data !== undefined
					? refetchSubscription?.data.data
					: ([] as ISubscriptionStatusCurrentPlanObject[]);

			const finalHistoryArray: IPayment[] = allPlansInHistory.map(
				(currentHistoryPlan) => {
					const finalHistoryObjectForTable = {
						date: '',
						plan: '',
						price: '',
						transaction: '',
						status: '',
						cardType: '',
						invoiceLink: '',
					};

					const paymentMethodObject =
						currentHistoryPlan.latest_invoice.payment_intent !== null
							? currentHistoryPlan.latest_invoice.payment_intent.payment_method
							: null;

					finalHistoryObjectForTable.date = getDateDDMMYY(
						currentHistoryPlan.created
					);
					finalHistoryObjectForTable.plan =
						currentHistoryPlan.plan.product.name;
					finalHistoryObjectForTable.price = Math.floor(
						currentHistoryPlan.plan.amount / 100
					)
						.toString()
						.concat(' USD');

					const preparingStatus =
						currentHistoryPlan.latest_invoice.payment_intent !== null
							? currentHistoryPlan.latest_invoice.payment_intent.status
							: 'trial';
					const preparingStatusUppercase = preparingStatus
						.split('')
						.map((letter: string, index: number) =>
							index === 0 ? letter.toUpperCase() : letter
						);
					finalHistoryObjectForTable.status = preparingStatusUppercase.join('');
					finalHistoryObjectForTable.invoiceLink =
						currentHistoryPlan.latest_invoice.hosted_invoice_url;

					if (paymentMethodObject !== null) {
						finalHistoryObjectForTable.cardType =
							paymentMethodObject.card.brand;
						finalHistoryObjectForTable.transaction = '**** '.concat(
							paymentMethodObject.card.last4
						);
					}

					return finalHistoryObjectForTable;
				}
			);
			setHistoryArray(finalHistoryArray);
			return finalHistoryArray;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	// ---- LOGIC FOR FONDY ---
	const handleGetHistoryFondy = async (): Promise<IPayment[] | null> => {
		try {
			const result = await getHistoryFondy({
				token,
				params: {
					page: currentPage.toString(),
					perPage: '10',
				},
			});

			if (result.data !== undefined) {
				setTotalResults(result.data.count);
			}
			const allPlansInHistory =
				result.data !== undefined
					? result?.data.result
					: ([] as IGetHistoryFondyResponseResult[]);

			const finalHistoryArray: IPayment[] = allPlansInHistory.map(
				(currentHistoryPlan) => {
					const finalHistoryObjectForTable = {
						date: '',
						plan: '',
						price: '',
						transaction: '',
						status: '',
						cardType: '',
						invoiceLink: '',
					};

					finalHistoryObjectForTable.date = getDateDDMMYYFromTimeCode(
						currentHistoryPlan.createdAt.toString()
					);
					finalHistoryObjectForTable.plan =
						currentHistoryPlan.product !== null
							? currentHistoryPlan.product.name
							: 'Unknown';
					finalHistoryObjectForTable.price = Math.floor(
						parseInt(currentHistoryPlan.amount) / 100
					)
						.toString()
						.concat(' USD');

					finalHistoryObjectForTable.status = 'Success';
					finalHistoryObjectForTable.invoiceLink = '';

					finalHistoryObjectForTable.cardType = currentHistoryPlan.card_type;
					finalHistoryObjectForTable.transaction = '**** '.concat(
						currentHistoryPlan.masked_card.substring(
							currentHistoryPlan.masked_card.length - 4
						)
					);

					return finalHistoryObjectForTable;
				}
			);
			setHistoryArray(finalHistoryArray);
			return finalHistoryArray;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	useEffect(() => {
		void handleGetHistoryFondy();
	}, [currentPage]);

	useEffect(() => {
		if (
			dataSubscriptionStatus !== undefined &&
			dataSubscriptionStatus.data.length > 0 &&
			!isCurrentFondy
		) {
			let currentSubscriptionForCard: ISubscriptionStatusCurrentPlanObject | null =
				null;

			if (
				dataSubscriptionStatus?.data.filter(
					(allSubscription) => allSubscription.status === 'active'
				).length > 0
			) {
				currentSubscriptionForCard = dataSubscriptionStatus?.data.filter(
					(allSubscription) => allSubscription.status === 'active'
				)[0];
			} else if (
				dataSubscriptionStatus?.data.filter(
					(allSubscription) => allSubscription.status === 'trialing'
				).length > 0
			) {
				currentSubscriptionForCard = dataSubscriptionStatus?.data.filter(
					(allSubscription) => allSubscription.status === 'trialing'
				)[0];
			}

			if (currentSubscriptionForCard !== null) {
				setIsExistCurrentPlan(true);
				const expireDate = getDateDDMMYY(
					currentSubscriptionForCard.current_period_end
				);

				const planName = currentSubscriptionForCard.plan.product.name;
				const planPrice = Math.floor(
					currentSubscriptionForCard.plan.amount / 100
				).toString();

				setCurrentPlanCardData({ expireDate, planName, planPrice });
				setCurrentPlanName(planName);
				setCurrentPlanId(currentSubscriptionForCard.id);
				setCurrentPlanPeriod(currentSubscriptionForCard.plan.interval);
			}
		} else if (
			dataSubscriptionStatus !== undefined &&
			dataSubscriptionStatus.data.length === 0 &&
			!isCurrentFondy
		) {
			setCurrentPlanCardData({ expireDate: '', planName: '', planPrice: '' });
			setCurrentPlanName('');
			setCurrentPlanId('');
			setIsExistCurrentPlan(false);
		} else if (!isCurrentFondy) {
			setIsExistCurrentPlan(false);
		}
	}, [dataSubscriptionStatus]);

	useEffect(() => {
		handleUpdateCurrentPlan(statisticDataFirstRender);
	}, [getFondyProductsData, statisticDataFirstRender]);

	const currentPlanCardInformation = (
		cardValue: string
	): JSX.Element | string =>
		isSuccessDataSubscriptionStatus ? (
			cardValue
		) : isLoadingDataSubscriptionStatus ? (
			<ProgressDots />
		) : isErrorDataSubscriptionStatus ? (
			<Box>Error</Box>
		) : (
			<></>
		);

	const refetchFnForUpdateData = async (): Promise<void> => {
		if (isCurrentFondy) {
			await refetchGetFondyProductsQuery();
			const updateStatisticData = (await refetchStatisticData()).data;
			handleUpdateCurrentPlan(updateStatisticData);
		} else {
			await refetchSubscriptionStatus();
			await refetchGetStripeProductsQuery();
			await getFunctionForHistory();
		}
	};

	const theme = {
		currentTheme,
		currentPlanCardWidth: openCloseHistory ? '100%' : 'max-content',
	};

	const currentPlanAllDataComponent = (
		<CurrentPlanAllData
			currentPlanCardInformation={currentPlanCardInformation}
			currentPlanCardData={currentPlanCardData}
			currentPlanPeriod={currentPlanPeriod}
			openCloseHistoryButton={
				<OpenCloseHistoryButton
					executeFnGetHistory={
						isCurrentFondy ? handleGetHistoryFondy : handleGetHistory
					}
					refetchFn={getFunctionForHistory}
					openCloseHistoryStatus={openCloseHistory}
					setOpenCloseHistoryFn={setOpenCloseHistory}
					statusPaymentsHistory={{
						isLoading: isLoadingDataSubscriptionStatusAllHistory,
						isError: isErrorDataSubscriptionStatusAllHistory,
						isSuccess: isSuccessDataSubscriptionStatusAllHistory,
					}}
				/>
			}
			setOpenCloseModalConfirmCancelSubscribe={
				setOpenCloseModalConfirmCancelSubscribe
			}
			handleDeletePlan={handleDeletePlan}
			openCloseModalConfirmCancelSubscribe={
				openCloseModalConfirmCancelSubscribe
			}
			loadingErrorContentDeleteSubscribe={loadingErrorContentDeleteSubscribe}
		/>
	);

	const SubscriptionObjectByStatus: React.FC = () => {
		if (isCurrentFondy) {
			return loadingStatusFondySubscription ? (
				<ProgressDots />
			) : errorStatusFondySubscription ? (
				<Box>Error, please, reload the page</Box>
			) : isExistCurrentPlan ? (
				currentPlanAllDataComponent
			) : (
				<Box>You don&apos;t have a current plan</Box>
			);
		} else {
			return isLoadingDataSubscriptionStatus ? (
				<ProgressDots />
			) : isErrorDataSubscriptionStatus ? (
				<Box>Error, please, reload the page</Box>
			) : isExistCurrentPlan ? (
				currentPlanAllDataComponent
			) : (
				<Box>You don&apos;t have a current plan</Box>
			);
		}
	};

	return (
		<StyledPlanMenu theme={theme} className="styledPlanMenu">
			<Box component="div" className="plan-menu-wrapper">
				<Box component="div" className="plan-menu">
					<Box component="div" className="plan-menu-current-plan-area">
						<Box
							component="div"
							className="in-CardWrapper"
							sx={{
								justifyContent: isExistCurrentPlan ? 'space-between' : 'center',
							}}
						>
							<SubscriptionObjectByStatus />
						</Box>
						<OpenCloseHistoryButton
							executeFnGetHistory={
								isCurrentFondy ? handleGetHistoryFondy : handleGetHistory
							}
							refetchFn={getFunctionForHistory}
							openCloseHistoryStatus={openCloseHistory}
							setOpenCloseHistoryFn={setOpenCloseHistory}
							statusPaymentsHistory={{
								isLoading: isLoadingDataSubscriptionStatusAllHistory,
								isError: isErrorDataSubscriptionStatusAllHistory,
								isSuccess: isSuccessDataSubscriptionStatusAllHistory,
							}}
						/>

						{openCloseHistory && !isCurrentFondy ? (
							<HistoryPayment
								payments={historyArray}
								statusPaymentsHistory={{
									isLoading: isLoadingDataSubscriptionStatusAllHistory,
									isError: isErrorDataSubscriptionStatusAllHistory,
									isSuccess: isSuccessDataSubscriptionStatusAllHistory,
								}}
							/>
						) : openCloseHistory && isCurrentFondy ? (
							<HistoryPayment
								payments={historyArray}
								statusPaymentsHistory={{
									isLoading: resultOfGetHistoryFondy.isLoading,
									isError: resultOfGetHistoryFondy.isError,
									isSuccess: resultOfGetHistoryFondy.isSuccess,
								}}
								isFondy
								totalResults={totalResults}
							/>
						) : (
							<></>
						)}
					</Box>

					<Box component="div" className="plan-menu-upgrade-plan-area">
						<Box
							component="div"
							className="plan-menu-upgrade-title-subtitle-toggle-field"
						>
							<Box component="div" className="upgrade-title-subtitle">
								<Box component="div" className="plan-menu-upgrade-title">
									Upgrade Plan
								</Box>
								<Box component="div" className="plan-menu-upgrade-subtitle">
									You can change your current plan to a better one
								</Box>
							</Box>
							<Box component="div" className="upgrade-toggle">
								<TogglePeriod />
							</Box>
						</Box>

						<CardsPricePlan
							fondyGetProductsFunctionObject={{
								getFondyProductsData,
								isErrorGetProductsFondy,
								isLoadingGetProductsFondy,
								isSuccessGetProductsFondy,
							}}
							stripeGetProductsFunctionObject={{
								getStripeProductsData,
								isErrorGetProducts,
								isLoadingGetProducts,
								isSuccessGetProducts,
							}}
							currentPlanInformationObject={{
								currentPlanName,
								currentPlanId,
								isExistCurrentPlan,
							}}
							refetchFunctionAfterChoosePlan={refetchFunctionAfterChoosePlan}
							getFunctionForHistory={getFunctionForHistory}
							openModalToCreateSubscription={openModalToCreateSubscription}
						/>
					</Box>
				</Box>
			</Box>
			{openCloseModalPaymentMethods && (
				<PaymentMethodsMenuInModal
					currentPlanName={changeablePlanOptions.currentPlanName}
					currentPrice={changeablePlanOptions.currentPrice}
					priceId={changeablePlanOptions.priceId}
					closeFn={() => {
						setOpenCloseModalPaymentMethods(false);
					}}
					refetchFn={refetchFnForUpdateData}
					isFondy={true}
				/>
			)}
		</StyledPlanMenu>
	);
};
