import React from 'react';
import { Box } from '@mui/material';
import { type IChangeableInputProps } from '../../../DetailedBotCard/CardInfoItem';
import { SharedWrappedInputWithIcon } from '../../../shared/Input/SharedWrappedInputWithIcon/SharedWrappedInputWithIcon';

interface IConfigWrappedInputProps {
	title: string;
	changeableInput: IChangeableInputProps;
	isEmpty: boolean;
}

export const ConfigWrappedInput: React.FC<IConfigWrappedInputProps> = ({
	title,
	changeableInput,
	isEmpty,
}) => {
	return (
		<Box component="div" className="configWrappedInput">
			<Box component="div" className="configWrappedInput-title">
				{title}
			</Box>
			<Box component="div" className="configWrappedInput-changeableInput">
				<SharedWrappedInputWithIcon
					type={changeableInput.type}
					placeholder={changeableInput.placeholder}
					isEmpty={isEmpty}
					params={changeableInput.params}
					uniqueNameOfInput={changeableInput.uniqueNameOfInput}
					isValid={changeableInput.isValid}
					value={changeableInput.value}
					icon={changeableInput.icon}
					isDisabled={changeableInput.disabled}
					minNumber={changeableInput.minNumber}
					maxNumber={changeableInput.maxNumber}
					stepNumber={changeableInput.stepNumber}
					tooltip={
						changeableInput.tooltip !== undefined
							? changeableInput.tooltip
							: undefined
					}
					iconSVG={changeableInput.iconSVG}
					maxLength={changeableInput.maxLength}
					isFloat={changeableInput.isFloat}
				/>
			</Box>
		</Box>
	);
};
