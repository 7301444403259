import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SharedModal } from '../../../shared/SharedModal/SharedModal';
import { useDeleteAllSnippets } from '../../hooks/useDeleteAllSnippets';
import { ProgressSpinner } from '../../../shared/ProgressSpinner/ProgressSpinner';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { setDeleteAllSnippetsMessage } from '../../../../app/slices/trainingBotMenu/deleteAllSnippetsMessageTrainingBotMenuSlice';
import { ModalExportSnippets } from '../../ModalExportSnippets/ModalExportSnippets';
import { setOpenModalExportSnippetsStatus } from '../../../../app/slices/trainingBotMenu/openModal/openExportSnippetsTrainingBotMenuSlice';
import { CustomTooltip } from '../../../shared/CustomTooltip/CustomTooltip';
import { setOpenModalImportSnippetsStatus } from '../../../../app/slices/trainingBotMenu/openModal/importSnippets/openImportSnippetsTrainingBotMenuSlice';
import { ModalImportSnippets } from '../../ModalImportSnippets/ModalImportSnippets';
import { useGetStatisticAboutAccount } from '../../../../app/hooks/useGetStatisticAboutAccount';
import { useDeleteSelectedSnippets } from '../../hooks/useDeleteSelectedSnippets';
import { useAddRemoveCurrentSnippetAsChecked } from '../../hooks/useAddRemoveCurrentSnippetAsChecked';

interface IActionsLeftAreaTableSnippets {
	chatbotId: string;
	isEmptyArray: boolean;
}

export const ActionsLeftAreaTableSnippets: React.FC<
	IActionsLeftAreaTableSnippets
> = ({ chatbotId, isEmptyArray }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const statistic = useGetStatisticAboutAccount();
	const { successStatus, getAllStatisticData } = statistic;
	const allStatisticData = getAllStatisticData();

	const [isHasSubscription, setIsHasSubscription] = useState(false);

	useEffect(() => {
		if (
			successStatus() &&
			allStatisticData !== undefined &&
			allStatisticData.subscriptionId !== '' &&
			allStatisticData.subscriptionId !== 'null' &&
			allStatisticData.subscriptionId != null
		) {
			setIsHasSubscription(true);
		} else {
			setIsHasSubscription(false);
		}
	}, [allStatisticData]);

	const messageDeleteAllSnippets = useAppSelector(
		(state: RootState) =>
			state.deleteAllSnippetsMessageTrainingBotMenu.deleteAllSnippetsMessage
	);

	const isOpenModalExportSnippets = useAppSelector(
		(state: RootState) => state.openExportSnippetsTrainingBotMenu
	);

	const isOpenModalImportSnippets = useAppSelector(
		(state: RootState) => state.openImportSnippetsTrainingBotMenu
	);

	const allCheckedArray = useAppSelector(
		(state: RootState) => state.checkedSnippetsMenu.checkedSnippetsArray
	);
	const [countSelected, setCountSelected] = useState<number>(0);

	useEffect(() => {
		setCountSelected(allCheckedArray.length);
	}, [allCheckedArray]);

	const [isOpenModalDeleteAllSnippets, setIsOpenModalDeleteAllSnippets] =
		useState(false);

	const dispatch = useAppDispatch();

	const refetchFn = useAppSelector(
		(state: RootState) => state.refetchFnTrainingBotMenu.refetchFn
	);

	const deleteAllSnippets = useDeleteAllSnippets();
	const deleteSelectedSnippets = useDeleteSelectedSnippets();
	const allFunctionsWithCheckedArray = useAddRemoveCurrentSnippetAsChecked();

	const handleDeleteAllSnippetsExecute = async (): Promise<void> => {
		await deleteAllSnippets(chatbotId, setIsOpenModalDeleteAllSnippets);
		await refetchFn();
	};

	const handleDeleteSelectedSnippetsExecute = async (): Promise<void> => {
		await deleteSelectedSnippets(
			allCheckedArray,
			setIsOpenModalDeleteAllSnippets
		);
		await refetchFn();
		allFunctionsWithCheckedArray.removeAllArraySnippetAsChecked();
	};

	const mainActionsInTable = [
		// Hide Actions in Left Area
		// { title: 'Mark as unpushed', isDelete: false, executeFunction: () => {} },
		// { title: 'Mark as pushed', isDelete: false, executeFunction: () => {} },
		{
			title: countSelected > 0 ? `Export (${countSelected})` : 'Export',
			isDelete: false,
			tooltip: `Export ${
				countSelected > 0 ? countSelected : 'all'
			} snippets in the format you need`,
			executeFunction: () => {
				dispatch(setOpenModalExportSnippetsStatus(true));
			},
			displayStatus: !isEmptyArray,
		},
		{
			title: 'Import',
			isDelete: false,
			tooltip: 'Import snippets in the csv or xlsx format',
			executeFunction: () => {
				dispatch(setOpenModalImportSnippetsStatus(true));
			},
			displayStatus: isHasSubscription,
		},
		{
			title: countSelected > 0 ? `Delete (${countSelected})` : 'Delete all',
			isDelete: true,
			tooltip: `Delete ${countSelected > 0 ? countSelected : 'all'} snippets`,
			executeFunction: () => {
				setIsOpenModalDeleteAllSnippets(true);
			},
			displayStatus: !isEmptyArray,
		},
	];

	const loadingErrorContent = (): JSX.Element | null => {
		switch (messageDeleteAllSnippets) {
			case 'error':
				return (
					<Box component="div" className="error-message-from-api">
						{' '}
						Something went wrong{' '}
					</Box>
				);

			case 'loading':
				return <ProgressSpinner />;

			case 'success':
				return (
					<Box component="div" className="message-from-api">
						{' '}
						You have successfully removed all snippets{' '}
					</Box>
				);

			default:
				return null;
		}
	};

	return (
		<Box component="div" className="actionsLeftAreaTableSnippets-wrapper">
			{mainActionsInTable.map((currentActionButton) => {
				if (!currentActionButton.displayStatus) {
					return null;
				}

				return (
					<CustomTooltip
						theme={theme}
						title={currentActionButton.tooltip}
						key={currentActionButton.title}
					>
						<Box
							component="button"
							className={`currentActionButton ${
								currentActionButton.isDelete ? 'isDeleteButton' : ''
							}`}
							onClick={currentActionButton.executeFunction}
							// disabled={isEmptyArray}
							// sx={{ display: isEmptyArray ? 'none' : 'flex' }}
						>
							{currentActionButton.title}
						</Box>
					</CustomTooltip>
				);
			})}
			{isOpenModalDeleteAllSnippets ? (
				<SharedModal
					message={loadingErrorContent()}
					title={`Are you sure you want to delete ${
						countSelected > 0 ? countSelected : 'all'
					} snippets?`}
					isOnlyAlert={false}
					isDeleteModal
					executeFn={() => {
						void (countSelected > 0
							? handleDeleteSelectedSnippetsExecute()
							: handleDeleteAllSnippetsExecute());
					}}
					closeFn={() => {
						setIsOpenModalDeleteAllSnippets(false);
						dispatch(setDeleteAllSnippetsMessage(''));
					}}
				/>
			) : (
				<></>
			)}

			{isOpenModalExportSnippets ? (
				<ModalExportSnippets
					closeFn={() => {
						dispatch(setOpenModalExportSnippetsStatus(false));
					}}
					chatbotId={chatbotId}
				/>
			) : (
				<></>
			)}

			{isOpenModalImportSnippets ? (
				<ModalImportSnippets
					closeFn={() => {
						dispatch(setOpenModalImportSnippetsStatus(false));
					}}
					chatbotId={chatbotId}
				/>
			) : (
				<></>
			)}
		</Box>
	);
};
