import styled from 'styled-components';

export const StyledHistoryRequest = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	align-items: center;

	& .historyRequestMenu-search-bar-field {
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;

		& .search-bar-title-subtitle-area {
			text-align: center;
			width: clamp(300px, 40%, 500px);
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 16px;
			color: ${(props) => props.theme.currentTheme.mainFontColor};

			& .search-bar-title {
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: 145%;
			}

			& .search-bar-subtitle {
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 145%;
			}
		}
	}

	& .historyRequestMenu-table-field {
		display: flex;
		width: 100%;
	}

	& .viewOnPage-HistoryDateRangePicker-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		& .viewOnPage-wrapper {
			display: flex;
			align-items: center;
			gap: 1rem;
		}
	}

	@media screen and (max-width: 1500px) {
		& .historyRequestMenu-search-bar-field {
			& .search-bar-title-subtitle-area {
				& .search-bar-title {
					font-size: 18px;
					font-weight: 600;
					line-height: 145%;
				}

				& .search-bar-subtitle {
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
				}
			}
		}
	}
`;
