// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSharedInputAlert = styled.div`
	display: flex;
	color: ${(props) => props.theme.currentTheme.alertFontColor};
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 145%;
`;
