// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialValidTestBotInputState {
	isValidTestBotCompletionInput: boolean;
	isValidTestBotInput: boolean;
}

const initialState: IInitialValidTestBotInputState = {
	isValidTestBotInput: false,
	isValidTestBotCompletionInput: true,
};

const isValidTestBotInputSlice = createSlice({
	name: 'isValidTestBotInput',
	initialState,
	reducers: {
		setValidStatusTestBotInput(state, action: PayloadAction<boolean>) {
			state.isValidTestBotInput = action.payload;
			return state;
		},
		setValidStatusTestBotCompletionInput(
			state,
			action: PayloadAction<boolean>
		) {
			state.isValidTestBotCompletionInput = action.payload;
			return state;
		},
	},
});

export const {
	setValidStatusTestBotInput,
	setValidStatusTestBotCompletionInput,
} = isValidTestBotInputSlice.actions;
export const isValidTestBotInputReducer = isValidTestBotInputSlice.reducer;
