import styled from 'styled-components';

export const StyledStepElement = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	color: ${(props) => props.theme.currentTheme.mainFontColor};
	padding-bottom: 24px;

	& .stepElement-header-field {
		display: flex;
		/* height: 40px; */

		& .stepElement-header-number {
			min-width: 40px;
			padding: 8px;
			color: #fff;
			background: ${(props) => props.theme.currentTheme.integrateAccent};
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px 0px 0px 4px;
		}

		& .stepElement-header {
			max-width: 420px;
			height: max-content;
			min-height: 40px;
			padding: 8px 16px;
			background: ${(props) => props.theme.currentTheme.integratePrimary};
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 0px 4px 4px 0px;
		}
	}

	& .stepElement-list-field {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding-left: 56px;

		& .stepListElement-wrapper {
			display: flex;
			/* align-items: center; */
			gap: 8px;
			hyphens: auto;
			word-break: break-word;

			& .stepElement-point-element {
				width: 8px !important;
				height: 8px !important;
				margin-top: 6px;
				border-radius: 50%;
				background: ${(props) => props.theme.currentTheme.integrateAccent};
			}

			& .step-Element-link {
				color: ${(props) => props.theme.currentTheme.coderfyAccentPrimaryColor};
				text-decoration: none;
			}
		}
	}

	@media screen and (max-width: 800px) {
		& .stepElement-list-field {
			padding-left: 24px;

			& .stepListElement-wrapper {
				& .stepElement-string-element {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 400px) {
		& .stepElement-header-field {
			& .stepElement-header {
				word-break: break-all;
			}
		}
	}
`;
