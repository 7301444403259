import React from 'react';
import { Box } from '@mui/material';
import { StyledInputsFieldWrapper } from './InputsFieldWrapper.style';

interface ISharedPasswordInput {
	children: React.ReactNode;
}

export const InputsFieldWrapper: React.FC<ISharedPasswordInput> = ({
	children,
}) => {
	return (
		<StyledInputsFieldWrapper>
			<Box component="div" className="inputs-field-wrapper">
				{children}
			</Box>
		</StyledInputsFieldWrapper>
	);
};
