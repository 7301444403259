/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledWebhookToCopy } from './WebhookToCopy.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';

interface IWebhookToCopyProps {
	link: string;
}

export const WebhookToCopy: React.FC<IWebhookToCopyProps> = ({ link }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const [textCopyJSCode, setTextCopyJSCode] = useState('');

	useEffect(() => {
		setTextCopyJSCode(link);
	}, [link]);

	const handleClickEffect = (): void => {
		setTextCopyJSCode('LINK WAS COPYED');
		setTimeout(() => {
			setTextCopyJSCode(link);
		}, 1000);
	};

	const handleCopyLink = (): void => {
		void navigator.clipboard.writeText(link);
		handleClickEffect();
	};

	const theme = { currentTheme };

	return (
		<StyledWebhookToCopy
			className="webhookToCopy-wrapper"
			theme={theme}
			onClick={handleCopyLink}
		>
			<Box component="div" className="webhookToCopy-link">
				{textCopyJSCode}
			</Box>
		</StyledWebhookToCopy>
	);
};
