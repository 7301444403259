/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React, { useState, useEffect } from 'react';

export const MutationObserverReCaptchaComponent: React.FC = () => {
	const body = document.body;

	const config = {
		childList: true,
		subtree: true,
	};

	const [noticeAppearReCaptcha, setNoticeAppearReCaptcha] = useState(false);

	const hideReCaptcha = (): void => {
		const reCaptchaComponent: HTMLElement | null =
			document.querySelector('.grecaptcha-badge');
		if (reCaptchaComponent !== null) {
			reCaptchaComponent.style.visibility = 'hidden';
		}
	};

	const noticeAboutLoadReCaptchaDiv = (): void => {
		const reCaptchaComponent: HTMLElement | null =
			document.querySelector('.grecaptcha-badge');
		if (reCaptchaComponent !== null) {
			setNoticeAppearReCaptcha(true);
		}
	};

	const observer = new MutationObserver(noticeAboutLoadReCaptchaDiv);

	useEffect(() => {
		observer.observe(body, config);
		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		hideReCaptcha();
	}, [noticeAppearReCaptcha]);

	return null;
};
