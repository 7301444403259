import React, { useEffect, useState } from 'react';
import { useAppSelector, useCheckAuth } from '../app/hooks';
import { Navigate } from 'react-router-dom';
import { LayoutAccount } from '../components/shared/LayoutAccount/LayoutAccount';
import { TrainingBotMenu } from '../components/TrainingBotMenu/TrainingBotMenu';
import { type RootState } from '../app/store';
import { useLazyGetCurrentBotQuery } from '../app/api/chatbot.api';

export const TrainingBotPage: React.FC = () => {
	const isAuthorization = useCheckAuth();
	const chatbotId = useAppSelector(
		(state: RootState) => state.currentChatbotIdTrainingBotMenu.currentChatbotId
	);
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const [getCurrentBot] = useLazyGetCurrentBotQuery();
	const [nameOfChatbot, setNameOfChatbot] = useState('');

	const getCurrentBotExecuteFn = async (): Promise<void> => {
		const resultOfGetCurrentBot = await getCurrentBot({
			id: parseInt(chatbotId),
			token,
		});
		if (resultOfGetCurrentBot.data !== undefined) {
			setNameOfChatbot(resultOfGetCurrentBot.data?.chatBotName);
		}
	};

	useEffect(() => {
		if (chatbotId.length > 0) {
			void getCurrentBotExecuteFn();
		}
	}, [chatbotId]);

	return (
		<LayoutAccount
			title={`${
				nameOfChatbot.length > 0
					? 'Chatbot "'.concat(nameOfChatbot).concat('"')
					: 'Chatbot'
			} training settings`}
		>
			{isAuthorization ? (
				<TrainingBotMenu />
			) : (
				<Navigate to="/" replace={true} />
			)}
		</LayoutAccount>
	);
};
