import React from 'react';
import { Box } from '@mui/material';
import {
	type IGenericGridElement,
	type IGenericRow,
} from '../SharedDesktopTable';
import { colorCircle } from '../SharedGridCard';
// import { SharedCheckboxItem } from '../../../../shared/SharedCheckboxItem/SharedCheckboxItem';

export interface IGridCardProps {
	row: IGenericRow;
	titles: IGenericGridElement[];
	hasActionButton: boolean;
}

export const MobileGridCard: React.FC<IGridCardProps> = ({
	row,
	titles,
	hasActionButton,
}) => {
	return (
		<Box component="div" className="grid-card-wrapper">
			<Box component="div" className="grid-card-accent-line">
				<Box component="div" className="grid-card-item-left">
					<Box component="span">{titles[0].valueGridElement}</Box>
					{titles[0].valueJSX}
				</Box>
				<Box
					component="div"
					sx={{ display: 'flex', alignItems: 'center' }}
					className="grid-card-item-right"
				>
					{row.gridElementsRow[0].isOnlyBool === true ? (
						colorCircle(row.gridElementsRow[0].boolValue === true)
					) : (
						<>
							<Box component="div">
								{row.gridElementsRow[0].valueGridElement}
							</Box>
							{row.gridElementsRow[0].valueJSX}
						</>
					)}
				</Box>
			</Box>

			{titles.map((currentTitle, index) => {
				if (index === 0) return null;
				return (
					<Box
						key={Math.random()
							.toString()
							.concat(row.gridElementsRow[index].valueGridElement)}
						component="div"
						className="grid-card-line"
					>
						<Box component="div" className="grid-card-item-left">
							<Box component="span">{currentTitle.valueGridElement}</Box>
							{currentTitle.valueJSX}
						</Box>
						<Box
							component="div"
							sx={{ display: 'flex', alignItems: 'center' }}
							className="grid-card-item-right"
						>
							{row.gridElementsRow[index].isOnlyBool === true ? (
								colorCircle(row.gridElementsRow[index].boolValue === true)
							) : (
								<>
									<Box component="div" className="text-content">
										{row.gridElementsRow[index].valueGridElement}
									</Box>
									{row.gridElementsRow[index].valueJSX}
								</>
							)}
						</Box>
					</Box>
				);
			})}

			{hasActionButton ? (
				<Box component="div" className="grid-card-line">
					<Box component="div" className="grid-card-item-left">
						Actions
					</Box>
					<Box component="div" className="grid-card-item-right actions-buttons">
						{row.actionButtons.map((currentActionButton) => (
							<Box
								key={Math.random().toString().concat('grid-card-item-right')}
							>
								{currentActionButton}
							</Box>
						))}
					</Box>
				</Box>
			) : (
				<></>
			)}
		</Box>
	);
};
