import React from 'react';
import { Box } from '@mui/material';
import { StyledSocialIntegrationMenu } from './SocialIntegrationMenu.style';
import { useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';

interface ISocialIntegrationMenuProps {
	children: JSX.Element;
}

export const SocialIntegrationMenu: React.FC<ISocialIntegrationMenuProps> = ({
	children,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	// const token = useAppSelector(
	// 	(state: RootState) => state.answerApiLogin.token
	// );

	const theme = {
		currentTheme,
	};

	return (
		<StyledSocialIntegrationMenu theme={theme}>
			<Box component="div" className="socialIntegrationMenu-wrapper">
				<Box
					component="div"
					className="socialIntegrationMenu-main-card-wrapper"
				>
					{children}
				</Box>
			</Box>
		</StyledSocialIntegrationMenu>
	);
};
