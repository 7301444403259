import React from 'react';
import { StyledMobileSnippetsTable } from './MobileSnippetsTable.style';
import { MobileGridCard } from './MobileGridCard';
import { useAppSelector } from '../../../../../app/hooks';
import { type RootState } from '../../../../../app/store';
import { type IIDataSnippetsRows } from '../SnippetsTable';
import { Box } from '@mui/material';

interface ISharedMobileTableProps {
	rows: IIDataSnippetsRows[];
	// middlewareFn: (currentSnippetNumber: string, prompt: string, completion: string) => void;
}

export const MobileSnippetsTable: React.FC<ISharedMobileTableProps> = ({
	rows,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = { currentTheme };

	return (
		<StyledMobileSnippetsTable theme={theme}>
			{rows.length === 0 && (
				<Box
					component="div"
					className="message-empty"
					sx={{ alignSelf: 'center', p: 4 }}
				>
					You don`t have snippets yet
				</Box>
			)}
			{rows.map((row, index) => (
				<MobileGridCard key={index} row={row} />
			))}
		</StyledMobileSnippetsTable>
	);
};
