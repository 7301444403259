// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialConfirmEmailInputValueValidStatus {
	isValidCardNumber: boolean;
	isValidMonth: boolean;
	isValidYear: boolean;
	isValidCVC: boolean;
}

const initialState: IInitialConfirmEmailInputValueValidStatus = {
	isValidCardNumber: false,
	isValidMonth: false,
	isValidYear: false,
	isValidCVC: false,
};

const isValidPaymentMethodCardValuesSlice = createSlice({
	name: 'isValidPaymentMethodCardValues',
	initialState,
	reducers: {
		setValidCardNumberStatus(state, action: PayloadAction<boolean>) {
			state.isValidCardNumber = action.payload;
			return state;
		},
		setValidMonthStatus(state, action: PayloadAction<boolean>) {
			state.isValidMonth = action.payload;
			return state;
		},
		setValidYearStatus(state, action: PayloadAction<boolean>) {
			state.isValidYear = action.payload;
			return state;
		},
		setValidCVCStatus(state, action: PayloadAction<boolean>) {
			state.isValidCVC = action.payload;
			return state;
		},
	},
});

export const {
	setValidCardNumberStatus,
	setValidMonthStatus,
	setValidYearStatus,
	setValidCVCStatus,
} = isValidPaymentMethodCardValuesSlice.actions;
export const isValidPaymentMethodCardValuesReducer =
	isValidPaymentMethodCardValuesSlice.reducer;
