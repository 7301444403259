// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialConfirmEmailInputValueState {
	confirmEmailValue: string;
}

const initialState: IInitialConfirmEmailInputValueState = {
	confirmEmailValue: '',
};

const confirmEmailInputValueSlice = createSlice({
	name: 'confirmEmailInputValue',
	initialState,
	reducers: {
		setConfirmEmail(state, action: PayloadAction<string>) {
			state.confirmEmailValue = action.payload;
			return state;
		},
	},
});

export const { setConfirmEmail } = confirmEmailInputValueSlice.actions;
export const confirmEmailInputValueReducer =
	confirmEmailInputValueSlice.reducer;
