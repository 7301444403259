import styled from 'styled-components';

export const StyledModalImportSnippets = styled.div`
	width: 100%;

	& .importSnippetMenuComponent-wrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding: 20px 16px;
		width: 100%;

		& .importSnippetMenu-title-subtitle {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			font-style: normal;
			width: 100%;

			& .importSnippetMenu-title {
				font-size: 24px;
				font-weight: 600;
				line-height: 145%;
			}

			& .importSnippetMenu-subtitle {
				font-size: 18px;
				font-weight: 400;
				line-height: 27px;
			}
		}

		& .importSnippetMenu-type-name-uploading-content {
			display: flex;
			align-items: center;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			font-size: 18px;
			font-weight: 400;
			line-height: 27px;
			width: 100%;

			& .name-type-upload-file-wrapper {
				display: flex;
				justify-content: space-between;
				gap: 1rem;
				width: 100%;

				& .name-type-upload-file {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;
				}

				& .remove-upload-file {
					display: flex;
					align-items: center;
					justify-content: center;

					& .close-icon {
						border-radius: 50%;
						cursor: pointer;
						padding: 0.2rem;
						transition: all 0.1s ease-in;

						&:hover {
							background: ${(props) => props.theme.currentTheme.disabledBorder};
						}

						& path {
							stroke: ${(props) => props.theme.currentTheme.mainFontColor};
						}
					}
				}
			}
		}

		& .importSnippetMenu-input-url {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 2rem;

			& > div > div {
				width: 100% !important;
			}

			& input {
				width: 100% !important;
			}

			& .importSnippetMenu-exe-buttons {
				display: flex;
				gap: 1rem;
				align-items: center;

				& #userFileSnippetsForm {
					width: 100%;
					height: 100%;
				}

				& .user-file-snippets-input-form-control {
					position: relative;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					border: 1px solid #5e17eb;
					width: 100%;
					height: 70px;
					border-radius: 10px;
					background: #5e17eb;
					cursor: pointer;
					padding: 1rem;
					transition: all 0.5s;
					position: relative;
					overflow: hidden;

					color: #fff;
					font-style: normal;
					font-size: 18px;
					line-height: 100%;

					&:hover {
						background: ${(props) => props.theme.currentTheme.cardsBackground};
						color: ${(props) => props.theme.currentTheme.mainFontColor};
					}

					&:active {
						background: ${(props) => props.theme.currentTheme.cardsBackground};
						color: ${(props) => props.theme.currentTheme.mainFontColor};
						transform: scale(0.95);
					}

					& #user-file-snippets-input {
						width: 0.1px;
						height: 0.1px;
						opacity: 0;
						overflow: hidden;
						position: absolute;
						z-index: -1;
					}
				}
			}

			& .importSnippetMenu-answer-window {
				border: 1px solid #1592ec;
				border-radius: 8px;
				padding: 1rem;
				overflow: auto;
				height: 300px;
				width: 100% !important;
				color: ${(props) => props.theme.currentTheme.fontColorInput};
				font-size: 16px;
				font-weight: 400;
				line-height: 27px;
				cursor: no-drop;
				background: ${(props) => props.theme.currentTheme.disabledBackground};
			}
		}

		& .importSnippetMenu-answer-loading-content {
			display: flex;
			justify-content: center;

			& .error-message-from-api {
				color: ${(props) => props.theme.currentTheme.alertFontColor};
			}

			& .message-from-api {
				color: #1592ec;
			}
		}

		& .importSnippetMenu-button-field {
			display: flex;
			gap: 1rem;

			& > div {
				width: 100% !important;
				& button {
					width: 100% !important;
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .importSnippetMenuComponent-wrapper {
			& .importSnippetMenu-input-url {
				& .importSnippetMenu-exe-buttons {
					& .user-file-snippets-input-form-control {
						height: 50px;
						font-size: 16px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 650px) {
		& .importSnippetMenuComponent-wrapper {
			padding: 16px;
			& .importSnippetMenu-title-subtitle {
				text-align: center;
			}
		}
	}
`;
