/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSharedWrappedInputWithExtraContent = styled.div`
	display: flex;
	gap: 1rem !important;
	position: relative;
	padding: 0px;

	& .left-input-image {
		position: relative;
		width: ${(props) => (props.theme.isQuestion === true ? 'auto' : '100px')};
		display: flex;
		justify-content: flex-end;
		align-self: normal;
		max-height: 68px;
		/* max-height: 50px; */

		& .question-icon {
			display: flex;
			flex-direction: column;
			position: relative;
			justify-content: center;

			& svg {
				color: #1592ec;
				cursor: pointer;
			}

			& .question-icon-text-field {
				display: ${(props) => (props.theme.display === true ? 'flex' : 'none')};
				border-radius: 16px;
				background: linear-gradient(
							${(props) => props.theme.currentTheme.cardsBackground} 0 0
						)
						padding-box,
					linear-gradient(
							${(props) =>
								props.theme.currentTheme.mainRootBackground === '#1A1D21'
									? '90deg'
									: '180deg'},
							${(props) => props.theme.currentTheme.cardsGradient.one},
							${(props) => props.theme.currentTheme.cardsGradient.two}
						)
						border-box;
				border: 1px solid transparent;
				padding: 16px 24px;

				flex-direction: column;
				position: absolute;
				bottom: 50px;
				left: -10px;
				opacity: 0.9;
				width: 200px;
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 140%;
				z-index: 950;
				padding: 0.7rem;
				text-align: center;
				color: ${(props) => props.theme.currentTheme.mainFontColor};
				transition: all 0.4s;

				& a {
					color: ${(props) => props.theme.currentTheme.mainFontColor};
					font-size: 16px;
				}

				& .hidden-polygon {
					position: absolute;
					left: 15px;
					bottom: -8px;
					z-index: 800;

					& .hidden-area,
					.hidden-border {
						position: absolute;
						z-index: 800;

						width: 20px;
						height: 20px;
						position: absolute;
					}

					& .hidden-area {
						bottom: -2px;
					}

					& .hidden-border {
						z-index: -1;
						bottom: -3px;
					}
				}
			}
		}
	}

	& .right-part-input {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 1rem;
	}

	@media screen and (max-width: 1500px) {
		& .left-input-image {
			max-height: 50px;
		}
	}

	@media screen and (max-width: 1000px) {
		flex-direction: row !important;

		& .left-input-image {
			& .question-icon {
				& .question-icon-text-field {
					display: ${(props) =>
						props.theme.display === true ? 'flex' : 'none'};
					border-radius: 16px;
					background: linear-gradient(
								${(props) => props.theme.currentTheme.cardsBackground} 0 0
							)
							padding-box,
						linear-gradient(
								${(props) =>
									props.theme.currentTheme.mainRootBackground === '#1A1D21'
										? '90deg'
										: '180deg'},
								${(props) => props.theme.currentTheme.cardsGradient.one},
								${(props) => props.theme.currentTheme.cardsGradient.two}
							)
							border-box;
					border: 1px solid transparent;
					padding: 16px 24px;

					flex-direction: column;
					position: absolute;
					bottom: 40px;
					left: -215px;
					opacity: 0.9;
					width: 260px;
					max-width: 85vw;
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 140%;
					z-index: 950;
					padding: 0.7rem;
					text-align: center;
					color: ${(props) => props.theme.currentTheme.mainFontColor};
					transition: all 0.4s;

					& a {
						color: ${(props) => props.theme.currentTheme.mainFontColor};
						font-size: 16px;
					}

					& .hidden-polygon {
						display: none;
					}
				}
			}
		}
		& .shared-input {
			width: 70vmin;
		}
	}

	@media screen and (max-width: 630px) {
		& .left-input-image {
			& .question-icon {
				& > div > svg {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
`;
