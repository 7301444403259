// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

interface IInitialRegistrationInputsValidState {
	isValidNameRegistration: boolean;
	isValidSurnameRegistration: boolean;
	isValidDomainRegistration: boolean;
	isValidEmailRegistration: boolean;
	isValidPasswordRegistration: boolean;
}

const initialState: IInitialRegistrationInputsValidState = {
	isValidNameRegistration: false,
	isValidSurnameRegistration: false,
	isValidDomainRegistration: false,
	isValidEmailRegistration: false,
	isValidPasswordRegistration: false,
};

const isValidRegistrationInputsSlice = createSlice({
	name: 'isValidRegistrationInputs',
	initialState,
	reducers: {
		setValidStatusNameRegistration(state, action) {
			state.isValidNameRegistration = action.payload;
			return state;
		},
		setValidStatusSurnameRegistration(state, action) {
			state.isValidSurnameRegistration = action.payload;
			return state;
		},
		setValidStatusDomainRegistration(state, action) {
			state.isValidDomainRegistration = action.payload;
			return state;
		},
		setValidStatusEmailRegistration(state, action) {
			state.isValidEmailRegistration = action.payload;
			return state;
		},
		setValidStatusPasswordRegistration(state, action) {
			state.isValidPasswordRegistration = action.payload;
			return state;
		},
	},
});

export const {
	setValidStatusNameRegistration,
	setValidStatusSurnameRegistration,
	setValidStatusDomainRegistration,
	setValidStatusEmailRegistration,
	setValidStatusPasswordRegistration,
} = isValidRegistrationInputsSlice.actions;
export const isValidRegistrationInputsReducer =
	isValidRegistrationInputsSlice.reducer;
