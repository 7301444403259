import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const openImportSnippetsTrainingBotMenuSlice = createSlice({
	name: 'openImportSnippetsTrainingBotMenu',
	initialState: false,
	reducers: {
		setOpenModalImportSnippetsStatus(state, action: PayloadAction<boolean>) {
			state = action.payload;
			return state;
		},
	},
});

export const { setOpenModalImportSnippetsStatus } =
	openImportSnippetsTrainingBotMenuSlice.actions;
export const openImportSnippetsTrainingBotMenuReducer =
	openImportSnippetsTrainingBotMenuSlice.reducer;
