import { useEffect, useMemo, useState } from 'react';
import {
	useGetStatisticAboutAccountQuery,
	useLazyGetStatisticAboutAccountQuery,
} from '../api/chatbot.api';
import { useAppSelector } from '../hooks';
import { type RootState } from '../store';
import { type IStatisticAboutAccountResponse } from '../api/interfaces/interfacesRequest';

interface IReturnObjectUseGetStatisticAboutAccount {
	getDifferenceBotsCount: () => boolean;
	getDifferenceBotsCountFirstRender: () => boolean;
	refetchStatisticDataExecute: () => Promise<void>;
	loadingStatus: () => boolean;
	errorStatus: () => boolean;
	successStatus: () => boolean;
	memoizingDifferenceBotsCount: boolean;
	getAllStatisticData: () => IStatisticAboutAccountResponse | undefined;
}

export const useGetStatisticAboutAccount =
	(): IReturnObjectUseGetStatisticAboutAccount => {
		const token = useAppSelector(
			(state: RootState) => state.answerApiLogin.token
		);

		const [getStatistic, statusOfGettingStatistic] =
			useLazyGetStatisticAboutAccountQuery();

		const [statisticData, setStatisticData] =
			useState<IStatisticAboutAccountResponse>();

		const { data: statisticDataFirstRender, refetch: refetchStatisticData } =
			useGetStatisticAboutAccountQuery({ token });

		useEffect(() => {
			getStatistic({ token })
				.then((response) => {
					setStatisticData(response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}, []);

		const refetchStatisticDataExecute = async (): Promise<void> => {
			await refetchStatisticData();
		};

		const getDifferenceBotsCountFirstRender = (): boolean => {
			let differenceBotsCount = 0;
			if (statisticDataFirstRender !== undefined) {
				const { maxBotsAllowedToCreate, botsCount } = statisticDataFirstRender;
				differenceBotsCount = maxBotsAllowedToCreate - botsCount;
			}
			return differenceBotsCount > 0;
		};

		const getDifferenceBotsCount = (): boolean => {
			let differenceBotsCount = 0;
			if (statisticData !== undefined) {
				const { maxBotsAllowedToCreate, botsCount } = statisticData;
				differenceBotsCount = maxBotsAllowedToCreate - botsCount;
			}
			return differenceBotsCount > 0;
		};

		const memoizingDifferenceBotsCount = useMemo((): boolean => {
			let differenceBotsCount = 0;
			if (statisticData !== undefined) {
				const { maxBotsAllowedToCreate, botsCount } = statisticData;
				differenceBotsCount = maxBotsAllowedToCreate - botsCount;
			}
			return differenceBotsCount > 0;
		}, [statisticData?.botsCount, statisticData?.maxBotsAllowedToCreate]);

		const loadingStatus = (): boolean => {
			return statusOfGettingStatistic.isFetching;
		};
		const errorStatus = (): boolean => {
			return statusOfGettingStatistic.isError;
		};
		const successStatus = (): boolean => {
			return statusOfGettingStatistic.isSuccess;
		};

		const getAllStatisticData = ():
			| IStatisticAboutAccountResponse
			| undefined => {
			return statisticData;
		};

		return {
			getDifferenceBotsCount,
			getDifferenceBotsCountFirstRender,
			refetchStatisticDataExecute,
			loadingStatus,
			errorStatus,
			successStatus,
			memoizingDifferenceBotsCount,
			getAllStatisticData,
		};
	};
