import styled from 'styled-components';

export const StyledPriceElement = styled.div`
	display: flex;
	gap: 6px;

	& svg {
		/* & img { */
		width: auto;
		height: ${(props) => props.theme.imgHeight};
		margin-top: 6px;
	}

	& .priceValue {
		color: #5e17eb;
		font-size: ${(props) => props.theme.fontSize};
		font-style: normal;
		font-weight: 700;
		line-height: 48px;
	}

	& .priceValue-cent {
		color: #5e17eb;
		font-size: 16px;
	}

	& .periodValue {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px;
		align-self: flex-start;
		/* align-self: ${(props) => props.theme.selfALign}; */
	}
`;
