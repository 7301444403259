/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledChatbotGeneratorMenu = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	min-height: 100vh;
	padding: 2rem;
	background: #0e172d;

	& .chatbot-generator-menu-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 2rem;

		& .chatbot-generator-main-area {
			border-radius: 30px;
			background: ${(props) =>
				`linear-gradient(${
					props.theme.currentTheme.mainRootBackground === '#1A1D21'
						? '90deg'
						: '180deg'
				}, ${props.theme.currentTheme.cardsGradient.one}, ${
					props.theme.currentTheme.cardsGradient.two
				})`};
			padding: 1px;

			& .chatbot-generator-main-area-border-wrapper {
				border-radius: 30px;
				background: ${(props) => props.theme.currentTheme.cardsBackground};
				display: flex;
				flex-direction: ${(props) => props.theme.direction};
				justify-content: space-between;
				align-items: center;
				padding: ${(props) => props.theme.padding};
				gap: 32px;

				& .chatbot-generator-title {
					color: #5e17eb;
					font-weight: 700;
					line-height: 58px;
					font-size: 42px;
					font-family: 'Play';
					text-align: center;
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .chatbot-generator-menu-wrapper {
			& .chatbot-generator-main-area {
				& .chatbot-generator-main-area-border-wrapper {
					padding: ${(props) => props.theme.paddingSmallScreen};
				}
			}
		}
	}

	@media screen and (max-width: 650px) {
		padding: 0.5rem;

		& .chatbot-generator-menu-wrapper {
			& .chatbot-generator-main-area {
				width: 100%;
				& .chatbot-generator-main-area-border-wrapper {
					justify-content: center;
					padding: 1rem;
					padding-top: 3rem;
				}
			}
		}
	}
`;
