import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialRefetchFnTrainingBotMenu {
	refetchFn: () => Promise<void>;
}

const initialState: IInitialRefetchFnTrainingBotMenu = {
	refetchFn: async () => {},
};

const refetchFnTrainingBotMenuSlice = createSlice({
	name: 'refetchFnTrainingBotMenu',
	initialState,
	reducers: {
		setRefetchFnTrainingBotMenu(
			state,
			action: PayloadAction<() => Promise<void>>
		) {
			state.refetchFn = action.payload;
			return state;
		},
	},
});

export const { setRefetchFnTrainingBotMenu } =
	refetchFnTrainingBotMenuSlice.actions;
export const refetchFnTrainingBotMenuReducer =
	refetchFnTrainingBotMenuSlice.reducer;
