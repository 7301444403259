/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled from 'styled-components';

export const StyledSharedModal = styled.div`
	/* For modal */

	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0px;
	left: 0px;
	background: #1a1d2180;
	z-index: 1600;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3rem;

	& .modal-wrapper-for-close {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100vw;
		height: 100vh;
		z-index: 1601;
	}

	& .are-you-sure-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 3rem;
		background: #fff;
		background: ${(props) => props.theme.currentTheme.cardsBackground};
		border: 1px solid ${(props) => props.theme.currentTheme.borderInMenu};
		border-radius: 20px;
		padding: 48px;
		width: 90%;
		max-width: 609px;
		height: max-content;
		/* max-height: 90vh; */
		/* overflow: auto; */
		position: relative;
		z-index: 1700;

		& .current-icon {
			width: 150px;
			height: 150px;
		}

		& > svg {
			width: 100px;
			height: 100px;
		}

		& .are-you-sure-title {
			font-style: normal;
			font-weight: 600;
			font-size: 32px;
			line-height: 38px;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			text-align: center;
		}

		& .are-you-sure-subtitle {
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 30px;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			text-align: center;
		}

		& .are-you-sure-buttons-area {
			display: flex;
			flex-wrap: wrap;
			gap: 2rem;
			justify-content: center;
			width: 100%;
		}

		& .are-you-sure-message {
			font-size: 20px;
			color: #5e17eb;
			font-weight: 600;
			text-align: center;
		}

		& .error-message-from-api {
			color: ${(props) => props.theme.currentTheme.mainFontColor};
		}

		& .error-message-from-api.error-alert {
			color: ${(props) => props.theme.currentTheme.alertFontColor};
		}
	}

	@media screen and (max-width: 1500px) {
		& .are-you-sure-wrapper {
			max-width: ${(props) => props.theme.maxWidth};
			gap: 1rem;
			padding: ${(props) => props.theme.paddingSmall};

			& .are-you-sure-title {
				font-size: 24px;
			}

			& .are-you-sure-subtitle {
				font-size: 18px;
			}
		}
	}

	@media screen and (max-width: 500px) {
		& .are-you-sure-wrapper {
			max-width: 500px;
			gap: 1rem;
			padding: 1rem;

			& .are-you-sure-title {
				line-height: 21px;
				font-size: 20px;
			}

			& .are-you-sure-subtitle {
				line-height: 18px;
				font-size: 16px;
			}
		}
	}
`;
