import styled from 'styled-components';

export const StyledSortComponentChoose = styled.div`
	display: flex;
	align-items: center;
	gap: 0.3rem;
	position: relative;

	& .title-snippet-sort-button {
		background: none;
		cursor: pointer;
		border: none;
		display: flex;
		align-items: center;
	}

	& .sortComponentChoose-list {
		display: flex;
		flex-direction: column;
		position: absolute;
		z-index: 500;
		top: 25px;
		left: 0px;
		gap: 0.3rem;
		border: 1px solid ${(props) => props.theme.currentTheme.borderInputs};
		background: ${(props) =>
			props.theme.currentTheme.accentHighlightColorForTable};
		border-radius: 10px;

		& .sortOption {
			width: 100px;
			cursor: pointer;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			font-size: 14px;
			font-weight: 500;
			padding: 8px 16px;

			&:hover {
				background: ${(props) => props.theme.currentTheme.accentPrimaryColor};
				color: #fff;
				border-radius: 8px;
			}
		}
	}
`;
