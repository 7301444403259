import React from 'react';
import { clearRegistrationStatus } from '../../../app/utils/utils';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Box } from '@mui/material';
import { SharedNavLink } from '../../shared/SharedNavlink/SharedNavlink';
import { type RootState } from '../../../app/store';

export const SuccessfullyRegistered: React.FC = () => {
	const responseFromApi = useAppSelector(
		(state: RootState) => state.answerApiRegistration
	);

	const dispatch = useAppDispatch();
	const handleGoToLoginMenu = (): void => {
		clearRegistrationStatus(dispatch);
	};

	return (
		<Box
			component="div"
			sx={{
				minHeight: '100vh',
				display: 'flex',
				justifyContent: 'center',
				width: '100%',
				padding: '10rem 4rem',
				background: '#0e172d',
				color: '#fff',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
					gap: '.5rem',
				}}
			>
				{responseFromApi.isSuccess ? (
					<>
						<Box>You are successfully registered. Please,</Box>
						<Box component="div" onClick={handleGoToLoginMenu}>
							<SharedNavLink link="/" text="Login" />
						</Box>
					</>
				) : (
					<>
						<Box>{responseFromApi.errorMessage}. Please, </Box>
						<Box
							component="div"
							sx={{ display: 'flex', gap: '.5rem' }}
							onClick={handleGoToLoginMenu}
						>
							<SharedNavLink link="/" text="Login" />
							or
							<SharedNavLink link="/registration" text="Register" />
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
};
