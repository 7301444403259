import styled from 'styled-components';

export const StyledSharedCustomTable = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	padding: 0px 30px 30px 30px;

	& .grid-bots-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		& .title-wrapper {
			display: flex;
			justify-content: space-between;
			padding: 0px 32px;
			border-radius: 8px;
			background: ${(props) =>
				props.theme.currentTheme.accentHighlightColorForTable};

			& .title-bots {
				padding: 10px;
				font-size: 18px;
				line-height: 140%;
			}
		}

		& .grid-card-wrapper {
			display: flex;
			justify-content: space-between;
			padding: 10px 32px;
			border-bottom: 1px solid
				${(props) => props.theme.currentTheme.accentHighlightColorForTable};

			& .grid-card-item {
				padding: 10px;
				font-size: 18px;
				line-height: 140%;
			}

			&:hover {
				background: ${(props) => props.theme.hoverBackgroundColor};
			}
		}

		& .domain-grid-item,
		.tone-grid-item,
		.style-grid-item,
		.actions-grid-item {
			display: flex;
			justify-content: flex-start;
			min-width: 130px;
			align-items: center;
			text-align: left;
		}

		& .domain-grid-item {
			flex: 2;
			min-width: 200px;
			hyphens: auto;
			word-wrap: break-word;
		}
		& .tone-grid-item {
			flex: 1;
		}
		& .style-grid-item {
			flex: 1;
		}
		& .actions-grid-item {
			flex: 3;
			width: 100%;
			min-width: max-content;
			justify-content: center;

			display: flex;
			gap: 0.7rem;

			& .inline-action-button {
				border: none;
				background: none;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 27px;
				color: #1592ec;
				cursor: pointer;
				padding: 0px;
			}

			& svg {
				width: 24px;
				height: 24px;
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .grid-bots-container {
			& .title-wrapper {
				& .title-bots {
					font-size: 14px;
				}
			}
			& .grid-card-wrapper {
				& .grid-card-item {
					font-size: 14px;
				}
			}
			& .actions-grid-item {
				& .inline-action-button {
					font-size: 14px;
				}
			}
		}
	}
`;
