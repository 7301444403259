import styled from 'styled-components';

export const StyledProgressDots = styled.div`
	display: inline-block;
	position: relative;
	width: 70px;
	height: 15px;

	& div {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: rgb(239, 239, 239);
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}

	& .dot-one {
		left: 8px;
		animation: progress-dots1 0.6s infinite;
	}

	& .dot-two {
		left: 8px;
		animation: progress-dots2 0.6s infinite;
	}

	& .dot-three {
		left: 32px;
		animation: progress-dots2 0.6s infinite;
	}

	& .dot-four {
		left: 56px;
		animation: progress-dots3 0.6s infinite;
	}

	@keyframes progress-dots1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}

	@keyframes progress-dots3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}

	@keyframes progress-dots2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(24px, 0);
		}
	}
`;
