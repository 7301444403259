// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const isCodeJSGeneratedSlice = createSlice({
	name: 'isCodeJSGenerated',
	initialState,
	reducers: {
		setCodeJSGeneratedStatus(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setCodeJSGeneratedStatus } = isCodeJSGeneratedSlice.actions;
export const isCodeJSGeneratedReducer = isCodeJSGeneratedSlice.reducer;
