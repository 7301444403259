// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const checkEmptyStatusForConfigModelMenuSlice = createSlice({
	name: 'checkEmptyStatusForConfigModelMenu',
	initialState: false,
	reducers: {
		setEmptyStatusForCheckConfigModelMenu(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForCheckConfigModelMenu } =
	checkEmptyStatusForConfigModelMenuSlice.actions;
export const checkEmptyStatusForConfigModelMenuReducer =
	checkEmptyStatusForConfigModelMenuSlice.reducer;
