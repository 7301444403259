import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import { StyledModalExportSnippets } from './ModalExportSnippets.style';
import { SharedTransparentButton } from '../../shared/SharedTransparentButton/SharedTransparentButton';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { ProgressDots } from '../../shared/ProgressDots/ProgressDots';
import {
	useExportSelectedSnippetsMutation,
	useLazyExportSnippetsQuery,
} from '../../../app/api/chatbot.api';
import { useAddRemoveCurrentSnippetAsChecked } from '../hooks/useAddRemoveCurrentSnippetAsChecked';

interface IModalExportSnippetsProps {
	closeFn: () => void;
	chatbotId: string;
}

interface IExportSnippetsComponentProps {
	closeFn: () => void;
	chatbotId: string;
}

const ExportSnippetsComponent: React.FC<IExportSnippetsComponentProps> = ({
	closeFn,
	chatbotId,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const theme = { currentTheme };

	const [exportSnippets, resultOfExportSnippets] = useLazyExportSnippetsQuery();
	const [exportSelectedSnippets, resultOfExportSelectedSnippets] =
		useExportSelectedSnippetsMutation();
	const allFunctionsWithCheckedArray = useAddRemoveCurrentSnippetAsChecked();

	const allCheckedArray = useAppSelector(
		(state: RootState) => state.checkedSnippetsMenu.checkedSnippetsArray
	);
	const [countSelected, setCountSelected] = useState<number>(0);

	useEffect(() => {
		setCountSelected(allCheckedArray.length);
	}, [allCheckedArray]);

	const exportBody = {
		token,
		chatbotId,
	};

	const exportBodySelectedSnippets = {
		token,
		snippetsArray: allCheckedArray,
	};

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const handleExportSelectedSnippetsExecute = async (
		type: string
	): Promise<void> => {
		try {
			const linkExport = await exportSelectedSnippets({
				...exportBodySelectedSnippets,
				type,
			});
			if ('data' in linkExport && linkExport.data !== undefined) {
				window.open(linkExport.data.url);
				allFunctionsWithCheckedArray.removeAllArraySnippetAsChecked();
			}
		} catch (error) {
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{' '}
					Something went wrong{' '}
				</Box>
			);
		}
	};

	const handleExportSnippetsExecute = async (type: string): Promise<void> => {
		try {
			const linkExport = await exportSnippets({ ...exportBody, type });
			if (linkExport.data !== undefined) {
				window.open(linkExport.data.url);
				allFunctionsWithCheckedArray.removeAllArraySnippetAsChecked();
			}
		} catch (error) {
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{' '}
					Something went wrong{' '}
				</Box>
			);
		}
	};

	useEffect(() => {
		setLoadingErrorContent(null);
	}, []);

	useEffect(() => {
		if (resultOfExportSnippets.isLoading || resultOfExportSnippets.isFetching) {
			setLoadingErrorContent(<ProgressDots />);
		} else if (resultOfExportSnippets.isError) {
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{' '}
					Something went wrong{' '}
				</Box>
			);
		} else if (resultOfExportSnippets.isSuccess) {
			setLoadingErrorContent(null);
		}
	}, [resultOfExportSnippets]);

	useEffect(() => {
		if (resultOfExportSelectedSnippets.isLoading) {
			setLoadingErrorContent(<ProgressDots />);
		} else if (resultOfExportSelectedSnippets.isError) {
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{' '}
					Something went wrong{' '}
				</Box>
			);
		} else if (resultOfExportSelectedSnippets.isSuccess) {
			setLoadingErrorContent(null);
		}
	}, [resultOfExportSelectedSnippets]);

	useEffect(() => {
		if (resultOfExportSnippets.status === 'rejected') {
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{' '}
					Something went wrong{' '}
				</Box>
			);
		}
	}, [resultOfExportSnippets]);

	const handleCloseModal = (): void => {
		closeFn();
		setLoadingErrorContent(null);
	};

	const isDisabledButton = (): boolean => {
		if (countSelected > 0) {
			return resultOfExportSelectedSnippets.isLoading;
		} else {
			return (
				resultOfExportSnippets.isFetching || resultOfExportSnippets.isLoading
			);
		}
	};

	const currentExportFn = (type: string): void => {
		if (countSelected > 0) {
			void handleExportSelectedSnippetsExecute(type);
		} else {
			void handleExportSnippetsExecute(type);
		}
	};

	return (
		<StyledModalExportSnippets theme={theme}>
			<Box component="div" className="exportSnippetMenuComponent-wrapper">
				<Box component="div" className="exportSnippetMenu-title-subtitle">
					<Box component="div" className="exportSnippetMenu-title">
						Export your snippets
					</Box>
					<Box component="div" className="exportSnippetMenu-subtitle">
						Select document type to export{' '}
						{countSelected > 0 ? `${countSelected} snippets` : ''}
					</Box>
				</Box>
				<Box component="div" className="exportSnippetMenu-input-url">
					<Box component="div" className="exportSnippetMenu-exe-buttons">
						<SharedMainButton
							isDeleteButton={false}
							text="CSV"
							buttonFunction={() => {
								currentExportFn('csv');
							}}
							isDisabled={isDisabledButton()}
						/>
						<SharedMainButton
							isDeleteButton={false}
							text="XLSX"
							buttonFunction={() => {
								currentExportFn('xlsx');
							}}
							isDisabled={isDisabledButton()}
						/>
					</Box>
				</Box>
				<Box
					component="div"
					className="exportSnippetMenu-answer-loading-content"
				>
					{loadingErrorContent}
				</Box>
				<Box component="div" className="exportSnippetMenu-button-field">
					<Box>
						<SharedTransparentButton
							text="Cancel"
							buttonFunction={handleCloseModal}
						/>
					</Box>
				</Box>
			</Box>
		</StyledModalExportSnippets>
	);
};

export const ModalExportSnippets: React.FC<IModalExportSnippetsProps> = ({
	closeFn,
	chatbotId,
}) => {
	return (
		<SharedModal
			closeFn={closeFn}
			executeFn={() => {}}
			message={null}
			title=""
			isOnlyAlert={false}
			standaloneFC={
				<ExportSnippetsComponent chatbotId={chatbotId} closeFn={closeFn} />
			}
		/>
	);
};
