import React from 'react';
import { StyledPriceElement } from './PriceElement.style';
import { ReactComponent as Dollar } from '../pic/dollar.svg';
import { Box } from '@mui/material';

interface IPriceElementProps {
	priceValue: string | JSX.Element;
	isBig: boolean;
	isPerMonth: boolean;
}

export const PriceElement: React.FC<IPriceElementProps> = ({
	priceValue,
	isBig,
	isPerMonth,
}) => {
	const theme = {
		fontSize: isBig ? '60px' : '40px',
		imgHeight: isBig ? '24px' : '14px',
		selfALign: isBig ? 'flex-start' : 'flex-end',
	};

	const isPriceValueString = typeof priceValue === 'string';

	const priceElements = (price: string): { integer: string; cent: string } => {
		const priceArray = price.split('.');

		return {
			integer: priceArray[0],
			cent: priceArray[1],
		};
	};

	return (
		<StyledPriceElement theme={theme}>
			<Dollar />
			<Box component="span" className="priceValue">
				{isPriceValueString ? (
					<>
						<Box component="span" className="priceValue">
							{priceElements(priceValue).integer}
						</Box>
						<Box component="span" className="priceValue-cent">
							{'.'}
							{priceElements(priceValue).cent}
						</Box>
					</>
				) : (
					priceValue
				)}
			</Box>
			<Box component="span" className="periodValue">
				{isPerMonth ? 'per month' : 'per year'}
			</Box>
		</StyledPriceElement>
	);
};
