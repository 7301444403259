// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const socialOpenCloseDropDownSlice = createSlice({
	name: 'socialOpenCloseDropDown',
	initialState,
	reducers: {
		setStatusSocialDropDownList(state, action: PayloadAction<boolean>) {
			state = action.payload;
			return state;
		},
	},
});

export const { setStatusSocialDropDownList } =
	socialOpenCloseDropDownSlice.actions;
export const socialOpenCloseDropDownReducer =
	socialOpenCloseDropDownSlice.reducer;
