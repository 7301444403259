import React from 'react';
import { Box } from '@mui/material';
import { StyledChatbotGeneratorMenu } from './ChatbotGeneratorMenu.style';
import { useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { AddCaptchaKeyComponent } from './AddCaptchaKeyComponent/AddCaptchaKeyComponent';
import { ReceiveJSCodeComponent } from './ReceiveJSCodeComponent/ReceiveJSCodeComponent';
import { CustomStepper } from './CustomStepper/CustomStepper';
import { AddStylizationComponent } from './AddStilizationComponent/AddStylizationComponent';
import { SharedMainButton } from '../shared/SharedMainButton/SharedMainButton';
import { useClearSettingsJSGenerated } from '../../app/hooks/useClearSettingsJSGenerated';
import { ChooseTemplateComponent } from './ChooseTemplateComponent/ChooseTemplateComponent';

export const ChatbotGeneratorMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const isCodeJSGenerated = useAppSelector(
		(state: RootState) => state.isCodeJSGenerated
	);
	const currentStep = useAppSelector(
		(state: RootState) => state.currentStepGenerator
	);

	const clearSettingsJSGenerated = useClearSettingsJSGenerated();

	const CurrentStepComponent = (): JSX.Element => {
		switch (currentStep) {
			case 0:
				return <AddCaptchaKeyComponent />;
			case 1:
				return <ChooseTemplateComponent />;
			case 2:
				return <AddStylizationComponent />;
			case 3:
				return (
					<ReceiveJSCodeComponent
						unmountFn={clearSettingsJSGenerated}
						isCoderfy
					/>
				);
			case 4:
				return (
					<ReceiveJSCodeComponent
						unmountFn={clearSettingsJSGenerated}
						isCoderfy
					/>
				);
			default:
				return <AddCaptchaKeyComponent />;
		}
	};

	const theme = {
		currentTheme,
		direction: currentStep === 1 ? 'row' : 'column',
		padding: currentStep === 0 ? '56px 32px 56px 56px' : '56px',
		paddingSmallScreen: currentStep === 0 ? '32px 24px 32px 32px' : '32px',
	};

	return (
		<StyledChatbotGeneratorMenu theme={theme}>
			<Box component="div" className="chatbot-generator-menu-wrapper">
				<CustomStepper />

				<Box component="div" className="chatbot-generator-main-area">
					<Box
						component="div"
						className="chatbot-generator-main-area-border-wrapper"
					>
						<CurrentStepComponent />
						{isCodeJSGenerated ? (
							<SharedMainButton
								isDeleteButton={false}
								buttonFunction={clearSettingsJSGenerated}
								text="Create a new bot"
							/>
						) : (
							<></>
						)}
					</Box>
				</Box>
			</Box>
		</StyledChatbotGeneratorMenu>
	);
};
