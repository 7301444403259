import styled from 'styled-components';

export const StyledChangeChatbotIntegration = styled.div`
	width: 100%;

	& .changeChatbotIntegration-wrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding: 30px 50px;
		width: 100%;

		& .changeChatbotIntegration-title-subtitle {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			font-style: normal;
			width: 100%;

			& .changeChatbotIntegration-title {
				font-size: 24px;
				font-weight: 600;
				line-height: 145%;
			}

			& .changeChatbotIntegration-subtitle {
				font-size: 18px;
				font-weight: 400;
				line-height: 27px;
			}
		}

		& .changeChatbotIntegration-input-url {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 2rem;

			& > div > div {
				width: 100% !important;
			}

			& input {
				width: 100% !important;
			}
		}

		& .changeChatbotIntegration-button-field {
			display: flex;
			gap: 1rem;

			& > div {
				width: 100% !important;
				& button {
					width: 100% !important;
				}
			}
		}
	}

	@media screen and (max-width: 650px) {
		& .changeChatbotIntegrationComponent-wrapper {
			padding: 16px;
			& .changeChatbotIntegration-title-subtitle {
				text-align: center;
			}
		}
	}
`;
