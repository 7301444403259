import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledTrainingBotMenu } from './TrainingBotMenu.style';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { NavbarTrainingBotMenu } from './NavbarTrainingBotMenu/NavbarTrainingBotMenu';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SearchBarTrainingBotMenu } from './SearchBarTrainingBotMenu/SearchBarTrainingBotMenu';
import { TableSnippetsTrainingBotMenu } from './TableSnippetsTrainingBotMenu/TableSnippetsTrainingBotMenu';
import { DetailedBotCard } from '../DetailedBotCard/DetailedBotCard';
import { useGetSnippetsByChatbotIdQuery } from '../../app/api/chatbot.api';
import { useLocation } from 'react-router-dom';
import {
	type IGetSnippetsByChatbotIdResponse,
	type IGetSnippetsByChatbotIdRequest,
} from '../../app/api/interfaces/snippets.interface';
import { type IIDataSnippetsRows } from './TableSnippetsTrainingBotMenu/SnippetsTable/SnippetsTable';
import {
	setStartFetchPage,
	setTotalResultsSnippets,
} from '../../app/slices/trainingBotMenu/paginationValuesTrainingBotMenuSlice';
import { ModalImportUrl } from './ModalImportUrl/ModalImportUrl';
import { ModalCreateChangePrompt } from './ModalCreateChangePrompt/ModalCreateChangePrompt';
import { setOpenModalEditCurrentSnippetStatus } from '../../app/slices/trainingBotMenu/openModal/openEditCurrentSnippetTrainingBotMenuSlice';
import { SharedModal } from '../shared/SharedModal/SharedModal';
import { setOpenModalDeleteCurrentSnippetStatus } from '../../app/slices/trainingBotMenu/openModal/openDeleteCurrentSnippetTrainingBotMenuSlice';
import { useDeleteCurrentSnippet } from './hooks/useDeleteSnippet';
import { ProgressSpinner } from '../shared/ProgressSpinner/ProgressSpinner';
import { setCurrentChatbotId } from '../../app/slices/trainingBotMenu/currentChatbotIdTrainingBotMenuSlice';
import { setRefetchFnTrainingBotMenu } from '../../app/slices/trainingBotMenu/refetchFnTrainingBotMenuSlice';
import { setDeleteSnippetMessage } from '../../app/slices/trainingBotMenu/deleteSnippetMessageTrainingBotMenuSlice';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SharedSearchBar } from '../shared/PaginationAndTableComponents/SearchBarTrainingBotMenu/SharedSearchBar';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SearchBarButtonsField } from './SearchBarTrainingBotMenu/SearchBarButtonsField';
import { activateSearchBarForRefetching } from '../../app/slices/trainingBotMenu/searchBar/searchBarValueSlice';
import { HistoryRequest } from './HistoryRequest/HistoryRequest';
import { ModalAskCache } from './ModalAskCache/ModalAskCache';
import { setOpenModalAskCacheStatus } from '../../app/slices/trainingBotMenu/openModal/openCacheSnippetsTrainingBotMenuSlice';
import { removeArrayCheckedSnippets } from '../../app/slices/trainingBotMenu/checkedSnippets/checkedSnippetsSlice';
import { ConfigModelMenu } from './ConfigModelMenu/ConfigModelMenu';
import { ConfigOpenAiMenu } from './ConfigOpenAiMenu/ConfigOpenAiMenu';

export const redoDateToDaysAgo = (date: Date): string => {
	const dateFitFormat = new Date(date);
	const dateMS = dateFitFormat.getTime();
	const todayMS = new Date().getTime();
	const difference = Math.floor((todayMS - dateMS) / 1000 / 60 / 60 / 24);

	if (difference <= 0) {
		return `Today`;
	} else {
		return `${difference} days ago`;
	}
};

export const TrainingBotMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);
	const currentPage = useAppSelector(
		(state: RootState) => state.currentPageTrainingBotMenu.currentPage
	);
	const paginationValues = useAppSelector(
		(state: RootState) => state.paginationValuesTrainingBotMenu
	);
	const { totalResultsSnippets, viewOnPage, startFetchPage, finishFetchPage } =
		paginationValues;

	const isOpenModalUpdateSnippet = useAppSelector(
		(state: RootState) => state.openEditCurrentSnippetTrainingBotMenu
	);

	const isOpenModalCacheSnippet = useAppSelector(
		(state: RootState) => state.openCacheSnippetsTrainingBotMenu
	);
	const { isOpenModalAskCache } = isOpenModalCacheSnippet;

	const isOpenModalDeleteSnippet = useAppSelector(
		(state: RootState) => state.openDeleteCurrentSnippetTrainingBotMenu
	);

	const searchBarValue = useAppSelector(
		(state: RootState) => state.searchBarValue.searchBarValue
		// (state: RootState) => state.searchBarValue.searchBarValueForRefetching
	);

	const sortOrder = useAppSelector(
		(state: RootState) => state.searchBarValue.sortOrder
	);

	const currentId = useLocation().pathname.split('/');
	const currentIdComputed = parseFloat(currentId[currentId.length - 1]);

	useEffect(() => {
		dispatch(setCurrentChatbotId(currentIdComputed.toString()));
	}, [currentIdComputed]);

	const [isOpenModalImportUrl, setIsOpenModalImportUrl] = useState(false);
	const [isOpenModalCreateSnippet, setIsOpenModalCreateSnippet] =
		useState(false);
	// const [isOpenModalUpdateSnippet, setIsOpenModalUpdateSnippet] =
	// 	useState(false);
	const [chatbotId, setChatbotId] = useState(currentIdComputed.toString());
	const [chatbotSnippets, setChatbotSnippets] = useState<IIDataSnippetsRows[]>(
		[]
	);

	const refetchFn = useAppSelector(
		(state: RootState) => state.refetchFnTrainingBotMenu.refetchFn
	);
	// const [totalResultsSnippets, setTotalResultsSnippets] = useState<number>(1);

	// const [loadingErrorContent, setLoadingErrorContent] = useState<JSX.Element | null>(null);
	const deleteCurrentSnippet = useDeleteCurrentSnippet();
	const currentSnippetId = useAppSelector(
		(state: RootState) => state.currentSnippetTrainingBotMenu.currentSnippetId
	);
	const messageDeleteCurrentSnippet = useAppSelector(
		(state: RootState) =>
			state.deleteSnippetMessageTrainingBotMenu.deleteSnippetMessage
	);
	const executeDeleteFn = async (): Promise<void> => {
		await deleteCurrentSnippet(currentSnippetId);
		await refetchFn();
	};

	const loadingErrorContent = (): JSX.Element | null => {
		switch (messageDeleteCurrentSnippet) {
			case 'error':
				return (
					<Box component="div" className="error-message-from-api">
						{' '}
						Something went wrong{' '}
					</Box>
				);

			case 'loading':
				return <ProgressSpinner />;

			case 'success':
				return (
					<Box component="div" className="message-from-api">
						{' '}
						Success{' '}
					</Box>
				);

			default:
				return null;
		}
	};

	useEffect(() => {
		setChatbotId(currentIdComputed.toString());
		setIsOpenModalImportUrl(false);
	}, []);

	const getSnippetsByChatbotIdRequestBody: IGetSnippetsByChatbotIdRequest = {
		token,
		// old logic
		// page: startFetchPage.toString(),
		// perPage: finishFetchPage.toString(),
		page: startFetchPage.toString(),
		perPage: viewOnPage,
		order: sortOrder,
		prompt: searchBarValue,
		chatbotId,
	};

	const dispatch = useAppDispatch();

	const {
		data: snippetsArrayData,
		// isError,
		// isFetching,
		// isLoading: isLoadingSnippetsArrayData,
		refetch: refetchSnippetsArrayData,
	} = useGetSnippetsByChatbotIdQuery(getSnippetsByChatbotIdRequestBody);

	const changeCurrentSnippetsStates = (
		snippetsArrayData: IGetSnippetsByChatbotIdResponse | undefined
	): void => {
		if (snippetsArrayData !== undefined) {
			// console.log(snippetsArrayData.totalResults);
			setChatbotSnippets(
				snippetsArrayData.data.map((arrayFromApi) => ({
					id: arrayFromApi.id,
					prompt: arrayFromApi.prompt,
					url: arrayFromApi.uri,
					botName: 'temp Name',
					created: redoDateToDaysAgo(arrayFromApi.created),
					cashed: arrayFromApi.cached !== null,
					pushed: arrayFromApi.pushed !== 0,
					vectorized: arrayFromApi.embedding !== null,
					completion: arrayFromApi.completion,
				}))
			);
			// console.log(snippetsArrayData);

			if (totalResultsSnippets !== snippetsArrayData.totalResults) {
				dispatch(setTotalResultsSnippets(snippetsArrayData.totalResults));
			}
		}
	};

	const refetchSnippetsArrayDataForUpdate = async (): Promise<void> => {
		refetchSnippetsArrayData()
			.then((response) => {
				changeCurrentSnippetsStates(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		dispatch(removeArrayCheckedSnippets());
	}, []);

	useEffect(() => {
		dispatch(setStartFetchPage(1));
		refetchSnippetsArrayData()
			.then((response) => {
				changeCurrentSnippetsStates(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [searchBarValue]);

	useEffect(() => {
		refetchSnippetsArrayData()
			.then((response) => {
				changeCurrentSnippetsStates(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
		dispatch(removeArrayCheckedSnippets());
	}, [startFetchPage, finishFetchPage, viewOnPage, sortOrder]);

	useEffect(() => {
		changeCurrentSnippetsStates(snippetsArrayData);
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		dispatch(setRefetchFnTrainingBotMenu(refetchSnippetsArrayDataForUpdate));
	}, [snippetsArrayData]);

	const searchBarValueAfterChange = useAppSelector(
		(state: RootState) => state.searchBarValue.searchBarValue
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleActivateRefetchingSnippets = (): void => {
		dispatch(activateSearchBarForRefetching(searchBarValueAfterChange));
	};

	const theme = {
		currentTheme,
	};

	return (
		<StyledTrainingBotMenu theme={theme}>
			<Box component="div" className="trainingBotMenu-wrapper">
				<Box component="div" className="trainingBotMenu-main-card-wrapper">
					<Box component="div" className="trainingBotMenu-nav-field">
						<NavbarTrainingBotMenu />
					</Box>
					{currentPage === 'Training data' ? (
						<>
							<Box component="div" className="trainingBotMenu-search-bar-field">
								<Box component="div" className="search-bar-title-subtitle-area">
									<Box component="div" className="search-bar-title">
										Machine Learning training data
									</Box>
									<Box component="div" className="search-bar-subtitle">
										This is your Machine Learning training data, that you can
										use to train your own AI model to answer questions related
										to your problem
									</Box>
								</Box>
								<SharedSearchBar
									uniqueNameOfInput="search-bar-input-trainingBotMenu"
									searchBarValue={searchBarValueAfterChange}
									handleActivateRefetchingFn={handleActivateRefetchingSnippets}
									extraComponent={
										<SearchBarButtonsField
											openModalImportUrlFn={() => {
												setIsOpenModalImportUrl(true);
											}}
											openModalCreateSnippetFn={() => {
												setIsOpenModalCreateSnippet(true);
											}}
										/>
									}
								/>
								{/* <SearchBarTrainingBotMenu
									openModalImportUrlFn={() => {
										setIsOpenModalImportUrl(true);
									}}
									openModalCreateSnippetFn={() => {
										setIsOpenModalCreateSnippet(true);
									}}
								/> */}
							</Box>
							<Box
								component="div"
								className="trainingBotMenu-snippets-table-field"
							>
								<TableSnippetsTrainingBotMenu
									snippets={chatbotSnippets}
									chatbotId={currentIdComputed.toString()}
								/>
							</Box>
							{isOpenModalImportUrl ? (
								<ModalImportUrl
									chatbotId={currentIdComputed.toString()}
									closeFn={() => {
										setIsOpenModalImportUrl(false);
									}}
								/>
							) : (
								<></>
							)}

							{isOpenModalCreateSnippet ? (
								<ModalCreateChangePrompt
									typeOfModal="createSnippet"
									chatbotId={currentIdComputed.toString()}
									closeFn={() => {
										setIsOpenModalCreateSnippet(false);
									}}
								/>
							) : (
								<></>
							)}

							{isOpenModalUpdateSnippet ? (
								<ModalCreateChangePrompt
									typeOfModal="changeSnippet"
									chatbotId={currentIdComputed.toString()}
									closeFn={() => {
										dispatch(setOpenModalEditCurrentSnippetStatus(false));
									}}
								/>
							) : (
								<></>
							)}

							{isOpenModalAskCache ? (
								<ModalAskCache
									chatbotId={currentIdComputed.toString()}
									closeFn={() => {
										dispatch(setOpenModalAskCacheStatus(false));
									}}
								/>
							) : (
								<></>
							)}

							{isOpenModalDeleteSnippet ? (
								<SharedModal
									isOnlyAlert={false}
									isDeleteModal
									title="Are you sure you want to delete snippet?"
									message={loadingErrorContent()}
									executeFn={() => {
										void executeDeleteFn();
									}}
									closeFn={() => {
										dispatch(setOpenModalDeleteCurrentSnippetStatus(false));
										dispatch(setDeleteSnippetMessage(''));
									}}
								/>
							) : (
								<></>
							)}
						</>
					) : currentPage === 'Settings' ? (
						<DetailedBotCard isCoderfyJS />
					) : currentPage === 'Model' ? (
						<ConfigModelMenu />
					) : currentPage === 'OpenAI' ? (
						<ConfigOpenAiMenu />
					) : (
						<HistoryRequest chatbotId={currentIdComputed.toString()} />
					)}
				</Box>
			</Box>
		</StyledTrainingBotMenu>
	);
};
