import styled from 'styled-components';

export const StyledChatbotsTable = styled.div`
	padding-bottom: 48px;
	transition: all 0.2s ease-in-out;

	& .chatbotsTable-title {
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 145%;
		color: ${(props) => props.theme.currentTheme.mainFontColor};
		padding-bottom: 48px;
	}

	& .chatbotsTable-wrapper {
		animation: appear-table 0.5s ease-in;
	}

	& .edit-button,
	.delete-button {
		cursor: pointer;
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(1.1);
		}
		&:active {
			transform: scale(0.9);
		}
	}

	& .edit-button {
		color: ${(props) => props.theme.currentTheme.integrateAccent};
	}

	& .delete-button {
		color: ${(props) => props.theme.currentTheme.alertFontColor};
	}

	@keyframes appear-table {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;
