// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IUserLoginDataResponse {
	isSuccess: boolean;
	errorMessage: string;
	token: string;
	id: number;
	username: string;
	surname: string;
}

const token = (): string => {
	const tokenFromStorage = window.localStorage.getItem('token');
	if (tokenFromStorage === null) {
		return '';
	} else {
		return tokenFromStorage;
	}
};

const initialState: IUserLoginDataResponse = {
	isSuccess: false,
	errorMessage: '',
	token: token(),
	id: 0,
	username: '',
	surname: '',
};

const answerApiLoginSlice = createSlice({
	name: 'answerApiLogin',
	initialState,
	reducers: {
		setAnswerApiLoginStatus(state, action) {
			state.isSuccess = action.payload;
			return state;
		},
		setAnswerApiLoginErrorMessage(state, action) {
			state.errorMessage = action.payload;
			return state;
		},
		setAnswerApiLoginToken(state, action) {
			state.token = action.payload;
			return state;
		},
		setAnswerApiLoginPersonalInformation(state, action) {
			const { id, username, surname } = action.payload;
			state.id = id;
			state.username = username;
			state.surname = surname;
			return state;
		},
	},
});

export const {
	setAnswerApiLoginStatus,
	setAnswerApiLoginErrorMessage,
	setAnswerApiLoginToken,
	setAnswerApiLoginPersonalInformation,
} = answerApiLoginSlice.actions;
export const answerApiLoginReducer = answerApiLoginSlice.reducer;
