import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { SharedPasswordInput } from '../../shared/Input/SharedPasswordInput/SharedPasswordInput';
import { InputsFieldWrapper } from '../../shared/Input/InputsFieldWrapper/InputsFieldWrapper';
import { WrappedInput } from '../../shared/Input/WrappedInput/WrappedInput';

export const InputsFieldRegistration: React.FC = () => {
	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForRegistration
	);
	const validInputsStatusRegistration = useAppSelector(
		(state: RootState) => state.isValidRegistrationInputs
	);
	const {
		isValidNameRegistration,
		isValidSurnameRegistration,
		isValidEmailRegistration,
		isValidPasswordRegistration,
	} = validInputsStatusRegistration;

	return (
		<InputsFieldWrapper>
			<WrappedInput
				type="text"
				placeholder="Name"
				isEmpty={isButtonPressed}
				isDarkBack
				uniqueNameOfInput="name-registration"
				isValid={isValidNameRegistration}
			/>
			<WrappedInput
				type="text"
				placeholder="Surname"
				isEmpty={isButtonPressed}
				isDarkBack
				uniqueNameOfInput="surname-registration"
				isValid={isValidSurnameRegistration}
			/>
			<WrappedInput
				type="email"
				placeholder="E-mail"
				isEmpty={isButtonPressed}
				isDarkBack
				uniqueNameOfInput="e-mail-registration"
				isValid={isValidEmailRegistration}
			/>
			<SharedPasswordInput
				withIcons={false}
				isEmpty={isButtonPressed}
				isDarkBack
				uniqueNameOfInput="password-first-registration"
				isValid={isValidPasswordRegistration}
			/>
		</InputsFieldWrapper>
	);
};
