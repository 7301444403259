import React from 'react';
import { Box } from '@mui/material';
import { SharedModal } from '../../../shared/SharedModal/SharedModal';
import { StyledModalAskLogout } from './ModalAskLogout.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { SharedTransparentButton } from '../../../shared/SharedTransparentButton/SharedTransparentButton';
import { SharedMainButton } from '../../../shared/SharedMainButton/SharedMainButton';

interface IModalAskLogoutProps {
	closeFn: () => void;
	executeFn: () => void;
}

interface IAskLogoutComponentProps {
	closeFn: () => void;
	executeFn: () => void;
}

const AskLogoutComponent: React.FC<IAskLogoutComponentProps> = ({
	closeFn,
	executeFn,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = { currentTheme };

	return (
		<StyledModalAskLogout theme={theme}>
			<Box component="div" className="askLogoutMenuComponent-wrapper">
				<Box component="div" className="askLogoutMenu-title-subtitle">
					<Box component="div" className="askLogoutMenu-subtitle">
						Are you sure you want to logout?
					</Box>
				</Box>
				<Box component="div" className="askLogoutMenu-input-url">
					<Box component="div" className="askLogoutMenu-exe-buttons">
						<SharedMainButton
							isDeleteButton={false}
							text="Yes"
							buttonFunction={executeFn}
						/>
						<Box component="div" className="askLogoutMenu-wrapper-button">
							<SharedTransparentButton text={'No'} buttonFunction={closeFn} />
						</Box>
					</Box>
				</Box>
			</Box>
		</StyledModalAskLogout>
	);
};

export const ModalAskLogout: React.FC<IModalAskLogoutProps> = ({
	closeFn,
	executeFn,
}) => {
	return (
		<SharedModal
			closeFn={closeFn}
			executeFn={() => {}}
			message={null}
			title=""
			isOnlyAlert={false}
			standaloneFC={
				<AskLogoutComponent closeFn={closeFn} executeFn={executeFn} />
			}
		/>
	);
};
