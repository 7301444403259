import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { InputsFieldWrapper } from '../../shared/Input/InputsFieldWrapper/InputsFieldWrapper';
import { WrappedInput } from '../../shared/Input/WrappedInput/WrappedInput';

export const InputsFieldContacts: React.FC = () => {
	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForContacts
	);
	const validInputsStatusContacts = useAppSelector(
		(state: RootState) => state.isValidContactsInputs
	);
	const { isValidNameContacts, isValidMessageContacts } =
		validInputsStatusContacts;

	return (
		<InputsFieldWrapper>
			<WrappedInput
				type="text"
				placeholder="Subject"
				isEmpty={isButtonPressed}
				isDarkBack
				uniqueNameOfInput="name-contacts"
				isValid={isValidNameContacts}
			/>
			<WrappedInput
				type="textarea"
				placeholder="Type here..."
				isEmpty={isButtonPressed}
				isDarkBack
				uniqueNameOfInput="type-text-area-contacts"
				isValid={isValidMessageContacts}
			/>
		</InputsFieldWrapper>
	);
};
