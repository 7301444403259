import React from 'react';
import { Box } from '@mui/material';
import { type IIDataBotRows } from '../../../PersonalCabinetMenu/Cards/ProfilesBotsCard';

export interface IGridCardProps {
	row: IIDataBotRows;
}

export const GridCard: React.FC<IGridCardProps> = ({ row }) => {
	return (
		<Box component="div" className="grid-card-wrapper">
			<Box component="div" className="grid-card-item domain-grid-item">
				{row.domain}
			</Box>
			<Box component="div" className="grid-card-item tone-grid-item">
				{row.tone}
			</Box>
			<Box component="div" className="grid-card-item style-grid-item">
				{row.style}
			</Box>
			<Box component="div" className="grid-card-item actions-grid-item">
				{row.actionButtons.map((element) => element)}
			</Box>
		</Box>
	);
};
