import React from 'react';
import { StyledAddNewPaymentMethodInputsField } from './AddNewPaymentMethodInputsField.style';
import { Box } from '@mui/material';
import { WrappedInput } from '../../../shared/Input/WrappedInput/WrappedInput';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';

export const AddNewPaymentMethodInputsField: React.FC = () => {
	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForPaymentMethodCardValues
	);
	const validCardInputsStatus = useAppSelector(
		(state: RootState) => state.isValidPaymentMethodCardValues
	);
	const { isValidCardNumber, isValidMonth, isValidYear, isValidCVC } =
		validCardInputsStatus;

	const valueInputs = useAppSelector(
		(state: RootState) => state.paymentMethodCardValue
	);
	const { cardNumber, month, year, cvc } = valueInputs;

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		currentTheme,
	};

	window.addEventListener('keyup', (event: KeyboardEvent) => {
		if (event.key === 'e' || event.key === '-' || event.key === '+') {
			event.preventDefault();
		}
	});

	return (
		<StyledAddNewPaymentMethodInputsField theme={theme}>
			<Box component="div" className="card-number-wrapper">
				<Box component="div" className="card-number-title">
					Card Number
				</Box>
				<Box component="div" className="card-number-input">
					<WrappedInput
						type="number"
						placeholder="•••• •••• •••• ••••"
						isEmpty={isButtonPressed}
						uniqueNameOfInput="payments-card-number"
						isValid={isValidCardNumber}
						value={cardNumber}
						maxLength={16}
					/>
				</Box>
			</Box>
			<Box component="div" className="expiry-cvc-wrapper">
				<Box component="div" className="expiry-wrapper">
					<Box component="div" className="expiry-title">
						Expiry
					</Box>
					<Box component="div" className="expiry-all-inputs">
						<WrappedInput
							type="number"
							placeholder="••"
							isEmpty={isButtonPressed}
							uniqueNameOfInput="payments-card-expiry-month"
							isValid={isValidMonth}
							value={month}
							maxLength={2}
						/>
						<Box component="div" className="slash">
							/
						</Box>
						<WrappedInput
							type="number"
							placeholder="••"
							isEmpty={isButtonPressed}
							uniqueNameOfInput="payments-card-expiry-year"
							isValid={isValidYear}
							value={year}
							maxLength={2}
						/>
					</Box>
				</Box>
				<Box component="div" className="cvc-wrapper">
					<Box component="div" className="cvc-title">
						CVC
					</Box>
					<WrappedInput
						type="number"
						placeholder="•••"
						isEmpty={isButtonPressed}
						uniqueNameOfInput="payments-card-cvc"
						isValid={isValidCVC}
						value={cvc}
						maxLength={3}
					/>
				</Box>
			</Box>
		</StyledAddNewPaymentMethodInputsField>
	);
};
