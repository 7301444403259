import styled from 'styled-components';

export const StyledSharedAnswer = styled.div`
	padding: 24px;
	gap: 16px;
	background: linear-gradient(141.78deg, #44adfa -8.39%, #5b22cc 100.37%);
	border-radius: 20px 20px 20px 0px;
	color: #fff;
	width: 80%;
	animation: appear 0.2s linear;

	& .answer-text {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	@keyframes appear {
		0% {
			transform: translateX(-100px);
			opacity: 0;
		}
		100% {
			transform: translateX(0px);
			opacity: 1;
		}
	}

	@media screen and (max-width: 350px) {
		width: 90%;
	}
`;
