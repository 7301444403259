import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { StyledDetailedBotCard } from './DetailedBotCard.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import {
	useDeleteBotMutation,
	useGetChatbotTemplateQuery,
	useGetCurrentBotQuery,
	useLazyGetChatbotStyleByTemplateQuery,
	usePatchCurrentBotMutation,
	useUpdateIconCurrentBotMutation,
} from '../../app/api/chatbot.api';
import {
	type IPatchCurrentBotDataRequestResponse,
	type IResponseBotDelete,
	type IResponseDataWithError,
} from '../../app/api/interfaces/interfaces';
import {
	checkInput,
	domainWithoutHttp,
	receiveHTMLCodeScriptForChatbot,
	receiveHTMLCodeScriptForChatbotCoderfyJS,
} from '../../app/utils/utils';
import { SharedModal } from '../shared/SharedModal/SharedModal';
import { ProgressSpinner } from '../shared/ProgressSpinner/ProgressSpinner';
import { SharedJSCode } from '../shared/SharedJSCode/SharedJSCode';
import { SharedPreviewChatbot } from '../shared/SharedPreviewChatbot/SharedPreviewChatbot';
// import { temporaryParams } from '../ChatbotGeneratorMenu/AddStilizationComponent/AddStylizationInputsField';
import { DetailedBotCardInputsField } from './DetailedBotCardInputsField';
import { CardInfoItem, type IChangeableInputProps } from './CardInfoItem';
import { setEmptyStatusForDetailedBotCard } from '../../app/slices/inputsDetailedBotCard/checkEmptyStatusForDetailedBotCardSlice';
import { SharedChangeIconComponent } from '../shared/SharedChangeIconComponent/SharedChangeIconComponent';
import testIcon from './pic/testIcon.svg';
import { setIconChatbotChoosing } from '../../app/slices/iconChatbot/iconChatbotSlice';
import { type IGetChatbotStyleByTemplateStylesList } from '../../app/api/interfaces/styleChatbot/getChatbotStyleByTemplate';
import { DataAlert } from '../shared/SharedDataAlertFromApi/SharedDataAlertFromApi';
import { ProgressDots } from '../shared/ProgressDots/ProgressDots';
import { type IGetChatbotTemplateResponse } from '../../app/api/interfaces/styleChatbot/getChatbotTemplate';
import { type IGetOneChatbotDataResponse } from '../../app/api/interfaces/getOneChatbot';
import { setLinkCurrentChatbotImg } from '../../app/slices/inputsGenerator/linkCurrentChatbotImgSlice';
import { setChatBotStructureDetailedBotCard } from '../../app/slices/inputsDetailedBotCard/detailedBotCardInputsValueSlice';
import pencil from './pic/pencil.svg';
// import man from './pic/man.svg';
import brush from './pic/brush.svg';
import world from './pic/world.svg';
import { SaveDeleteBntFieldShared } from '../shared/SaveDeleteBntFieldShared/SaveDeleteBntFieldShared';
import { SharedCheckboxItem } from '../shared/SharedCheckboxItem/SharedCheckboxItem';

interface IDataResponseDeleteBot {
	data: IResponseBotDelete;
}

interface IDataResponseUpdateBot {
	data: IPatchCurrentBotDataRequestResponse;
}

export interface IBotCustomizationInformation {
	title: string;
	value: string | null;
	changeableInput: IChangeableInputProps | null;
}

export const DetailedBotCard: React.FC<{ isCoderfyJS: boolean }> = ({
	isCoderfyJS,
}) => {
	const navigate = useNavigate();

	const iconChatbotChoosing = useAppSelector(
		(state: RootState) => state.iconChatbot.iconChatbotChoosing
	);

	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const validStatusInputsDetailedBotCard = useAppSelector(
		(state: RootState) => state.isValidDetailedBotCardInputs
	);
	const {
		isValidToneDetailedBotCard,
		isValidStyleDetailedBotCard,
		isValidHeaderDetailedBotCard,
		isValidButtonTextDetailedBotCard,
		isValidChatBotNameDetailedBotCard,
		isValidChatBotStructureDetailedBotCard,
		isValidChatBotStyleCssDetailedBotCard,
	} = validStatusInputsDetailedBotCard;

	const valueInputsDetailedBotCard = useAppSelector(
		(state: RootState) => state.detailedBotCardInputsValue
	);
	const {
		header: headerDetailedBotCard,
		buttonText: buttonTextDetailedBotCard,
		tone: toneDetailedBotCard,
		style: styleDetailedBotCard,
		chatBotName: chatBotNameDetailedBotCard,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		styleCss: styleCssDetailedBotCard,
		structure: structureDetailedBotCard,
	} = valueInputsDetailedBotCard;

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const [displayedScript, setDisplayedScript] = useState('');
	const [currentBot, setCurrentBot] =
		useState<IGetOneChatbotDataResponse | null>(null);

	const [isOpenModal, setIsOpenModal] = useState(false);
	const [
		isOpenModalAfterSuccessDeleteBot,
		setIsOpenModalAfterSuccessDeleteBot,
	] = useState(false);
	const [isOpenModalAfterUpdateBot, setIsOpenModalAfterUpdateBot] =
		useState(false);

	const [messageErrorForModal, setMessageErrorForModal] = useState('');

	const [dialogMessage, setDialogMessage] = useState('');

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [currentFile, setCurrentFile] = useState<File>();

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const currentId = useLocation().pathname.split('/');
	const currentIdComputed = parseFloat(currentId[currentId.length - 1]);
	const linkCloudlet = process.env.REACT_APP_AISTA_LINK;
	const {
		data: currentBotData,
		isLoading: isLoadingCurrentBotData,
		isError: isErrorCurrentBotData,
		refetch: refetchCurrentBotData,
	} = useGetCurrentBotQuery({ id: currentIdComputed, token });

	const [stylesListByTemplateId, setStylesListByTemplateId] =
		useState<IGetChatbotStyleByTemplateStylesList[]>();

	const [dataUpdateIcon] = useUpdateIconCurrentBotMutation();

	const [templateId, setTemplateId] = useState(0);
	const [styleId, setStyleId] = useState(0);

	const {
		data: dataGetChatbotTemplate,
		isLoading: isLoadingGetChatbotTemplate,
		isError: isErrorGetChatbotTemplate,
		isSuccess: isSuccessGetChatbotTemplate,
		refetch: refetchGetChatbotTemplate,
	} = useGetChatbotTemplateQuery({ token });

	const [dataArrayTemplates, setDataArrayTemplates] =
		useState<IGetChatbotTemplateResponse[]>();

	useEffect(() => {
		if (dataGetChatbotTemplate !== undefined) {
			setDataArrayTemplates(dataGetChatbotTemplate);
			const templatesNamesList = dataGetChatbotTemplate.map(
				(currentTemplate) => currentTemplate.name
			);
			checkInput(
				'structure-autocomplete-detailed-bot-card-changeableInput',
				{ valueInput: structureDetailedBotCard, params: templatesNamesList },
				dispatch
			);
		}
	}, [dataGetChatbotTemplate]);

	useEffect(() => {
		if (isLoadingGetChatbotTemplate) {
			// console.log('isLoadingGetChatbotTemplate');
			setLoadingErrorContent(<ProgressDots />);
		} else if (isErrorGetChatbotTemplate) {
			// console.log('isErrorGetChatbotTemplate');
			setLoadingErrorContent(
				<DataAlert
					text="Something went wrong. Please, reload the page"
					isError
				/>
			);
		} else if (isSuccessGetChatbotTemplate) {
			// console.log('isSuccessGetChatbotTemplate');
			setLoadingErrorContent(null);
		}
	}, [
		isLoadingGetChatbotTemplate,
		isErrorGetChatbotTemplate,
		isSuccessGetChatbotTemplate,
	]);

	const [getChatbotStyleByTemplate, statusGettingChatbotStyleByTemplate] =
		useLazyGetChatbotStyleByTemplateQuery();

	const [isUseReferences, setIsUseReferences] = useState<boolean>(
		currentBotData?.search === true
	);
	useEffect(() => {
		setIsUseReferences(currentBotData?.search === true);
	}, [currentBotData]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const executeGetChatbotStyleByTemplate = async (
		token: string,
		chosenTemplateId: string
	): Promise<void> => {
		try {
			const resultGettingChatbotStyleByTemplate =
				await getChatbotStyleByTemplate({
					token,
					id: chosenTemplateId,
				});
			if ('data' in resultGettingChatbotStyleByTemplate) {
				const stylesChatbotList =
					resultGettingChatbotStyleByTemplate.data?.styleCss;
				// const stylesChatbotListNames = stylesListByTemplateId?.map((currentStyle) => currentStyle.name);
				// checkInput(
				// 	'style-css-autocomplete-detailed-bot-card-changeableInput',
				// 	{ valueInput: currentBot !== null ? currentBot.style : '', params: stylesChatbotListNames },
				// 	dispatch
				// );

				setStylesListByTemplateId(stylesChatbotList);
			} else {
				setLoadingErrorContent(
					<DataAlert text="Something went wrong" isError />
				);
			}
		} catch (error) {
			setLoadingErrorContent(<DataAlert text="Something went wrong" isError />);
		}
	};

	const getStyleCssByTemplateExe = (): void => {
		if (dataArrayTemplates !== undefined) {
			const chosenTemplateId = dataArrayTemplates.find(
				(currentTemplate) => currentTemplate.name === structureDetailedBotCard
			)?.id;
			if (chosenTemplateId !== undefined) {
				void executeGetChatbotStyleByTemplate(
					token,
					chosenTemplateId.toString()
				);
			}
		}
	};

	useEffect(() => {
		getStyleCssByTemplateExe();
	}, [dataArrayTemplates, structureDetailedBotCard]);

	useEffect(() => {
		// dispatch(setChatBotStyleCSSDetailedBotCard(''));
	}, [structureDetailedBotCard]);

	useEffect(() => {
		setDialogMessage('');
		dispatch(setEmptyStatusForDetailedBotCard(false));
	}, []);

	useEffect(() => {
		if (currentBotData !== undefined) {
			setCurrentBot(currentBotData);
			if ('structure' in currentBotData && currentBotData.structure !== null) {
				if ('name' in currentBotData.structure) {
					dispatch(
						setChatBotStructureDetailedBotCard(currentBotData.structure.name)
					);
				}
			}
			// console.log('currentBotData', currentBotData);
			const displayedScriptTemporary = receiveHTMLCodeScriptForChatbot(
				linkCloudlet,
				true,
				false,
				false,
				true,
				currentBotData.style,
				currentBotData.tone,
				domainWithoutHttp(currentBotData.domain),
				currentBotData.askMe,
				currentBotData.button
			);
			const displayedScriptCoderfyJS = receiveHTMLCodeScriptForChatbotCoderfyJS(
				currentBotData.id.toString()
			);
			setDisplayedScript(
				isCoderfyJS ? displayedScriptCoderfyJS : displayedScriptTemporary
			);
		} else {
			setDialogMessage('Bot doesn`t exist');
		}
	}, [currentBotData]);

	useEffect(() => {
		if (statusGettingChatbotStyleByTemplate.isLoading) {
			setLoadingErrorContent(<ProgressDots />);
		} else if (statusGettingChatbotStyleByTemplate.isError) {
			setLoadingErrorContent(
				<DataAlert
					text="Something went wrong. Please, reload the page"
					isError
				/>
			);
		} else if (statusGettingChatbotStyleByTemplate.isSuccess) {
			setLoadingErrorContent(null);
		}
	}, [statusGettingChatbotStyleByTemplate]);

	const [dataDeleted, resultOfDeletion] = useDeleteBotMutation();
	const [dataUpdateBot, resultOfUpdateBot] = usePatchCurrentBotMutation();

	const handleDeleteBot = (id: number): void => {
		dataDeleted({ id, token })
			.then((response: IDataResponseDeleteBot | IResponseDataWithError) => {
				// console.log(response);
				if ('data' in response) {
					// console.log(response.data);
					setIsOpenModal(false);
					setIsOpenModalAfterSuccessDeleteBot(true);
					// document.location.replace('/');
				} else if ('error' in response) {
					// console.log(response.error.data.message);
					response.error.data.error.length > 0
						? setMessageErrorForModal(response.error.data.error)
						: setMessageErrorForModal('Error');
				}
			})
			.catch((error) => {
				console.log(error);
				setMessageErrorForModal('Server error: Failed to fetch');
			});
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		resultOfDeletion.isLoading && setLoadingErrorContent(<ProgressSpinner />);
		resultOfDeletion.isError &&
			setLoadingErrorContent(<DataAlert text={messageErrorForModal} isError />);
	}, [resultOfDeletion, messageErrorForModal]);

	useEffect(() => {
		resultOfUpdateBot.isLoading && setLoadingErrorContent(<ProgressSpinner />);
		resultOfUpdateBot.isError &&
			setLoadingErrorContent(<DataAlert text={messageErrorForModal} isError />);
		resultOfUpdateBot.isSuccess &&
			setLoadingErrorContent(<DataAlert text={messageErrorForModal} />);
	}, [resultOfUpdateBot, messageErrorForModal]);

	const handleSaveNewBotInformation = (): void => {
		dispatch(setEmptyStatusForDetailedBotCard(true));

		if (
			// isValidToneDetailedBotCard &&
			// isValidStyleDetailedBotCard &&
			isValidChatBotStructureDetailedBotCard &&
			isValidChatBotStyleCssDetailedBotCard &&
			isValidHeaderDetailedBotCard &&
			isValidButtonTextDetailedBotCard &&
			isValidChatBotNameDetailedBotCard &&
			headerDetailedBotCard.length > 0 &&
			buttonTextDetailedBotCard.length > 0 &&
			// toneDetailedBotCard.length > 0 &&
			// styleDetailedBotCard.length > 0 &&
			structureDetailedBotCard.length > 0 &&
			styleCssDetailedBotCard.length > 0 &&
			chatBotNameDetailedBotCard.length > 0 &&
			currentBot !== null
		) {
			const displayedScriptTemporary = receiveHTMLCodeScriptForChatbot(
				linkCloudlet,
				true,
				false,
				false,
				true,
				styleDetailedBotCard,
				toneDetailedBotCard,
				domainWithoutHttp(
					currentBotData !== undefined ? currentBotData.domain : ''
				),
				headerDetailedBotCard,
				buttonTextDetailedBotCard
			);
			const displayedScriptCoderfyJS = receiveHTMLCodeScriptForChatbotCoderfyJS(
				currentBotData !== undefined ? currentBotData.id.toString() : ''
			);
			setDisplayedScript(
				isCoderfyJS ? displayedScriptCoderfyJS : displayedScriptTemporary
			);
			// console.log('new information is sent');
			handleUpdateBot(currentBot.id).catch((error) => error);
		} else {
			// console.log('temporary error');
			setLoadingErrorContent(
				<DataAlert text="Please fill in all information fields" isError />
			);
		}
	};

	useEffect(() => {
		if (
			isValidToneDetailedBotCard &&
			isValidStyleDetailedBotCard &&
			isValidHeaderDetailedBotCard &&
			isValidButtonTextDetailedBotCard &&
			headerDetailedBotCard.length > 0 &&
			buttonTextDetailedBotCard.length > 0 &&
			toneDetailedBotCard.length > 0 &&
			styleDetailedBotCard.length > 0
		) {
			const displayedScriptTemporary = receiveHTMLCodeScriptForChatbot(
				linkCloudlet,
				true,
				false,
				false,
				true,
				styleDetailedBotCard,
				toneDetailedBotCard,
				domainWithoutHttp(
					currentBotData !== undefined ? currentBotData.domain : ''
				),
				headerDetailedBotCard,
				buttonTextDetailedBotCard
			);
			!isCoderfyJS && setDisplayedScript(displayedScriptTemporary);
			// console.log('new code');
		}
	}, [valueInputsDetailedBotCard]);

	const botCustomizationInformationNameDomain: IBotCustomizationInformation[] =
		[
			{
				title: 'Name',
				value: null,
				changeableInput: {
					type: 'text',
					placeholder: 'Name',
					uniqueNameOfInput: 'style-name-detailed-bot-card-changeableInput',
					isValid: isValidChatBotNameDetailedBotCard,
					value: currentBot !== null ? currentBot.chatBotName : 'Welcome-Bot',
					icon: pencil,
					// tooltip: 'Chatbot name',
				},
			},
			{
				title: 'Domain',
				value: null,
				changeableInput: {
					type: 'text',
					placeholder: 'Tone',
					uniqueNameOfInput:
						'tone-autocomplete-detailed-bot-card-changeableInput',
					isValid: isValidToneDetailedBotCard,
					value: currentBot !== null ? currentBot.domain : '',
					disabled: true,
					icon: world,
				},
			},
		];

	const botCustomizationInformation: IBotCustomizationInformation[] = [
		{
			title: 'Title Chatbot',
			value: null,
			changeableInput: {
				type: 'text',
				placeholder: 'Header of chat window',
				uniqueNameOfInput: 'header-detailed-bot-card-changeableInput',
				isValid: isValidHeaderDetailedBotCard,
				value: currentBot !== null ? currentBot.askMe : '',
				icon: pencil,
				// tooltip: 'Chatbot header',
			},
		},
		{
			title: 'Button text',
			value: null,
			changeableInput: {
				type: 'text',
				placeholder: 'Button text',
				uniqueNameOfInput: 'button-text-detailed-bot-card-changeableInput',
				isValid: isValidButtonTextDetailedBotCard,
				value: currentBot !== null ? currentBot.button : '',
				icon: pencil,
				// tooltip: 'Button text at the bottom of your site',
			},
		},
		{
			title: 'Choose a chatbot structure',
			value: null,
			changeableInput: {
				type: 'autocomplete',
				placeholder: 'Structure',
				params: dataArrayTemplates?.map(
					(currentTemplate) => currentTemplate.name
				),
				uniqueNameOfInput:
					'structure-autocomplete-detailed-bot-card-changeableInput',
				isValid: isValidChatBotStructureDetailedBotCard,
				value: structureDetailedBotCard,
				// value: (currentBot !== null && 'structure' in currentBot && 'name' in currentBot.structure) ? currentBot.structure.name : '',
				icon: brush,
				// tooltip: 'Choose a chatbot structure: how the main elements of the chatbot will be located',
			},
		},
		{
			title: 'Choose a style',
			value: null,
			changeableInput: {
				type: 'autocomplete',
				placeholder: 'Theme',
				params: stylesListByTemplateId?.map(
					(currentStyle) => currentStyle.name
				),
				uniqueNameOfInput:
					'style-css-autocomplete-detailed-bot-card-changeableInput',
				isValid: isValidChatBotStyleCssDetailedBotCard,
				value: currentBot !== null ? currentBot.style : '',
				icon: brush,
				// tooltip: 'Choose a chatbot color scheme',
			},
		},
	];

	const handleUpdateBot = async (id: number): Promise<void> => {
		if (currentBot !== null) {
			const updateBotDataRequestBody: IPatchCurrentBotDataRequestResponse = {
				styleCss: styleId,
				structure: templateId,
			};

			const referenceForCheck = isUseReferences;

			const arrayForUpdateConfigRequestBody = [
				{ value: headerDetailedBotCard, key: 'askMe', type: 'string' },
				{ value: buttonTextDetailedBotCard, key: 'button', type: 'string' },
				{
					value: chatBotNameDetailedBotCard,
					key: 'chatBotName',
					type: 'string',
				},
			];

			const descriptionProperty = {
				configurable: true,
				enumerable: true,
				writable: true,
			};

			for (const updateValue of arrayForUpdateConfigRequestBody) {
				if (currentBot !== undefined) {
					const currentValue =
						currentBot[updateValue.key as keyof typeof currentBot];

					if (updateValue.value !== currentValue) {
						const value = updateValue.value;

						Object.defineProperty(updateBotDataRequestBody, updateValue.key, {
							...descriptionProperty,
							value,
						});
					}
				}
			}

			if (
				currentBotData !== undefined &&
				currentBotData.search !== referenceForCheck
			) {
				Object.defineProperty(updateBotDataRequestBody, 'search', {
					...descriptionProperty,
					value: referenceForCheck,
				});
			}

			const base64String = iconChatbotChoosing;

			const patchDataForUpdateChatbotIcon = {
				id: currentBot.id,
				token,
				patchData: { file: base64String },
			};

			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const updateIconChatbotPromise = async (): Promise<any> =>
				await dataUpdateIcon(patchDataForUpdateChatbotIcon)
					.then((updateIconChatbotResponse) => {
						// console.log('updateIconChatbotResponse', updateIconChatbotResponse);
						return updateIconChatbotResponse;
					})
					.catch(
						(updateIconChatbotResponseError) => updateIconChatbotResponseError
					);

			const updateInformationChatbotPromise = dataUpdateBot({
				id,
				token,
				patchData: updateBotDataRequestBody,
			})
				.then(
					(
						updateInformationChatbotResponse:
							| IDataResponseUpdateBot
							| IResponseDataWithError
					) => updateInformationChatbotResponse
				)
				.catch(
					(updateInformationChatbotError) => updateInformationChatbotError
				);

			Promise.all(
				iconChatbotChoosing.length < 2
					? [updateInformationChatbotPromise]
					: [updateIconChatbotPromise(), updateInformationChatbotPromise]
			)
				.then((resultAllPromises) => {
					const statusAllPromises = resultAllPromises.map(
						(currentUpdatePromise) => {
							// console.log(currentUpdatePromise);
							if ('data' in currentUpdatePromise) {
								return { status: true, data: currentUpdatePromise.data };
							} else if ('error' in currentUpdatePromise) {
								return { status: false, data: currentUpdatePromise.error };
							} else {
								return { status: false, data: 'none' };
							}
						}
					);
					const errorMessageInPromises = statusAllPromises.find(
						(currentUpdatePromise) => !currentUpdatePromise.status
					);

					if (errorMessageInPromises === undefined) {
						dispatch(setIconChatbotChoosing(''));

						setIsOpenModal(false);
						setIsOpenModalAfterUpdateBot(true);
						setMessageErrorForModal('Your bot has been successfully updated!');
						void refetchCurrentBotData();
						void refetchGetChatbotTemplate();
					} else {
						errorMessageInPromises.data.error.data.error.length > 0
							? setMessageErrorForModal(
									errorMessageInPromises.data.error.data.error
							  )
							: setMessageErrorForModal('Error');
					}
				})
				.catch((errorAllPromises) => {
					console.log(errorAllPromises);
					setIsOpenModal(false);
					setIsOpenModalAfterUpdateBot(true);
					setMessageErrorForModal('Something went wrong');
				});
		} else {
			console.log('temp error');
		}
	};

	const handleOpenModalDelete = (): void => {
		setIsOpenModal(true);
	};
	const handleOpenModalUpdate = (): void => {
		setIsOpenModalAfterUpdateBot(true);
		handleSaveNewBotInformation();
	};

	const handleCloseModal = (): void => {
		setIsOpenModal(false);
		setLoadingErrorContent(null);
		setIsOpenModalAfterSuccessDeleteBot(false);
		setIsOpenModalAfterUpdateBot(false);
	};

	useEffect(() => {
		const currentLinkChatbotImg = stylesListByTemplateId?.find(
			(currentStyle) => currentStyle.name === styleCssDetailedBotCard
		)?.imageUrl;
		const currentStyleId = stylesListByTemplateId?.find(
			(currentStyle) => currentStyle.name === styleCssDetailedBotCard
		)?.id;
		const currentStructureId = dataArrayTemplates?.find(
			(currentStructure) => currentStructure.name === structureDetailedBotCard
		)?.id;
		currentLinkChatbotImg !== undefined &&
			dispatch(setLinkCurrentChatbotImg(currentLinkChatbotImg));
		currentStyleId !== undefined && setStyleId(currentStyleId);
		currentStructureId !== undefined && setTemplateId(currentStructureId);
		const stylesChatbotListNames = stylesListByTemplateId?.map(
			(currentStyle) => currentStyle.name
		);
		checkInput(
			'style-css-autocomplete-detailed-bot-card-changeableInput',
			{ valueInput: styleCssDetailedBotCard, params: stylesChatbotListNames },
			// { valueInput: currentBot !== null ? currentBot.style : '', params: stylesChatbotListNames },
			dispatch
		);
	}, [
		styleCssDetailedBotCard,
		structureDetailedBotCard,
		stylesListByTemplateId,
		dataArrayTemplates,
	]);

	return (
		// <LayoutAccount title="Chatbot Settings">
		<StyledDetailedBotCard theme={theme}>
			<Box component="div" className="bot-card-wrapper-border">
				<Box component="div" className="bot-card-wrapper">
					<Box component="div" className="bot-card-info-field">
						{isLoadingCurrentBotData ? (
							<ProgressSpinner />
						) : currentBotData != null && currentBot !== null ? (
							<Box
								component="div"
								className="bot-card-info-wrapper small-box-wrapper"
							>
								<Box component="div" className="wrapper-for-all-bot-info">
									<Box component="div" className="bot-info-domain-icon-name">
										<SharedChangeIconComponent
											isChatbotIcon
											setCurrentFile={setCurrentFile}
											iconUrl={
												currentBotData != null
													? currentBotData.imageUrl
													: testIcon
											}
											isCreationChatbot={false}
										/>

										<DetailedBotCardInputsField
											botCustomizationInformation={
												botCustomizationInformationNameDomain
											}
										/>

										<Box component="div" className="checkbox-wrapper">
											<SharedCheckboxItem
												setStateAction={() => {
													setIsUseReferences(!isUseReferences);
												}}
												label="Use references in the response chatbot"
												className="styledConfigModelMenu-cache-checkbox"
												isChecked={isUseReferences}
											/>
										</Box>
									</Box>
									<Box
										component="div"
										className="bot-card-info in-wrapper-table"
									>
										<Box component="div" className="information-about-bot">
											{botCustomizationInformation?.map((cardInfoItem) => {
												return (
													<CardInfoItem
														key={cardInfoItem.title}
														title={cardInfoItem.title}
														value={cardInfoItem.value}
														changeableInput={cardInfoItem.changeableInput}
													/>
												);
											})}
										</Box>

										<Box component="div" className="visualization-bot">
											<SharedPreviewChatbot
												style={styleCssDetailedBotCard}
												isCoderfy
											/>
										</Box>
									</Box>
								</Box>
								<Box component="div" className="title-code">
									Your script:
								</Box>
								<SharedJSCode displayedScript={displayedScript} />
							</Box>
						) : (
							isErrorCurrentBotData && (
								<Box
									component="div"
									className="bot-card-info-title in-wrapper-title"
								>
									{dialogMessage}
								</Box>
							)
						)}
					</Box>
					<Box component="div" className="bot-card-buttons">
						<SaveDeleteBntFieldShared
							handleOpenModalDelete={handleOpenModalDelete}
							handleOpenModalUpdate={handleOpenModalUpdate}
							statusState={currentBot !== null}
							textDeleteBtn="Delete Chatbot"
							textSaveBtn="Save"
							textTooltipSaveBtn="Update current information"
						/>
					</Box>
					{isOpenModal && (
						<SharedModal
							executeFn={() => {
								currentBot !== null && handleDeleteBot(currentBot.id);
							}}
							isDeleteModal
							closeFn={handleCloseModal}
							message={loadingErrorContent}
							isOnlyAlert={false}
							title="Are you sure you want to delete bot?"
							subtitle="This operation cannot be reversed, and you will lose your bots training data"
						/>
					)}
					{isOpenModalAfterSuccessDeleteBot && (
						<SharedModal
							executeFn={() => {}}
							isDeleteModal={true}
							closeFn={() => {
								handleCloseModal();
								navigate('/');
							}}
							message={null}
							isOnlyAlert={true}
							title="Your bot has been deleted!"
						/>
					)}
					{isOpenModalAfterUpdateBot && (
						<SharedModal
							executeFn={() => {}}
							isDeleteModal={false}
							closeFn={() => {
								handleCloseModal();
							}}
							message={loadingErrorContent}
							isOnlyAlert={true}
							title={
								resultOfUpdateBot.isLoading
									? 'Please, wait'
									: resultOfUpdateBot.isError
									? 'Warning'
									: resultOfUpdateBot.isSuccess
									? 'Succeed'
									: ''
							}
						/>
					)}
				</Box>
			</Box>
		</StyledDetailedBotCard>
		// </LayoutAccount>
	);
};
