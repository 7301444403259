/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialChooseTemplate {
	chosenTemplateName: string;
	chosenTemplateId: string;
}

const initialState: IInitialChooseTemplate = {
	chosenTemplateName: '',
	chosenTemplateId: '',
};

const chooseTemplateSlice = createSlice({
	name: 'chooseTemplate',
	initialState,
	reducers: {
		setChosenTemplateName(state, action: PayloadAction<string>) {
			state.chosenTemplateName = action.payload;
			return state;
		},
		setChosenTemplateId(state, action: PayloadAction<string>) {
			state.chosenTemplateId = action.payload;
			return state;
		},
	},
});

export const { setChosenTemplateName, setChosenTemplateId } =
	chooseTemplateSlice.actions;
export const chooseTemplateReducer = chooseTemplateSlice.reducer;
