import React from 'react';
import { CellInput } from './CellInput';
import { StyledCellInputRow } from './CellInputRow.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';

export const CellInputRow: React.FC = () => {
	const inputsList = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	return (
		<StyledCellInputRow theme={theme}>
			{inputsList.map((cell, index) => (
				<CellInput
					key={cell}
					index={index}
					numberOfInput={cell}
					inputsList={inputsList}
				/>
			))}
		</StyledCellInputRow>
	);
};
