import React from 'react';
import { Box } from '@mui/material';
import { StyledNavbar } from './Navbar.style';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as LogoCoderfy } from './pic/logoCorrect.svg';
import { ReactComponent as About } from './pic/about.svg';
import { ReactComponent as Dashboard } from './pic/dashboard.svg';
import { ReactComponent as Union } from './pic/union.svg';
import { ReactComponent as Social } from './pic/social.svg';
// import { ReactComponent as Edit } from './pic/editDark.svg';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { closeMobileMenu } from '../../app/slices/openMobileMenuSlice';
import { NavListItem } from './NavlistItem/NavlistItem';
import { setStatusAboutDropDownList } from '../../app/slices/navbarSlices/aboutOpenCloseDropDownSlice';
import { ToggleTheme } from './ToggleTheme/ToggleTheme';
import { Logout } from './Logout/Logout';
import { SharedMainButton } from '../shared/SharedMainButton/SharedMainButton';
import { hideMenu } from '../../app/slices/showHideMenuSlice';
import { useGetStatisticAboutAccount } from '../../app/hooks/useGetStatisticAboutAccount';
import { setStatusSocialDropDownList } from '../../app/slices/navbarSlices/socialOpenCloseDropDownSlice';
// import { ProgressDots } from '../shared/ProgressDots/ProgressDots';

export interface IDropList {
	value: string;
	link: string;
}

export interface IListLinks {
	value: string;
	link: string;
	logo: JSX.Element;
	isDropDownList: boolean;
	dropList?: IDropList[];
	isDropDownListOpen?: boolean;
	toggleDropDownStatusFunction?: () => void;
}

export const Navbar: React.FC = () => {
	const mobileMenuStatus = useAppSelector(
		(state: RootState) => state.openMobileMenu
	);
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const isShowMenu = useAppSelector((state: RootState) => state.showHideMenu);

	const dispatch = useAppDispatch();

	const isAboutDropDownListOpen = useAppSelector(
		(state: RootState) => state.aboutOpenCloseDropDown
	);
	const isSocialDropDownListOpen = useAppSelector(
		(state: RootState) => state.socialOpenCloseDropDown
	);
	const toggleAboutDropDownList = (): void => {
		dispatch(setStatusAboutDropDownList(!isAboutDropDownListOpen));
	};
	const toggleSocialDropDownList = (): void => {
		dispatch(setStatusSocialDropDownList(!isSocialDropDownListOpen));
	};

	const handleCloseMobileMenu = (): void => {
		dispatch(closeMobileMenu());
	};

	const { getDifferenceBotsCountFirstRender } = useGetStatisticAboutAccount();

	const location = useLocation();

	const links: IListLinks[] = [
		{
			value: 'Dashboard',
			link: '/personal-cabinet',
			logo: <Dashboard />,
			isDropDownList: false,
		},
		{
			value: 'Social Media Integration',
			link: location.pathname,
			logo: <Social />,
			isDropDownList: true,
			dropList: [
				{ value: 'Telegram', link: '/social-integration/telegram' },
				{ value: 'Viber', link: '/social-integration/viber' },
				{ value: 'Messenger', link: '/social-integration/messenger' },
				{ value: 'WhatsApp', link: '/social-integration/whatsapp' },
			],
			isDropDownListOpen: isSocialDropDownListOpen,
			toggleDropDownStatusFunction: toggleSocialDropDownList,
		},
		{
			value: 'Contacts',
			link: '/contacts',
			logo: <Union />,
			isDropDownList: false,
		},
		{
			value: 'FAQ',
			link: location.pathname,
			logo: <About />,
			isDropDownList: true,
			dropList: [
				{ value: 'About', link: '/about' },
				{ value: 'Questions', link: '/about/questions' },
				{ value: 'Niches', link: '/about/niches' },
				{ value: 'CMS & Platforms', link: '/about/platforms' },
				{ value: 'Support', link: '/about/support' },
			],
			isDropDownListOpen: isAboutDropDownListOpen,
			toggleDropDownStatusFunction: toggleAboutDropDownList,
		},
	];

	const theme = {
		background: mobileMenuStatus ? '#0e172d' : '#0e172d5b',
		palette: currentTheme,
		isShowMenu,
	};

	return (
		<StyledNavbar theme={theme}>
			<Box
				component="div"
				className="layout-temporary-background"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					dispatch(hideMenu());
				}}
			></Box>
			<Box component="div" className="navbar-wrapper">
				<Box component="div" className="navbar-navigation">
					<Box component="div" className="navbar-logo">
						<Box component="a" href="/personal-cabinet">
							<LogoCoderfy />
						</Box>
					</Box>
					<Box component="div" className="navbar-generate-button">
						{
							// loadingStatus() ? (
							// 	<ProgressDots />
							// ) :
							getDifferenceBotsCountFirstRender() ? (
								<NavLink
									to={'/chatbot-generator'}
									style={{ margin: '0px' }}
									onClick={() => {
										dispatch(hideMenu());
									}}
								>
									<SharedMainButton
										isDeleteButton={false}
										text="Create bot"
										buttonFunction={() => {}}
										tooltip="You can create your custom chatbot"
										// inProgress={loadingStatus()}
									/>
								</NavLink>
							) : (
								<Box sx={{ width: '90%', margin: '0px' }}>
									<SharedMainButton
										isDeleteButton={false}
										text="Create bot"
										buttonFunction={() => {}}
										isDisabled={true}
									/>
								</Box>
							)
						}
					</Box>

					<Box
						component="div"
						className="navbar-menu-list-links"
						sx={{ display: { xs: 'none', sm: 'flex' } }}
					>
						{links.map((link) => (
							<NavListItem
								key={link.value}
								navListItem={link}
								executeCloseMobileFunction={handleCloseMobileMenu}
							/>
						))}
					</Box>
				</Box>

				<Box component="div" className="additional">
					<NavLink
						to="/plan"
						className="plan-button"
						style={{ cursor: 'pointer' }}
						onClick={() => {
							dispatch(hideMenu());
						}}
					>
						<SharedMainButton
							text="Upgrade plan"
							buttonFunction={() => {}}
							isDeleteButton={false}
							isAccentButton
							tooltip="Your subscription settings"
						/>
					</NavLink>
					<Box component="div" className="logout-hover-class">
						<Logout />
					</Box>
					<Box component="div" className="">
						<ToggleTheme />
					</Box>
				</Box>
			</Box>
		</StyledNavbar>
	);
};

export const MemoizingNavbar = React.memo(Navbar);
