import React from 'react';
import Pagination from '@mui/material/Pagination';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { StyledSharedPagination } from './SharedPagination.style';
import { PaginationItem } from '@mui/material';
import { ReactComponent as Arrow } from '../pic/arrow.svg';
// import { setStartFetchPage } from '../../../../app/slices/trainingBotMenu/paginationValuesTrainingBotMenuSlice';
import { type ActionCreatorWithPayload } from '@reduxjs/toolkit';

interface ISharedPaginationProps {
	viewOnPage: string;
	totalResults: number;
	setValueFn: ActionCreatorWithPayload<number, string>;
	currentPage: number;
}

export const SharedPagination: React.FC<ISharedPaginationProps> = ({
	viewOnPage,
	totalResults,
	setValueFn,
	currentPage,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const countOfPaginationItems = Math.ceil(totalResults / parseInt(viewOnPage));

	const dispatch = useAppDispatch();

	const handleChangeResponseSnippets = (
		event: React.ChangeEvent<unknown>,
		page: number
	): void => {
		const firstPage = page;
		dispatch(setValueFn(firstPage > 0 ? firstPage : 1));
	};

	const theme = {
		currentTheme,
	};

	const PreviousArrow: React.FC = () => (
		<Arrow style={{ transform: 'rotate(180deg)' }} />
	);

	return (
		<StyledSharedPagination theme={theme} className="paginationTableSnippets">
			<Pagination
				onChange={handleChangeResponseSnippets}
				count={
					!Number.isNaN(countOfPaginationItems) ? countOfPaginationItems : 1
				}
				page={currentPage}
				renderItem={(item) => (
					<PaginationItem
						slots={{ previous: PreviousArrow, next: Arrow }}
						{...item}
					/>
				)}
			/>
		</StyledSharedPagination>
	);
};
