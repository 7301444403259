import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const openExportSnippetsTrainingBotMenuSlice = createSlice({
	name: 'openExportSnippetsTrainingBotMenu',
	initialState: false,
	reducers: {
		setOpenModalExportSnippetsStatus(state, action: PayloadAction<boolean>) {
			state = action.payload;
			return state;
		},
	},
});

export const { setOpenModalExportSnippetsStatus } =
	openExportSnippetsTrainingBotMenuSlice.actions;
export const openExportSnippetsTrainingBotMenuReducer =
	openExportSnippetsTrainingBotMenuSlice.reducer;
