import styled from 'styled-components';

export const StyledSharedSearchBar = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;

	& .search-input {
		position: relative;
		display: flex;

		& .search-input-action-button {
			position: absolute;
			top: 15px;
			right: 15px;
			display: flex;
			align-items: center;

			/* & div { */
			& button {
				background: none;
				border: none;
				/* cursor: pointer; */
				cursor: auto;

				& svg {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	& .buttons-field {
		display: flex;
		gap: 24px;

		& > div {
			width: 131px;
		}
	}

	@media screen and (min-width: 1500px) {
		& .search-input {
			& .search-input-action-button {
				top: 25px;
			}
		}
	}

	@media screen and (max-width: 1250px) {
		flex-direction: column;
	}

	@media screen and (max-width: 350px) {
		& .buttons-field {
			flex-direction: column;
		}
	}
`;
