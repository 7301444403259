// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusSocialIntegrateSlice = createSlice({
	name: 'checkEmptyStatusSocialIntegrate',
	initialState,
	reducers: {
		setEmptyStatusSocialIntegrate(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusSocialIntegrate } =
	checkEmptyStatusSocialIntegrateSlice.actions;
export const checkEmptyStatusSocialIntegrateReducer =
	checkEmptyStatusSocialIntegrateSlice.reducer;
