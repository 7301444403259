// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusForImportUrlSlice = createSlice({
	name: 'checkEmptyStatusForImportUrl',
	initialState,
	reducers: {
		setEmptyStatusForImportUrl(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForImportUrl } =
	checkEmptyStatusForImportUrlSlice.actions;
export const checkEmptyStatusForImportUrlReducer =
	checkEmptyStatusForImportUrlSlice.reducer;
