import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialOpenModalVectoriseCurrentSnippetsState {
	isOpenModalAskVectorise: boolean;
	isOpenModalAfterVectoriseOnlyAlert: boolean;
}

const initialState: IInitialOpenModalVectoriseCurrentSnippetsState = {
	isOpenModalAfterVectoriseOnlyAlert: false,
	isOpenModalAskVectorise: true,
};

const openVectoriseSnippetsTrainingBotMenuSlice = createSlice({
	name: 'openVectoriseSnippetsTrainingBotMenu',
	initialState,
	reducers: {
		setOpenModalAfterVectoriseOnlyAlertStatus(
			state,
			action: PayloadAction<boolean>
		) {
			state.isOpenModalAfterVectoriseOnlyAlert = action.payload;
			return state;
		},
		setOpenModalAskVectoriseStatus(state, action: PayloadAction<boolean>) {
			state.isOpenModalAskVectorise = action.payload;
			return state;
		},
	},
});

export const {
	setOpenModalAfterVectoriseOnlyAlertStatus,
	setOpenModalAskVectoriseStatus,
} = openVectoriseSnippetsTrainingBotMenuSlice.actions;
export const openVectoriseSnippetsTrainingBotMenuReducer =
	openVectoriseSnippetsTrainingBotMenuSlice.reducer;
