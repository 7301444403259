import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { InputsFieldWrapper } from '../../shared/Input/InputsFieldWrapper/InputsFieldWrapper';
import { SharedPasswordInput } from '../../shared/Input/SharedPasswordInput/SharedPasswordInput';

export const InputsFieldReset: React.FC = () => {
	const isResetPasswordButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForReset
	);
	const isValidInputsStatusReset = useAppSelector(
		(state: RootState) => state.isValidResetInputValue
	).isValidPasswordReset;

	return (
		<InputsFieldWrapper>
			<SharedPasswordInput
				withIcons={false}
				isEmpty={isResetPasswordButtonPressed}
				uniqueNameOfInput="password-reset"
				isDarkBack={true}
				isValid={isValidInputsStatusReset}
			/>
		</InputsFieldWrapper>
	);
};
