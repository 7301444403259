import styled from 'styled-components';

export const StyledSharedDesktopTable = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	color: ${(props) => props.theme.currentTheme.mainFontColor};

	& .grid-table-desktop-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		/* width: max-content; */

		& .title-wrapper {
			display: flex;
			/* justify-content: center; */
			justify-content: space-between;
			padding: 0px 16px;
			border-radius: 8px;
			background: ${(props) =>
				props.theme.currentTheme.accentHighlightColorForTable};

			& .title-grid-element {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				padding: 10px;
				font-size: 16px;
				line-height: 140%;
			}

			& .bool-element {
				display: flex;
				justify-content: center;
			}
		}

		& .grid-card-wrapper {
			display: flex;
			/* justify-content: center; */
			justify-content: space-between;
			padding: 10px 16px;
			border-bottom: 1px solid
				${(props) => props.theme.currentTheme.accentHighlightColorForTable};

			& .grid-card-item {
				padding: 10px;
				font-size: 16px;
				line-height: 140%;
				word-break: break-word;
				/* For SHOW MORE */
				/* max-height: 500px;
				overflow: auto; */
			}

			&:hover {
				background: ${(props) => props.theme.hoverBackgroundColor};
			}

			& .show-more-less-clickable {
				font-weight: 600;
				text-decoration: none;
				color: #1592ec;
			}

			& .actions-buttons {
				display: flex;
				gap: 1rem;
				align-items: center;

				& > svg {
					cursor: pointer;
				}
			}

			& .grid-item {
				/* flex: 1; */
				display: flex;
				justify-content: flex-start;
				min-width: 80px;
				align-items: center;
				text-align: left;
				gap: 0.5rem;
			}

			& .bool-element {
				display: flex;
				justify-content: center;
			}
		}

		/* & .domain-grid-item,
		.bot-name-item,
		.created-item,
		.actions-grid-item {
			display: flex;
			justify-content: flex-start;
			min-width: 80px;
			align-items: center;
			text-align: left;
		} */

		/* & .domain-grid-item {
			flex: 3;
			min-width: 160px;
			hyphens: auto;
			word-wrap: break-word;
		}
		& .bot-name-item {
			flex: 2;
			hyphens: auto;
			word-wrap: break-word;
		}
		& .created-item {
			flex: 2;
		} */

		& .actions-title-grid-item {
			display: flex;
			justify-content: center;
			text-align: center;
		}

		& .actions-grid-item {
			/* flex: 1; */
			width: 120px;
			min-width: 120px;
			justify-content: center;
		}

		/* & .url-menu {
			display: flex;
			align-items: center;
			gap: 0.3rem;

			& svg {
				width: 20px;
				height: 20px;
			}

			& button {
				border: none;
				background: none;
				cursor: pointer;
			}
		} */

		/* & .cashed-item,
		.pushed-item,
		.vectorized-item {
			flex: 2;

			display: flex;
			justify-content: center;
			align-items: center;
		} */
	}

	@media screen and (max-width: 1500px) {
		& .grid-table-desktop-container {
			& .title-wrapper {
				& .title-snippet {
					font-size: 16px;
				}
			}
			& .grid-card-wrapper {
				& .grid-card-item {
					font-size: 16px;
				}
			}
		}
	}
`;
