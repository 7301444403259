// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const checkEmptyStatusForOpenAIMenuInputsSlice = createSlice({
	name: 'checkEmptyStatusForOpenAIMenuInputs',
	initialState: false,
	reducers: {
		setEmptyStatusForCheckConfigOpenAIMenu(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForCheckConfigOpenAIMenu } =
	checkEmptyStatusForOpenAIMenuInputsSlice.actions;
export const checkEmptyStatusForOpenAIMenuInputsReducer =
	checkEmptyStatusForOpenAIMenuInputsSlice.reducer;
