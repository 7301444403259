// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const isConfirmEmailSlice = createSlice({
	name: 'isConfirmEmail',
	initialState,
	reducers: {
		setConfirmEmailStatus(state, action: PayloadAction<boolean>) {
			state = action.payload;
			return state;
		},
	},
});

export const { setConfirmEmailStatus } = isConfirmEmailSlice.actions;
export const isConfirmEmailReducer = isConfirmEmailSlice.reducer;
