import styled from 'styled-components';

export const StyledModalImportUrl = styled.div`
	width: 100%;

	& .importUrlComponent-wrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding: 30px 50px;
		width: 100%;

		& .importUrl-title-subtitle {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			font-style: normal;
			width: 100%;

			& .importUrl-title {
				font-size: 24px;
				font-weight: 600;
				line-height: 145%;
			}

			& .importUrl-subtitle {
				font-size: 18px;
				font-weight: 400;
				line-height: 27px;
			}
		}

		& .importUrl-input-url {
			width: 100%;
			& input {
				width: 100% !important;
			}
		}

		& .importUrl-button-field {
			display: flex;
			gap: 1rem;

			& > div {
				width: 100% !important;
				& button {
					width: 100% !important;
				}
			}
		}
	}

	@media screen and (max-width: 650px) {
		& .importUrlComponent-wrapper {
			padding: 16px;
			& .importUrl-title-subtitle {
				text-align: center;
			}
		}
	}
`;
