import React from 'react';
import { Tooltip, tooltipClasses, type TooltipProps } from '@mui/material';
import styled from 'styled-components';

const InnerTooltip: React.FC<TooltipProps> = ({
	className,
	...props
}: TooltipProps) => {
	return (
		<Tooltip
			{...props}
			classes={{ popper: className }}
			arrow
			enterDelay={300}
			enterNextDelay={300}
			PopperProps={{
				disablePortal: true,
			}}
		/>
	);
};

export const CustomTooltip = styled(InnerTooltip)((theme) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: '#5E17EB',
		// color: theme.theme.currentTheme.accentPrimaryColor,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#5E17EB',
		// backgroundColor: theme.theme.currentTheme.accentPrimaryColor,
		color: '#fff',
		border: 'none',
		borderRadius: '20px',
		padding: '0.5rem 1rem',
		textAlign: 'center',
		fontFamily: 'Nunito',
		fontSize: '14px',
	},
}));
