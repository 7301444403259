import React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { StyledContainerAbout } from './ContainerAbout.style';

interface IContainerAboutProps {
	children: React.ReactElement;
}

export const ContainerAbout: React.FC<IContainerAboutProps> = ({
	children,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	return (
		<StyledContainerAbout theme={theme} className="container-about-wrapper">
			{children}
		</StyledContainerAbout>
	);
};
