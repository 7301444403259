// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import { useDeleteSnippetMutation } from '../../../app/api/chatbot.api';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { setDeleteSnippetMessage } from '../../../app/slices/trainingBotMenu/deleteSnippetMessageTrainingBotMenuSlice';
import { setOpenModalDeleteCurrentSnippetStatus } from '../../../app/slices/trainingBotMenu/openModal/openDeleteCurrentSnippetTrainingBotMenuSlice';

export const useDeleteCurrentSnippet = (): ((
	snippetId: string
) => Promise<void>) => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const [deleteCurrentSnippet, resultOfDeletingCurrentSnippet] =
		useDeleteSnippetMutation();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (resultOfDeletingCurrentSnippet.isError) {
			dispatch(setDeleteSnippetMessage('error'));
		} else if (resultOfDeletingCurrentSnippet.isLoading) {
			dispatch(setDeleteSnippetMessage('loading'));
		} else if (resultOfDeletingCurrentSnippet.isSuccess) {
			dispatch(setDeleteSnippetMessage('success'));
		}
	}, [resultOfDeletingCurrentSnippet]);

	useEffect(() => {
		dispatch(setDeleteSnippetMessage(''));
	}, []);

	return async (snippetId: string): Promise<void> => {
		const deleteSnippetBody = {
			token,
			id: snippetId,
		};
		try {
			const result = await deleteCurrentSnippet(deleteSnippetBody);
			if ('data' in result) {
				dispatch(setDeleteSnippetMessage('success'));
				dispatch(setOpenModalDeleteCurrentSnippetStatus(false));
				dispatch(setDeleteSnippetMessage(''));
			} else {
				dispatch(setDeleteSnippetMessage('error'));
			}
		} catch (error) {
			dispatch(setDeleteSnippetMessage('error'));
		}
	};
};
