// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledNavListItem = styled.div`
	width: 100%;
	position: relative;

	& a {
		display: flex;
		justify-content: space-between;
		color: ${(props) => props.theme.colorLinks};
		font-weight: ${(props) => props.theme.weight};
		font-size: 16px;
		text-decoration: none;
		transition: all 0.4s;
		border-radius: 20px;

		&:hover {
			background: #24282e;
		}

		& > div {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 18px;
			padding: 10px;
			/* padding: 16px; */
			padding-right: 10px;
		}

		& .nav-list-icon {
			display: flex;
			justify-content: center;
			align-items: center;

			& svg {
				height: 100%;
				width: 20px;
			}
		}
	}

	& .nav-list-drop {
		& > button {
			width: 20px;
			/* width: 20%; */
			display: flex;
			justify-content: center;
			align-items: center;
			background: none;
			border: none;
			cursor: pointer;
			transition: all 0.4s;

			&:hover {
				transform: scale(1.05);
			}
		}
	}

	& .nav-list-drop-list {
		transition: all 0.4s;
		display: flex;
		flex-direction: column;
		padding-left: 34px;

		& > a {
			padding: 16px;
			font-weight: ${(props) => props.theme.weight};
		}
	}

	@media screen and (max-width: 1500px) {
		& a {
			font-size: 14px;
			&:hover {
			}

			& > div {
				gap: 10px;
				padding: 0.5rem;
			}

			& .nav-list-icon {
			}
		}

		& .nav-list-drop {
			& > button {
				width: 20px;
				/* width: 20%; */
				display: flex;
				justify-content: center;
				align-items: center;
				background: none;
				border: none;
				cursor: pointer;
				transition: all 0.4s;

				&:hover {
				}
			}
		}

		& .nav-list-drop-list {
			padding-left: 34px;

			& > a {
				padding: 8px;
			}
		}
	}
`;
