import React, { useState, useEffect } from 'react';
import { InputsFieldForgot } from './InputsFieldForgot/InputsFieldForgot';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { setEmptyStatusForForgot } from '../../app/slices/inputsForgot/checkEmptyStatusForForgotSlice';
import { useForgotPasswordMutation } from '../../app/api/chatbot.api';
import { SharedOneActionComponent } from '../shared/SharedOneActionComponent/SharedOneActionComponent';
import {
	type IResponseDataWithError,
	type IResponseForgotPasswordSuccess,
} from '../../app/api/interfaces/interfaces';
import { ProgressSpinner } from '../shared/ProgressSpinner/ProgressSpinner';
import { Box } from '@mui/material';

interface IDataResponseForgot {
	data: IResponseForgotPasswordSuccess;
}

export const ForgotMenu: React.FC = () => {
	const forgotInputValue = useAppSelector(
		(state: RootState) => state.forgotInputValue
	);
	const validInputStatus = useAppSelector(
		(state: RootState) => state.isValidForgotInputValue
	);

	const [forgotPassword, resultOfSendForgot] = useForgotPasswordMutation();
	const [errorStatus, setErrorStatus] = useState('');
	const [successStatus, setSuccessStatus] = useState('');
	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const forgotData = {
		email: forgotInputValue.emailForgot,
		domain: window.location.host,
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		setErrorStatus('');
		dispatch(setEmptyStatusForForgot(false));
		// console.log(window.location.host);
	}, []);

	const handleForgotPassword = (): void => {
		dispatch(setEmptyStatusForForgot(true));
		if (
			forgotInputValue.emailForgot.length > 0 &&
			validInputStatus.isValidEmailForgot
		) {
			// console.log(forgotData);

			forgotPassword(forgotData)
				.then((response: IDataResponseForgot | IResponseDataWithError) => {
					// console.log('response:', response);
					if ('data' in response) {
						// console.log('success');
						setSuccessStatus(response.data.message);
					} else if ('error' in response) {
						// console.log('error');
						// console.log(response.error.data.error);
						setErrorStatus(response.error.data.error);
					} else {
						console.log('');
						// console.log('empty response');
					}
				})
				.catch((error) => {
					console.log(error);
					setErrorStatus('Server error: Failed to fetch');
				});
		}
	};

	useEffect(() => {
		// console.log(resultOfSendForgot.status);
		resultOfSendForgot.isLoading && setLoadingErrorContent(<ProgressSpinner />);
		resultOfSendForgot.isError &&
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{errorStatus}
				</Box>
			);
	}, [resultOfSendForgot, errorStatus]);

	useEffect(() => {
		// console.log(successStatus);
		resultOfSendForgot.isSuccess &&
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{successStatus}
				</Box>
			);
	}, [resultOfSendForgot, successStatus]);

	return (
		<SharedOneActionComponent
			handleExecuteFunction={handleForgotPassword}
			inputsFieldForgot={<InputsFieldForgot />}
			title="Don`t remember your password?"
			subtitle="Just write your e-mail and we will send you recovery instructions"
			textButton="Send e-mail"
			loadingErrorContent={loadingErrorContent}
			navLink="/registration"
			isForgotMenu={true}
		/>
	);
};
