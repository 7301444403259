// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: number = 0;

const currentStepGeneratorSlice = createSlice({
	name: 'currentStepGenerator',
	initialState,
	reducers: {
		setCurrentStepGenerator(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setCurrentStepGenerator } = currentStepGeneratorSlice.actions;
export const currentStepGeneratorReducer = currentStepGeneratorSlice.reducer;
