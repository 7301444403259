/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialPaginationValuesTrainingBotMenu {
	totalResultsSnippets: number;
	viewOnPage: string;
	startFetchPage: number;
	finishFetchPage: number;
	isValidViewOnPage: boolean;
}

const initialState: IInitialPaginationValuesTrainingBotMenu = {
	totalResultsSnippets: 0,
	viewOnPage: '10',
	startFetchPage: 1,
	finishFetchPage: 10,
	isValidViewOnPage: true,
};

const paginationValuesTrainingBotMenuSlice = createSlice({
	name: 'paginationValuesTrainingBotMenu',
	initialState,
	reducers: {
		setTotalResultsSnippets(state, action: PayloadAction<number>) {
			state.totalResultsSnippets = action.payload;
			return state;
		},
		setViewOnPage(state, action: PayloadAction<string>) {
			state.viewOnPage = action.payload;
			return state;
		},
		setStartFetchPage(state, action: PayloadAction<number>) {
			state.startFetchPage = action.payload;
			return state;
		},
		setFinishFetchPage(state, action: PayloadAction<number>) {
			state.finishFetchPage = action.payload;
			return state;
		},

		setIsValidViewOnPage(state, action: PayloadAction<boolean>) {
			state.isValidViewOnPage = action.payload;
			return state;
		},
	},
});

export const {
	setTotalResultsSnippets,
	setViewOnPage,
	setStartFetchPage,
	setFinishFetchPage,
	setIsValidViewOnPage,
} = paginationValuesTrainingBotMenuSlice.actions;
export const paginationValuesTrainingBotMenuReducer =
	paginationValuesTrainingBotMenuSlice.reducer;
