// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = true;

const isCurrentFondySlice = createSlice({
	name: 'isCurrentFondy',
	initialState,
	reducers: {
		setCurrentPayment(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setCurrentPayment } = isCurrentFondySlice.actions;
export const isCurrentFondyReducer = isCurrentFondySlice.reducer;
