/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
	useAppSelector,
	useGetPersonalInformation,
} from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { SharedTransparentButton } from '../../../shared/SharedTransparentButton/SharedTransparentButton';
import { ProgressDots } from '../../../shared/ProgressDots/ProgressDots';
import { StyledPersonalUserCard } from './PersonalUserCard.style';

export const PersonalUserCard: React.FC = () => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);
	const personalInformationFromApi = useAppSelector(
		(state: RootState) => state.answerApiPersonalInformation
	);

	const { personalInformationData, isLoading, isError } =
		useGetPersonalInformation(token);

	const ResponseDataLoadingError: React.FC<{ responseData: string }> = ({
		responseData,
	}) => {
		if (isLoading) {
			return <ProgressDots />;
		} else if (isError) {
			console.log(personalInformationData);
			return <>Error</>;
		} else {
			return <Box>{responseData}</Box>;
		}
	};

	return (
		<StyledPersonalUserCard className="personal-cabinet-info-wrapper small-box-wrapper">
			<Box
				component="div"
				className="personal-cabinet-info-title in-wrapper-title"
			>
				Personal information
			</Box>
			<Box component="div" className="personal-cabinet-info in-wrapper-table">
				<Box>
					<Box className="personal-cabinet-info-article">Username:</Box>
					<Box component="span" className="personal-cabinet-info-value">
						<ResponseDataLoadingError
							responseData={personalInformationFromApi.username}
						/>
					</Box>
				</Box>
				<Box>
					<Box className="personal-cabinet-info-article">Surname:</Box>
					<Box component="span" className="personal-cabinet-info-value">
						<ResponseDataLoadingError
							responseData={personalInformationFromApi.surname}
						/>
					</Box>
				</Box>
				<Box>
					<Box className="personal-cabinet-info-article">Email:</Box>
					<Box component="span" className="personal-cabinet-info-value">
						<ResponseDataLoadingError
							responseData={personalInformationFromApi.email}
						/>
					</Box>
				</Box>
			</Box>
			<Box className="personal-cabinet-info-button">
				<NavLink to="/change-info" style={{ textDecoration: 'none' }}>
					<SharedTransparentButton
						buttonFunction={() => {}}
						text="Change information"
						tooltip="Your personal information. You can change the basic data or delete the account"
					/>
				</NavLink>
			</Box>
		</StyledPersonalUserCard>
	);
};
