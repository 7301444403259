// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface ICreateUserDataResponse {
	// username:	string | null
	// surname:	string | null
	// domain:	string | null
	// email:	string | null
	// password:	string | null
	isSuccess: boolean;
	errorMessage: string;
}

const initialState = {
	// username: null,
	// surname: null,
	// domain: null,
	// email: null,
	// password: null,
	isSuccess: false,
	errorMessage: '',
};

const answerApiRegistrationSlice = createSlice({
	name: 'answerApiRegistration',
	initialState,
	reducers: {
		setAnswerApiRegistrationStatus(state, action) {
			state.isSuccess = action.payload;
			return state;
		},
		setAnswerApiRegistrationErrorMessage(state, action) {
			state.errorMessage = action.payload;
			return state;
		},
	},
});

export const {
	setAnswerApiRegistrationStatus,
	setAnswerApiRegistrationErrorMessage,
} = answerApiRegistrationSlice.actions;
export const answerApiRegistrationReducer = answerApiRegistrationSlice.reducer;
