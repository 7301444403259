// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledAdditionalConfirmationField = styled.div`
	color: #fff;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	justify-content: center;
	align-items: center;

	& .button-resend-code {
		display: flex;
		gap: 0.55rem;
		background: transparent;
		border: none;
		color: ${(props) => (props.theme.active === true ? '#fff' : '#a5a5a5')};

		font-size: 20px;
		cursor: ${(props) => (props.theme.active === true ? 'pointer' : 'auto')};
		transition: all 0.4s;
		text-decoration: ${(props) =>
			props.theme.active === true ? 'underline' : 'none'};
		font-weight: 700;
		line-height: 2rem;

		&:hover {
			transform: ${(props) =>
				props.theme.active === true ? 'scale(1.05)' : 'scale(1)'};
		}
		&:active {
			transform: ${(props) =>
				props.theme.active === true ? 'scale(.95)' : 'scale(1)'};
			color: ${(props) =>
				props.theme.active === true ? '#5e17eb' : '#a5a5a5'};
		}
	}
`;
