import styled from 'styled-components';

export const StyledTokenAlertMessage = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	display: flex;
	gap: 16px;
	padding: 0px 0px 0px 406px;

	& .tokenAlert-msg-box {
		width: max-content;
		padding: 16px 24px;
		border-radius: 8px;
		border: 1px solid
			${(props) => props.theme.currentTheme.coderfyAlertPrimaryColor};
		background: ${(props) =>
			props.theme.currentTheme.coderfyAlertSecondaryColor};
		color: ${(props) => props.theme.currentTheme.mainFontColor};
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;

		& .tokenAlert-msg-article {
			width: 472px;
		}
	}

	@media screen and (max-width: 1500px) {
		padding: 0px;
		top: 36px;
		left: -16px;
	}

	@media screen and (max-width: 800px) {
		position: relative;
		top: 0px;
		left: 0px;

		& .tokenAlert-msg-box {
			width: 100%;

			& .tokenAlert-msg-article {
				width: 100%;
			}
		}
	}
`;
