import React, { useState } from 'react';
import { Box } from '@mui/material';
import { StyledSharedWrappedInputWithExtraContent } from './SharedWrappedInputWithExtraContent.style';
import { WrappedInput } from '../WrappedInput/WrappedInput';
import { ReactComponent as Help } from './pic/help.svg';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { ReactComponent as PolygonBlack } from './pic/polygonBlack.svg';
import { ReactComponent as PolygonBlackBorder } from './pic/polygonWhiteBorderForBlack.svg';
import { ReactComponent as PolygonWhite } from './pic/polygonWhite.svg';
import { ReactComponent as PolygonWhiteBorder } from './pic/polygonWhiteBorder.svg';

interface IInputProps {
	type: string;
	placeholder: string;
	value?: string;
	isEmpty: boolean;
	uniqueNameOfInput: string;
	target?: string;
	tip?: string;
	isDarkBack: boolean;
	isDisabled?: boolean;

	isValid: boolean;
}

export const SharedWrappedInputWithExtraContent: React.FC<IInputProps> = ({
	type,
	placeholder,
	isEmpty,
	uniqueNameOfInput,
	target,
	tip,
	isDarkBack,
	value,
	isValid,
	isDisabled,
}: IInputProps) => {
	const [isOpenQuestionTextField, setIsOpenQuestionTextField] = useState(false);

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		display: isOpenQuestionTextField,
		isQuestion: target !== undefined,
		colorAlert: isDarkBack ? '#edc2c2' : 'red',
		currentTheme,
	};

	const handleToggleQuestionTextField = (): void => {
		setIsOpenQuestionTextField(!isOpenQuestionTextField);
	};
	const handleOpenQuestionTextField = (): void => {
		setIsOpenQuestionTextField(true);
	};
	const handleCloseQuestionTextField = (): void => {
		setIsOpenQuestionTextField(false);
	};

	return (
		<StyledSharedWrappedInputWithExtraContent
			theme={theme}
			onMouseLeave={handleCloseQuestionTextField}
		>
			<WrappedInput
				type={type}
				placeholder={placeholder}
				isEmpty={isEmpty}
				isDarkBack
				uniqueNameOfInput={uniqueNameOfInput}
				value={value}
				isValid={isValid}
				isDisabled={isDisabled === true}
			/>
			<Box className="left-input-image" component="div">
				{target !== undefined || tip !== undefined ? (
					<Box component="div" className="question-icon">
						<Box
							component="div"
							onMouseEnter={handleOpenQuestionTextField}
							onClick={handleToggleQuestionTextField}
						>
							<Help />
						</Box>
						<Box component="div" className="question-icon-text-field">
							<Box>
								{tip !== undefined
									? tip
									: `You can get more information about ${placeholder} at the`}
							</Box>
							{target === 'empty' ? (
								<></>
							) : (
								<a href={target} target="blank">
									link
								</a>
							)}
							<Box component="div" className="hidden-polygon">
								<Box component="div" className="hidden-area">
									{currentTheme.mainRootBackground === '#EFEFEF' ? (
										<PolygonWhite />
									) : (
										<PolygonBlack />
									)}
								</Box>
								<Box component="div" className="hidden-border">
									{currentTheme.mainRootBackground === '#EFEFEF' ? (
										<PolygonWhiteBorder />
									) : (
										<PolygonBlackBorder />
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				) : (
					<></>
				)}
			</Box>
		</StyledSharedWrappedInputWithExtraContent>
	);
};
