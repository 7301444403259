import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { type IThemeStyledComponents } from '../SharedInput';
import { StyledCustomAutocomplete } from './CustomAutocomplete.stlye';

interface ICustomAutocompleteProps {
	params?: string[] | undefined;
	placeholder: string;
	inputFunction: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
	value?: string;
	theme?: IThemeStyledComponents;
	uniqueNameOfInput: string;
	disabled?: boolean;
}

export const CustomAutocomplete: React.FC<ICustomAutocompleteProps> = ({
	params,
	inputFunction,
	placeholder,
	theme,
	uniqueNameOfInput,
	value,
	disabled,
}) => {
	const emptyOptions = ['no options'];

	const autocompleteInputFunction = (
		event: React.SyntheticEvent<Element, Event>
	): void => {
		try {
			if (event.currentTarget.textContent !== null) {
				inputFunction(event.currentTarget.textContent);
			} else {
				inputFunction('');
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<StyledCustomAutocomplete theme={theme} className="customAutocomplete">
			<Autocomplete
				disablePortal
				id={uniqueNameOfInput}
				options={params !== undefined ? params : emptyOptions}
				sx={{
					width: 300,
				}}
				renderInput={(params) => (
					<TextField {...params} placeholder={placeholder} />
				)}
				onChange={autocompleteInputFunction}
				fullWidth={true}
				className="shared-input"
				// eslint-disable-next-line no-shadow-restricted-names
				isOptionEqualToValue={(undefined, value) => value !== undefined}
				value={value}
				disabled={disabled === true}
			/>
		</StyledCustomAutocomplete>
	);
};
