import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useGetStatisticAboutAccount } from '../../../../app/hooks/useGetStatisticAboutAccount';
import { StyledStatisticCard } from './StatisticCard.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { CurrentStatisticItem } from './CurrentStatisticItem/CurrentStatisticItem';
import { CurrentPlanComponent } from './CurrentPlanCpmponent/CurrentPlanComponent';
import { useGetSubscriptionBySubId } from '../../../../app/hooks/paymentHooks/useGetSubscriptionBySubId';
import { useGetProductsChatbotQuery } from '../../../../app/api/chatbot.api';
import { type IGetProductsChatbotResponse } from '../../../../app/api/interfaces/fondy/getProductsChatbot.interface';

export const StatisticCard: React.FC = () => {
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const isCurrentFondy = useAppSelector(
		(state: RootState) => state.isCurrentFondy
	);

	const statistic = useGetStatisticAboutAccount();
	const { errorStatus, successStatus, getAllStatisticData } = statistic;

	const allStatisticData = getAllStatisticData();

	const { returnObject, updateSubscriptionId } = useGetSubscriptionBySubId();

	const [currentPlanName, setCurrentPlanName] = useState<string | JSX.Element>(
		''
	);
	const [expireDate, setExpireDate] = useState<string | JSX.Element>('');

	const [fondyProductsData, setFondyProductsData] = useState<
		IGetProductsChatbotResponse | undefined
	>();

	const {
		data: getFondyProductsData,
		isError: isErrorGetProductsFondy,
		isLoading: isLoadingGetProductsFondy,
		isSuccess: isSuccessGetProductsFondy,
		// refetch: refetchGetFondyProductsQuery,
	} = useGetProductsChatbotQuery({ token });

	useEffect(() => {
		if (getFondyProductsData !== undefined) {
			setFondyProductsData(getFondyProductsData);
		}
	}, [getFondyProductsData]);

	useEffect(() => {
		if (allStatisticData != null && fondyProductsData !== undefined) {
			updateSubscriptionId(allStatisticData.subscriptionId);
			if (isCurrentFondy && allStatisticData.subscriptionDueDate !== null) {
				setExpireDate(allStatisticData.subscriptionDueDate.toString());
				const currentPlan = fondyProductsData.data.find(
					(plan) => plan.id.toString() === allStatisticData.subscriptionId
				);
				currentPlan !== undefined && setCurrentPlanName(currentPlan.name);
			}
		}
	}, [allStatisticData, fondyProductsData]);

	useEffect(() => {
		if (
			!isCurrentFondy &&
			typeof returnObject.currentPlanName === 'string' &&
			Boolean(returnObject.currentPlanName)
		) {
			setCurrentPlanName(returnObject.currentPlanName);
			setExpireDate(returnObject.expireDate);
		}
	}, [returnObject]);

	const theme = { currentTheme };

	return (
		<StyledStatisticCard className="statisticCard-info-wrapper" theme={theme}>
			<Box component="div" className="statisticCard-title in-wrapper-title">
				Subscription status
			</Box>
			{errorStatus() ? (
				<>Something went wrong</>
			) : // ) : loadingStatus() ? (
			// 	<ProgressSpinner />
			successStatus() ? (
				<Box component="div" className="statisticCard-info in-wrapper-table">
					{isCurrentFondy ? (
						<CurrentPlanComponent
							currentPlan={currentPlanName}
							expireDate={expireDate}
							isError={isErrorGetProductsFondy}
							isLoading={isLoadingGetProductsFondy}
							isSuccess={isSuccessGetProductsFondy}
						/>
					) : (
						<CurrentPlanComponent
							currentPlan={currentPlanName}
							expireDate={expireDate}
							isError={returnObject.isErrorGetSubscriptionById}
							isLoading={returnObject.isLoadingGetSubscriptionById}
							isSuccess={returnObject.isSuccessGetSubscriptionById}
						/>
					)}
					<Box className="statisticCard-statistic-info">
						<Box>
							<CurrentStatisticItem
								usedCount={allStatisticData?.botsCount}
								maxCount={allStatisticData?.maxBotsAllowedToCreate}
								nameCurrentStatisticItem="Bots"
							/>
							<CurrentStatisticItem
								usedCount={allStatisticData?.requestsCount}
								maxCount={allStatisticData?.requestsPerMonth}
								nameCurrentStatisticItem="Requests"
							/>
						</Box>
						<Box>
							<CurrentStatisticItem
								usedCount={allStatisticData?.snippetsCount}
								maxCount={allStatisticData?.snippetsAvailable}
								nameCurrentStatisticItem="Snippets"
							/>
							{/* <CurrentStatisticItem usedCount={0} maxCount={2} nameCurrentStatisticItem='Pages'/> */}
						</Box>
					</Box>
				</Box>
			) : (
				<></>
			)}
		</StyledStatisticCard>
	);
};
