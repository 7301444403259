import React from 'react';
import { StyledSharedMobileTable } from './SharedMobileTable.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { GridCard } from './GridCard';
import { type IIDataBotRows } from '../../../PersonalCabinetMenu/Cards/ProfilesBotsCard';

interface ISharedMobileTableProps {
	rows: IIDataBotRows[];
}

export const SharedMobileTable: React.FC<ISharedMobileTableProps> = ({
	rows,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = { currentTheme };

	return (
		<StyledSharedMobileTable theme={theme}>
			{rows.map((row, index) => (
				<GridCard key={index} row={row} />
			))}
		</StyledSharedMobileTable>
	);
};
