import styled from 'styled-components';

export const StyledContainerAbout = styled.div`
	padding: 32px 22px;
	background: linear-gradient(
				${(props) => props.theme.currentTheme.cardsBackground} 0 0
			)
			padding-box,
		linear-gradient(to right, #bfd5ff, #dee9ff) border-box;
	border: 1px solid transparent;
	border-radius: 20px;
`;
