// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

interface IInitialSocialIntegrateInputsValidState {
	isValidSocialIntegrateInputTelegram: boolean;
	isValidSocialIntegrateInputViber: boolean;
	isValidSocialIntegrateInputMessenger: boolean;
	isValidSocialIntegrateInputWatsApp: boolean;
}

const initialState: IInitialSocialIntegrateInputsValidState = {
	isValidSocialIntegrateInputTelegram: false,
	isValidSocialIntegrateInputViber: false,
	isValidSocialIntegrateInputMessenger: false,
	isValidSocialIntegrateInputWatsApp: false,
};

const isValidSocialIntegrateInputsSlice = createSlice({
	name: 'isValidSocialIntegrateInputs',
	initialState,
	reducers: {
		setValidStatusSocialIntegrateTelegram(state, action) {
			state.isValidSocialIntegrateInputTelegram = action.payload;
			return state;
		},
		setValidStatusSocialIntegrateViber(state, action) {
			state.isValidSocialIntegrateInputViber = action.payload;
			return state;
		},
		setValidStatusSocialIntegrateMessenger(state, action) {
			state.isValidSocialIntegrateInputMessenger = action.payload;
			return state;
		},
		setValidStatusSocialIntegrateWatsApp(state, action) {
			state.isValidSocialIntegrateInputWatsApp = action.payload;
			return state;
		},
	},
});

export const {
	setValidStatusSocialIntegrateTelegram,
	setValidStatusSocialIntegrateViber,
	setValidStatusSocialIntegrateMessenger,
	setValidStatusSocialIntegrateWatsApp,
} = isValidSocialIntegrateInputsSlice.actions;
export const isValidSocialIntegrateInputsReducer =
	isValidSocialIntegrateInputsSlice.reducer;
