import styled from 'styled-components';

export const StyledSaveDeleteBntFieldShared = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;

	& > div {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		width: 100%;

		& > a {
			width: 100%;
		}
	}

	@media screen and (max-width: 1250px) {
		flex-direction: column;
		& > div {
			flex-direction: column;
		}
	}
`;
