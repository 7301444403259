// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialContactsValidState {
	isValidNameContacts: boolean;
	isValidEmailContacts: boolean;
	isValidMessageContacts: boolean;
}

const initialState: IInitialContactsValidState = {
	isValidNameContacts: false,
	isValidEmailContacts: false,
	isValidMessageContacts: false,
};

const isValidContactsInputsSlice = createSlice({
	name: 'isValidContactsInputs',
	initialState,
	reducers: {
		setValidStatusNameContacts(state, action) {
			state.isValidNameContacts = action.payload;
			return state;
		},
		setValidStatusEmailContacts(state, action) {
			state.isValidEmailContacts = action.payload;
			return state;
		},
		setValidStatusMessageContacts(state, action) {
			state.isValidMessageContacts = action.payload;
			return state;
		},
	},
});

export const {
	setValidStatusNameContacts,
	setValidStatusEmailContacts,
	setValidStatusMessageContacts,
} = isValidContactsInputsSlice.actions;
export const isValidContactsInputsReducer = isValidContactsInputsSlice.reducer;
