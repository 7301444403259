import styled from 'styled-components';

export const StyledAboutMainMenu = styled.div`
	& .aboutMainMenu-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;
		color: ${(props) => props.theme.currentTheme.mainFontColor};
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 145%;

		& .aboutMainMenu-subtitle {
			display: flex;
			align-self: flex-start;
			font-weight: 400;
			font-size: 24px;
			line-height: 145%;
			width: 50vw;
		}

		& .about-advantages {
			display: flex;
			justify-content: center;
			flex-direction: row;
			gap: 1rem;

			& .wrapperListAdvantages {
				max-width: 571px;
			}
		}

		& .about-offer {
			display: flex;
			justify-content: center;
			gap: 2rem;
			align-items: center;
			width: 100%;

			& .about-offer-text {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				max-width: 40%;

				& .about-offer-text-title {
					font-style: normal;
					font-weight: 600;
					font-size: 32px;
					line-height: 48px;
					color: #1592ec;
				}

				& .about-offer-text-text {
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 145%;
				}
			}

			& .about-offer-cards {
				display: flex;
				gap: 1rem;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;

				& .card-wrapper {
					display: flex;
					flex-direction: column;
					gap: 1rem;
					padding: 2rem;
					background: linear-gradient(
								${(props) => props.theme.currentTheme.cardsBackground} 0 0
							)
							padding-box,
						linear-gradient(to right, #bfd5ff, #dee9ff) border-box;
					border: 1px solid transparent;
					border-radius: 20px;
					width: 215px;
					height: 188px;

					& .card-title {
						font-style: normal;
						font-weight: 400;
						font-size: 24px;
						line-height: 145%;
						text-align: center;
					}
					& .card-accent {
						font-style: normal;
						font-weight: 600;
						font-size: 36px;
						line-height: 100%;
						text-align: center;
						color: #1592ec;
					}
					& .card-additional {
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 145%;
						text-align: center;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		padding: 0px 2rem !important;
		& .aboutMainMenu-wrapper {
			& .aboutMainMenu-subtitle {
				padding-left: 2rem;
				font-size: 20px;
			}

			& .about-advantages {
				flex-direction: column;

				& .wrapperListAdvantages {
					max-width: 100%;
				}
			}

			& .about-offer {
				& .about-offer-cards {
					display: flex;
					gap: 1rem;

					& .card-wrapper {
						padding: 1.5rem;
						width: 188px;
						height: 160px;

						& .card-title {
							font-size: 22px;
						}
						& .card-accent {
							font-size: 30px;
						}
						& .card-additional {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1300px) {
		& .aboutMainMenu-wrapper {
			& .about-offer {
				padding: 1rem;
				flex-direction: column;

				& .about-offer-text {
					max-width: 100%;
				}
			}
		}
	}

	/* After hide navbar */
	@media screen and (max-width: 1000px) {
		padding: 0px 1rem !important;

		& .aboutMainMenu-wrapper {
			font-size: 16px;

			& .about-advantages {
			}

			& .aboutMainMenu-subtitle {
				padding-left: 1rem;
				width: 80vw;
			}
		}
	}

	@media screen and (max-width: 800px) {
		& .aboutMainMenu-wrapper {
			font-size: 12px;

			& .aboutMainMenu-subtitle {
				padding-left: 0px;
				font-size: 14px;
				padding-top: 2rem;
			}

			& .about-offer {
				& .about-offer-text {
					& .about-offer-text-title {
						font-size: 24px;
					}

					& .about-offer-text-text {
						font-size: 16px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 360px) {
		& .aboutMainMenu-wrapper {
			& .aboutMainMenu-subtitle {
				padding-top: 5rem;
			}
		}
	}
`;
