import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import {
	setAnswerApiLoginPersonalInformation,
	setAnswerApiLoginStatus,
	setAnswerApiLoginToken,
} from '../../../app/slices/answersFromAPI/answerApiLoginSlice';
import { setItem } from '../../../app/utils/storageUtils';
import { setAuthorizationStatus } from '../../../app/slices/isAuthorizationSlice';
import { useAppDispatch } from '../../../app/hooks';
import { useLoginWithGoogleByAccessTokenMutation } from '../../../app/api/chatbot.api';
import { type ILoginWithGoogleAccessTokenRequest } from '../../../app/api/interfaces/interfaces';
import googlePic from '../pic/google.svg';

export const LoginWithGoogle: React.FC = () => {
	const dispatch = useAppDispatch();

	const completeLogin = (personalInformationFromApi: any, token: any): any => {
		dispatch(setAnswerApiLoginStatus(true));
		dispatch(setAnswerApiLoginPersonalInformation(personalInformationFromApi));
		setItem('loginDataFromApi', JSON.stringify(personalInformationFromApi));
		dispatch(setAnswerApiLoginToken(token));
		dispatch(setAuthorizationStatus(true));
		setItem('isAuthorization', 'true');
		setItem('token', token);
	};

	const [loginWithGoogleByAccessToken, loginResultByAccessToken] =
		useLoginWithGoogleByAccessTokenMutation();

	useEffect(() => {
		if (loginResultByAccessToken.isSuccess) {
			completeLogin(
				loginResultByAccessToken.data?.user,
				loginResultByAccessToken.data?.token
			);
		}
	}, [loginResultByAccessToken]);

	const login = useGoogleLogin({
		onSuccess: (response) => {
			const googleLoginByAccessToken: ILoginWithGoogleAccessTokenRequest = {
				accessToken: response.access_token,
			};
			loginWithGoogleByAccessToken(googleLoginByAccessToken).catch(() => {
				console.log('Google auth error');
			});
		},
		onError: () => {
			console.log(`Login Failed`);
		},
	});

	return (
		<Box
			component="button"
			className="loginWithGoogle-button"
			onClick={() => {
				login();
			}}
		>
			<Box component="img" src={googlePic} alt="google-pic" />
		</Box>
	);
};
