import React from 'react';
import { useCheckAuth } from '../../app/hooks';
import { Navigate } from 'react-router-dom';
import { LayoutAccount } from '../../components/shared/LayoutAccount/LayoutAccount';
import { SocialIntegrationMenu } from '../../components/SocialIntegrationMenu/SocialIntegrationMenu';
import { MessengerMenu } from '../../components/SocialIntegrationMenu/MainSocialMenus/MessengerMenu/MessengerMenu';

export const MessengerPage: React.FC = () => {
	const isAuthorization = useCheckAuth();

	return (
		<LayoutAccount title="Messenger integration">
			{isAuthorization ? (
				<SocialIntegrationMenu>
					<MessengerMenu />
				</SocialIntegrationMenu>
			) : (
				<Navigate to="/" replace={true} />
			)}
		</LayoutAccount>
	);
};
