import React, { useState } from 'react';
import { Box } from '@mui/material';
import { type ISnippetObjectForSaveUpdate } from '../DialogWnd/DialogWnd';
// import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
// import { setFocusTextareaId, setFocusTextareaType } from '../../../../app/slices/testChatbotModal/focusTextareaSlice';
// import { type RootState } from '../../../../app/store';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

interface IDialogAQElementProps {
	isPrompt: boolean;
	snippet: ISnippetObjectForSaveUpdate;
	changeSnippetInList: (
		id: number,
		isPrompt: boolean,
		updateValue: string
	) => void;
}

export const DialogAQElement: React.FC<IDialogAQElementProps> = ({
	isPrompt,
	snippet,
	changeSnippetInList,
}) => {
	const [valueInput, setValueInput] = useState(
		isPrompt ? snippet.prompt : snippet.completion
	);

	const [editInput, setEditInput] = useState(false);
	// const currentFocusId = useAppSelector((state: RootState) => state.focusTextarea.focusTextareaId)
	// const currentFocusType = useAppSelector((state: RootState) => state.focusTextarea.isPrompt)

	// const textareaRef = useRef<HTMLTextAreaElement | null>(null);
	// const dispatch = useAppDispatch();

	const handleChangeValueInput = (
		event: React.ChangeEvent<HTMLTextAreaElement>
	): void => {
		const newValue = event.target.value;
		setValueInput(newValue);
		// changeSnippetInList(snippet.id, isPrompt, newValue);
	};

	const handleEditSnippet = (): void => {
		setEditInput(true);
	};

	const handleUpdateSnippet = (): void => {
		changeSnippetInList(snippet.id, isPrompt, valueInput);
		setEditInput(false);
	};

	// const handleFocusTextarea = (): void => {
	// 	dispatch(setFocusTextareaId(snippet.id));
	// 	dispatch(setFocusTextareaType(isPrompt));
	// };

	// useEffect(() => {
	// 	if (
	// 		currentFocusId === snippet.id
	// 		&& currentFocusType === isPrompt
	// 		&& textareaRef.current !== null
	// 	) {
	// 		textareaRef.current.focus();
	// 		textareaRef.current.selectionStart = valueInput.length;
	// 	};
	// }, []);

	return (
		<Box
			component="div"
			className={`dialog-aq ${
				isPrompt ? 'prompt-element' : 'completion-element'
			}`}
		>
			{editInput ? (
				<Box
					placeholder={
						isPrompt ? 'Change your question' : 'Change chatbot answer'
					}
					component="textarea"
					className={`input-dialog-${isPrompt ? 'question' : 'answer'}`}
					// type='textarea'
					autoComplete="off"
					rows={valueInput.length / 29}
					value={valueInput}
					// ref={textareaRef}
					// onFocus={handleFocusTextarea}
					onChange={handleChangeValueInput}
				/>
			) : (
				<Box
					component="div"
					className={`input-dialog-div-${isPrompt ? 'question' : 'answer'}`}
				>
					{valueInput}
				</Box>
			)}
			<Box component="div" className="update-input-edit">
				<EditIcon onClick={handleEditSnippet} />
				{editInput ? (
					// <Box component="div" className="update-input-save">
					<SaveIcon onClick={handleUpdateSnippet} />
				) : (
					// </Box>
					<></>
				)}
			</Box>
		</Box>
	);
};
