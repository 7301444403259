import styled from 'styled-components';

export const StyledSocialIntegrationMenu = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 6rem 50px !important;

	& .socialIntegrationMenu-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 0px !important;

		& .socialIntegrationMenu-main-card-wrapper {
			display: flex;
			flex-direction: column;
			position: relative;
			border-radius: 30px;
			padding: 48px;
			width: 100%;
			height: max-content;
			gap: 2.96vh;
			background: linear-gradient(
						180deg,
						${(props) => props.theme.currentTheme.cardsBackground} 0%,
						${(props) => props.theme.currentTheme.cardsBackground} 100%
					)
					padding-box,
				linear-gradient(
						180deg,
						${(props) => props.theme.currentTheme.cardsGradient.one},
						${(props) => props.theme.currentTheme.cardsGradient.two} 65.62%
					)
					border-box;
			border: 1px solid transparent;

			& .integrationMenu-header {
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: 145%;
				color: ${(props) => props.theme.currentTheme.mainFontColor};
				padding-bottom: 48px;
				position: relative;
			}
		}
	}

	@media screen and (max-width: 800px) {
		padding: 6rem 16px !important;

		& .socialIntegrationMenu-wrapper {
			& .socialIntegrationMenu-main-card-wrapper {
				padding: 32px 16px;
			}
		}
	}
`;
