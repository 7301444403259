// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialChangeInformationInputsValueState {
	password: string;
	repeatPassword: string;
	email: string;
	username: string;
	surname: string;
}

const initialState: IInitialChangeInformationInputsValueState = {
	password: '',
	repeatPassword: '',
	email: '',
	username: '',
	surname: '',
};

const changeInformationInputsValueSlice = createSlice({
	name: 'changeInformationInputsValue',
	initialState,
	reducers: {
		setPasswordChangeInformation(state, action) {
			state.password = action.payload;
			return state;
		},
		setEmailChangeInformation(state, action) {
			state.email = action.payload;
			return state;
		},
		setUsernameChangeInformation(state, action) {
			state.username = action.payload;
			return state;
		},
		setSurnameChangeInformation(state, action) {
			state.surname = action.payload;
			return state;
		},
		setPasswordRepeatChangeInformation(state, action) {
			state.repeatPassword = action.payload;
			return state;
		},
	},
});

export const {
	setPasswordChangeInformation,
	setEmailChangeInformation,
	setUsernameChangeInformation,
	setSurnameChangeInformation,
	setPasswordRepeatChangeInformation,
} = changeInformationInputsValueSlice.actions;
export const changeInformationInputsValueReducer =
	changeInformationInputsValueSlice.reducer;
