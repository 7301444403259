// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import checkboxBorder from './pic/checkboxBorder.svg';
import checkboxChecked from './pic/checkboxChecked.svg';

interface ISharedCheckboxItemProps {
	setStateAction: () => void;
	label: string;
	className: string;
	isChecked: boolean;
}
export const SharedCheckboxItem: React.FC<ISharedCheckboxItemProps> = ({
	setStateAction,
	label,
	className,
	isChecked,
}) => {
	// const [isChecked, setIsChecked] = useState(false);

	// const handleCheckCurrentCheckbox = (): void => {
	// 	setIsChecked(!isChecked);
	// 	setStateAction();
	// };

	return (
		<Box component="div" className={`checkbox-item ${className}`}>
			<FormControlLabel
				control={
					<Checkbox
						icon={<img src={checkboxBorder} alt="checkboxBorder" />}
						checked={isChecked}
						// onChange={handleCheckCurrentCheckbox}
						checkedIcon={
							<Box
								sx={{
									fontSize: 'x-small',
									width: '20px',
									height: '20px',
									borderRadius: '6px',
									background: '#1EAF2C',
								}}
							>
								<img src={checkboxChecked} alt="checkboxBorder" />
							</Box>
						}
					/>
				}
				onChange={setStateAction}
				// onChange={handleCheckCurrentCheckbox}
				label={label}
			/>
		</Box>
	);
};
