// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledSharedMainButton = styled.button`
	border: ${(props) => props.theme.border};
	/* border: 1px solid ${(props) => props.theme.mainColor}; */
	width: 100%;
	height: 70px;
	border-radius: ${(props) => props.theme.borderRadius};
	background: ${(props) => props.theme.background};
	cursor: pointer;
	padding: 1rem;
	transition: all 0.5s;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	text-decoration: none;

	&:disabled {
		background: ${(props) => props.theme.currentTheme.disabledButtonColor};
		border: none;
		cursor: no-drop;

		& div {
			color: ${(props) => props.theme.currentTheme.mainFontColor};
		}

		&:hover {
			background: ${(props) => props.theme.currentTheme.disabledButtonColor};
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			border: none;
		}

		&:active {
			background: ${(props) => props.theme.currentTheme.disabledButtonColor};
			color: ${(props) => props.theme.currentTheme.mainFontColor};
			transform: scale(1);
		}
	}

	& div {
		color: ${(props) => props.theme.colorFont};
		font-style: normal;
		font-size: 18px;
		line-height: 100%;
		width: 100%;
	}

	&:hover {
		background: ${(props) => props.theme.hoverBackground};

		& div {
			color: ${(props) => props.theme.hoverColor};
		}
	}

	&:active {
		background: ${(props) => props.theme.mainColor};
		color: ${(props) => props.theme.mainFontColor};
		transform: scale(0.95);
	}

	@media screen and (max-width: 1500px) {
		width: 100%;
		height: 50px;
		& div {
			font-size: 16px;
		}
	}

	@media screen and (max-width: 1000px) {
		width: 100%;
	}

	@media screen and (max-width: 350px) {
		width: 100%;
		max-width: 85vw;
	}
`;
