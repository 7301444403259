// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IDeletePaymentMethodsMenuSliceInitialState {
	isOpenModalDeletePaymentMethod: boolean;
	currentPaymentMethodId: string;
	currentPaymentMethodData: string;
}

const initialState: IDeletePaymentMethodsMenuSliceInitialState = {
	isOpenModalDeletePaymentMethod: false,
	currentPaymentMethodId: '',
	currentPaymentMethodData: '',
};

const deletePaymentMethodsMenuSlice = createSlice({
	name: 'deletePaymentMethodsMenu',
	initialState,
	reducers: {
		setStatusOpenModalDelete(state, action: PayloadAction<boolean>) {
			state.isOpenModalDeletePaymentMethod = action.payload;
			return state;
		},
		setCurrentPaymentMethodIdForDelete(state, action: PayloadAction<string>) {
			state.currentPaymentMethodId = action.payload;
			return state;
		},
		setCurrentPaymentMethodDataForDelete(state, action: PayloadAction<string>) {
			state.currentPaymentMethodData = action.payload;
			return state;
		},
	},
});

export const {
	setStatusOpenModalDelete,
	setCurrentPaymentMethodIdForDelete,
	setCurrentPaymentMethodDataForDelete,
} = deletePaymentMethodsMenuSlice.actions;
export const deletePaymentMethodsMenuReducer =
	deletePaymentMethodsMenuSlice.reducer;
