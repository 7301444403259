import * as React from 'react';
import { StyledSharedTitleSubtitle } from './SharedTitleSubtitle.style';
import { Box } from '@mui/material';

export interface ISharedTitleSubtitle {
	title: string;
	subtitle: string;
	align?: string;
	fontSize?: string;
	width?: string;
}

export const SharedTitleSubtitle: React.FC<ISharedTitleSubtitle> = ({
	title,
	subtitle,
	align,
	fontSize,
	width,
}) => {
	const theme = {
		align,
		fontSize,
		width,
	};

	return (
		<StyledSharedTitleSubtitle theme={theme}>
			<Box component="div" className="shared-title">
				{title}
			</Box>
			<Box component="div" className="shared-subtitle">
				{subtitle}
			</Box>
		</StyledSharedTitleSubtitle>
	);
};
