import * as React from 'react';
import { type IThemeStyledComponents } from '../SharedInput';
import { StyledCustomTextarea } from './CustomTextarea.stlye';

interface ICustomAutocompleteProps {
	placeholder: string;
	inputFunction: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
	value?: string;
	theme?: IThemeStyledComponents;
	separateChangeFn?: () => void;
}

export const CustomTextarea: React.FC<ICustomAutocompleteProps> = ({
	value,
	inputFunction,
	placeholder,
	theme,
	separateChangeFn,
}) => {
	const textareaInputFunction = (
		event: React.ChangeEvent<HTMLTextAreaElement>
	): void => {
		try {
			if (event.target.value !== null) {
				inputFunction(event.target.value);
			} else {
				inputFunction('');
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<StyledCustomTextarea theme={theme}>
			<textarea
				aria-label="Textarea"
				placeholder={placeholder}
				value={value}
				onChange={textareaInputFunction}
				className="shared-input"
			/>
		</StyledCustomTextarea>
	);
};
