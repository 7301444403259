import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledChatbotsTable } from './ChatbotsTable.style';
import {
	type IGenericRow,
	SharedDesktopTable,
} from '../../../shared/PaginationAndTableComponents/SharedTable/SharedDesktopTable';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import {
	useDeleteMessengerBotMutation,
	useGetMessengerBotsQuery,
} from '../../../../app/api/chatbot.api';
// import { ProgressDots } from '../../../shared/ProgressDots/ProgressDots';
import { DataAlert } from '../../../shared/SharedDataAlertFromApi/SharedDataAlertFromApi';
import { useWindowSize } from '../../../../app/hooks/useWindowSize';
import { SharedMobileSnippetsTable } from '../../../shared/PaginationAndTableComponents/SharedTable/MobileSnippetsTable/SharedMobileSnippetsTable';
import { Delete, Edit } from '@mui/icons-material';
import { CustomTooltip } from '../../../shared/CustomTooltip/CustomTooltip';
import { PureSkeleton } from '../../../shared/Skeleton/PureSkeleton/PureSkeleton';
import { SharedModal } from '../../../shared/SharedModal/SharedModal';
import { ModalChangeChatbotIntegration } from './ModalChangeChatbotIntegration/ModalChangeChatbotIntegration';
import { setChangeChatbotIntegrationInput } from '../../../../app/slices/socialMenu/changeChatbotIntegrationInput/changeChatbotIntegrationInput.slice';
import { ProgressDots } from '../../../shared/ProgressDots/ProgressDots';
import { setRefetchFnSocialMenu } from '../../../../app/slices/socialMenu/refetchFnSocialMenu.slice';

interface IChatbotsTableProps {
	keyForDataDisplay: string;
}

export const ChatbotsTable: React.FC<IChatbotsTableProps> = ({
	keyForDataDisplay,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const windowWidth = useWindowSize();

	const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
	const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
	const [
		currentChatbotIdIntegrationForEditAndDelete,
		setCurrentChatbotIdIntegrationForEditAndDelete,
	] = useState(0);
	const [currentTokenForEdit, setCurrentTokenForEdit] = useState('');
	const [currentChatbotIdForEdit, setCurrentChatbotIdForEdit] = useState(0);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isAllSocialAppear, setIsAllSocialAppear] = useState(false);

	const {
		data: allSocialChatbots,
		isLoading: isLoadingAllSocialChatbots,
		isError: isErrorAllSocialChatbots,
		isSuccess: isSuccessAllSocialChatbots,
		refetch: refetchAllSocialChatbots,
	} = useGetMessengerBotsQuery({ token });

	const dispatch = useAppDispatch();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [deleteIntegrateChatbot, deletionIntegrateChatbotStatus] =
		useDeleteMessengerBotMutation();

	const [rowArrayForTable, setRowArrayForTable] = useState<IGenericRow[]>([]);

	const [objectTitlesForTable] = useState({
		titles: [
			{ valueGridElement: 'ID', valueJSX: <></>, widthElement: '150px' },
			{ valueGridElement: 'ChatbotId', valueJSX: <></>, widthElement: '150px' },
			// { valueGridElement: 'Messenger', valueJSX: <></>, widthElement: '150px' },
			{
				valueGridElement: 'Token',
				valueJSX: <></>,
				widthElement: '100%',
			},
			// { valueGridElement: 'Actions', valueJSX: <></>, widthElement: '150px' },
		],
	});

	const handleRefetchFn = async (): Promise<void> => {
		await refetchAllSocialChatbots();
	};

	const handleClickOnEdit = (
		chatbotIdIntegration: number,
		token: string,
		chatbotId: number
	): void => {
		setCurrentChatbotIdIntegrationForEditAndDelete(chatbotIdIntegration);
		setCurrentChatbotIdForEdit(chatbotId);
		setCurrentTokenForEdit(token);
		setIsOpenModalUpdate(true);
		dispatch(setChangeChatbotIntegrationInput(token));
	};
	const handleClickOnDelete = (id: number): void => {
		setCurrentChatbotIdIntegrationForEditAndDelete(id);
		setIsOpenModalDelete(true);
	};

	const handleDeleteChatbotIntegration = async (id: number): Promise<void> => {
		try {
			const resultDeletion = await deleteIntegrateChatbot({
				token,
				chatbotId: id.toString(),
			});
			if ('data' in resultDeletion) {
				void refetchAllSocialChatbots();
				setIsOpenModalDelete(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (allSocialChatbots !== undefined) {
			setTimeout(() => {
				setIsAllSocialAppear(true);
			}, 500);

			dispatch(setRefetchFnSocialMenu(handleRefetchFn));

			const socialChatbotsArray = allSocialChatbots.result;
			const filteredSocialChatbots = socialChatbotsArray.filter(
				(currentSocialChatbot) =>
					currentSocialChatbot.messenger === keyForDataDisplay.toLowerCase()
			);
			const mutatedSocialChatbotsForTable = filteredSocialChatbots.map(
				(currentSocialChatbot) => {
					return {
						gridElementsRow: [
							{
								valueGridElement: currentSocialChatbot.id.toString(),
								valueJSX: <></>,
								widthElement: '150px',
							},
							{
								valueGridElement: currentSocialChatbot.chatbotId.toString(),
								valueJSX: <></>,
								widthElement: '150px',
							},
							// {
							//   valueGridElement: currentSocialChatbot.messenger,
							//   valueJSX: <></>,
							//   widthElement: '150px',
							// },
							{
								valueGridElement: currentSocialChatbot.botToken,
								valueJSX: <></>,
								widthElement: '100%',
							},
						],
						actionButtons: [
							<CustomTooltip
								key={currentSocialChatbot.id}
								title="Edit current chatbot token"
							>
								<Edit
									className="edit-button"
									onClick={() => {
										handleClickOnEdit(
											currentSocialChatbot.id,
											currentSocialChatbot.botToken,
											currentSocialChatbot.chatbotId
										);
									}}
									style={{ width: '25px', height: 'auto' }}
								/>
							</CustomTooltip>,
							<CustomTooltip
								key={currentSocialChatbot.id}
								title="Remove current chatbot integration"
							>
								<Delete
									className="delete-button"
									onClick={() => {
										handleClickOnDelete(currentSocialChatbot.id);
									}}
									style={{ width: '22px', height: 'auto' }}
								/>
							</CustomTooltip>,
						],
					};
				}
			);
			setRowArrayForTable(mutatedSocialChatbotsForTable);
		}
	}, [allSocialChatbots]);

	const theme = { currentTheme };

	const ChatbotsTableByWidth: React.FC = () => {
		if (windowWidth[0] >= 700) {
			return (
				<SharedDesktopTable
					table={{
						titles: objectTitlesForTable.titles,
						genericRows: rowArrayForTable,
					}}
					hasActionButton={true}
				/>
			);
		} else {
			return (
				<SharedMobileSnippetsTable
					table={{
						titles: objectTitlesForTable.titles,
						genericRows: rowArrayForTable,
					}}
					hasActionButton={true}
				/>
			);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const LoadingComponent: React.FC = () => (
		<Box
			sx={{
				width: '100%',
				height: '100px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<ProgressDots />
		</Box>
	);

	return (
		<StyledChatbotsTable className="chatbotsTable-wrapper" theme={theme}>
			{!isAllSocialAppear ? (
				<LoadingComponent />
			) : rowArrayForTable.length > 0 ? (
				<>
					<Box component="div" className="chatbotsTable-title">
						Your {keyForDataDisplay} chatbots:
					</Box>
					<Box component="div" className="chatbotsTable-wrapper">
						{isLoadingAllSocialChatbots ? (
							<PureSkeleton />
						) : isErrorAllSocialChatbots ? (
							<DataAlert text="Something went wrong" isError />
						) : isSuccessAllSocialChatbots ? (
							<ChatbotsTableByWidth />
						) : (
							<></>
						)}
					</Box>
					{isOpenModalDelete ? (
						<SharedModal
							closeFn={() => {
								setIsOpenModalDelete(false);
								dispatch(setChangeChatbotIntegrationInput(''));
							}}
							message={null}
							title="Are you sure you want to remove current chatbot integration"
							isDeleteModal
							isOnlyAlert={false}
							executeFn={() => {
								void handleDeleteChatbotIntegration(
									currentChatbotIdIntegrationForEditAndDelete
								);
							}}
						/>
					) : (
						<></>
					)}
					{isOpenModalUpdate ? (
						<ModalChangeChatbotIntegration
							closeFn={() => {
								setIsOpenModalUpdate(false);
							}}
							chatbotId={currentChatbotIdForEdit}
							chatbotIdIntegration={currentChatbotIdIntegrationForEditAndDelete.toString()}
							refetchFn={handleRefetchFn}
							currentTokenForEdit={currentTokenForEdit}
						/>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
		</StyledChatbotsTable>
	);
};
