import React, { useEffect, useState, useRef } from 'react';
import { StyledSharedChangeIconComponent } from './SharedChangeIconComponent.style';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { ReactComponent as Close } from './pic/close.svg';
import chooseImage from './pic/chooseImage.svg';
import chooseImageDark from './pic/chooseImageDark.svg';
import { ReactComponent as ArrowWhite } from './pic/arrowWhite.svg';
import { ReactComponent as ArrowDark } from './pic/arrowDark.svg';
import {
	CropImageComponent,
	type ICompletedCropValues,
} from './CropImageComponent';
import { SharedMainButton } from '../SharedMainButton/SharedMainButton';
import {
	setIconChatbotChoosing,
	setIconChatbotChoosingForCreationChatbot,
} from '../../../app/slices/iconChatbot/iconChatbotSlice';
import { CustomButtonUploadImage } from './CustomButtonUploadImage/CustomButtonUploadImage';

interface ISharedChangeIconComponentProps {
	isChatbotIcon: boolean;
	iconUrl: string;
	setCurrentFile: React.Dispatch<React.SetStateAction<File | undefined>>;
	isCreationChatbot: boolean;
}

export const SharedChangeIconComponent: React.FC<
	ISharedChangeIconComponentProps
> = ({ isChatbotIcon, iconUrl, setCurrentFile, isCreationChatbot }) => {
	const isDarkTheme = (): boolean =>
		currentTheme.mainRootBackground === '#1A1D21';

	const [iconChatbot, setIconChatbot] = useState(iconUrl);
	const [iconChatbotForCreateChatbot, setIconChatbotForCreateChatbot] =
		useState('');
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isImageChosen, setImageChosen] = useState(false);
	const [isCropStage, setIsCropStage] = useState(false);
	const [chosenImageObjectURL, setChosenImageObjectURL] = useState('');

	const [completedCrop, setCompletedCrop] = useState<ICompletedCropValues>();

	const iconChatbotChoosing = useAppSelector(
		(state: RootState) => state.iconChatbot.iconChatbotChoosing
	);
	const iconChatbotChoosingForCreationChatbot = useAppSelector(
		(state: RootState) =>
			state.iconChatbot.iconChatbotChoosingForCreationChatbot
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const iconCreateObjectURL = useAppSelector(
		(state: RootState) => state.iconChatbot.iconCreateObjectURL
	);

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = {
		currentTheme,
		rotate: `rotate(${!isCropStage ? '180deg' : '0deg'})`,
		cursorSelect: isImageChosen ? 'pointer' : 'no-drop',
		isDisable: isImageChosen ? '1' : '0.3',
	};

	const userImageContainer = useRef<HTMLImageElement | null>(null);
	const userImageUpload = useRef<HTMLInputElement | null>(null);

	const dispatch = useAppDispatch();

	useEffect(() => {
		iconChatbotChoosingForCreationChatbot !== '' &&
			setIconChatbotForCreateChatbot(iconChatbotChoosingForCreationChatbot);
	}, [iconChatbotChoosingForCreationChatbot]);

	useEffect(() => {
		iconChatbotChoosing !== '' && setIconChatbot(iconChatbotChoosing);
	}, [iconChatbotChoosing]);

	const handleOpenCloseModal = (desiredModalCondition: boolean): void => {
		setIsOpenModal(desiredModalCondition);
		if (!desiredModalCondition) {
			if (isCreationChatbot) {
				dispatch(setIconChatbotChoosingForCreationChatbot(''));
			} else {
				dispatch(setIconChatbotChoosing(''));
			}
		}
	};

	useEffect(() => {
		if (!isOpenModal) {
			setImageChosen(false);
			setIsCropStage(false);
		}
	}, [isOpenModal]);

	const saveUserImage = (): void => {
		if (
			userImageUpload.current?.files != null &&
			userImageContainer.current !== null
		) {
			const currentUserImage = userImageUpload.current.files[0];
			setCurrentFile(currentUserImage);
			// console.log(currentUserImage);
			if (currentUserImage !== undefined) {
				userImageContainer.current.src =
					window.URL.createObjectURL(currentUserImage);
				setImageChosen(true);
				setChosenImageObjectURL(window.URL.createObjectURL(currentUserImage));

				setIsCropStage(true);
			}
		}
	};

	const selectImageAndGoToCrop = (): void => {
		isImageChosen && setIsCropStage(true);
	};

	const backToChooseImage = (): void => {
		setIsCropStage(false);
		if (isCreationChatbot) {
			dispatch(setIconChatbotChoosingForCreationChatbot(''));
		} else {
			dispatch(setIconChatbotChoosing(''));
		}
	};

	const handleConfirmChangeMainIconChatbot = (): void => {
		setIsOpenModal(false);
		if (isCreationChatbot) {
			dispatch(setIconChatbotChoosing(''));
		} else {
			dispatch(setIconChatbotChoosingForCreationChatbot(''));
		}
	};

	return (
		<StyledSharedChangeIconComponent
			theme={theme}
			className="styledSharedChangeIconComponent"
		>
			<CustomButtonUploadImage
				handleOpenCloseModal={handleOpenCloseModal}
				iconChatbot={
					isCreationChatbot ? iconChatbotForCreateChatbot : iconChatbot
				}
				isCreationChatbot={isCreationChatbot}
			/>
			{isOpenModal ? (
				<Box component="div" className="change-icon-modal-Menu">
					<Box
						component="div"
						className="wrapper-change-icon-modal-menu-window"
					>
						<Box
							component="div"
							className="modal-menu-high-field"
							onClick={() => {}}
						>
							<Box
								component="div"
								className="action-button-back-next"
								onClick={
									!isCropStage ? selectImageAndGoToCrop : backToChooseImage
								}
								sx={{ flexDirection: !isCropStage ? 'row-reverse' : 'row' }}
							>
								{isCropStage ? (
									<>
										<Box
											component="div"
											className="action-button-back-next-arrow"
										>
											{isDarkTheme() ? <ArrowWhite /> : <ArrowDark />}
										</Box>
										<Box component="span">
											{!isCropStage ? 'Select' : 'Back'}
										</Box>
									</>
								) : (
									<></>
								)}
							</Box>
							<Box component="div" className="title-action">
								<Box component="span">{isCropStage ? 'Crop icon' : ''}</Box>
							</Box>
							<Box
								component="div"
								className="modal-menu-close-btn"
								onClick={() => {
									handleOpenCloseModal(false);
								}}
							>
								<Close />
							</Box>
						</Box>
						{isCropStage ? (
							<Box component="div" className="cropImageStage">
								<CropImageComponent
									src={chosenImageObjectURL}
									completedCrop={completedCrop}
									setCompletedCrop={setCompletedCrop}
									isCreationChatbot={isCreationChatbot}
								/>
								{/* <img
									src={iconChatbotChoosing}
									style={{
										borderRadius: '50%',
										objectFit: 'contain',
										width: completedCrop?.width,
										height: completedCrop?.height,
									}}
								/> */}

								<SharedMainButton
									buttonFunction={handleConfirmChangeMainIconChatbot}
									text="Apply"
									isDeleteButton={false}
								/>
							</Box>
						) : (
							<Box component="div" className="selectImageStage">
								<Box
									component="div"
									className="modal-ChooseImage-pic"
									id="user-image"
									onClick={() => {}}
									// ref={userImageContainer}
								>
									<img
										ref={userImageContainer}
										src={isDarkTheme() ? chooseImageDark : chooseImage}
										alt="user-uploaded-image"
									/>
									{/* {isDarkTheme() ? <ChooseImageDark /> : <ChooseImage />} */}
								</Box>
								<Box component="span" className="modal-ChooseImage-text">
									Select image for your ChatBot
								</Box>

								<label className="user-image-input-form-control">
									Choose photo
									<input
										type="file"
										id="user-image-input"
										ref={userImageUpload}
										onChange={saveUserImage}
									/>
									{/* <SharedMainButton
											buttonFunction={() => {}}
											text="Choose photo"
											isDeleteButton={false}
										/> */}
								</label>
							</Box>
						)}
					</Box>
				</Box>
			) : (
				<></>
			)}
		</StyledSharedChangeIconComponent>
	);
};
