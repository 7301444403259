import React from 'react';
import { Box } from '@mui/material';
import { StyledWhatsAppConfiguration } from './WhatsAppConfiguration.style';
import arrow from '../../../../pic/arrow.svg';
import { useAppSelector } from '../../../../../../app/hooks';
import { type RootState } from '../../../../../../app/store';
import whatsAppConfigurationOpen from '../../../../pic/whatsAppConfigurationOpen.png';
import whatsAppConfiguration from '../../../../pic/whatsAppConfiguration.png';

export const WhatsAppConfiguration: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	return (
		<StyledWhatsAppConfiguration
			theme={theme}
			className="messageExample-wrapper"
		>
			<Box component="div" className="messageExample-arrow">
				<Box component="img" src={arrow} alt="arrow-pic" />
			</Box>
			<Box component="div" className="messageExample-msg-box">
				<Box
					component="img"
					className="step-Element-img-first"
					src={whatsAppConfigurationOpen}
				/>
			</Box>
			<Box component="div" className="messageExample-msg-box">
				<Box
					component="img"
					className="step-Element-img-second"
					src={whatsAppConfiguration}
				/>
			</Box>
		</StyledWhatsAppConfiguration>
	);
};
