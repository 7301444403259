// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusForChangeInfoSlice = createSlice({
	name: 'checkEmptyStatusForChangeInfo',
	initialState,
	reducers: {
		setEmptyStatusForChangeInfo(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForChangeInfo } =
	checkEmptyStatusForChangeInfoSlice.actions;
export const checkEmptyStatusForChangeInfoReducer =
	checkEmptyStatusForChangeInfoSlice.reducer;
