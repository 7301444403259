import styled from 'styled-components';

export const StyledNichesMenu = styled.div`
	display: flex;
	gap: 24px;
	width: 100%;

	& > div {
		display: flex;
		gap: 16px;
		width: 100%;
	}

	& .niches-column-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 32px;
		width: 50%;

		& .nicheWrapper {
			display: flex;
			justify-content: center;
			flex-direction: row;
			align-items: center;
			gap: 1rem;

			& .nicheWrapper-icon {
				position: relative;

				& .icon-index {
					display: flex;
					justify-content: center;
					align-items: center;

					font-style: normal;
					font-weight: 600;
					font-size: 28px;
					line-height: 0px;
					color: #1592ec;
				}

				& img {
					height: 100%;
					width: 90px;
				}
			}

			& .nicheWrapper-text {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				color: ${(props) => props.theme.currentTheme.mainFontColor};

				& .nicheWrapper-text-title {
					font-style: normal;
					font-weight: 400;
					font-size: 24px;
					line-height: 145%;
				}

				& .nicheWrapper-text-value {
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 140%;
				}
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .niches-column-wrapper {
			& .nicheWrapper {
				& .nicheWrapper-text {
					& .nicheWrapper-text-title {
						font-size: 20px;
					}

					& .nicheWrapper-text-value {
						font-size: 16px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 800px) {
		padding-top: 3rem !important;
	}

	@media screen and (max-width: 600px) {
		& > div {
			flex-direction: column;
		}
		flex-direction: column;
		gap: 1rem;
		padding-top: 3rem !important;
		& .niches-column-wrapper {
			padding: 0px !important;
			width: 100%;
			gap: 1rem;
		}
	}

	@media screen and (max-width: 360px) {
		padding-top: 2rem !important;

		& .niches-column-wrapper {
			gap: 2rem;
			& .nicheWrapper {
				flex-direction: column;
				padding: 1rem;
				background: linear-gradient(
							${(props) => props.theme.currentTheme.cardsBackground} 0 0
						)
						padding-box,
					linear-gradient(to right, #bfd5ff, #dee9ff) border-box;
				border: 1px solid transparent;
				border-radius: 20px;

				& .nicheWrapper-text {
					text-align: center;
				}
			}
		}
	}
`;
