import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialDeleteSnippetMessageTrainingBotMenu {
	deleteSnippetMessage: string;
}

const initialState: IInitialDeleteSnippetMessageTrainingBotMenu = {
	deleteSnippetMessage: '',
};

const deleteSnippetMessageTrainingBotMenuSlice = createSlice({
	name: 'deleteSnippetMessageTrainingBotMenu',
	initialState,
	reducers: {
		setDeleteSnippetMessage(state, action: PayloadAction<string>) {
			state.deleteSnippetMessage = action.payload;
			return state;
		},
	},
});

export const { setDeleteSnippetMessage } =
	deleteSnippetMessageTrainingBotMenuSlice.actions;
export const deleteSnippetMessageTrainingBotMenuReducer =
	deleteSnippetMessageTrainingBotMenuSlice.reducer;
