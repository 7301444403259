import React from 'react';
import { Box } from '@mui/material';
import { StyledCustomButtonUploadImage } from './CustomButtonUploadImage.style';
import { ReactComponent as IconUpload } from '../pic/iconUpload.svg';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { ReactComponent as Close } from '../pic/close.svg';
import { setIconChatbotChoosingForCreationChatbot } from '../../../../app/slices/iconChatbot/iconChatbotSlice';

interface ICustomButtonUploadImageProps {
	handleOpenCloseModal: (desiredModalCondition: boolean) => void;
	iconChatbot: string;
	isCreationChatbot: boolean;
}

export const CustomButtonUploadImage: React.FC<
	ICustomButtonUploadImageProps
> = ({ handleOpenCloseModal, iconChatbot, isCreationChatbot }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	const iconChatbotChoosingForCreationChatbot = useAppSelector(
		(state: RootState) =>
			state.iconChatbot.iconChatbotChoosingForCreationChatbot
	);
	const dispatch = useAppDispatch();

	const handleRemoveIcon = (): void => {
		dispatch(setIconChatbotChoosingForCreationChatbot(''));
	};

	const UploadImageUpdate: React.FC = () => (
		<Box component="div" className="change-icon-Component">
			<Box
				component="div"
				className="wrapper-icon"
				sx={{ backgroundImage: `url(${iconChatbot}) !important` }}
			></Box>
			<Box
				component="div"
				className="edit-icon-wrapper"
				onClick={() => {
					handleOpenCloseModal(true);
				}}
			>
				<Box component="div" className="edit-icon-pic">
					<IconUpload />
				</Box>
				<Box component="div" className="edit-icon-text">
					Edit icon
				</Box>
			</Box>
		</Box>
	);

	const UploadImageCreateBot: React.FC = () => (
		<Box component="div" className="change-icon-Component without-icon">
			<Box
				component="div"
				className="edit-icon-wrapper"
				onClick={() => {
					handleOpenCloseModal(true);
				}}
			>
				<Box component="div" className="edit-icon-pic">
					<IconUpload />
				</Box>
				<Box component="div" className="edit-icon-text">
					Edit icon
				</Box>
			</Box>
			<Box component="div" className="change-icon-message-present">
				{iconChatbotChoosingForCreationChatbot !== '' ? (
					<Box component="div" className="change-icon-message">
						<Box component="span" className="change-icon-message-text">
							Image uploaded
						</Box>
						<Box
							component="span"
							className="remove-btn"
							onClick={handleRemoveIcon}
						>
							<Close />
						</Box>
					</Box>
				) : (
					<></>
				)}
			</Box>
		</Box>
	);

	const finalButtonUploadDependsCreationChatbot = isCreationChatbot ? (
		<UploadImageCreateBot />
	) : (
		<UploadImageUpdate />
	);

	return (
		<StyledCustomButtonUploadImage theme={theme}>
			{finalButtonUploadDependsCreationChatbot}
		</StyledCustomButtonUploadImage>
	);
};
