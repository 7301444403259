// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusForResetSlice = createSlice({
	name: 'checkEmptyStatusForReset',
	initialState,
	reducers: {
		setEmptyStatusForReset(state, action) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForReset } = checkEmptyStatusForResetSlice.actions;
export const checkEmptyStatusForResetReducer =
	checkEmptyStatusForResetSlice.reducer;
