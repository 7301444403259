// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialConfigOpenAIMenuInputsValueState {
	openAiKey: string;
	temperature: string;
	completionModel: string;
	vectorModel: string;
	threshold: string;
	maxTokensContent: string;
	maxTokensRequest: string;
	maxTokensResponse: string;
	maxTokensMessage: string;
}

export const initialState: IInitialConfigOpenAIMenuInputsValueState = {
	openAiKey: '',
	temperature: '',
	completionModel: '',
	vectorModel: '',
	threshold: '',
	maxTokensContent: '',
	maxTokensRequest: '',
	maxTokensResponse: '',
	maxTokensMessage: '',
};

const configOpenAIMenuInputsSlice = createSlice({
	name: 'configOpenAIMenuInputs',
	initialState,
	reducers: {
		setOpenAiKeyConfigOpenAIMenu(state, action: PayloadAction<string>) {
			state.openAiKey = action.payload;
			return state;
		},
		setTemperatureConfigOpenAIMenu(state, action: PayloadAction<string>) {
			state.temperature = action.payload;
			return state;
		},
		setCompletionModelConfigOpenAIMenu(state, action: PayloadAction<string>) {
			state.completionModel = action.payload;
			return state;
		},
		setVectorModelConfigOpenAIMenu(state, action: PayloadAction<string>) {
			state.vectorModel = action.payload;
			return state;
		},
		setThresholdConfigOpenAIMenu(state, action: PayloadAction<string>) {
			state.threshold = action.payload;
			return state;
		},
		setMaxTokensContentConfigOpenAIMenu(state, action: PayloadAction<string>) {
			state.maxTokensContent = action.payload;
			return state;
		},
		setMaxTokensRequestConfigOpenAIMenu(state, action: PayloadAction<string>) {
			state.maxTokensRequest = action.payload;
			return state;
		},
		setMaxTokensResponseConfigOpenAIMenu(state, action: PayloadAction<string>) {
			state.maxTokensResponse = action.payload;
			return state;
		},
		setMaxTokensMessageConfigOpenAIMenu(state, action: PayloadAction<string>) {
			state.maxTokensMessage = action.payload;
			return state;
		},
	},
});

export const {
	setOpenAiKeyConfigOpenAIMenu,
	setTemperatureConfigOpenAIMenu,
	setCompletionModelConfigOpenAIMenu,
	setVectorModelConfigOpenAIMenu,
	setThresholdConfigOpenAIMenu,
	setMaxTokensContentConfigOpenAIMenu,
	setMaxTokensRequestConfigOpenAIMenu,
	setMaxTokensResponseConfigOpenAIMenu,
	setMaxTokensMessageConfigOpenAIMenu,
} = configOpenAIMenuInputsSlice.actions;
export const configOpenAIMenuInputsReducer =
	configOpenAIMenuInputsSlice.reducer;
