import React from 'react';
import { StyledHistoryDateRangePicker } from './HistoryDateRangePicker.style';
import { SharedDateRangePicker } from '../../../shared/PaginationAndTableComponents/SharedDateRangePicker/SharedDateRangePicker';
import { type ICurrentThemeColor } from '../../../../app/slices/switchThemeSlice';
import { useAppDispatch } from '../../../../app/hooks';
import {
	changeDateRangeHistoryMenu,
	removeDateRangeHistoryMenu,
} from '../../../../app/slices/trainingBotMenu/historyMenu/searchBarHistoryMenu/searchBarHistoryMenuValueSlice';

interface IHistoryDateRangePickerProps {
	currentTheme: ICurrentThemeColor;
}

export const HistoryDateRangePicker: React.FC<IHistoryDateRangePickerProps> = ({
	currentTheme,
}) => {
	const dispatch = useAppDispatch();

	const handleChangeDateRangeToState = (
		firstDate: string,
		lastDate: string
	): void => {
		dispatch(changeDateRangeHistoryMenu([firstDate, lastDate]));
	};

	const handleRemoveStateDateRangeFn = (): void => {
		dispatch(removeDateRangeHistoryMenu());
	};

	return (
		<StyledHistoryDateRangePicker>
			<SharedDateRangePicker
				setStateDateRangeFn={handleChangeDateRangeToState}
				removeStateDateRangeFn={handleRemoveStateDateRangeFn}
				placeholder="Select date range"
				currentTheme={currentTheme}
			/>
		</StyledHistoryDateRangePicker>
	);
};
