import React from 'react';
import { Box } from '@mui/material';
import { StyledWrappedCardDetailedPlatform } from './WrappedCardDetailedPlatform.style';
import folderPicBlack from '../pic/folderPicBlack.png';
import folderPicWhite from '../pic/folderPicWhite.png';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';

interface IAllLogosList {
	link: string;
	id: number;
	name: string;
	value: React.ReactElement;
	isSmall: boolean;
}

interface ITitleAndContentCurrentItemProps {
	title: string;
	content: string[];
	allLogosList: IAllLogosList[];
}

export const WrappedCardDetailedPlatform: React.FC<
	ITitleAndContentCurrentItemProps
> = ({ title, content, allLogosList }) => {
	const currentLogoItem = allLogosList.find((item) => item.name === title);
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	return (
		<StyledWrappedCardDetailedPlatform
			theme={theme}
			id={currentLogoItem?.name.toLowerCase()}
			className={`wrappedCardDetailedPlatform`}
			// style={{backgroundImage: `url(${folderPicWhite})`}}
		>
			<Box component="div" className="wrappedCardDetailedPlatform-high-block">
				<Box
					component="div"
					className="wrappedCardDetailedPlatform-high-block-logo"
				>
					{currentLogoItem?.value}
				</Box>
				<Box
					component="div"
					className="wrappedCardDetailedPlatform-high-block-name"
				>
					{currentLogoItem?.name}
				</Box>
			</Box>
			<Box component="div" className="wrappedCardDetailedPlatform-text-block">
				<Box
					component="div"
					className="wrappedCardDetailedPlatform-text-content"
					id={currentLogoItem?.name.toLowerCase().concat('-text-content')}
				>
					{content.map((item) => (
						<Box
							component="div"
							className="wrappedCardDetailedPlatform-text-value"
							key={item}
						>
							{item}
						</Box>
					))}
				</Box>
			</Box>
			<Box component="div" className="wrapped-card-background"></Box>
			<Box component="div" className="folder-pic">
				<Box
					component="img"
					className="folder-pic-image"
					src={
						currentTheme.mainRootBackground === '#1A1D21'
							? folderPicBlack
							: folderPicWhite
					}
					alt="folder-pic"
				/>
			</Box>
		</StyledWrappedCardDetailedPlatform>
	);
};
