import styled from 'styled-components';

export const StyledStatisticCard = styled.div`
	background: ${(props) => props.theme.currentTheme.vidgetCardBlue};
	padding: 32px;
	border-radius: 20px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;

	& .statisticCard-title {
		font-weight: 600;
		line-height: 48px;
	}

	& .statisticCard-info {
		display: flex;
		gap: 48px;

		& .statisticCard-plan-info {
		}

		& .statisticCard-statistic-info {
			display: flex;
			gap: 48px;
			width: max-content;
			/* flex-wrap: wrap; */
			width: 100%;

			& > div {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 48px;
				width: 100%;
			}
		}
	}

	@media screen and (min-width: 1500px) {
		& .statisticCard-title {
			font-size: 24px;
		}
	}

	@media screen and (max-width: 1500px) {
		& .statisticCard-info {
			gap: 24px;

			& .statisticCard-statistic-info {
				gap: 24px;
			}
		}
	}

	@media screen and (max-width: 1400px) {
		& .statisticCard-info {
			& .statisticCard-statistic-info {
				gap: 48px;
				flex-direction: column;
			}
		}
	}

	@media screen and (max-width: 550px) {
		& .statisticCard-info {
			flex-direction: column;
			align-items: center;
		}
	}
`;
