// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const requiredItemFromStorage = (key: string): string => {
	const surnameFromStorage = window.localStorage.getItem('loginDataFromApi');
	if (surnameFromStorage === null) {
		return '';
	} else {
		const storageData = JSON.parse(surnameFromStorage);
		if (key in storageData) {
			return storageData[key];
		} else {
			return '';
		}
	}
};

export interface IUserPersonalInformationDataResponse {
	isSuccess: boolean;
	errorMessage: string;
	id: number;
	email: string;
	username: string;
	surname: string;
}

const initialState: IUserPersonalInformationDataResponse = {
	isSuccess: false,
	errorMessage: '',
	email: '',
	id: 0,
	username: requiredItemFromStorage('username'),
	surname: requiredItemFromStorage('surname'),
};

const answerApiPersonalInformationSlice = createSlice({
	name: 'answerApiPersonalInformation',
	initialState,
	reducers: {
		setAnswerApiPersonalInformationStatus(state, action) {
			state.isSuccess = action.payload;
			return state;
		},
		setAnswerApiPersonalInformationErrorMessage(state, action) {
			state.errorMessage = action.payload;
			return state;
		},
		setAnswerApiPersonalInformationPersonalInformation(state, action) {
			const { id, username, surname, email } = action.payload;
			state.id = id;
			state.username = username;
			state.surname = surname;
			state.email = email;
			return state;
		},
	},
});

export const {
	setAnswerApiPersonalInformationStatus,
	setAnswerApiPersonalInformationErrorMessage,
	setAnswerApiPersonalInformationPersonalInformation,
} = answerApiPersonalInformationSlice.actions;
export const answerApiPersonalInformationReducer =
	answerApiPersonalInformationSlice.reducer;
