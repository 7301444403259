import styled from 'styled-components';

export const StyledMobileSnippetsTable = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;

	& .message-empty {
		color: ${(props) => props.theme.currentTheme.mainFontColor};
	}

	& .grid-card-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding-bottom: 1rem;
		border-bottom: 2px solid
			${(props) => props.theme.currentTheme.accentHighlightColorForTable};

		& .grid-card-accent-line {
			background: ${(props) =>
				props.theme.currentTheme.accentHighlightColorForTable};
		}

		& .grid-card-accent-line,
		.grid-card-line {
			border-radius: 4px;
			padding: 8px 16px;
			display: flex;
			align-items: center;
			width: 100%;
			text-align: start;

			& .grid-card-item-left,
			.grid-card-item-right {
				display: flex;
				justify-content: flex-start;
				width: 100%;
				color: ${(props) => props.theme.currentTheme.mainFontColor};
			}

			& .grid-card-item-left {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 145%;
			}

			& .grid-card-item-right {
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 145%;
				width: 100%;
				hyphens: auto;
				word-wrap: break-word;
			}

			& .actions-buttons {
				display: flex;
				gap: 2rem;

				& > svg {
					cursor: pointer;
					width: 25px;
					height: auto;
					color: #1592ec;
				}

				& .disabled-svg {
					color: ${(props) => props.theme.currentTheme.disabledColor};
					cursor: auto !important;
				}
			}
		}
	}

	@media screen and (max-width: 380px) {
		& .grid-card-wrapper {
			& .grid-card-accent-line,
			.grid-card-line {
				& .grid-card-item-right {
					width: 150%;
				}
			}
		}
	}
`;
