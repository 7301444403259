import styled from 'styled-components';

export const StyledAddStylizationInputsField = styled.div`
	& .styledSharedChangeIconComponent {
		width: max-content;
	}

	& .wrapperForArticleInput {
		width: 100%;
	}

	& .wrapped-header-with-upload-icon {
		display: flex;
		width: 100%;
		gap: 16px;

		& .change-icon-Component {
			width: max-content;
			gap: 0px;
		}

		& .shared-wrapped-input {
			width: 100% !important;

			& .shared-input {
				width: 100% !important;
			}
		}
	}

	& .styledSharedChangeIconComponent {
		margin-bottom: 0px;
	}

	@media screen and (max-width: 1500px) {
		& .wrapped-header-with-upload-icon {
			& .change-icon-Component {
				& .edit-icon-wrapper {
					height: 50px;
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		& .wrapped-header-with-upload-icon {
			flex-direction: column;
			align-items: center;
		}
	}
`;
