import React, { useEffect } from 'react';
import { StyledPaymentMethodsChoosingField } from './PaymentMethodsChoosingField.style';
import { CustomCardCheckbox } from './CustomCardCheckbox';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { FormControl, RadioGroup } from '@mui/material';
import {
	setCurrentPaymentMethodId,
	setStatusCheckedAddNewCardOption,
} from '../../../../app/slices/paymentMethodsMenu/paymentMethodsMenuSlice';

export interface ICardsArrayData {
	last4: string;
	brand: string;
	id: string;
}

interface IPaymentMethodsChoosingFieldProps {
	cardsArray: ICardsArrayData[];
	isFondy: boolean;
}

export const PaymentMethodsChoosingField: React.FC<
	IPaymentMethodsChoosingFieldProps
> = ({ cardsArray, isFondy }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = {
		currentTheme,
	};

	const checkedItem = useAppSelector(
		(state: RootState) => state.paymentMethodsMenu.currentPaymentMethodId
	);

	// const [checkedItem, setCheckedItem] = useState<string>('');
	// const [checkedItem, setCheckedItem] = useState<string>('Add-New-Card');

	const dispatch = useAppDispatch();

	const handleChangeRadioGroup = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		// setCheckedItem(event.target.value);
		dispatch(setCurrentPaymentMethodId(event.target.value));
	};

	useEffect(() => {
		checkedItem === 'Add-New-Card'
			? dispatch(setStatusCheckedAddNewCardOption(true))
			: dispatch(setStatusCheckedAddNewCardOption(false));
	}, [checkedItem]);

	return (
		<StyledPaymentMethodsChoosingField theme={theme}>
			<FormControl>
				<RadioGroup
					aria-labelledby="cards-radio-buttons-group"
					defaultValue="1"
					name="cards-radio-buttons-group"
					onChange={handleChangeRadioGroup}
				>
					{cardsArray.map((currentCard) => {
						return (
							<CustomCardCheckbox
								label={'•••• •••• •••• '.concat(currentCard.last4)}
								last4={'•••• '.concat(currentCard.last4)}
								key={currentCard.id}
								isCard={true}
								brand={currentCard.brand}
								id={currentCard.id}
								isChecked={checkedItem === currentCard.id}
							/>
						);
					})}
					<CustomCardCheckbox
						label={'Add New Card'}
						isCard={false}
						id={'Add-New-Card'}
						isChecked={checkedItem === 'Add-New-Card'}
						last4=""
					/>
				</RadioGroup>
			</FormControl>
		</StyledPaymentMethodsChoosingField>
	);
};
