// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import {
	type PayloadAction,
	createSlice,
	type AnyAction,
} from '@reduxjs/toolkit';

export interface IInitialLoginState {
	domainLogin: string;
	emailLogin: string;
	passwordLogin: string;
}

const initialState: IInitialLoginState = {
	domainLogin: '',
	emailLogin: '',
	passwordLogin: '',
};

const loginInputsValueSlice = createSlice({
	name: 'loginInputsValue',
	initialState,
	reducers: {
		setDomainLogin(state, action: PayloadAction<string>) {
			state.domainLogin = action.payload;
			return state;
		},
		setEmailLogin(state, action: PayloadAction<string>) {
			state.emailLogin = action.payload;
			return state;
		},
		setPasswordLogin(state, action: PayloadAction<string>) {
			state.passwordLogin = action.payload;
			return state;
		},
		setStateLoginFromStorage(state, action: PayloadAction<AnyAction>) {
			const allItems = action.payload;
			if (allItems.domain !== null) {
				state.domainLogin = allItems.domain;
			}
			if (allItems.email !== null) {
				state.emailLogin = allItems.email;
			}
			if (allItems.password !== null) {
				state.passwordLogin = allItems.password;
			}
			return state;
		},
	},
});

export const {
	setDomainLogin,
	setEmailLogin,
	setPasswordLogin,
	setStateLoginFromStorage,
} = loginInputsValueSlice.actions;
export const loginInputsValueReducer = loginInputsValueSlice.reducer;
