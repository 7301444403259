/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialCheckedSnippets {
	checkedSnippetsArray: number[];
}

const initialState: IInitialCheckedSnippets = {
	checkedSnippetsArray: [],
};

const checkedSnippetsSlice = createSlice({
	name: 'checkedSnippets',
	initialState,
	reducers: {
		pushArrayCheckedSnippets(state, action: PayloadAction<number>) {
			state.checkedSnippetsArray = [
				...state.checkedSnippetsArray,
				action.payload,
			];
			return state;
		},
		pushSeveralSnippetsInCheckedArray(state, action: PayloadAction<number[]>) {
			state.checkedSnippetsArray = [
				...state.checkedSnippetsArray,
				...action.payload,
			];
			return state;
		},
		removeCurrentSnippetsFromArrayChecked(
			state,
			action: PayloadAction<number>
		) {
			const arrayWithoutSnippet = state.checkedSnippetsArray.filter(
				(currentSnippet) => currentSnippet !== action.payload
			);
			state.checkedSnippetsArray = arrayWithoutSnippet;
			return state;
		},
		removeArrayCheckedSnippets(state) {
			state.checkedSnippetsArray = [];
			return state;
		},
	},
});

export const {
	pushArrayCheckedSnippets,
	pushSeveralSnippetsInCheckedArray,
	removeCurrentSnippetsFromArrayChecked,
	removeArrayCheckedSnippets,
} = checkedSnippetsSlice.actions;
export const checkedSnippetsMenuReducer = checkedSnippetsSlice.reducer;
