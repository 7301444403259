import React from 'react';
import { articlesQA } from '../shared/textContent';
import { StyledAboutQuestionsMenu } from './AboutQuestionsMenu.style';
import { AQWrapper } from './AQWrapper';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';

export const AboutQuestionsMenu: React.FC = () => {
	const firstPartOfArticles = articlesQA.filter((value, index) => index <= 3);
	const secondPartOfArticles = articlesQA.filter((value, index) => index > 3);
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	return (
		<StyledAboutQuestionsMenu theme={theme}>
			<Box>
				<Box component="div" className="articles-column-wrapper">
					{firstPartOfArticles.map((article) => (
						<AQWrapper
							key={article.a}
							answer={article.a}
							question={article.q}
							content={article.content}
						/>
					))}
				</Box>
				<Box component="div" className="articles-column-wrapper">
					{secondPartOfArticles.map((article) => (
						<AQWrapper
							key={article.a}
							answer={article.a}
							question={article.q}
							content={article.content}
						/>
					))}
				</Box>
			</Box>
		</StyledAboutQuestionsMenu>
	);
};
