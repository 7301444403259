import React from 'react';
import { assistanceQuestions, seventhLastArticle } from '../shared/textContent';
import { StyledSupportMenu } from './SupportMenu.style';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { ContainerAbout } from '../shared/ContainerAbout/ContainerAbout';

import growth from '../pic/growth.svg';
import success from '../pic/success.svg';
import security from '../pic/security.svg';

interface ISupportBlockInformationProps {
	icon: React.ReactElement;
	title: string;
	content: string[];
}

export const SupportMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const firstBlock = seventhLastArticle[0];
	const secondBlock = seventhLastArticle[2];
	const thirdBlock = seventhLastArticle[1];

	const SupportBlockInformation: React.FC<ISupportBlockInformationProps> = ({
		icon,
		title,
		content,
	}) => (
		<Box component="div" className="supportMenu-block">
			<Box component="div" className="supportMenu-block-icon">
				{icon}
				<Box component="div" className="supportMenu-block-title">
					{title}
				</Box>
			</Box>
			<Box component="div" className="supportMenu-block-content">
				{content.map((item, index) => (
					<Box key={index}>{item}</Box>
				))}
			</Box>
		</Box>
	);

	return (
		<StyledSupportMenu theme={theme}>
			<Box component="div" className="supportMenu-wrapper">
				<Box component="div" className="supportMenu-subtitle">
					{assistanceQuestions.map((value, index) => (
						<Box key={index}>{value}</Box>
					))}
				</Box>
				<Box component="div" className="supportMenu-first-second-block">
					<ContainerAbout>
						<SupportBlockInformation
							title={firstBlock.title}
							content={firstBlock.content}
							icon={
								<Box component="img" className="current-icon" src={security} />
							}
						/>
					</ContainerAbout>
					<ContainerAbout>
						<SupportBlockInformation
							title="Examples of how chatbots helped businesses"
							content={secondBlock.content}
							icon={
								<Box component="img" className="current-icon" src={growth} />
							}
						/>
					</ContainerAbout>
				</Box>
				<Box component="div" className="supportMenu-third-block">
					<ContainerAbout>
						<Box component="div" className="supportMenu-block">
							<Box component="div" className="supportMenu-block-icon">
								<Box component="img" className="current-icon" src={success} />
							</Box>
							<Box component="div" className="supportMenu-block-title-content">
								<Box component="div" className="supportMenu-block-title">
									Success Stories
								</Box>
								<Box component="div" className="supportMenu-block-content">
									{thirdBlock.content.map((item, index) => (
										<Box key={index}>{item}</Box>
									))}
								</Box>
							</Box>
						</Box>
					</ContainerAbout>
				</Box>
			</Box>
		</StyledSupportMenu>
	);
};
