// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialConfigModelMenuInputsValueState {
	website: string;
	flavor: string;
	reCaptchaKey: string;
	reCaptchaTemperature: string;
	greeting: string;
	prefix: string;
	reCaptchaSecretKey: string;
}

export const initialState: IInitialConfigModelMenuInputsValueState = {
	website: '',
	flavor: '',
	reCaptchaKey: '',
	reCaptchaTemperature: '',
	reCaptchaSecretKey: '',
	greeting: '',
	prefix: '',
};

const configModelMenuInputsSlice = createSlice({
	name: 'configModelMenuInputs',
	initialState,
	reducers: {
		setWebsiteConfigModelMenu(state, action: PayloadAction<string>) {
			state.website = action.payload;
			return state;
		},
		setFlavorConfigModelMenu(state, action: PayloadAction<string>) {
			state.flavor = action.payload;
			return state;
		},
		setReCaptchaKeyConfigModelMenu(state, action: PayloadAction<string>) {
			state.reCaptchaKey = action.payload;
			return state;
		},
		setReCaptchaTemperatureConfigModelMenu(
			state,
			action: PayloadAction<string>
		) {
			state.reCaptchaTemperature = action.payload;
			return state;
		},
		setReCaptchaSecretKeyConfigModelMenu(state, action: PayloadAction<string>) {
			state.reCaptchaSecretKey = action.payload;
			return state;
		},
		setGreetingConfigModelMenu(state, action: PayloadAction<string>) {
			state.greeting = action.payload;
			return state;
		},
		setPrefixConfigModelMenu(state, action: PayloadAction<string>) {
			state.prefix = action.payload;
			return state;
		},
		setStateConfigModelMenuFromStorage(
			state,
			action: PayloadAction<IInitialConfigModelMenuInputsValueState>
		) {
			const allItems: IInitialConfigModelMenuInputsValueState = action.payload;
			if (allItems.website !== undefined) {
				state.website = allItems.website;
			}
			if (allItems.greeting !== undefined) {
				state.greeting = allItems.greeting;
			}
			if (allItems.prefix !== undefined) {
				state.prefix = allItems.prefix;
			}
			if (allItems.reCaptchaKey !== undefined) {
				state.reCaptchaKey = allItems.reCaptchaKey;
			}
			if (allItems.reCaptchaSecretKey !== undefined) {
				state.reCaptchaSecretKey = allItems.reCaptchaSecretKey;
			}
			if (allItems.flavor !== undefined) {
				state.flavor = allItems.flavor;
			}
			return state;
		},
	},
});

export const {
	setWebsiteConfigModelMenu,
	setFlavorConfigModelMenu,
	setReCaptchaTemperatureConfigModelMenu,
	setReCaptchaKeyConfigModelMenu,
	setReCaptchaSecretKeyConfigModelMenu,
	setGreetingConfigModelMenu,
	setPrefixConfigModelMenu,
	setStateConfigModelMenuFromStorage,
} = configModelMenuInputsSlice.actions;
export const configModelMenuInputsReducer = configModelMenuInputsSlice.reducer;
