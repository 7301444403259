import React from 'react';
import { Box } from '@mui/material';
import { StyledTelegramMenu } from './TelegramMenu.style';
import {
	StepElement,
	type IStepElementProps,
} from '../../shared/StepElement/StepElement';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { MessageExample } from './additional/MessageExample/MessageExample';
import { TokenAlertMessage } from './additional/TokenAlertMessage/TokenAlertMessage';
import { useWindowSize } from '../../../../app/hooks/useWindowSize';
import { InputSendToken } from '../../shared/InputSendToken/InputSendToken';
import { ChatbotsTable } from '../../shared/ChatbotsTable/ChatbotsTable';
import { HelpIntegrate } from '../../shared/HelpIntegrate/HelpIntegrate';

export const TelegramMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const windowSize = useWindowSize();
	const innerWindowWidth = windowSize[0];

	const steps: IStepElementProps[] = [
		{
			index: 1,
			header: 'Get Telegram bot token',
			stepList: [
				{
					stepListElement: (
						<Box component="span" className="step-Element-span-wrapper">
							Go to&nbsp;
							<Box
								component="a"
								className="step-Element-link"
								href="https://t.me/BotFather"
								target="blank"
							>
								https://t.me/BotFather
							</Box>
							&nbsp;and press start
						</Box>
					),
				},
				{ stepListElement: 'Send him /newbot command' },
				{
					stepListElement:
						'Then you need to set up bots name, @username, and avatar',
				},
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{ position: 'relative' }}
						>
							At the end you will get a message like this:
							<MessageExample />
						</Box>
					),
					indentation: innerWindowWidth > 800 ? 240 : 0,
				},
				{
					stepListElement: (
						<Box
							component="div"
							className="step-Element-div-wrapper"
							sx={{
								position: 'relative',
								display: 'flex',
								flexDirection: 'column',
								gap: '16px',
							}}
						>
							Use similar token to access the HTTP API:
							<Box
								component="div"
								className="step-Element-token-example"
								sx={{
									color: currentTheme.mainFontColor,
									border: `1px solid ${currentTheme.coderfySecondaryColor}`,
									borderRadius: '8px',
									pt: 1,
									pb: 1,
									pl: 2,
									pr: 2,
									position: innerWindowWidth > 800 ? 'absolute' : 'relative',
									left: innerWindowWidth > 800 ? '-16px' : 0,
									top:
										innerWindowWidth > 1500
											? '40px'
											: innerWindowWidth > 800
											? '123px'
											: 0,
									width: innerWindowWidth > 800 ? 'max-content' : '100%',
									hyphens: 'auto',
									wordBreak: 'break-all',
								}}
							>
								224236343:AAGQbNeoaCoG6a2sbdd0D_4Sr9ia7U-b
							</Box>
							<TokenAlertMessage />
						</Box>
					),
					indentation:
						innerWindowWidth > 1500 ? 56 : innerWindowWidth > 800 ? 144 : 0,
				},
				{
					stepListElement: (
						<Box component="span" className="step-Element-span-wrapper">
							For a description of the Bot API, see this page:&nbsp;
							<Box
								component="a"
								className="step-Element-link"
								href="https://core.telegram.org/bots/api"
								target="blank"
							>
								https://core.telegram.org/bots/api
							</Box>
						</Box>
					),
				},
			],
		},
		{
			index: 2,
			header:
				'Copy the token and paste it to website. Select your chatbot that you want to integrate with Telegram',
			stepList: [
				{
					stepListElement: <InputSendToken uniqueName="Telegram" />,
					withoutPointDot: true,
				},
			],
		},
		{ index: 3, header: 'Test your bot', stepList: [] },
	];

	return (
		<StyledTelegramMenu theme={theme}>
			<ChatbotsTable keyForDataDisplay="Telegram" />
			<Box component="div" className="integrationMenu-header">
				To integrate Telegram with Coderfy Chatbot you need:
				{windowSize[0] > 1200 && <HelpIntegrate />}
			</Box>
			{steps.map((step) => (
				<StepElement
					key={step.index}
					index={step.index}
					stepList={step.stepList}
					header={step.header}
				/>
			))}
			{windowSize[0] <= 1200 && <HelpIntegrate />}
		</StyledTelegramMenu>
	);
};
