import React from 'react';
import { StyledSharedInputAlert } from './SharedInputAlert.style';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';

interface ISharedInputAlertProps {
	uniqueNameOfInput: string;

	isValid: boolean;
	isEmpty: boolean;
	alertMessageV6?: string;
}

export const SharedInputAlert: React.FC<ISharedInputAlertProps> = ({
	uniqueNameOfInput,
	isValid,
	isEmpty,
	alertMessageV6,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	if (alertMessageV6 !== undefined) {
		if (!isValid && isEmpty) {
			return (
				<StyledSharedInputAlert theme={theme} className="alertMessage">
					{alertMessageV6}
				</StyledSharedInputAlert>
			);
		} else {
			return <></>;
		}
	} else {
		const AlertTemporaryDomain: React.FC = () => (
			<StyledSharedInputAlert theme={theme} className="alertMessage">
				Please, enter in the format: name(.subdomain).domain
			</StyledSharedInputAlert>
		);
		const AlertEmail: React.FC = () => (
			<StyledSharedInputAlert theme={theme} className="alertMessage">
				Please, enter the correct E-mail
			</StyledSharedInputAlert>
		);
		const AlertPassword: React.FC = () => (
			<StyledSharedInputAlert className="alertMessage" theme={theme}>
				The password must be at least 8 characters long and contain two numbers,
				two upper case and two lower case letters
			</StyledSharedInputAlert>
		);
		const AlertName: React.FC = () => (
			<StyledSharedInputAlert theme={theme} className="alertMessage">
				Are you sure your name has less than two letters?
			</StyledSharedInputAlert>
		);
		const AlertSurname: React.FC = () => (
			<StyledSharedInputAlert theme={theme} className="alertMessage">
				Are you sure your surname has less than two letters?
			</StyledSharedInputAlert>
		);
		const AlertHeaderButtonText: React.FC = () => (
			<StyledSharedInputAlert theme={theme} className="alertMessage">
				Please enter a valid value without special characters
			</StyledSharedInputAlert>
		);
		const AlertVariable: React.FC = () => (
			<StyledSharedInputAlert theme={theme} className="alertMessage">
				Please enter a correct value
			</StyledSharedInputAlert>
		);
		const AlertVariableAutocomplete: React.FC = () => (
			<StyledSharedInputAlert theme={theme} className="alertMessage">
				Please choose a correct value
			</StyledSharedInputAlert>
		);

		if (!isValid && isEmpty) {
			switch (uniqueNameOfInput) {
				case 'domain-login':
					return <AlertTemporaryDomain />;

				case 'e-mail-login':
					return <AlertEmail />;

				case 'password-login':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter the password
						</StyledSharedInputAlert>
					);

				case 'domain-generator-menu':
					return <AlertTemporaryDomain />;

				case 'tone-autocomplete-generator-menu':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, choose the Tone
						</StyledSharedInputAlert>
					);

				case 'openAI-key-generator-menu':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter the correct OpenAI key
						</StyledSharedInputAlert>
					);

				case 'reCaptcha-key-generator-menu':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter the correct ReCaptcha key
						</StyledSharedInputAlert>
					);

				case 'reCaptcha-secret-key-generator-menu':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter the correct ReCaptcha secret key
						</StyledSharedInputAlert>
					);

				case 'style-autocomplete-generator-menu':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, choose the style
						</StyledSharedInputAlert>
					);

				case 'header-generator-menu':
					return <AlertHeaderButtonText />;

				case 'button-text-generator-menu':
					return <AlertHeaderButtonText />;

				case 'name-registration':
					return <AlertName />;

				case 'surname-registration':
					return <AlertSurname />;

				case 'domain-registration':
					return <AlertTemporaryDomain />;

				case 'e-mail-registration':
					return <AlertEmail />;

				case 'password-first-registration':
					return <AlertPassword />;

				case 'name-contacts':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Add a subject line for your email
						</StyledSharedInputAlert>
					);

				case 'e-mail-contacts':
					return <AlertEmail />;

				case 'type-text-area-contacts':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							We would like to know a little more information
						</StyledSharedInputAlert>
					);

				case 'e-mail-forgot':
					return <AlertEmail />;

				case 'username-change-info':
					return <AlertName />;

				case 'email-change-info':
					return <AlertEmail />;

				case 'password-change-info':
					return <AlertPassword />;

				case 'surname-change-info':
					return <AlertSurname />;

				case 'password-change-info-repeat':
					return <AlertPassword />;

				case 'password-reset':
					return <AlertPassword />;

				case 'code-email-confirm':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter the six-digit code from your email
						</StyledSharedInputAlert>
					);

				case 'tone-autocomplete-detailed-bot-card-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, choose the Tone
						</StyledSharedInputAlert>
					);
				case 'style-autocomplete-detailed-bot-card-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, choose the style
						</StyledSharedInputAlert>
					);
				case 'style-name-detailed-bot-card-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, choose the name Chatbot
						</StyledSharedInputAlert>
					);
				case 'structure-autocomplete-detailed-bot-card-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, choose the chatbot structure
						</StyledSharedInputAlert>
					);
				case 'style-css-autocomplete-detailed-bot-card-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, choose the style
						</StyledSharedInputAlert>
					);

				case 'header-detailed-bot-card-changeableInput':
					return <AlertHeaderButtonText />;

				case 'button-text-detailed-bot-card-changeableInput':
					return <AlertHeaderButtonText />;

				case 'payments-card-number':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter a valid card number
						</StyledSharedInputAlert>
					);
				case 'payments-card-expiry-month':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Enter month
						</StyledSharedInputAlert>
					);
				case 'payments-card-expiry-year':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Enter year
						</StyledSharedInputAlert>
					);
				case 'payments-card-cvc':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Enter CVC
						</StyledSharedInputAlert>
					);

				case 'import-url-for-scrape':
					return <AlertTemporaryDomain />;

				case 'createChangePrompt-input-modal':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter correct prompt
						</StyledSharedInputAlert>
					);
				case 'createChangeCompletion-input-modal':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter correct completion
						</StyledSharedInputAlert>
					);

				case 'testBot-input-modal':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter correct prompt
						</StyledSharedInputAlert>
					);

				case 'testBot-textarea-answer-loading-content-modal':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter correct completion
						</StyledSharedInputAlert>
					);

				case 'website-model-menu-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter correct domain
						</StyledSharedInputAlert>
					);
				case 'flavor-model-menu-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter flavor
						</StyledSharedInputAlert>
					);
				case 'reCAPTCHA-model-menu-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter reCAPTCHA key
						</StyledSharedInputAlert>
					);
				case 'reCAPTCHA-key-model-menu-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter reCAPTCHA secret key
						</StyledSharedInputAlert>
					);
				case 'greeting-model-menu-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter a greeting
						</StyledSharedInputAlert>
					);
				case 'prefix-model-menu-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter a prefix
						</StyledSharedInputAlert>
					);

				case 'openAI-API-key-openAI-menu-changeableInput':
					return (
						<StyledSharedInputAlert theme={theme} className="alertMessage">
							Please, enter a correct openAI key
						</StyledSharedInputAlert>
					);
				case 'temperature-openAI-menu-changeableInput':
					return <AlertVariable />;
				case 'threshold-openAI-menu-changeableInput':
					return <AlertVariable />;
				case 'max-context-tokens-openAI-menu-changeableInput':
					return <AlertVariable />;
				case 'max-request-tokens-openAI-menu-changeableInput':
					return <AlertVariable />;
				case 'max-response-tokens-openAI-menu-changeableInput':
					return <AlertVariable />;
				case 'max-message-tokens-openAI-menu-changeableInput':
					return <AlertVariable />;
				case 'completion-chat-openAI-menu-changeableInput':
					return <AlertVariableAutocomplete />;
				case 'vector-model-openAI-menu-changeableInput':
					return <AlertVariableAutocomplete />;
				case 'reCaptcha-temperature-model-menu-changeableInput':
					return <AlertVariable />;

				case 'sendToken-input-Telegram':
					return <AlertVariable />;
				case 'sendToken-input-Viber':
					return <AlertVariable />;
				case 'sendToken-input-Messenger':
					return <AlertVariable />;
				case 'sendToken-input-WhatsApp':
					return <AlertVariable />;

				default:
					return <></>;
			}
		} else {
			return <></>;
		}
	}
};
