import React from 'react';
import { Box } from '@mui/material';
import { StyledModalConfirmRecurrentPaymentComponent } from './ModalConfirmRecurrentPaymentComponent.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { SharedMainButton } from '../../../shared/SharedMainButton/SharedMainButton';
import { SharedTransparentButton } from '../../../shared/SharedTransparentButton/SharedTransparentButton';

interface IModalConfirmRecurrentPaymentComponentProps {
	executePaymentRecurrentFondy: () => Promise<void>;
	handleCloseModal: () => void;
	handleOpenModalAfterRecurrent: () => void;
}

export const ModalConfirmRecurrentPaymentComponent: React.FC<
	IModalConfirmRecurrentPaymentComponentProps
> = ({
	executePaymentRecurrentFondy,
	handleCloseModal,
	handleOpenModalAfterRecurrent,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const theme = { currentTheme };

	return (
		<StyledModalConfirmRecurrentPaymentComponent theme={theme}>
			{/* <Box component="div" className="modalConfirmRecurrentPaymentComponent-wrapper"> */}
			<Box
				component="div"
				className="modalConfirmRecurrentPayment-title-subtitle"
			>
				<Box component="div" className="modalConfirmRecurrentPayment-subtitle">
					Are you sure you want to change your default payment method and pay
					for your purchase?
				</Box>
			</Box>
			<Box component="div" className="modalConfirmRecurrentPayment-input-url">
				<Box
					component="div"
					className="modalConfirmRecurrentPayment-exe-buttons"
				>
					<Box
						component="div"
						className="modalConfirmRecurrentPayment-wrapper-button"
					>
						<SharedMainButton
							isDeleteButton={false}
							text="Yes"
							buttonFunction={() => {
								void executePaymentRecurrentFondy();
								handleCloseModal();
								handleOpenModalAfterRecurrent();
							}}
						/>
					</Box>
					<Box
						component="div"
						className="modalConfirmRecurrentPayment-wrapper-button"
					>
						<SharedTransparentButton
							text={'Cancel'}
							buttonFunction={handleCloseModal}
						/>
					</Box>
				</Box>
			</Box>
			{/* </Box> */}
		</StyledModalConfirmRecurrentPaymentComponent>
	);
};
