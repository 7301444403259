import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StyledCustomStepper } from './CustomStepper.style';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { Box } from '@mui/material';

const steps = [
	'Enter domain',
	'Choose a chatbot template',
	'Choose a style and customize chat',
	'Get the script and use it',
];

export const CustomStepper: React.FC = () => {
	const currentStep = useAppSelector(
		(state: RootState) => state.currentStepGenerator
	);
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const CurrentStepBigText: React.FC = () => {
		return (
			<Box component="div" className="mobile-step">
				<Box component="div" className="mobile-step-current">
					STEP {(currentStep + 1).toString()}
				</Box>
				<Box component="div" className="mobile-step-text">
					{steps[currentStep]}
				</Box>
			</Box>
		);
	};

	return (
		<StyledCustomStepper theme={currentTheme}>
			<Stepper activeStep={currentStep} alternativeLabel>
				{steps.map((label, index) => (
					<Step key={label}>
						<StepLabel>
							<Box>STEP {index + 1}</Box>
							<Box component="div" className="text-in-step">
								{label}
							</Box>
						</StepLabel>
					</Step>
				))}
			</Stepper>
			<CurrentStepBigText />
		</StyledCustomStepper>
	);
};
