/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledLayoutAccount = styled.div`
	display: flex;
	background: ${(props) => props.theme.mainRootBackground};
	position: relative;

	& .layout-menu-wrapper {
		position: relative;
		width: 100%;
		min-height: 100vh;

		& .layout-menu-title {
			position: absolute;
			top: 40px;
			left: 60px;
			font-style: normal;
			font-weight: 600;
			font-size: 36px;
			line-height: 100%;
			color: ${(props) => props.theme.mainFontColor};
			padding: 0px !important;
			height: max-content;

			& .layout-menu-subtitle {
				position: absolute;
				top: 60px;
				left: 0px;
				padding: 0px !important;
				font-weight: 400;
				font-size: 24px;
				line-height: 145%;
				width: 50vw;
			}
		}

		& > div {
			background: ${(props) => props.theme.menuBackground};
			padding: 0px 4rem;
			height: 100%;

			& > div {
				padding: 140px 0px;
			}
		}
	}

	& .layout-head-wrapper {
		position: absolute;
		top: 50px;
		right: 50px;
	}

	& .navbar-menu-icon-navigation {
		display: none;
		position: fixed;
		right: 50px;
		top: 40px;
		cursor: pointer;
		z-index: 900;

		& svg {
			width: 30px;
			height: 30px;
		}

		& .close-icon {
			& path {
				stroke: ${(props) => props.theme.disabledBorder};
			}
		}

		& .menu-icon {
			& path {
				stroke: ${(props) => props.theme.mainFontColor};
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .layout-menu-wrapper {
			& .layout-menu-title {
				font-size: 24px;

				& .layout-menu-subtitle {
					font-size: 20px;
				}
			}

			& > div {
				& > div {
					padding: 100px 0px;
				}
			}
		}
	}

	/* After hide navbar */
	@media screen and (max-width: 1000px) {
		& .layout-menu-wrapper {
			& .layout-menu-title {
				& .layout-menu-subtitle {
					width: 80vw;
				}
			}

			& > div {
				& > div {
				}
			}
		}
		& .navbar-menu-icon-navigation {
			display: flex;
		}
	}

	@media screen and (max-width: 800px) {
		& .layout-menu-wrapper {
			& .layout-menu-title {
				position: absolute;
				top: 40px;
				left: 20px;
				font-size: 24px;
				line-height: 100%;
				max-width: 50%;

				& .layout-menu-subtitle {
					top: 80px;
				}
			}

			& > div {
				padding: 0px 1rem;
			}
		}
	}

	@media screen and (max-width: 360px) {
		& .layout-menu-wrapper {
			& .layout-menu-title {
				& .layout-menu-subtitle {
					top: 140px;
				}
			}
		}
	}
`;
