import React from 'react';
import { Box } from '@mui/material';
import { StyledErrorMenu } from './ErrorMenu.style';
import { SharedTitleSubtitle } from '../shared/SharedTitleSubtitle/SharedTitleSubtitle';
import { NavLink } from 'react-router-dom';
import { SharedMainButton } from '../shared/SharedMainButton/SharedMainButton';
import brainLogo from './pic/brain.svg';

export const ErrorMenu: React.FC = () => {
	return (
		<StyledErrorMenu>
			<Box component="div" className="error-wrapper">
				<Box component="div" className="error-area">
					<Box
						component="img"
						className="error-logo-image"
						alt="error-logo-image"
						src={brainLogo}
					/>
					<SharedTitleSubtitle
						title="Ooops..."
						subtitle="404 | This page doesn't exist, please return to the main page:"
						width="300px"
					/>
					<Box component="div" className="error-button">
						<NavLink to="/">
							<SharedMainButton
								text="Return"
								buttonFunction={() => {}}
								isDeleteButton={false}
							/>
						</NavLink>
					</Box>
				</Box>
			</Box>
		</StyledErrorMenu>
	);
};
