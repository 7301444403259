import React from 'react';
import { Box } from '@mui/material';
import { WrappedInput } from '../shared/Input/WrappedInput/WrappedInput';
import { useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';

export interface IUserInformation {
	title: string;
	value: string;
	id: string;
	type: string;
}

interface ICurrentUserInfoCard {
	currentInformation: IUserInformation;
}

export const CurrentUserInfoCard: React.FC<ICurrentUserInfoCard> = ({
	currentInformation,
}) => {
	const isSendChangeInformationButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForChangeInfo
	);
	const validInputsStatusChangeInformation = useAppSelector(
		(state: RootState) => state.isValidChangeInformationValues
	);
	const { isValidUsernameChangeInfo, isValidSurnameChangeInfo } =
		validInputsStatusChangeInformation;

	return (
		<Box component="div" className="currentInformation-wrapper-border">
			<Box component="div" className="currentInformation-new">
				<Box component="div" className="new-title">
					{currentInformation.id === 'surname-change-info' ? 'Surname' : 'Name'}
				</Box>
				<Box component="div" className="new-value">
					<WrappedInput
						type={currentInformation.type}
						isEmpty={isSendChangeInformationButtonPressed}
						placeholder={currentInformation.title}
						uniqueNameOfInput={currentInformation.id}
						value={currentInformation.value}
						autocomplete={false}
						isValid={
							currentInformation.id === 'surname-change-info'
								? isValidSurnameChangeInfo
								: currentInformation.id === 'username-change-info' &&
								  isValidUsernameChangeInfo
						}
					/>
				</Box>
			</Box>
		</Box>
	);
};
