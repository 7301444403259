// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import {
	useAppDispatch,
	useMiddlewareHandleInfoAboutSnippet,
} from '../../../../app/hooks';
import { setOpenModalEditCurrentSnippetStatus } from '../../../../app/slices/trainingBotMenu/openModal/openEditCurrentSnippetTrainingBotMenuSlice';

export const useOpenModalEdit = (): ((
	currentSnippetNumber: string,
	prompt: string,
	completion: string
) => void) => {
	const sendCurrentSnippetInfo = useMiddlewareHandleInfoAboutSnippet();
	const dispatch = useAppDispatch();

	return (
		currentSnippetNumber: string,
		prompt: string,
		completion: string
	): void => {
		sendCurrentSnippetInfo(currentSnippetNumber, prompt, completion);
		dispatch(setOpenModalEditCurrentSnippetStatus(true));
	};
};
