// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialForgotInputValueValidStatus {
	isValidEmailForgot: boolean;
}

const initialState: IInitialForgotInputValueValidStatus = {
	isValidEmailForgot: false,
};

const isValidForgotInputValueSlice = createSlice({
	name: 'isValidForgotInputValue',
	initialState,
	reducers: {
		setValidEmailForgotStatus(state, action) {
			state.isValidEmailForgot = action.payload;
			return state;
		},
	},
});

export const { setValidEmailForgotStatus } =
	isValidForgotInputValueSlice.actions;
export const isValidForgotInputValueReducer =
	isValidForgotInputValueSlice.reducer;
