import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IChangeChatbotIntegrationInputState {
	changeChatbotIntegrationInput: string;
}

const initialState: IChangeChatbotIntegrationInputState = {
	changeChatbotIntegrationInput: '',
};

const changeChatbotIntegrationInputSlice = createSlice({
	name: 'changeChatbotIntegrationInput',
	initialState,
	reducers: {
		setChangeChatbotIntegrationInput(state, action: PayloadAction<string>) {
			state.changeChatbotIntegrationInput = action.payload;
			return state;
		},
	},
});

export const { setChangeChatbotIntegrationInput } =
	changeChatbotIntegrationInputSlice.actions;
export const changeChatbotIntegrationInputReducer =
	changeChatbotIntegrationInputSlice.reducer;
