import styled from 'styled-components';

export const StyledSharedPreviewWrapperImg = styled.div`
	/* width: 33%; */
	/* height: 100%; */
	max-height: 425px;
	padding: 24px;
	padding-left: ${(props) => props.theme.paddingLeft};
	border-radius: 16px;
	border: 1px solid #93bce2;
	background: ${(props) => props.theme.currentTheme.templateBg} !important;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: appear-PreviewWrapperImg 0.3s ease-in-out;

	& .currentStyle {
		width: 300px;
		max-width: 100%;
		max-height: 380px;
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.3s ease-in-out;
	}

	& .appear-PreviewWrapperImg {
		animation: appear-PreviewWrapperImg 0.3s ease-in-out;
	}

	@keyframes appear-PreviewWrapperImg {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@media screen and (max-width: 1250px) {
		& .currentStyle {
			width: auto;
			height: 100%;
		}
	}

	@media screen and (max-width: 600px) {
		width: 80%;
	}
`;
