import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialNotVectorisedSnippetsCountMessageTrainingBotMenu {
	notVectorisedSnippetsCountMessage: string;
}

const initialState: IInitialNotVectorisedSnippetsCountMessageTrainingBotMenu = {
	notVectorisedSnippetsCountMessage: '',
};

const notVectorisedSnippetsCountMessageTrainingBotMenuSlice = createSlice({
	name: 'notVectorisedSnippetsCountMessageTrainingBotMenu',
	initialState,
	reducers: {
		setNotVectorisedSnippetsCountMessage(state, action: PayloadAction<string>) {
			state.notVectorisedSnippetsCountMessage = action.payload;
			return state;
		},
	},
});

export const { setNotVectorisedSnippetsCountMessage } =
	notVectorisedSnippetsCountMessageTrainingBotMenuSlice.actions;
export const notVectorisedSnippetsCountMessageTrainingBotMenuReducer =
	notVectorisedSnippetsCountMessageTrainingBotMenuSlice.reducer;
