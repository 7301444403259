import React from 'react';
import { useCheckAuth } from '../../app/hooks';
import { Navigate } from 'react-router-dom';
import { LayoutAccount } from '../../components/shared/LayoutAccount/LayoutAccount';
import { SocialIntegrationMenu } from '../../components/SocialIntegrationMenu/SocialIntegrationMenu';
import { TelegramMenu } from '../../components/SocialIntegrationMenu/MainSocialMenus/TelegramMenu/TelegramMenu';

export const TelegramPage: React.FC = () => {
	const isAuthorization = useCheckAuth();

	return (
		<LayoutAccount title="Telegram integration">
			{isAuthorization ? (
				<SocialIntegrationMenu>
					<TelegramMenu />
				</SocialIntegrationMenu>
			) : (
				<Navigate to="/" replace={true} />
			)}
		</LayoutAccount>
	);
};
