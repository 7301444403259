import React from 'react';
import { Box } from '@mui/material';
import { StyledPersonalCabinetMenu } from './PersonalCabinetMenu.style';
import { PersonalUserCard } from './Cards/PersonalUserCard/PersonalUserCard';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LetsGenerateCard } from './Cards/LetsGenerateCard';
// import { LearningStageCard } from './Cards/LearningStageCard';
import { ProfilesBotsCard } from './Cards/ProfilesBotsCard';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { CardWrapper } from './CardWrapper/CardWrapper';
import { useGetStatisticAboutAccount } from '../../app/hooks/useGetStatisticAboutAccount';
import { StatisticCard } from './Cards/StatisticCard/StatisticCard';
import { ModalTestBot } from '../TrainingBotMenu/ModalTestBot/ModalTestBot';
import { setOpenModalTestBotStatus } from '../../app/slices/trainingBotMenu/openModal/openTestBotTrainingBotMenuSlice';

export const PersonalCabinetMenu: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const { getDifferenceBotsCountFirstRender } = useGetStatisticAboutAccount();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const cards = [
		// {
		// 	key: 2,
		// 	value: loadingStatus() ? (
		// 		<ProgressDots />
		// 	) : getDifferenceBotsCount() ? (
		// 		<LetsGenerateCard />
		// 	) : (
		// 		<></>
		// 	),
		// 	fullSize: false,
		// },
		// { key: 4, value: <LearningStageCard />, fullSize: false },
		{ key: 3, value: <ProfilesBotsCard />, fullSize: true },
		{ key: 1, value: <PersonalUserCard />, fullSize: false },
		{
			key: 5,
			value: (
				<Box component="div" className="unitedComponent">
					Hello
				</Box>
			),
			fullSize: false,
		},
	];

	const isOpenModalTestBot = useAppSelector(
		(state: RootState) => state.openTestBotTrainingBotMenu
	);
	const currentChatbotIdForActionsTrainingBot = useAppSelector(
		(state: RootState) =>
			state.currentChatbotIdForActionsTrainingBotMenu.currentChatbotIdForTest
	);
	const dispatch = useAppDispatch();

	return (
		<StyledPersonalCabinetMenu theme={currentTheme}>
			<Box component="div" className="personal-cabinet-wrapper">
				<Box component="div" className="personal-cabinet-menu">
					<Box component="div" className="personal-cabinet-high-vidgets">
						{getDifferenceBotsCountFirstRender() ? (
							<CardWrapper isFullSize={true} lazyAppear={true}>
								{getDifferenceBotsCountFirstRender() ? (
									<LetsGenerateCard />
								) : (
									<></>
								)}
							</CardWrapper>
						) : (
							<></>
						)}
					</Box>
					<Box component="div" className="personal-cabinet-middle-vidgets">
						<Box component="div" className="unitedComponent">
							<Box sx={{ width: '40%', height: '100%', maxWidth: '370px' }}>
								<CardWrapper isFullSize={true} isFullHeight>
									<PersonalUserCard />
								</CardWrapper>
							</Box>
							<Box sx={{ width: '100%', height: '100%', maxWidth: '860px' }}>
								<StatisticCard />
							</Box>
						</Box>

						<CardWrapper isFullSize={true}>
							<ProfilesBotsCard />
						</CardWrapper>
					</Box>
				</Box>
				{isOpenModalTestBot ? (
					<ModalTestBot
						chatbotId={currentChatbotIdForActionsTrainingBot}
						closeFn={() => {
							dispatch(setOpenModalTestBotStatus(false));
						}}
					/>
				) : (
					<></>
				)}
			</Box>
		</StyledPersonalCabinetMenu>
	);
};
