import styled from 'styled-components';

export const StyledHelpIntegrate = styled.div`
	position: absolute;
	top: 56px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;

	& .helpIntegrate {
		border-radius: 8px;
		background: linear-gradient(
			113deg,
			${(props) => props.theme.currentTheme.integrateGradient.one},
			${(props) => props.theme.currentTheme.integrateGradient.two}
		);
		padding: 32px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: 24px;
		color: ${(props) => props.theme.currentTheme.mainFontColor};
		text-align: center;
		width: 453px;

		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 27px;

		box-shadow: ${(props) => props.theme.currentTheme.boxShadow};

		& .helpIntegrate-button {
			width: 153px;
			background: none;
			border: none;
			padding: 1rem 2rem;
			border-radius: 8px;
			position: relative;

			& .helpIntegrate-button-text {
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 100%;
			}

			&:hover {
				background: linear-gradient(150deg, #3daeff, #5410db);
				color: #fff;
			}

			& .helpIntegrate-button-pic {
				position: absolute;
				left: 0px;
				top: 0px;
				width: 100%;
				height: 100%;
			}
		}
	}

	@media screen and (max-width: 1500px) {
		& .helpIntegrate {
			width: 300px;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;

			& .helpIntegrate-button {
				width: 145px;
			}
		}
	}

	@media screen and (max-width: 1200px) {
		position: relative;
		top: 0px;
		left: 0px;
	}
`;
