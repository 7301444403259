import styled from 'styled-components';

export const StyledSharedDateRangePicker = styled.div`
	& .rs-picker-toggle-wrapper {
		& .rs-picker-toggle.rs-btn {
			background-color: ${(props) =>
				props.theme.currentTheme.cardsBackground} !important;
			color: ${(props) => props.theme.currentTheme.fontColorInput};
			border: 1px solid ${(props) => props.theme.currentTheme.borderInputs};
			height: 40px;
			display: flex;
			align-items: center;
			outline: 4px solid ${(props) => props.theme.currentTheme.cardsBackground};

			&:hover {
				background: linear-gradient(
							${(props) => props.theme.currentTheme.cardsBackground} 0 0
						)
						padding-box,
					linear-gradient(to right, #43f2ff, #691eff) border-box;
				border: 1px solid transparent;
			}
		}
		/* & .rs-picker-toggle-caret.rs-icon { */
		& svg {
			top: 10px;
		}
		/* } */

		/* & .rs-picker-default .rs-picker-toggle {
      background-color: red;
    } */

		& .rs-picker-toggle-textbox {
			background-color: ${(props) =>
				props.theme.currentTheme.cardsBackground} !important;
			color: ${(props) => props.theme.currentTheme.fontColorInput};
			/* border: 1px solid ${(props) =>
				props.theme.currentTheme.borderInputs}; */
			border: none;
		}
		/* & .rs-picker-default {
      background: red !important;
      border: 1px solid pink;
    } */
	}
`;
