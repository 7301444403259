// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const openMobileMenuSlice = createSlice({
	name: 'openMobileMenu',
	initialState,
	reducers: {
		openMobileMenu(state) {
			state = true;
			return state;
		},
		closeMobileMenu(state) {
			state = false;
			return state;
		},
	},
});

export const { openMobileMenu, closeMobileMenu } = openMobileMenuSlice.actions;
export const openMobileMenuReducer = openMobileMenuSlice.reducer;
