// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledLoginMenu = styled.div`
	width: 100%;

	& .menu-wrapper {
		& .menu-inputs-area {
			& .login-inputs-field {
				width: 80%;
				align-self: end;
			}

			& .login-forgot {
				padding-right: 2rem;
				align-self: flex-end;
			}

			& .login-create-under-button {
				display: flex;
			}

			& .error-message-from-api {
				color: rgb(237, 194, 194);
				font-size: 20px;
				font-weight: 700;
			}

			& .login-field-with-other-sources {
				display: flex;
				gap: 2rem;
				align-items: center;
				width: 184px;

				& > button {
					border: none;
					cursor: pointer;
					transition: all 0.1s ease-in;
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0.5rem;
					background: #fff;
					border-radius: 50%;

					&:hover {
						transform: scale(1.1);
					}
					&:active {
						transform: scale(0.95);
					}
				}

				& > div {
					transition: all 0.1s ease-in;

					&:hover {
						transform: scale(1.1);
					}
					&:active {
						transform: scale(0.95);
					}
				}
			}
		}
		& .login-button {
			display: flex;
			justify-content: center;
			width: 100%;
		}
	}

	@media screen and (max-width: 1100px) {
		& .menu-wrapper {
			& .menu-inputs-area {
				& .login-inputs-field {
					align-self: center;
					width: auto;

					& .login-titles-field {
						margin-bottom: 2rem;
					}
				}
			}
		}
	}

	@media screen and (max-width: 350px) {
		& .menu-wrapper {
			& .menu-inputs-area {
				& .login-button {
					width: 85vw !important;
				}
			}
		}
	}
`;
