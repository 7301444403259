// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const StyledRegistrationMenu = styled.div`
	width: 100%;

	& .menu-wrapper {
		& .menu-inputs-area {
			max-width: 470px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			& .registration-login-under-button {
				display: flex;
			}

			& .error-message-from-api {
				color: rgb(237, 194, 194);
				font-size: 20px;
				font-weight: 700;
			}

			& .registration-button {
				display: flex;
				justify-content: center;
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		& .menu-wrapper {
			& .menu-inputs-area {
				max-width: 550px;
				width: 70vmin;

				& .registration-inputs-field {
					align-self: center;
					width: auto;

					& .registration-titles-field {
						margin-bottom: 2rem;
					}
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		& .menu-wrapper {
			& .menu-inputs-area {
				width: 90vmin;
			}

			& .registration-button {
				width: 85vw !important;
			}
		}
	}
`;
