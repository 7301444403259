import styled from 'styled-components';

export const StyledFondyPaymentMethodsMenu = styled.div`
	padding: 0px !important;
	width: 100vw;
	height: 100vh;
	background: #00000020;
	position: fixed;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10000;

	& .fondyPaymentMethodsMenu-wrapper {
		width: 90%;
		height: 90%;
		position: relative;

		& .closePic-wrapper {
			position: absolute;
			top: 5%;
			right: 10%;
			z-index: 10001;

			& .closePic {
				width: 24px;
				height: 24px;
				cursor: pointer;
			}
		}

		& #fondy-window {
			height: 100%;
			overflow: auto;

			& #f {
				background: none;
				font-family: 'Nunito';

				& .mode_wrapper_brPcx {
					display: none;
				}

				& .f-container {
					padding-bottom: 50px;
					border-radius: 8px;
					background: ${(props) => props.theme.currentTheme.cardsBackground};
					color: ${(props) => props.theme.currentTheme.mainFontColor};
				}
				& .f-container-sm {
				}
				& .f-order-desc-text {
					background: none;
				}
				& .f-price {
					& .f-amount {
						color: #1592ec;
					}
				}
				& .f-loyaut {
					padding-top: 50px;
				}
				& .f-wallet-pay-button {
					background: #5e17eb;
				}
				& .f-btn-dark:after {
					background: #5e17eb;
				}
				& .f-button-pay {
					background: #5e17eb;
				}
				& .f-btn-success:before {
					box-shadow: none;
				}
				& .f-btn-success-gradient-custom:after {
					background: #5e17eb;
				}

				& .f-card {
					& .f-card-gradient-custom {
						background: linear-gradient(45deg, #5e17eb, #1592ec);
					}
				}

				& .f-checkout-email {
					background: ${(props) => props.theme.currentTheme.cardsBackground};
					color: ${(props) => props.theme.currentTheme.fontColorInput};
					border: 1px solid ${(props) => props.theme.currentTheme.borderInputs};
					font-family: 'Nunito';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 100%;

					&:hover {
						background: linear-gradient(
									${(props) => props.theme.currentTheme.cardsBackground} 0 0
								)
								padding-box,
							linear-gradient(to right, #43f2ff, #691eff) border-box;
						border: 1px solid transparent;
					}
				}
			}
		}
	}

	@media screen and (min-width: 1200px) {
		& .fondyPaymentMethodsMenu-wrapper {
			& #fondy-window {
				& #f {
					& .f-container {
						max-height: max-content;
					}
				}
			}
		}
	}
`;
