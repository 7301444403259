import React from 'react';
import { Box } from '@mui/material';
import githubPic from '../pic/github.svg';

export const LoginWithGithub: React.FC = () => {
	const baseUrl = process.env.REACT_APP_BASE_LINK;

	return (
		<Box
			component="button"
			className="loginWithGithub-button"
			onClick={() => {
				window.location.replace(
					`${baseUrl !== undefined ? baseUrl : ''}/auth/github/chatbot`
				);
			}}
		>
			<Box component="img" src={githubPic} alt="github-pic" />
		</Box>
	);
};
