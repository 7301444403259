import React from 'react';
import { useCheckAuth } from '../../app/hooks';
import { Navigate } from 'react-router-dom';
import { LayoutAccount } from '../../components/shared/LayoutAccount/LayoutAccount';
import { SocialIntegrationMenu } from '../../components/SocialIntegrationMenu/SocialIntegrationMenu';
import { ViberMenu } from '../../components/SocialIntegrationMenu/MainSocialMenus/ViberMenu/ViberMenu';

export const ViberPage: React.FC = () => {
	const isAuthorization = useCheckAuth();

	return (
		<LayoutAccount title="Viber integration">
			{isAuthorization ? (
				<SocialIntegrationMenu>
					<ViberMenu />
				</SocialIntegrationMenu>
			) : (
				<Navigate to="/" replace={true} />
			)}
		</LayoutAccount>
	);
};
