// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import {
	type TypedUseSelectorHook,
	useDispatch,
	useSelector,
} from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { getItem, setItem } from './utils/storageUtils';
import {
	useGetPersonalInformationQuery,
	useUpdateUserMutation,
} from './api/chatbot.api';
import { setAnswerApiPersonalInformationPersonalInformation } from './slices/answersFromAPI/answerApiPersonalInformationSlice';
import {
	type IUpdateUserData,
	type IPersonalInformationResponse,
	type IResponseDataWithError,
	type IResponsePatchUserSuccess,
} from './api/interfaces/interfaces';
import { setMessageFromApi } from './slices/stateChangeUserInfoMenuSlice/stateChangeUserInfoMenuSlice';
import { setAnswerApiLoginPersonalInformation } from './slices/answersFromAPI/answerApiLoginSlice';
import { setCurrentSnippetNumber } from './slices/trainingBotMenu/currentSnippetTrainingBotMenuSlice';
import {
	setCompletion,
	setPrompt,
} from './slices/trainingBotMenu/inputImportUrl/inputsCreateChangePropmt/createChangePromptValuesSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useCheckAuth = (): boolean => {
	const isAuthorizationFromStorage = getItem('isAuthorization');

	const isAuthorization: boolean = useAppSelector(
		(state: RootState) => state.isAuthorization
	);

	if (isAuthorizationFromStorage !== null) {
		return isAuthorizationFromStorage === 'true';
	} else {
		return isAuthorization;
	}
};

interface IUseGetPersonalInformationReturn {
	personalInformationData: IPersonalInformationResponse | undefined;
	isLoading: boolean;
	isError: boolean;
}

export const useGetPersonalInformation = (
	token: string
): IUseGetPersonalInformationReturn => {
	const {
		data: personalInformationData,
		isLoading,
		isError,
		refetch,
	} = useGetPersonalInformationQuery(token);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (personalInformationData !== undefined) {
			const { id, username, surname, email } = personalInformationData;
			dispatch(
				setAnswerApiPersonalInformationPersonalInformation({
					id,
					username,
					surname,
					email,
				})
			);
			setItem('userData', JSON.stringify({ id, username, surname, email }));
		}
	}, [personalInformationData]);

	useEffect(() => {
		void refetch();
	}, []);

	return { personalInformationData, isLoading, isError };
};

export const useUpdateUserData = (): IUpdateUserData => {
	const validInputsStatusChangeInformation = useAppSelector(
		(state: RootState) => state.isValidChangeInformationValues
	);

	const {
		// isValidUsernameChangeInfo,
		// isValidSurnameChangeInfo,
		isValidPasswordRepeatChangeInfo,
		isValidPasswordChangeInfo,
	} = validInputsStatusChangeInformation;

	const changeInputsValue = useAppSelector(
		(state: RootState) => state.changeInformationInputsValue
	);
	const { username, password, surname, repeatPassword } = changeInputsValue;

	const result: IUpdateUserData = {
		username,
		surname,
		resetToken: 'string',
	};
	if (
		repeatPassword === password &&
		repeatPassword.length > 0 &&
		password.length > 0 &&
		isValidPasswordRepeatChangeInfo &&
		isValidPasswordChangeInfo
	) {
		result.password = password;
		return result;
	} else {
		return result;
	}
};

interface IDataResponseUpdateUser {
	data: IResponsePatchUserSuccess;
}

// interface IUseUpdateExecuteFunctionProps {
// 	setAnswerApiLoginPersonalInformation: ActionCreatorWithPayload<JSX.Element | null, string>
// }

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useUpdateExecuteFunction = () => {
	const [updateUser] = useUpdateUserMutation();

	const updateUserData = useUpdateUserData();

	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const changeInputsValue = useAppSelector(
		(state: RootState) => state.changeInformationInputsValue
	);

	const { username, surname } = changeInputsValue;

	const dispatch = useAppDispatch();

	const updateExecuteFunction = (): void => {
		updateUser({
			updateUserData,
			token,
		})
			.then(
				async (response: IDataResponseUpdateUser | IResponseDataWithError) => {
					// console.log(response);
					if ('data' in response) {
						// console.log(response.data.data);
						dispatch(setMessageFromApi(response.data.data));

						const loginDataFromApiPrevious = getItem('loginDataFromApi');
						const id =
							loginDataFromApiPrevious !== null
								? JSON.parse(loginDataFromApiPrevious).id
								: 0;

						const personalInformationFromApi = {
							id,
							username,
							surname,
						};

						dispatch(
							setAnswerApiLoginPersonalInformation(personalInformationFromApi)
						);
						setItem(
							'loginDataFromApi',
							JSON.stringify(personalInformationFromApi)
						);
					} else if ('error' in response) {
						// console.log(response.error);
						dispatch(setMessageFromApi(response.error.data.message));
					}
				}
			)
			.catch((error) => {
				console.log(error);
				dispatch(setMessageFromApi('Server error: Failed to fetch'));
			});
	};
	return updateExecuteFunction;
};

export const useIsDarkTheme = (): boolean => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	if (currentTheme.mainRootBackground === '#1A1D21') {
		return true;
	} else {
		return false;
	}
};

export const useMiddlewareHandleInfoAboutSnippet = (): ((
	currentSnippetNumber: string,
	prompt: string | undefined,
	completion: string | undefined
) => void) => {
	const dispatch = useAppDispatch();

	return (
		currentSnippetNumber: string,
		prompt: string | undefined,
		completion: string | undefined
	) => {
		dispatch(setCurrentSnippetNumber(currentSnippetNumber));
		if (prompt !== undefined && completion !== undefined) {
			dispatch(setPrompt(prompt));
			dispatch(setCompletion(completion));
		}
	};
};
