import styled from 'styled-components';

export const StyledWrappedCardDetailedPlatform = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 500;
	max-width: 690px;
	/* height: 380px; */
	/* width: 100%; */
	color: ${(props) => props.theme.currentTheme.mainFontColor};

	height: 500px;
	/* height: max-content; */
	width: 100%;
	/* margin-top: ${(props) => props.theme.margin}; */
	/* max-width: 360px; */
	/* height: 100%; */
	/* background-size: 100% 100%; */
	/* background-repeat: no-repeat; */

	/* background-position: center;
background-clip: padding-box;
background-origin: border-box; */
	/* overflow: scroll; */

	& .folder-pic {
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: -1;
		width: 100%;
		height: 100%;
		overflow: hidden;

		& img {
			position: absolute;
			top: -10px;
			left: -10px;
			width: 100%;
			height: 100%;
		}
	}

	& .wrappedCardDetailedPlatform-high-block {
		display: flex;
		justify-content: space-between;
		padding: 3.2rem 3rem 1rem 3rem;

		& .wrappedCardDetailedPlatform-high-block-logo {
			max-width: 176px;
			height: 40px;
			width: 40%;
			display: flex;
			align-items: center;

			& svg {
				/* height: auto;
      width: auto; */
				max-width: 150px;
				width: auto;
				height: 40px;
			}

			& #drupal-logo {
				height: 35px;
			}
		}

		& .wrappedCardDetailedPlatform-high-block-name {
			width: 50%;
			font-size: 24px;
			line-height: 145%;
			/* padding-left: 30%; */
			display: flex;
			justify-content: center;
		}
	}

	& .wrappedCardDetailedPlatform-text-block {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 309px;
		position: relative;
		z-index: 550;
		padding: 32px;
		padding-top: 10%;

		& #prestashop-text-content {
			font-size: 0.85em;
		}

		& .wrappedCardDetailedPlatform-text-content {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		& .wrappedCardDetailedPlatform-text-value {
		}
	}

	& .wrapped-card-background {
		padding: 4px;
		/* width: 352px; */
		/* height: 301px; */
		width: 90%;
		height: 90%;
		background: ${(props) => props.theme.currentTheme.platformsCardColor};
		border-radius: 20px;
		position: absolute;
		z-index: -1;
		top: 20px;
		left: 15px;
	}

	@media screen and (max-width: 1300px) {
		height: 500px;

		& .wrappedCardDetailedPlatform-high-block {
			padding: 3.2rem 3rem 1rem 2.5rem;
			/* padding: 2.5rem 3rem 1rem 3rem; */
			& .wrappedCardDetailedPlatform-high-block-name {
				width: 40%;
			}
		}

		& .wrappedCardDetailedPlatform-text-block {
			padding-top: 25%;
		}

		& .wrappedCardDetailedPlatform-high-block {
			& .wrappedCardDetailedPlatform-high-block-logo {
				& svg {
					max-width: 100px;
					width: auto;
					height: 40px;
				}
			}
		}
	}

	@media screen and (max-width: 700px) {
		height: max-content;

		& .folder-pic {
			width: 100%;
		}

		& .wrappedCardDetailedPlatform-high-block {
			/* padding: 3.2rem 3rem 1rem 3rem; */
			padding: 2.5rem 3rem 1rem 3rem;
			& .wrappedCardDetailedPlatform-high-block-name {
				width: 40%;
				/* width: 50%; */
			}
		}

		& .wrappedCardDetailedPlatform-text-block {
			padding-top: 0px;
		}

		& .wrappedCardDetailedPlatform-high-block {
			& .wrappedCardDetailedPlatform-high-block-logo {
				& svg {
					max-width: 100%;
					width: auto;
					height: 40px;
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		& .folder-pic {
			width: 105%;
		}
	}

	@media screen and (max-width: 450px) {
		height: 500px;

		& .wrappedCardDetailedPlatform-high-block {
			padding: 3.2rem 3rem 1rem 3rem;

			& .wrappedCardDetailedPlatform-high-block-name {
				width: 40%;
				font-size: 18px;
				/* width: 50%; */
			}
		}

		& .wrappedCardDetailedPlatform-text-block {
			padding-top: 35%;
		}
	}

	@media screen and (max-width: 350px) {
		height: 600px;

		& .wrappedCardDetailedPlatform-high-block {
			padding: 4rem 3rem 1rem 3rem;
		}

		& .wrappedCardDetailedPlatform-text-block {
			padding-top: 75%;
		}

		& .wrapped-card-background {
			top: 30px;
		}
	}
`;
