// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import {
	type IResponseDataWithError,
	type IUserLoginData,
} from '../api/interfaces/interfaces';
import { type IDataResponseLogin } from '../../components/LoginMenu/LoginMenu';
import {
	setAnswerApiLoginErrorMessage,
	setAnswerApiLoginPersonalInformation,
	setAnswerApiLoginStatus,
	setAnswerApiLoginToken,
} from '../slices/answersFromAPI/answerApiLoginSlice';
import { setAuthorizationStatus } from '../slices/isAuthorizationSlice';
import {
	clearStorage,
	removeSessionItem,
	setItem,
	setSessionItem,
} from './storageUtils';
import { type AppDispatch } from '../store';
import { setConfirmEmailIssueStatus } from '../slices/isOpenConfirmEmailIssueSlice';
import { clearLoginAndLogout, ifErrorResponseFromApi } from './utils';
import { googleLogout } from '@react-oauth/google';

export const loginFn = (
	response: IDataResponseLogin | IResponseDataWithError,
	loginData: IUserLoginData,
	dispatch: AppDispatch
): null => {
	const { domain, email } = loginData;

	if ('data' in response && 'user' in response.data) {
		if (
			'isEmailConfirmed' in response.data.user &&
			!response.data.user.isEmailConfirmed
		) {
			removeSessionItem('registrationData');
			// clearSessionStorage();
			setSessionItem(
				'registrationData',
				JSON.stringify({ email: response.data.user.email })
			);
			dispatch(setConfirmEmailIssueStatus(true));
		} else {
			dispatch(setConfirmEmailIssueStatus(false));
			const personalInformationFromApi = {
				id: response.data.user.id,
				username: response.data.user.username,
				surname: response.data.user.surname,
			};

			dispatch(setAnswerApiLoginStatus(true));
			dispatch(
				setAnswerApiLoginPersonalInformation(personalInformationFromApi)
			);
			setItem('loginDataFromApi', JSON.stringify(personalInformationFromApi));
			dispatch(setAnswerApiLoginToken(response.data.token));
			dispatch(setAuthorizationStatus(true));
			setItem('isAuthorization', 'true');
			setItem(
				'loginData',
				JSON.stringify({
					domain,
					email,
				})
			);
			setItem('token', response.data.token);
		}
	} else if ('error' in response) {
		console.log(response.error);
		ifErrorResponseFromApi(response, dispatch, setAnswerApiLoginErrorMessage);
	} else {
		console.log('Empty response');
	}

	return null;
};

export const logout = (dispatch: AppDispatch): void => {
	clearLoginAndLogout(dispatch);
	clearStorage();
	removeSessionItem('registrationData');
	// clearSessionStorage();
	googleLogout();
	window.location.replace('/');
};
