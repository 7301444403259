import React from 'react';
import { Box } from '@mui/material';
import { type IIsFloatDigit } from '../shared/Input/WrappedInput/WrappedInput';
import { useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { SharedWrappedInputWithIcon } from '../shared/Input/SharedWrappedInputWithIcon/SharedWrappedInputWithIcon';

export interface IChangeableInputProps {
	type: string;
	placeholder: string;
	params?: string[];
	uniqueNameOfInput: string;
	isValid: boolean;
	value: string;
	disabled?: boolean;
	icon?: string;
	iconSVG?: JSX.Element;
	tooltip?: string;

	minNumber?: number;
	maxNumber?: number;
	stepNumber?: number;
	maxLength?: number;
	isFloat?: IIsFloatDigit;
}

interface ICurrentBotInformationIssueProps {
	value?: string | null;
	changeableInput: IChangeableInputProps | null;
}

const CurrentBotInformationIssue: React.FC<
	ICurrentBotInformationIssueProps
> = ({ value, changeableInput }) => {
	const isSaveButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusForDetailedBotCard
	);

	if (value !== null) {
		return (
			<Box component="div" className="bot-card-info-value">
				{value}
			</Box>
		);
	} else if (changeableInput !== null) {
		return (
			<Box component="div" className="bot-card-info-value-changeableInput">
				<SharedWrappedInputWithIcon
					type={changeableInput.type}
					placeholder={changeableInput.placeholder}
					isEmpty={isSaveButtonPressed}
					params={changeableInput.params}
					uniqueNameOfInput={changeableInput.uniqueNameOfInput}
					isValid={changeableInput.isValid}
					value={changeableInput != null ? changeableInput.value : ''}
					icon={changeableInput.icon}
					isDisabled={changeableInput.disabled}
					iconSVG={changeableInput.iconSVG}
					tooltip={changeableInput.tooltip}
				/>
			</Box>
		);
	} else {
		return null;
	}
};

interface ICardInfoItemProps {
	title: string;
	value?: string | null;
	changeableInput: IChangeableInputProps | null;
}

export const CardInfoItem: React.FC<ICardInfoItemProps> = ({
	title,
	value,
	changeableInput,
}) => {
	return (
		<Box component="div" className="cardInfoItem">
			<Box component="div" className="in-wrapper-title">
				{title}
			</Box>
			<CurrentBotInformationIssue
				value={value}
				changeableInput={changeableInput}
			/>
		</Box>
	);
};
