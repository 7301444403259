import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledSharedJSCode } from './SharedJSCode.style';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { SharedTransparentButton } from '../SharedTransparentButton/SharedTransparentButton';
import downWhite from '../../Navbar/pic/DownWhite.svg';
import downBlack from '../../Navbar/pic/DownBlack.svg';

interface ISharedJSCode {
	displayedScript: string;
}

export const SharedJSCode: React.FC<ISharedJSCode> = ({ displayedScript }) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const [colorBackgroundJSCode, setColorBackgroundJSCode] = useState('none');
	const [isOpenCode, setIsOpenCode] = useState(false);
	const [textCopyJSCode, setTextCopyJSCode] = useState(displayedScript);

	useEffect(() => {
		setTextCopyJSCode(displayedScript);
	}, [displayedScript]);

	const handleClickEffect = (): void => {
		setColorBackgroundJSCode('#c4b3e6');
		setTextCopyJSCode('COPIED: ' + displayedScript);
		setTimeout(() => {
			setColorBackgroundJSCode('none');
		}, 200);
		setTimeout(() => {
			setTextCopyJSCode(displayedScript);
		}, 700);
	};

	const copyInformation = (): void => {
		void navigator.clipboard.writeText(displayedScript);
		handleClickEffect();
	};

	const theme = {
		background: colorBackgroundJSCode,
		currentTheme,
		heightCode: isOpenCode ? 'max-content' : '68px',
		heightCodeSmallScreen: isOpenCode ? 'max-content' : '60px',
	};

	return (
		<StyledSharedJSCode theme={theme}>
			<Box component="div" className="receive-js-code-area">
				<Box component="div" className="js-code-value">
					<Box component="div" className="js-code-text">
						<Box component="div" className="script-text">
							{textCopyJSCode}
						</Box>
					</Box>
					<Box
						component="div"
						className="js-code-arrow-expand"
						onClick={() => {
							setIsOpenCode(!isOpenCode);
						}}
					>
						{isOpenCode ? (
							<img
								src={
									currentTheme.mainRootBackground === '#1A1D21'
										? downWhite
										: downBlack
								}
							/>
						) : (
							<img
								src={
									currentTheme.mainRootBackground === '#1A1D21'
										? downWhite
										: downBlack
								}
								style={{ transform: 'rotate(90deg)' }}
							/>
						)}
					</Box>
				</Box>
				<Box component="div" className="button-copy">
					<SharedTransparentButton
						text={'Copy'}
						buttonFunction={copyInformation}
						tooltip="Copy the script to be pasted into your HTML code"
					/>
				</Box>
			</Box>
		</StyledSharedJSCode>
	);
};
