import styled from 'styled-components';

export const StyledAddNewPaymentMethodInputsField = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	& .card-number-wrapper {
		display: flex;
		flex-direction: column;
		gap: 8px;
		font-size: 18px;
		font-weight: 400;
		line-height: 28px;

		& input::-webkit-outer-spin-button,
		& input::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}
	}

	& .expiry-cvc-wrapper {
		display: flex;
		justify-content: space-between;

		& .expiry-wrapper {
			display: flex;
			flex-direction: column;
			gap: 8px;
			font-size: 18px;
			font-weight: 400;
			line-height: 28px;

			& input::-webkit-outer-spin-button,
			& input::-webkit-inner-spin-button {
				background: red;
				color: red;
			}
		}

		& .cvc-wrapper {
			display: flex;
			flex-direction: column;
			gap: 8px;
			font-size: 18px;
			font-weight: 400;
			line-height: 28px;

			& input::-webkit-outer-spin-button,
			& input::-webkit-inner-spin-button {
				-webkit-appearance: none;
			}
		}

		& .expiry-all-inputs {
			display: flex;
			gap: 0.5rem;
			padding-right: 30px;
			align-items: flex-start;
			color: ${(props) => props.theme.currentTheme.mainFontColor};

			& .slash {
				padding-top: 20px;
				align-self: flex-start;
			}
		}
	}

	& .shared-input {
		width: 100% !important;
	}

	@media screen and (max-width: 1500px) {
		& .expiry-cvc-wrapper {
			& .expiry-all-inputs {
				& .slash {
					padding-top: 10px;
					align-self: flex-start;
				}
			}
		}
	}
`;
