// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import { StyledAdditionalConfirmationField } from './AdditionalConfirmationField.style';
import { Box } from '@mui/material';
import { useResendEmailMutation } from '../../../app/api/chatbot.api';
import {
	type IResponseDataWithError,
	type IResendEmailResponse,
} from '../../../app/api/interfaces/interfaces';
import { ProgressSpinner } from '../../shared/ProgressSpinner/ProgressSpinner';

interface IAdditionalConfirmationFieldProps {
	email: string;
}

interface IDataResendEmailMenu {
	data: IResendEmailResponse;
}

export const AdditionalConfirmationField: React.FC<
	IAdditionalConfirmationFieldProps
> = ({ email }) => {
	const [timer, setTimer] = useState(0);
	const [visibleTimer, setVisibleTimer] = useState(false);
	const [isActiveButton, setIsActiveButton] = useState(true);

	const [resendEmail, resultOfResendEmail] = useResendEmailMutation();
	const [resendStatus, setResendStatus] = useState('');

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	useEffect(() => {
		timer > 0 &&
			setTimeout(() => {
				setTimer(timer - 1);
			}, 1000);
		if (timer === 0) {
			setIsActiveButton(true);
			setResendStatus('');
			setVisibleTimer(false);
		} else {
			setIsActiveButton(false);
		}
	}, [timer]);

	const theme = {
		active: isActiveButton,
	};

	const handleResendCode = (): void => {
		if (isActiveButton) {
			setVisibleTimer(true);
			setTimer(59);

			// console.log(email);

			resendEmail({ email })
				.then((response: IDataResendEmailMenu | IResponseDataWithError) => {
					// console.log(response);
					if ('data' in response) {
						// console.log('success');
						setResendStatus(response.data.message);
					} else if ('error' in response) {
						// console.log(response.error.data.message);
						setResendStatus(response.error.data.message);
					} else {
						console.log('');
						// console.log('empty response');
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	useEffect(() => {
		resultOfResendEmail.isLoading &&
			setLoadingErrorContent(<ProgressSpinner />);
		resultOfResendEmail.isError ||
			(resultOfResendEmail.isSuccess &&
				setLoadingErrorContent(
					<Box
						component="div"
						sx={{ color: '#fff !important' }}
						className="error-message-from-api"
					>
						{resendStatus}
					</Box>
				));
	}, [resultOfResendEmail, resendStatus]);

	return (
		<StyledAdditionalConfirmationField theme={theme}>
			<Box
				component="button"
				disabled={!isActiveButton}
				className="button-resend-code"
				onClick={handleResendCode}
			>
				<Box>Resend code</Box>
				{visibleTimer && <Box component="div"> (00:{timer})</Box>}
			</Box>
			{loadingErrorContent !== null && loadingErrorContent}
		</StyledAdditionalConfirmationField>
	);
};
