import { type Crop } from 'react-image-crop';
import { useAppDispatch } from '../hooks';
import {
	setIconChatbotChoosing,
	setIconChatbotChoosingForCreationChatbot,
	setIconCreateObjectURL,
} from '../slices/iconChatbot/iconChatbotSlice';
import { useEffect, useState } from 'react';

interface IUseGetCroppedImgReturn {
	getCroppedImg: () => string;
	getBlob: () => Promise<string>;
}

export async function base64toFile(
	base64String: string,
	filename: string,
	mimeType: string
): Promise<File> {
	// console.log('base64String', base64String);

	const blobIcon = new Blob([base64String], { type: mimeType });
	// const blob = URL.createObjectURL(
	// 	new Blob([base64String], { type: mimeType })
	// );

	const img = URL.createObjectURL(blobIcon);
	// console.log(img);
	const fileImg = new File([img], filename, { type: mimeType });
	// console.log(fileImg);
	// const reader = new FileReader();

	// reader.readAsDataURL(fileImg);
	// reader.onload = async () => {
	// 	await Promise.resolve(reader.result);
	// 	console.log(reader.result);
	// };
	// console.log(reader.result);

	// return fileImg;
	return await Promise.resolve(fileImg);
	// return new File([blob], filename, { type: mimeType });
}

// attributes for base64toFile()
// const base64String = 'base64 string';
// const filename = 'iconChatbot.jpg';
// const mimeType = 'image/jpeg';
// const file = base64toFile(base64String, filename, mimeType);

export const useGetCroppedImg = (
	userImageContainer: HTMLImageElement | null,
	crop: Crop,
	isCreationChatbot: boolean
): IUseGetCroppedImgReturn => {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');
	let renderUrl = canvas.toDataURL();
	const [blobIcon, setBlobIcon] = useState('');

	async function toBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
		return await new Promise((resolve) => {
			canvas.toBlob(resolve);
		});
	}

	const dispatch = useAppDispatch();

	const getBlob = async (): Promise<string> => {
		URL.revokeObjectURL(renderUrl);
		const blob = await toBlob(canvas);
		if (blob !== null) {
			renderUrl = URL.createObjectURL(blob);
			setBlobIcon(renderUrl);
			// console.log(blob, renderUrl);
		}

		return renderUrl;
	};

	useEffect(() => {
		dispatch(setIconCreateObjectURL(blobIcon));
	}, [crop]);

	const getCroppedImg = (): string => {
		const dispatch = useAppDispatch();

		const TO_RADIANS = Math.PI / 180;

		// temporary without rotate
		const rotate = 0;
		const scale = 1;

		if (userImageContainer !== null) {
			canvas.width = crop.width;
			canvas.height = crop.height;

			const scaleX = userImageContainer.naturalWidth / userImageContainer.width;
			const scaleY =
				userImageContainer.naturalHeight / userImageContainer.height;

			const pixelRatio = window.devicePixelRatio;
			canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
			canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

			if (ctx !== null) {
				ctx.scale(pixelRatio, pixelRatio);
				ctx.imageSmoothingQuality = 'high';

				const cropX = crop.x * scaleX;
				const cropY = crop.y * scaleY;

				const rotateRads = rotate * TO_RADIANS;
				const centerX = userImageContainer.naturalWidth / 2;
				const centerY = userImageContainer.naturalHeight / 2;

				ctx.translate(-cropX, -cropY);
				ctx.translate(centerX, centerY);
				ctx.rotate(rotateRads);
				ctx.scale(scale, scale);
				ctx.translate(-centerX, -centerY);

				ctx.drawImage(
					userImageContainer,
					0,
					0,
					userImageContainer.naturalWidth,
					userImageContainer.naturalHeight,
					0,
					0,
					userImageContainer.naturalWidth,
					userImageContainer.naturalHeight
				);

				ctx.restore();

				// ctx.drawImage(
				// 	userImageContainer,
				// 	crop.x * scaleX,
				// 	crop.y * scaleY,
				// 	crop.width * scaleX,
				// 	crop.height * scaleY,
				// 	0,
				// 	0,
				// 	crop.width,
				// 	crop.height
				// );
			}

			// canvas.getContext('2d')
		}
		// const renderBase64 = canvas
		// 	.toDataURL('image/jpeg')
		// 	.replace('image/png', 'image/octet-stream');
		const renderUrl = canvas.toDataURL();
		void getBlob();
		// console.log(renderUrl);

		useEffect(() => {
			if (isCreationChatbot) {
				dispatch(setIconChatbotChoosingForCreationChatbot(renderUrl));
			} else {
				dispatch(setIconChatbotChoosing(renderUrl));
			}
		}, [canvas]);

		// const formData = new FormData()
		// const uploadedImageName = 'selfie.png';
		// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
		// const blobImage = canvas.toBlob(function (blob) {
		// 	if (blob !== null ) {
		// 		formData.append('user_picture', blob, uploadedImageName);
		// 	}
		// });

		// console.log(formData, dataBlob);

		return renderUrl;
	};

	return { getCroppedImg, getBlob };
};
