import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import { StyledModalAskCache } from './ModalAskCache.style';
import { SharedTransparentButton } from '../../shared/SharedTransparentButton/SharedTransparentButton';
import { SharedMainButton } from '../../shared/SharedMainButton/SharedMainButton';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import { useUpdateSnippetMutation } from '../../../app/api/chatbot.api';
import { ProgressSpinner } from '../../shared/ProgressSpinner/ProgressSpinner';
import { setOpenModalAfterCacheOnlyAlertStatus } from '../../../app/slices/trainingBotMenu/openModal/openCacheSnippetsTrainingBotMenuSlice';

interface IModalAskCacheProps {
	closeFn: () => void;
	chatbotId: string;
}

interface IAskCacheComponentProps {
	closeFn: () => void;
	chatbotId: string;
}

const AskCacheComponent: React.FC<IAskCacheComponentProps> = ({
	closeFn,
	chatbotId,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const refetchFn = useAppSelector(
		(state: RootState) => state.refetchFnTrainingBotMenu.refetchFn
	);
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);
	const currentSnippetId = useAppSelector(
		(state: RootState) => state.currentSnippetTrainingBotMenu.currentSnippetId
	);
	const isOpenModalCacheSnippet = useAppSelector(
		(state: RootState) => state.openCacheSnippetsTrainingBotMenu
	);
	const { isOpenModalAfterCacheOnlyAlert } = isOpenModalCacheSnippet;

	const theme = { currentTheme };

	const dispatch = useAppDispatch();

	const [messageAfterRequest, setMessageAfterRequest] =
		useState<JSX.Element | null>(null);
	const [messageAfterRequestTitle, setMessageAfterRequestTitle] =
		useState<string>('Warning');
	const [updateSnippet, resultOfUpdateSnippet] = useUpdateSnippetMutation();

	useEffect(() => {
		setMessageAfterRequestTitle('Warning');
		setMessageAfterRequest(null);
	}, []);

	const executeCacheFn = async (): Promise<void> => {
		openModalCacheAfterExecute();
		const requestBody = {
			token,
			id: currentSnippetId,
			updateSnippetBody: {
				cached: 1,
			},
		};
		try {
			const result = await updateSnippet(requestBody);
			if ('data' in result) {
				setMessageAfterRequest(
					<Box sx={{ color: '#1592EC' }}>
						Your snippet has been successfully cached
					</Box>
				);
				setMessageAfterRequestTitle('Successfully');
				await refetchFn();
			} else if ('error' in result) {
				setMessageAfterRequest(
					<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
				);
				setMessageAfterRequestTitle('Warning');
			}
		} catch (error) {
			setMessageAfterRequest(
				<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
			);
			setMessageAfterRequestTitle('Warning');
		}
	};

	const openModalCacheAfterExecute = (): void => {
		// dispatch(setOpenModalAskCacheStatus(false));
		dispatch(setOpenModalAfterCacheOnlyAlertStatus(true));
	};

	const handleCloseModal = (): void => {
		closeFn();
	};

	useEffect(() => {
		if (resultOfUpdateSnippet.isError) {
			setMessageAfterRequest(
				<Box sx={{ color: '#E34C4C' }}>Something went wrong</Box>
			);
			setMessageAfterRequestTitle('Warning');
		} else if (resultOfUpdateSnippet.isLoading) {
			setMessageAfterRequest(<ProgressSpinner />);
		}
	}, [resultOfUpdateSnippet]);

	const AskCache: React.FC = () => (
		<StyledModalAskCache theme={theme}>
			<Box component="div" className="askCacheMenuComponent-wrapper">
				<Box component="div" className="askCacheMenu-title-subtitle">
					<Box component="div" className="askCacheMenu-subtitle">
						Do you really want to cache this snippet?
					</Box>
				</Box>
				<Box component="div" className="askCacheMenu-input-url">
					<Box component="div" className="askCacheMenu-exe-buttons">
						<Box component="div" className="askCacheMenu-wrapper-button">
							<SharedMainButton
								isDeleteButton={false}
								text="Yes"
								buttonFunction={() => {
									void executeCacheFn();
								}}
							/>
						</Box>
						<Box component="div" className="askCacheMenu-wrapper-button">
							<SharedTransparentButton
								text="No"
								buttonFunction={handleCloseModal}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</StyledModalAskCache>
	);

	const CacheAlertAfterExecute: React.FC = () => {
		return (
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
					alignItems: 'center',
				}}
			>
				<Box sx={{ fontSize: '24px', fontWeight: '600' }}>
					{messageAfterRequestTitle}
				</Box>
				<Box>{messageAfterRequest}</Box>
				<SharedTransparentButton
					text="Ok"
					buttonFunction={() => {
						handleCloseModal();
						dispatch(setOpenModalAfterCacheOnlyAlertStatus(false));
					}}
				/>
			</Box>
		);
	};

	if (!isOpenModalAfterCacheOnlyAlert) {
		return <AskCache />;
	} else if (isOpenModalAfterCacheOnlyAlert) {
		return <CacheAlertAfterExecute />;
	} else return null;

	// return <AskCache/>;
};

export const ModalAskCache: React.FC<IModalAskCacheProps> = ({
	closeFn,
	chatbotId,
}) => {
	return (
		<SharedModal
			closeFn={closeFn}
			executeFn={() => {}}
			message={null}
			title=""
			isOnlyAlert={false}
			standaloneFC={
				<AskCacheComponent chatbotId={chatbotId} closeFn={closeFn} />
			}
		/>
	);
};
