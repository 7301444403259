import React from 'react';
import { LayoutAccount } from '../../components/shared/LayoutAccount/LayoutAccount';
import { PlatformsMenu } from '../../components/AboutMenu/PlatformsMenu/PlatformsMenu';

export const PlatformsPage: React.FC = () => {
	return (
		<LayoutAccount title="Platforms">
			<PlatformsMenu />
		</LayoutAccount>
	);
};
