// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialLinkCurrentChatbotImgState {
	link: string;
}

export const initialState: IInitialLinkCurrentChatbotImgState = {
	link: '',
};

const linkCurrentChatbotImgSlice = createSlice({
	name: 'linkCurrentChatbotImg',
	initialState,
	reducers: {
		setLinkCurrentChatbotImg(state, action: PayloadAction<string>) {
			state.link = action.payload;
			return state;
		},
	},
});

export const { setLinkCurrentChatbotImg } = linkCurrentChatbotImgSlice.actions;
export const linkCurrentChatbotImgReducer = linkCurrentChatbotImgSlice.reducer;
