import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { StyledLoginMenu } from './LoginMenu.style';
import { SharedNavLink } from '../shared/SharedNavlink/SharedNavlink';
import { InputsFieldLogin } from './InputsFieldLogin/InputsFieldLogin';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { type RootState } from '../../app/store';
import { setEmptyStatusForLogin } from '../../app/slices/inputsLogin/checkEmptyStatusForLoginSlice';
import { SharedTitleSubtitle } from '../shared/SharedTitleSubtitle/SharedTitleSubtitle';
import { useLoginUserMutation } from '../../app/api/chatbot.api';
import { WrapperMenu } from '../shared/WrapperMenu/WrapperMenu';
import {
	type IResponseDataWithError,
	type IResponseLoginSuccess,
} from '../../app/api/interfaces/interfaces';
import { ProgressSpinner } from '../shared/ProgressSpinner/ProgressSpinner';
import { loginFn } from '../../app/utils/loginUtil';
import { SharedMainButton } from '../shared/SharedMainButton/SharedMainButton';
import { LoginWithGoogle } from './InputsFieldLogin/LoginWithGoogle';
import { LoginWithGithub } from './InputsFieldLogin/LoginWithGithub';
import { LoginWithFacebook } from './InputsFieldLogin/LoginWithFacebook';

export interface IDataResponseLogin {
	data: IResponseLoginSuccess;
}

export const LoginMenu: React.FC = () => {
	const loginInputsValues = useAppSelector(
		(state: RootState) => state.loginInputsValue
	);
	const { domainLogin, emailLogin, passwordLogin } = loginInputsValues;
	const validInputsStatus = useAppSelector(
		(state: RootState) => state.isValidInputs
	);
	const { isValidEmailLogin, isValidPasswordLogin } = validInputsStatus;

	const isLoginErrorMessage = useAppSelector(
		(state: RootState) => state.answerApiLogin.errorMessage
	);

	const [loginUser, resultOfLogin] = useLoginUserMutation();

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const loginData = {
		domain: domainLogin,
		email: emailLogin,
		password: passwordLogin,
	};

	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setEmptyStatusForLogin(false));
	}, []);

	const completeLogin = (): void => {
		dispatch(setEmptyStatusForLogin(true));
		if (
			emailLogin.length > 0 &&
			passwordLogin.length > 0 &&
			isValidEmailLogin &&
			isValidPasswordLogin
		) {
			loginUser(loginData)
				.then((response: IDataResponseLogin | IResponseDataWithError) => {
					loginFn(response, loginData, dispatch);
				})
				.catch((error: IResponseDataWithError) => {
					console.log('error: ', error);
				});
		}
	};

	useEffect(() => {
		resultOfLogin.isLoading && setLoadingErrorContent(<ProgressSpinner />);
		resultOfLogin.isError &&
			setLoadingErrorContent(
				<Box component="div" className="error-message-from-api">
					{isLoginErrorMessage}
				</Box>
			);
		resultOfLogin.isSuccess && setLoadingErrorContent(null);
	}, [resultOfLogin, isLoginErrorMessage]);

	return (
		<WrapperMenu>
			<StyledLoginMenu>
				<Box component="div" className="menu-wrapper">
					<Box sx={{ width: '500px' }}></Box>
					<Box component="div" className="menu-inputs-area">
						<SharedTitleSubtitle
							title="Custom ChatGPT Chatbot"
							subtitle="HI, HAPPY TO SEE YOU"
							width="300px"
						/>
						<Box component="div" className="login-inputs-field">
							<InputsFieldLogin />
						</Box>
						{loadingErrorContent}
						<Box component="div" className="login-button">
							<SharedMainButton
								isDeleteButton={false}
								text="Sign in"
								buttonFunction={completeLogin}
							/>
						</Box>
						<Box component="div" className="login-create-under-button">
							<SharedNavLink
								link="/registration"
								text="Don't have an account yet? "
								accent="Create it."
							/>
						</Box>
						<SharedNavLink link="/forgot" text="" accent="Forgot password?" />
						<Box component="div" className="login-field-with-other-sources">
							<LoginWithGithub />
							<LoginWithFacebook />
							<LoginWithGoogle />
						</Box>
					</Box>
				</Box>
			</StyledLoginMenu>
		</WrapperMenu>
	);
};
