import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
	setOpenModalAfterVectoriseOnlyAlertStatus,
	setOpenModalAskVectoriseStatus,
} from '../../../app/slices/trainingBotMenu/openModal/openVectoriseSnippetsTrainingBotMenuSlice';
import { SharedModal } from '../../shared/SharedModal/SharedModal';
import { type RootState } from '../../../app/store';
import { ModalAskVectorise } from '../../TrainingBotMenu/ModalAskVectorise/ModalAskVectorise';
import { setCurrentChatbotIdForVectorise } from '../../../app/slices/trainingBotMenu/openModal/currentValues/currentChatbotIdForActionsTrainingBotMenuSlice';
import { loadingErrorContent } from '../../../app/utils/createLoadingErrorContent';
import { CustomTooltip } from '../../shared/CustomTooltip/CustomTooltip';

interface IVectoriseActionBtnProps {
	chatbotId: number;
}

export const VectoriseActionBtn: React.FC<IVectoriseActionBtnProps> = ({
	chatbotId,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);

	const currentChatbotId = useAppSelector(
		(state: RootState) =>
			state.currentChatbotIdForActionsTrainingBotMenu
				.currentChatbotIdForVectorise
	);

	const dispatch = useAppDispatch();

	const isOpenModalVectoriseSnippet = useAppSelector(
		(state: RootState) => state.openVectoriseSnippetsTrainingBotMenu
	);

	const { isOpenModalAfterVectoriseOnlyAlert, isOpenModalAskVectorise } =
		isOpenModalVectoriseSnippet;

	const messageVectoriseCurrentSnippet = useAppSelector(
		(state: RootState) =>
			state.vectoriseSnippetMessageTrainingBotMenu.vectoriseSnippetMessage
	);

	const openModalVectoriseAsk = (): void => {
		dispatch(setOpenModalAskVectoriseStatus(true));
		dispatch(setCurrentChatbotIdForVectorise(chatbotId.toString()));
	};

	useEffect(() => {
		dispatch(setOpenModalAskVectoriseStatus(false));
		dispatch(setOpenModalAfterVectoriseOnlyAlertStatus(false));
	}, []);

	const theme = { currentTheme };

	return (
		<>
			<CustomTooltip theme={theme} title="Vectorise your snippets">
				<Box>
					<Box
						component="button"
						className="vectorise-action inline-action-button"
						onClick={openModalVectoriseAsk}
					>
						Vectorise
					</Box>
				</Box>
			</CustomTooltip>
			{isOpenModalAskVectorise ? (
				<ModalAskVectorise
					chatbotId={currentChatbotId}
					closeFn={() => {
						dispatch(setOpenModalAskVectoriseStatus(false));
					}}
				/>
			) : (
				<></>
			)}

			{isOpenModalAfterVectoriseOnlyAlert ? (
				<SharedModal
					isOnlyAlert
					title={
						messageVectoriseCurrentSnippet === 'loading'
							? 'Please, wait'
							: messageVectoriseCurrentSnippet === 'error'
							? 'Warning'
							: messageVectoriseCurrentSnippet === 'success'
							? 'Succeed'
							: ''
					}
					message={loadingErrorContent(
						messageVectoriseCurrentSnippet,
						'Your snippets have been successfully vectorized'
					)}
					executeFn={() => {}}
					closeFn={() => {
						dispatch(setOpenModalAfterVectoriseOnlyAlertStatus(false));
					}}
				/>
			) : (
				<></>
			)}
		</>
	);
};
