import React from 'react';
import { Box } from '@mui/material';
import { StyledHelpIntegrate } from './HelpIntegrate.style';
import { useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import integrateBtn from '../../pic/integrateBtnSvg.svg';
import { useNavigate } from 'react-router-dom';

export const HelpIntegrate: React.FC = () => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };
	const navigate = useNavigate();

	return (
		<StyledHelpIntegrate className="helpIntegrate-wrapper" theme={theme}>
			<Box component="div" className="helpIntegrate">
				<Box component="div" className="helpIntegrate-text">
					We can always help you with quick integration into social networks for
					a small fee
				</Box>
				<Box component="button" className="helpIntegrate-button">
					<Box component="span" className="helpIntegrate-button-text">
						Integrate
					</Box>
					<Box
						component="img"
						className="helpIntegrate-button-pic"
						alt="helpIntegrate-btn"
						src={integrateBtn}
						onClick={() => {
							navigate('/contacts');
						}}
					/>
				</Box>
			</Box>
		</StyledHelpIntegrate>
	);
};
