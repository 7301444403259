/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialCurrentPageTrainingBotMenu {
	currentPage: string;
}

const initialState: IInitialCurrentPageTrainingBotMenu = {
	currentPage: 'Training data',
};

const currentPageTrainingBotMenuSlice = createSlice({
	name: 'currentPageTrainingBotMenu',
	initialState,
	reducers: {
		setCurrentPage(state, action: PayloadAction<string>) {
			state.currentPage = action.payload;
			return state;
		},
	},
});

export const { setCurrentPage } = currentPageTrainingBotMenuSlice.actions;
export const currentPageTrainingBotMenuReducer =
	currentPageTrainingBotMenuSlice.reducer;
