/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialCurrentPageSocialMenu {
	currentPageSocial: string;
}

const initialState: IInitialCurrentPageSocialMenu = {
	currentPageSocial: 'Telegram',
};

const currentPageSocialMenuSlice = createSlice({
	name: 'currentPageSocialMenu',
	initialState,
	reducers: {
		setCurrentPageSocial(state, action: PayloadAction<string>) {
			state.currentPageSocial = action.payload;
			return state;
		},
	},
});

export const { setCurrentPageSocial } = currentPageSocialMenuSlice.actions;
export const currentPageSocialMenuReducer = currentPageSocialMenuSlice.reducer;
