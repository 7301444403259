import styled from 'styled-components';

export const StyledCustomButtonUploadImage = styled.div`
	& .change-icon-Component {
		display: flex;
		/* flex-direction: column; */
		align-items: center;
		gap: 16px;

		& .wrapper-icon {
			/* border: 1px solid #000; */
			width: 66px;
			height: 66px;
			overflow: hidden;
			border-radius: 50%;
			padding: 0px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			border: 1px solid ${(props) => props.theme.currentTheme.borderInMenu};
		}

		& .edit-icon-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
			padding: 23px 24px;
			height: 66px;
			cursor: pointer;
			border-radius: 8px;
			background: linear-gradient(
						${(props) => props.theme.currentTheme.cardsBackground} 0 0
					)
					padding-box,
				linear-gradient(135deg, #5ab5ff, #4717af) border-box;
			border: 1px solid transparent;

			&:hover {
				/* transform: scale(0.95); */
				background: linear-gradient(
							${(props) => props.theme.currentTheme.vidgetCardBlueDisabledColor}
								0 0
						)
						padding-box,
					linear-gradient(135deg, #5ab5ff, #4717af) border-box;
				border: 1px solid transparent;
			}

			& .edit-icon-pic {
				display: flex;
				/* align-items: center; */
			}

			& .edit-icon-text {
				display: flex;
				align-items: center;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 145%;
				color: ${(props) => props.theme.currentTheme.mainFontColor};
			}
		}
	}

	& .change-icon-message-present {
		display: flex;
		width: 100%;

		& .change-icon-message {
			padding-top: 8px;
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;

			& .change-icon-message-text {
				color: #1592ec;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 145%;
			}

			& .remove-btn {
				cursor: pointer;
				width: 14px;
				height: 14px;

				& svg {
					width: 14px;
					height: 14px;

					& path {
						stroke: ${(props) => props.theme.currentTheme.mainFontColor};
					}
				}
			}
		}
	}

	& .without-icon {
		flex-direction: column;
	}

	@media screen and (max-width: 350px) {
		& .change-icon-Component {
			flex-direction: column;
		}
	}
`;
