import React, { useEffect, useState } from 'react';
import { WrappedInput } from '../../../shared/Input/WrappedInput/WrappedInput';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { type RootState } from '../../../../app/store';
import { setEmptyStatusSocialIntegrate } from '../../../../app/slices/inputsSocialIntegrate/checkEmptyStatusSocialIntegrate.slice';
import { SharedMainButton } from '../../../shared/SharedMainButton/SharedMainButton';
import { SharedModal } from '../../../shared/SharedModal/SharedModal';
import {
	useCreateMessengerBotMutation,
	useGetAllBotsQuery,
} from '../../../../app/api/chatbot.api';
import { DataAlert } from '../../../shared/SharedDataAlertFromApi/SharedDataAlertFromApi';
import { ProgressDots } from '../../../shared/ProgressDots/ProgressDots';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { StyledInputSendToken } from './InputSendToken.style';

export interface IStatusForWebhookLink {
	status: boolean;
	chatbotId: number;
}

interface IInputSendTokenProps {
	uniqueName: string;
	changeStatusForWebhookLink?: (status: boolean, chatbotId: number) => void;
	updateLocalStorageFn?: (chatbotId: number) => void;
}

interface IAutocompleteBot {
	label: string;
	id: number;
}

export const InputSendToken: React.FC<IInputSendTokenProps> = ({
	uniqueName,
	changeStatusForWebhookLink,
	updateLocalStorageFn,
}) => {
	const inputSendTokenValue = useAppSelector(
		(state: RootState) => state.inputsValueSocialIntegrate
	);
	const isValidInputSendToken = useAppSelector(
		(state: RootState) => state.isValidSocialIntegrateInputs
	);
	const isEmptyInputSendToken = useAppSelector(
		(state: RootState) => state.checkEmptyStatusSocialIntegrate
	);
	const tokenUser = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const currentValue = (): string => {
		switch (uniqueName) {
			case 'Telegram':
				return inputSendTokenValue.socialIntegrateValueInputTelegram;
			case 'Viber':
				return inputSendTokenValue.socialIntegrateValueInputViber;
			case 'Messenger':
				return inputSendTokenValue.socialIntegrateValueInputMessenger;
			case 'WhatsApp':
				return inputSendTokenValue.socialIntegrateValueInputWatsApp;
			default:
				return '';
		}
	};

	const currentValueIsValid = (): boolean => {
		switch (uniqueName) {
			case 'Telegram':
				return isValidInputSendToken.isValidSocialIntegrateInputTelegram;
			case 'Viber':
				return isValidInputSendToken.isValidSocialIntegrateInputViber;
			case 'Messenger':
				return isValidInputSendToken.isValidSocialIntegrateInputMessenger;
			case 'WhatsApp':
				return isValidInputSendToken.isValidSocialIntegrateInputWatsApp;
			default:
				return false;
		}
	};

	const { data: allBotData } = useGetAllBotsQuery({ token: tokenUser });

	const [autocompleteCurrentBot, setAutocompleteCurrentBot] =
		useState<IAutocompleteBot | null>(null);
	const [allCurrentBots, setAllCurrentBots] = useState<IAutocompleteBot[]>([]);

	useEffect(() => {
		if (allBotData !== undefined) {
			const allCurrentBotsArray = allBotData.map((currentBot) => {
				return {
					label: currentBot.chatBotName.concat(`-${currentBot.id}`),
					id: currentBot.id,
				};
			});
			setAllCurrentBots(allCurrentBotsArray);
		}
	}, [allBotData]);

	const [isOpenModal, setIsOpenModal] = useState(false);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setEmptyStatusSocialIntegrate(false));
	}, []);

	const refetchFn = useAppSelector(
		(state: RootState) => state.refetchFnSocialMenu.refetchFnSocial
	);

	const [loadingErrorContent, setLoadingErrorContent] =
		useState<JSX.Element | null>(null);

	const [createMessengerBot, creationMessengerBotStatus] =
		useCreateMessengerBotMutation();

	const executeCreateMessengerBot = async (): Promise<void> => {
		const keyForCreationBot = uniqueName.toLowerCase();
		const currentChatbotId =
			autocompleteCurrentBot !== null ? autocompleteCurrentBot.id : 0;

		const createMessengerBotBody = {
			botToken: currentValue(),
			messenger: keyForCreationBot,
			chatbotId: currentChatbotId,
		};
		try {
			const resultOfCreationMessengerBot = await createMessengerBot({
				token: tokenUser,
				createMessengerBotBody,
			});
			if ('data' in resultOfCreationMessengerBot) {
				await refetchFn();
				if (changeStatusForWebhookLink !== undefined) {
					changeStatusForWebhookLink(true, currentChatbotId);
				}
				if (updateLocalStorageFn !== undefined) {
					updateLocalStorageFn(currentChatbotId);
				}
				setLoadingErrorContent(
					<DataAlert
						text={`Your bot for ${uniqueName} has been successfully created`}
					/>
				);
			} else {
				setLoadingErrorContent(
					<DataAlert text="Something went wrong" isError />
				);
			}
		} catch (error) {
			setLoadingErrorContent(<DataAlert text="Something went wrong" isError />);
		}
	};

	const handleChangeAutocomplete = (
		changeEvent: React.SyntheticEvent<Element, Event>,
		value: IAutocompleteBot | null
	): void => {
		setAutocompleteCurrentBot(value);
	};

	return (
		<StyledInputSendToken className="wrappedInputSendToken" theme={theme}>
			<Autocomplete
				disablePortal
				id="bots-Autocomplete"
				options={allCurrentBots}
				sx={{
					width: 300,
				}}
				renderInput={(params) => (
					<TextField {...params} placeholder="Choose bot for integration" />
				)}
				onChange={handleChangeAutocomplete}
				fullWidth={true}
				className="shared-input"
				// eslint-disable-next-line no-shadow-restricted-names
				isOptionEqualToValue={(undefined, value) => value !== undefined}
				value={autocompleteCurrentBot}
			/>
			<WrappedInput
				type="text"
				placeholder={`Enter ${uniqueName} token`}
				isEmpty={isEmptyInputSendToken}
				isValid={currentValueIsValid()}
				uniqueNameOfInput={`sendToken-input-${uniqueName}`}
				value={currentValue()}
			/>
			<SharedMainButton
				buttonFunction={() => {
					setIsOpenModal(true);
					void executeCreateMessengerBot();
				}}
				text={'Send token'}
				isDeleteButton={false}
				isDisabled={!currentValueIsValid() || autocompleteCurrentBot === null}
			/>
			{isOpenModal && (
				<SharedModal
					closeFn={() => {
						setIsOpenModal(false);
					}}
					executeFn={() => {}}
					isOnlyAlert
					message={
						creationMessengerBotStatus.isLoading ? (
							<ProgressDots />
						) : (
							loadingErrorContent
						)
					}
					title={
						creationMessengerBotStatus.isError
							? 'Warning'
							: creationMessengerBotStatus.isSuccess
							? 'Success'
							: 'Information'
					}
				/>
			)}
		</StyledInputSendToken>
	);
};
