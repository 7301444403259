import styled from 'styled-components';

export const StyledSnippetsTable = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	color: ${(props) => props.theme.currentTheme.mainFontColor};

	& .grid-snippet-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		& .title-wrapper {
			display: flex;
			justify-content: space-between;
			padding: 0px 16px;
			border-radius: 8px;
			background: ${(props) =>
				props.theme.currentTheme.accentHighlightColorForTable};

			& .title-snippet {
				padding: 10px;
				font-size: 16px;
				line-height: 140%;
			}

			& .title-snippet.snippet-checkbox {
				padding: 10px 0px;
			}
		}

		& .message-empty {
			color: ${(props) => props.theme.currentTheme.mainFontColor};
		}

		& .grid-card-wrapper {
			display: flex;
			justify-content: space-between;
			padding: 10px 16px;
			border-bottom: 1px solid
				${(props) => props.theme.currentTheme.accentHighlightColorForTable};

			& .grid-card-item {
				padding: 10px;
				font-size: 16px;
				line-height: 140%;
			}

			&:hover {
				background: ${(props) => props.theme.hoverBackgroundColor};
			}

			& .actions-buttons {
				display: flex;
				gap: 0.5rem;
				align-items: center;

				& > svg {
					cursor: pointer;
					width: 20px;
					height: auto;
					color: #1592ec;
				}

				& .disabled-svg {
					color: ${(props) => props.theme.currentTheme.disabledColor};
					cursor: auto !important;
				}
			}
		}

		& .snippet-checkbox {
			display: flex;
			align-items: center;
			min-width: 30px;
			flex: 1;
			width: 30px;
		}

		& .domain-grid-item,
		.bot-name-item,
		.created-item,
		.actions-grid-item {
			display: flex;
			justify-content: flex-start;
			min-width: 80px;
			align-items: center;
			text-align: left;
		}

		& .domain-grid-item {
			flex: 3;
			min-width: 160px;
			hyphens: auto;
			word-wrap: break-word;
		}
		& .bot-name-item {
			flex: 2;
			hyphens: auto;
			word-wrap: break-word;
		}
		& .created-item {
			flex: 2;
		}
		& .actions-grid-item {
			flex: 1;
			width: 90px;
			min-width: 90px;
			justify-content: center;
		}

		& .sort-component {
			display: flex;
			align-items: center;
			gap: 0.3rem;

			& svg {
				width: 20px;
				height: 20px;
			}

			& button {
				border: none;
				background: none;
				cursor: pointer;
			}
		}

		& .cashed-item,
		.pushed-item,
		.vectorized-item {
			flex: 2;

			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	@media screen and (max-width: 1500px) {
		& .grid-snippet-container {
			& .title-wrapper {
				& .title-snippet {
					font-size: 16px;
				}
			}
			& .grid-card-wrapper {
				& .grid-card-item {
					font-size: 16px;
				}
			}
		}
	}
`;
