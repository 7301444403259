/* eslint-disable no-useless-escape */
import type { AppDispatch } from '../store';
import validator from 'validator';
import {
	setDomainGeneratorMenu,
	setOpenAIKeyGeneratorMenu,
	setReCaptchaKeyGeneratorMenu,
	setReCaptchaSecretKeyGeneratorMenu,
	setStyleGeneratorMenu,
	setToneGeneratorMenu,
	setButtonTextGeneratorMenu,
	setHeaderGeneratorMenu,
} from '../slices/inputsGenerator/chatGeneratorInputsValueSlice';
import {
	setValidStatusDomainGeneratorMenu,
	setValidStatusOpenAIKeyGeneratorMenu,
	setValidStatusReCaptchaKeyGeneratorMenu,
	setValidStatusReCaptchaSecretKeyGeneratorMenu,
	setValidStatusStyleGeneratorMenu,
	setValidStatusToneGeneratorMenu,
	setValidStatusButtonTextGeneratorMenu,
	setValidStatusHeaderGeneratorMenu,
} from '../slices/inputsGenerator/isValidChatGeneratorInputsSlice';
import {
	setDomainLogin,
	setEmailLogin,
	setPasswordLogin,
} from '../slices/inputsLogin/loginInputsValueSlice';
import {
	setValidStatusDomainLogin,
	setValidStatusEmailLogin,
	setValidStatusPasswordLogin,
} from '../slices/inputsLogin/isValidInputsSlice';
import {
	setDomainRegistration,
	setEmailRegistration,
	setNameRegistration,
	setPasswordRegistration,
	setSurnameRegistration,
} from '../slices/inputsRegistration/registrationInputsValueSlice';
import {
	setValidStatusDomainRegistration,
	setValidStatusEmailRegistration,
	setValidStatusNameRegistration,
	setValidStatusPasswordRegistration,
	setValidStatusSurnameRegistration,
} from '../slices/inputsRegistration/isValidRegistrationInputsSlice';
import { setRegistrationStatus } from '../slices/isRegistrationSlice';
import { setEmptyStatusForRegistration } from '../slices/inputsRegistration/checkEmptyStatusForRegistrationSlice';
import { setEmptyStatusForLogin } from '../slices/inputsLogin/checkEmptyStatusForLoginSlice';
import {
	setEmptyStatusForCheckChatGeneratorFirstStep,
	setEmptyStatusForCheckChatGeneratorSecondStep,
} from '../slices/inputsGenerator/checkEmptyStatusForChatGeneratorSlice';
import { setAuthorizationStatus } from '../slices/isAuthorizationSlice';
import { setCodeJSGeneratedStatus } from '../slices/isCodeJSGeneratedSlice';
import {
	setEmailContacts,
	setMessageContacts,
	setNameContacts,
} from '../slices/inputsContacts/contactsInputsValueSlice';
import {
	setValidStatusEmailContacts,
	setValidStatusMessageContacts,
	setValidStatusNameContacts,
} from '../slices/inputsContacts/isValidContactsInputsSlice';
import { setCurrentStepGenerator } from '../slices/currentStepGeneratorSlice';
import { setEmailForgot } from '../slices/inputsForgot/forgotInputValueSlice';
import { setValidEmailForgotStatus } from '../slices/inputsForgot/isValidForgotInputValueSlice';
import {
	setEmailChangeInformation,
	setPasswordChangeInformation,
	setPasswordRepeatChangeInformation,
	setSurnameChangeInformation,
	setUsernameChangeInformation,
} from '../slices/inputsChangeInformation/changeInformationInputsValueSlice';
import {
	setValidStatusEmailChangeInfo,
	setValidStatusPasswordChangeInfo,
	setValidStatusPasswordRepeatChangeInfo,
	setValidStatusSurnameChangeInfo,
	setValidStatusUsernameChangeInfo,
} from '../slices/inputsChangeInformation/isValidChangeInformationValuesSlice';
import { setPasswordReset } from '../slices/inputResetPassword/resetInputValueSlice';
import { setValidPasswordResetStatus } from '../slices/inputResetPassword/isValidResetInputValueSlice';
import { type ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { setConfirmEmail } from '../slices/inputsConfirmEmail/confirmEmailInputValueSlice';
import { setValidEmailConfirmEmailStatus } from '../slices/inputsConfirmEmail/isValidConfirmEmailInputValueSlice';
import { type IResponseDataWithError } from '../api/interfaces/interfaces';
import {
	setValidStatusButtonTextDetailedBotCard,
	setValidStatusChatBotNameDetailedBotCard,
	setValidStatusChatBotStructureDetailedBotCard,
	setValidStatusChatBotStyleCssDetailedBotCard,
	setValidStatusHeaderDetailedBotCard,
	setValidStatusStyleDetailedBotCard,
	setValidStatusToneDetailedBotCard,
} from '../slices/inputsDetailedBotCard/isValidDetailedBotCardInputsSlice';
import {
	setButtonTextDetailedBotCard,
	setChatBotNameDetailedBotCard,
	setChatBotStructureDetailedBotCard,
	setChatBotStyleCSSDetailedBotCard,
	setHeaderDetailedBotCard,
	setStyleDetailedBotCard,
	setToneDetailedBotCard,
} from '../slices/inputsDetailedBotCard/detailedBotCardInputsValueSlice';
import {
	setCVC,
	setCardNumber,
	setMonth,
	setYear,
} from '../slices/inputsPaymentMethodCard/paymentMethodCardValue';
import {
	setValidCVCStatus,
	setValidCardNumberStatus,
	setValidMonthStatus,
	setValidYearStatus,
} from '../slices/inputsPaymentMethodCard/isValidPaymentMethodCardValuesSlice';
import {
	setIsValidViewOnPage,
	setViewOnPage,
} from '../slices/trainingBotMenu/paginationValuesTrainingBotMenuSlice';
import { setDomainImportUrl } from '../slices/trainingBotMenu/inputImportUrl/inputImportUrl/importUrlValueSlice';
import { setValidStatusDomainImportUrl } from '../slices/trainingBotMenu/inputImportUrl/inputImportUrl/isValidImportUrlInputsSlice';
import {
	setCompletion,
	setPrompt,
} from '../slices/trainingBotMenu/inputImportUrl/inputsCreateChangePropmt/createChangePromptValuesSlice';
import {
	setValidStatusCompletion,
	setValidStatusPrompt,
} from '../slices/trainingBotMenu/inputImportUrl/inputsCreateChangePropmt/isValidCreateChangePromptInputsSlice';
import { setSearchBarValue } from '../slices/trainingBotMenu/searchBar/searchBarValueSlice';
import { setValidStatusSearchBarInput } from '../slices/trainingBotMenu/searchBar/isValidSearchBarInputsSlice';
import {
	setTestBotCompletionInputValue,
	setTestBotInputValue,
} from '../slices/trainingBotMenu/testBotInput/testBotInputValueSlice';
import {
	setValidStatusTestBotCompletionInput,
	setValidStatusTestBotInput,
} from '../slices/trainingBotMenu/testBotInput/isValidTestBotInputSlice';
import { setSearchBarValueHistoryMenu } from '../slices/trainingBotMenu/historyMenu/searchBarHistoryMenu/searchBarHistoryMenuValueSlice';
import { setValidStatusSearchBarInputHistoryMenu } from '../slices/trainingBotMenu/historyMenu/searchBarHistoryMenu/isValidSearchBarHistoryMenuInputsSlice';
import {
	setHistoryIsValidViewOnPage,
	setHistoryViewOnPage,
} from '../slices/trainingBotMenu/historyMenu/paginationValuesHistoryMenuSlice';
import {
	setFlavorConfigModelMenu,
	setGreetingConfigModelMenu,
	setPrefixConfigModelMenu,
	setReCaptchaKeyConfigModelMenu,
	setReCaptchaSecretKeyConfigModelMenu,
	setReCaptchaTemperatureConfigModelMenu,
	setWebsiteConfigModelMenu,
} from '../slices/configInputs/configModelMenuInputs/configModelMenuInputsSlice';
import {
	setValidStatusFlavorConfigModelMenu,
	setValidStatusGreetingConfigModelMenu,
	setValidStatusPrefixConfigModelMenu,
	setValidStatusReCaptchaKeyConfigModelMenu,
	setValidStatusReCaptchaSecretKeyConfigModelMenu,
	setValidStatusReCaptchaTemperatureConfigModelMenu,
	setValidStatusWebsiteConfigModelMenu,
} from '../slices/configInputs/configModelMenuInputs/isValidModelMenuInputsSlice';
import {
	setCompletionModelConfigOpenAIMenu,
	setMaxTokensContentConfigOpenAIMenu,
	setMaxTokensMessageConfigOpenAIMenu,
	setMaxTokensRequestConfigOpenAIMenu,
	setMaxTokensResponseConfigOpenAIMenu,
	setOpenAiKeyConfigOpenAIMenu,
	setTemperatureConfigOpenAIMenu,
	setThresholdConfigOpenAIMenu,
	setVectorModelConfigOpenAIMenu,
} from '../slices/configInputs/configOpenAIMenuInputs/configOpenAIMenuInputsSlice';
import {
	setValidStatusCompletionConfigOpenAIMenu,
	setValidStatusMaxTokensContentConfigOpenAIMenu,
	setValidStatusMaxTokensMessageConfigOpenAIMenu,
	setValidStatusMaxTokensRequestConfigOpenAIMenu,
	setValidStatusMaxTokensResponseConfigOpenAIMenu,
	setValidStatusOpenAiConfigOpenAIMenu,
	setValidStatusTemperatureConfigOpenAIMenu,
	setValidStatusThresholdConfigOpenAIMenu,
	setValidStatusVectorModelConfigOpenAIMenu,
} from '../slices/configInputs/configOpenAIMenuInputs/isValidOpenAIMenunputsSlice';
import {
	setValidStatusSocialIntegrateMessenger,
	setValidStatusSocialIntegrateTelegram,
	setValidStatusSocialIntegrateViber,
	setValidStatusSocialIntegrateWatsApp,
} from '../slices/inputsSocialIntegrate/isValidSocialIntegrateInputs.slice';
import {
	setSocialIntegrateValueInputMessenger,
	setSocialIntegrateValueInputTelegram,
	setSocialIntegrateValueInputViber,
	setSocialIntegrateValueInputWatsApp,
} from '../slices/inputsSocialIntegrate/inputsValueSocialIntegrate.slice';

export interface IDependenciesFromWrapperInput {
	valueInput: string;
	params: string[] | undefined;
}

export interface IPressedButtonFromWrapperInput {
	isSendButtonPressed: boolean;
	firstStep: boolean;
	secondStep: boolean;
	isSignUpButtonPressed: boolean;
	isSendMessagePressed: boolean;
	isSendForgotPasswordButtonPressed: boolean;
	isChangeInformationButtonPressed: boolean;
	isResetPasswordButtonPressed: boolean;
}

export interface IValidOptionsFromWrapperInput {
	isValidDomainLogin: boolean;
	isValidEmailLogin: boolean;
	isValidPasswordLogin: boolean;

	isValidDomainGeneratorMenu: boolean;
	isValidToneGeneratorMenu: boolean;
	isValidOpenAIKeyGeneratorMenu: boolean;
	isValidReCaptchaKeyGeneratorMenu: boolean;
	isValidStyleGeneratorMenu: boolean;
	isValidHeaderGeneratorMenu: boolean;
	isValidButtonTextGeneratorMenu: boolean;

	isValidNameRegistration: boolean;
	isValidSurnameRegistration: boolean;
	isValidDomainRegistration: boolean;
	isValidEmailRegistration: boolean;
	isValidPasswordRegistration: boolean;

	isValidNameContacts: boolean;
	isValidEmailContacts: boolean;
	isValidMessageContacts: boolean;

	isValidEmailForgot: boolean;

	isValidPasswordChangeInfo: boolean;
	isValidEmailChangeInfo: boolean;
	isValidUsernameChangeInfo: boolean;

	isValidPasswordReset: boolean;
}

export const validation = {
	useDomainValidation: (value: string): boolean => {
		if (
			value.match(
				/https?:\/\/(www\.)?[-\w@:%\.\+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-\w()@:%\.\+~#=?&]*)/gim
			) !== null
		) {
			return true;
		} else {
			return false;
		}
	},
	useTemporaryDomainValidation: (value: string): boolean => {
		if (
			value.match(
				/[-\w@:%\.\+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-\w()@:%\.\+~#=?&]*)/gim
			) !== null
		) {
			return true;
		} else {
			return false;
		}
	},
	useDomainValidationWithoutHttp: (value: string): boolean => {
		if (
			validator.isURL(value)
			// Boolean(validator.isURL(value))
			// && value.match(/http(s)?:/gim) === null
			// && value.split('')[value.length - 1] !== '/'
		) {
			return true;
			// } else if (value.match(/http(s)?:\/\//gim) !== null) {
			// 	return false;
		} else {
			return false;
		}
	},

	useEmailValidation: (value: string): boolean => {
		if (
			value.match(
				/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/gim
			) !== null
		) {
			return true;
		} else {
			return false;
		}
	},
	usePasswordLoginValidation: (value: string): boolean => {
		if (value.length >= 1) {
			return true;
		} else {
			return false;
		}
	},
	usePasswordValidation: (value: string): boolean => {
		if (
			// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
			validator.isStrongPassword(value, {
				minLowercase: 2,
				minUppercase: 2,
				minNumbers: 2,
				minSymbols: 0,
			})
		) {
			return true;
		} else {
			return false;
		}
	},
	useAutocompleteValidation: (
		value: string,
		options: string[] | undefined
	): boolean => {
		if (
			options != null &&
			Boolean(options.find((option) => option === value))
		) {
			return true;
		} else {
			return false;
		}
	},
	useOpenAIKeyValidation: (value: string): boolean => {
		// тимчасова валідація
		if (value.length > 0) {
			return true;
		} else {
			return false;
		}
	},
	useReCaptchaKeyValidation: (value: string): boolean => {
		// тимчасова валідація
		if (value.length > 0) {
			return true;
		} else {
			return false;
		}
	},
	useReCaptchaSecretKeyValidation: (value: string): boolean => {
		// тимчасова валідація
		if (value.length > 0) {
			return true;
		} else {
			return false;
		}
	},
	useNameSurnameValidation: (value: string): boolean => {
		if (value.length > 1) {
			return true;
		} else {
			return false;
		}
	},
	useMessageValidation: (value: string): boolean => {
		if (
			value.length > 0 &&
			value.match(/>|<|\/|\\|\||=|\*|class|{|}/gim) === null
			// value.match(/>|<|\/|\\|\||=|\*|class|'|"|`|{|}/gim) === null
		) {
			return true;
		} else {
			return false;
		}
	},
	useConfirmCodeValidation: (value: string): boolean => {
		if (value.length === 6) {
			return true;
		} else {
			return false;
		}
	},

	useCardNumberValidation: (value: string): boolean => {
		if (validator.isCreditCard(value)) {
			return true;
		} else {
			return false;
		}
	},
	useCardMonthValidation: (value: string): boolean => {
		if (
			value.length > 0 &&
			value.length <= 2 &&
			Number.parseInt(value) > 0 &&
			Number.parseInt(value) <= 12
		) {
			return true;
		} else {
			return false;
		}
	},
	useCardYearValidation: (value: string): boolean => {
		if (
			value.length === 2 &&
			Number.parseInt(value) > 0 &&
			Number.parseInt(value) <= 99
		) {
			return true;
		} else {
			return false;
		}
	},
	useCardCVCValidation: (value: string): boolean => {
		if (value.length === 3) {
			return true;
		} else {
			return false;
		}
	},
	useViewOnPageValidation: (value: string): boolean => {
		if (value === '10' || value === '25' || value === '50') {
			return true;
		} else {
			return false;
		}
	},

	usePromptCompletionValidation: (value: string): boolean => {
		if (value.length > 1) {
			return true;
		} else {
			return false;
		}
	},

	useFromZeroToOneValidation: (value: string): boolean => {
		if (
			value.length <= 3 &&
			Number.parseInt(value) >= 0 &&
			Number.parseInt(value) <= 1
		) {
			return true;
		} else {
			return false;
		}
	},
	useIsNumberValidation: (value: string): boolean => {
		if (validator.isNumeric(value)) {
			return true;
		} else {
			return false;
		}
	},
	useNotRequiredEmpty: (value: string): boolean => {
		// if (value.length > 0) {
		return true;
		// } else {
		// 	return false;
		// }
	},
};

export const stagePercentProgress = (
	maxValue: number,
	setStateFunction: (currentValue: number) => void
): void => {
	const count = 20;
	let delay = 20;
	for (let i = 0; i <= maxValue; i++) {
		setTimeout(() => {
			setStateFunction(i);
		}, delay + count);
		delay = delay + count;
	}
};

export const checkInput = (
	uniqueNameOfInput: string,
	dependencies: IDependenciesFromWrapperInput,
	dispatch: AppDispatch
): void => {
	const setValueAndDefineValidation = (
		setValueFn: ActionCreatorWithPayload<string, string>,
		validationFn: (value: string, params?: string[] | undefined) => boolean,
		setValidStatusFn: ActionCreatorWithPayload<boolean, string>
	): void => {
		// console.log(uniqueNameOfInput, dependencies);

		dispatch(setValueFn(dependencies.valueInput));
		validationFn(dependencies.valueInput, dependencies.params)
			? dispatch(setValidStatusFn(true))
			: dispatch(setValidStatusFn(false));
	};

	try {
		switch (uniqueNameOfInput) {
			case 'domain-login':
				setValueAndDefineValidation(
					setDomainLogin,
					validation.useDomainValidationWithoutHttp,
					setValidStatusDomainLogin
				);
				break;
			case 'e-mail-login':
				setValueAndDefineValidation(
					setEmailLogin,
					validation.useEmailValidation,
					setValidStatusEmailLogin
				);
				break;
			case 'password-login':
				setValueAndDefineValidation(
					setPasswordLogin,
					validation.usePasswordLoginValidation,
					setValidStatusPasswordLogin
				);

				break;
			case 'domain-generator-menu':
				setValueAndDefineValidation(
					setDomainGeneratorMenu,
					validation.useDomainValidationWithoutHttp,
					setValidStatusDomainGeneratorMenu
				);
				break;
			case 'tone-autocomplete-generator-menu':
				setValueAndDefineValidation(
					setToneGeneratorMenu,
					validation.useAutocompleteValidation,
					setValidStatusToneGeneratorMenu
				);
				break;
			case 'openAI-key-generator-menu':
				setValueAndDefineValidation(
					setOpenAIKeyGeneratorMenu,
					validation.useOpenAIKeyValidation,
					setValidStatusOpenAIKeyGeneratorMenu
				);
				break;
			case 'reCaptcha-key-generator-menu':
				setValueAndDefineValidation(
					setReCaptchaKeyGeneratorMenu,
					validation.useReCaptchaKeyValidation,
					setValidStatusReCaptchaKeyGeneratorMenu
				);
				break;
			case 'reCaptcha-secret-key-generator-menu':
				setValueAndDefineValidation(
					setReCaptchaSecretKeyGeneratorMenu,
					validation.useReCaptchaSecretKeyValidation,
					setValidStatusReCaptchaSecretKeyGeneratorMenu
				);
				break;
			case 'style-autocomplete-generator-menu':
				setValueAndDefineValidation(
					setStyleGeneratorMenu,
					validation.useAutocompleteValidation,
					setValidStatusStyleGeneratorMenu
				);
				break;
			case 'header-generator-menu':
				setValueAndDefineValidation(
					setHeaderGeneratorMenu,
					validation.useMessageValidation,
					setValidStatusHeaderGeneratorMenu
				);
				break;
			case 'button-text-generator-menu':
				setValueAndDefineValidation(
					setButtonTextGeneratorMenu,
					validation.useMessageValidation,
					setValidStatusButtonTextGeneratorMenu
				);
				break;

			case 'name-registration':
				setValueAndDefineValidation(
					setNameRegistration,
					validation.useNameSurnameValidation,
					setValidStatusNameRegistration
				);
				break;
			case 'surname-registration':
				setValueAndDefineValidation(
					setSurnameRegistration,
					validation.useNameSurnameValidation,
					setValidStatusSurnameRegistration
				);
				break;
			case 'domain-registration':
				setValueAndDefineValidation(
					setDomainRegistration,
					validation.useDomainValidationWithoutHttp,
					setValidStatusDomainRegistration
				);
				break;
			case 'e-mail-registration':
				setValueAndDefineValidation(
					setEmailRegistration,
					validation.useEmailValidation,
					setValidStatusEmailRegistration
				);
				break;
			case 'password-first-registration':
				setValueAndDefineValidation(
					setPasswordRegistration,
					validation.usePasswordValidation,
					setValidStatusPasswordRegistration
				);
				break;

			case 'name-contacts':
				setValueAndDefineValidation(
					setNameContacts,
					validation.useNameSurnameValidation,
					setValidStatusNameContacts
				);
				break;
			case 'e-mail-contacts':
				setValueAndDefineValidation(
					setEmailContacts,
					validation.useEmailValidation,
					setValidStatusEmailContacts
				);
				break;
			case 'type-text-area-contacts':
				setValueAndDefineValidation(
					setMessageContacts,
					validation.useMessageValidation,
					setValidStatusMessageContacts
				);
				break;

			case 'e-mail-forgot':
				setValueAndDefineValidation(
					setEmailForgot,
					validation.useEmailValidation,
					setValidEmailForgotStatus
				);
				break;

			case 'username-change-info':
				setValueAndDefineValidation(
					setUsernameChangeInformation,
					validation.useNameSurnameValidation,
					setValidStatusUsernameChangeInfo
				);
				break;
			case 'email-change-info':
				setValueAndDefineValidation(
					setEmailChangeInformation,
					validation.useEmailValidation,
					setValidStatusEmailChangeInfo
				);
				break;
			case 'password-change-info':
				setValueAndDefineValidation(
					setPasswordChangeInformation,
					validation.usePasswordValidation,
					setValidStatusPasswordChangeInfo
				);
				break;
			case 'surname-change-info':
				setValueAndDefineValidation(
					setSurnameChangeInformation,
					validation.useNameSurnameValidation,
					setValidStatusSurnameChangeInfo
				);
				break;
			case 'password-change-info-repeat':
				setValueAndDefineValidation(
					setPasswordRepeatChangeInformation,
					validation.usePasswordValidation,
					setValidStatusPasswordRepeatChangeInfo
				);
				break;

			case 'password-reset':
				setValueAndDefineValidation(
					setPasswordReset,
					validation.usePasswordValidation,
					setValidPasswordResetStatus
				);
				break;

			case 'code-email-confirm':
				setValueAndDefineValidation(
					setConfirmEmail,
					validation.useConfirmCodeValidation,
					setValidEmailConfirmEmailStatus
				);
				break;

			case 'tone-autocomplete-detailed-bot-card-changeableInput':
				setValueAndDefineValidation(
					setToneDetailedBotCard,
					validation.useAutocompleteValidation,
					setValidStatusToneDetailedBotCard
				);
				break;
			case 'style-autocomplete-detailed-bot-card-changeableInput':
				setValueAndDefineValidation(
					setStyleDetailedBotCard,
					validation.useAutocompleteValidation,
					setValidStatusStyleDetailedBotCard
				);
				break;
			case 'header-detailed-bot-card-changeableInput':
				setValueAndDefineValidation(
					setHeaderDetailedBotCard,
					validation.useMessageValidation,
					setValidStatusHeaderDetailedBotCard
				);
				break;
			case 'button-text-detailed-bot-card-changeableInput':
				setValueAndDefineValidation(
					setButtonTextDetailedBotCard,
					validation.useMessageValidation,
					setValidStatusButtonTextDetailedBotCard
				);
				break;
			case 'style-name-detailed-bot-card-changeableInput':
				setValueAndDefineValidation(
					setChatBotNameDetailedBotCard,
					validation.useMessageValidation,
					setValidStatusChatBotNameDetailedBotCard
				);
				break;
			case 'structure-autocomplete-detailed-bot-card-changeableInput':
				setValueAndDefineValidation(
					setChatBotStructureDetailedBotCard,
					validation.useAutocompleteValidation,
					setValidStatusChatBotStructureDetailedBotCard
				);
				break;
			case 'style-css-autocomplete-detailed-bot-card-changeableInput':
				setValueAndDefineValidation(
					setChatBotStyleCSSDetailedBotCard,
					validation.useAutocompleteValidation,
					setValidStatusChatBotStyleCssDetailedBotCard
				);
				break;

			case 'payments-card-number':
				setValueAndDefineValidation(
					setCardNumber,
					validation.useCardNumberValidation,
					setValidCardNumberStatus
				);
				break;
			case 'payments-card-expiry-month':
				setValueAndDefineValidation(
					setMonth,
					validation.useCardMonthValidation,
					setValidMonthStatus
				);
				break;
			case 'payments-card-expiry-year':
				setValueAndDefineValidation(
					setYear,
					validation.useCardYearValidation,
					setValidYearStatus
				);
				break;
			case 'payments-card-cvc':
				setValueAndDefineValidation(
					setCVC,
					validation.useCardCVCValidation,
					setValidCVCStatus
				);
				break;

			case 'viewOnPage-autocomplete':
				setValueAndDefineValidation(
					setViewOnPage,
					validation.useViewOnPageValidation,
					setIsValidViewOnPage
				);
				break;

			case 'import-url-for-scrape':
				setValueAndDefineValidation(
					setDomainImportUrl,
					validation.useDomainValidationWithoutHttp,
					setValidStatusDomainImportUrl
				);
				break;

			case 'createChangePrompt-input-modal':
				setValueAndDefineValidation(
					setPrompt,
					validation.usePromptCompletionValidation,
					setValidStatusPrompt
				);
				break;
			case 'createChangeCompletion-input-modal':
				setValueAndDefineValidation(
					setCompletion,
					validation.usePromptCompletionValidation,
					setValidStatusCompletion
				);
				break;

			case 'search-bar-input-trainingBotMenu':
				setValueAndDefineValidation(
					setSearchBarValue,
					validation.usePromptCompletionValidation,
					setValidStatusSearchBarInput
				);
				break;

			case 'testBot-input-modal':
				setValueAndDefineValidation(
					setTestBotInputValue,
					validation.usePromptCompletionValidation,
					setValidStatusTestBotInput
				);
				break;

			case 'testBot-textarea-answer-loading-content-modal':
				setValueAndDefineValidation(
					setTestBotCompletionInputValue,
					validation.usePromptCompletionValidation,
					setValidStatusTestBotCompletionInput
				);
				break;

			case 'search-bar-input-historyRequestMenu':
				setValueAndDefineValidation(
					setSearchBarValueHistoryMenu,
					validation.usePromptCompletionValidation,
					setValidStatusSearchBarInputHistoryMenu
				);
				break;

			case 'viewOnPage-historyRequestMenu':
				setValueAndDefineValidation(
					setHistoryViewOnPage,
					validation.useViewOnPageValidation,
					setHistoryIsValidViewOnPage
				);
				break;

			case 'website-model-menu-changeableInput':
				setValueAndDefineValidation(
					setWebsiteConfigModelMenu,
					validation.useDomainValidationWithoutHttp,
					setValidStatusWebsiteConfigModelMenu
				);
				break;
			case 'flavor-model-menu-changeableInput':
				setValueAndDefineValidation(
					setFlavorConfigModelMenu,
					validation.useAutocompleteValidation,
					setValidStatusFlavorConfigModelMenu
				);
				break;
			case 'reCAPTCHA-model-menu-changeableInput':
				setValueAndDefineValidation(
					setReCaptchaKeyConfigModelMenu,
					validation.useNotRequiredEmpty,
					setValidStatusReCaptchaKeyConfigModelMenu
				);
				break;
			case 'greeting-model-menu-changeableInput':
				setValueAndDefineValidation(
					setGreetingConfigModelMenu,
					validation.useNotRequiredEmpty,
					setValidStatusGreetingConfigModelMenu
				);
				break;
			case 'reCAPTCHA-key-model-menu-changeableInput':
				setValueAndDefineValidation(
					setReCaptchaSecretKeyConfigModelMenu,
					validation.useNotRequiredEmpty,
					setValidStatusReCaptchaSecretKeyConfigModelMenu
				);
				break;
			case 'prefix-model-menu-changeableInput':
				setValueAndDefineValidation(
					setPrefixConfigModelMenu,
					validation.useNotRequiredEmpty,
					setValidStatusPrefixConfigModelMenu
				);
				break;
			case 'reCaptcha-temperature-model-menu-changeableInput':
				setValueAndDefineValidation(
					setReCaptchaTemperatureConfigModelMenu,
					validation.useFromZeroToOneValidation,
					setValidStatusReCaptchaTemperatureConfigModelMenu
				);
				break;

			case 'openAI-API-key-openAI-menu-changeableInput':
				setValueAndDefineValidation(
					setOpenAiKeyConfigOpenAIMenu,
					validation.useNotRequiredEmpty,
					setValidStatusOpenAiConfigOpenAIMenu
				);
				break;
			case 'temperature-openAI-menu-changeableInput':
				setValueAndDefineValidation(
					setTemperatureConfigOpenAIMenu,
					validation.useFromZeroToOneValidation,
					setValidStatusTemperatureConfigOpenAIMenu
				);
				break;
			case 'threshold-openAI-menu-changeableInput':
				setValueAndDefineValidation(
					setThresholdConfigOpenAIMenu,
					validation.useFromZeroToOneValidation,
					setValidStatusThresholdConfigOpenAIMenu
				);
				break;
			case 'max-context-tokens-openAI-menu-changeableInput':
				setValueAndDefineValidation(
					setMaxTokensContentConfigOpenAIMenu,
					validation.useIsNumberValidation,
					setValidStatusMaxTokensContentConfigOpenAIMenu
				);
				break;
			case 'max-request-tokens-openAI-menu-changeableInput':
				setValueAndDefineValidation(
					setMaxTokensRequestConfigOpenAIMenu,
					validation.useIsNumberValidation,
					setValidStatusMaxTokensRequestConfigOpenAIMenu
				);
				break;
			case 'max-response-tokens-openAI-menu-changeableInput':
				setValueAndDefineValidation(
					setMaxTokensResponseConfigOpenAIMenu,
					validation.useIsNumberValidation,
					setValidStatusMaxTokensResponseConfigOpenAIMenu
				);
				break;
			case 'max-message-tokens-openAI-menu-changeableInput':
				setValueAndDefineValidation(
					setMaxTokensMessageConfigOpenAIMenu,
					validation.useIsNumberValidation,
					setValidStatusMaxTokensMessageConfigOpenAIMenu
				);
				break;
			case 'completion-chat-openAI-menu-changeableInput':
				setValueAndDefineValidation(
					setCompletionModelConfigOpenAIMenu,
					validation.useAutocompleteValidation,
					setValidStatusCompletionConfigOpenAIMenu
				);
				break;
			case 'vector-model-openAI-menu-changeableInput':
				setValueAndDefineValidation(
					setVectorModelConfigOpenAIMenu,
					validation.useAutocompleteValidation,
					setValidStatusVectorModelConfigOpenAIMenu
				);
				break;

			case 'sendToken-input-Telegram':
				setValueAndDefineValidation(
					setSocialIntegrateValueInputTelegram,
					validation.useMessageValidation,
					setValidStatusSocialIntegrateTelegram
				);
				break;
			case 'sendToken-input-Viber':
				setValueAndDefineValidation(
					setSocialIntegrateValueInputViber,
					validation.useMessageValidation,
					setValidStatusSocialIntegrateViber
				);
				break;
			case 'sendToken-input-Messenger':
				setValueAndDefineValidation(
					setSocialIntegrateValueInputMessenger,
					validation.useMessageValidation,
					setValidStatusSocialIntegrateMessenger
				);
				break;
			case 'sendToken-input-WhatsApp':
				setValueAndDefineValidation(
					setSocialIntegrateValueInputWatsApp,
					validation.useMessageValidation,
					setValidStatusSocialIntegrateWatsApp
				);
				break;

			default:
				break;
		}
	} catch (error) {
		console.log(error);
	}
};

export const isEmptyColor = (isValid: boolean, isEmpty: boolean): string => {
	if (!isValid && isEmpty) {
		return '2px solid #D24646';
	} else {
		return 'none';
	}
};

export const clearRegistrationStatus = (dispatch: AppDispatch): void => {
	dispatch(setRegistrationStatus(false));
	dispatch(setEmptyStatusForRegistration(false));
};

export const clearLoginAndLogout = (dispatch: AppDispatch): void => {
	dispatch(setAuthorizationStatus(false));

	dispatch(setEmailLogin(''));
	dispatch(setDomainLogin(''));
	dispatch(setPasswordLogin(''));

	dispatch(setCodeJSGeneratedStatus(false));
	dispatch(setCurrentStepGenerator(0));

	dispatch(setEmptyStatusForRegistration(false));
	dispatch(setEmptyStatusForLogin(false));
	dispatch(setEmptyStatusForCheckChatGeneratorFirstStep(false));
	dispatch(setEmptyStatusForCheckChatGeneratorSecondStep(false));
};

export const receiveHTMLCodeScriptForChatbot = (
	nameOfCloudlet: string | undefined,
	markdown: boolean,
	speech: boolean,
	search: boolean,
	chat: boolean,
	cssStyle: string,
	file: string,
	domainName: string,
	headerName: string,
	buttonName: string
): string => {
	const currentNameOfCloudlet = (): string =>
		nameOfCloudlet === undefined ? 'undefined' : nameOfCloudlet;
	const convertBooleanToString = (param: boolean): string =>
		param ? 'true' : 'false';

	const domainWithoutDot = domainName.replace(/\./gim, '_');
	const displayedScript = `<script src="${currentNameOfCloudlet()}include-javascript?markdown=${convertBooleanToString(
		markdown
	)}&speech=${convertBooleanToString(speech)}&search=${convertBooleanToString(
		search
	)}&chat=${convertBooleanToString(chat)}&css=${encodeURI(
		cssStyle.toLowerCase()
	)}&file=default&type=${domainWithoutDot}&header=${encodeURI(
		headerName
	)}&button=${encodeURI(buttonName)}" defer></script>`;

	return displayedScript;
};

export const receiveHTMLCodeScriptForChatbotCoderfyJS = (
	chatbotId: string
): string => {
	const linkCloudlet = process.env.REACT_APP_BASE_LINK;
	const currentNameOfCloudlet = (): string =>
		linkCloudlet === undefined ? 'undefined' : linkCloudlet;

	return `<script src="${currentNameOfCloudlet()}/chatbot/script?chatbotId=${chatbotId}" defer >
	</script>`;
};

export const ifErrorResponseFromApi = (
	response: IResponseDataWithError,
	dispatch: AppDispatch,
	setValidStatusFn: ActionCreatorWithPayload<string, string>,
	ifDataInResponse?: string
): void => {
	console.log('error util');
	if ('data' in response.error) {
		dispatch(
			setValidStatusFn(
				ifDataInResponse === undefined
					? response.error.data.error
					: ifDataInResponse
			)
		);
	} else {
		dispatch(setValidStatusFn(response.error.error));
	}
};

export const domainWithoutHttp = (domain: string): string => {
	let result = '';
	result = domain.replace(/http(s)?:\/\//, '');
	const resultArray = result.split('');
	resultArray[resultArray.length - 1] === '/' && resultArray.pop();
	result = resultArray.join('');
	return result;
};
