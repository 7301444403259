import { Box } from '@mui/material';
import React from 'react';

interface IWrappedInputWithArticleProps {
	children: JSX.Element;
	article: string;
}

export const WrapperForArticleInput: React.FC<
	IWrappedInputWithArticleProps
> = ({ children, article }) => {
	return (
		<Box component="div" className="wrapperForArticleInput">
			<Box component="div" className="wrapperForArticleInput-article">
				{article}
			</Box>
			<Box component="div" className="wrapperForArticleInput-input">
				{children}
			</Box>
		</Box>
	);
};
