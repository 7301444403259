import styled from 'styled-components';

export const StyledSharedPagination = styled.div`
	& .MuiPagination-root {
		& .MuiPagination-ul {
			& .MuiButtonBase-root.MuiPaginationItem-root {
				color: ${(props) => props.theme.currentTheme.mainFontColor};
				font-family: 'Nunito';
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
			}

			& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
				color: #8044f8;
				background: none;
				border-bottom: 1px solid #8044f8;
				border-radius: 0px;
			}

			& .MuiPaginationItem-root {
				color: ${(props) => props.theme.currentTheme.mainFontColor};
			}
		}
	}

	@media screen and (max-width: 450px) {
		transform: scale(0.7);
		& .MuiPagination-root {
			& .MuiPagination-ul {
				flex-wrap: nowrap;
			}
		}
	}

	@media screen and (max-width: 300px) {
		& .MuiPagination-root {
			& .MuiPagination-ul {
				flex-wrap: wrap;
			}
		}
	}
`;
