import React from 'react';
import { Box } from '@mui/material';
import circle from '../pic/circle.svg';

interface INicheWrapperProps {
	title: string;
	value: string;
	index: number;
}

export const NicheWrapper: React.FC<INicheWrapperProps> = ({
	title,
	value,
	index,
}) => {
	const theme = { indexTwoDigit: index >= 10 ? '29px' : '36px' };

	return (
		<Box component="div" className="nicheWrapper">
			<Box component="div" className="nicheWrapper-icon">
				<Box
					component="div"
					className="icon-index"
					sx={{ position: 'absolute', top: '46%', left: theme.indexTwoDigit }}
				>
					{index}
				</Box>
				<Box component="img" className="current-icon" src={circle} />
			</Box>
			<Box component="div" className="nicheWrapper-text">
				<Box component="div" className="nicheWrapper-text-title">
					{title}
				</Box>
				<Box component="div" className="nicheWrapper-text-value">
					{value}
				</Box>
			</Box>
		</Box>
	);
};
