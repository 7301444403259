import { useAppDispatch } from '../../../app/hooks';
import {
	pushArrayCheckedSnippets,
	pushSeveralSnippetsInCheckedArray,
	removeArrayCheckedSnippets,
	removeCurrentSnippetsFromArrayChecked,
} from '../../../app/slices/trainingBotMenu/checkedSnippets/checkedSnippetsSlice';

interface IAllFunctionsWithCheckedArray {
	addCurrentSnippetAsChecked: (currentSnippetId: number) => void;
	removeCurrentSnippetAsChecked: (currentSnippetId: number) => void;
	removeAllArraySnippetAsChecked: () => void;
	addSeveralSnippetsInCheckedArray: (snippetIds: number[]) => void;
}

export const useAddRemoveCurrentSnippetAsChecked =
	(): IAllFunctionsWithCheckedArray => {
		const dispatch = useAppDispatch();

		const addCurrentSnippetAsChecked = (currentSnippetId: number): void => {
			dispatch(pushArrayCheckedSnippets(currentSnippetId));
		};

		const removeCurrentSnippetAsChecked = (currentSnippetId: number): void => {
			dispatch(removeCurrentSnippetsFromArrayChecked(currentSnippetId));
		};

		const removeAllArraySnippetAsChecked = (): void => {
			dispatch(removeArrayCheckedSnippets());
		};

		const addSeveralSnippetsInCheckedArray = (snippetIds: number[]): void => {
			dispatch(pushSeveralSnippetsInCheckedArray(snippetIds));
		};

		const allFunctionsWithCheckedArray = {
			addCurrentSnippetAsChecked,
			removeCurrentSnippetAsChecked,
			removeAllArraySnippetAsChecked,
			addSeveralSnippetsInCheckedArray,
		};

		return allFunctionsWithCheckedArray;
	};
