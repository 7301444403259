import { Box } from '@mui/material';
import React from 'react';
import { StyledProgressDots } from './ProgressDots.style';

export const ProgressDots: React.FC = () => {
	return (
		<StyledProgressDots>
			<Box component="div" className="dot-one"></Box>
			<Box component="div" className="dot-two"></Box>
			<Box component="div" className="dot-three"></Box>
			<Box component="div" className="dot-four"></Box>
		</StyledProgressDots>
	);
};
