import React, { useState } from 'react';
import { Box } from '@mui/material';
import { SharedQuestion } from '../shared/Question/Question';
import { SharedAnswer } from '../shared/Answer/Answer';

interface IAQWrapperProps {
	answer: string;
	question: string;
	content: string[];
}

export const AQWrapper: React.FC<IAQWrapperProps> = ({
	answer,
	question,
	content,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleOpenCloseAnswer = (): void => {
		setIsOpen(!isOpen);
	};

	return (
		<Box component="div" className="aqWrapper">
			<SharedQuestion
				isOpen={isOpen}
				closeOpenAnswer={handleOpenCloseAnswer}
				text={question}
			/>
			{isOpen ? <SharedAnswer text={answer} content={content} /> : <></>}
		</Box>
	);
};
