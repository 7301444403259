import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { type RootState } from '../../../../../app/store';
import { StyledChangeChatbotIntegration } from './ChangeChatbotIntegration.style';
import { SharedMainButton } from '../../../../shared/SharedMainButton/SharedMainButton';
import { SharedTransparentButton } from '../../../../shared/SharedTransparentButton/SharedTransparentButton';
import { SharedModal } from '../../../../shared/SharedModal/SharedModal';
import { useUpdateMessengerBotMutation } from '../../../../../app/api/chatbot.api';
import { setEmptyStatusChangeChatbotIntegration } from '../../../../../app/slices/socialMenu/changeChatbotIntegrationInput/checkEmptyStatusChangeChatbotIntegration.slice';
import { DataAlert } from '../../../../shared/SharedDataAlertFromApi/SharedDataAlertFromApi';
import { ProgressSpinner } from '../../../../shared/ProgressSpinner/ProgressSpinner';
import { WrappedInput } from '../../../../shared/Input/WrappedInput/WrappedInput';
import { setChangeChatbotIntegrationInput } from '../../../../../app/slices/socialMenu/changeChatbotIntegrationInput/changeChatbotIntegrationInput.slice';
import { setValidStatusChangeChatbotIntegrationInput } from '../../../../../app/slices/socialMenu/changeChatbotIntegrationInput/isValidChangeChatbotIntegrationInput.slice';
import { validation } from '../../../../../app/utils/utils';
import { type IUpdateMessengerBotRequest } from '../../../../../app/api/interfaces/messenger/updateMessengerBot.interface';

interface IModalChangeChatbotIntegration {
	closeFn: () => void;
	chatbotId: number;
	chatbotIdIntegration: string;
	refetchFn: () => Promise<void>;
	currentTokenForEdit: string;
}

interface IChangeChatbotIntegrationProps {
	closeFn: () => void;
	chatbotId: number;
	chatbotIdIntegration: string;
	refetchFn: () => Promise<void>;
	currentTokenForEdit: string;
}

const ChangeChatbotIntegration: React.FC<IChangeChatbotIntegrationProps> = ({
	closeFn,
	chatbotId,
	refetchFn,
	currentTokenForEdit,
	chatbotIdIntegration,
}) => {
	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);
	const theme = { currentTheme };

	const [messageAfterRequest, setMessageAfterRequest] = useState<
		JSX.Element | string
	>('');

	const valueChangeChatbotIntegrationInput = useAppSelector(
		(state: RootState) =>
			state.changeChatbotIntegrationInput.changeChatbotIntegrationInput
	);

	const isButtonPressed = useAppSelector(
		(state: RootState) => state.checkEmptyStatusChangeChatbotIntegration
	);
	const validChangeChatbotIntegrationInputStatus = useAppSelector(
		(state: RootState) =>
			state.isValidChangeChatbotIntegrationInput
				.isValidChangeChatbotIntegrationInput
	);

	const [updateChatbotIntegration, updateChatbotIntegrationStatus] =
		useUpdateMessengerBotMutation();

	const dispatch = useAppDispatch();
	const handleSendFn = async (): Promise<void> => {
		dispatch(setEmptyStatusChangeChatbotIntegration(true));

		if (
			validChangeChatbotIntegrationInputStatus &&
			valueChangeChatbotIntegrationInput.length > 0
		) {
			const requestBody: IUpdateMessengerBotRequest = {
				token,
				chatbotIdIntegration,
				updateMessengerBotBody: {
					botToken: valueChangeChatbotIntegrationInput,
					chatbotId,
				},
			};
			try {
				const result = await updateChatbotIntegration(requestBody);
				if ('data' in result) {
					setMessageAfterRequest(
						<DataAlert text="Your token has been successfully updated" />
					);
					await refetchFn();
				} else if ('error' in result) {
					setMessageAfterRequest(
						<DataAlert text="Something went wrong" isError />
					);
				}
			} catch (error) {
				setMessageAfterRequest(
					<DataAlert text="Something went wrong" isError />
				);
			}
		}
	};

	useEffect(() => {
		dispatch(setEmptyStatusChangeChatbotIntegration(false));
	}, []);

	useEffect(() => {
		if (updateChatbotIntegrationStatus.isError) {
			setMessageAfterRequest(<DataAlert text="Something went wrong" isError />);
		} else if (updateChatbotIntegrationStatus.isLoading) {
			setMessageAfterRequest(<ProgressSpinner />);
		}
	}, [updateChatbotIntegrationStatus]);

	const handleCloseModal = (): void => {
		closeFn();
	};

	return (
		<StyledChangeChatbotIntegration theme={theme}>
			<Box component="div" className="changeChatbotIntegration-wrapper">
				<Box
					component="div"
					className="changeChatbotIntegration-title-subtitle"
				>
					<Box component="div" className="changeChatbotIntegration-title">
						Edit your chatbot integration token
					</Box>
				</Box>
				<Box component="div" className="changeChatbotIntegration-input-url">
					<WrappedInput
						type="text"
						placeholder="Token"
						isEmpty={isButtonPressed}
						isValid={validChangeChatbotIntegrationInputStatus}
						uniqueNameOfInput="changeChatbotIntegration-input-modal"
						value={valueChangeChatbotIntegrationInput}
						configChangeInputV6={{
							alertMessage: 'Please, enter a correct value',
							setValueFn: setChangeChatbotIntegrationInput,
							setValidStatusFn: setValidStatusChangeChatbotIntegrationInput,
							validationFn: validation.useMessageValidation,
						}}
					/>
				</Box>
				{messageAfterRequest}
				<Box component="div" className="changeChatbotIntegration-button-field">
					<Box>
						<SharedTransparentButton
							text="Close"
							buttonFunction={handleCloseModal}
						/>
					</Box>
					<Box>
						<SharedMainButton
							isDeleteButton={false}
							text="Update"
							buttonFunction={() => {
								void handleSendFn();
							}}
						/>
					</Box>
				</Box>
			</Box>
		</StyledChangeChatbotIntegration>
	);
};

export const ModalChangeChatbotIntegration: React.FC<
	IModalChangeChatbotIntegration
> = ({
	closeFn,
	chatbotId,
	refetchFn,
	currentTokenForEdit,
	chatbotIdIntegration,
}) => {
	return (
		<SharedModal
			closeFn={closeFn}
			executeFn={() => {}}
			message={null}
			title=""
			isOnlyAlert={false}
			standaloneFC={
				<ChangeChatbotIntegration
					chatbotId={chatbotId}
					closeFn={closeFn}
					refetchFn={refetchFn}
					currentTokenForEdit={currentTokenForEdit}
					chatbotIdIntegration={chatbotIdIntegration}
				/>
			}
		/>
	);
};
