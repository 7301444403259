// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import {
	useAppDispatch,
	useMiddlewareHandleInfoAboutSnippet,
} from '../../../../app/hooks';
import { setOpenModalAskCacheStatus } from '../../../../app/slices/trainingBotMenu/openModal/openCacheSnippetsTrainingBotMenuSlice';

export const useOpenModalCache = (): ((
	currentSnippetNumber: string
) => void) => {
	const sendCurrentSnippetInfo = useMiddlewareHandleInfoAboutSnippet();
	const dispatch = useAppDispatch();

	return (currentSnippetNumber: string): void => {
		sendCurrentSnippetInfo(currentSnippetNumber, undefined, undefined);
		dispatch(setOpenModalAskCacheStatus(true));
	};
};
