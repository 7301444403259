/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialInputsValueSocialIntegrateSliceState {
	socialIntegrateValueInputTelegram: string;
	socialIntegrateValueInputViber: string;
	socialIntegrateValueInputMessenger: string;
	socialIntegrateValueInputWatsApp: string;
}

const initialState: IInitialInputsValueSocialIntegrateSliceState = {
	socialIntegrateValueInputTelegram: '',
	socialIntegrateValueInputViber: '',
	socialIntegrateValueInputMessenger: '',
	socialIntegrateValueInputWatsApp: '',
};

const inputsValueSocialIntegrateSlice = createSlice({
	name: 'inputsValueSocialIntegrateSlice',
	initialState,
	reducers: {
		setSocialIntegrateValueInputTelegram(state, action) {
			state.socialIntegrateValueInputTelegram = action.payload;
			return state;
		},
		setSocialIntegrateValueInputViber(state, action) {
			state.socialIntegrateValueInputViber = action.payload;
			return state;
		},
		setSocialIntegrateValueInputMessenger(state, action) {
			state.socialIntegrateValueInputMessenger = action.payload;
			return state;
		},
		setSocialIntegrateValueInputWatsApp(state, action) {
			state.socialIntegrateValueInputWatsApp = action.payload;
			return state;
		},
	},
});

export const {
	setSocialIntegrateValueInputTelegram,
	setSocialIntegrateValueInputViber,
	setSocialIntegrateValueInputMessenger,
	setSocialIntegrateValueInputWatsApp,
} = inputsValueSocialIntegrateSlice.actions;
export const inputsValueSocialIntegrateReducer =
	inputsValueSocialIntegrateSlice.reducer;
