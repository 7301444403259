import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IInitialRefetchFnSocialMenu {
	refetchFnSocial: () => Promise<void>;
}

const initialState: IInitialRefetchFnSocialMenu = {
	refetchFnSocial: async () => {},
};

const refetchFnSocialMenuSlice = createSlice({
	name: 'refetchFnSocialMenu',
	initialState,
	reducers: {
		setRefetchFnSocialMenu(state, action: PayloadAction<() => Promise<void>>) {
			state.refetchFnSocial = action.payload;
			return state;
		},
	},
});

export const { setRefetchFnSocialMenu } = refetchFnSocialMenuSlice.actions;
export const refetchFnSocialMenuReducer = refetchFnSocialMenuSlice.reducer;
