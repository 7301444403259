// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

const checkEmptyStatusForConfirmEmailSlice = createSlice({
	name: 'checkEmptyStatusForConfirmEmail',
	initialState,
	reducers: {
		setEmptyStatusForConfirmEmail(state, action: PayloadAction<boolean>) {
			state = action.payload;
			return state;
		},
	},
});

export const { setEmptyStatusForConfirmEmail } =
	checkEmptyStatusForConfirmEmailSlice.actions;
export const checkEmptyStatusForConfirmEmailReducer =
	checkEmptyStatusForConfirmEmailSlice.reducer;
