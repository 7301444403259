import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { StyledChangeUserInfoMenu } from './ChangeUserInfoMenu.style';
import {
	useAppDispatch,
	useAppSelector,
	// useUpdateExecuteFunction,
	useUpdateUserData,
} from '../../app/hooks';
import { type RootState } from '../../app/store';
import {
	useDeleteUserMutation,
	useUpdateUserMutation,
} from '../../app/api/chatbot.api';
import { clearLoginAndLogout } from '../../app/utils/utils';
import { setEmptyStatusForChangeInfo } from '../../app/slices/inputsChangeInformation/checkEmptyStatusForChangeInfoSlice';
import {
	clearSessionStorage,
	clearStorage,
	getItem,
	setItem,
} from '../../app/utils/storageUtils';
import { SharedModal } from '../shared/SharedModal/SharedModal';
import {
	CurrentUserInfoCard,
	type IUserInformation,
} from './CurrentUserInfoCard';
import {
	type IResponseDataWithError,
	type IResponsePatchUserSuccess,
	type IResponseUserDelete,
} from '../../app/api/interfaces/interfaces';
import { ProgressSpinner } from '../shared/ProgressSpinner/ProgressSpinner';
import { setAnswerApiLoginPersonalInformation } from '../../app/slices/answersFromAPI/answerApiLoginSlice';
import {
	setIsOpenModal,
	setIsOpenModalAlertChange,
	setIsOpenModalAfterSuccessDeleteAccount,
	setMessageErrorForModal,
	setMessageFromApi,
} from '../../app/slices/stateChangeUserInfoMenuSlice/stateChangeUserInfoMenuSlice';
import { ChangePasswordField } from './ChangePasswordField';
import { setEmptyStatusForChangePasswordInfo } from '../../app/slices/inputsChangeInformation/checkEmptyStatusForChangePasswordSlice';
import { SharedMainButton } from '../shared/SharedMainButton/SharedMainButton';
import { DataAlert } from '../shared/SharedDataAlertFromApi/SharedDataAlertFromApi';
import { useNavigate } from 'react-router-dom';

interface IDataResponseUpdateUser {
	data: IResponsePatchUserSuccess;
}

interface IDataResponseDeleteUser {
	data: IResponseUserDelete;
}

export const ChangeUserInfoMenu: React.FC = () => {
	const navigate = useNavigate();

	const loginInputsValue = useAppSelector(
		(state: RootState) => state.loginInputsValue
	);
	// const personalInformationFromApi = useAppSelector(
	// 	(state: RootState) => state.answerApiLogin
	// );
	const token = useAppSelector(
		(state: RootState) => state.answerApiLogin.token
	);

	const validInputsStatusChangeInformation = useAppSelector(
		(state: RootState) => state.isValidChangeInformationValues
	);

	const currentTheme = useAppSelector((state: RootState) => state.switchTheme);
	const theme = { currentTheme };

	const {
		isValidUsernameChangeInfo,
		isValidSurnameChangeInfo,
		isValidPasswordRepeatChangeInfo,
		isValidPasswordChangeInfo,
	} = validInputsStatusChangeInformation;
	const changeInputsValue = useAppSelector(
		(state: RootState) => state.changeInformationInputsValue
	);
	const { username, password, surname, repeatPassword } = changeInputsValue;

	const personalInformationFromApi = useAppSelector(
		(state: RootState) => state.answerApiPersonalInformation
	);

	const dispatch = useAppDispatch();

	const userInformation: IUserInformation[] = [
		{
			title: 'Username:',
			value: personalInformationFromApi.username,
			id: 'username-change-info',
			type: 'text',
		},
		{
			title: 'Surname:',
			value: personalInformationFromApi.surname,
			id: 'surname-change-info',
			type: 'text',
		},
	];
	const userInformationPassword: IUserInformation[] = [
		{
			title: 'Password:',
			value: '',
			id: 'password-change-info',
			type: 'password',
		},
		{
			title: 'Repeat password:',
			value: '',
			id: 'password-change-info-repeat',
			type: 'password',
		},
	];

	const stateChangeUserInfoMenu = useAppSelector(
		(state: RootState) => state.stateChangeUserInfoMenu
	);

	const {
		messageErrorForModal,
		messageFromApi,
		isOpenModal,
		isOpenModalAlertChange,
		isOpenModalAfterSuccessDeleteAccount,
	} = stateChangeUserInfoMenu;

	// const [isOpenModal, setIsOpenModal] = useState(false);
	// const [isOpenModalAlertChange, setIsOpenModalAlertChange] = useState(false);
	// const [isOpenModalAfterSuccessDeleteAccount, setIsOpenModalAfterSuccessDeleteAccount] = useState(false);

	// const [messageErrorForModal, setMessageErrorForModal] = useState('');
	// const [messageFromApi, setMessageFromApi] = useState('');

	const [
		loadingErrorContentForChangeUser,
		setLoadingErrorContentForChangeUser,
	] = useState<JSX.Element | null>(null);
	const [
		loadingErrorContentForDeleteUser,
		setLoadingErrorContentForDeleteUser,
	] = useState<JSX.Element | null>(null);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [updateUser, resultOfUpdateUser] = useUpdateUserMutation();
	const [deleteUser, resultOfDeleteUser] = useDeleteUserMutation();

	// const updateUserData = (): IUpdateUserData => {
	// 	const result: IUpdateUserData = {
	// 		username,
	// 		surname,
	// 		resetToken: 'string',
	// 	};
	// 	if (
	// 		repeatPassword === password &&
	// 		repeatPassword.length > 0 &&
	// 		password.length > 0 &&
	// 		isValidPasswordRepeatChangeInfo &&
	// 		isValidPasswordChangeInfo
	// 	) {
	// 		result.password = password;
	// 		return result;
	// 	} else {
	// 		return result;
	// 	}
	// };

	const updateUserData = useUpdateUserData();

	// const updateExecuteFunction = useUpdateExecuteFunction();

	const updateExecuteFunction = (): void => {
		updateUser({
			updateUserData,
			token,
		})
			.then(
				async (response: IDataResponseUpdateUser | IResponseDataWithError) => {
					// console.log(response);
					if ('data' in response) {
						// console.log(response.data.data);
						dispatch(setMessageFromApi(response.data.data));

						const loginDataFromApiPrevious = getItem('loginDataFromApi');
						const id =
							loginDataFromApiPrevious !== null
								? JSON.parse(loginDataFromApiPrevious).id
								: 0;

						const personalInformationFromApi = {
							id,
							username,
							surname,
						};

						dispatch(
							setAnswerApiLoginPersonalInformation(personalInformationFromApi)
						);
						setItem(
							'loginDataFromApi',
							JSON.stringify(personalInformationFromApi)
						);
					} else if ('error' in response) {
						// console.log(response.error);
						dispatch(setMessageFromApi(response.error.data.message));
					}
				}
			)
			.catch((error) => {
				console.log(error);
				dispatch(setMessageFromApi('Server error: Failed to fetch'));
			});
	};

	const handleSendChangeInformation = (): void => {
		dispatch(setEmptyStatusForChangeInfo(true));
		if (repeatPassword.length > 0 || password.length > 0) {
			dispatch(setEmptyStatusForChangePasswordInfo(true));
			if (
				repeatPassword === password &&
				repeatPassword.length > 0 &&
				password.length > 0 &&
				isValidPasswordRepeatChangeInfo &&
				isValidPasswordChangeInfo &&
				username.length > 0 &&
				surname.length > 0 &&
				isValidSurnameChangeInfo &&
				isValidUsernameChangeInfo
			) {
				updateExecuteFunction();
				setTimeout(() => {
					dispatch(setIsOpenModalAlertChange(true));
				}, 100);
			} else {
				setTimeout(() => {
					dispatch(setIsOpenModalAlertChange(true));
				}, 100);
				repeatPassword !== password &&
					setLoadingErrorContentForChangeUser(
						<DataAlert isError text="Password mismatch" />
					);
				console.log('');
				// console.log('not valid inputs');
			}
		} else {
			if (
				personalInformationFromApi.username === username &&
				personalInformationFromApi.surname === surname
			) {
				setTimeout(() => {
					dispatch(setIsOpenModalAlertChange(true));
				}, 100);
				setLoadingErrorContentForChangeUser(
					<DataAlert isError text="Nothing to change: the data is the same" />
				);
			} else if (
				username.length > 0 &&
				surname.length > 0 &&
				isValidSurnameChangeInfo &&
				isValidUsernameChangeInfo
			) {
				updateExecuteFunction();
				setTimeout(() => {
					dispatch(setIsOpenModalAlertChange(true));
				}, 100);
			} else {
				console.log('');
				// console.log('not valid inputs');
			}
		}
	};

	useEffect(() => {
		dispatch(setEmptyStatusForChangeInfo(false));
		dispatch(setEmptyStatusForChangePasswordInfo(false));
		setLoadingErrorContentForChangeUser(null);
	}, []);

	useEffect(() => {
		resultOfUpdateUser.isLoading &&
			setLoadingErrorContentForChangeUser(<ProgressSpinner />);
		resultOfUpdateUser.isError &&
			setLoadingErrorContentForChangeUser(
				<DataAlert isError text={messageFromApi} />
			);
		resultOfUpdateUser.isSuccess &&
			setLoadingErrorContentForChangeUser(<DataAlert text={messageFromApi} />);
	}, [resultOfUpdateUser, messageFromApi]);

	useEffect(() => {
		resultOfUpdateUser.isSuccess
			? dispatch(setIsOpenModalAlertChange(true))
			: dispatch(setIsOpenModalAlertChange(false));
	}, [resultOfUpdateUser]);

	const handleDeleteAccount = (): void => {
		// console.log(loginInputsValue);
		deleteUser({
			email: loginInputsValue.emailLogin,
			token,
		})
			.then((response: IDataResponseDeleteUser | IResponseDataWithError) => {
				// console.log(response);
				if ('data' in response) {
					// console.log(response.data);

					dispatch(setIsOpenModalAfterSuccessDeleteAccount(true));
					dispatch(setIsOpenModal(false));

					clearStorage();
					clearSessionStorage();
				} else if ('error' in response) {
					// console.log(response.error.data.message);
					response.error.data.error.length > 0
						? dispatch(setMessageErrorForModal(response.error.data.error))
						: dispatch(setMessageErrorForModal('Error'));
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(setMessageErrorForModal(JSON.stringify(error)));
			});
	};

	const handleOpenModal = (): void => {
		dispatch(setIsOpenModal(true));
	};
	const handleCloseModal = (): void => {
		dispatch(setIsOpenModal(false));
		setLoadingErrorContentForDeleteUser(null);
	};
	const handleCloseModalAlertChange = (): void => {
		dispatch(setIsOpenModalAlertChange(false));
	};
	const handlePressOkAfterDeleteAccount = (): void => {
		navigate('/');
		clearLoginAndLogout(dispatch);
	};

	useEffect(() => {
		resultOfDeleteUser.isLoading &&
			setLoadingErrorContentForDeleteUser(<ProgressSpinner />);
		resultOfDeleteUser.isError &&
			setLoadingErrorContentForDeleteUser(
				<DataAlert isError text={messageErrorForModal} />
			);
	}, [resultOfDeleteUser, messageErrorForModal]);

	useEffect(() => {
		if (repeatPassword.length === 0 && password.length === 0) {
			dispatch(setEmptyStatusForChangePasswordInfo(false));
		}
	}, [repeatPassword, password]);

	return (
		<StyledChangeUserInfoMenu theme={theme}>
			<Box component="div" className="change-use-info-wrapper-border">
				<Box component="div" className="change-use-info-wrapper">
					<Box component="div" className="change-user-info">
						<Box component="div" className="change-info-area">
							<Box component="div" className="change-name-surname-field">
								<Box component="div" className="name-surname-area">
									{userInformation.map(
										(currentInformation: IUserInformation) => {
											return (
												<CurrentUserInfoCard
													currentInformation={currentInformation}
													key={currentInformation.id}
												/>
											);
										}
									)}
								</Box>
							</Box>
							<Box component="div" className="change-password-field">
								<ChangePasswordField fields={userInformationPassword} />
							</Box>
						</Box>
						<Box component="div" className="change-buttons-area">
							<Box>
								<SharedMainButton
									isDeleteButton={false}
									buttonFunction={handleSendChangeInformation}
									text="Save"
									tooltip="Update current information"
								/>
								<SharedMainButton
									isDeleteButton
									buttonFunction={handleOpenModal}
									text="Delete Account"
								/>
							</Box>
						</Box>
						{isOpenModal && (
							<SharedModal
								executeFn={handleDeleteAccount}
								closeFn={handleCloseModal}
								message={loadingErrorContentForDeleteUser}
								title="Are you sure you want to delete account?"
								subtitle="This operation cannot be reversed, and you will lose your account"
								isOnlyAlert={false}
								isDeleteModal
							/>
						)}
						{isOpenModalAlertChange ? (
							<SharedModal
								executeFn={() => {}}
								closeFn={handleCloseModalAlertChange}
								message={loadingErrorContentForChangeUser}
								title={
									resultOfUpdateUser.isError
										? 'Warning'
										: resultOfUpdateUser.isLoading
										? 'Please, wait'
										: resultOfUpdateUser.isSuccess
										? 'Succeed'
										: 'Warning'
								}
								isOnlyAlert
							/>
						) : (
							<></>
						)}
						{isOpenModalAfterSuccessDeleteAccount ? (
							<SharedModal
								executeFn={() => {}}
								closeFn={handlePressOkAfterDeleteAccount}
								message={null}
								title="Your account has been deleted!"
								isOnlyAlert
								isDeleteModal
							/>
						) : (
							<></>
						)}
					</Box>
				</Box>
			</Box>
		</StyledChangeUserInfoMenu>
	);
};
