// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export interface IInitialCheckEmptyStatusForTestBotInputSliceState {
	checkEmptyQuestion: boolean;
	checkEmptyCompletion: boolean;
}

const initialState: IInitialCheckEmptyStatusForTestBotInputSliceState = {
	checkEmptyQuestion: false,
	checkEmptyCompletion: false,
};

const checkEmptyStatusForTestBotInputSlice = createSlice({
	name: 'checkEmptyStatusForTestBotInput',
	initialState,
	reducers: {
		setEmptyStatusQuestionForTestBotInput(state, action) {
			state.checkEmptyQuestion = action.payload;
			return state;
		},
		setEmptyStatusCompletionForTestBotInput(state, action) {
			state.checkEmptyCompletion = action.payload;
			return state;
		},
	},
});

export const {
	setEmptyStatusQuestionForTestBotInput,
	setEmptyStatusCompletionForTestBotInput,
} = checkEmptyStatusForTestBotInputSlice.actions;
export const checkEmptyStatusForTestBotInputReducer =
	checkEmptyStatusForTestBotInputSlice.reducer;
