import React, { memo, useEffect } from 'react';
import { Box } from '@mui/material';
import { StyledReceiveJSCodeComponent } from './ReceiveJSCodeComponent.style';
import { useAppSelector } from '../../../app/hooks';
import { type RootState } from '../../../app/store';
import {
	receiveHTMLCodeScriptForChatbot,
	receiveHTMLCodeScriptForChatbotCoderfyJS,
} from '../../../app/utils/utils';
import { NavLink } from 'react-router-dom';
import { SharedJSCode } from '../../shared/SharedJSCode/SharedJSCode';
import check from './pic/check.svg';
import { removeItem } from '../../../app/utils/storageUtils';

interface IReceiveJSCodeComponentProps {
	unmountFn: () => void;
	isCoderfy?: boolean;
}

export const ReceiveJSCodeComponent: React.FC<IReceiveJSCodeComponentProps> =
	memo(function ReceiveJSCodeComponent(
		receiveJSCodeParams: IReceiveJSCodeComponentProps
	) {
		const inputsChatGeneratorValues = useAppSelector(
			(state: RootState) => state.chatGeneratorInputsValue
		);
		const currentTheme = useAppSelector(
			(state: RootState) => state.switchTheme
		);
		const currentChatbotIdAfterCreation = useAppSelector(
			(state: RootState) => state.currentChatbotIdForHTMLCode.chatbotId
		);

		const linkCloudlet = process.env.REACT_APP_AISTA_LINK;

		const displayedScript = receiveHTMLCodeScriptForChatbot(
			linkCloudlet,
			true,
			false,
			false,
			true,
			inputsChatGeneratorValues.style,
			inputsChatGeneratorValues.tone,
			inputsChatGeneratorValues.domain,
			inputsChatGeneratorValues.header,
			inputsChatGeneratorValues.buttonText
		);

		const displayedScriptCoderfyJS = receiveHTMLCodeScriptForChatbotCoderfyJS(
			currentChatbotIdAfterCreation
		);

		const theme = { currentTheme };

		useEffect(() => {
			removeItem('chatbotData');
		}, []);

		// useEffect(() => {
		// return () => {console.log('UNmount'); unmountFn.unmountFn();};
		// }, []);

		return (
			<StyledReceiveJSCodeComponent theme={theme}>
				<Box component="img" src={check} />
				<Box component="div" className="receive-title">
					Great!
				</Box>
				<Box component="div" className="receive-subtitle">
					Your script is ready
				</Box>
				<SharedJSCode
					displayedScript={
						receiveJSCodeParams.isCoderfy === true
							? displayedScriptCoderfyJS
							: displayedScript
					}
				/>
				<Box component="span" className="receive-message">
					<Box component="span" className="receive-message-text">
						If you don&apos;t know how to use the code, please visit the page{' '}
					</Box>
					<NavLink to="/about/platforms">About</NavLink>
				</Box>
			</StyledReceiveJSCodeComponent>
		);
	});
